import React from 'react';
import { useState } from 'react';
import EngagedLinksTabs from './EngagedLinksTabs';

function TopEngagedLinks({ contactEngagement }) {
  const [activeTab, setActiveTab] = useState('Opens');
  const renderLink = ({ emailAddress, fullName, totalClicks, totalOpens }) => {
    return (
      <div key={`${fullName}-${emailAddress}`} className='engaged-links-row'>
        <span className='email-performance-link'>
          {fullName}
          {`   <${emailAddress}>`}
        </span>
        <span className='email-performance-clicks'>{activeTab === 'Opens' ? totalOpens : totalClicks}</span>
      </div>
    );
  };
  return (
    <div className='email-performance-section-container-top'>
      <div className='engaged-links-header-container'>
        <div className='email-performance-rate-header'>Most engaged recipients</div>
        <EngagedLinksTabs activeTab={activeTab} setActiveTab={setActiveTab} disabled={false} />
      </div>

      {contactEngagement.length > 0 ? (
        <div className='engaged-links-table'>
          {contactEngagement
            .sort(function (a, b) {
              if (activeTab === 'Opens') {
                return b.totalOpens - a.totalOpens;
              } else {
                return b.totalClicks - a.totalClicks;
              }
            })
            .map((item) => {
              return renderLink(item);
            })}
        </div>
      ) : (
        <p className='engaged-links-empty-state'>No one has opened this email yet</p>
      )}
    </div>
  );
}

export default TopEngagedLinks;
