import React from 'react';
import SkeletonKeyUpdates from '../../Dashboard/key_updates/SkeletonKeyUpdates';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonSavedItems = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className={`row mx-auto main-content-wrapper align-items-start`}>
        <div className='side-left-element d-none d-lg-block'>
          <div className={`main-navigation settings-navigation pt-4`}>
            <div className='sidebar-nav pr-3'>
              <div className='mt-4 mb-3 pl-lg-5'>
                <Skeleton height={19} width={'80px'} />
              </div>
              <ul className='px-0 mb-2'>
                {Array(3)
                  .fill()
                  .map((item, index) => (
                    <li className={`px-2 pl-lg-5 pointer mb-4`} key={index}>
                      <p className='mb-0'>
                        <Skeleton height={20} width={'160px'} />
                      </p>
                      <p className='mb-0'>
                        <Skeleton height={19} width={'90px'} />
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div className='main-content pr-xl-5 px-md-4 px-3'>
          <div className='pt-5'>
            <div className='row mx-0 justify-content-between align-items-center mb-2'>
              <div className='d-flex align-items-center'>
                <Skeleton height={24} width={'340px'} />
                <Skeleton height={30} width={30} circle={true} />
              </div>
              <div className='d-flex'>
                <Skeleton height={34} width={120} style={{ borderRadius: '20px', marginRight: '10px' }} />
                <Skeleton height={34} width={34} circle={true} />
              </div>
            </div>
            <SkeletonKeyUpdates />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonSavedItems;
