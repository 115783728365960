const Preloader = ({ style }) => {
  return (
    <div
      className={`w-100 bg-white flex-centered justify-content-center preloader-min-height`}
      style={style ?? undefined}
    >
      <img src={`${process.env.REACT_APP_CDNURL}/images/loader.gif`} alt='Loading' />
    </div>
  );
};
export default Preloader;
