import React from 'react';

function EngagedLinksTabs({ activeTab, setActiveTab, disabled }) {
  return (
    <div className='engaged-links-tabs'>
      <button
        className={`engaged-links-tabs-button ${activeTab === 'Opens' ? 'engaged-links-tabs-button-active' : ''}`}
        onClick={() => setActiveTab('Opens')}
        disabled={disabled}
      >
        Opens
      </button>
      <button
        className={`engaged-links-tabs-button ${activeTab === 'Clicks' ? 'engaged-links-tabs-button-active' : ''}`}
        onClick={() => setActiveTab('Clicks')}
        disabled={disabled}
      >
        Clicks
      </button>
    </div>
  );
}

export default EngagedLinksTabs;
