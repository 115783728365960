import React, { useContext } from 'react';
import { store } from '../../Store';
import { useAuth0 } from '@auth0/auth0-react';
import { createFiltersProperties } from '../helpers/controlActiveFilters';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import useIsMobile from '../utilities/useIsMobile';
import parliamentarianUsers from '../sidebar/parliamentarianUsers';
import uppercaseFirstLetter from '../utilities/uppercaseFirstLetter';
import MainSearchBar from '../MainSearchBar';
import EmbedWebsiteButton from '../../Settings/WebsiteFeeds/EmbedWebsiteButton';
import Filters from '../Filters';
import { Link, useLocation } from 'react-router-dom-v5-compat';

const InboxBar = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { code } = activePlan;
  const filtersProperties = createFiltersProperties();

  const { inboxSection } = useMainSectionParameter();
  const isMobile = useIsMobile();

  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();

  const createMainSectionName = () => {
    const sectionName = 'feed';
    if (location.pathname.includes('stakeholder-mapping')) {
      return parliamentarianUsers(code) ? 'Policymakers' : 'Stakeholder mapping';
    } else {
      if (inboxSection && isAuthenticated) {
        let { given_name } = user;
        let title;
        if (parliamentarianUsers(code)) {
          title = `Your ${sectionName}`;
        } else {
          title =
            given_name.length < 17
              ? `${given_name}'${given_name.charAt(given_name.length - 1) === 's' ? '' : 's'} ${sectionName}`
              : `${sectionName}`;
        }
        return !isMobile ? title : uppercaseFirstLetter(sectionName);
      }
      return 'Research';
    }
  };
  const sectionName = createMainSectionName();

  return (
    <>
      <div className={`bg-white monitor-items-bar inbox-items-bar`}>
        <div className='mx-auto main-content-wrapper flex-centered justify-content-between resize-elements-container'>
          <div className='side-left-element pl-md-5 pl-3'>
            <div className='position-relative mr-3'>
              <p className={`mb-0 title-h4 nowrap-item monitor-dropdown-item`}>{sectionName}</p>
            </div>
          </div>
          <div
            className={`main-content flex-centered position-relative filters-general-container justify-content-${isMobile ? 'end' : 'between'} pr-xl-5 px-md-4 px-3`}
          >
            {!isMobile && <MainSearchBar />}

            <div className='d-flex align-items-start'>
              {isMobile && <Filters {...filtersProperties} />}
              <EmbedWebsiteButton />
              <Link
                to={`/settings/alerts?returnUrl=${encodeURIComponent(location.pathname)}`}
                className='general-button px-3 ml-3 d-none d-lg-block action-button rounded-button'
              >
                <span
                  className=' d-inline-block icon-alert-medium paragraph-p2'
                  style={{ marginTop: '-2px', verticalAlign: 'middle', marginRight: '8px' }}
                />
                Alerts
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InboxBar;
