import useGeneralApiCall from '../../../../apiCalls/useGeneralApiCall';
import { useState } from 'react';

function useEmailRecipientInfo() {
  const [recipientInfo, setRecipientInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { generalApiCall } = useGeneralApiCall();

  const getRecipientInfo = async ({ emailId, contactId }) => {
    setIsLoading(true);
    let method = 'get';
    let pathname = `/api/crm-email/get-contact-activity?emailId=${emailId}&contactId=${contactId}`;
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties: {},
      needsAuthentication: true,
      notShowErrorMessage: true,
    });
    setRecipientInfo(result);
    setIsLoading(false);
  };
  return { getRecipientInfo, isLoading, recipientInfo };
}

export default useEmailRecipientInfo;
