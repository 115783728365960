import React, { useContext, useEffect, useState } from 'react';
import Loading from '../Login/Loading';
import axios from 'axios';
import AdminListPage from './ui/AdminListPage';
import { TeamUserFields } from './TeamUserFields';
import createUpdateField from './createUpdateField';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import useCreateANewTeam from './CreateANewTeam';
import { AdminStore } from './AdminState';

const MainTeamList = () => {
  const adminState = useContext(AdminStore);
  const { state, dispatch } = adminState;
  const { activeFilters } = state;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const initialFields = TeamUserFields.filter((item) => item.page.includes('team'));
  const [createTeamFields, setCreateTeamFields] = useState(initialFields);

  const setActiveFilters = (value) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'activeFilters', value });
  };
  const { generalApiCall } = useGeneralApiCall();

  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadTeamLists({ sourceProp: source });
    return () => {
      source.cancel('Admin api call canceled by the user');
    };
  }, []);

  const loadTeamLists = async (props) => {
    try {
      const { page, filters, sourceProp } = props;
      const filtersToApply = filters ?? activeFilters;
      const { search, order, sort } = filtersToApply;
      let pathname = '/api/admin/list-organisations';
      let method = 'post';
      let requestProperties = {
        pageNumber: page ?? 1,
        pageSize: pageSize,
        sort: sort.value,
        order: order.value,
        searchFilter: search,
      };
      let results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: true,
        requestSource: sourceProp,
        notShowErrorMessage: true,
      });
      if (!!results) {
        let finalResults = results;
        if (page) {
          let newResults = [...teamList.results, ...results.results];
          finalResults = {
            ...results,
            results: newResults,
          };
        }
        setTeamList(finalResults);
        if (filters) {
          setIsLoadingFilters(false);
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const applyFilters = (props) => {
    setIsLoadingFilters(true);
    const { property, value } = props;
    const filters = { ...activeFilters, [property]: value };
    setActiveFilters(filters);
    loadTeamLists({ filters });
  };

  const loadMoreResults = () => {
    let { totalCount, pageNumber } = teamList;
    let number = pageNumber * pageSize;
    if (number < totalCount) {
      let page = pageNumber + 1;
      loadTeamLists({ page });
    }
  };

  const { createFunction, copyCredentials } = useCreateANewTeam({
    fields: createTeamFields,
    setFields: setCreateTeamFields,
    source,
    results: teamList,
    setShowSuccessMessage,
    temporaryPassword,
    setTemporaryPassword,
    loadTeamLists,
  });

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <AdminListPage
            updateField={createUpdateField({ createTeamFields, setCreateTeamFields })}
            isLoadingFilters={isLoadingFilters}
            teamList={teamList}
            applyFilters={applyFilters}
            activeFilters={activeFilters}
            createTeamFields={createTeamFields}
            setCreateTeamFields={setCreateTeamFields}
            teamFunctions={{ createFunction, copyCredentials }}
            showSuccessMessage={showSuccessMessage}
            setShowSuccessMessage={setShowSuccessMessage}
            initialFields={initialFields}
            temporaryPassword={temporaryPassword}
            loadMoreResults={loadMoreResults}
          />
        </>
      )}
    </>
  );
};

export default MainTeamList;
