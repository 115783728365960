import { useLocation } from 'react-router-dom-v5-compat';
import ParliamentaryItem from '../../Parliamentary_record/ParlamentaryItem';
import TweetItem from '../../social_media/TweetItem';
import { useContext, useRef, useState } from 'react';
import { store } from '../../../Store';
import getUrlParam from '../../utilities/getUrlParam';
import useOutsideElement from '../../utilities/useOutsideElement';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useAuth0 } from '@auth0/auth0-react';
import { DataPopup } from '../../Navigation/NotFeaturePopups';

const changeSectionNames = (name) => {
  switch (name) {
    case 'Spoken parliamentary contributions':
      return 'parliamentary contribution';
    case 'Tweets':
      return 'Tweet';
    case 'Retweets':
      return 'Retweet';
    case 'EDMs':
      return 'EDM';
    case 'Written questions':
    case 'Committee contributions':
      return name.slice(0, -1).toLowerCase();
    default:
      return `${name.toLowerCase()} contribution`;
  }
};

const ContentItemsPanel = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const location = useLocation();
  const TweetsSection = location.pathname.includes('/analytics/tweets');
  const list = keywordsLists.find((item) => item.id === parseInt(getUrlParam('topic-id')));

  const { isAuthenticated } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);

  const {
    handleScroll,
    setRightPanelState,
    text,
    sectionsValues,
    isLoading,
    activeSection,
    setActiveSection,
    results,
    sentimentPage,
    stakeholdersPage,
    overallSentiment,
    sentimentProperties,
    interestByRegionPage,
  } = props;

  const titleChart = () => {
    if (sentimentPage || overallSentiment) {
      return 'Sentiment';
    } else if (stakeholdersPage) {
      return 'Contributions';
    } else if (interestByRegionPage) {
      return 'Interest by region';
    } else {
      return TweetsSection ? 'Tweets' : 'Mentions';
    }
  };

  return (
    <>
      <div className='analytics-content-items-panel'>
        <div className='h-100 border bg-white position-relative analytics-content-items-container'>
          <CustomScrollbar
            className={'simple-scrollbar top-content-scrollbar smooth-scrolling'}
            maximalThumbYSize={100}
            onScroll={(scrollValues) => {
              handleScroll(scrollValues);
            }}
          >
            <h3 className='analytics-panel-title title-h5-m-bold px-3 pt-3 bg-white mb-0 pb-2'>{titleChart()}</h3>
            <button
              className='general-button circle-button border bg-main-blue text-white z-max analytics-close-button'
              style={{ right: '13px', top: '11px' }}
              onClick={() => {
                setRightPanelState({ open: false });
              }}
              data-tooltip-content={'Close side panel'}
              data-tooltip-id={'general-tooltip'}
            >
              <span
                className='icon-close paragraph-p6 d-inline-block'
                style={{ verticalAlign: 'middle', marginTop: '-2px' }}
              />
            </button>
            <div className='px-3 pb-3 border-bottom analytics-panel-buttons'>
              <p className='mb-0'>{typeof text === 'function' ? text(sectionsValues) : text}</p>
              {sectionsValues.length > 1 &&
                sectionsValues.map((section) => {
                  const { name, value } = section;
                  const iconClass = name.toLowerCase().includes('tweet') ? 'twitter' : 'parliamentary-record';
                  return (
                    <button
                      onClick={() => {
                        setActiveSection(name);
                      }}
                      className={`d-block w-100 px-3 py-1 mt-2 general-button text-left rounded-button ${name === activeSection ? 'action-button' : 'border text-light-blue analytics-button'}`}
                      key={`item-${name}`}
                    >
                      <span
                        className={`${sentimentPage ? `number-contributions-${name.toLowerCase()} number-contributions-item` : `icon-container-${iconClass}`}`}
                      >
                        <span className={`${sentimentPage ? '' : `paragraph-p3 mr-2 icon-${iconClass}`} `} />
                      </span>{' '}
                      {value} {changeSectionNames(name)}
                      {value === 1 ? '' : 's'}
                    </button>
                  );
                })}
            </div>
            <SentimentContentFilter sentimentProperties={sentimentProperties} results={results} />
            <div className='p-3'>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <>
                  <div className='latest-tweets-list policy-list px-0 items-list legislation-content pb-2'>
                    {results?.hits?.map((item, index, array) => {
                      const props = { index, item, array };
                      const { type, contentUrl, contributionId } = item;
                      switch (type) {
                        case 'ParliamentaryRecord':
                          return (
                            <ParliamentaryItem
                              key={`${type}-item-${index}`}
                              {...props}
                              itemOnclick={(e) => {
                                e.preventDefault();
                                if (!isAuthenticated) {
                                  setIsOpen(true);
                                } else {
                                  window.open(
                                    `${contentUrl}${getUrlParam('topic-id') ? `?topic-id=${getUrlParam('topic-id')}` : ''}${!!contributionId ? `#contribution-${contributionId}` : ''}`
                                  );
                                }
                              }}
                            />
                          );
                        case 'Tweet':
                          return <TweetItem key={`${type}-item-${index}`} {...props} customKeywords={list?.keywords} />;
                        default:
                          return '';
                      }
                    })}
                  </div>
                  {results?.hits?.length === 0 && (
                    <p className='text-center main-light-text' style={{ marginTop: '150px' }}>
                      No contributions
                    </p>
                  )}
                </>
              )}
            </div>
          </CustomScrollbar>
        </div>
      </div>
      <DataPopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

const SentimentContentFilter = (props) => {
  const { sentimentProperties, results } = props;
  const { sectionResults, setContentActiveSection, contentActiveSection } = sentimentProperties ?? {};
  const section = sectionResults?.find((item) => item.name === contentActiveSection);
  const numberOfItems = section?.results?.totalHits;
  const iconClass = section?.name?.toLowerCase().includes('tweet') ? 'twitter' : 'parliamentary-record';

  const [showOptions, setShowOptions] = useState(false);
  const optionsLength = sectionResults?.length > 1;

  const filtersElement = useRef(null);
  const hideFilters = () => {
    setShowOptions(false);
  };
  useOutsideElement(filtersElement, hideFilters);

  return (
    <>
      {sectionResults?.length > 0 && results?.hits?.length > 0 && (
        <div className='position-relative border-bottom py-2' ref={filtersElement}>
          <button
            className={`w-100 d-inline-block text-left bg-main-white general-button py-1 paragraph-p2 pr-3 pl-4 ${showOptions ? 'bg-blue-hovered' : ''} flex-centered nowrap-item`}
            onClick={() => {
              if (optionsLength) {
                setShowOptions(!showOptions);
              }
            }}
            style={{ cursor: optionsLength ? 'pointer' : 'auto' }}
          >
            <span
              className={`w-100 d-inline-block ${optionsLength ? 'dropdown-item-element' : ''} ${showOptions ? 'open' : ''}`}
            >
              <span className={`d-inline-block icon-container-${iconClass}`}>
                <span className={`paragraph-p3 mr-2 icon-${iconClass}`} />
              </span>
              {numberOfItems} {changeSectionNames(contentActiveSection)}
              {numberOfItems === 1 ? '' : 's'}
            </span>
          </button>
          {showOptions && (
            <div className={`box filters-container px-0 pt-0 w-100 sentiment-box-filter-container py-1`}>
              {sectionResults.map((option) => {
                const { results, name } = option;
                let totalHits = results?.totalHits;
                const localIconClass = name.toLowerCase().includes('tweet') ? 'twitter' : 'parliamentary-record';
                return (
                  <button
                    key={`filter-${option.name}`}
                    className='general-button text-left pr-3 pl-4 py-2 list-action-button d-block w-100'
                    onClick={() => {
                      setShowOptions(false);
                      setContentActiveSection(option?.name);
                    }}
                  >
                    <span className={`d-inline-block icon-container-${localIconClass}`}>
                      <span className={`paragraph-p3 mr-2 icon-${localIconClass}`} />
                    </span>
                    {totalHits} {changeSectionNames(option.name)}
                    {totalHits === 1 ? '' : 's'}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { changeSectionNames };
export default ContentItemsPanel;
