import React from 'react';
import ProgressBar from '../../../../../Common/TopicPicker/ProgressBar';
import InfoTooltip from './InfoTooltip';

function RateSection({ type, performance, handleOpenRecipients, deliveryItems }) {
  const title = type === 'reply' ? 'Deliverability' : type[0].toUpperCase() + type.substring(1) + ' rate ';
  const titlePercentage = `${
    performance[`${type}Rate`] !== undefined ? parseFloat(Number(performance[`${type}Rate`] * 100).toFixed(1)) : 'n/a'
  }%`;
  const ratesTitle = type === 'reply' ? 'replies' : `${type}s`;
  const uniqueQntty =
    performance[`unique${ratesTitle[0].toUpperCase() + ratesTitle.substring(1)}`] !== undefined
      ? performance[`unique${ratesTitle[0].toUpperCase() + ratesTitle.substring(1)}`]
      : 'n/a';
  const totalQntty =
    performance[`total${ratesTitle[0].toUpperCase() + ratesTitle.substring(1)}`] !== undefined
      ? performance[`total${ratesTitle[0].toUpperCase() + ratesTitle.substring(1)}`]
      : 'n/a';
  const mobileQntty =
    performance[`percentageMobile${ratesTitle[0].toUpperCase() + ratesTitle.substring(1)}`] !== undefined
      ? performance[`percentageMobile${ratesTitle[0].toUpperCase() + ratesTitle.substring(1)}`]
      : 0;

  return (
    <div
      className='email-performance-section-container position-relative'
      style={{ paddingBottom: type === 'reply' ? '16px' : '40px' }}
    >
      <div className='email-performance-rate-header flex-centered'>
        <span>{title}</span>
        {type !== 'reply' && (
          <div className='flex-centered flex-grow-1 justify-content-between'>
            <span className='email-performance-link' onClick={() => handleOpenRecipients(type)}>
              {titlePercentage}
            </span>
            <InfoTooltip
              text={
                type === 'open'
                  ? 'Open rate is the percentage of people who opened your email out of the people who were sent your email.<br/><br/>Unique opens shows the number of recipients who opened your email.<br/><br/>Total opens shows the number of times your recipients opened your email. This includes multiple opens by the same recipient.'
                  : 'Click rate is the percentage of people who clicked a link in your email out of the people who were sent your email.<br/><br/>Unique clicks shows the number of recipients who clicked on a link in your email.<br/><br/>Total clicks shows the number of times your recipients clicked on links in your email. This includes multiple clicks by the same recipient.'
              }
            />
          </div>
        )}
      </div>
      {type === 'reply' ? (
        <div className='delivery-container'>
          {Object.keys(deliveryItems).map((item, index) => {
            return (
              <div key={`delivery-item-${index}`} className={`email-performance-rate`}>
                <span>{item}</span>
                <span onClick={() => handleOpenRecipients(item)} className='email-performance-link'>
                  {deliveryItems[item]}
                </span>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <ProgressBar done={performance[`${type}Rate`] * 100} />
          <div className={`email-performance-rate email-performance-rate-unique`}>
            <span>Unique {ratesTitle}</span>
            <span className='email-performance-link' onClick={() => handleOpenRecipients(type)}>
              {uniqueQntty}
            </span>
          </div>
          <div className='email-performance-rate email-performance-rate-total'>
            <span>Total {ratesTitle}</span>
            <span className='email-performance-total-text'>{totalQntty}</span>
          </div>
        </>
      )}

      {type !== 'reply' && (
        <div className='email-performance-device'>
          <div>
            <span>DESKTOP</span>
            <span className='email-performance-device-percentage'>
              {!!uniqueQntty ? parseFloat(Number((1 - mobileQntty) * 100).toFixed(1)) : 0}%
            </span>
          </div>
          <div>
            <span>MOBILE</span>
            <span className='email-performance-device-percentage'>
              {parseFloat(Number(mobileQntty * 100).toFixed(1))}%
            </span>
          </div>
          <InfoTooltip
            text={
              type === 'open'
                ? 'Device opens are based on data provided by email clients. If a device type cannot be determined, the open is recorded as Desktop'
                : 'Device clicks are based on data provided by email clients. If a device type cannot be determined, the click is recorded as Desktop'
            }
            style={{ marginLeft: 0 }}
          />
        </div>
      )}
    </div>
  );
}

export default RateSection;
