import { useState, useEffect } from 'react';

function useIsMobile(range) {
  const size = range ? range : 992;
  const [isMobile, setIsMobile] = useState(window.innerWidth < size ? true : false);

  //SET MOBILE
  useEffect(() => {
    const setMobile = () => {
      if (window.innerWidth < size) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    setMobile();
    window.addEventListener('resize', setMobile);
    return () => {
      window.removeEventListener('resize', setMobile);
    };
  }, []);

  return isMobile;
}

export default useIsMobile;
