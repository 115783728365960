import * as _ from 'underscore';

function roundToOneHundred(l, target) {
  //https://stackoverflow.com/questions/13483430/how-to-make-rounded-percentages-add-up-to-100
  var off =
    target -
    _.reduce(
      l,
      function (acc, x) {
        return acc + Math.round(x);
      },
      0
    );
  return _.chain(l)
    .map(function (x, i) {
      return Math.round(x) + (off > i) - (i >= l.length + off);
    })
    .value();
}

export default roundToOneHundred;
