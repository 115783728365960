import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonCalendarList = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='calendar-page-container p-5'>
        {Array(9)
          .fill()
          .map((item, index) => (
            <div key={`item${index}`}>
              <div className='mb-4'>
                <div className='my-1'>
                  <Skeleton height={19} width={`100%`} />
                </div>
                <div className='my-1'>
                  <Skeleton height={19} width={`60%`} />
                </div>
                <div className='my-1'>
                  <Skeleton height={19} width={`80%`} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </SkeletonTheme>
  );
};
export default SkeletonCalendarList;
