import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import getUrlParam, { getUrlParamRaw } from '../utilities/getUrlParam';

const useGetParliamentaryContributions = (props) => {
  const { generalApiCall } = useGeneralApiCall();
  const getTotalContributions = async (props) => {
    const { contentUrl, type, source } = props ?? {};
    const topicParam = getUrlParam('topic-id');
    const searchParam = getUrlParamRaw('or') ?? getUrlParamRaw('search');
    const andKeywordsParam = getUrlParamRaw('and');
    const notKeywordsParam = getUrlParamRaw('not');
    const contactIdParam = getUrlParam('contact-id');

    let pathname = `/api/search/query`;
    const method = 'post';

    const getContentType = () => {
      if (type === 'scottish-official-report') {
        return 'ScotlandCountrySpecificParliamentaryContribution';
      } else if (type === 'welsh-official-report') {
        return 'WalesCountrySpecificParliamentaryContribution';
      } else if (type === 'senedd-committee-transcript') {
        return 'SeneddCommitteeTranscriptContribution';
      } else {
        return 'ParliamentaryContribution';
      }
    };

    let filters = [
      { field: 'contentType', value: getContentType(), operator: 'str_eq' },
      { field: 'contentUrl', value: contentUrl, operator: 'str_eq' },
    ];
    if (contactIdParam) {
      filters.push({ field: 'contactIds', value: contactIdParam, operator: 'str_eq' });
    }

    let requestProperties = {
      collaspeField: null,
      type: 'ParliamentaryRecord',
      sort: { field: 'contributionOrder', order: 'ASC' },
      pageSize: 10000,
      filters,
    };
    if (topicParam && topicParam !== 'none') {
      pathname = `/api/search/query-by-keyword-list`;
      requestProperties = {
        ...requestProperties,
        keywordListId: topicParam === 'all' ? null : parseInt(topicParam),
      };
    }

    if (topicParam === 'none') {
      requestProperties = { ...requestProperties, query: '' };
    }

    if (searchParam) {
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(decodeURIComponent(searchParam)?.split(',')),
        andQueries: andKeywordsParam ? ChangeKeywordsExclusions(decodeURIComponent(andKeywordsParam).split(',')) : [],
        mustNotQueries: notKeywordsParam
          ? ChangeKeywordsExclusions(decodeURIComponent(notKeywordsParam).split(','))
          : [],
      };
    }

    let results = await generalApiCall({
      pathname,
      requestProperties,
      requestSource: source,
      method,
      needsAuthentication: !!topicParam,
    });
    return results;
  };

  const getContactIdInformation = async (contactId, source) => {
    const pathname = '/api/search/query';
    const method = 'post';
    const requestProperties = {
      query: '',
      type: 'stakeholder',
      pageNumber: 1,
      pageSize: 1,
      filters: [{ field: 'id', value: contactId, operator: 'str_eq' }],
    };
    const results = generalApiCall({ pathname, method, requestProperties, requestSource: source });
    return results;
  };

  return { getTotalContributions, getContactIdInformation };
};

export default useGetParliamentaryContributions;
