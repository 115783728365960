import React from 'react';
import EmailRecipientsBody from './EmailRecipientsBody';
import EmailRecipientsSideBar from './EmailRecipientsSideBar';
import EmailRecipientsSkeleton from './EmailRecipientsSkeleton';

function EmailRecipients({
  sendRecords,
  emailId,
  searchValue,
  setSearchValue,
  activeOption,
  setActiveOption,
  isLoading,
}) {
  const sidebarMapping = {
    All: [],
    Delivered: ['Delivered', 'Clicked', 'Opened'],
    Opened: ['Clicked', 'Opened'],
    Clicked: ['Clicked'],
    Bounced: ['Bounced'],
    'Spam reports': ['Complained'],
    'Not sent': ['NotSent', 'Cancelled'],
  };

  const contactsByActiveTab = (item) => {
    if (item === 'All') {
      return sendRecords;
    } else {
      return sendRecords.filter((record) => sidebarMapping[item].includes(record.status));
    }
  };

  return (
    <div
      className={`${
        emailId ? 'recipients-performance-wrapper' : ''
      } row mx-auto main-content-wrapper pb-lg-0 flex-grow-1`}
    >
      <div
        className={`${
          emailId ? 'side-left-element-recipients-performance' : 'side-left-element-recipients'
        } d-none d-lg-block`}
      >
        <EmailRecipientsSideBar
          activeOption={activeOption}
          setActiveOption={setActiveOption}
          sidebarMapping={sidebarMapping}
          sendRecords={sendRecords}
          qntty={contactsByActiveTab}
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        {isLoading ? (
          <EmailRecipientsSkeleton />
        ) : (
          <EmailRecipientsBody
            title={activeOption}
            contactsByActiveTab={contactsByActiveTab(activeOption)}
            emailId={emailId}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        )}
      </div>
    </div>
  );
}

export default EmailRecipients;
