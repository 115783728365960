import React, { useContext, useEffect, useState, useRef } from 'react';
import AdminUserPage from './ui/AdminUserPage/index.js';
import axios from 'axios';
import { useParams } from 'react-router-dom-v5-compat';
import createNotification from '../Settings/Utilities/CreateNotification.js';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall.js';
import useAdminNewsletterSubscriptions from './useAdminNewsletterSubscriptions.js';
import { useAdminAgencyUser } from './ui/UserSideNav.js';
import { AdminStore } from './AdminState.js';
import useCreateEmailAlerts from '../Settings/EmailAlerts/useCreateEmailAlerts.js';

const IndividualUserPage = () => {
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state ?? {};

  const [isLoading, setIsLoading] = useState(true);
  const [userInformation, setUserInformation] = useState({});
  const [clientsAlerts, setClientsAlerts] = useState([]);
  const [teamId, setTeamId] = useState(null);

  const [teamSubscriptions, setTeamSubscriptions] = useState({});
  const [onlySendRelevant, setOnlySendRelevant] = useState(false);

  const { newAgencyPlan } = useAdminAgencyUser();
  const { createEmailAlerts } = useCreateEmailAlerts();

  const params = useParams();
  const generalSource = useRef();

  const { generalApiCall } = useGeneralApiCall();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    generalSource.current = source;
    if (params.userId) {
      loadInitialInformation(source);
    }

    return () => {
      source.cancel('user information cancelled by the user');
      generalSource.current?.cancel();
    };
  }, []);

  const loadUserInformation = async (source) => {
    try {
      const pathname = `/api/admin/get-user?userId=${params.userId}`;
      const method = 'get';
      let results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
      return results;
    } catch (error) {}
  };

  const loadTeamSubscriptions = async (source) => {
    let method = 'get';
    let pathname = `/api/admin/get-active-subscriptions?organisationId=${parseInt(params.id)}`;
    let results = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    return results;
  };

  const loadInitialInformation = async (source) => {
    try {
      let userInformation = await loadUserInformation(source);
      let teamSubscriptions = await loadTeamSubscriptions(source);
      if (newAgencyPlan) {
        let agencyAlerts = await loadAllEmailAlerts(source);
        if (!!agencyAlerts) {
          setClientsAlerts(agencyAlerts);
        }
      }

      if (userInformation) {
        setUserInformation(userInformation);
        let upcomingEvents = userInformation.newsletterSubscriptions;
        for (let i = 0; i < upcomingEvents.length; i++) {
          let item = upcomingEvents[i];
          if (item.onlySendIfContainsRelevantContent) {
            setOnlySendRelevant(true);
            break;
          }
        }
        if (!!teamSubscriptions) {
          setTeamSubscriptions(teamSubscriptions[0]);
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  const loadAllEmailAlerts = async (source) => {
    const pathname = `/api/admin/get-alert-settings-all-teams?userId=${params.userId}`;
    const method = 'get';
    return await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
  };

  const createUpdateEmailAlert = async (emailAlertData, localTeamId = teamId) => {
    const { id, frequency, contentTypesToReceive, sendEmbargoedKeyUpdatesImmediately } = emailAlertData;
    const { activePlanAndUserState } = userInformation;
    const { enableHandpickedContent } = activePlanAndUserState;
    let pathname = `/api/admin/${id === '' ? 'create' : 'update'}-alert-setting`;
    let method = 'post';
    let requestProperties = {
      ...emailAlertData,
      sendEmbargoedKeyUpdatesImmediately:
        frequency !== 'Immediate' && enableHandpickedContent && contentTypesToReceive?.includes('KeyUpdates')
          ? sendEmbargoedKeyUpdatesImmediately
          : null,
      recipientUserId: userInformation.id,
      alertTypes:
        typeof emailAlertData.alertTypes === 'string' ? emailAlertData.alertTypes : emailAlertData.alertTypes.join(','),
    };
    if (id === '') {
      delete requestProperties.id;
    }

    if (newAgencyPlan && !!localTeamId) {
      requestProperties = {
        ...requestProperties,
        teamId,
      };
    }

    let result = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      requestSource: generalSource.current,
    });
    if (result) {
      //User information updating
      let newUserInformation = { ...userInformation };
      let { alertSettings } = newUserInformation;
      let position = id !== '' ? alertSettings.findIndex((item) => item.id === id) : alertSettings.length;
      alertSettings[position] = result;
      setUserInformation({ ...userInformation, alertSettings });
      //Client alerts updating
      if (newAgencyPlan && !!localTeamId) {
        let newClientAlerts = [...clientsAlerts];
        let clientPosition = newClientAlerts?.findIndex((item) => item?.teamId === localTeamId);
        let newAlertSettings = [...newClientAlerts[clientPosition]?.alertSettings];
        let alertPosition = id !== '' ? newAlertSettings.findIndex((item) => item.id === id) : newAlertSettings.length;
        newAlertSettings[alertPosition] = result;
        newClientAlerts[clientPosition] = {
          ...newClientAlerts[clientPosition],
          alertSettings: newAlertSettings,
        };
        setClientsAlerts(newClientAlerts);
      }
      createNotification('success', `Successfully ${id !== '' ? 'saved' : 'created'}`);
      return true;
    }
  };

  const deleteEmailAlert = async (id) => {
    let pathname = `/api/admin/delete-alert-setting/${id}`;
    let method = 'delete';
    let result = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource: generalSource.current,
    });
    if (result) {
      //User information updating
      let newUserInformation = { ...userInformation };
      let { alertSettings } = newUserInformation;
      let position = alertSettings.findIndex((item) => item.id === id);
      alertSettings.splice(position, 1);
      setUserInformation({ ...userInformation, alertSettings });
      //Client alerts updating
      if (newAgencyPlan && !!teamId) {
        let newClientAlerts = [...clientsAlerts];
        let clientPosition = newClientAlerts?.findIndex((item) => item?.teamId === teamId);
        let newAlertSettings = [...newClientAlerts[clientPosition]?.alertSettings];
        let alertPosition = newAlertSettings?.findIndex((item) => item.id === id);
        newAlertSettings.splice(alertPosition, 1);
        newClientAlerts[clientPosition] = {
          ...newClientAlerts[clientPosition],
          alertSettings: newAlertSettings,
        };
        setClientsAlerts(newClientAlerts);
      }
      createNotification('success', `Successfully deleted`);
      return true;
    }
  };

  const pauseEmailAlert = async (item, localTeamId) => {
    let value = item.paused;
    let itemInfo = { ...item, paused: !value };
    await createUpdateEmailAlert(itemInfo, localTeamId);
  };

  const compileUserInformation = () => {
    if (userInformation?.alertSettings) {
      return {
        ...userInformation,
        alertSettings: createEmailAlerts(
          userInformation.alertSettings,
          organisation?.teams?.flatMap((item) => item?.keywordLists)
        ),
      };
    }
  };

  const compileEmailAlerts = () => {
    let alerts = clientsAlerts?.map((item) => {
      let client = organisation?.teams?.find((team) => team?.id === item?.teamId);
      let newClient = { ...client };
      newClient = {
        ...newClient,
        myKeywordLists: newClient?.keywordLists,
      };
      return { ...item, adminClient: newClient };
    });
    return alerts;
  };

  const { adminNewsletterControl } = useAdminNewsletterSubscriptions({
    userInformation,
    setUserInformation,
    onlySendRelevant,
    setOnlySendRelevant,
  });

  const createClient = () => {
    let currentClient = organisation?.teams?.find((item) => item?.id === teamId);
    return {
      ...currentClient,
      teamName: currentClient?.name,
      teamId: currentClient?.id,
    };
  };

  return (
    <>
      {isLoading && <p className='pt-5'>Loading...</p>}
      {!isLoading && (
        <AdminUserPage
          teamTopics={organisation?.teams?.flatMap((item) => item?.keywordLists)}
          userInformation={compileUserInformation()}
          createUpdateEmailAlert={createUpdateEmailAlert}
          deleteEmailAlert={deleteEmailAlert}
          pauseEmailAlert={pauseEmailAlert}
          teamSubscriptions={teamSubscriptions}
          adminNewsletterControl={adminNewsletterControl}
          onlySendRelevant={onlySendRelevant}
          clients={organisation?.teams}
          clientsAlerts={compileEmailAlerts()}
          teamIdState={{ teamId, setTeamId }}
          client={teamId ? createClient() : null}
        />
      )}
    </>
  );
};

export default IndividualUserPage;
