import { useLocation, useParams } from 'react-router-dom-v5-compat';

const mainSectionParameter = (params) => {
  const { mainSection } = params;
  const referenceSection = mainSection === 'reference';
  const inboxSection = mainSection === 'monitor-inbox';
  const searchResultsSection = window.location.pathname.includes('/search-results');
  const mainSectionTitle = inboxSection || referenceSection ? `${inboxSection ? 'Inbox' : 'Reference'} - ` : '';
  return { referenceSection, inboxSection, searchResultsSection, mainSectionTitle };
};

const useMainSectionParameter = () => {
  const location = useLocation();
  const params = useParams();
  const { mainSection } = params;
  const referenceSection = mainSection === 'reference';
  const inboxSection = location.pathname.includes('/monitor-inbox');
  const searchResultsSection = location.pathname.includes('/search-results');
  const mainSectionTitle = inboxSection || referenceSection ? `${inboxSection ? 'Inbox' : 'Reference'} - ` : '';
  return { referenceSection, inboxSection, searchResultsSection, mainSectionTitle };
};

export { useMainSectionParameter, mainSectionParameter };
