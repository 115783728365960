import React, { useContext, useState } from 'react';
import { store } from '../../Store.js';
import createNotification from '../Utilities/CreateNotification.js';
import DeleteFunction from './DeleteFunction.js';
import { useNavigate } from 'react-router-dom-v5-compat';
import MicroModal from 'micromodal';
import useIsMobile from '../../Dashboard/utilities/useIsMobile.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const TopicDeleteButton = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team, keywordsLists, IsSavingKeywordList, activeSubscriptions } = state;
  const { enableHandpickedContent } = activeSubscriptions;
  const { myself, allMembers } = team ?? {};
  const { listPosition } = props;
  const currentKeywordCategory = keywordsLists[listPosition];
  const { id, isMyList, hasEmailAlertSettings, isHandpicked } = currentKeywordCategory;
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const deleteConditional =
    id !== '' && (myself?.role === 'Admin' || myself?.role === 'Owner' || isMyList) && !IsSavingKeywordList;

  const { getAccessToken } = useGetAccessToken();
  const navigate = useNavigate();
  const isSmallMobile = useIsMobile(480);
  const { topicOrClientWord } = useTopicOrClientWord();

  return (
    <>
      {deleteConditional && (
        <button
          tabIndex={-1}
          className='general-button topic-delete-button bordered-delete-topic-button mr-3 px-3'
          onClick={async () => {
            if (hasEmailAlertSettings && myself?.role === 'Member') {
              createNotification('danger', 'Unable to delete topic as there are alerts configured for it');
            } else if (enableHandpickedContent && isHandpicked) {
              createNotification('danger', 'Please contact our support team to delete this topic');
            } else {
              if (allMembers.length === 0) {
                setIsLoadingButton(true);
                await DeleteFunction({
                  state: state,
                  dispatch: dispatch,
                  getAccessToken: getAccessToken,
                  listPosition: listPosition,
                  navigate: navigate,
                });
                setIsLoadingButton(false);
              } else {
                MicroModal.show(`deletetopic-pop-up`, {
                  awaitCloseAnimation: true,
                  disableFocus: true,
                });
              }
            }
          }}
        >
          {isLoadingButton ? (
            <i className='fas fa-spinner fa-spin'></i>
          ) : (
            `Delete${isSmallMobile ? '' : ` ${topicOrClientWord}`}`
          )}
        </button>
      )}
    </>
  );
};

export default TopicDeleteButton;
