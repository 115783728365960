export const preventHistoryBack = function (e, el) {
  let delta = e.deltaX || e.wheelDeltaX;
  if (!delta) {
    return;
  }
  window.WebKitMediaKeyError /*detect safari*/ && (delta *= -1);
  if ((el.scrollLeft + el.offsetWidth === el.scrollWidth && delta > 0) || (el.scrollLeft === 0 && delta < 0)) {
    e.preventDefault();
  }
  if (el.scrollLeft + el.offsetWidth > el.scrollWidth && delta > 0) {
    e.preventDefault();
  }
};
