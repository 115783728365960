import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonConsultation = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div>
        <ul className='policy-list px-0 items-list'>
          {Array(9)
            .fill()
            .map((item, index) => (
              <SkeletonConsultationItem key={index} />
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

const SkeletonConsultationItem = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <li className={`my-2`}>
        <div className='row mx-0 p-3 align-items-center'>
          <div className='content-title pr-lg-5 pr-2 ml-2'>
            <div className='my-1'>
              <Skeleton height={19} width={`100%`} />
            </div>
            <div className='my-1'>
              <Skeleton height={19} width={`30%`} />
            </div>
          </div>
        </div>
      </li>
    </SkeletonTheme>
  );
};

export { SkeletonConsultationItem };
export default SkeletonConsultation;
