import React from 'react';
import { TwitterShareButton } from 'react-share';
import createNotification from '../../Settings/Utilities/CreateNotification';
const ShareParliamentaryContent = (props) => {
  const { title, url } = props;
  return (
    <div className='parliamentary-share-buttons'>
      <button
        className={`general-button position-relative share-parliamentary-content`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          let tempInput = document.createElement('input');
          tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
          tempInput.value = url;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand('copy');
          document.body.removeChild(tempInput);
          createNotification('success', `Link copied`);
        }}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-content={'Copy link to this extract'}
      >
        <span className={`icon-share-link`} />
      </button>
      <TwitterShareButton
        url={url}
        title={title}
        className='share-parliamentary-content'
        data-tooltip-id={'general-tooltip'}
        data-tooltip-content={'Share this extract on Twitter'}
      >
        <span className={`icon-twitter-x-circle`} />
      </TwitterShareButton>
    </div>
  );
};

export default ShareParliamentaryContent;
