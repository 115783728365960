import { useState } from 'react';
import Filters from '../../Filters';
import { useContext } from 'react';
import { store } from '../../../Store';
import useIsMobile from '../../utilities/useIsMobile';
import { createFiltersProperties } from '../../helpers/controlActiveFilters';
import EmailComponentNav from './components/EmailComponentNav';
import StakeholdersSubNav from './components/StakeholdersSubNav';
import CrmContactsSubNav from './components/CrmContactsSubNav';
import AddContactButton from '../CrmContacts/AddContactButton';
import CreateEmailLink from './components/CreateEmailLink';
import SubnavSearchBar from './components/SubnavSearchBar';
import EngagementStatisticsNav from './components/EngagementStatisticsNav';
import AddContactModal from '../CrmContacts/AddContactModal';
import EmailPerformanceNav from './components/EmailPerformanceNav';
import AddLobbyingMaterialButton from '../EngagementStatisticsDropdown/AddLobbyingMaterialButton';
import ClearFiltersButton from '../../SubNav/ui/ClearFiltersButton';
import useDetectActiveFilters from '../../SubNav/useDetectActiveFilters';
import InfluenceNotIncludedFeaturesPopup from '../CrmContacts/InfluenceNotIncludedFeaturesPopup';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { AIAssistantCreateButtons } from '../../../WritingAssistant/ui/MyContent';

const InfluenceCrmSubNav = () => {
  const [isStakeholdersSelected, setIsStakeholdersSelected] = useState(false);

  const location = useLocation();
  const params = useParams();
  const { crmEmailId } = params;

  const isMobile = useIsMobile(992);
  const isSmallMobile = useIsMobile(480);

  const { detectActiveFilters } = useDetectActiveFilters();
  const activeFilters = detectActiveFilters();

  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    readyKeywordLists,
    listDomainsVerified,
    selectedCrmContacts,
    showAddContactModal,
    crmContactsQtty,
    activePlan,
  } = state;

  const { enableCrm } = activePlan;
  const filtersProperties = createFiltersProperties();
  const innerPath = location.pathname.split('/')[2];

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const isContactsSelected = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;
  const contactsBarCondition = location.pathname.includes('/contacts') && isContactsSelected;
  const activeSubNavCondition = contactsBarCondition || (isStakeholdersSelected && innerPath?.includes('stakeholder'));

  const showHideAddContactModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddContactModal',
      value: !showAddContactModal,
    });
  };
  const getSubNavTitle = () => {
    if (location.pathname.includes('/policymakers')) {
      return 'Policymakers';
    } else {
      return 'Influence';
    }
  };

  //SUBNAV FOR CONTACTS AND EMAILS
  const renderSearchNav = () => {
    const renderActionButton = () => {
      if (location.pathname.includes('/contacts')) {
        return (
          <>
            {!crmContactsQtty && <AddContactButton asDropdown />}
            {!!crmContactsQtty && (activeFilters ? <ClearFiltersButton contactsSubNav /> : <AddContactButton />)}
          </>
        );
      } else if (location.pathname.includes('/emails')) {
        if (!!listDomainsVerified.verifiedDomains.length || !!listDomainsVerified.verifiedEmailAddresses.length) {
          return (
            <CreateEmailLink
              showToolTip={
                !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length
              }
            />
          );
        } else {
          return (
            <a
              href='http://support.policymogul.com/en/articles/7936585-sending-emails-from-policymogul'
              target='_blank'
              rel='noopener noreferrer'
            >
              <button className='setup-guide-emails-btn'>Setup guide</button>
            </a>
          );
        }
      } else if (location.pathname.includes('/my-lobbying-material')) {
        return <button className='influence-team-content-fake-btn' />;
      }
    };
    const searchBarCondition =
      (crmContactsQtty && location.pathname.includes('/contacts')) || !location.pathname.includes('/contacts');
    return (
      <>
        {!contactsBarCondition && (
          <div className='influence-subnav-wrapper'>
            {searchBarCondition && (
              <div className='mx-auto main-content-wrapper flex-centered align-items-center resize-elements-container stakeholder-sub-nav'>
                <SubnavSearchBar />
                {location.pathname.includes('/contacts') && (
                  <Filters section={'InfluenceContacts'} filtersProperties={filtersProperties} />
                )}
              </div>
            )}
            {renderActionButton()}
          </div>
        )}
        <div className={`crm-contacts-select-subnav ${!contactsBarCondition ? 'd-none' : ''}`}>
          <CrmContactsSubNav isSmallMobile={isSmallMobile} />
        </div>
      </>
    );
  };

  return (
    <>
      {showAddContactModal && (
        <>
          {enableCrm ? (
            <AddContactModal showHideAddContactModal={showHideAddContactModal} fromContactsSelect />
          ) : (
            <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={showHideAddContactModal} />
          )}
        </>
      )}
      <div
        className={`contact-crm-subnav inbox-items-bar monitor-items-bar trends-subnav-bar crm-contacts-subnav-bar ${
          activeSubNavCondition ? 'subnav-contacts-selected' : ''
        }`}
      >
        <div className='mx-auto main-content-wrapper flex-centered justify-content-between'>
          {innerPath === 'emails' && (crmEmailId || location.pathname.split('/')[3] === 'create') ? (
            <EmailComponentNav />
          ) : (
            <>
              <div className='side-left-element pl-md-5 pl-3 d-none d-lg-block'>
                <p className='mb-0 title-h4 nowrap-item influence-subnav-tab-title'>
                  {contactsBarCondition ? '' : getSubNavTitle()}
                </p>
              </div>

              <div
                className={`main-content flex-centered  filters-general-container justify-content-between pr-xl-5 px-md-4 px-3`}
              >
                {(innerPath === 'contacts' || innerPath === 'emails' || innerPath === 'my-lobbying-material') &&
                  renderSearchNav()}
                {(innerPath?.includes('stakeholder') || location.pathname.includes('/policymakers')) && (
                  <StakeholdersSubNav
                    isMobile={isMobile}
                    isSmallMobile={isSmallMobile}
                    readyKeywordLists={readyKeywordLists}
                    filtersProperties={filtersProperties}
                    setIsStakeholdersSelected={setIsStakeholdersSelected}
                  />
                )}
                {innerPath?.includes('engagement-statistics') && <EngagementStatisticsNav />}
                {innerPath?.includes('email-performance') && <EmailPerformanceNav />}
                {innerPath?.includes('lobbying-material') && <AddLobbyingMaterialButton influenceSubnav />}
                {innerPath?.includes('ai-assistant') && (
                  <div className='influence-ai-assistant-container'>
                    <AIAssistantCreateButtons influencePage={true} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InfluenceCrmSubNav;
