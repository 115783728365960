export default function getUrlParam(parameter) {
  const urlParams = new URLSearchParams(window.location.search);
  let urlparameter;
  if (urlParams.get(parameter)) {
    urlparameter = urlParams.get(parameter);
  }
  return urlparameter;
}

function getUrlParamRaw(parameter, defaultvalue) {
  //AE:This function is different from the getUrlParam because it returns the raw value with encoded values supporting special characters. For now it's only neessary with the search or with the or parameter.
  const urlParams = new URLSearchParams(window.location.search);
  let urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }

  let searchParameterFromSearchParams = urlParams.get(parameter);
  if (searchParameterFromSearchParams) {
    if (searchParameterFromSearchParams.includes('%')) {
      return urlparameter?.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25');
    }
    if (searchParameterFromSearchParams.includes('&')) {
      return urlparameter?.replace(/&(?![0-9][0-9a-fA-F]+)/g, '%26');
    }
    return searchParameterFromSearchParams;
  }
  return urlparameter;
}

function getUrlVars() {
  var vars = {};
  // eslint-disable-next-line
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value.split('#')[0];
  });
  return vars;
}

export { getUrlParamRaw };
