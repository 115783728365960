import React from 'react';
import AttachmentDropzone from './AttachtmenDropzone';
import DescriptionTinyEditor from './DescriptionTinyEditor';

const Description = (props) => {
  const { sectionValues, createdFromTheApi } = props;
  const { fields } = sectionValues;

  return (
    <>
      {createdFromTheApi && (
        <div className='bg-light-red influence-creation_section'>
          <h4 className='title-h5-bold'>Disclaimer</h4>
          <p className='d-flex align-items-center mb-0'>
            The text below was written by PolicyMogul’s AI assistant. This feature is in Beta and output may contain
            factual errors. You can use it verbatim, but we suggest you read it carefully and consider making edits to
            ensure it is accurate and reflects your views.
          </p>
        </div>
      )}
      <div className='influence-creation_section influence-description'>
        {fields.map((item, index) => {
          const { title, value, setValue, name, required, subtext } = item;
          return (
            <div className='description-value' key={`description-value-${index}`}>
              <h3 className='title-h5-m-bold '>
                {title}
                {!required && <span> (optional)</span>}
              </h3>
              <p className='paragraph-p2 main-light-text mb-3'>{subtext}</p>
              {name !== 'attachments' && (
                <>
                  <DescriptionTinyEditor name={name} value={value} setValue={setValue} index={index} />
                </>
              )}
              {name === 'attachments' && <AttachmentDropzone value={value} setValue={setValue} />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Description;
