import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import getUrlParam from '../utilities/getUrlParam';

const TotalContributionsWidget = (props) => {
  const { hits, reachedTheEnd } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const keyPressFunction = (e) => {
    let type;
    if (e.code === 'ArrowRight') {
      type = 'next';
    } else if (e.code === 'ArrowLeft') {
      type = 'prev';
    }
    if (type && !disabledItem({ type })) {
      navigateFunction({ type });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction);
    return () => {
      document.removeEventListener('keydown', keyPressFunction);
    };
  }, [location.hash]);

  const itemPosition = () => {
    let position;
    let currentId = location.hash.replace('#contribution-', '');
    if (currentId !== '') {
      position = hits.findIndex((item) => item.contributionId === currentId);
    } else {
      position = -1;
    }
    return position;
  };

  const widgetItemPosition = () => {
    let position = itemPosition();
    if (reachedTheEnd && position + 1 < hits.length) {
      return `${position + 1}-${hits.length}`;
    } else if (position === undefined || position < 0) {
      return null;
    } else {
      return position + 1;
    }
  };

  const disabledItem = ({ type }) => {
    let position = itemPosition();
    if (position !== undefined) {
      if (type === 'next') {
        return position >= hits.length - 1;
      }
      if (type === 'prev') {
        return position <= 0;
      }
    }
    return true;
  };

  const navigateFunction = ({ type }) => {
    let position = itemPosition();
    let newPosition = type === 'next' ? (position += 1) : (position -= 1);
    navigate(`${location.pathname}${location.search}#contribution-${hits[newPosition].contributionId}`);
  };

  const searchParam = getUrlParam('search');
  const topicParam = getUrlParam('topic-id');

  return (
    <div className='flex-centered'>
      <div className='pl-3 py-3 flex-grow-1 overflow-hidden'>
        <p className='mb-0'>
          {widgetItemPosition() && hits.length > 1 ? `${widgetItemPosition()} of ` : ''}
          {hits.length} {searchParam || (topicParam && topicParam !== 'none') ? 'relevant ' : ''} contribution
          {hits.length === 1 ? '' : 's'}
        </p>
      </div>
      {(hits.length > 1 || (hits.length === 1 && !location.hash.includes('contribution-'))) && (
        <div className='flex-centered total-contributions-buttons'>
          {widgetItemPosition() && (
            <button
              className={`general-button total-contributions-prev`}
              disabled={disabledItem({ type: 'prev' })}
              onClick={() => {
                navigateFunction({ type: 'prev' });
              }}
            >
              <span className='icon-arrow-back'></span>
            </button>
          )}

          <button
            className={`general-button total-contributions-next`}
            disabled={disabledItem({ type: 'next' }) || reachedTheEnd}
            onClick={() => {
              navigateFunction({ type: 'next' });
            }}
          >
            <span className='icon-arrow-next'></span>
          </button>
        </div>
      )}
    </div>
  );
};

export default TotalContributionsWidget;
