import React, { useContext, useEffect, useRef, useState } from 'react';
import DashboardSidebar from './sidebar';
import SkeletonKeyUpdates from './key_updates/SkeletonKeyUpdates';
import { useMainSectionParameter } from './helpers/useMainSectionParameter';
import StakeHolders from './StakeHolders';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../Store';
import {
  createFiltersProperties,
  sectionName,
  useCreateSectionTitle,
  createSectionName,
} from './helpers/controlActiveFilters';
import Filters from './Filters';
import useCompileFiltersFunction from './Filters/useCompileFilters';
import FiltersData from './Filters/FiltersData';
import SectionTitle from './components/SectionTitle';
import TotalResults from './components/TotalResults';
import ClearFiltersButton from './SubNav/ui/ClearFiltersButton';
import getUrlParam from './utilities/getUrlParam';
import useDetectActiveFilters from './SubNav/useDetectActiveFilters';
import useIsMobile from './utilities/useIsMobile';
import useTopicOrClientWord from './hooks/useTopicOrClientWord';
import ListControl from './ListControl';
import { Navigate, Route, Routes, useParams, Link, useLocation } from 'react-router-dom-v5-compat';

const ListRoutes = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeResults, search, activePlan, contentResults } = state;
  const { searchState, list } = search;
  const { enableReferenceSection, enableTwitter } = activePlan;
  const params = useParams();
  const { referenceSection, searchResultsSection } = useMainSectionParameter();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { detectActiveFilters } = useDetectActiveFilters();
  const { isAuthenticated } = useAuth0();

  const changesDashboard = useRef();
  changesDashboard.current = () => {
    setIsLoading(true);
    let activePlanItems = Object.keys(activePlan);
    if ((activePlanItems.length > 0 && searchState) || !isAuthenticated) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    changesDashboard.current();
  }, [activeResults, activePlan, searchState, isAuthenticated]);

  const filterProperties = createFiltersProperties();
  const { inboxSection } = useMainSectionParameter();
  const { activeResultsConditional } = useCompileFiltersFunction();

  const createFiltersCondition = () => {
    const filters = FiltersData().filter(
      (filter) =>
        filter.pages.includes(sectionName()) &&
        activeResultsConditional(filter, FiltersData()) &&
        !filter.hidden &&
        !filter.excludeFromPopup
    );
    if (inboxSection || (searchResultsSection && location.pathname.includes('upcoming-events'))) {
      let section = contentResults[activeResults][createSectionName()];
      return detectActiveFilters() ? true : section?.hits?.length > 0 && filters.length > 0;
    } else {
      return filters.length > 0 || searchResultsSection;
    }
  };

  const referenceFiltersConditional = createFiltersCondition();
  const { createSectionTitle } = useCreateSectionTitle();
  const hideCalendarTitle =
    location.pathname.includes('/calendar') && !getUrlParam('search') && (searchResultsSection || referenceSection);
  const isMobile = useIsMobile();
  const calendarCondition = inboxSection && location.pathname.includes('upcoming-events');
  const { agencyUser } = useTopicOrClientWord();

  return (
    <div className={`row mx-auto main-content-wrapper`}>
      <div className={`side-left-element d-none d-lg-block`}>
        <DashboardSidebar />
      </div>
      <div className={`main-content pr-xl-5 px-md-4 px-3`}>
        {isLoading && (
          <>
            <SkeletonKeyUpdates />
          </>
        )}
        {!isLoading && (
          <div className='content-lists-container'>
            {!hideCalendarTitle && (
              <div
                className={`justify-content-between flex-centered section-maintitle-container ${list === 'noListsCreated' ? 'section-maintitle-nolists' : ''} flex-wrap`}
              >
                <SectionTitle sectionName={createSectionTitle()} />
                <div className={`${referenceFiltersConditional ? 'outside-filters-container-width' : ''} pl-1`}>
                  <div className={`flex-centered justify-content-${inboxSection ? 'end' : 'between'}`}>
                    {((referenceFiltersConditional && enableReferenceSection) || (inboxSection && enableTwitter)) && (
                      <ClearFiltersButton />
                    )}
                    {calendarCondition && (
                      <p className='mb-0 main-subtle-text total-results-container d-none d-lg-block'>
                        These events are tailored based on your {agencyUser ? 'clients' : 'interests'}.{' '}
                        <Link to={'/calendar'} className='simple-link-button'>
                          See the full calendar
                        </Link>
                      </p>
                    )}
                    {(!inboxSection || !detectActiveFilters()) && !calendarCondition ? (
                      <div className='ml-auto mr-0'>
                        <TotalResults />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className='row mx-0'>
              <div
                className={`flex-grow-1 ${referenceFiltersConditional ? 'reference-main-content' : ''}`}
                style={{ flex: 1 }}
              >
                <Routes>
                  <Route path='stakeholders' element={<StakeHolders />} />
                  <Route
                    path={`tweets`}
                    element={
                      process.env.REACT_APP_ENABLE_TWITTER === 'false' ? (
                        <Navigate to={`/${params['mainSection']}/key-updates`} />
                      ) : (
                        <ListControl />
                      )
                    }
                  />
                  <Route path=':page/*' element={<ListControl />} />
                </Routes>
              </div>
              {referenceFiltersConditional && !isMobile && (
                <div className='ml-4 outside-filters-container'>
                  <Filters {...filterProperties} useOutside />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListRoutes;
