const useReportMutationCallback = (props) => {
  const { scrollBarRef, layout, mutationContainer, targetRef } = props;

  const callback = (mutations) => {
    mutations.forEach((mutation) => {
      const elementAdded = Array.from(mutation.addedNodes).filter((element) => {
        if (element.classList) {
          return element.classList.value.includes('awsui_grid');
        } else {
          return false;
        }
      });

      if (elementAdded?.length > 0 && layout?.length === 0) {
        let newNodes = elementAdded[0].children;
        if (newNodes?.length === 12) {
          let lastEl = newNodes[newNodes?.length - 1];
          for (let i = 0; i <= 7; i++) {
            let newEl = lastEl.cloneNode(true);
            let num = i + 1;
            //AE: pending to implement, newEl.style.gridArea = `${baseNumber + Math.ceil(num / 4)} / ${num - (4 * (Math.ceil(num / 4) - 1))} / span 1 / span 1`;
            newEl.style.gridArea = `${num <= 4 ? 4 : 5} / ${num <= 4 ? num : num - 4} / span 1 / span 1`;
            elementAdded[0].appendChild(newEl);
          }
        }
      }
      const body = document.getElementsByTagName('body')[0];
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'style' &&
        body.classList.value.includes('awsui_show-resize-cursor') &&
        scrollBarRef.current &&
        mutation.target?.classList?.value.includes('awsui_resized')
      ) {
        const { view: wrapperElement, viewScrollTop: scrollTop, getScrollHeight } = scrollBarRef.current;
        const { clientHeight } = wrapperElement;
        const scrollHeight = getScrollHeight();

        let element = mutation.target;
        let getStyles = element.getAttribute(mutation.attributeName);
        let regex = /([\w-]*)\s*:\s*([^;]*)/g;
        let match,
          properties = {};
        // eslint-disable-next-line
        while ((match = regex.exec(getStyles))) {
          properties[match[1]] = match[2].trim();
        }
        let top = element.getBoundingClientRect()?.top - wrapperElement.getBoundingClientRect()?.top;
        let height = parseInt(properties?.height?.replace('px', ''));
        let totalValue = top + height;
        let heightCondition = !!height ? totalValue >= clientHeight : false;
        let topCondition = !!height ? totalValue <= 0 : false;
        if (heightCondition) {
          if (scrollHeight <= mutationContainer.current?.getBoundingClientRect()?.height && scrollBarRef?.current) {
            scrollBarRef?.current?.scrollTop(scrollTop + (totalValue - clientHeight));
          }
        }

        if (topCondition && scrollBarRef?.current) {
          scrollBarRef?.current?.scrollTop(scrollTop + totalValue);
        }
      }

      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'style' &&
        body.classList.value.includes('awsui_show-grab-cursor') &&
        scrollBarRef.current &&
        mutation.target?.classList?.value.includes('awsui_dragged')
      ) {
        const { scrollTop } = scrollBarRef.current.getValues();
        let element = mutation.target;
        let getStyles = element.getAttribute(mutation.attributeName);
        let regex = /([\w-]*)\s*:\s*([^;]*)/g;
        let match,
          properties = {};
        // eslint-disable-next-line
        while ((match = regex.exec(getStyles))) {
          properties[match[1]] = match[2].trim();
        }
        let top = parseInt(properties?.top?.replace('px', ''));
        if (top < scrollTop && top < targetRef?.current?.getBoundingClientRect()?.top + 32) {
          // upscroll code
          scrollBarRef.current.scrollTop(top);
        }
      }
    });
  };

  return { callback };
};

export default useReportMutationCallback;
