import { useRef, useState } from 'react';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import useOutsideElement from '../../../utilities/useOutsideElement';
import { colours } from '../../../Analytics/useCallOverallSentiment';
import useIsMobile from '../../../utilities/useIsMobile';

const StakeholderSentimentBar = (props) => {
  const { ranking, filterForSentimentFunction, filterSentiment, localTopic } = props;
  const { aggregatedSentiment } = ranking ?? {};
  const { topicOrClientWord } = useTopicOrClientWord();
  const options = ['Positive', 'Negative', 'Neutral', 'Mixed'];
  const [showOptions, setShowOptions] = useState(false);

  const filtersElement = useRef(null);
  const hideFilters = () => {
    setShowOptions(false);
  };

  useOutsideElement(filtersElement, hideFilters);
  const noTopicsCondition = !localTopic || localTopic?.id === null;
  const sentimentLowerCase = !!aggregatedSentiment ? aggregatedSentiment?.toLowerCase() : 'neutral';
  const isMobile = useIsMobile(526);

  return (
    <div
      className='px-4 px-xl-5 border-top  py-2 d-flex align-items-center justify-content-between sentiment-filter-bar'
      style={{
        backgroundColor: `rgba(${colours[aggregatedSentiment ? aggregatedSentiment.toLowerCase() : 'neutral']}, 0.1)`,
      }}
    >
      {noTopicsCondition ? (
        <p className={`mb-0 ${isMobile ? 'pr-2' : ''}`}>
          Select a topic using the search bar above to reveal stakeholder sentiment analysis for that topic
        </p>
      ) : (
        <>
          <p className='mb-0 flex-centered'>
            <span className='d-flex align-items-start'>
              <span
                className={`sentiment-bar-dot sentiment-dot mr-2 d-inline-block sentiment-dot-${sentimentLowerCase}`}
                style={{ verticalAlign: 'middle', marginTop: '7px' }}
              />
              <span>
                Sentiment analysis for this {topicOrClientWord}:{' '}
                <span className='font-weight-bold'>{aggregatedSentiment ?? 'Neutral'}</span>{' '}
              </span>
            </span>
            <span
              className='icon-question-circle d-none d-lg-inline-block'
              data-tooltip-html={`<p class="mb-2 px-3 title-h5-m-bold border-bottom py-3"><span class="sentiment-dot sentiment-title-dot mr-2 d-inline-block sentiment-dot-${sentimentLowerCase}"></span> ${aggregatedSentiment ?? 'Unknown'}</p><p class='px-3 mb-0 title-h5'>This stakeholder has expressed an overall ${sentimentLowerCase} value sentiment for this topic based on language analysis.</p><a class='mt-2 title-h5 simple-link-button px-3 pb-3 d-block' href='http://support.policymogul.com/en/articles/8041604-sentiment-analysis' target='_blank' rel="noopener noreferrer">Learn more`}
              data-tooltip-id={'clickeable-tooltip'}
              data-tooltip-class-name={'contributions-tooltip sentiment-dot-tooltip sentiment-max-tooltip'}
              style={{ verticalAlign: 'middle', marginTop: '0px', marginLeft: '6px' }}
            />
          </p>

          <div className='position-relative' ref={filtersElement}>
            <button
              className={`sentiment-bar-filter-button ${filterSentiment ? 'sentiment-bar-filter-button-active' : ''} bg-main-white border general-button rounded-button py-1 paragraph-p3 hovered-button ${showOptions ? 'bg-blue-hovered' : ''} flex-centered nowrap-item`}
              onClick={() => setShowOptions(!showOptions)}
            >
              <span className='sentiment-bar-text'>
                {filterSentiment && isMobile ? (
                  <span
                    className={`mr-2 d-inline-block sentiment-dot sentiment-dot-${filterSentiment.toLowerCase()}`}
                  />
                ) : (
                  <span className={`icon-filters`} />
                )}{' '}
                <span className={`${filterSentiment ? '' : 'd-none d-sm-inline-block dropdown-item-element-9'}`}>
                  {filterSentiment && (
                    <>
                      <span className={`d-none d-sm-inline-block ml-sm-1`}>{filterSentiment}</span>{' '}
                      <span className='d-none d-xl-inline-block'>contributions</span>
                    </>
                  )}
                </span>
              </span>
              {filterSentiment && (
                <span
                  className='general-button action-state-hover text-center sentiment-close-button'
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    filterForSentimentFunction({ sentiment: null });
                  }}
                >
                  <span className='icon-close paragraph-p6' />
                </span>
              )}
            </button>
            {showOptions && (
              <div className={`box filters-container px-0 pt-0`} style={{ width: '250px' }}>
                <p className='border-bottom py-3 px-4 mb-0 font-weight-bold'>
                  Filter contributions by stakeholder sentiment
                </p>
                <div className='py-1'>
                  {options.map((option) => {
                    return (
                      <button
                        key={`filter-${option}`}
                        className='general-button text-left px-4 py-2 list-action-button d-block w-100'
                        onClick={() => {
                          setShowOptions(false);
                          filterForSentimentFunction({ sentiment: option });
                        }}
                      >
                        <span className={`sentiment-dot sentiment-dot-${option.toLowerCase()} d-inline-block mr-2`} />{' '}
                        {option} contributions
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StakeholderSentimentBar;
