import axios from 'axios';
import { net_api_url } from '../../Store';
import ChangeSearchQuery from './changeSearchQuery';
import getSearchWithinHash from './getSearchWithinHash';
const callHightlightContentAPI = async (props) => {
  const [objectId, objectType, query, andQueries, source] = props;
  try {
    let requestHeaders;
    if (source) {
      requestHeaders = {
        cancelToken: source.token,
      };
    }
    let contentHightlighted = await axios.post(
      `${net_api_url}/api/search/highlight-content`,
      {
        objectId: objectId,
        objectType: objectType,
        query: query,
        andQueries: andQueries,
        searchWithinQuery: getSearchWithinHash() ? ChangeSearchQuery(getSearchWithinHash()) : '',
      },
      requestHeaders
    );
    return contentHightlighted.data;
  } catch (error) {
    console.error(error);
  }
};

export default callHightlightContentAPI;
