import React, { useContext } from 'react';
import AdminNotes from './ui/AdminNotes';
import { AdminStore } from './AdminState';

const AdminNotesPage = () => {
  const adminState = useContext(AdminStore);
  const { state, dispatch } = adminState;
  const { organisation } = state;
  return (
    <>
      <AdminNotes
        team={organisation}
        setTeam={(team) => dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: team })}
      />
    </>
  );
};

export default AdminNotesPage;
