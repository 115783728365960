import { useContext } from 'react';
import { store } from '../../Store';

const parliamentarianUsers = (code) => {
  if (code) {
    return code.startsWith('MogulFree');
  }
};

const consultantAgenciesMembers = (code) => {
  if (code) {
    return code.startsWith('Agency');
  }
};

const membershipMembers = (code) => {
  if (code) {
    return code.startsWith('MembershipOrganisation');
  }
};

const viewCode = ({ code, location }) => {
  const start =
    location?.search === '' || (location?.search.match(/=/g)?.length === 1 && location?.search?.includes('view='))
      ? '?'
      : '&';
  if (parliamentarianUsers(code)) {
    return `${start}view=p`;
  } else if (code && consultantAgenciesMembers(code)) {
    return `${start}view=c`;
  } else if (membershipMembers(code)) {
    return `${start}view=m`;
  } else {
    return '';
  }
};

const useTypeOfUsers = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { code } = activePlan;
  const isParliamentarian = parliamentarianUsers(code);
  return { isParliamentarian };
};

const campaignersUsers = ({ name }) => {
  return name === 'Campaigner';
};

export { consultantAgenciesMembers, viewCode, membershipMembers, useTypeOfUsers, campaignersUsers };
export default parliamentarianUsers;
