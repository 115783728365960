import createNotification from '../../Settings/Utilities/CreateNotification';
import useTopicOrClientWord from './useTopicOrClientWord';
import Axios from 'axios';
import { useEffect, useRef } from 'react';
import { net_api_url } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import useGetAccessToken from '../apiCalls/useGetAccessToken';

const useHiddenItemsFunctions = () => {
  const { transformTopicOrClientWord } = useTopicOrClientWord();
  const { generalApiCall } = useGeneralApiCall();

  const CancelToken = Axios.CancelToken;
  const axiosCancelToken = useRef(null);
  axiosCancelToken.current = CancelToken.source();

  useEffect(() => {
    return () => {
      axiosCancelToken.current.cancel('Topic canceled by the user.');
    };
  }, []);

  const { getAccessToken } = useGetAccessToken();

  const updateHiddenItemState = async ({ state, userId, listId, adminPage = false, showSuccsessMessage }) => {
    let pathname = adminPage ? '/api/admin/update-keyword-list-state' : '/api/keyword-list/update-keyword-list-state';
    let requestProperties = {
      userId,
      keywordListId: listId,
      state,
    };
    let successMessage = `${transformTopicOrClientWord({
      uppercase: true,
      plural: false,
    })} successfully ${state.toLowerCase()}`;

    try {
      let token = await getAccessToken();
      let result = await Axios.post(`${net_api_url}${pathname}`, requestProperties, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: axiosCancelToken.current.token,
      });
      if (result.status === 200 && showSuccsessMessage) {
        createNotification('success', successMessage);
      }
      return result.status === 200;
    } catch (error) {
      console.error(error);
    }
  };

  const getHiddenItemsList = async ({ userId, adminPage, source }) => {
    let pathname = adminPage
      ? `/api/admin/get-user-keyword-list-states/${userId}`
      : `/api/keyword-list/user-keyword-list-states/${userId}`;

    try {
      let token = await getAccessToken();
      let result = await Axios.get(`${net_api_url}${pathname}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source?.token ?? axiosCancelToken.current.token,
      });
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllKeywordLists = async ({ teamId, source }) => {
    let pathname = `/api/admin/get-keyword-lists?teamId=${parseInt(teamId)}&includeHidden=true`;

    try {
      let token = await getAccessToken();
      let result = await Axios.get(`${net_api_url}${pathname}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source?.token ?? axiosCancelToken.current.token,
      });
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateHiddenClientState = async ({ state, userId, teamId, adminPage = false, showSuccsessMessage, source }) => {
    try {
      const method = 'post';
      const pathname = adminPage ? '/api/admin/update-team-state' : '/api/team/update-team-state';
      const requestProperties = {
        userId,
        teamId: teamId,
        state,
      };
      let successMessage = `Client successfully ${state.toLowerCase()}`;
      const result = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
        requestSource: source,
        returnCompleteRequest: true,
      });
      if (result.status === 200 && showSuccsessMessage) {
        createNotification('success', successMessage);
      }
      return result.status === 200;
    } catch (error) {}
  };

  const getHiddenClientList = async ({ userId, adminPage, source }) => {
    try {
      const method = 'get';
      const pathname = adminPage ? `/api/admin/get-user-team-states/${userId}` : `/api/team/user-team-states/${userId}`;
      let result = await generalApiCall({
        method,
        pathname,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
      });
      if (!!result) {
        return result;
      }
    } catch (error) {}
  };

  return {
    getHiddenItemsList,
    updateHiddenItemState,
    getAllKeywordLists,
    updateHiddenClientState,
    getHiddenClientList,
  };
};

export default useHiddenItemsFunctions;
