import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

function StakeholderSelectBarSkeleton() {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div
        className='stakeholder-master-checkbox-input-wrapper'
        style={{
          background: 'rgba(242, 242, 242, 0.4)',
          border: '1px solid rgba(212,212,212,0.5)',
        }}
      >
        <div className={`stakeholder-master-checkbox-input`}>
          <input />
          <label
            style={{
              background: '#f2f2f2',
              border: '1px solid rgba(212,212,212,0.5)',
            }}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default StakeholderSelectBarSkeleton;
