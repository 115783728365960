import createNotification from '../../Settings/Utilities/CreateNotification';
import generalApiCall from '../apiCalls/generalApiCall';

const DeleteInfluenceItemFunction = async (props) => {
  const { itemInformation, params, getAccessToken, source, navigate } = props;
  const { page } = params;
  const pathname = `/api/user-generated-content/${page === 'policy-asks' ? 'policy-ask' : page}/${parseInt(itemInformation.id)}`;
  const method = 'delete';
  let token = await getAccessToken();
  const requestHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  };
  let results = await generalApiCall({ method, pathname, requestHeaders });
  if (!!results) {
    createNotification(
      'success',
      `Your ${page === 'policy-asks' ? 'policy ask' : page.replace('-', ' ')} has been deleted successfully`
    );
    return navigate(`/influence/lobbying-material`);
  }
};

export default DeleteInfluenceItemFunction;
