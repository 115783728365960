const calculateEmbedListHeight = (
  keywordsLists,
  value,
  notAddSpace,
  customMaxHeightValue = 140,
  customReferenceValue = 42
) => {
  let referenceValue = customReferenceValue;
  let maxHeightValue = customMaxHeightValue;
  let height = value ?? 60;
  keywordsLists.forEach((item, index) => {
    height += referenceValue * index;
  });
  if (height > maxHeightValue) {
    return maxHeightValue + (notAddSpace ? 0 : 26);
  } else {
    return height;
  }
};

export default calculateEmbedListHeight;
