import { Portal } from 'react-portal';
import Popup from '../../Common/PopUp';
import WebsiteFeedMain from './WebsiteFeedMain';
import { useContext, useState } from 'react';
import { store } from '../../Store';
import WebsiteFeaturePopupContent from './WebsiteFeaturePopup';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';

const EmbedWebsiteButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, activePlan, readyKeywordLists } = state;
  const { enableEmbedWidgets, subscriptionStatus } = activePlan;

  const { settingsPage, listToUse } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [showFeaturePopup, setShowFeaturePopup] = useState(false);

  const { newAgencyPlan } = useTopicOrClientWord();

  const popupProperties = {
    identifier: 'website-feeds-popup',
    awaitOpenAnimation: false,
  };

  return (
    <>
      {settingsPage ? (
        <button
          className='action-button general-button px-4 py-2'
          onClick={() => {
            if (enableEmbedWidgets) {
              setShowPopup(true);
            } else {
              setShowFeaturePopup(true);
            }
          }}
        >
          Embed a real-time feed{' '}
          <span className='d-none d-lg-inline-block'>on {newAgencyPlan ? 'any' : 'your'} website</span>
        </button>
      ) : (
        <>
          {readyKeywordLists &&
            subscriptionStatus !== 'SubscribedOverLimit' &&
            subscriptionStatus !== 'OnFreePlanOverLimit' &&
            subscriptionStatus !== 'TrialEnded' &&
            keywordsLists.length > 0 && (
              <button
                className='general-button action-state-hover border code-button ml-2 ml-lg-0  main-subtle-text'
                data-tooltip-id={'general-tooltip'}
                data-tooltip-place={'bottom'}
                onClick={(e) => {
                  if (enableEmbedWidgets) {
                    setShowPopup(true);
                  } else {
                    setShowFeaturePopup(true);
                  }
                }}
              >
                <span className='icon-code-thin paragraph-p4' />{' '}
                <span className='d-none d-lg-inline' style={{ marginLeft: '6px' }}>
                  {' '}
                  Embed feed {!enableEmbedWidgets && <span className='icon-padlock ml-1 paragraph-p3' />}
                </span>
              </button>
            )}
        </>
      )}
      {showPopup && (
        <WebsitePopup
          setShowPopup={setShowPopup}
          additionalClass='website-feeds-popup-container'
          maxWidth={1050}
          listToUse={listToUse}
          {...popupProperties}
        />
      )}
      {showFeaturePopup && (
        <WebsiteFeaturePopup
          setShowPopup={setShowFeaturePopup}
          additionalClass={'website-feature-feeds-popup-container'}
          maxWidth={900}
          {...popupProperties}
        />
      )}
    </>
  );
};

const WebsitePopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <WebsiteFeedMain {...props} />
      </Popup>
    </Portal>
  );
};

const WebsiteFeaturePopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <WebsiteFeaturePopupContent {...props} />
      </Popup>
    </Portal>
  );
};

export default EmbedWebsiteButton;
