const CommonsCategories = [
  'Business in the House of Commons',
  'Select committee meetings (Commons)',
  'Business in Westminster Hall',
];
const LordsCategories = [
  'Business in the House of Lords',
  'Select committee meetings (Lords)',
  'Business in Grand Committee in the House of Lords',
];
const OrderCategories = (categories) => {
  const commonCategoriesToShow = [];
  const lordsCategoriesToShow = [];
  const announcementsCategoriesToShow = [];

  CommonsCategories.forEach((item) => {
    if (categories.includes(item)) {
      commonCategoriesToShow.push(item);
    }
  });
  LordsCategories.forEach((item) => {
    if (categories.includes(item)) {
      lordsCategoriesToShow.push(item);
    }
  });
  categories.forEach((item) => {
    if (!LordsCategories.includes(item) && !CommonsCategories.includes(item)) {
      announcementsCategoriesToShow.push(item);
    }
  });
  return [commonCategoriesToShow, lordsCategoriesToShow, announcementsCategoriesToShow];
};

export { OrderCategories, CommonsCategories, LordsCategories };
