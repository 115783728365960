import { useAuth0 } from '@auth0/auth0-react';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';

const useGetMatchingKeywordsListsForContent = () => {
  const { generalApiCall } = useGeneralApiCall();
  const { isAuthenticated } = useAuth0();

  const getMatchingKeywordsListsAndFeedback = async ({ objectId }) => {
    if (isAuthenticated) {
      const matchingKeywordLists = await getMatchingKeywordsLists({ objectId });
      if (matchingKeywordLists?.length > 0) {
        const keywordsFromLists = matchingKeywordLists?.reduce((accumulator, currentValue) => {
          let terms = currentValue?.explanation?.searchTermMatches;
          Object.keys(terms)?.forEach((item) => {
            if (!accumulator.includes(item)) {
              accumulator.push(item);
            }
          });
          return accumulator;
        }, []);
        const feedback = await getFeedbackForItem({ objectId });
        return {
          feedback,
          keywordsFromLists,
          keywordsListsIds: matchingKeywordLists.map((item) => item?.matchingKeywordList?.id),
        };
      }
    }
    return {};
  };

  const getMatchingKeywordsLists = ({ objectId }) => {
    const method = 'get';
    const pathname = `/api/feed/matching-keyword-lists?objectId=${objectId}`;
    return generalApiCall({ method, pathname, needsAuthentication: true });
  };

  const getFeedbackForItem = ({ objectId }) => {
    const method = 'get';
    const pathname = `/api/feed/item-feedback?objectId=${objectId}`;
    return generalApiCall({ method, pathname, needsAuthentication: true });
  };

  return { getMatchingKeywordsListsAndFeedback };
};

export default useGetMatchingKeywordsListsForContent;
