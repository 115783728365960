import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import TopicPicker from '../../Common/TopicPicker';
import { store } from '../../Store';
import useResetReference from '../SubNav/useResetReference';
import getUrlParam from '../utilities/getUrlParam';
import ListControl from '../ListControl';
import SkeletonKeyUpdates from '../key_updates/SkeletonKeyUpdates';

const Campaigns = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, search } = state;

  const navigate = useNavigate();
  const location = useLocation();
  const [mainSearchValue, setMainSearchValue] = useState(
    !!getUrlParam('search') ? decodeURIComponent(getUrlParam('search')) : ''
  );
  const { resetReference } = useResetReference();
  const [isLoading, setIsLoading] = useState(true);
  const topicIdQuery = getUrlParam('topic-id');

  const searchFunction = ({ freeSearch, topic }) => {
    let queryParameters = new URLSearchParams(location.search);
    if (freeSearch) {
      setMainSearchValue(freeSearch);
      queryParameters.set('search', encodeURIComponent(freeSearch));
    }
    if (topic) {
      setMainSearchValue('');
      queryParameters.set('topic-id', topic?.id);
    }
    navigate({ search: queryParameters.toString() });
  };

  const resetResults = () => {
    navigate(`${location.pathname}`);
    resetReference();
    setMainSearchValue('');
  };

  const searchActive = getUrlParam('search') || getUrlParam('topic-id');
  const updateState = useRef();
  updateState.current = () => {
    if (!topicIdQuery) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'search', value: { ...search, activeSearch: false, list: null } });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'activeResults', value: 'allResults' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    updateState.current();
  }, []);

  return (
    <>
      <div className='bg-light-blue text-white py-4 py-lg-5'>
        <div className='mx-auto main-content-wrapper px-lg-5 px-3'>
          <p className='mb-0 campaigns-text'>
            Campaigns include policy asks, research, news releases and other briefing material made available by
            organisations
          </p>

          <div className='col-lg-9 px-0 pt-3'>
            <div className='d-lg-flex'>
              <div className='flex-grow-1 campaigns-topic-picker'>
                <TopicPicker
                  keywordsLists={keywordsLists.filter((item) => item?.id !== null)}
                  allowFreeSearch={true}
                  showTopicCases={!!getUrlParam('topic-id')}
                  fixedPosition={true}
                  searchFunction={searchFunction}
                  mainSearchValue={mainSearchValue}
                  closeButtonFunction={resetResults}
                  closeButtonMessage={`See all campaigns`}
                />
              </div>
              <button
                className='d-none d-lg-block ml-4 px-4 general-button rounded-button bg-main-white common-button-hover'
                style={{ visibility: searchActive ? 'visible' : 'hidden' }}
                onClick={() => {
                  resetResults();
                }}
              >
                See all campaigns
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='mx-auto main-content-wrapper px-lg-5 px-3 pt-4'>
        <h3 className='main-title mt-2 mb-3'>{searchActive ? 'Relevant' : 'Latest'} campaigns</h3>
        {isLoading ? <SkeletonKeyUpdates /> : <ListControl />}
      </div>
    </>
  );
};

export default Campaigns;
