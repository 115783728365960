import { Portal } from 'react-portal';
import Popup from '../../../../Common/PopUp';
import { RemoveContactsContent } from '../../../Influence/CrmContacts/RemoveContactsModal/RemoveContactsModal';
import { useState } from 'react';
import useContactsFunctionForStakeholders from '../useContactsFunctionForStakeholder';
import { useCallContactIdsResults } from '../../StakeHolderList';

const ContentPopup = (props) => {
  const { closeMicroModal, StakeholderInformation, setActiveTab } = props;
  const { contactInformation, id } = StakeholderInformation ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const { addOrDeleteContact } = useContactsFunctionForStakeholders();
  const { setContactIdsCheck, contactIdsCheck } = useCallContactIdsResults();

  const removeFromContactLists = async () => {
    setIsLoading(true);
    await addOrDeleteContact({ contactId: contactInformation?.[0]?.id, action: 'delete' });
    const newContactIdsCheck = {
      ...contactIdsCheck,
      [id]: false,
    };
    setContactIdsCheck(newContactIdsCheck);
    setActiveTab('Profile');
    setIsLoading(false);
    closeMicroModal(true);
  };

  return (
    <div className='pt-4'>
      <RemoveContactsContent
        contactsCount={1}
        closeMicroModal={closeMicroModal}
        isLoading={isLoading}
        removeFromContactLists={removeFromContactLists}
        {...props}
      />
    </div>
  );
};

const StakeholderRemoveContactPopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentPopup {...props} />
      </Popup>
    </Portal>
  );
};

export default StakeholderRemoveContactPopup;
