import React, { useState, useEffect, useRef, useContext } from 'react';
import CrmContactsTable from './CrmContactsTable';
import AddTagsModal from './AddTagsModal/AddTagsModal';
import { store } from '../../../Store';
import AddNoteModal from './AddNoteModal/AddNoteModal';
import RemoveContactsModal from './RemoveContactsModal/RemoveContactsModal';
import useValidateContactsOversubscribed from '../../utilities/useValidateContactsOversubscribed';
import InfluenceNotIncludedFeaturesPopup from './InfluenceNotIncludedFeaturesPopup';
import { useLocation } from 'react-router-dom-v5-compat';
import RemoveContactTagsModal from './CrmContactsTable/CrmContactsListBar/RemoveContactTagsModal';
import ChampionStatusModal from './CrmContactsTable/CrmContactsListBar/ChampionStatusModal';

function CrmContacts() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    selectedCrmContacts,
    crmContactsTotalHits,
    showAddTagsModalCrmContacts,
    showAddNoteModalCrmContacts,
    showRemoveContactsModal,
    showRemoveContactTagsModal,
    showChangeChampionStatusModal,
    activePlan,
  } = state;
  const { enableCrm } = activePlan;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const initialCall = useRef();

  const { setShowContactsOversubscribedModal, shownInnitiallyContactsOversubscribed, contactsOversubscribed } =
    useValidateContactsOversubscribed();

  useEffect(() => {
    if (contactsOversubscribed < 0 && !shownInnitiallyContactsOversubscribed) {
      setShowContactsOversubscribedModal(true);
    }
  }, [contactsOversubscribed]);

  const setShowAddTagsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddTagsModalCrmContacts',
      value,
    });
  };
  const setShowAddNotesModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddNoteModalCrmContacts',
      value,
    });
  };

  const hideAddTagsModal = () => {
    setShowAddTagsModal({ show: false, preselectedContact: undefined });
  };
  const hideAddNotesModal = () => {
    setShowAddNotesModal(false);
  };

  const setSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedCrmContacts',
      value,
    });
  };
  const setPreviousStoredSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'previousStoredSelectedCrmContacts',
      value,
    });
  };

  const setTotalHits = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'crmContactsTotalHits',
      value,
    });
  };

  const resetSelectedContacts = () => {
    setSelectedCrmContacts({
      selectedAll: false,
      selectedIds: [],
      selectedAllVisible: [],
    });
  };

  useEffect(() => {
    resetSelectedContacts();
  }, [location]);

  return (
    <>
      {showAddTagsModalCrmContacts.show && (
        <>
          {enableCrm ? (
            <AddTagsModal
              hideAddTagsModal={hideAddTagsModal}
              selectedCrmContacts={selectedCrmContacts}
              setSelectedCrmContacts={setSelectedCrmContacts}
              resetSelectedContacts={resetSelectedContacts}
              totalHits={crmContactsTotalHits}
              initialCall={initialCall}
            />
          ) : (
            <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={hideAddTagsModal} />
          )}
        </>
      )}
      {showAddNoteModalCrmContacts && (
        <>
          {enableCrm ? (
            <AddNoteModal
              hideAddNotesModal={hideAddNotesModal}
              selectedCrmContacts={selectedCrmContacts}
              setSelectedCrmContacts={setSelectedCrmContacts}
              resetSelectedContacts={resetSelectedContacts}
              totalHits={crmContactsTotalHits}
              initialCall={initialCall}
            />
          ) : (
            <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={hideAddTagsModal} />
          )}
        </>
      )}
      {showRemoveContactsModal && (
        <RemoveContactsModal
          selectedCrmContacts={selectedCrmContacts}
          setSelectedCrmContacts={setSelectedCrmContacts}
          resetSelectedContacts={resetSelectedContacts}
          totalHits={crmContactsTotalHits}
          initialCall={initialCall}
        />
      )}
      {showRemoveContactTagsModal && (
        <RemoveContactTagsModal
          selectedCrmContacts={selectedCrmContacts}
          setSelectedCrmContacts={setSelectedCrmContacts}
          resetSelectedContacts={resetSelectedContacts}
          totalHits={crmContactsTotalHits}
          initialCall={initialCall}
        />
      )}
      <div className={`main-content pl-md-4 pl-3 h-100`}>
        <div className='d-flex flex-column h-100'>
          <CrmContactsTable
            selectedCrmContacts={selectedCrmContacts}
            setSelectedCrmContacts={setSelectedCrmContacts}
            setPreviousStoredSelectedCrmContacts={setPreviousStoredSelectedCrmContacts}
            setTotalHits={setTotalHits}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            initialCall={initialCall}
            setShowAddTagsModal={setShowAddTagsModal}
          />
        </div>
      </div>
      <ChampionStatusModal
        isOpen={showChangeChampionStatusModal}
        totalHits={crmContactsTotalHits}
        setIsOpen={(value) => {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'showChangeChampionStatusModal',
            value,
          });
        }}
      />
    </>
  );
}

export default CrmContacts;
