import React, { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHeightContainer } from '../../../utilities/useHeightContainer';
import CustomScrollbar from '../../../../Common/CustomScrollbar';

function UpdateCustomContactSkeleton() {
  const [heightContainer, containerRef] = useHeightContainer();
  const scrollBarRef = useRef(null);

  return (
    <div>
      <div ref={containerRef} />
      <CustomScrollbar
        className={`main-content-scrollbar stakeholder-list-scrollbar`}
        style={{ height: `${heightContainer - 58}px` }}
        maximalThumbYSize={100}
        ref={scrollBarRef}
      >
        <div className={`account-list px-0 pb-2 update-custom-contact-body`}>
          <div className='pr-3' id='settings-container'>
            <ul className='policy-list px-0 hover-content'>
              <form className='mx-0'>
                <div className='px-0'>
                  <li className='add-contact-list-item'>
                    <Skeleton height={17} width={80} />
                    <div className='pl-2 mt-2  account-email-field'>
                      <input type='text' name='firstName' required />
                    </div>
                  </li>

                  <li className='add-contact-list-item'>
                    <Skeleton height={17} width={80} />
                    <div className='pl-2 mt-2  account-email-field'>
                      <input type='text' name='firstName' required />
                    </div>
                  </li>
                  <li>
                    <Skeleton height={17} width={120} />
                    <div className='pl-2 mt-2  account-email-field'>
                      <input className='col-15' type='text' required name='email' />
                    </div>
                  </li>
                  <li>
                    <Skeleton height={17} width={70} />
                    <div className='pl-2 mt-2  account-email-field'>
                      <input className='col-15' type='text' required name='salutation' />
                    </div>
                  </li>
                  <li>
                    <Skeleton height={17} width={90} />
                    <div className='pl-2 mt-2  account-email-field'>
                      <textarea
                        className='col-15'
                        type='text'
                        style={{ overflow: 'hidden', resize: 'none' }}
                        name='description'
                      />
                    </div>
                  </li>
                </div>
              </form>
            </ul>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
}

export default UpdateCustomContactSkeleton;
