import React, { useState } from 'react';
import DropdownComponent from '../../../Settings/WebsiteFeeds/DropdownComponent';
import uppercaseFirstLetter from '../../utilities/uppercaseFirstLetter';
import CalendarExpirtyDate from './CalendarExpiryDate';
import RegionAutocomplete from './RegionAutocomplete';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import AddKeywordsComponent from '../AddKeywordsComponent';
import Popup from '../../../Common/PopUp';
import { Portal } from 'react-portal';
import CustomScrollbar from '../../../Common/CustomScrollbar';

const Visibility = (props) => {
  const { type, visibilityOptions, itemInformation } = props;
  const { publishedAt } = itemInformation;

  const { date, websiteLink, visibility } = visibilityOptions;
  const influenceTypeName = type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ');
  const [showCalendarPicker, setShowCalendarPicker] = useState(false);
  const [stakeholdersOnlyShowPopup, setStakeholdersOnlyShowPopup] = useState(false);
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();

  const DateOptionsElement = () => {
    return (
      <CustomScrollbar
        className={'simple-scrollbar indented-scrollbar'}
        style={{ height: `240px` }}
        maximalThumbYSize={100}
      >
        {date.options.map((item, index) => (
          <p
            key={`date-option-${index}`}
            className={`mb-0 frequency-item px-3 py-2 ${(!!date.value && date.value?.name === item?.name) || (date.value?.selected && item?.value === 'picker') ? 'frequency-item-selected' : ''}`}
            onClick={() => {
              if (item.value === 'picker') {
                setShowCalendarPicker(true);
              } else {
                date.setValue(item);
              }
            }}
          >
            {item.name}
          </p>
        ))}
      </CustomScrollbar>
    );
  };
  return (
    <>
      <div className='influence-creation_section influence-creation_visibility'>
        <h3 className='title-h5-m-bold'>Choose who can see and support this {influenceTypeName}</h3>
        <p className='paragraph-p2 main-light-text'>
          This cannot be edited once a piece of content has been published.
        </p>
        <div className='row mx-0 mb-4 mb-lg-0'>
          {visibility?.options.map((item, index) => {
            const { option, title, subtext, disabled } = item;
            return (
              <div
                className={`col-lg-8 influence-box-container ${publishedAt !== null ? 'influence-box-container-auto' : ''} ${option !== visibility.value && publishedAt !== null ? 'influence-box-container-disabled' : ''}`}
                key={`item-${index}`}
              >
                <div
                  className={`h-100 influence-visibility-box box-rounded text-center box-select ${option === visibility.value ? 'box-selected' : ''}`}
                  onClick={() => {
                    if (publishedAt === null) {
                      if (disabled) {
                        //SHOW POPUP
                        setStakeholdersOnlyShowPopup(true);
                      } else {
                        visibility.setValue(option);
                      }
                    }
                  }}
                >
                  {disabled && <span className='icon-padlock d-inline-block ml-2' />}
                  <h4 className='title-h4-bold mb-3'>{title} </h4>
                  <p className='paragraph-p2 main-light-text mb-0'>{subtext}</p>
                </div>
              </div>
            );
          })}
        </div>

        <h3 className='title-h5-m-bold'>
          Keywords <span className='font-weight-normal'>(required)</span>
        </h3>
        <p className='paragraph-p2 main-light-text'>
          Enter the keywords that best describe the nature of your {newAgencyPlan ? `client's ` : ''}
          {influenceTypeName}
        </p>
        <AddKeywordsComponent {...props} />

        {!agencyUser && (
          <>
            <h3 className='title-h5-m-bold mt-5'>Associate a webpage with this {influenceTypeName}</h3>
            <p className='paragraph-p2 main-light-text'>{`Use your ${newAgencyPlan ? `client's` : `organisation's`} homepage unless you have a specific policy or campaign page`}</p>
            <div>
              <input
                data-hj-allow
                className={'main-input form-control'}
                value={!!websiteLink?.value ? websiteLink?.value : ''}
                onChange={(e) => {
                  websiteLink?.setValue(e.target.value);
                }}
              />
            </div>
          </>
        )}

        <h3 className='title-h5-m-bold mt-5'>Choose when this {influenceTypeName} will expire</h3>
        <p className='paragraph-p2 main-light-text'>
          By default, {influenceTypeName}
          {influenceTypeName === 'policy ask' ? 's' : ''} expire after 6 months. You can change the expiry date at any
          time
        </p>
        <div className='mb-5 expiry-date-dropdown'>
          <DropdownComponent
            items={DateOptionsElement}
            mainItem={!!date.value ? date.value.name : 'Select an option'}
          />
        </div>

        <h3 className='title-h5-m-bold'>Choose a location that applies to this {influenceTypeName}</h3>
        <p className='paragraph-p2 main-light-text'>
          {uppercaseFirstLetter(influenceTypeName)}
          {influenceTypeName === 'policy ask' ? 's' : ''} can be national or local. If location is not relevant, choose
          national
        </p>
        <RegionAutocomplete {...props} />

        {showCalendarPicker && (
          <Portal>
            <CalendarExpirtyDate {...props} setShowCalendarPicker={setShowCalendarPicker} />
          </Portal>
        )}
      </div>
      {stakeholdersOnlyShowPopup && (
        <Portal>
          <StakeholdersOnlyPopup
            identifier={'stakeholders-only-popup'}
            setShowPopup={setStakeholdersOnlyShowPopup}
            additionalClass={'stakeholders-only-popup'}
            options={visibility?.options}
          />
        </Portal>
      )}
    </>
  );
};

const StakeholdersOnlyPopupContent = (props) => {
  const { options, closeMicroModal } = props;
  return (
    <div className='d-flex'>
      <div className='bg-main-blue text-white py-5 px-2 pl-lg-4 sentiment-political-box pr-lg-0'>
        <div className='pl-4 ml-2'>
          <img src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`} alt='sidebar-icon' className={`logo`} />
        </div>
        <ul className='policy-list px-0 pt-5 pr-lg-0 mt-5'>
          <li className='stakeholder-only-option bg-grey text-main-blue'>
            <span className='icon-small-check inline-block text-main-blue title-h3' />
            <span className='nowrap-item'>Restrict visibility to policymakers</span>
          </li>
          <li className='stakeholder-only-option'>
            <span className='icon-small-check inline-block text-white title-h3' />
            <span className='nowrap-item'>Tailored stakeholder research</span>
          </li>
          <li className='stakeholder-only-option'>
            <span className='icon-small-check inline-block text-white title-h3' />
            <span className='nowrap-item'>Priority news service</span>
          </li>
          <li className='stakeholder-only-option'>
            <span className='icon-small-check inline-block text-white title-h3' />
            <span className='nowrap-item'>Real-time committee coverage</span>
          </li>
        </ul>
      </div>
      <div className='flex-grow-1 h-100 d-none d-lg-block p-5 bg-grey'>
        <h2 className='text-center stakeholders-only-title font-weight-normal py-4'>
          Advanced visibility options are available to subscribers of PolicyMogul’s Enterprise plan
        </h2>

        <div className='row mx-0'>
          {options?.map((item, index) => {
            const { option, title, subtext } = item;
            return (
              <div className='col-lg-8 influence-box-container' key={`item-${index}`}>
                <div
                  className={`h-100 influence-visibility-box box-rounded text-center box-select ${option === 'StakeholdersOnly' ? 'box-selected' : ''}`}
                >
                  <h4 className='title-h4-bold mb-3'>{title}</h4>
                  <p className='paragraph-p2 main-light-text mb-0'>{subtext}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className='text-right'>
          <button
            className='contact-button-launch-messenger action-button rounded-button px-5 py-2'
            onClick={() => {
              closeMicroModal();
            }}
          >
            Contact us to upgrade
          </button>
        </div>
      </div>
    </div>
  );
};

const StakeholdersOnlyPopup = (props) => {
  return (
    <Popup {...props}>
      <StakeholdersOnlyPopupContent {...props} />
    </Popup>
  );
};

export default Visibility;
