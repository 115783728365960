import RateLimitPopup from './RateLimitPopup';
import SentimentGraphPage from './SentimentGraphPage';
import SentimentSearchBarPage from './SentimentSearchBarPage';

const SentimentPage = (props) => {
  const { isLoading, data, sentimentState, modifyFiltersBar, popupModifiers, searchParam, showPopup } = props;
  const { showLimitPopup, setShowLimitPopup } = popupModifiers;

  return (
    <>
      {searchParam ? (
        <SentimentGraphPage
          isLoading={isLoading}
          data={data}
          sentimentState={sentimentState}
          modifyFiltersBar={modifyFiltersBar}
          showPopup={showPopup}
        />
      ) : (
        <SentimentSearchBarPage />
      )}

      <RateLimitPopup isOpen={showLimitPopup} setIsOpen={setShowLimitPopup} />
    </>
  );
};

export default SentimentPage;
