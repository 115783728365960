import Popup from '../../../Common/PopUp';
import TinyEditor from '../../../Dashboard/Influence/CrmEmails/TinyEditor/TinyEditor';
import { useState } from 'react';
import useEditEmailSignature from '../useEditEmailSignature';
import useUploadImageBase64 from '../useUploadImageBase64';

const ContentPopup = (props) => {
  const { item, closeMicroModal, loadInitialInformation } = props ?? {};
  const { signature, id, displayName, emailAddress } = item ?? {};
  const [inputValue, setInputValue] = useState(signature ?? '');
  const [saveFunctionOnLoad, setSaveFunctionOnLoad] = useState(false);
  const { editEmailSignature, isLoading } = useEditEmailSignature();
  const { uploadImageBase64 } = useUploadImageBase64();

  const handleSave = async (props) => {
    const { remove } = props ?? {};
    setSaveFunctionOnLoad(remove ? 'delete' : 'save');
    let valueToUse = inputValue;
    if (!remove) {
      let htmlBody = document.createElement('div');
      htmlBody.innerHTML = inputValue;
      let images = htmlBody.querySelectorAll('img');
      if (Array.from(images).length > 0) {
        let uploadImagesRes = await Promise.all(
          Array.from(images)
            .filter((image) => !image.src.startsWith('https://'))
            .map(async (image) => {
              let mimeContentType = image.src.split(';')[0].split(':')[1];
              let base64EncodedData = image.src.split(',')[1];
              let fileName =
                image.getAttribute('data-name') ??
                `${displayName}-${new Date().getMilliseconds()}.${image.src.substring(image.src.indexOf('/') + 1, image.src.indexOf(';base64'))}`;
              let link = await uploadImageBase64({ fileName, mimeContentType, base64EncodedData });
              if (link?.data) {
                image.src = link.data;
              }
            })
        );
        if (uploadImagesRes) {
          valueToUse = htmlBody.innerHTML;
        }
      }
    }
    const result = await editEmailSignature({
      senderEmailAddressId: id,
      displayName,
      signature: remove ? null : valueToUse,
    });
    if (result) {
      let initialInfoRes = await loadInitialInformation();
      if (initialInfoRes) {
        setSaveFunctionOnLoad(false);
        closeMicroModal();
      }
    }
  };

  const disabledCondition = () => {
    return !inputValue || inputValue === '' || inputValue === signature;
  };

  const title = `Email signature for ${emailAddress?.split('<')?.[1]?.substring(0, emailAddress?.split('<')?.[1]?.length - 1)}`;
  return (
    <>
      <div className='section-title px-4 py-4'>
        <h4 className='title-h4-bold mb-0'>{title}</h4>
      </div>
      <div className='signature-text-editor'>
        <TinyEditor stateContent={inputValue} handleBody={setInputValue} autoFocus={true} signatureEditor />
        <div className='signature-buttons-section d-flex align-items-center'>
          {!!signature && (
            <button
              className='action-button main-danger-button px-2 px-lg-4 rounded'
              onClick={() => {
                handleSave({ remove: true });
              }}
            >
              {isLoading === 'delete' || saveFunctionOnLoad === 'delete' ? (
                <i
                  className='fas fa-spinner fa-spin'
                  style={{
                    margin: '0 auto',
                    color: '#fcfcfc',
                    marginTop: '2px',
                    width: '33.7px',
                  }}
                ></i>
              ) : (
                'Delete'
              )}
            </button>
          )}
          <button
            className='action-button px-2 px-lg-4 rounded ml-3'
            onClick={handleSave}
            disabled={disabledCondition()}
          >
            {isLoading === 'save' || saveFunctionOnLoad === 'save' ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  margin: '0 auto',
                  color: '#fcfcfc',
                  marginTop: '2px',
                  width: '33.7px',
                }}
              ></i>
            ) : (
              'Save'
            )}
          </button>
        </div>

        <input id='upload-image' type='file' />
      </div>
    </>
  );
};

const EditEmailSignatureModal = (props) => {
  return (
    <Popup {...props}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default EditEmailSignatureModal;
