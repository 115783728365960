import axios from 'axios';
import { net_api_url } from '../../Store';
const highlightWidget = async (objectType, objectId, keywordListId, includeBrandingProperty) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  try {
    let contentRequest = {
      cancelToken: source.token,
    };
    let highlightedResults = await axios.post(
      `${net_api_url}/api/widget/highlight-content${!includeBrandingProperty ? '?removeRelativeLinks=true' : ''}`,
      {
        objectType: objectType,
        objectId: objectId,
        keywordListId: keywordListId,
      },
      contentRequest
    );
    return highlightedResults ? highlightedResults.data : null;
  } catch (error) {}
};

export default highlightWidget;
