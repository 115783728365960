import { net_api_url } from '../../Store';
const inviteUser = async (getAccessToken, source, values) => {
  try {
    let token = await getAccessToken();
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: values.email,
        role: values.role,
      }),
    };
    var response = await fetch(`${net_api_url}/api/team/send-invite`, requestOptions);
    var responseData = '';
    if (response.status === 500) {
      responseData = await response.text();
    } else {
      responseData = JSON.stringify(await response.json(), null, 2);
    }
    return responseData;
  } catch (error) {
    console.error(error);
  }
};

export default inviteUser;
