import React, { useContext, useState, useEffect, useRef } from 'react';
import { store } from '../../Store.js';
import StakeHolderList from './StakeHolderList.js';
import SkeletonHolderListSkeleton from './StakeHolderListSkeleton.js';
import StakeHolderNoMogulUsers from './StakeHolderNoMogulUsers.js';
import { useAuth0 } from '@auth0/auth0-react';
import addMetaTags from '../utilities/addMetaTags.js';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import InfluenceNotIncludedFeaturesPopup from '../Influence/CrmContacts/InfluenceNotIncludedFeaturesPopup.js';
import { useLocation, useParams } from 'react-router-dom-v5-compat';
import { useNoTopicsCondition } from '../components/EmptyMessage.js';
import NoTopicsView from '../../Settings/Topics/NoTopicsView.js';

const StakeHolders = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activeResults, search, query, activePlan } = state;
  const { enableStakeholders } = activePlan;
  const { searchState } = search;
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const { referenceSection, searchResultsSection, mainSectionTitle } = useMainSectionParameter(params);

  const initialFunction = useRef();
  initialFunction.current = () => {
    let activePlanItems = Object.keys(activePlan);
    if ((activePlanItems.length > 0 && searchState) || !isAuthenticated) {
      setIsLoading(false);
      if (!isAuthenticated || !enableStakeholders) {
        setPageTitle();
      }
    }
  };

  const setPageTitle = () => {
    let title = `${mainSectionTitle}${mainSectionTitle !== '' ? 'Stakeholders' : 'Stakeholder mapping'} - PolicyMogul`;
    let hash = '';
    addMetaTags({ title, hash, location, dispatch });
  };

  useEffect(() => {
    initialFunction.current();
  }, [activeResults, query, searchState]);

  const stakeholdersConditional =
    (isAuthenticated && enableStakeholders) ||
    referenceSection ||
    searchResultsSection ||
    location.pathname === '/general-election-2024/candidates';
  const stakeholderMapping =
    location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers');
  const policymakersSection = location.pathname.includes('/policymakers');
  const [showStakeholdersCrmPopup, setShowStakeholdersCrmPopup] = useState(false);
  const { newAgencyNoTopicsCondition } = useNoTopicsCondition();

  return (
    <>
      <div
        className={`${
          stakeholderMapping ? 'main-content pr-xl-5 px-md-4 px-3 h-100' : ''
        } ${policymakersSection ? 'flex-grow-1 pl-xl-5' : ''}`}
      >
        {isLoading && !stakeholderMapping && <SkeletonHolderListSkeleton includeTitle={true} />}
        <>
          {!stakeholdersConditional && !isLoading && <StakeHolderNoMogulUsers />}
          {stakeholdersConditional && (
            <>
              {newAgencyNoTopicsCondition ? (
                <div className='bg-white box-top-rounded'>
                  <NoTopicsView />
                </div>
              ) : (
                <StakeHolderList setShowStakeholdersCrmPopup={setShowStakeholdersCrmPopup} />
              )}
            </>
          )}
        </>
      </div>
      {showStakeholdersCrmPopup && (
        <InfluenceNotIncludedFeaturesPopup type={'contacts'} setShowPopup={setShowStakeholdersCrmPopup} />
      )}
    </>
  );
};

export default StakeHolders;
