import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import normalize from '../utilities/normalizeString';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { store } from '../../Store.js';
import preventLink from '../TagsList/prevenLink';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import useIsMobile from '../utilities/useIsMobile';
import ItemActions from './ItemActions';
import useValidateContactsOversubscribed from '../utilities/useValidateContactsOversubscribed';
import useNewlyAddedContacts from '../hooks/useNewlyAddedContacts';
import MicroModal from 'micromodal';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';

const ShareContent = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, team } = state;
  const { enableCrm } = activePlan;

  const {
    embargoed,
    link,
    shareButtonWidget,
    id,
    title,
    itemShare,
    item,
    alreadyInContacts,
    stakeholderMapping,
    influenceItem,
    setShowStakeholdersCrmPopup,
    listItem,
  } = props;
  const [urlToShare, setUrlToShare] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { generalApiCall } = useGeneralApiCall();

  const { setNewlyAddedContacts } = useNewlyAddedContacts();

  const { validateContactsOversubscribed, setShowContactsOversubscribedConfirmModal } =
    useValidateContactsOversubscribed();
  const { newAgencyPlan } = useTopicOrClientWord();

  const callContactAction = async (contactId, type) => {
    if (type === 'remove') {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'deleteStakeholderId',
        value: contactId,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'showRemoveContactsModal',
        value: true,
      });
    } else {
      validateContactsOversubscribed(
        async () => {
          let requestProperties;
          if (contactId) {
            requestProperties = {
              contactIds: [contactId],
              tags: null,
            };
            let pathname = `/api/crm-contact/add-stakeholders`;
            let method = 'post';
            try {
              const result = await generalApiCall({
                method,
                pathname,
                requestProperties,
                needsAuthentication: true,
                returnError: true,
              });

              if (result && !result.status) {
                setNewlyAddedContacts(result);
                navigate('/influence/contacts');
              } else {
                if (result?.status === 403) {
                  setShowContactsOversubscribedConfirmModal(true);
                } else {
                  let message = `An error ocurred.`;
                  createNotification('danger', message);
                }
              }
            } catch (e) {}
          }
        },
        'add_contact',
        1
      );
    }
  };

  useEffect(() => {
    let origin = influenceItem ? process.env.REACT_APP_POLICYINFLUENCE_APP_URL : window.location.origin;
    let urlToShare = origin;
    let contentPage = location.pathname.match(/[0-9]+/) || location.pathname.toLowerCase().startsWith('/embed');
    let analyticsTracking = `?utm_${
      shareButtonWidget ? 'campaign' : 'source'
    }=${shareButtonWidget ? 'share-widget-button' : 'share-button'}`;
    let legislationType = props.legislationType ? `${props.legislationType.toLowerCase()}/` : '';
    let hash = location.hash.includes('contribution') ? '' : location.hash;

    if (link) {
      urlToShare = `${link}${analyticsTracking}`;
    } else if (item.contentUrl) {
      urlToShare = `${origin}${item.contentUrl}${analyticsTracking}`;
    } else {
      if (!contentPage) {
        urlToShare += `${location.pathname}/${legislationType}${id}/${normalize(title)}${analyticsTracking}${hash}`;
      } else {
        urlToShare += `${location.pathname}${analyticsTracking}${hash}`;
      }
    }
    setUrlToShare(urlToShare);
  }, [id]);
  return (
    <>
      <div className='share-container position-relative'>
        <div className={`px-0 px-sm-1 share-container-items ${listItem ? '' : 'd-flex'}`}>
          {!shareButtonWidget && !itemShare && <SaveButton {...props} thick={!listItem} />}
          {stakeholderMapping && (
            <button
              className={`contacts-activity-button general-button position-relative ${
                alreadyInContacts ? 'light-red-color' : 'light-blue-color'
              }`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (enableCrm) {
                  if (alreadyInContacts) {
                    callContactAction(id, 'remove');
                  } else {
                    callContactAction(id);
                  }
                } else {
                  if (setShowStakeholdersCrmPopup) {
                    setShowStakeholdersCrmPopup(true);
                  }
                }
              }}
              style={{
                opacity: embargoed ? 0.6 : 1,
                color: alreadyInContacts ? '#e46666 !important' : '##0094cc',
              }}
            >
              {newAgencyPlan ? (
                <img
                  alt='avatar'
                  className='rounded-circle img-fluid d-inline-block agency-image'
                  src={
                    team?.organisationLogo ?? `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`
                  }
                />
              ) : (
                <span className={`${alreadyInContacts ? 'icon-minus-rounded' : 'icon-plus-rounded'}`}></span>
              )}
              <span className={`${newAgencyPlan && alreadyInContacts ? 'pr-3' : ''}`}>
                {alreadyInContacts ? 'Remove from contacts' : `Add to ${newAgencyPlan ? '' : 'my '}contacts`}
              </span>
            </button>
          )}
          {!listItem && (
            <button
              className={
                itemShare
                  ? 'share-icon-button general-button position-relative'
                  : 'general-button circle-btn-with-hover'
              }
              data-tooltip-html={
                embargoed
                  ? 'You cannot share this update <br/>while it is under embargo'
                  : itemShare
                    ? ''
                    : 'Share this update'
              }
              data-tooltip-id={'general-tooltip'}
              style={{ opacity: itemShare ? 1 : 0.6, marginLeft: '4px' }}
              onClick={(e) => {
                e.target.blur();
                preventLink(e);
                if (!embargoed) {
                  dispatch({
                    type: 'MODIFY_SECTION',
                    parameter: 'shareStakeholderPopupProps',
                    value: {
                      title: title ?? item?.title,
                      header: 'Share this update',
                      influenceItem: influenceItem,
                      urlToShare: urlToShare,
                    },
                  });
                  MicroModal.show('share-stakeholder-popup', {
                    awaitCloseAnimation: true,
                    disableFocus: true,
                  });
                }
              }}
            >
              <span
                className='icon-share paragraph-p2'
                style={
                  !itemShare
                    ? {
                        transform: 'rotate(350deg) scale(1.05)',
                        display: 'block',
                      }
                    : {
                        marginRight: '10px',
                      }
                }
              ></span>
              {itemShare && 'Share update'}
            </button>
          )}
          {listItem && (
            <>
              <button
                className={`share-icon-button general-button position-relative`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!embargoed) {
                    let tempInput = document.createElement('input');
                    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
                    tempInput.value = urlToShare;
                    document.body.appendChild(tempInput);
                    tempInput.select();
                    document.execCommand('copy');
                    document.body.removeChild(tempInput);
                    createNotification('success', `Link copied`);
                  }
                }}
                data-tooltip-id={'general-tooltip'}
                data-tooltip-html={
                  embargoed
                    ? 'You cannot share this update <br/>while it is under embargo'
                    : itemShare
                      ? ''
                      : 'Copy link'
                }
                style={{ opacity: embargoed ? 0.6 : 1 }}
              >
                <span className={`icon-share-link`} />
                {itemShare ? `Copy link ${stakeholderMapping ? 'to profile' : ''}` : ''}
              </button>
              {embargoed && (
                <button
                  className={`twitter-button pointer text-left general-button`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  style={{ opacity: 0.6 }}
                  data-tooltip-id={'general-tooltip'}
                  data-tooltip-html={'You cannot share this update <br/>while it is under embargo'}
                >
                  <span className={`icon-twitter-x-circle `} />
                  {itemShare ? 'Share on X' : ''}
                </button>
              )}
              {!embargoed && item.type !== 'Tweet' && (
                <TwitterShareButton
                  url={urlToShare}
                  title={title}
                  className={`twitter-button pointer text-left general-button`}
                  data-tooltip-id={'general-tooltip'}
                  data-tooltip-content={itemShare ? '' : 'Share on X'}
                >
                  <span className={`icon-twitter-x-circle`} />
                  {itemShare ? 'Share on X' : ''}
                </TwitterShareButton>
              )}
              {influenceItem && (
                <>
                  <LinkedinShareButton
                    title={title}
                    source={'Policy influence'}
                    className={`linkedin-button pointer text-left general-button`}
                    url={urlToShare}
                    resetButtonStyle={false}
                  >
                    <span className={`icon-linkedin d-inline-block`} /> <span>Share on LinkedIn</span>
                  </LinkedinShareButton>

                  <FacebookShareButton
                    quote={title}
                    url={urlToShare}
                    resetButtonStyle={false}
                    className={`facebook-button pointer text-left general-button`}
                  >
                    <span
                      className={`icon-facebook d-inline-block title-h3`}
                      style={{ marginTop: '-4px', verticalAlign: 'middle' }}
                    />{' '}
                    <span>Share on Facebook</span>
                  </FacebookShareButton>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const SaveButton = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { saveItemsIds, reloadSavedItemInformation } = state;

  const { removeFromFolder, item, itemShare, hideActions, listItem, thick } = props;
  const { generalApiCall } = useGeneralApiCall();
  const isMobile = useIsMobile();

  let defineObjectId = () => {
    if (item.contributionId) {
      return item.parentObjectID;
    } else if (item.type === 'RankedStakeholder') {
      return item.baseObjectID;
    } else {
      return item.objectID ?? item.objectId;
    }
  };

  let objectId = defineObjectId();

  const savedItemIdInfo = saveItemsIds.findIndex((element) => {
    return element.objectId === objectId;
  });

  const foldersConditional = savedItemIdInfo >= 0;
  const [isLoading, setIsLoading] = useState(false);
  const [savedItemsPerFolder, setSavedItemsPerFolder] = useState([]);
  const [timesOverItem, setTimesOverItem] = useState(0);

  const removeConditional = removeFromFolder || savedItemIdInfo >= 0;
  const StakeholderItem = item.type === 'Stakeholder' || item.type === 'RankedStakeholder';

  const buttonText = () => {
    if (isLoading) {
      return '';
    } else {
      if (removeConditional) {
        return savedItemIdInfo >= 0 && savedItemsPerFolder.length > 0 && !removeFromFolder
          ? `Saved to ${savedItemsPerFolder.length} folder${savedItemsPerFolder.length === 1 ? '' : 's'}`
          : `${removeFromFolder ? 'Remove from folder' : ''}`;
      } else {
        return `Save this ${StakeholderItem ? 'stakeholder' : 'update'}`;
      }
    }
  };

  const callFoldersId = useRef();
  const isReadyApi = useRef(false);
  callFoldersId.current = async () => {
    if (foldersConditional && !removeFromFolder) {
      setIsLoading(true);
      let objectId = saveItemsIds[savedItemIdInfo].objectId;
      let pathname = `/api/saved-items/saved-item-by-objectid-folders?objectId=${objectId}`;
      let method = 'get';
      let results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
      });
      if (results) {
        isReadyApi.current = true;
        setSavedItemsPerFolder(results);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'reloadSavedItemInformation',
          value: null,
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (reloadSavedItemInformation?.itemId === defineObjectId()) {
      callFoldersId.current();
    }
  }, [reloadSavedItemInformation]);

  const saveActions = useRef();
  saveActions.current = (e) => {
    preventLink(e);
    e.target.blur();
    if (removeFromFolder) {
      removeFromFolder(e, item.savedItemid);
    } else {
      if (item) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'saveItemPopup',
          value: {
            item: { ...item, savedItemsPerFolder },
            showPopup: true,
            listItem: listItem,
          },
        });
      }
      if (hideActions) {
        hideActions();
      }
    }
  };

  const loadApiOnHover = useRef();
  const overItem = useRef(false);
  const isClicked = useRef(false);

  const readyCondition = !removeConditional || (removeConditional && isReadyApi.current);
  loadApiOnHover.current = async (e) => {
    if (!isLoading && timesOverItem === 0) {
      setTimesOverItem(timesOverItem + 1);
      await callFoldersId.current();
      if (overItem.current) {
        e.target.blur();
        e.target.focus();
        if (isClicked.current) {
          saveActions.current(e);
        }
      }
    }
  };

  return (
    <>
      <button
        className={`general-button bg-white ${itemShare ? '' : 'circle-btn-with-hover'} ${isLoading ? 'items-actions-save-disabled' : ''} ${
          itemShare ? '' : 'item-actions-save-content'
        }`}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-content={isMobile ? undefined : buttonText()}
        style={{ backgroundImage: 'none' }}
        onClick={async (e) => {
          preventLink(e);
          isClicked.current = true;
          if (isMobile) {
            await loadApiOnHover.current(e);
          }
          if (!isLoading && readyCondition) {
            saveActions.current(e);
          }
        }}
        onMouseOver={(e) => {
          overItem.current = true;
          if (!isMobile) {
            setTimeout(() => {
              if (overItem.current) {
                loadApiOnHover.current(e);
              }
            }, 300);
          }
        }}
        onMouseLeave={() => {
          overItem.current = false;
        }}
      >
        {removeConditional ? (
          <span className='icon-saved-item d-block title-h4' style={{ marginTop: '2px' }}></span>
        ) : (
          <span
            className='icon-save title-h4'
            style={
              thick && !removeConditional
                ? {
                    fontWeight: '700',
                    color: 'rgba(33,37,41,0.6)',
                  }
                : {
                    color: 'rgba(0, 18, 43, 0.5)',
                    marginTop: '-1px',
                    display: 'block',
                  }
            }
          ></span>
        )}
      </button>
    </>
  );
};

const Share = (props) => {
  const { listItem } = props;
  const isMobile = useIsMobile(420);
  const saveActions = listItem || isMobile;
  return (
    <>
      {saveActions && <ItemActions {...props} />}
      {!saveActions && (
        <>
          <ShareContent {...props} />
        </>
      )}
    </>
  );
};

export { ShareContent, SaveButton };
export default Share;
