const filtersOptions = [
  {
    name: 'sort',
    options: [
      {
        name: 'Creation date',
        value: 'CreateDate',
        default: true,
      },
      {
        name: 'Name',
        value: 'Name',
      },
    ],
  },
  {
    name: 'order',
    options: [
      {
        name: 'Ascending',
        value: 'Asc',
      },
      {
        name: 'Descending',
        value: 'Desc',
        default: true,
      },
    ],
  },
];

export default filtersOptions;
