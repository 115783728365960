import React, { useState, useRef, useContext, useEffect } from 'react';
import { TwitterShareButton } from 'react-share';
import { store } from '../Store';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { useAuth0 } from '@auth0/auth0-react';
import { copyInnerHtml } from '../Dashboard/helpers/copyFunctionality';

const TextSelector = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan } = state;
  const { enableReports } = activePlan;

  const { children, currentNew, scrollbarRef, scrolling } = props;
  const { canonicalUrl, embargoed } = currentNew ?? {};
  const [tooltip, setTooltip] = useState({ visible: false, text: '', position: { top: 0, left: 0 } });

  const contentRef = useRef(null);
  const tooltipRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const url = `${window.location.origin}${canonicalUrl}?utm_source=right-click-tweet`;
  const { isAuthenticated } = useAuth0();

  const hideTooltip = () => {
    setTooltip({ visible: false, text: '', position: { top: 0, left: 0 } });
  };

  const handleMouseUp = () => {
    if (!location.pathname.startsWith('/reports') && !currentNew?.embargoed) {
      setTimeout(() => {
        const selection = window.getSelection();
        let selectedText = '';
        const newRange = document.createRange();
        let validRange = false;
        for (let i = 0; i < selection.rangeCount; i++) {
          const range = selection.getRangeAt(i);

          if (contentRef.current?.contains(range.commonAncestorContainer)) {
            if (!validRange) {
              newRange.setStart(range.startContainer, range.startOffset);
              validRange = true;
            }
            newRange.setEnd(range.endContainer, range.endOffset);
            selectedText += range.toString();
          }
        }

        if (validRange && selectedText.trim().length > 0) {
          selection.removeAllRanges();
          selection.addRange(newRange);

          const rects = newRange.getClientRects();
          if (rects.length > 0) {
            const firstRect = rects[0];

            const scrollableContainer = scrollbarRef.current.container;
            const containerRect = scrollableContainer.getBoundingClientRect();
            let firstVisibleRect = rects[0];
            let lastVisibleRect = rects[rects.length - 1];
            let isTooltipFromBottom = false;

            for (let rect of rects) {
              if (rect.bottom > containerRect.top && rect.top < containerRect.bottom) {
                firstVisibleRect = rect;
                break;
              }
            }

            if (firstVisibleRect.top < containerRect.top) {
              isTooltipFromBottom = true;
              for (let i = rects.length - 1; i >= 0; i--) {
                const rect = rects[i];
                if (rect.bottom > containerRect.top && rect.top < containerRect.bottom) {
                  lastVisibleRect = rect;
                  break;
                }
              }
            }

            const tooltipLeft = (isTooltipFromBottom ? lastVisibleRect.left : firstRect.left) + firstRect.width / 2;
            const tooltipTop = isTooltipFromBottom ? lastVisibleRect.bottom : firstVisibleRect.top;

            const container = document.createElement('div');
            container.appendChild(newRange.cloneContents());
            setTooltip({
              visible: true,
              text: container.innerHTML,
              plainText: selectedText.trim(),
              position: {
                top: isTooltipFromBottom ? lastVisibleRect.bottom : tooltipTop,
                left: tooltipLeft,
              },
              fromBottom: isTooltipFromBottom,
            });
          }
        } else {
          hideTooltip();
        }
      }, 0);
    }
  };

  const handleMouseDown = (e) => {
    if (tooltipRef.current && tooltipRef.current?.contains(e.target)) {
      return;
    }
    hideTooltip();
  };

  const composeTextWithLink = (text) => {
    const maxLength = 280;
    const ellipsis = '...';

    if (text?.length + url?.length + ellipsis.length > maxLength) {
      const availableLength = maxLength - url.length - ellipsis.length;
      text = text.slice(0, availableLength) + ellipsis;
    }

    return text;
  };

  const changingSelectFromElements = (allow) => {
    const selectValue = allow ? 'auto' : 'none';
    const topNav = document.querySelector('.header-logout');
    const backButton = document.querySelector('.back-content-button');
    const rightPanel = document.querySelector('.related-content');
    if (topNav) {
      topNav.style.userSelect = selectValue;
    }
    if (backButton) {
      backButton.style.userSelect = selectValue;
    }
    if (rightPanel) {
      rightPanel.style.userSelect = selectValue;
    }
  };

  useEffect(() => {
    changingSelectFromElements();
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      changingSelectFromElements(true);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (scrolling && tooltip?.visible) {
      hideTooltip();
    }
  }, [scrolling]);

  return (
    <div className='position-relative' ref={contentRef} style={{ userSelect: 'auto' }}>
      {children}

      {tooltip.visible && (
        <div
          ref={tooltipRef}
          className={`bg-light-blue rounded-pill flex-centered content-tooltip-actions z-extra-max ${tooltip?.fromBottom ? 'tooltip-actions-bottom' : ''}`}
          style={{
            position: 'fixed',
            top: tooltip.fromBottom ? tooltip.position.top + 9 : tooltip.position.top - 55,
            left: tooltip.position.left,
            transform: 'translateX(-50%)',
          }}
        >
          <button
            className='general-button circle-button bg-main-white flex-centered justify-content-center mr-2'
            onClick={() => {
              copyInnerHtml(tooltip?.text, tooltip?.plainText);
            }}
          >
            <span className='icon-copy-thin' />
          </button>
          <TwitterShareButton
            url={url}
            title={composeTextWithLink(tooltip?.plainText)}
            className={`general-button circle-button bg-white flex-centered justify-content-center`}
          >
            <span className='icon-twitter-x' />
          </TwitterShareButton>
          <button
            className='general-button text-white py-1 ml-2 px-2 main-subtle-text border-left nowrap-item actions-create-report-button'
            onClick={() => {
              if (isAuthenticated && enableReports) {
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'updateToCreateReport',
                  value: { ...currentNew, extract: tooltip?.text },
                });
                navigate('/reports/create');
              } else {
                if (isAuthenticated) {
                  navigate('/pricing');
                } else {
                  navigate('/register');
                }
              }
            }}
            data-tooltip-html={
              !enableReports
                ? 'Upgrade to a Mogul or Enterprise subscription to create reports'
                : `${embargoed ? 'You cannot create a report on this update<br/> while it is under embargo' : ''}`
            }
            disabled={embargoed}
            data-tooltip-id='general-tooltip'
          >
            Create report {!enableReports ? <span className='icon-padlock d-inline-block-centered ml-1' /> : ''}
          </button>
        </div>
      )}
    </div>
  );
};

export default TextSelector;
