import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
const exportAsImage = async (el, imageFileName, mapChart) => {
  if (mapChart) {
    domtoimage
      .toPng(el, {
        width: el.clientWidth * 2.5,
        height: el.clientHeight * 2.5,
        style: {
          transform: 'scale(' + 2.5 + ')',
          transformOrigin: 'top left',
        },
      })
      .then(function (dataUrl) {
        downloadImage(dataUrl, imageFileName);
      });
  } else {
    const canvas = await html2canvas(el, {
      scale: 2.5,
    });
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
  }
};
const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.style = 'display:none;';
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;
