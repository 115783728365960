import { useContext } from 'react';
import { store } from '../../Store';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import getUrlParam, { getUrlParamRaw } from '../utilities/getUrlParam';

const useGetHighlightingKeywords = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, referenceState, keywordsLists } = state;
  const { list, query } = search;
  const { activeReference } = referenceState;

  let andKeywords = list !== null ? list.andKeywords : [];
  let queryHighlight = list !== null ? ChangeKeywordsCategory(list.keywords) : query;

  if (activeReference) {
    andKeywords = referenceState.andKeywords;
    queryHighlight = ChangeKeywordsCategory(referenceState.keywords);
  }

  //AE: THIS VERIFICATIONS ARE NECESSARY TO THE CASE WHEN WE ARRIVED TO THIS PAGE FROM THE STAKEHOLDERPAGE AND NOT DIRECT LOAD
  if (getUrlParam('topic-id')) {
    let listId = getUrlParam('topic-id') === 'all' ? null : parseInt(getUrlParam('topic-id'));
    let listFromUrlParam = keywordsLists.find((item) => item.id === listId);
    andKeywords = listFromUrlParam?.andKeywords ?? [];
    queryHighlight = ChangeKeywordsCategory(listFromUrlParam?.keywords ?? []);
  }

  if (getUrlParam('search')) {
    let queryDecoded = decodeURIComponent(getUrlParamRaw('search'));
    if (!activeReference || (activeReference && queryDecoded !== referenceState.keywords.join(','))) {
      queryHighlight = queryDecoded;
      andKeywords = [];
    }
  }

  return { andKeywords, queryHighlight };
};

export default useGetHighlightingKeywords;
