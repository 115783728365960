import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';

const SkeletonBilling = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='pt-4 pt-lg-5'>
        <div
          className='box px-lg-5 pt-5 px-3 settings-box'
          ref={containerRef}
          style={{ height: `${heightContainer}px` }}
        >
          <div className='mb-4 row  mx-0'>
            <div className='col-lg-7 px-0'>
              <div>
                <Skeleton width={'90%'} height={19} />
              </div>
              <div className='my-1'>
                <Skeleton width={'70%'} height={19} />
              </div>
            </div>
            <div className='col-lg-9 px-0'>
              <Skeleton width={'35%'} height={24} />
            </div>
          </div>

          <div className='pb-5'>
            <Skeleton width={'20%'} height={19} />
            <hr />
            <ul className='policy-list px-0'>
              {Array(4)
                .fill()
                .map((item, index) => (
                  <li key={`account${index}`}>
                    <div className='my-2'>
                      <Skeleton width={'30%'} height={19} />
                    </div>
                    <div className='my-2'>
                      <Skeleton width={'27%'} height={19} />
                    </div>
                  </li>
                ))}
            </ul>

            <div className='my-3'>
              <Skeleton width={'30%'} height={19} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonBilling;
