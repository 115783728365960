const ElectionKeyUpdatesFilters = [
  {
    name: 'Election Tag',
    pages: ['ElectionKeyUpdates'],
    filterName: 'tags',
    optionSelected: 'election',
    hidden: true,
    options: [
      {
        name: 'election',
        filterValue: 'election',
        hash: 'election',
        selected: true,
      },
    ],
    prefix: 'election-tag',
  },
  {
    name: 'Date',
    pages: ['ElectionKeyUpdates'],
    type: 'date',
    optionSelected: 'After',
    filterName: 'dateTime',
    hidden: true,
    options: [
      {
        name: 'After',
        filter: ['2024-05-20'],
        selected: true,
      },
    ],
  },
];

const finalElectionKeyUpdatesFilters =
  process.env.REACT_APP_ELECTION_ONLY_RECENT_CONTENT === 'false'
    ? ElectionKeyUpdatesFilters.filter((item) => item.name !== 'Date')
    : ElectionKeyUpdatesFilters;

export default finalElectionKeyUpdatesFilters;
