import { useState, useCallback, useEffect } from 'react';

function useHeightContainer(needScroll, parameter) {
  const [heightContainer, setHeightContainer] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const containerRef = useCallback(
    (node) => {
      const createHeight = () => {
        if (node !== null) {
          if (needScroll) {
            node.scrollTop = 0;
          }
          let value = parameter ?? 0;
          let height = windowHeight - (node.getBoundingClientRect().top + value);
          setHeightContainer(height);
        }
      };
      createHeight();
    },
    [windowHeight]
  ); /* 
  AE: Need to track what impact it will have on performance
  [location, needScroll, parameter, trialBannerHeight, windowHeight] */

  useEffect(() => {
    const changeHeight = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', changeHeight);
    return () => {
      window.removeEventListener('resize', changeHeight);
    };
  }, []);

  return [heightContainer, containerRef];
}

export { useHeightContainer };
