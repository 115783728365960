import React, { useRef } from 'react';
import normalize from '../utilities/normalizeString';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { mentionsGraphNations } from './createAnalyticsState';
import DropdownComponent from '../../Settings/WebsiteFeeds/DropdownComponent';
import sortArrayByPattern from '../helpers/sortArrayByPattern';

const CountryPicker = ({
  selectedCountries,
  setSelectedCountries,
  onSelectCallback,
  scrollBarPosition,
  embedAnalyticsPopup,
}) => {
  const createSelectedTopics = () => {
    const countriesLengths = selectedCountries.length;
    if (countriesLengths === 0) {
      return '';
    } else if (countriesLengths === 1) {
      return `${selectedCountries[0]?.name}`;
    } else if (countriesLengths > 1 && countriesLengths !== mentionsGraphNations.length) {
      return `${sortArrayByPattern(selectedCountries, mentionsGraphNations)[0]?.name} + ${countriesLengths - 1}`;
    } else {
      return `${embedAnalyticsPopup ? 'All' : 'all'} stakeholders`;
    }
  };
  return (
    <div className={`${embedAnalyticsPopup ? '' : 'country-picker'}`} style={{ minWidth: '200px' }}>
      <DropdownComponent
        fixedDropdown={!embedAnalyticsPopup}
        mainItem={() => (
          <span className='hidden-lines hidden-one-line w-100'>
            {embedAnalyticsPopup ? '' : 'by'} {createSelectedTopics()}
          </span>
        )}
        notCloseOnSelect
        dropdownClass={embedAnalyticsPopup ? '' : 'country-options-dropdown'}
        fixedTopSpace={10}
        scrollBarPosition={scrollBarPosition}
      >
        <CountryPickerDropdown
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          onSelectCallback={onSelectCallback}
          embedAnalyticsPopup={embedAnalyticsPopup}
        />
      </DropdownComponent>
    </div>
  );
};

const CountryPickerDropdown = (props) => {
  const {
    selectedCountries,
    setSelectedCountries,
    returnSelected,
    disabledCondition,
    onSelectCallback,
    embedAnalyticsPopup,
  } = props;

  const listsContainer = useRef(null);

  return (
    <div className='keyword-lists-website-feeds px-3 py-2'>
      <div
        className='website-popup-container-lists large-container'
        style={{ paddingBottom: '1px' }}
        ref={listsContainer}
      >
        {mentionsGraphNations.map((item, index) => {
          const { value, name } = item;
          return (
            <div className={`checkbox-item`} key={`topicItem-${name}`}>
              <input
                id={`${normalize(name)}`}
                onChange={() => {
                  let newItems = [...selectedCountries];
                  let itemPosition = newItems.findIndex((item) => item.value === value);
                  if (itemPosition >= 0) {
                    if (newItems.length === 1) {
                      createNotification('danger', 'You must have at least 1 nation selected');
                    } else {
                      newItems.splice(itemPosition, 1);
                      returnSelected ? setSelectedCountries(newItems, item) : setSelectedCountries(newItems);
                    }
                  } else {
                    returnSelected
                      ? setSelectedCountries([...selectedCountries, item], item)
                      : setSelectedCountries([...selectedCountries, item]);
                  }
                  if (onSelectCallback) {
                    onSelectCallback(itemPosition < 0, item);
                  }
                }}
                type={'checkbox'}
                checked={!!selectedCountries.find((item) => item?.value === value)}
                disabled={disabledCondition ? disabledCondition(item) : false}
              />
              <label htmlFor={`${normalize(name)}`} className='create-email-alert-label'>
                <p className='mb-0'>
                  {embedAnalyticsPopup ? '' : 'by'} {name}
                </p>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CountryPicker;
