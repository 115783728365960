import React from 'react';
import InfluenceCrmSideBar from './InfluenceCrmSideBar/InfluenceCrmSideBar';
import InfluenceCrmSubNav from './InfluenceCrmSubNav/InfluenceCrmSubNav';
import { store } from '../../Store';
import { useContext } from 'react';
import { useEffect } from 'react';
import useGetAllDomains from './api/useGetAllDomains';
import useGetContactsQntty from './api/useGetContactsQntty';
import useGetContactsTags from '../Filters/useGetContactsTags';
import InfluenceSectionTitle from './InfluenceSectionTitle/InfluenceSectionTitle';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../Login/Loading';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom-v5-compat';
import WritingMyContent from '../../WritingAssistant/WritingMyContent';

function InfluenceCrm() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { hideInfluenceSidebar, activePlan, crmContactsQtty, crmEmailsTableOnSort } = state;
  const { totalCrmContacts } = activePlan;
  const contactsQntty = useGetContactsQntty();
  const { verifiedDomains, allDomains, domainsProccessing, allEmailAddresses, verifiedEmailAddresses } =
    useGetAllDomains();

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const sort = query.get('sort') ? query.get('sort').split('_') : null;

  useGetContactsTags();

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'crmContactsQtty',
      value: contactsQntty,
    });
  }, [contactsQntty]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'contactsOversubscribed',
      value: totalCrmContacts - crmContactsQtty,
    });
  }, [crmContactsQtty, totalCrmContacts]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'listDomainsVerified',
      value: {
        verifiedDomains,
        allDomains,
        domainsProccessing,
        allEmailAddresses,
        verifiedEmailAddresses,
      },
    });
  }, [verifiedDomains, allDomains, domainsProccessing, allEmailAddresses, verifiedEmailAddresses]);

  useEffect(() => {
    if (!sort) {
      if (location.pathname === '/influence/emails' && !crmEmailsTableOnSort?.sort?.length) {
        query.set('sort', `lastUpdated_descending`);
        navigate(
          {
            pathname: location.pathname,
            search: `${query.toString()}`,
          },
          { replace: true }
        );
      }
    }
  }, [location.pathname]);

  const hideSubnav = location.pathname.includes('/influence/contacts/update/');
  const hideSidebar = location.pathname.includes('/influence/contacts/update/');
  const hideSectionTitle = location.pathname.includes('/influence/contacts/update/');

  return (
    <>
      {!hideSubnav && <InfluenceCrmSubNav />}
      {!hideSectionTitle && <InfluenceSectionTitle />}
      <div className={`row mx-auto main-content-wrapper pb-lg-0 flex-grow-1 `}>
        <div
          className={`side-left-element d-none ${
            hideInfluenceSidebar || location.pathname.includes('/policymakers') ? '' : 'd-lg-block'
          }`}
        >
          {!hideSidebar && <InfluenceCrmSideBar />}
        </div>
        <Outlet />
        <Routes>
          <Route element={<NoMatchPage />} />
        </Routes>
      </div>
    </>
  );
}

const InfluenceWritingAssistant = () => {
  return (
    <div className='main-content h-100 dashboard-root-container'>
      <WritingMyContent individualPage={true} />
    </div>
  );
};

const NoMatchPage = () => {
  return <h3 className='px-5'>404 - Not found</h3>;
};

export { InfluenceWritingAssistant };
export default withAuthenticationRequired(InfluenceCrm, {
  onRedirecting: () => <Loading />,
  returnTo: `/login-success?returnUrl=${encodeURIComponent(
    `${window.location.pathname}${window.location.search}${window.location.hash}`
  )}`,
});
