import { useEffect, useState } from 'react';

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const changeDimentions = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', changeDimentions);
    return () => {
      window.removeEventListener('resize', changeDimentions);
    };
  }, [windowHeight, windowWidth]);
  return { windowHeight, windowWidth };
};
export default useWindowHeight;
