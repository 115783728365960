import React, { useContext } from 'react';
import normalize from '../../utilities/normalizeString';
import removeHyphenFromString from '../../utilities/removeHyphenFromString';
import { store } from '../../../Store';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import { useLocation } from 'react-router-dom-v5-compat';

const SortMode = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;

  const { filter, selectOption, list, notIncludeName, inDropdown } = props;
  const { name, options, optionSelected } = filter;
  const location = useLocation();

  const selectedInDropdown =
    location.hash.length > 0
      ? removeHyphenFromString(
          location.hash
            .split('~')
            .find((str) => str.startsWith('#'))
            .substring(1)
        )
      : optionSelected;

  const ImagePadlock = (props) => {
    const { item, customText } = props;
    return (
      <span
        className='ml-1 icon-padlock'
        data-tooltip-content={customText ?? `To sort by ${item.tiptext}, use the search bar to apply a Topic`}
        data-tooltip-id={'general-tooltip'}
      />
    );
  };

  const { agencyUser } = useTopicOrClientWord();

  return (
    <>
      {!notIncludeName && <h3>{name}</h3>}
      {options
        .filter((item) => !item.hidden)
        .map((option, index) => {
          const { name, applyToSearch, additional, description, activePlanCondition } = option;
          const enableOption = !activePlanCondition || activePlan[activePlanCondition];
          return (
            <div
              className={`radio-button mb-2 sort-mode-radio-stakeholders ${inDropdown ? 'filter-item-dropdown' : ''}`}
              key={`sort-filter${index}`}
            >
              <input
                id={normalize(name)}
                onChange={() => {
                  if (list !== null) {
                    let newValue = name;
                    let newFilter = {
                      ...filter,
                      options: options.map((item) => {
                        if (item.hidden) {
                          return { ...item, hidden: false };
                        } else {
                          return item;
                        }
                      }),
                    };
                    selectOption({ filter: newFilter, newValue });
                  }
                }}
                type='radio'
                checked={inDropdown ? selectedInDropdown === name : optionSelected === name}
                disabled={!enableOption}
              />
              <label htmlFor={normalize(name)}>
                <p className={`${(list === null && !applyToSearch) || !enableOption ? 'unselectable-option' : ''}`}>
                  <strong>{name}</strong> {additional ?? ''}
                  {((list === null && !applyToSearch) || !enableOption) && (
                    <ImagePadlock
                      item={option}
                      customText={
                        !enableOption
                          ? `Upgrade to ${agencyUser ? '' : 'Mogul or '}Enterprise for sentiment analysis`
                          : null
                      }
                    />
                  )}
                </p>
                <p className='label-description'>{description}</p>
              </label>
            </div>
          );
        })}
    </>
  );
};
export default SortMode;
