import getUrlParam from '../../Dashboard/utilities/getUrlParam';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import { useContext } from 'react';
import { store } from '../../Store';
import { Link, useLocation } from 'react-router-dom-v5-compat';

const TopicItem = (props) => {
  const {
    item,
    applyTopic,
    index,
    startMoveLists,
    setListPosition,
    listPosition,
    setStartMoveLists,
    topic,
    searchValue,
    showTopicSelected,
    showActions = true,
  } = props;
  const isMobile = useIsMobile();
  const location = useLocation();

  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const { myself } = team;
  const { transformTopicOrClientWord, topicOrClientWord, agencyUser } = useTopicOrClientWord();

  return (
    <div
      className={`${startMoveLists && listPosition === index ? 'dropdown-keywordlist-highlighted' : ''}`}
      onClick={() => {
        applyTopic({ topic: item });
      }}
      onMouseOver={() => {
        if (!isMobile) {
          if (!startMoveLists) {
            setStartMoveLists(true);
          }
          setListPosition(index);
        }
      }}
    >
      <li
        className={`dropdown-keywordlist d-flex ${
          item.description && item.description.trim() !== '' ? 'dropdown-keywordlist-description' : ''
        }`}
      >
        <span className={`icon-topics-thin`} />
        <div className='flex-grow-1'>
          <p className='hidden-lines hidden-one-line dropdown-name'>{item.name}</p>
          {item.description && <p className='dropdown-description description-field'>{item.description}</p>}
          {topic?.id === item.id &&
            (searchValue === '' || location.pathname.startsWith('/analytics/')) &&
            (!!getUrlParam('topic-id') || showTopicSelected) && <span className='icon-tick topic-selected-mark' />}
        </div>
        <div className='dropdown-list-actions'>
          {(myself.role === 'Admin' || myself.role === 'Owner' || item.isMyList) && item.id !== null && showActions && (
            <Link
              to={`/settings/${transformTopicOrClientWord({
                plural: true,
              })}/${item.id}?returnUrl=${encodeURIComponent(location.pathname)}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className='general-button dropdown-edit-keyword-list flex-centered'
                data-tooltip-content={`Edit this ${topicOrClientWord}${agencyUser ? "'s keywords" : ''}`}
                data-tooltip-id={'general-tooltip'}
                data-tooltip-place={'top'}
                data-tooltip-class-name={'edit-icon-analytics-tooltip'}
              >
                <span className='icon-edit-pencil' />
              </button>
            </Link>
          )}
        </div>
      </li>
    </div>
  );
};

export default TopicItem;
