import React, { useContext, useEffect, useRef, useState } from 'react';
import idsWithoutImages from '../../../../key_updates/idsWithoutImages';
import { locationTopicDescriptor } from '../../../../helpers/locationSearchDescriptor';
import useRememberPosition from '../../../../hooks/useRememberPosition';
import { useMainSectionParameter } from '../../../../helpers/useMainSectionParameter';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../../../../Store';
import normalize from '../../../../utilities/normalizeString';
import TextForCell from '../../../tableComponents/TextForCell';
import TagsCell from '../../../tableComponents/TagsCell';
import CrmContactDummyItem from './CrmContactDummyItem';
import useNewlyAddedContacts from '../../../../hooks/useNewlyAddedContacts';
import { normalizedDateUtc } from '../../../../utilities/normalizedDateUK';
import { Link, useLocation, useParams } from 'react-router-dom-v5-compat';
import ChampionStatus from '../../../tableComponents/ChampionStatus';

const CrmContactItem = React.forwardRef(
  (
    {
      item,
      scrollBarRef,
      selectedCrmContacts,
      setSelectedCrmContacts,
      setPreviousStoredSelectedCrmContacts,
      contactIds,
      hitsToRender,
      dummyItem,
      setShowAddTagsModal,
      activeIndex,
    },
    rowRef
  ) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { activePlan, contentResults, activeResults } = state;
    const { enableStakeholders } = activePlan;
    const { CrmContacts } = contentResults[activeResults];

    const { title, imageId, salutation, email, createdAtUtc, role, description, addedByUserFullName, id } = item;
    const location = useLocation();
    const params = useParams();
    const { searchResultsSection } = useMainSectionParameter(params);
    const { isAuthenticated } = useAuth0();
    const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;

    const isSelected = selectedIds.includes(item.id) || selectedAll || selectedAllVisible.includes(item.id);

    const { checkContactNewlyAdded, resetNewlyAddedContacts } = useNewlyAddedContacts();
    const [newlyAdded, setNewlyAdded] = useState(checkContactNewlyAdded(id));

    const titleCellRef = useRef();
    const roleCellRef = useRef();
    const descriptionCellRef = useRef();
    const emailCellRef = useRef();
    const dateCellRef = useRef();
    const addedByCellRef = useRef();
    const salutationCellRef = useRef();
    const tagsCellRef = useRef();

    const handleCheck = (e) => {
      if (e.target.checked) {
        if (selectedAllVisible.length > 0) {
          const newSelectedIds = [...selectedAllVisible, item.id];
          setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
          setSelectedCrmContacts({
            selectedAll: false,
            selectedIds: [],
            selectedAllVisible: newSelectedIds,
          });
        } else {
          const newSelectedIds = [...selectedIds, item.id];
          setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
          setSelectedCrmContacts({
            selectedAll: false,
            selectedIds: newSelectedIds,
            selectedAllVisible: [],
          });
        }
      } else {
        if (selectedAll) {
          const newSelectedIds = contactIds.filter((id) => id !== item.id);
          setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
          setSelectedCrmContacts({
            selectedIds: newSelectedIds,
            selectedAll: false,
            selectedAllVisible: [],
          });
        } else if (selectedAllVisible.length > 0) {
          const newSelectedIds = selectedAllVisible.filter((id) => id !== item.id);
          setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
          setSelectedCrmContacts({
            selectedIds: [],
            selectedAll: false,
            selectedAllVisible: newSelectedIds,
          });
        } else {
          const newSelectedIds = selectedIds.filter((id) => id !== item.id);
          setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
          setSelectedCrmContacts({
            selectedAll: false,
            selectedIds: newSelectedIds,
            selectedAllVisible: [],
          });
        }
      }
    };

    const locationDescriptor = (path) => {
      return {
        pathname: path,
        search:
          !enableStakeholders || !isAuthenticated
            ? ''
            : searchResultsSection || location.pathname.startsWith('/influence/contacts')
              ? `?topic-id=all`
              : locationTopicDescriptor(location),
        hash: '',
      };
    };

    const { rememberPosition } = useRememberPosition();

    useEffect(() => {
      let removeNewlyAddedClass;
      if (newlyAdded) {
        removeNewlyAddedClass = setTimeout(() => {
          setNewlyAdded(false);
        }, 3000);
      }
      return () => {
        if (removeNewlyAddedClass) {
          clearInterval(removeNewlyAddedClass);
          resetNewlyAddedContacts();
        }
      };
    }, []);

    const emailText = email ? (
      role?.includes('MP') && email?.endsWith('parliament.uk') ? (
        <>
          <span>{email.toLowerCase()}</span>
        </>
      ) : (
        email.toLowerCase()
      )
    ) : (
      ''
    );

    if (dummyItem) {
      return <CrmContactDummyItem dummyItem={dummyItem} />;
    }

    const updateChampionStatus = ({ id, value }) => {
      let newContacts = { ...CrmContacts };
      const contactPosition = newContacts?.hits?.findIndex((item) => item?.id === id);
      if (contactPosition >= 0) {
        let newHits = [...newContacts?.hits];
        newHits[contactPosition] = {
          ...newHits[contactPosition],
          championStatus: value,
        };
        newContacts = {
          ...newContacts,
          hits: newHits,
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              CrmContacts: newContacts,
            },
          },
        });
      }
    };

    return (
      <tr
        className={`crm-table-item table-item-alignment-adjustment ${newlyAdded ? 'crm-table-item-newly-added' : ''}`}
        ref={rowRef}
      >
        <td>
          <div className={`crm-table-item-checkbox ${selectedAll ? 'crm-table-item-checkbox-selected-all' : ''}`}>
            <input
              type={'checkbox'}
              checked={isSelected}
              onChange={handleCheck}
              id={`crmContact-${item.id}`}
              disabled={selectedAll}
            />
            <label htmlFor={`crmContact-${item.id}`} />
          </div>
        </td>
        <td className='crm-table-item-name' ref={titleCellRef}>
          <div>
            <CrmContactImage imageId={imageId} />
            <Link
              className='content-item-link'
              to={
                item.contactId
                  ? locationDescriptor(`/stakeholders/${item.contactId}/${normalize(title)}`)
                  : `/influence/contacts/update/${id}${location.search}`
              }
              style={{
                whiteSpace: 'nowrap',
              }}
              onClick={() => {
                rememberPosition('Stakeholders', scrollBarRef);
              }}
            >
              <TextForCell text={title} ref={titleCellRef} type={'title'} />
            </Link>
          </div>
        </td>
        <td className='crm-table-item-champion-status'>
          <ChampionStatus
            updateChampionStatus={updateChampionStatus}
            contact={item}
            scrollBarPosition={scrollBarRef?.current?.scrollTop}
            conditionsToClose={[scrollBarRef?.current?.scrollLeft, activeIndex]}
          />
        </td>
        <td ref={roleCellRef}>
          <TextForCell text={role} ref={roleCellRef} />
        </td>
        <td ref={descriptionCellRef} id={1}>
          <TextForCell text={description} ref={descriptionCellRef} />
        </td>
        <td ref={emailCellRef} className={`position-relative ${contactHolMemberCondition ? 'pr-5' : ''}`}>
          <TextForCell text={emailText} ref={emailCellRef} />
          {contactHolMemberCondition(email) && (
            <ContactHolMemberInfoIcon
              className='main-subtle-text'
              style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '15px' }}
            />
          )}
        </td>
        <td
          className='crm-table-item-tags'
          style={{ cursor: item?.tags?.length > 0 ? undefined : 'pointer' }}
          ref={tagsCellRef}
        >
          <TagsCell
            ref={tagsCellRef}
            hitsToRender={hitsToRender}
            item={item}
            setShowAddTagsModal={setShowAddTagsModal}
            setPreviousStoredSelectedCrmContacts={() => setPreviousStoredSelectedCrmContacts(selectedCrmContacts)}
            setSelectedCrmContacts={setSelectedCrmContacts}
            selectedCrmContacts={selectedCrmContacts}
          />
        </td>
        <td ref={dateCellRef}>{normalizedDateUtc(createdAtUtc).format('D MMM, YYYY')}</td>
        <td ref={addedByCellRef}>
          <TextForCell text={addedByUserFullName} ref={addedByCellRef} />
        </td>
        <td ref={salutationCellRef}>
          <TextForCell text={salutation} ref={salutationCellRef} />
        </td>
      </tr>
    );
  }
);

export const CrmContactImage = (props) => {
  const { imageId, size } = props;
  return (
    <div
      className='crm-table-img'
      style={{
        backgroundImage:
          imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
            ? `url('https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1')`
            : `url('${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png')`,
        backgroundSize: size ? size : '30px',
        backgroundPosition: 'center center',
        height: size ? size : '30px',
        minWidth: size ? size : '30px',
        borderRadius: '50%',
      }}
    />
  );
};

const contactHolMemberCondition = (email) => email === 'contactholmember@parliament.uk';
const ContactHolMemberInfoIcon = ({ style = {}, className = '' }) => {
  return (
    <span
      className={`icon-info ${className}`}
      data-tooltip-content={
        'Approximately 200 peers use the generic email address contactholmember@parliament.uk. It is not possible to send bulk emails to this address'
      }
      data-tooltip-id={`tooltip-mobile`}
      data-tooltip-class-name={`header-tooltip pricing-tooltip-description pricing-tooltip-description`}
      style={{
        ...style,
      }}
    ></span>
  );
};

export { ContactHolMemberInfoIcon, contactHolMemberCondition };

export default CrmContactItem;
