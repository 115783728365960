import React, { useContext } from 'react';
import Popup from '../../../Common/PopUp';
import useEmailComponentFunctions from './useEmailComponentFunctions';
import { Portal } from 'react-portal';
import { store } from '../../../Store';
import thousandCommas from '../../helpers/thousandCommas';

const ContentPopup = ({ closeMicroModal, contactHolMemberCondition, emailSelectedContacts }) => {
  const { isLoading, saveEmail } = useEmailComponentFunctions();
  return (
    <div className='py-3 pt-4'>
      <h3 className='topic-title section-title pl-lg-5 pl-4 pb-3 mb-0'>Ready to send your email?</h3>
      <div className='popup-body px-3 px-lg-5 text-center mt-4'>
        {contactHolMemberCondition() ? (
          <p>{contactHolMemberCondition()}</p>
        ) : (
          <p>
            Your email will be sent to {emailSelectedContacts?.length}{' '}
            {emailSelectedContacts?.length === 1 ? 'person' : 'people'}
          </p>
        )}
      </div>

      <div className='mt-4 d-flex justify-content-center pb-lg-2 '>
        <button
          className='general-button px-4 py-2 main-subtle-text border rounded light-blue-background-004-hover'
          onClick={closeMicroModal}
        >
          {contactHolMemberCondition() ? 'Edit recipients' : 'Cancel'}
        </button>
        <button
          className='general-button main-button px-4 py-2 ml-4 light-blue-background-08-hover'
          onClick={async (e) => {
            let sendAction = await saveEmail({ isForSending: true });
            if (sendAction) {
              closeMicroModal();
            }
          }}
          style={{
            backgroundColor: '#0094cc',
            border: 'none',
          }}
        >
          {isLoading === 'send' ? (
            <i className='fas fa-spinner fa-spin' style={{ marginRight: '2px' }}></i>
          ) : contactHolMemberCondition() ? (
            'Confirm send'
          ) : (
            'Send'
          )}
        </button>
      </div>
    </div>
  );
};

const SendPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { emailSelectedContacts } = state;

  const contactHolMemberCondition = () => {
    const contactHolMemberCount = emailSelectedContacts.filter(
      (item) => item.email === 'contactholmember@parliament.uk'
    ).length;
    if (contactHolMemberCount >= 2) {
      return `${thousandCommas(contactHolMemberCount)} recipients in this email campaign use the generic email address contactholmember@parliament.uk. As bulk emails to one address are not possible, ${thousandCommas(contactHolMemberCount)} recipients will appear as 'Not sent' in your email performance statistics.`;
    } else {
      return false;
    }
  };

  return (
    <Portal>
      <Popup {...props} maxWidth={contactHolMemberCondition() ? 500 : 450}>
        <ContentPopup
          {...props}
          emailSelectedContacts={emailSelectedContacts}
          contactHolMemberCondition={contactHolMemberCondition}
        />
      </Popup>
    </Portal>
  );
};

export default SendPopup;
