import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const CrmEmailsTableSkeleton = (props) => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <table
        style={{
          width: 'calc(100% + 2.5rem)',
          border: '1px solid rgba(212, 212, 212, 0.6)',
          borderRight: 'none',
          borderRadius: '5px 0 0 0',
        }}
        className='crm-table-emails my-lg-2'
      >
        <tbody>
          {Array(12)
            .fill()
            .map((item, index) => (
              <CrmEmailItemSkeleton key={index} header={index === 0} />
            ))}
        </tbody>
      </table>
    </SkeletonTheme>
  );
};

const CrmEmailItemSkeleton = ({ header }) => {
  return (
    <tr className='crm-table-item' style={{ minHeight: '57px', width: '100%' }}>
      <td
        style={{
          padding: header ? '10px 23px 10px 20px' : header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
        }}
      >
        <div style={{ width: '322px' }}>
          <Skeleton height={16} width={45} />
        </div>
      </td>
      <td
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '160px',
          padding: header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
        }}
      >
        <Skeleton height={26} width={69} />
      </td>
      <td
        style={{
          padding: header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
        }}
      >
        <div style={{ width: '176px' }}>
          <Skeleton height={18} width={'75%'} />
        </div>
      </td>
      <td
        className='crm-table-item-tags'
        style={{
          padding: header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
          width: '140px',
        }}
      >
        <div style={{ width: '96px' }}>
          <Skeleton height={18} width={'25%'} />
        </div>
      </td>
      <td
        style={{
          padding: header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
        }}
      >
        <div style={{ width: '94px' }}>
          <Skeleton height={18} width={'50%'} />
        </div>
      </td>
      <td
        style={{
          padding: header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
        }}
      >
        <div style={{ width: '94px' }}>
          <Skeleton height={18} width={'50%'} />
        </div>
      </td>
      <td
        style={{
          padding: header ? '10px 23px 10px 20px' : '14px 23px 13px 20px',
        }}
      >
        <div style={{ width: '94px' }}>
          <Skeleton height={18} width={'50%'} />
        </div>
      </td>
    </tr>
  );
};

export { CrmEmailItemSkeleton };
export default CrmEmailsTableSkeleton;
