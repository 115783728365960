import React, { useEffect, useState, useContext } from 'react';
import { store } from '../../Store.js';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import normalize from '../../Dashboard/utilities/normalizeString';
import TeamContentComponent from './TeamContentUI';
import callTeamsContent from '../../Dashboard/apiCalls/callTeamsContent.js';
import SkeletonMyTeamsContent from '../../Dashboard/Influence/skeletons/SkeletonMyTeamsContent.js';
import { useLocation } from 'react-router-dom-v5-compat';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const TeamContent = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    // navigate(`${location.pathname}`)
    loadResults(source);
    return () => {
      source.cancel('API call cancel by the user');
    };
  }, []);

  const loadResults = async (source) => {
    try {
      setIsLoading(true);
      let token = isAuthenticated ? await getAccessToken() : '';
      let results = await callTeamsContent({ token, source });
      if (!!results) {
        let finalResults = compileTotalResults(results);
        setResults(finalResults);

        let title = `Lobbying material - Influence - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'locationToStorage',
          value: locationToStorage,
        });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const compileTotalResults = (results) => {
    let finalItems = [];
    results.forEach((item) => {
      const { id, title, askerUserId, publishedAt, type } = item;
      let personType = !!askerUserId ? 'asker' : 'owner';
      let returnUrl = `?returnUrl=${encodeURIComponent(location.pathname)}`;
      const createURLParameter = () => {
        switch (type) {
          case 'PolicyAsk':
            return 'policy-asks';
          case 'BriefingMaterial':
            return 'briefing-material';
          case 'NewsRelease':
            return 'news-release';
          default:
            return type.toLowerCase();
        }
      };
      let urlParameter = createURLParameter();
      finalItems.push({
        ...item,
        organisation: item[`${personType}Organisation`],
        imageUrl: item?.clientLogo === null ? false : item?.clientLogo ?? item[`${personType}OrganisationLogo`],
        link:
          publishedAt === null
            ? `/influence/${urlParameter}/edit/${id}/1${returnUrl}`
            : `/${urlParameter}/${id}/${normalize(title)}`,
        draft: publishedAt === null,
      });
    });
    return finalItems;
  };

  const filtersOptions = [
    {
      name: 'Any type of content',
    },
    {
      name: 'Policy asks',
      type: 'PolicyAsk',
    },
    {
      name: 'Briefing material',
      type: 'BriefingMaterial',
    },
    {
      name: 'Research',
      type: 'Research',
    },
    {
      name: 'News releases',
      type: 'NewsRelease',
    },
    /* {
      name: 'Drafts',
      draft: true
    } */
  ];

  const applyFilters = async (item) => {
    setActiveFilters(item.type ? [item] : []);
    setIsLoading(true);
    let token = isAuthenticated ? await getAccessToken() : '';
    let results = await callTeamsContent({ token, source, type: item?.type });
    if (results) {
      setResults(compileTotalResults(results));
    }
    setIsLoading(false);
  };

  const checkedFilters = () => {
    let checked = [];
    activeFilters.forEach((item) => {
      checked.push(item.name);
    });
    return checked;
  };

  const filteredResultsBySearch = (results, searchString) => {
    const filteredResults = [];
    const resultsStrArr = results.map((item) => JSON.stringify(item));
    const searchStringArr = searchString.split(' ');
    resultsStrArr.forEach((resultItem) => {
      const resultItemValid = searchStringArr.every((searchString) => resultItem.toLowerCase().includes(searchString));
      if (resultItemValid) {
        filteredResults.push(JSON.parse(resultItem));
      }
    });
    return filteredResults;
  };

  const resultsSearch = query.get('search') ? decodeURIComponent(query.get('search')) : '';

  const resultsToRender =
    resultsSearch && results && location.pathname.includes('/influence')
      ? filteredResultsBySearch(results, resultsSearch)
      : results;

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'teamContentResultsQntty',
      value: resultsToRender?.length,
    });
  }, [location, resultsSearch, results]);

  return (
    <>
      {isLoading && <SkeletonMyTeamsContent />}

      {!isLoading && (
        <TeamContentComponent
          totalResults={resultsToRender}
          results={resultsToRender}
          activeFilters={activeFilters}
          filtersOptions={filtersOptions}
          applyFilters={applyFilters}
          checkedFilters={checkedFilters()}
        />
      )}
    </>
  );
};

export default TeamContent;
