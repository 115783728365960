import parliamentarianUsers from '../../../Dashboard/sidebar/parliamentarianUsers.js';
import TransformFromPascalCase from '../../Utilities/TransformFromPascalCase.js';
import transformToPascalCase from '../../Utilities/TransformToPascalCase.js';

const changeItemsName = (item) => {
  switch (item) {
    case 'Upcoming events':
      return 'Events';
    case 'Library material':
      return 'CommonsLibraryArticles';
    default:
      return transformToPascalCase(item);
  }
};

const createResourcesName = (contentTypesToReceive, referenceValues, code) => {
  //AE: Transform resources for the website feeds component

  const transformReferenceValues = (referenceValues) => {
    return referenceValues.map((item) => changeItemsName(item));
  };

  let resources = referenceValues
    ? transformReferenceValues(referenceValues)
    : [
        'KeyUpdates',
        'Consultations',
        'Legislation',
        'HansardContent',
        'AnsweredWrittenQuestion',
        'UnansweredWrittenQuestion',
        'Tweets',
        'UserContent',
        'CommonsLibraryArticles',
        'Edms',
      ];

  let resourcesToReceive = contentTypesToReceive.split(', ');
  let retweetsPosition = resourcesToReceive.indexOf('Retweets');
  if (retweetsPosition >= 0) {
    resourcesToReceive.splice(retweetsPosition, 1);
  }
  if (process.env.REACT_APP_ENABLE_TWITTER === 'false') {
    let tweetsPosition = resources.indexOf('Tweets');
    if (tweetsPosition >= 0) {
      resources.splice(tweetsPosition, 1);
    }

    let contentTweetsPosition = resourcesToReceive.indexOf('Tweets');
    if (contentTweetsPosition >= 0) {
      resourcesToReceive.splice(contentTweetsPosition, 1);
    }
  }

  let exceptResources = resources.filter((type) => !resourcesToReceive.includes(type));
  const correctResourceName = (name) => {
    switch (name) {
      case 'AnsweredWrittenQuestion':
        return 'Answered written questions';
      case 'UnansweredWrittenQuestion':
        return 'Newly tabled written questions';
      case 'HandPickedKeyUpdates':
        return 'Key updates';
      case 'HansardContent':
        return 'Official records';
      case 'UserContent':
        return code && parliamentarianUsers(code) ? 'Campaigns' : 'Lobbying material';
      case 'CommonsLibraryArticles':
        return 'Library material';
      case 'Edms':
        return 'Early Day Motions';
      default:
        return TransformFromPascalCase(name);
    }
  };
  const createResourcesValue = () => {
    if (exceptResources.length === 1) {
      return `Everything except ${correctResourceName(exceptResources[0])}`;
    } else if (exceptResources.length === 2) {
      if (
        exceptResources.includes('AnsweredWrittenQuestion') &&
        exceptResources.includes('UnansweredWrittenQuestion')
      ) {
        return `Everything except Written questions and answers`;
      }
      return `Everything except ${correctResourceName(exceptResources[0])} and ${correctResourceName(exceptResources[1])}`;
    } else {
      resourcesToReceive =
        resourcesToReceive.includes('AnsweredWrittenQuestion') &&
        resourcesToReceive.includes('UnansweredWrittenQuestion')
          ? [
              `Written questions and answers`,
              ...resourcesToReceive.filter(
                (item) => item !== 'AnsweredWrittenQuestion' && item !== 'UnansweredWrittenQuestion'
              ),
            ]
          : resourcesToReceive;
      return `${resourcesToReceive.map((item) => correctResourceName(item)).join(', ')}`;
    }
  };
  return resourcesToReceive.length >= resources.length ? 'Everything' : createResourcesValue();
};

export { changeItemsName };
export default createResourcesName;
