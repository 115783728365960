import React, { useContext, useEffect, useRef, useState } from 'react';
import useValidateContactsOversubscribed from '../../../../utilities/useValidateContactsOversubscribed';
import useContactsApiCalls from '../../useContactsApiCalls';
import Axios from 'axios';
import { store } from '../../../../../Store';
import useOutsideElement from '../../../../utilities/useOutsideElement';

function RemoveTagsFromContacts({
  expandedButton,
  isLoading,
  onlyIconOnMobile,
  setShowRemoveContactsModal,
  setShowRemoveContactTagsModal,
  setShowChampionsStatusModal,
}) {
  const [openOptions, setOpenOptions] = useState(false);
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedContactTags, selectedCrmContacts, contentResults, activeResults } = state;
  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();
  const { getContactTagsByQuery } = useContactsApiCalls();

  const containerElement = useRef();
  useOutsideElement(containerElement, () => setOpenOptions(false));

  const getTags = async (source) => {
    let tagsRes = [];
    if (selectedAll) {
      const tagsArray = await getContactTagsByQuery(source);
      if (tagsArray) {
        tagsRes = tagsArray;
      }
    } else {
      let idsArr = selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds.length > 0 ? selectedIds : null;
      if (idsArr) {
        let tagsSet = new Set();
        idsArr.forEach((id) => {
          let contactTags = contentResults[activeResults].CrmContacts?.hits.find((contact) => contact.id === id)?.tags;
          if (contactTags?.length > 0) {
            contactTags.forEach((tag) => tagsSet.add(tag));
          }
        });
        tagsRes = [...tagsSet];
      }
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedContactTags',
      value: tagsRes,
    });
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getTags(source);
    return () => {
      source.cancel('Contact tags canceled by the user.');
    };
  }, [selectedCrmContacts]);

  return (
    <div className='contacts-list-bar-action-main-container' ref={containerElement}>
      <button
        className={`contacts-list-bar-action-container-btn ${expandedButton === 'remove' ? 'action-container-btn-expanded' : ''}`}
        onClick={() => {
          setOpenOptions(!openOptions);
        }}
        disabled={isLoading}
      >
        {onlyIconOnMobile && expandedButton !== 'remove' ? '' : 'More'}
        <span
          className={`icon-arrow-down icon-arrow-down${openOptions ? '-expanded' : ''} icon-arrow-down${isLoading ? '-disabled' : ''}`}
        />
      </button>
      {openOptions && (
        <div className='login-navbar py-2'>
          <button
            className='settings-button setting-item general-button d-block text-left w-100 position-relative'
            onClick={() => {
              setOpenOptions(!openOptions);
              validateContactsOversubscribed(() => setShowRemoveContactTagsModal(true), 'remove');
            }}
            disabled={selectedContactTags.length === 0}
          >
            <span className={`icon-tag ${isLoading ? 'icon-tag-disabled' : ''} mr-3`} />
            Remove tags
          </button>
          <button
            className='settings-button setting-item general-button d-block text-left w-100 position-relative'
            onClick={() => {
              setOpenOptions(!openOptions);
              setShowChampionsStatusModal();
            }}
          >
            <span className={`icon-edit-pencil-thick mr-3 d-inline-block-centered`} />
            Change champion status
          </button>
          <button
            className='settings-button setting-item general-button d-block text-left w-100 position-relative'
            onClick={() => {
              setOpenOptions(!openOptions);
              validateContactsOversubscribed(() => setShowRemoveContactsModal(true), 'remove');
            }}
          >
            <span className={`icon-close ${isLoading ? 'icon-close-disabled' : ''} mr-3`} />
            Remove from contacts
          </button>
        </div>
      )}
    </div>
  );
}

export default RemoveTagsFromContacts;
