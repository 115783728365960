import MicroModal from 'micromodal';
import React from 'react';
import { useEffect } from 'react';

function SalutationModal({ hideSalutationModal }) {
  useEffect(() => {
    MicroModal.show('resume-topic-email-alert-1', {
      awaitCloseAnimation: true,
    });
  }, []);

  const closeMicroModal = (e) => {
    e.stopPropagation();
    MicroModal.close('resume-topic-email-alert-1');
    setTimeout(() => {
      hideSalutationModal();
    }, 300);
  };

  return (
    <div
      className={`modal micromodal-slide topic-modal create-download-chart-simple-modal`}
      id={`resume-topic-email-alert-1`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={(e) => {
            closeMicroModal(e);
          }}
        />
        <div
          className='modal__container pt-4'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
          style={{ minHeight: 0, maxWidth: '597px' }}
        >
          <div>
            <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3'>Salutation</h3>
            <div className='px-lg-5 px-3'>
              <div className='remove-contacts-wrapper pt-4'>
                <p className='salutation-modal-text'>
                  Salutation placeholders are used when sending email campaigns to multiple people, to ensure the
                  appropriate salutation is sent to each one.
                </p>
              </div>
            </div>
            <div className='salutation-modal-content-container'>
              <p className='salutation-modal-content-title'>Example</p>
              <div className='salutation-modal-content-img'></div>
            </div>
          </div>

          <button
            className='close-button-filters general-button webfeed-popup-close'
            type='button'
            onClick={(e) => {
              closeMicroModal(e);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default SalutationModal;
