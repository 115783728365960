import React, { useEffect, useRef, useState } from 'react';
import ParliamentarianNameAutocomplete from './ParliamentarianNameAutocomplete';
const RegistrationUi = (props) => {
  const { errorMessage } = props;
  return (
    <div className='create-profile-container px-0 needs-verification-container create-parliamentarian-container'>
      <div className='scroll-container h-100 create-profile-general-content'>
        <h2 className='company-name'>Policy Mogul</h2>
        <div className='d-flex justify-content-center align-items-center w-100 py-lg-2 py-lg-5 pt-5'>
          <div className='mt-5 mt-lg-0'>
            <div className='px-5 py-4 create-profile-content'>
              <div className='pt-4'>
                {errorMessage !== '' ? (
                  <h3 className='title-h4-m-bold mb-0'> {errorMessage} </h3>
                ) : (
                  <RegistrationFields {...props} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RegistrationFields = (props) => {
  const { formFields, modifyValue, registerUser, actionText, codeInformation } = props;

  const createMarkup = (body) => {
    return { __html: body };
  };

  const elements = useRef([]);
  const [passwordType, setPasswordType] = useState('password');
  const ShowPassword = () => {
    return (
      <button
        className={`indented-button general-button show-password-button show-password-${
          passwordType === 'password' ? 'hidden' : 'shown'
        }`}
        tabIndex={-1}
        onClick={() => {
          setPasswordType(passwordType === 'password' ? 'text' : 'password');
        }}
      >
        Show password
      </button>
    );
  };

  const focusInitialElement = useRef();
  const parliamentarianAutocomplete = useRef();

  focusInitialElement.current = () => {
    let fields = formFields.filter((item) => !item.hidden);
    if (fields[0].type === 'autocomplete') {
      parliamentarianAutocomplete.current.focus();
    } else {
      let objectKeys = Object.keys(elements.current);
      let element = elements.current[objectKeys[0]];
      if (element) {
        element.focus();
      }
    }
  };

  useEffect(() => {
    focusInitialElement.current();
  }, []);

  return (
    <>
      <h2 className='mb-4 pb-1'>
        {codeInformation?.userType === 'Prospective parliamentary candidate'
          ? 'Create your PPC account'
          : 'Create your profile'}
      </h2>
      <form autoComplete='off'>
        {formFields.map((item) => {
          const { value, disabled, required, property, type, name, errorMessage, hidden } = item;
          return (
            <div className={`mb-3 form-group form-item-${property}`} key={`form-${property}`}>
              {property !== 'termsAndConditions' && !hidden && (
                <label htmlFor={property}>
                  {name} {required && <span className='text-red'>*</span>}
                </label>
              )}
              {type !== 'autocomplete' && type !== 'checkbox' && !hidden && (
                <>
                  <input
                    name={property}
                    id={property}
                    className={'form-control'}
                    type={type === 'password' ? passwordType : type}
                    value={value}
                    required={required ? required : false}
                    disabled={disabled ? disabled : false}
                    autoComplete={type === 'password' ? 'new-password' : 'off'}
                    onChange={(e) => {
                      modifyValue(property, e.target.value);
                    }}
                    ref={(input) => (elements.current[property] = input)}
                  />
                  {type === 'password' && <ShowPassword />}
                </>
              )}
              {type === 'autocomplete' && (
                <ParliamentarianNameAutocomplete
                  property={property}
                  modifyValue={modifyValue}
                  itemValue={value}
                  ref={parliamentarianAutocomplete}
                />
              )}
              {property === 'termsAndConditions' && (
                <div className='pt-1 pb-3'>
                  <p className='checkbox-item mb-0'>
                    <input
                      id={property}
                      onChange={(e) => {
                        modifyValue(property, e.target.checked);
                      }}
                      type='checkbox'
                      checked={value}
                    />
                    <label className='font-weight-normal terms-conditions-label' htmlFor={property}>
                      I agree to the{' '}
                      <a
                        href='https://about.policymogul.com/terms-and-conditions/'
                        target='_blank'
                        className='simple-link-button'
                        rel='noopener noreferrer'
                      >
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        href='https://about.policymogul.com/privacy-policy/'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='simple-link-button'
                      >
                        Privacy Policy
                      </a>
                    </label>
                  </p>
                </div>
              )}
              {errorMessage && (
                <div className='mt-2 general-error-message'>
                  <p className='mb-0 message py-2 px-3' dangerouslySetInnerHTML={createMarkup(errorMessage)} />
                </div>
              )}
            </div>
          );
        })}
        <button
          className='general-button action-button general-button-spacing mb-5'
          onClick={(e) => {
            e.preventDefault();
            registerUser(e);
          }}
        >
          {actionText ? actionText : 'Log in'}
        </button>
      </form>
    </>
  );
};

export { RegistrationFields };
export default RegistrationUi;
