import React, { useRef, useState } from 'react';
import Popup from '../../Common/PopUp';
import RelatedContentContainer from '../UserContent/RelatedContentContainer';
import CustomScrollbar from '../../Common/CustomScrollbar';

const ContentPopup = ({ insertContentLink }) => {
  const [associatedContent, setAssociatedContent] = useState([]);

  const propsForRelatedContentContainer = {
    type: 'research',
    compiledSteps: [],
    isReadyToPreview: false,
    sectionName: 'Associated content',
    sectionValues: {
      fields: [
        {
          name: 'relatedContent',
          value: [],
          required: false,
        },
      ],
    },
    nextText: 'Preview',
    disabledNavigation: false,
    itemInformation: {},
    validateFields: true,
    editPageId: null,
    deleteFunction: null,
    validateEditChanges: true,
    InfluenceState: [],
    allowOrganisationWebsite: true,
    keywordsLists: [],
    teamContent: [],
  };
  const scrollBarRef = useRef(null);

  return (
    <div className='modal-with-header-title'>
      <p className='popup-header px-3 px-lg-5'>Search for content to insert</p>
      <div className='separator' />
      <div className='popup-body'>
        <CustomScrollbar
          ref={scrollBarRef}
          className={'simple-scrollbar '}
          style={{ height: `${530}px` }}
          maximalThumbYSize={100}
        >
          <div className='row mx-auto influence-general-container'>
            <div className='influence-content-container flex-grow-1'>
              <div
                className='mx-auto'
                style={{
                  maxWidth: '960px',
                }}
              >
                <div
                  className='simple-box influence-creation_content'
                  style={{
                    minHeight: `${530}px`,
                    margin: '0 auto',
                    maxWidth: '960px',
                  }}
                >
                  <RelatedContentContainer
                    associatedContent={associatedContent}
                    setAssociatedContent={setAssociatedContent}
                    inModal
                    insertContentLink={insertContentLink}
                    {...propsForRelatedContentContainer}
                  />
                </div>
              </div>
            </div>
          </div>
        </CustomScrollbar>
      </div>
    </div>
  );
};

const InsertContentLinkModal = (props) => {
  return (
    <Popup {...props} identifier={'insert-content-link'}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default InsertContentLinkModal;
