import React from 'react';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import CustomScrollbar from '../../Common/CustomScrollbar';

const SaveItemsSideNav = (props) => {
  const { setShowPopup, folders, changeFolder, currentFolder } = props;
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <div className={`main-navigation settings-navigation pt-4`}>
      <div className='navigation-container d-flex justify-content-between flex-column'>
        <div
          className='sidebar-nav pr-3 d-flex flex-column'
          ref={containerRef}
          style={{ height: `${heightContainer}px` }}
        >
          <h3 className='section-sidebar-subtitle pl-2 pl-lg-5 mb-3'>Folders</h3>
          <div className='flex-grow-1'>
            <CustomScrollbar className={'simple-scrollbar h-100'} maximalThumbYSize={100}>
              <div className='pb-2'>
                <button
                  className='general-button add-folder-button text-light-blue mb-2'
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  Create a new folder
                </button>

                <ul className='px-0 mb-2'>
                  {folders.map((item) => (
                    <SideNavItem
                      key={`item-${item.id}`}
                      item={item}
                      currentFolder={currentFolder}
                      changeFolder={changeFolder}
                    />
                  ))}
                </ul>
              </div>
            </CustomScrollbar>
          </div>
        </div>
      </div>
    </div>
  );
};

const SideNavItem = (props) => {
  const { item, currentFolder, changeFolder, hideActions } = props;
  const { name, id, itemCount } = item;

  return (
    <li
      className={`px-2 pl-lg-5 sidebar-item save-item-side-nav pointer ${currentFolder.id === id ? 'active' : ''}`}
      onClick={() => {
        if (currentFolder.id !== id) {
          changeFolder(item);
        }
        if (hideActions) {
          hideActions();
        }
      }}
    >
      <p
        className='mb-0 side-nav-saved-title hidden-lines hidden-one-line'
        data-tooltip-content={name.length > 26 ? name : ''}
        data-tooltip-id={'general-tooltip'}
      >
        {name}
      </p>
      <p className='main-light-text mb-0 parragraph-p3'>
        {/* Private<span className="dot"></span> */}
        {itemCount ? itemCount : 0} item{itemCount === 1 ? '' : 's'}
      </p>
    </li>
  );
};

export { SideNavItem };
export default SaveItemsSideNav;
