const CandidatesFilters = [
  {
    name: 'Party',
    pages: ['Candidates'],
    additionalItemsText: 'parties',
    searchState: 'always',
    filterName: 'party',
    optionSelected: '',
    expandedByDefault: true,
    options: [
      {
        name: 'Conservative',
        selected: true,
      },
      {
        name: 'Labour',
        selected: true,
      },
      {
        name: 'Liberal Democrat',
        hash: 'lib-dem',
        selected: true,
      },
      {
        name: 'Reform UK',
        selected: true,
      },
      {
        name: 'Scottish National Party',
        hash: 'snp',
      },
      {
        name: 'Alliance',
      },
      {
        name: 'Democratic Unionist Party',
        hash: 'dup',
      },
      {
        name: 'Green Party',
        hash: 'green',
      },
      {
        name: 'Independent',
      },
      {
        name: 'Independent Social Democrat',
      },
      {
        name: 'Labour Independent',
      },
      {
        name: 'Plaid Cymru',
      },
      {
        name: 'Sinn Féin',
      },
      {
        name: 'Social Democratic & Labour Party',
        hash: 'sdlp',
      },
      {
        name: 'Ulster Unionist Party',
      },
      {
        name: 'Workers Party of Britain',
      },
    ],
    prefix: 'party',
  },
  {
    name: 'Role',
    pages: ['Candidates'],
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'categories',
    optionSelected: 'PPCs for 2024/25',
    hidden: true,
    options: [
      {
        name: 'PPCs for 2024/25',
        filterValue: 'PPC 2024/25',
        hash: 'ppc',
        selected: true,
      },
    ],
    prefix: 'role',
  },
  {
    name: 'Sort',
    searchState: 'allResults',
    type: 'sort',
    pages: ['Candidates'],
    hidden: true,
    optionSelected: 'Last name ascending',
    options: [
      {
        name: 'Last name ascending',
        filter: [
          {
            pages: ['Candidates'],
            property: { field: 'lastName', order: 'ASC' },
          },
        ],
        selected: true,
      },
    ],
  },
];

export default CandidatesFilters;
