import MicroModal from 'micromodal';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { store } from '../../../../Store';
import useGeneralApiCall from '../../../apiCalls/useGeneralApiCall';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import useGetSectionFilters from '../../../Filters/useGetSectionFilters';
import { useLocation } from 'react-router-dom-v5-compat';
import thousandCommas from '../../../helpers/thousandCommas';
import useNewlyAddedContacts from '../../../hooks/useNewlyAddedContacts';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import { useCallContactIdsResults } from '../../../StakeHolders/StakeHolderList';

function RemoveContactsModal({ resetSelectedContacts = () => {}, totalHits = 0, initialCall }) {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { crmContactsQtty, selectedCrmContacts, removedContactsQntty, deleteStakeholderId } = state;
  const [isLoading, setIsLoading] = useState(false);
  const { setContactIdsCheck, contactIdsCheck } = useCallContactIdsResults();

  const { resetNewlyAddedContacts } = useNewlyAddedContacts();

  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const searchQuery = query.get('search');

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const contactsCount = deleteStakeholderId
    ? 1
    : selectedAllVisible.length > 0
      ? selectedAllVisible.length
      : selectedIds.length > 0
        ? selectedIds.length
        : totalHits;

  const { generalApiCall } = useGeneralApiCall();

  const hideRemoveContactsModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'deleteStakeholderId',
      value: null,
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactsModal',
      value: false,
    });
  };

  useEffect(() => {
    MicroModal.show('resume-topic-email-alert', {
      awaitCloseAnimation: true,
    });
  }, []);

  const closeMicroModal = () => {
    MicroModal.close('resume-topic-email-alert');
    setTimeout(() => {
      hideRemoveContactsModal();
    }, 300);
  };

  //API CALL TO REMOVE CONTACTS FROM LIST
  const removeFromContactLists = async () => {
    setIsLoading(true);
    const requestProperties = deleteStakeholderId
      ? {
          stakeholderContactIds: [deleteStakeholderId],
        }
      : selectedAll
        ? {
            filters: filters,
            query: searchQuery,
          }
        : {
            ids: selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds,
          };
    let pathname = deleteStakeholderId
      ? '/api/crm-contact/delete-by-stakeholder-contact-id'
      : selectedAll
        ? '/api/crm-contact/delete-by-query'
        : '/api/crm-contact/delete';
    let method = 'post';
    try {
      let results = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
      });
      setIsLoading(false);
      if (selectedAll) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'clearFilters',
          value: true,
        });
      } else {
        initialCall?.current();
      }
      createNotification(
        'success',
        `${thousandCommas(contactsCount)} contact${contactsCount > 1 ? 's' : ''} successfully removed`
      );
      resetSelectedContacts();
      resetNewlyAddedContacts();
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'crmContactsQtty',
        value: crmContactsQtty - contactsCount,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'removedContactsQntty',
        value: removedContactsQntty + contactsCount,
      });
      if (deleteStakeholderId) {
        const newContactIdsCheck = {
          ...contactIdsCheck,
          [deleteStakeholderId]: false,
        };
        setContactIdsCheck(newContactIdsCheck);
      }

      closeMicroModal();
      return results;
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`modal micromodal-slide topic-modal create-download-chart-simple-modal`}
      id={`resume-topic-email-alert`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div
          className='modal__container pt-4'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
          style={{ minHeight: 'unset', maxWidth: '558px' }}
        >
          <RemoveContactsContent
            contactsCount={contactsCount}
            closeMicroModal={closeMicroModal}
            isLoading={isLoading}
            removeFromContactLists={removeFromContactLists}
          />

          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

const RemoveContactsContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const { contactsCount, closeMicroModal, isLoading, removeFromContactLists } = props;
  const contactsText = contactsCount === 1 ? `this contact` : `these ${thousandCommas(contactsCount)} contacts`;
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <div>
      <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
        {newAgencyPlan
          ? `Remove ${contactsText} from ${team?.teamName}'s contact list`
          : `Are you sure you want to remove ${contactsText}`}{' '}
        ?
      </h3>
      <div className='px-lg-5 px-3'>
        <div className='remove-contacts-wrapper pt-4'>
          <p className='remove-contacts-title main-subtle-text'>
            By removing contacts from your contact list, you will lose any tags and notes associated with them, and will
            no longer be able to send emails or perform data exports.
          </p>
        </div>
      </div>
      <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
        <span className='remove-contacts-helper-text-icon mr-2' />
        These changes impact all members of your team
      </p>
      <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4'>
        <button onClick={closeMicroModal} className='danger-button-outlined px-3 py-2 mr-3' disabled={isLoading}>
          Cancel
        </button>
        <button onClick={removeFromContactLists} className='danger-button px-3 py-2' disabled={isLoading}>
          {isLoading ? (
            <i
              className='fas fa-spinner fa-spin'
              style={{
                margin: '0 41.2px',
                color: '#fcfcfc',
                marginTop: '2px',
                width: '33.7px',
              }}
            ></i>
          ) : contactsCount === 1 ? (
            `Remove contact`
          ) : (
            `Remove ${thousandCommas(contactsCount)} contacts`
          )}
        </button>
      </div>
    </div>
  );
};

export { RemoveContactsContent };
export default RemoveContactsModal;
