import React, { useRef } from 'react';
const SearchFilter = (props) => {
  const { filter, selectOption } = props;
  const { value } = filter;
  const inputBox = useRef(null);
  return (
    <>
      <h3>Search within results</h3>
      <div className={`search-filters-text d-flex align-items-center`}>
        <button className='search-filters-icon-inside search-filters-icon general-button ml-2 mr-2'>Search</button>
        <input
          className='form-control'
          type='text'
          ref={inputBox}
          value={value}
          onChange={(e) => {
            let newValue = e.target.value;
            selectOption({ filter, newValue });
          }}
        />
        <div
          className='close-icon-container'
          style={{ visibility: value !== '' ? 'visible' : 'hidden' }}
          onClick={() => {
            let newValue = '';
            selectOption({ filter, newValue });
            setTimeout(() => {
              inputBox.current.focus();
            }, 100);
          }}
        >
          <button className='general-button close-icon' />
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
