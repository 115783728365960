import { useContext } from 'react';
import { store } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';

const useOrganizationInfoDescriptionFunctions = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { team } = state ?? {};
  const { generalApiCall } = useGeneralApiCall();

  const updateOrganizationInfoDescription = async ({ value, keywordListId, teamId }) => {
    let method = 'post';
    let pathname = keywordListId
      ? `/api/keyword-list/client-organisation-description/${keywordListId}`
      : `/api/team/update-organisation-description`;
    let requestProperties = { description: value };
    if (teamId) {
      requestProperties = { ...requestProperties, teamId: teamId };
    }
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    if (result) {
      if (!keywordListId) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'team',
          value: { ...team, organisationDescription: result?.organisationDescription },
        });
      }
      return result;
    }
  };

  const removeAndUpdateOrganizationDescription = async ({ value, keywordListId, teamId }) => {
    let removeResult = await removeOrganizationDescription({ keywordListId, teamId });
    if (removeResult.status === 200) {
      let updateResult = await updateOrganizationInfoDescription({
        value,
        keywordListId,
        teamId,
      });
      return updateResult;
    }
  };

  const removeAndUpdateTeamAdminOrganizationDescription = async ({ value, teamId, keywordListId }) => {
    let removeResult = await removeTeamAdminOrganizationDescription({
      teamId,
      keywordListId,
    });
    if (removeResult.status === 200) {
      let updateResult = await updateTeamAdminDescription({
        value,
        teamId,
        keywordListId,
      });
      return updateResult;
    }
  };

  const removeTeamAdminOrganizationDescription = async ({ teamId, keywordListId }) => {
    let method = 'delete';
    let pathname = keywordListId
      ? `/api/admin/client-organisation-description/${keywordListId}`
      : `/api/admin/organisation-description/${teamId}`;
    const result = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      returnCompleteRequest: true,
    });
    if (result?.status === 200) {
      return result;
    }
  };

  const removeOrganizationDescription = async ({ keywordListId, teamId }) => {
    let method = keywordListId ? 'delete' : 'post';
    let pathname = keywordListId
      ? `/api/keyword-list/client-organisation-description/${keywordListId}`
      : `/api/team/remove-organisation-description${teamId ? `?teamId=${teamId}` : ''}`;
    const result = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      returnCompleteRequest: true,
    });
    if (result?.status === 200) {
      if (!keywordListId) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'team',
          value: { ...team, organisationDescription: result?.organisationDescription },
        });
      }
      return result;
    }
  };

  const getClientDescription = async ({ keywordListId }) => {
    let method = 'get';
    let pathname = `/api/keyword-list/client-organisation-description/${keywordListId}`;
    const result = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
    });
    if (result) {
      return result;
    }
  };

  const updateTeamAdminDescription = async ({ value, teamId, keywordListId }) => {
    let method = 'post';
    let pathname = keywordListId
      ? `/api/admin/client-organisation-description/${keywordListId}`
      : `/api/admin/organisation-description/${teamId}`;
    let requestProperties = { description: value };
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    if (result) {
      return result;
    }
  };

  return {
    updateOrganizationInfoDescription,
    removeOrganizationDescription,
    getClientDescription,
    removeAndUpdateOrganizationDescription,
    updateTeamAdminDescription,
    removeAndUpdateTeamAdminOrganizationDescription,
    removeTeamAdminOrganizationDescription,
  };
};

export default useOrganizationInfoDescriptionFunctions;
