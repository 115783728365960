const removeHyphenFromString = (string) => {
  let items = string.split('-');
  items[0] = items[0].charAt(0).toUpperCase() + items[0].slice(1);
  items.forEach((item) => {
    if (item === 's') {
      let position = items.indexOf(item);
      items[position - 1] = items[position - 1] + `'s`;
      items.splice(position, 1);
    }
  });
  let finalValue = items.join(' ');
  return finalValue;
};

export default removeHyphenFromString;
