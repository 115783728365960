const detectURImalformed = (string) => {
  if (string) {
    try {
      return decodeURIComponent(string);
    } catch (error) {
      let encoded = encodeURIComponent(string);
      return decodeURIComponent(encoded);
    }
  }
  return '';
};

export default detectURImalformed;
