import MicroModal from 'micromodal';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { store } from '../../../../Store';
import useGeneralApiCall from '../../../apiCalls/useGeneralApiCall';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import { useRef } from 'react';

function AddNoteModal({ hideAddNotesModal, selectedCrmContacts }) {
  const { selectedIds } = selectedCrmContacts;
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ type: '', message: '' });

  const { generalApiCall } = useGeneralApiCall();
  const dataElements = useRef([]);
  useEffect(() => {
    MicroModal.show('resume-topic-email-alert', {
      awaitCloseAnimation: true,
    });
  }, []);

  const closeMicroModal = () => {
    MicroModal.close('resume-topic-email-alert');
    setTimeout(() => {
      hideAddNotesModal();
    }, 300);
  };

  const addNote = () => {
    let validate = validateForm();
    if (validate) {
      saveNote();
    }
  };

  const validateForm = () => {
    let elements = dataElements.current;
    let fields = Object.keys(elements);
    for (let i = 0; i < fields.length; i++) {
      let field = elements[fields[i]];
      let val = field.value;
      if (field.required && val === '') {
        setError({ type: fields[i], message: 'Please complete this field' });
        return false;
      }
    }
    return true;
  };

  const Error = () => {
    return (
      <div className='error-message px-0 px-lg-2 col-lg-4 mt-2 mt-lg-0'>
        <img className='mr-3' src={`${process.env.REACT_APP_CDNURL}/images/warning-icon.svg`} alt='warning-icon' />
        <p className='mb-0 message py-2 px-3'>{error.message}</p>
      </div>
    );
  };

  const handleChange = (e) => {
    if (e?.target?.name === error?.type) {
      setError({ type: '', message: '' });
    }
  };

  // API CALL TO ADD TAGS TO CONTACTS
  const saveNote = async () => {
    setIsLoading(true);

    let note = dataElements.current['note'].value;
    const ptId = contentResults[activeResults]?.CrmContacts?.hits?.find(
      (contact) => contact?.id === selectedIds[0]
    )?.contactPtId;
    const objectId = `Stakeholder-${ptId}`;

    try {
      const requestProperties = {
        title: '',
        contentType: 'Stakeholder',
        objectId: objectId,
        activityType: 'Note',
        text: note,
      };
      let pathname = '/api/crm-activity/create';
      let method = 'post';
      let results = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
      });

      if (results) {
        closeMicroModal();
        createNotification(
          'success',
          `Note added to ${
            contentResults[activeResults]?.CrmContacts?.hits?.find((contact) => contact?.id === selectedIds[0])?.title
          } successfully`
        );
      }
    } catch (error) {
      let warningMessage = 'Error occurred, please try again later';
      if (error?.response?.message) {
        warningMessage = error?.response?.message;
      }
      createNotification('danger', warningMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`modal micromodal-slide topic-modal create-download-chart-simple-modal`}
      id={`resume-topic-email-alert`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div
          className='modal__container pt-4'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
          style={{ minHeight: 'unset' }}
        >
          <div>
            <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3'>
              {`Add note about ${
                contentResults[activeResults]?.CrmContacts?.hits?.find((contact) => contact?.id === selectedIds[0])
                  ?.title
              }
              `}
            </h3>
            <div className='px-lg-5 px-3'>
              <div className='px-3 pb-4 pt-5'>
                <div className={`account-list px-0 pb-2`}>
                  <div className='pr-3' id='settings-container'>
                    <ul className='policy-list px-0 hover-content'>
                      <form className='mx-0'>
                        <div className='px-0'>
                          <li style={{ padding: 0 }}>
                            <div className='account-email-field'>
                              <textarea
                                className='col-15 add-note-modal-textarea'
                                type='text'
                                style={{ overflow: 'hidden', resize: 'none' }}
                                ref={(input) => (dataElements.current['note'] = input)}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                name='note'
                                required
                              />
                            </div>
                            {error.type === 'note' && <Error />}
                          </li>
                        </div>
                      </form>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white'>
              <button onClick={addNote} className='general-button action-button px-3 py-2' disabled={isLoading}>
                {isLoading ? (
                  <i
                    className='fas fa-spinner fa-spin'
                    style={{
                      margin: '0 auto',
                      color: '#fcfcfc',
                      marginTop: '2px',
                      width: '33.7px',
                    }}
                  ></i>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </div>

          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddNoteModal;
