import React from 'react';
import DropdownComponent from '../../../Settings/WebsiteFeeds/DropdownComponent';
import normalize from '../../../Dashboard/utilities/normalizeString';

const AdminTopicOwner = (props) => {
  const { teamMembers, topicInformation, setTopicInformation } = props;
  const { ownerUserId } = topicInformation;
  const setTopicOwner = (id) => {
    setTopicInformation({ ...topicInformation, ownerUserId: id });
  };

  const TeamMembersList = () => {
    return (
      <div className='keyword-lists-website-feeds'>
        <div className='scroll-container hover-container' style={{ maxHeight: '200px' }}>
          <div className='hover-content px-3 pt-2 pb-3'>
            {teamMembers.map((item) => {
              let { id, fullName, lastName, firstName } = item;
              if (!fullName) {
                fullName = `${firstName} ${lastName}`;
              }
              return (
                <div className={`radio-button uponclick-radio-button`} key={`topicItem-${id}`}>
                  <input
                    id={`${fullName ? normalize(fullName) : normalize(lastName)}-${id}`}
                    onChange={() => {
                      setTopicOwner(id);
                    }}
                    type='radio'
                    checked={id === ownerUserId}
                  />
                  <label htmlFor={`${fullName ? normalize(fullName) : normalize(lastName)}-${id}`}>
                    {fullName || lastName}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const getMainItem = () => {
    const owner = teamMembers.find((item) => item.id === ownerUserId);
    const { fullName, lastName, firstName } = owner;
    return fullName || `${firstName} ${lastName}`;
  };
  return (
    <div className='topic-items-container padding-topic-component py-5'>
      <h3 className='topic-title mb-2'>Owner of the topic</h3>
      <DropdownComponent items={TeamMembersList} mainItem={getMainItem()} />
    </div>
  );
};

export default AdminTopicOwner;
