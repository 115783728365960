import FiltersData from './FiltersData';

const enforceUpdateStoredFilters = (mainSection) => {
  let storedFilters = localStorage.getItem('contentFilters');
  if (storedFilters && JSON.parse(storedFilters)[mainSection]) {
    let filtersToCompare = JSON.parse(storedFilters)[mainSection];
    let originalFilters = JSON.parse(JSON.stringify(FiltersData()));
    if (filtersToCompare.length < originalFilters.length) {
      return true;
    }
    for (let i = 0; i < filtersToCompare.length; i++) {
      let filter = filtersToCompare[i];
      let originalFilter = originalFilters[i];
      let lengthObjectCondition = Object.keys(filter).length !== Object.keys(originalFilter).length;
      let pagesObjectCondition = originalFilter.pages.length !== filter.pages.length;
      let searchStateCondition = originalFilter.searchState !== filter.searchState;
      if (lengthObjectCondition || pagesObjectCondition || searchStateCondition) {
        return true;
      }
    }
  }
  return false;
};

export default enforceUpdateStoredFilters;
