import React from 'react';

function TopClickedLinks({ mostClickedLinks, hasLink }) {
  const renderLink = ({ url, totalCount }) => {
    return (
      <div key={`${url}-${totalCount}`} className='top-clicked-links-row'>
        <span className='email-performance-link' style={{ marginLeft: 0 }}>
          {url}
        </span>
        <span className='email-performance-clicks'>{totalCount}</span>
      </div>
    );
  };
  return (
    <div className='email-performance-section-container-top'>
      <div className='email-performance-rate-header'>Top clicked links</div>
      <div className='top-clicked-links-table'>
        {hasLink ? (
          mostClickedLinks.length > 0 ? (
            <>
              <div className='top-clicked-links-row top-clicked-links-table-header'>
                <span>LINK</span>
                <span>CLICKS</span>
              </div>
              {mostClickedLinks
                .sort(function (a, b) {
                  return b.totalCount - a.totalCount;
                })
                .map((item) => {
                  return renderLink(item);
                })}
            </>
          ) : (
            <p className='top-clicked-links-empty-state'>No one has clicked any links yet</p>
          )
        ) : (
          <p className='top-clicked-links-empty-state'>No links were included in this email</p>
        )}
      </div>
    </div>
  );
}

export default TopClickedLinks;
