import React, { useState, useEffect, useRef, useContext } from 'react';
import createNotification from '../../../Settings/Utilities/CreateNotification';
import { store } from '../../../Store';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import useGetContactsTags from '../../Filters/useGetContactsTags';

const TagsCell = React.forwardRef((props, ref) => {
  const { item, hitsToRender, setShowAddTagsModal } = props;
  const [containerWidth, setContainerWidth] = useState(null);
  const [addElipsis, setAddElipsis] = useState(false);

  const containerRef = useRef();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, activeResults, selectedCrmContacts } = state;

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const isSelectedCrmContacts = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;
  const { generalApiCall } = useGeneralApiCall();

  const { upadateContactTags } = useGetContactsTags({ notCallInitially: true });

  useEffect(() => {
    if (ref?.current && containerWidth) {
      setAddElipsis(ref.current.offsetWidth < containerWidth + 31);
    }
  });

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth);
    }
  }, [containerRef, ref?.current?.offsetWidth]);

  //API CALL TO REMOVE TAG FROM CONTACT
  const removeTagFromContact = async (id, tags, tag) => {
    const requestProperties = {
      id,
      tags: tags.length > 0 ? tags : null,
    };
    let pathname = '/api/crm-contact/update';
    let method = 'post';
    try {
      let results = await generalApiCall({
        method,
        pathname,
        requestProperties,
        needsAuthentication: true,
      });

      createNotification('success', `${tag} tag successfully removed`);
      //update contactsTags value in store in case if there is no more such tag
      upadateContactTags();
      return results;
    } catch (e) {}
  };

  const handleRemoveTag = (tag) => {
    const newTags = item.tags.filter((currentTag) => currentTag !== tag);
    const newHitsToRender = hitsToRender.map((stakeholder) => {
      if (stakeholder.id === item.id) {
        return { ...stakeholder, tags: newTags };
      } else {
        return stakeholder;
      }
    });

    //REMOVE TAG FROM GLOBAL STORE
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'contentResults',
      value: {
        ...contentResults,
        [activeResults]: {
          CrmContacts: {
            ...contentResults[activeResults].CrmContacts,
            hits: newHitsToRender,
          },
        },
      },
    });
    //REMOVE TAG SILENTLY WITHOUT REFRESHING TABLE
    removeTagFromContact(item.id, newTags, tag);
  };

  const handleAddTag = () => {
    setShowAddTagsModal({ show: true, preselectedContact: item.id });
  };

  if (item.tags.length > 0) {
    return (
      <>
        {!isSelectedCrmContacts && (
          <div className='add-tag-cell-container'>
            <span className='icon-add' onClick={handleAddTag} />
          </div>
        )}
        <div
          key={`tag-${item.contactId}-${item.contactId}`}
          className={`position-relative ${!isSelectedCrmContacts ? 'allow-add-tag' : ''} tag-cell-container`}
          data-tooltip-content={addElipsis ? item.tags.join(', ') : undefined}
          data-tooltip-id={addElipsis ? 'general-tooltip' : undefined}
          data-tooltip-class-name={addElipsis ? 'header-tooltip pricing-tooltip-description' : undefined}
        >
          <div className='tagsContainer' ref={containerRef}>
            {item.tags.map((tag, index) => {
              return (
                <span className='tag' key={`${tag}-${item.contactId}`}>
                  {tag}
                  {index !== item.tags.length - 1 ? ',' : ''}
                  <span
                    className='tag-remove'
                    data-tooltip-content='Remove tag from this stakeholder'
                    data-tooltip-id='general-tooltip'
                    data-tooltip-class-name={'header-tooltip pricing-tooltip-description'}
                    onClick={() => {
                      handleRemoveTag(tag);
                    }}
                  />
                </span>
              );
            })}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        {!isSelectedCrmContacts && (
          <div className='add-tag-cell-container'>
            <span className='icon-add' onClick={handleAddTag} />
          </div>
        )}

        <div
          key={`tag-${item.contactId}-tagCell`}
          className={`position-relative ${!isSelectedCrmContacts ? 'allow-add-tag' : ''} tag-cell-container`}
        >
          <div className='tagsContainer'></div>
        </div>
      </div>
    );
  }
});

export default TagsCell;
