import React, { useRef, useState } from 'react';
import InfluenceItem from '../../Dashboard/UserContent/ui/InfluenceListItem';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import NoResultsTeam from './NoResultsTeam';
import normalize from '../../Dashboard/utilities/normalizeString';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import CustomScrollbar from '../../Common/CustomScrollbar';
import { useLocation, Link } from 'react-router-dom-v5-compat';

const TeamContentComponent = (props) => {
  const { results, activeFilters, filtersOptions, applyFilters, checkedFilters } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const [showFilters, setShowFilters] = useState(false);
  const hideFilters = () => {
    setShowFilters(false);
  };
  const filtersElement = useRef(null);
  const location = useLocation();
  useOutsideElement(filtersElement, hideFilters);

  const influenceSection = location.pathname.includes('influence');
  return (
    <div className={`${influenceSection ? 'pl-md-4 pl-3' : 'pt-lg-5'}`}>
      <>
        <div
          className={`d-flex justify-content-between align-items-center pt-lg-0 pr-lg-4 ${
            influenceSection ? 'influence-team-content-header' : 'py-4'
          }`}
        >
          {!influenceSection && <h3 className='main-title topics-title mb-0 pl-2'>My team's content</h3>}
          <div
            className={`position-relative filter-main-container d-flex ${
              influenceSection ? 'influence-team-content-btn-container pl-md-4' : ''
            }`}
            ref={filtersElement}
          >
            {/* FILTERS */}
            {results.length > 0 && !influenceSection && (
              <Link
                to={`/influence/add-content?returnUrl=${encodeURIComponent(location.pathname)}`}
                className='action-button action-button mr-2 filter-action-button d-none d-lg-block'
                style={{ padding: '5px 1.5em' }}
              >
                Add lobbying material
              </Link>
            )}
            {(!influenceSection || (results.length > 0 && influenceSection)) && (
              <button
                className={`sort-button filter-button ${showFilters ? 'active' : ''} ${
                  activeFilters.length === 0 ? 'active-complete' : 'active-single'
                }  ${influenceSection ? 'type-of-content-influence-btn' : ''}`}
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {activeFilters.length === 0 && <span className='filter-name'>Type of content</span>}
                {activeFilters.length > 0 && (
                  <>
                    <span className='filter-name'>{activeFilters[0].name}</span>
                    {activeFilters.length > 1 ? ` +${activeFilters.length - 1}` : ''}
                    <span
                      className='general-button close-filter-active'
                      onClick={(e) => {
                        e.stopPropagation();
                        applyFilters({});
                        setShowFilters(false);
                      }}
                    >
                      Close
                    </span>
                  </>
                )}
              </button>
            )}
            {showFilters && (
              <div className={`box filters-container house-container`}>
                <button
                  className='close-button-filters general-button'
                  onClick={() => {
                    setShowFilters(false);
                  }}
                >
                  Close
                </button>

                <p className='title'>Type of content</p>

                <div className='filter-options-radio-container'>
                  {filtersOptions.map((item, index) => (
                    <div className='radio-button mb-2' key={`filter-${index}`}>
                      <input
                        id={`${normalize(item.name)}`}
                        onChange={() => {
                          applyFilters(item);
                        }}
                        type='radio'
                        checked={
                          item.name === 'Any type of content'
                            ? activeFilters.length === 0
                            : checkedFilters.includes(item.name)
                        }
                      />
                      <label htmlFor={`${normalize(item.name)}`}>{item.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div ref={containerRef} />
        <CustomScrollbar
          className={'simple-scrollbar'}
          style={{
            height: `${heightContainer}px`,
            marginLeft: influenceSection ? '-8px' : '',
          }}
          maximalThumbYSize={100}
        >
          <div className={`pt-2 pr-lg-4 px-2`}>
            {results.length === 0 && <NoResultsTeam {...props} influenceSection={influenceSection} />}
            {results.length > 0 && (
              <>
                {results.map((item, index) => (
                  <InfluenceItem key={`item${index}`} item={item} index={index} array={results} />
                ))}
              </>
            )}
          </div>
        </CustomScrollbar>
      </>
    </div>
  );
};

export default TeamContentComponent;
