import React, { useState } from 'react';
import Popup from '../../Common/PopUp';
import { Portal } from 'react-portal';

const DeleteUserPopupContent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { closeMicroModal, deleteUser } = props;
  return (
    <div className='px-lg-5 px-3 py-5 text-center'>
      <h3 className='topic-title topic-title-email-alerts mb-0'>Are you sure you want to delete this user?</h3>
      <div className='pt-4'>
        <button onClick={closeMicroModal} className='border border-grey rounded px-3 py-2 mr-3' disabled={isLoading}>
          Cancel
        </button>
        <button
          onClick={async () => {
            setIsLoading(true);
            await deleteUser();
            setIsLoading(false);
            closeMicroModal();
          }}
          className='general-button topic-delete-button px-3 py-2 border-general border-red-20'
          disabled={isLoading}
        >
          {isLoading && (
            <i
              className='fas fa-spinner fa-spin'
              style={{
                marginTop: '2px',
                width: '33.7px',
              }}
            />
          )}
          Delete user
        </button>
      </div>
    </div>
  );
};

const DeleteUserPopup = (props) => {
  return (
    <Portal>
      <Popup {...props} maxWidth={500}>
        <DeleteUserPopupContent {...props} />
      </Popup>
    </Portal>
  );
};

export default DeleteUserPopup;
