import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom-v5-compat';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import overallExternalTooltipHandler from './OverallExternalTooltip';
import { colours as mainColours } from '../../useCallOverallSentiment';
import dayjs from 'dayjs';
import { formatSimpleGeneralDate } from '../../../helpers/formatGeneralDate';
import { EmptyStateRateLimit } from '../../AnalyticsDashboardContainer';
import { store } from '../../../../Store';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const createLabelText = (reportDateInterval) => {
  const firstDate = reportDateInterval[0];
  const secondDate = reportDateInterval[1] ? dayjs(reportDateInterval[1]) : dayjs();
  let diff = dayjs(secondDate).diff(dayjs(firstDate), 'days');

  if (reportDateInterval) {
    if (diff > 364) {
      let years = dayjs(secondDate).diff(dayjs(firstDate), 'years');
      return `${years === 1 ? '' : years} year${years === 1 ? '' : 's'}`;
    } else if (diff < 364 && diff >= 29) {
      let months = dayjs(secondDate).diff(dayjs(firstDate), 'months');
      return `${months > 1 ? months : ''} month${months <= 1 ? '' : 's'}`;
    } else {
      return `${diff} days`;
    }
  } else {
    return `${process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS} days`;
  }
};

const PieOverallSentimentChart = React.forwardRef((props, ref) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsRateLimitReached } = state;
  const {
    data,
    clickFunction,
    hiddenItems,
    reportWidget,
    activeSections,
    totalHeight,
    reportDateInterval,
    heightGraph = 280,
  } = props;
  const emptyMessage = data?.value?.filter((item) => item > 0).length === 0;
  const location = useLocation();
  const dashboard = location.pathname.includes('/dashboard');
  const { labels, colours, value } = data;
  const totalValue = data?.value.reduce(
    (previousValue, currentValue) => previousValue + (typeof currentValue === 'number' ? currentValue : 0),
    0
  );

  const createData = () => {
    let options = {
      labels,
      datasets: [
        {
          label: 'Overall sentiment',
          data: value,
          backgroundColor: colours.map((item) => `rgba(${item}, 0.8)`),
          borderColor: colours.map((item) => `rgba(${item}, 1)`),
          borderWidth: 1,
        },
      ],
    };
    if (reportWidget) {
      options = {
        ...options,
        datasets: [
          {
            ...options?.datasets[0],
            hoverBackgroundColor: colours.map((item) => `rgba(${item}, 0.8)`),
            borderWidth: 0,
            hoverBorderWidth: 0,
          },
        ],
      };
    }
    return options;
  };

  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        padding: 10,
        displayColors: false,
        callbacks: {
          title: (context) => {
            return `${context[0]?.label}`;
          },
          label: function (context) {
            let sum = totalValue;
            let lf = new Intl.ListFormat('en-GB');
            let numberValue = (value[context.dataIndex] * 100) / sum;
            let valuePercentage = Math.round(numberValue);
            let percentage = valuePercentage + '%';
            return `${percentage} of contributions in the last ${process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS} days ${hiddenItems?.length ? `(with ${lf.format(hiddenItems.map((item) => item.toLowerCase()))} removed)` : ''}`;
          },
        },
      },
      datalabels: {
        display: true,
        align: 'end',
        offset: '-5',
        clip: true,
        color: function (context) {
          return labels[context.dataIndex] === 'Negative' ? '#fff' : '#00122BCC';
        },
        formatter: function (value, context) {
          return value > 0 ? labels[context.dataIndex] : '';
        },
      },
    },
    maintainAspectRatio: false,
    onClick: (e, item) => {
      if (clickFunction) {
        clickFunction(item);
      }
    },
  };

  if (reportWidget) {
    options = {
      ...options,
      animation: false,
    };
  } else {
    options = {
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.plugins.tooltip,
          external: overallExternalTooltipHandler,
        },
      },
    };
  }

  const labelsForReportWidget = useRef();
  const [labelsHeight, setLabelsHeight] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setLabelsHeight(labelsForReportWidget?.current?.getBoundingClientRect()?.height);
    }, [100]);
  }, [activeSections, labelsForReportWidget?.current]);

  return (
    <>
      {analyticsRateLimitReached ? (
        <EmptyStateRateLimit />
      ) : (
        <div
          style={{ height: totalHeight ? '100%' : dashboard ? '280px' : `${heightGraph}px` }}
          className={`pie-chart-graph-container position-relative ${emptyMessage ? 'flex-centered justify-content-center' : ''}`}
        >
          {emptyMessage ? (
            <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
              {analyticsRateLimitReached ? 'Try again soon or sign in for unlimited searches' : 'No data available'}
            </p>
          ) : (
            <div className='dashboard-root-container h-100 '>
              <div
                className='flex-grow-1 position-relative specific-graph-container'
                style={
                  reportWidget
                    ? { paddingLeft: '15%', paddingRight: '15%', height: `calc(100% - ${labelsHeight}px)` }
                    : null
                }
              >
                <Pie ref={ref} options={options} data={createData()} />
              </div>
              <div>
                {reportWidget && (
                  <div ref={labelsForReportWidget} className='pt-4'>
                    {activeSections?.map((section, index) => {
                      let titleLowerCase = section.toLowerCase();
                      let numberValue = (value[index] * 100) / totalValue;
                      let valuePercentage = Math.round(numberValue);
                      let percentage = valuePercentage + '%';
                      const createColor = () => {
                        switch (titleLowerCase) {
                          case 'neutral':
                            return '18, 43, 0, 0.5';
                          case 'positive':
                            return '128, 181, 66 , 1';
                          default:
                            return `${mainColours[titleLowerCase]}, 1`;
                        }
                      };
                      let color = `rgba(${createColor()})`;
                      return (
                        <React.Fragment key={`label-${titleLowerCase}`}>
                          {valuePercentage > 0 && (
                            <p className='mb-2 paragraph-p1 main-subtle-text'>
                              <span className='font-weight-bold' style={{ color: color }}>
                                {section}:{' '}
                              </span>{' '}
                              {percentage} of contributions in the {createLabelText(reportDateInterval)}{' '}
                              {`to ${formatSimpleGeneralDate(reportDateInterval[1] ?? dayjs())}`}
                            </p>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
});

export { createLabelText };
export default PieOverallSentimentChart;
