import Preloader from '../AnalyticsDashboard/Preloader';
import PieOverallSentimentChart from './PieOverallSentimentChart';
import OverallSentimentChartData from './OverallSentimentChartData';
import { useContext, useEffect, useRef, useState } from 'react';
import { ChartOptionsButton } from '../MentionsByTopic';
import AnalyticsTitle from '../AnalyticsTitle';
import HoverText from '../../HoverText';
import SmallPieChart from './SmallPieChart';
import { colours as SentimentColours } from '../../useCallOverallSentiment';
import uppercaseFirstLetter from '../../../utilities/uppercaseFirstLetter';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import PartyMetaData from '../MentionsByTopic/PartyMetadata';
import OverallSentimentRightPanel from '../../OverallSentimentRightPanel';
import CountryPicker from '../../MentionsCountryPicker';
import useModifyAnalyticsState from '../../useModifyAnalyticsState';
import CustomScrollbar from '../../../../Common/CustomScrollbar';
import { store } from '../../../../Store';
import thousandCommas from '../../../helpers/thousandCommas';
import { useAllowRightPanel, useChartDataOptionSelected } from '../MentionsByTopic/ChartDataTable';

const OverallSentiment = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { isLoading, data, analyticsState, isLoadedFirstTime, initialData, setData, widget, embedWidget } = props;
  const { chartDataOptionSelected, selectedCountries } = analyticsState;
  const { rawData, labels, colours } = data;
  const [activeSection, setActiveSection] = useState(null);
  const [activeSections, setActiveSections] = useState(['Positive', 'Negative', 'Mixed']);
  const [disableSelectSection, setDisableSelectSection] = useState(false);
  const [showAllParties, setShowAllParties] = useState(false);
  const [rightPanelState, setRightPanelState] = useState({ open: false });
  const { delayShowPanelTitle } = useAllowRightPanel();

  const { modifyState } = useModifyAnalyticsState();

  let setSelectedCountries = (val) => {
    modifyState({ property: 'selectedCountries', newValue: val });
  };

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);
  const chartRefContainer = useRef(null);
  const scrollBarContainer = useRef(null);
  const chartDataSection = useRef(null);
  const emptyMessage = !isLoading ? data?.value?.filter((item) => item > 0).length === 0 : false;
  const { chartDataOption } = useChartDataOptionSelected();

  const scrollFromChart = (item) => {
    const index = item?.[0]?.index;
    const sentiment = labels[index];
    const value = data?.value[index];
    const totalValue = data?.value.reduce(
      (previousValue, currentValue) => previousValue + (typeof currentValue === 'number' ? currentValue : 0),
      0
    );
    const percentage = (value * 100) / totalValue;
    if (!embedWidget) {
      if (value !== 0 && chartDataOption === 'Overall') {
        setRightPanelState({
          open: true,
          date: null,
          sentiment: sentiment,
          noResults: value === 0,
          rawData,
          text: `${value ? thousandCommas(value) : 0} of ${thousandCommas(totalValue)} ${`contribution${totalValue === 1 ? '' : 's'} (${Math.round(percentage ?? 0)}%) by parliamentarians`} in the last ${process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS} days were ${sentiment.toLowerCase()}`,
        });
        delayShowPanelTitle();
      } else {
        scrollBarContainer.current.scrollTop(chartDataSection.current.offsetTop);
      }
    }
  };

  const compileOverallSentimentData = (sections) => {
    let newData = JSON.parse(JSON.stringify(initialData));
    let finalValue = transformContributionsToInclude({ newData, sections });
    setData(finalValue);
  };

  const changeOrderTableLabel = (label, e) => {
    const element = e.target;
    const order = e.target.dataset.order;
    const column = label.toLowerCase();
    let rawData = JSON.parse(JSON.stringify(data.rawData));
    let newRawData = {};
    const getPercentage = (points, column) => {
      const allPointsFiltered = Object.entries(points)
        .filter((item) => activeSections.includes(uppercaseFirstLetter(item[0])))
        .map((item) => item[1])
        .reduce((a, b) => a + b, 0);
      const currPercentage = ((points[column] ?? 0) / allPointsFiltered) * 100;
      return currPercentage;
    };
    const rawDataArray = Object.values(
      Object.entries(rawData).sort(([, a], [, b]) => {
        let aPercentage = getPercentage(a, column);
        let bPercentage = getPercentage(b, column);
        return order === 'asc' ? (aPercentage ?? 0) - (bPercentage ?? 0) : (bPercentage ?? 0) - (aPercentage ?? 0);
      })
    );
    rawDataArray.forEach((item) => {
      newRawData[item[0]] = item[1];
    });
    setData({ ...data, rawData: newRawData });
    element.dataset.order = order === 'asc' ? 'desc' : 'asc';
    setActiveSection(null);
  };

  const hiddenItems = () => {
    return initialData.labels.filter((item) => !activeSections.includes(item));
  };

  const changeChartSelectedOption = () => {
    switch (chartDataOptionSelected) {
      case 'Party':
        return 'parties';
      case 'Parliamentary position':
        return 'positions';
      default:
        return `${chartDataOptionSelected.toLowerCase()}s`;
    }
  };

  useEffect(() => {
    compileOverallSentimentData(activeSections);
  }, [initialData]);

  useEffect(() => {
    if (!widget) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'chartState',
        value: { isLoading, chartRef, chartSection: chartContainerRef, data, emptyMessage },
      });
    }
    return () => {
      if (!widget) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'chartState',
          value: {},
        });
      }
    };
  }, [isLoading, data, chartRef]);

  const createSmallChartsKeys = () => {
    let keys = Object.keys(rawData);
    if (chartDataOptionSelected === 'Party' && !showAllParties) {
      return keys.filter((item) => PartyMetaData.showParties.includes(item));
    } else {
      return keys;
    }
  };

  const smallChartsKeys = createSmallChartsKeys();

  const checkIfEmptyChart = (data) => {
    if (data.value.length === 0 || data.value.filter((item) => item > 0).length === 0) {
      return { 'data-html2canvas-ignore': true };
    }
  };

  const overallOption = chartDataOption === 'Overall';

  return (
    <div className='d-flex w-100 h-100'>
      <div className={`h-100 bg-white flex-grow-1 rounded-top ${!widget && !embedWidget ? 'border' : ''}`}>
        <CustomScrollbar
          className={'simple-scrollbar smooth-scrolling'}
          maximalThumbYSize={100}
          ref={scrollBarContainer}
        >
          <div className='pb-4'>
            <div className='px-lg-5 px-3 position-relative sentiment-snapshot-container'>
              {isLoading && !isLoadedFirstTime.current && <Preloader />}
              {isLoadedFirstTime.current && (
                <>
                  {isLoading && <div className='loading-background' />}
                  {!widget && (
                    <>
                      <div className='d-flex align-items-lg-center justify-content-between my-5 flex-column flex-md-row'>
                        <div className='d-md-flex align-items-center'>
                          <div className='flex-centered'>
                            <AnalyticsTitle title={'Sentiment snapshot'} />
                            <ChartOptionsButton analyticsState={analyticsState} isLoading={isLoading} />
                          </div>
                          <div className='ml-md-3 mt-3 mt-md-0'>
                            <CountryPicker
                              selectedCountries={selectedCountries}
                              setSelectedCountries={setSelectedCountries}
                            />
                          </div>
                        </div>
                        {!embedWidget && (
                          <div className='d-none d-lg-flex align-items-center'>
                            <HoverText />
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <div ref={chartRefContainer} className='d-flex justify-content-between'>
                    {!emptyMessage && (
                      <div className='pie-chart-legend mb-4'>
                        <ContributionsToIncludeSelect
                          activeSections={activeSections}
                          setActiveSections={setActiveSections}
                          compileOverallSentimentData={compileOverallSentimentData}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`row mx-0 ${overallOption ? '' : 'mt-4'} justify-content-center PieOverallSentimentChart`}
                    ref={chartContainerRef}
                  >
                    <div className='my-4 px-2 pie-chart-container'>
                      <PieOverallSentimentChart
                        key={`pie-chart-${chartDataOptionSelected}`}
                        ref={chartRef}
                        data={data}
                        clickFunction={scrollFromChart}
                        activeSections={activeSections}
                        hiddenItems={hiddenItems()}
                        heightGraph={overallOption ? 380 : 220}
                      />
                      {!emptyMessage && !overallOption && (
                        <h3 className='title-h6-bold text-center mt-4'>All {changeChartSelectedOption()}</h3>
                      )}
                    </div>
                    {!emptyMessage && !overallOption && (
                      <>
                        {smallChartsKeys.map((item, index) => {
                          let content = rawData[item];
                          return (
                            <div
                              className='my-4 px-2 pie-chart-container'
                              {...checkIfEmptyChart({
                                labels,
                                colours,
                                value: labels.map((label) => content[label.toLowerCase()] ?? 0),
                              })}
                              key={`small-chart-${item}-${index}`}
                            >
                              <SmallPieChart
                                title={item}
                                data={{
                                  labels,
                                  colours,
                                  value: labels.map((label) => content[label.toLowerCase()] ?? 0),
                                }}
                                clickFunction={() => {
                                  scrollFromChart();
                                  setActiveSection(index);
                                  setDisableSelectSection(true);
                                  setTimeout(() => {
                                    setDisableSelectSection(false);
                                  }, 1000);
                                }}
                                activeSections={activeSections}
                                hiddenItems={hiddenItems()}
                              />
                            </div>
                          );
                        })}
                        {chartDataOptionSelected === 'Party' && !showAllParties && (
                          <div data-html2canvas-ignore='true' className='my-4 px-2 pie-chart-container'>
                            <button
                              className='general-button text-center show-all-parties-button'
                              onClick={() => {
                                setShowAllParties(!showAllParties);
                              }}
                            >
                              <span className='dropdown-item-element'>Show all parties</span>
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!emptyMessage && !widget && !embedWidget && (
                    <div ref={chartDataSection}>
                      <OverallSentimentChartData
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        data={data}
                        analyticsState={analyticsState}
                        chartRef={chartRef}
                        activeSections={activeSections}
                        disableSelectSection={disableSelectSection}
                        smallChartsKeys={smallChartsKeys}
                        rightPanelState={rightPanelState}
                        setRightPanelState={setRightPanelState}
                        changeOrderTableLabel={changeOrderTableLabel}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      {rightPanelState.open && (
        <OverallSentimentRightPanel
          contentState={rightPanelState}
          setRightPanelState={setRightPanelState}
          analyticsState={analyticsState}
        />
      )}
    </div>
  );
};

const ContributionsToIncludeSelect = (props) => {
  const { compileOverallSentimentData, setActiveSections, activeSections } = props;

  const setSection = (section) => {
    let newSections = [...activeSections];
    if (newSections.includes(section)) {
      if (newSections.length === 1) {
        createNotification('danger', `You must have at least 1 resource selected`);
      } else {
        let position = newSections.indexOf(section);
        newSections.splice(position, 1);
      }
    } else {
      newSections.push(section);
    }
    if (compileOverallSentimentData) {
      compileOverallSentimentData(newSections);
    }
    setActiveSections(newSections);
  };

  return (
    <ul className='d-flex m-0 p-0 flex-wrap'>
      {Object.keys(SentimentColours).map((item) => {
        let colour = SentimentColours[item];
        let section = uppercaseFirstLetter(item);
        return (
          <li
            className='legend-container-select-box flex-centered pointer'
            key={`item-legend-${item}`}
            style={{
              marginRight: '20px',
              marginBottom: '10px',
              fontSize: '0.925em',
            }}
            onClick={() => {
              setSection(section);
            }}
          >
            <span
              className={`legend-item ${activeSections.includes(section) ? 'legend-selected' : ''}`}
              style={{
                background: `rgba(${colour}, 0.2)`,
                border: `1px solid rgb(${colour})`,
                borderRadius: '3px',
                color: `rgb(${colour})`,
                display: 'inline-block',
                height: '20px',
                marginRight: '10px',
                width: '20px',
                minWidth: '20px',
              }}
            />
            <p className='p-0 m-0'>{section}</p>
          </li>
        );
      })}
    </ul>
  );
};

const transformContributionsToInclude = ({ newData, sections }) => {
  let labels = newData.labels;
  let indexes = [];
  labels.forEach((item, index) => {
    if (sections.includes(item)) {
      indexes.push(index);
    }
  });
  let colours = indexes.map((index) => newData.colours[index]);
  let value = indexes.map((index) => newData.value[index]);
  let newLabels = indexes.map((index) => newData.labels[index]);
  let finalValue = { ...newData, labels: newLabels, colours, value };
  return finalValue;
};

export { ContributionsToIncludeSelect, transformContributionsToInclude };
export default OverallSentiment;
