import React, { useContext } from 'react';
import Popup from '../../Common/PopUp';
import { store } from '../../Store';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import thousandCommas from '../helpers/thousandCommas';

const ContentPopup = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contactsOversubscribed } = state;

  const location = useLocation();
  const navigate = useNavigate();

  const closeMicroModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsOversubscribedModal',
      value: false,
    });
  };
  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p>
          You are currently over your contact limit. Please remove{' '}
          <strong>{thousandCommas(-contactsOversubscribed)}</strong> contacts to comply with your current subscription,
          or purchase more
        </p>

        <div className='my-4'>
          <button className='general-button delete-button px-4 mr-3 py-1' onClick={closeMicroModal}>
            Cancel
          </button>
          <button
            style={{
              backgroundColor: '#0094cc',
              fontWeight: 400,
              marginTop: '-4px',
              border: 'none',
            }}
            className='btn btn-general action-button px-4 py-2'
            onClick={() => {
              closeMicroModal();
              navigate(`/settings/billing?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`, {
                replace: true,
              });
            }}
          >
            Purchase more contacts
          </button>
        </div>
      </div>
    </div>
  );
};

const ContactsOversubscribedModal = (props) => {
  return (
    <Popup {...props} maxWidth={600}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default ContactsOversubscribedModal;
