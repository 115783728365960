const LegislationFilters = [
  {
    name: 'Sort',
    searchState: 'allResults',
    type: 'sort',
    pages: ['Legislation'],
    hidden: true,
    optionSelected: 'Title ascending',
    options: [
      {
        name: 'Title ascending',
        filter: [{ pages: ['Legislation'], property: { field: 'title', order: 'ASC' } }],
        selected: true,
      },
    ],
  },
  {
    name: 'Type',
    searchState: 'always',
    pages: ['Legislation'],
    filterName: 'contentType',
    options: [
      {
        name: 'Primary legislation',
        filterValue: 'Primary',
        hash: 'primary',
        optionSelected: 'All bills',
        suboptions: [
          {
            name: 'Government bills',
            filter: [
              {
                contentType: 'Primary',
                filter: { field: 'primaryTypeOfBill', value: 'Government Bill', operator: 'str_eq' },
              },
              {
                contentType: 'Secondary',
                filter: { field: 'primaryTypeOfBill', value: 'Secondary', operator: 'str_eq' },
              },
            ],
          },
          {
            name: 'All bills',
          },
        ],
      },
      {
        name: 'Secondary legislation',
        filterValue: 'Secondary',
        hash: 'secondary',
        filter: { field: 'contentType', value: 'Secondary', operator: 'str_eq' },
      },
    ],
  },
];

export default LegislationFilters;
