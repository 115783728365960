import { useEffect } from 'react';
//https://css-tricks.com/dangers-stopping-event-propagation/
//https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
//
const useOutsideHoverElement = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleHoverOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event.target);
      }
    }
    // Bind the event listener
    document.addEventListener('mouseover', handleHoverOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mouseover', handleHoverOutside);
    };
  }, [ref]);
};

export default useOutsideHoverElement;
