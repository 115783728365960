const EventsGrouped = (events) => {
  const categories = [];
  events.forEach((item) => {
    if (!categories.includes(item.eventCategoryName)) {
      categories.push(item.eventCategoryName);
    }
  });
  const Events = {};
  categories.forEach((item) => {
    Events[item] = [];
  });
  events.forEach((item) => {
    Events[item.eventCategoryName].push(item);
  });
  return Events;
};

export default EventsGrouped;
