const createEmailAlertName = (frequency) => {
  switch (frequency) {
    case 'Weekdaily':
      return 'Daily digest';
    case 'TwiceWeekdaily':
      return 'Twice daily digest';
    case 'Weekly':
      return 'Weekly digest';
    default:
      return frequency;
  }
};

export default createEmailAlertName;
