import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonLegislation = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div>
        <ul className='policy-list px-0 items-list'>
          {Array(9)
            .fill()
            .map((item, index) => (
              <SkeletonLegislationItem key={index} />
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

const SkeletonLegislationItem = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <li className={`my-2`}>
        <div className='row mx-0 p-3 align-items-center'>
          <div className='mr-3'>
            <Skeleton height={19} width={19} />
          </div>
          <div className='col-13 pr-lg-5 pr-2 pl-0'>
            <Skeleton height={19} width={`100%`} />
          </div>
          <div className='col-2'>
            <Skeleton height={13} width={`100%`} />
            <div className='mx-auto d-flex flex-column align-items-center'>
              <Skeleton height={13} width={`65px`} />
            </div>
          </div>
        </div>
      </li>
    </SkeletonTheme>
  );
};

export { SkeletonLegislationItem };
export default SkeletonLegislation;
