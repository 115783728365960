import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import changeSearchQuery from '../../../Sentiment/ChangeSearchQuery';
import { getUrlParamRaw } from '../../../utilities/getUrlParam';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const getOrCreateTooltip = (chart, tooltip) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgb(244, 239, 237)';
    tooltipEl.style.borderRadius = '5px';
    tooltipEl.style.color = 'rgb(0, 18, 43)';
    tooltipEl.style.border = '1px solid rgba(0, 18, 43, 0.3)';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 15px)';
    tooltipEl.style.transition = 'opacity .2s ease, transform .2s ease';
    tooltipEl.style.fontSize = '13px';
    tooltipEl.style.width = '250px';
    tooltipEl.classList.add('external-mentions-tooltip');
    tooltipEl.setAttribute('id', 'external-mentions-tooltip');

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  } else {
    if (tooltip.title.length === 0) {
      tooltipEl.style.padding = '0px';
      tooltipEl.style.width = '0px';
      tooltipEl.style.border = 'none';
      //tooltipEl.style.opacity = '0';
    } else {
      tooltipEl.style.width = '250px';
      tooltipEl.style.border = '1px solid rgba(0, 18, 43, 0.3)';
    }
  }
  return tooltipEl;
};

const sentimentExternalTooltip = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, tooltip);
  const titleLines = tooltip.title || [];

  /*Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }*/

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);
    const tableHead = document.createElement('thead');

    if (titleLines.length > 0) {
      titleLines.forEach((title) => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const th = document.createElement('th');
        th.style.borderWidth = 0;
        th.style.fontSize = '15px';
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
    }

    const tableBody = document.createElement('tbody');

    if (titleLines.length > 0) {
      bodyLines.forEach((body, i) => {
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;

        const td = document.createElement('td');
        td.style.borderWidth = 0;

        const text = document.createTextNode(body);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
      const sentimentLink = document.createElement('div');
      ReactDOM.render(<SentimentLink title={titleLines[0]} />, sentimentLink);
      tableBody.appendChild(sentimentLink);
    }

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    // Add new children
    if (titleLines.length > 0) {
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  }

  if (titleLines.length > 0) {
    // Display, position, and set styles for font
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    let topPosition = positionY + tooltip.caretY;
    let leftPosition = positionX + tooltip.caretX;
    let tooltipHeight = tooltipEl.getBoundingClientRect().height;

    if (topPosition + tooltipHeight > 400) {
      topPosition = positionY + tooltip.caretY - tooltipHeight;
    }
    if (topPosition <= 0) {
      topPosition = 0;
      leftPosition += chart?.config?.type === 'line' ? 20 : 120;
    }
    tooltipEl.style.left = leftPosition + 'px';
    tooltipEl.style.top = topPosition + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    tooltipEl.style.opacity = 1;

    tooltipEl.addEventListener('mouseout', () => {
      tooltipEl.style.opacity = 0;
    });

    tooltipEl.addEventListener('mouseover', () => {
      tooltipEl.style.opacity = 1;
    });
  }
};

const SentimentLink = (props) => {
  const searchQuery = changeSearchQuery();
  const { title } = props;
  //AE: Firefox adjustment just will work for the month view, if we enable a different interval needs to be changed.
  const startOfMonth = dayjs(title, 'MMM YYYY', 'en', true).startOf('month').format('YYYY-MM-DD');
  const endtOfMonth = dayjs(title, 'MMM YYYY', 'en', true).endOf('month').add(1, 'day').format('YYYY-MM-DD');
  const href = `/search-results?search=${encodeURIComponent(searchQuery)}#most-relevant~between-${startOfMonth}-and-${endtOfMonth}`;
  return (
    <a className='simple-link-button mt-2 d-inline-block' href={href}>
      See the political developments from {title} related to '{decodeURIComponent(getUrlParamRaw('search'))}'
    </a>
  );
};

export default sentimentExternalTooltip;
