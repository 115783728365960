import React from 'react';
import Popup from '../../Common/PopUp';

const ContentPopup = (props) => {
  const { deleteAFolder, closeMicroModal, currentFolder } = props;
  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p>Are you sure you want to delete this folder?</p>

        <div className='my-4'>
          <button
            className='general-button main-button px-4 py-2'
            onClick={async (e) => {
              let deleteAction = await deleteAFolder(e, currentFolder.id);
              if (deleteAction) {
                closeMicroModal();
              }
            }}
          >
            Delete folder
          </button>
        </div>
      </div>
    </div>
  );
};

const ConfirmDeleteFolder = (props) => {
  return (
    <Popup {...props}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default ConfirmDeleteFolder;
