import React from 'react';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import Popup from '../../Common/PopUp';
import { Portal } from 'react-portal';

const ContentPopup = (props) => {
  const { code, contactsPage } = props;
  const location = useLocation();
  const MogulTrial = code.includes('Mogul');

  return (
    <>
      <div className='section-title px-4 py-4'>
        <h3 className='export-message-title  mb-0'>
          <span>Export</span>
        </h3>
      </div>
      <div className='px-4 py-3'>
        {contactsPage ? (
          <>
            <p>Exporting contacts is not available during the trial period.</p>
            <p className='mb-0'>Please upgrade to a paid subscription to use this feature.</p>
          </>
        ) : (
          <>
            {MogulTrial && (
              <>
                <p>Exporting stakeholders is available to paying subscribers on the Mogul or Enterprise plans. </p>
                <p className='mb-0'>
                  To export your results, please upgrade to a paid Mogul or Enterprise subscription.
                </p>
              </>
            )}
            {!MogulTrial && (
              <p className='mb-4'>
                Exporting stakeholders is not available whilst you're on a free trial. Please upgrade your subscription
                or contact your account manager.
              </p>
            )}
          </>
        )}
      </div>
      {MogulTrial && (
        <Link
          to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`}
          className='py-3 d-block text-center border-top'
        >
          <button className='px-3 py-2 action-button general-button'>Compare plans</button>
        </Link>
      )}
    </>
  );
};

const ExportMessage = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentPopup {...props} />
      </Popup>
    </Portal>
  );
};

export default ExportMessage;
