import React, { useState } from 'react';
import Navigation from '../../Dashboard/Navigation';
import ConfirmDeleteFolder from './ConfirmDeleteFolder';
import { CreateFolderPopup } from './CreateFolderPopup';
import FolderItems from './FolderItems';
import SaveItemsSideNav from './SaveItemsSideNav';
import SkeletonSavedItems from './SkeletonSavedItems';
import SubNavSavedItems from './SubNavSavedItems';
const SavedItemsComponent = (props) => {
  const { isLoading } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const [editParameter, setEditParameter] = useState(null);

  return (
    <>
      <Navigation />
      <SubNavSavedItems
        {...props}
        setShowConfirmDeletePopup={setShowConfirmDeletePopup}
        setFolderToEdit={setFolderToEdit}
        setShowPopup={setShowPopup}
        setEditParameter={setEditParameter}
      />
      {isLoading && <SkeletonSavedItems />}
      {!isLoading && (
        <>
          <div className={`row mx-auto main-content-wrapper align-items-start`}>
            <div className='side-left-element d-none d-lg-block'>
              <SaveItemsSideNav {...props} setShowPopup={setShowPopup} />
            </div>
            <div className='main-content pr-xl-5 px-md-4 px-3'>
              <FolderItems {...props} />
            </div>
          </div>

          {showPopup && (
            <CreateFolderPopup
              {...props}
              identifier={'create-folder'}
              setShowPopup={setShowPopup}
              folderToEdit={folderToEdit}
              setFolderToEdit={setFolderToEdit}
              setEditParameter={setEditParameter}
              additionalClass={'saved-items-popup'}
              editParameter={editParameter}
            />
          )}

          {showConfirmDeletePopup && (
            <ConfirmDeleteFolder
              {...props}
              identifier={'confirm-delete-folder'}
              setShowPopup={setShowConfirmDeletePopup}
              additionalClass={'saved-items-popup'}
            />
          )}
        </>
      )}
    </>
  );
};

export default SavedItemsComponent;
