import { useContext, useState } from 'react';
import ModalComponent from '../../../../../Common/Modal';
import ChampionStatus, { useChangeChampionStatus } from '../../../tableComponents/ChampionStatus';
import { store } from '../../../../../Store';
import createNotification from '../../../../../Settings/Utilities/CreateNotification';
import thousandCommas from '../../../../helpers/thousandCommas';

const ChampionStatusModal = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedCrmContacts, contentResults, activeResults } = state;

  const { isOpen, setIsOpen, totalHits = 0 } = props;
  const { changeChampionStatus } = useChangeChampionStatus();
  const [championStatus, setChampionStatus] = useState(null);
  const [selectingOption, setSelectingOption] = useState(false);

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const [isLoading, setIsLoading] = useState(false);

  const contactsCount =
    selectedAllVisible.length > 0 ? selectedAllVisible.length : selectedIds.length > 0 ? selectedIds.length : totalHits;

  const handleAddChampionStatus = async () => {
    try {
      const contactIds = selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds;
      setIsLoading(true);
      let result = await changeChampionStatus({
        ids: selectedAll ? null : contactIds,
        value: championStatus,
        selectedAll,
      });
      if (result) {
        createNotification('success', `Champion status changed`);

        let newCrmContacts;
        if (selectedAll) {
          newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
            return {
              ...stakeholder,
              championStatus: championStatus,
            };
          });
        } else {
          newCrmContacts = contentResults[activeResults].CrmContacts?.hits.map((stakeholder) => {
            if (contactIds.includes(stakeholder.id)) {
              return {
                ...stakeholder,
                championStatus: championStatus,
              };
            } else {
              return stakeholder;
            }
          });
        }
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              CrmContacts: {
                ...contentResults[activeResults].CrmContacts,
                hits: newCrmContacts,
              },
            },
          },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedCrmContacts',
          value: {
            selectedAll: false,
            selectedIds: [],
            selectedAllVisible: [],
          },
        });
        setIsOpen(false);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <ModalComponent
      maxWidth={600}
      isOpen={isOpen}
      setIsOpen={(value) => {
        setChampionStatus(null);
        setIsOpen(value);
      }}
    >
      <div>
        <h3 className='py-4 topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 pl-3 pr-5'>
          Choose a champion status to assign to{' '}
          {contactsCount === 1
            ? `this contact`
            : `these ${!!contactsCount ? thousandCommas(contactsCount) : 0} contacts`}
        </h3>
        <div className='py-4 px-lg-5 px-3'>
          <ChampionStatus
            contact={{ championStatus: championStatus }}
            updateFunction={({ value }) => {
              setSelectingOption(value);
              setChampionStatus(value === 'No status' ? null : value);
              setTimeout(() => {
                setSelectingOption(false);
              }, 100);
            }}
            notShowSelected={true}
            conditionsToClose={[selectingOption]}
          />
        </div>
        <p className='remove-contacts-helper-text px-lg-5 px-3 mt-2'>
          <span className='remove-contacts-helper-text-icon mr-2' />
          These changes impact all members of your team
        </p>

        <div className='d-flex justify-content-end px-4 px-lg-5 confirm-results-section bg-white pt-4 rounded-bottom'>
          <button
            onClick={() => setIsOpen(false)}
            className='danger-button-outlined px-3 py-2 mr-3'
            disabled={isLoading}
          >
            Cancel
          </button>
          <button onClick={handleAddChampionStatus} className='danger-button px-3 py-2' disabled={isLoading}>
            {isLoading ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  margin: '0 41.2px',
                  color: '#fcfcfc',
                  marginTop: '2px',
                  width: '33.7px',
                }}
              ></i>
            ) : (
              `Update champion status`
            )}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ChampionStatusModal;
