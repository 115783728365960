const LobbyingMaterialFilters = [
  {
    name: 'Type',
    searchState: 'always',
    filterName: 'contentType',
    pages: ['LobbyingMaterial', 'Campaigns'],
    updateFilter: true,
    options: [
      {
        name: 'Briefing material',
        filterValue: 'BriefingMaterial',
      },
      {
        name: 'Policy asks',
        filterValue: 'PolicyAsk',
      },
      {
        name: 'Research',
        filterValue: 'Research',
      },
      {
        name: 'News release',
        filterValue: 'NewsRelease',
      },
    ],
  },
];

export default LobbyingMaterialFilters;
