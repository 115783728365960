import { useContext, useState } from 'react';
import CreateEmailAlert from '../Topics/CreateEmailAlert';
import ResumeTopicEmailAlerts from '../Topics/ResumeTopicEmailAlerts';
import { store } from '../../Store';
import { EmailAlertBadge } from '../Topics/TopicsLists';

const ClientEmailAlertComponent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, emailAlerts, clients } = state;
  const { allowImmediateAlerts, allowWeekdailyAlerts, allowWeeklyAlerts } = activeSubscriptions;
  const allowAlerts = allowImmediateAlerts && allowWeekdailyAlerts && allowWeeklyAlerts;

  const { topic, teamId, openPopupState, topics } = props;
  const { setIsOpenPopup } = openPopupState ?? {};
  //AE: We need to use the email alerts property because myAlertSettings inside each topic doesn't include digestCombiningAllTopics
  const alertSettings = emailAlerts.filter(
    (item) => (item.keywordListIds.includes(topic?.id) || item.digestCombiningAllTopics) && item?.teamId === teamId
  );

  const [openCreateEmailAlert, setOpenCreateEmailAlert] = useState(false);
  const [openTopicEmailAlerts, setOpenTopicEmailAlerts] = useState(false);

  return (
    <>
      <EmailAlertBadge
        emailAlerts={alertSettings}
        allowAlerts={allowAlerts}
        clickFunction={() => {
          if (allowAlerts) {
            setIsOpenPopup(true);
            if (alertSettings.length === 0) {
              setOpenCreateEmailAlert(true);
            } else {
              setOpenTopicEmailAlerts(true);
            }
          }
        }}
      />
      {openCreateEmailAlert && (
        <CreateEmailAlert
          setIsOpenEditEmailAlert={(value) => {
            setOpenCreateEmailAlert(value);
            setIsOpenPopup(value);
          }}
          emailAlertId={''}
          teamId={teamId}
          topicData={{
            topicId: topic?.id,
            name: topic?.name,
          }}
          customKeywordsLists={topics}
          teamName={clients?.find((item) => item?.teamId === teamId)?.teamName}
        />
      )}
      {openTopicEmailAlerts && (
        <ResumeTopicEmailAlerts
          setOpenTopicEmailAlerts={(value) => {
            setOpenTopicEmailAlerts(value);
            setIsOpenPopup(value);
          }}
          currentTopicAlerts={alertSettings}
        />
      )}
    </>
  );
};

export default ClientEmailAlertComponent;
