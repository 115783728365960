import createNotification from '../../Settings/Utilities/CreateNotification';

const copyLinkFunction = ({ e, url, closeFunction }) => {
  e.preventDefault();
  e.stopPropagation();
  let tempInput = document.createElement('input');
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
  tempInput.value = url;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
  if (closeFunction) {
    closeFunction();
  }
  createNotification('success', `Link copied`);
};

export default copyLinkFunction;
