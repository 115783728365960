const dayjs = require('dayjs');

const DateReplacements = (date) => {
  const today = dayjs().startOf('day');
  const dateReference = dayjs(date).startOf('day');
  const diff = dateReference.diff(today, 'day');
  let replacement;
  if (diff === 0) {
    replacement = 'Today';
  } else if (diff === 1) {
    replacement = 'Tomorrow';
  } else if (diff === -1) {
    replacement = 'Yesterday';
  } else {
    replacement = null;
  }
  return replacement;
};

export default DateReplacements;
