import React, { useState, useRef, useEffect, useMemo } from 'react';
import useHiddenItemsFunctions from '../../../Dashboard/hooks/useHiddenItemsFunctions';
import useOutsideElement from '../../../Dashboard/utilities/useOutsideElement';
import Axios from 'axios';

const SelectClientsItemsAdmin = (props) => {
  const { userItem, disabled, newAdminMember, newMember, localClientsState } = props;
  const { localClients, setLocalClients } = localClientsState ?? {};
  const [itemsList, setItemsList] = useState([]);
  const [changingState, setChangingState] = useState(null);
  const { getHiddenClientList } = useHiddenItemsFunctions();

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    const asyncFn = async (source) => {
      try {
        if (newMember || newAdminMember) {
          setItemsList(localClients);
        } else {
          let items = await getHiddenClientList({ userId: userItem.id, adminPage: true, source });
          if (items?.length) {
            setItemsList(items);
          }
        }
      } catch (error) {}
    };
    asyncFn(source);
    return () => {
      source.cancel('user information cancelled by the user');
    };
  }, []);

  useEffect(() => {
    if (userItem?.newRole === 'Admin') {
      let newitemsList = itemsList.map((item) => {
        if (item.myState === 'Disabled') {
          return { ...item, myState: 'Enabled' };
        } else {
          return item;
        }
      });
      setItemsList(newitemsList);
    }
  }, [userItem?.newRole]);

  const checkedItem = (item) => {
    return item?.myState === 'Enabled' || item?.myState === 'Hidden';
  };

  const disabledConditional = changingState || disabled;

  const allItemsChecked = useMemo(
    () => itemsList?.every((item) => item?.myState === 'Enabled' || item?.myState === 'Hidden'),
    [itemsList, userItem?.myState]
  );
  const getSelectTitle = () => {
    if (allItemsChecked) return 'All clients';
    return `${itemsList.filter(checkedItem).length} out of ${
      itemsList.length
    } client${itemsList.length > 1 ? 's' : ''}`;
  };

  const hiddenItemsElement = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const hideDropdown = () => {
    setShowDropdown(false);
  };
  useOutsideElement(hiddenItemsElement, hideDropdown);

  return (
    <div
      className='position-relative role-select-container'
      style={{
        maxWidth: newMember || newAdminMember ? 'unset' : '250px',
        backgroundColor: disabled ? 'rgba(196, 196, 196, 0.2)' : undefined,
      }}
      ref={hiddenItemsElement}
    >
      <p
        className={`mb-0 hour-select px-3  ${
          showDropdown ? 'hour-select-opendropdown' : ''
        } ${disabled ? 'disabled-role-select' : ''} ${changingState ? 'disabled-hour-select' : ''}`}
        onClick={() => {
          if (!disabledConditional) {
            setShowDropdown(!showDropdown);
          }
        }}
      >
        <span className={disabled ? 'px-3' : undefined}>{getSelectTitle()}</span>
      </p>

      {showDropdown && (
        <>
          <div className='frequency-dropdown text-left team-role-dropdown scroll-container'>
            {itemsList.map((item) => (
              <ItemCheckboxAdmin
                item={item}
                key={`item-checkbox-${item.teamId}`}
                changingState={changingState}
                allItemsChecked={allItemsChecked}
                userItem={userItem}
                checkedItem={checkedItem}
                itemsList={itemsList}
                setChangingState={setChangingState}
                setItemsList={setItemsList}
                newMember={newMember}
                newAdminMember={newAdminMember}
                setLocalClients={setLocalClients}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const ItemCheckboxAdmin = ({
  item,
  changingState,
  userItem,
  checkedItem,
  itemsList,
  setChangingState,
  setItemsList,
  newMember,
  newAdminMember,
  setLocalClients,
}) => {
  const { updateHiddenClientState } = useHiddenItemsFunctions();

  const switchVisability = async ({ item, returnRes, stateArg, showSuccsessMessage }) => {
    let updated;
    if (!changingState) {
      if (!returnRes && !newMember && !newAdminMember) setChangingState(item.teamId);
      let newState = () => {
        if (stateArg) {
          return stateArg;
        } else {
          if (checkedItem(item)) {
            if (userItem?.teamRole === 'Admin' || userItem?.teamRole === 'Owner' || newAdminMember) {
              if (item?.myState === 'Enabled') {
                return 'Hidden';
              } else {
                return 'Enabled';
              }
            } else {
              if (item?.myState === 'Enabled') {
                return 'Hidden';
              } else {
                return 'Disabled';
              }
            }
          } else {
            return 'Enabled';
          }
        }
      };

      if (!newMember && !newAdminMember) {
        updated = await updateHiddenClientState({
          state: newState(),
          userId: parseInt(userItem.id),
          teamId: item.teamId,
          showSuccsessMessage,
          adminPage: true,
        });
      }

      if (updated || newMember || newAdminMember) {
        if (returnRes) {
          return updated;
        } else {
          let updatedItemsList = itemsList.map((listItem) => {
            if (listItem.teamId === item.teamId) {
              return { ...listItem, myState: newState() };
            }
            return listItem;
          });
          setItemsList(updatedItemsList);
          if (setLocalClients) {
            setLocalClients(updatedItemsList);
          }
        }
      }
      if (!returnRes) setChangingState(null);
    }
  };

  return (
    <label htmlFor={`hideTopic-${item?.teamId ?? 'all'}-id`} className='mb-0 w-100 font-weight-normal'>
      <div className={`mb-0 frequency-item px-3 py-2 position-relative`}>
        {changingState === item?.teamId ? (
          <i
            className='fas fa-spinner fa-spin'
            style={{
              position: 'absolute',
              color: '#212529',
              left: '9px',
              top: '12px',
            }}
          ></i>
        ) : (
          <div
            className={`stakeholder-checkbox-input topics-hide-checkbox-dropdown`}
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: item?.myState === 'Hidden' ? '#D1D4D7' : '',
            }}
          >
            <input
              type={'checkbox'}
              onChange={(e) => {
                switchVisability({
                  item,
                  showSuccsessMessage: true,
                });
              }}
              name={`hideTopic-${item?.teamId ?? 'all'}`}
              id={`hideTopic-${item?.teamId ?? 'all'}-id`}
              checked={checkedItem(item)}
            />
            <label htmlFor={`hideTopic-${item?.teamId ?? 'all'}-id`} />
          </div>
        )}
        {item?.teamName}
      </div>
    </label>
  );
};

export default SelectClientsItemsAdmin;
