import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import useGetSectionFilters from '../../../Filters/useGetSectionFilters';
import useIsMobile from '../../../utilities/useIsMobile';
import { store } from '../../../../Store';
import { useContext } from 'react';

function SubnavSearchBar() {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [searchValue, setSearchValue] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { hash } = sectionFilters;

  const inputSearch = useRef(null);
  const isSmallMobile = useIsMobile(480);

  useEffect(() => {
    const searchQuery = query.get('search') ? decodeURIComponent(query.get('search')) : '';
    setSearchValue(searchQuery);
  }, [location.search]);

  const setStringValue = ({ value, type }) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: `search${type}String`,
      value: value,
    });
  };

  const searchResults = (value) => {
    if (location.pathname.includes('contacts')) {
      setStringValue({ value, type: 'Contacts' });
    } else if (location.pathname.includes('emails')) {
      setStringValue({ value, type: 'Emails' });
    }
    if (value.trim() === '') {
      query.delete('search');
    } else {
      query.set('search', encodeURIComponent(value));
    }
    const pathname = location.pathname.includes('contacts/update') ? '/influence/contacts' : location.pathname;
    let urlToHistory = `${pathname}?${query.toString()}${
      hash !== '' && location.pathname.includes('/contacts') ? `#${hash}` : ''
    }`;
    navigate(urlToHistory, { replace: true });
  };
  return (
    <div className='sub-nav-searchbar influence-search-bar'>
      <div className='position-relative'>
        <div
          className={
            'simple-searchbar flex-centered simple-searchbar-open simple-searchbar-open-influence simple-searchbar-influence'
          }
        >
          <span className={`icon-search title-h5-m`} style={{ margin: '2px 11px 0 -1px', color: '#9c9c9c' }} />
          <div className='flex-grow-1'>
            <input
              className={`search-main-input w-100`}
              data-hj-allow
              type='text'
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              style={{ height: '28px' }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  let value = e.target.value;
                  inputSearch.current.blur();
                  searchResults(value);
                } else if (e.keyCode === 9) {
                  e.preventDefault();
                }
              }}
              ref={inputSearch}
              placeholder={
                isSmallMobile
                  ? 'Search'
                  : location.pathname.includes('/contacts')
                    ? 'Search by name, role, description or tag'
                    : location.pathname.includes('/emails')
                      ? 'Search emails'
                      : 'Search my lobbying material'
              }
            />
          </div>
          <div
            className='close-icon-container'
            style={{
              visibility: searchValue !== '' || query.has('search') ? 'visible' : 'hidden',
              display: searchValue !== '' || query.has('search') ? undefined : 'none',
            }}
          >
            <button
              className='general-button close-icon'
              data-tooltip-content='Clear search'
              data-tooltip-id='general-tooltip'
              data-tooltip-place='right'
              onClick={(e) => {
                e.stopPropagation();
                if (location.pathname.includes('contacts')) {
                  setStringValue({ value: '', type: 'Contacts' });
                } else if (location.pathname.includes('emails')) {
                  setStringValue({ value: '', type: 'Emails' });
                }
                query.delete('search');
                let urlToHistory = `${location.pathname}?${query.toString()}${
                  hash !== '' && location.pathname.includes('/contacts') ? `#${hash}` : ''
                }`;
                navigate(urlToHistory, { replace: true });
                setSearchValue('');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubnavSearchBar;
