import { useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';
import normalize from '../utilities/normalizeString';
import useInfluenceSaveFunctions from './InfluenceSaveFunction';
import { useStepsStructure } from './StepsStructure';

const useSectionFunctions = (object) => {
  const { StepsStructure } = useStepsStructure();
  const { InfluenceSaveFunctions } = useInfluenceSaveFunctions();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const editPage = location.pathname.includes('/edit');

  const { InfluenceState, setInfluenceState, setDisabledNavigation, sectionName, itemInformation } = object;

  const sectionPosition = (sectionName) => {
    const sectionPosition = InfluenceState.findIndex((item) => item.name === sectionName);
    return sectionPosition;
  };

  const updateProcessSection = (props) => {
    const { sectionName, property, newValue } = props;
    const newInfluenceState = InfluenceState.slice();
    const position = sectionPosition(sectionName);
    newInfluenceState[position][property] = newValue;
    setInfluenceState(newInfluenceState);
  };

  const modifyFields = (props) => {
    const { fields, fieldName, value } = props;
    const newFields = fields.slice();
    const position = newFields.findIndex(
      (item) => item.name === fieldName && (item.type === params.page || !item.type)
    );
    newFields[position].value = value;
    return newFields;
  };

  const modifyField = (field) => {
    return (value) => {
      const position = sectionPosition(sectionName);
      const fields = InfluenceState[position].fields;
      const fieldName = field;
      const newFields = modifyFields({ fields, fieldName, value });
      const property = 'fields';
      const newValue = newFields;
      updateProcessSection({ sectionName, property, newValue });
    };
  };

  const createFields = () => {
    const position = sectionPosition(sectionName);
    const fields = InfluenceState[position]?.fields?.filter((item) => item.type === params.page || !item.type);
    const items = [];
    fields?.forEach((item) => {
      const { name } = item;
      items.push({
        ...item,
        setValue: modifyField(name),
      });
    });
    return items;
  };

  const saveSection = async (publish) => {
    const validateFields = () => {
      const position = sectionPosition(sectionName);
      const fields = InfluenceState[position].fields?.filter((item) => params.page === item.type || !item.type);
      let validate = true;
      fields?.forEach((item) => {
        if (item.required && item.value === '') {
          validate = false;
        }
      });
      return validate;
    };
    if (validateFields()) {
      setDisabledNavigation(true);

      let results = await InfluenceSaveFunctions(object);
      if (results) {
        if (publish) {
          setInfluenceState([...StepsStructure]);
          if (results?.visibility === 'Anyone' && itemInformation?.publishedAt === null) {
            return navigate(`/share/${params.page}/${results?.id}`);
          } else {
            return navigate(`/${params.page}/${results?.id}/${normalize(results?.title)}`);
          }
        }

        const baseStep = params?.step?.split('?')?.[0];
        let newStep = parseInt(baseStep) + 1;
        let step = InfluenceState[baseStep - 1]?.name === 'Associated content' ? 'preview' : newStep;
        let url = editPage ? `edit/${params.id}/${step}` : `create/${step}`;
        const searchParams = new URLSearchParams(window.location.search);
        navigate({ pathname: `/influence/${params.page}/${url}`, search: searchParams.toString() });
        //mark as complete
        const property = 'complete';
        const newValue = true;
        updateProcessSection({ sectionName, property, newValue });
      }
      setDisabledNavigation(false);
    }
  };

  const saveRelatedContent = async (selectedResourcesState) => {
    let notPreview = true;
    let newObject = {
      ...object,
      InfluenceState: selectedResourcesState,
    };
    let results = await InfluenceSaveFunctions(newObject, notPreview);
    return results;
  };

  return {
    fields: createFields(),
    saveSection,
    saveRelatedContent,
  };
};

export default useSectionFunctions;
