import React, { useRef, useState } from 'react';
import CalendarPicker, { GoToTodayButton } from './CalendarPicker';
import DateReplacements from './DatesReplacements.js';
import { Link, useParams } from 'react-router-dom-v5-compat';
import useIsMobile from '../utilities/useIsMobile';
import useOutsideElement from '../utilities/useOutsideElement';
const dayjs = require('dayjs');

const TitleDateNav = () => {
  const params = useParams();
  const { startdate, title } = params;

  const defineDate = () => {
    if (startdate) {
      return startdate;
    } else if (title) {
      return title;
    } else {
      return dayjs().format('YYYY-MM-DD');
    }
  };

  const date = defineDate();

  const DateToShow = () => {
    let dateReplacement = DateReplacements(date);
    if (dateReplacement === null) {
      return `${dayjs(date).format('dddd D MMMM')} ${dayjs(date).get('year') !== dayjs().get('year') ? dayjs(date).get('year') : ''}`;
    } else {
      return dateReplacement;
    }
  };

  return (
    <div className='calendar-current-date mb-0 mr-lg-3 d-flex flex-row'>
      <div className='mr-lg-2 calendar-picker-controls-list'>
        <Link
          to={`/calendar/${dayjs(date).subtract(1, 'day').format('YYYY-MM-DD')}`}
          data-tooltip-content={'Previous day'}
          data-tooltip-id={'general-tooltip'}
          data-tooltip-class-name={'current-date-tooltip header-tooltip'}
        >
          Prev
        </Link>
        <Link
          className='calendar-current-date-next'
          to={`/calendar/${dayjs(date).add(1, 'day').format('YYYY-MM-DD')}`}
          data-tooltip-content={'Next day'}
          data-tooltip-id={'general-tooltip'}
          data-tooltip-class-name={'current-date-tooltip header-tooltip'}
        >
          Next
        </Link>
      </div>
      <p className='px-1 px-lg-2 text-lg-right mb-0 nowrap-item'>{DateToShow()}</p>
    </div>
  );
};

const SelectDateComponent = () => {
  const [showPicker, setShowPicker] = useState(false);
  const selectDateButton = useRef(null);
  const params = useParams();
  const { startdate } = params;
  const [currentButtonToday, setCurrentButtonToday] = useState(true);
  const [month, setMonth] = useState(startdate === undefined ? new Date() : dayjs(startdate).toDate());

  const titleDimentions = () => {
    const { top, left, width, height } =
      selectDateButton.current !== null ? selectDateButton.current.getBoundingClientRect() : {};
    const elementWidth = 260;
    return {
      position: 'fixed',
      left: `${left + width - elementWidth}px`,
      top: `${top + height + 5}px`,
    };
  };

  const hideBox = () => {
    setShowPicker(false);
  };

  const calendarContainer = useRef();
  useOutsideElement(calendarContainer, hideBox);

  return (
    <div className='position-relative calendar-select-container ml-3 ml-lg-0' ref={calendarContainer}>
      <button
        className={`sort-button calendar-select-date ${showPicker ? 'active' : ''}`}
        ref={selectDateButton}
        onClick={() => {
          setShowPicker(!showPicker);
        }}
      >
        <span className='icon-calendar'></span>
        Select date
      </button>
      {showPicker && (
        <div
          className='tweets-calendar calendar-picker reference-calendar-picker mobile-calendar-picker'
          style={titleDimentions()}
        >
          <div className='px-4 py-3 section-title'>
            <GoToTodayButton
              currentButtonToday={currentButtonToday}
              setCurrentButtonToday={setCurrentButtonToday}
              setMonth={setMonth}
            />
          </div>
          <CalendarPicker
            selectDateButton={selectDateButton}
            setShowPicker={setShowPicker}
            month={month}
            setMonth={setMonth}
            currentButtonToday={currentButtonToday}
            setCurrentButtonToday={setCurrentButtonToday}
          />
        </div>
      )}
    </div>
  );
};

const CalendarListControls = () => {
  const isMobile = useIsMobile();
  return (
    <div className='flex-centered'>
      <TitleDateNav />
      {isMobile && <SelectDateComponent />}
    </div>
  );
};

export default CalendarListControls;
