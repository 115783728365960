import DownloadChartModal from './DownloadChartModal/DownloadChartModal';
import { useEffect, useState, useContext } from 'react';
import { store } from '../../../Store';
import useUpdateTitleAnalytics from '../useUpdateTitleAnalytics';
import { useLocation } from 'react-router-dom-v5-compat';
import { useAuth0 } from '@auth0/auth0-react';
import { useAllowRightPanel } from './MentionsByTopic/ChartDataTable';
import getUrlParam from '../../utilities/getUrlParam';
import { EmbedAnalyticsPopup } from '../../../EmbedAnalytics/EmbedAnalyticsButton';

const GraphActions = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { chartState, changeOfGraphFilters } = state;

  const { isLoading, chartRef, chartSection, data, emptyMessage } = chartState ?? {};
  const { isAuthenticated } = useAuth0();

  const [isShowDownloadModal, setIsShowDownloadModal] = useState(false);
  const [popupForWidget, setPopupForWidget] = useState(false);
  const { sectionTitle } = useUpdateTitleAnalytics();
  const [animated, setAnimated] = useState(false);
  const location = useLocation();

  const interestByRegion = location.pathname.includes('/interest-by-region');
  const topStakeholders = location.pathname.includes('/top-mps-and-peers');
  const showDownloadModal = (widget) => {
    const createRefCondition = () => {
      if (interestByRegion) {
        return !!chartRef;
      } else if (topStakeholders) {
        return true;
      } else {
        return chartRef?.current;
      }
    };
    const refCondition = createRefCondition();
    if (!isLoading && refCondition) {
      setPopupForWidget(widget);
      setIsShowDownloadModal(true);
    }
  };

  useEffect(() => {
    if (changeOfGraphFilters) {
      setTimeout(() => {
        if (!emptyMessage) {
          setAnimated(true);
        }
        setTimeout(() => {
          setAnimated(false);
          dispatch({ type: 'MODIFY_SECTION', parameter: 'changeOfGraphFilters', value: false });
        }, 1000);
      }, 500);
    }
  }, [changeOfGraphFilters]);

  return (
    <>
      {isAuthenticated ? (
        <div
          className={`chart-action-buttons flex-centered graph-actions-buttons ${emptyMessage ? 'graph-actions-buttons-empty-message' : ''}`}
        >
          <ShowEmbedButton showDownloadModal={showDownloadModal} animated={animated} />
          <button
            className={`general-button print-chart-button border text-center ${animated ? 'animated' : ''}`}
            onClick={() => {
              showDownloadModal();
            }}
          >
            <span className='icon-download paragraph-p2' />{' '}
            <span className='ml-2 d-none d-lg-inline-block button-text'>Download chart</span>
          </button>
        </div>
      ) : (
        <div
          className='d-flex px-4 py-3 pointer action-state-hover'
          onClick={() => {
            showDownloadModal();
          }}
        >
          <div className='pt-1 analytics-share-icon-container'>
            <span className='icon-arrow-download title-h5-m' />
          </div>
          <div className='pl-3'>
            <p className='font-weight-bold mb-1'>Download chart</p>
            <p className='main-light-text mb-0'>Download this specific chart and share it with anyone</p>
          </div>
        </div>
      )}

      {isShowDownloadModal && (
        <DownloadChartModal
          setShowPopup={setIsShowDownloadModal}
          chartSection={chartSection}
          setChartOnDownload={() => {}}
          type={sectionTitle()}
          data={data}
          chartRef={chartRef}
          popupForWidget={popupForWidget}
        />
      )}
    </>
  );
};

const ShowEmbedButton = (props) => {
  const { hideText, animated } = props;
  const { isAuthenticated } = useAuth0();
  const topicParameter = getUrlParam('topic-id');
  const [showPopup, setShowPopup] = useState(false);
  const { sectionTitle } = useUpdateTitleAnalytics();
  const { showPanelWithTitle } = useAllowRightPanel();

  return (
    <>
      <span
        className={`general-button border text-center mb-0 pointer ${hideText ? 'button-hide-text' : ''} ${animated ? 'animated' : ''}`}
        onClick={() => {
          if (isAuthenticated) {
            if (!!topicParameter) {
              setShowPopup(true);
            }
          } else {
            showPanelWithTitle('Embed a real-time chart on any site or blog');
          }
        }}
        data-tooltip-content={!topicParameter ? 'Apply a topic to embed this chart' : ''}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-place={'bottom'}
      >
        <span className='icon-code-thin paragraph-p5' />{' '}
        <span className={`ml-2 d-none d-lg-inline-block button-text`}>
          Embed live chart
          {(!isAuthenticated || !topicParameter) && <span className='icon-padlock ml-2 mr-1 paragraph-p3' />}
        </span>
      </span>

      {showPopup && (
        <EmbedAnalyticsPopup
          setShowPopup={setShowPopup}
          additionalClass='website-feeds-popup-container'
          maxWidth={1200}
          identifier={'analytics-feeds-popup'}
          awaitOpenAnimation={false}
          chartTypes={[sectionTitle()]}
        />
      )}
    </>
  );
};

export default GraphActions;
