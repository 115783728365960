import { useState } from 'react';

const useNewUserFields = () => {
  const [formFields, setFormFields] = useState([
    {
      property: 'fullName',
      name: 'Full name',
      value: '',
      required: true,
      type: 'text',
      validationRule: (val) => val.trim().split(' ').length > 1,
      validationMessage: 'Please enter your full name',
    },
    {
      property: 'password',
      name: 'Choose a password',
      value: '',
      type: 'password',
      required: true,
      validationRule: (val) => val.match(/[a-z]/g) && val.match(/[A-Z]/g) && val.match(/[0-9]/g) && val.length >= 8,
      validationMessage:
        'Your password should contain:<ul class="pl-4"><li> 8 characters</li> <li>Lower case letters (a-z)</li> <li>Upper case letters (A-Z)</li><li>Numbers (0-9)</li>',
    },
    {
      property: 'termsAndConditions',
      name: 'Terms and conditions',
      value: false,
      type: 'checkbox',
      validationRule: (val) => val === true,
      validationMessage: 'You must agree to the Terms and Conditions',
    },
  ]);

  const modifyValue = (property, value) => {
    let updatedFormFileds = formFields.slice();
    let propertyIndex = updatedFormFileds.findIndex((item) => item.property === property);
    let newPropertyObject = {
      ...updatedFormFileds[propertyIndex],
      errorMessage: null,
      value: value,
    };
    updatedFormFileds[propertyIndex] = newPropertyObject;
    setFormFields(updatedFormFileds);
  };

  return { formFields, setFormFields, modifyValue };
};

export default useNewUserFields;
