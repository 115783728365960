import { TwitterShareButton } from 'react-share';
import Popup from '../../Common/PopUp';
import copyLinkFunction from '../utilities/copyLinkFunction';

const SentimentSharePopup = (props) => {
  const { url, title } = props;
  return (
    <Popup {...props}>
      <SentimentSharePopupContent url={url} title={title} />
    </Popup>
  );
};

const SentimentSharePopupContent = (props) => {
  const { url, closeMicroModal, title } = props;

  return (
    <div className='p-3 px-lg-5 py-5'>
      <h3 className='main-title'>Share</h3>
      <div className='d-flex justify-content-center px-4 pb-4 pt-2'>
        <div className='row stakeholder-share-buttons'>
          <button
            className='general-button position-relative p-3 copy-link-button'
            onClick={(e) => {
              copyLinkFunction({ e, url, closeFunction: closeMicroModal });
            }}
          >
            <span className='icon-share-image icon-share-image-copy'></span>
            <span>Copy link</span>
          </button>
          <TwitterShareButton
            url={url}
            title={title}
            onClick={() => {
              closeMicroModal();
            }}
          >
            <span className='icon-share-image icon-share-image-twitter'></span>
            <span>Twitter</span>
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};

export { copyLinkFunction };
export default SentimentSharePopup;
