const ChangeSearchQuery = (query) => {
  let trimmedQuery = '';
  if (!query.includes('"')) {
    trimmedQuery = query.trim();
  } else {
    trimmedQuery = query;
  }
  let queryToReturn = '';
  if (trimmedQuery.indexOf(' ') >= 0 && !trimmedQuery.includes('"')) {
    queryToReturn = `"${trimmedQuery}"`;
  } else {
    queryToReturn = trimmedQuery;
  }
  return queryToReturn;
};

export default ChangeSearchQuery;
