//AE: This component was necessary because on the MentionsChart.js file we create the tick boxes plugin that is not necessary here and to avoid too many conditionals and having more control over the graph on the dashboard.
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { formatGeneralDate } from '../../../helpers/formatGeneralDate';
import pattern from 'patternomaly';
import thousandCommas from '../../../helpers/thousandCommas';
import dayjs from 'dayjs';
import { store } from '../../../../Store';
import { useContext } from 'react';
import { EmptyStateRateLimit } from '../../AnalyticsDashboardContainer';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Filler, Legend);

ChartJS.defaults.font.family = 'Lato';

const DashboardMentionsChart = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsRateLimitReached } = state;

  const {
    data,
    stakeholdersContributions,
    currentView,
    maxTicksLimit = 5,
    heightGraph = 280,
    typeOfChart = 'Bar',
    reportWidget,
    displayGrid = true,
    totalHeight,
  } = props;
  const labels = data?.labels ?? [];
  const emptyMessage = labels.length === 0;

  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: !reportWidget,
        backgroundColor: 'rgb(244, 239, 237)',
        borderColor: 'rgba(0, 18, 43, 0.3)',
        borderWidth: 1,
        titleColor: 'rgb(0, 18, 43)',
        bodyColor: 'rgb(0, 18, 43)',
        padding: 12,
        titleFont: { size: 14 },
        displayColors: false,
        callbacks: {
          title: function (context) {
            let label = context?.[0]?.label || '';
            return formatGeneralDate(label, false, currentView === 'Month');
          },
          label: function (context) {
            return `${context.dataset.label} : ${thousandCommas(context.formattedValue)}`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit,
          callback: function (value) {
            let labelOfTick = this.getLabelForValue(value);
            return formatGeneralDate(labelOfTick, false, currentView === 'Month');
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          precision: 0,
        },
        grid: {
          display: displayGrid,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
  };
  if (reportWidget) {
    options = {
      ...options,
      animation: false,
    };
  }

  const isDiagonalBackground = (label) => {
    return new Date(label).getTime() >= new Date(dayjs().startOf(currentView.toLowerCase())).getTime();
  };

  const color = stakeholdersContributions ? '0, 28, 53' : '0, 148, 204';
  const colours = { Retweets: '0, 28, 53', Tweets: '0, 148, 204' };

  const createBackgroundColor = (color) => {
    if (typeOfChart === 'Line') {
      return `rgb(${color}, 0.2)`;
    } else {
      return labels.map((item, index) => {
        return isDiagonalBackground(item)
          ? pattern.draw('diagonal-right-left', `rgb(${color})`, 'rgba(255,255,255,0.6)', 6)
          : `rgb(${color})`;
      });
    }
  };

  const borderWidth = () => {
    if (typeOfChart === 'Bar') {
      return 0;
    } else {
      return 2;
    }
  };
  const dash = (ctx) => (ctx.p0DataIndex > labels.length - 3 ? [6, 6] : [borderWidth(), 0]);

  let dataContent = {
    labels,
    datasets: [
      {
        label: 'Total mentions',
        data: data?.value,
        borderWidth: borderWidth(),
        hoverBorderWidth: borderWidth(),
        pointRadius: 3,
        pointHoverRadius: 3,
        pointBackgroundColor: `rgb(${color})`,
        pointHoverBackgroundColor: `rgb(${color})`,
        pointBorderWidth: borderWidth(),
        borderColor: `rgb(${color})`,
        fill: true,
        hoverBackgroundColor: createBackgroundColor(color),
        backgroundColor: createBackgroundColor(color),
      },
    ],
  };
  if (typeOfChart === 'Line') {
    dataContent = {
      ...dataContent,
      datasets: [
        {
          ...dataContent.datasets[0],
          segment: {
            borderDash: (ctx) => dash(ctx) || [borderWidth(), 0],
          },
        },
      ],
    };
  }

  if (!stakeholdersContributions && data?.value) {
    let datasets = [];
    Object.keys(data.value).forEach((item) => {
      let value = data.value[item];
      let color = colours[item];
      let dataset = {
        label: item,
        data: value?.map((item) => item.value),
        borderWidth: 0,
        pointRadius: 3,
        borderColor: `rgb(${color})`,
        fill: true,
        hoverBackgroundColor: createBackgroundColor(color),
        backgroundColor: createBackgroundColor(color),
      };
      datasets.push(dataset);
      if (typeOfChart === 'Line') {
        dataset = {
          ...dataset,
          segment: {
            borderDash: (ctx) => dash(ctx) || [borderWidth(), 0],
          },
        };
      }
    });
    dataContent = {
      ...dataContent,
      datasets,
    };
  }

  return (
    <div
      style={{ height: totalHeight ? '100%' : `${heightGraph}px` }}
      className={`${emptyMessage ? 'flex-centered justify-content-center' : ''}`}
    >
      {analyticsRateLimitReached ? (
        <EmptyStateRateLimit />
      ) : (
        <>
          {emptyMessage ? (
            <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
              {analyticsRateLimitReached ? 'Try again soon or sign in for unlimited searches' : 'No mentions found'}
            </p>
          ) : (
            <>
              {typeOfChart === 'Bar' && <Bar options={options} data={dataContent} />}
              {typeOfChart === 'Line' && <Line options={options} data={dataContent} />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardMentionsChart;
