export const insertListTinyEditor = (e, editor) => {
  const AUTOBULLET_TRIGGER_CHARACTER = ['-', '*'];
  const AUTONUMBEREDLIST_TRIGGER_CHARACTER = ['1.'];
  const AUTOBULLET_VALID_CONTAINERS = ['HTMLTableCellElement', 'HTMLParagraphElement', 'HTMLDivElement', 'HTMLElement'];

  var sel = editor.selection.getSel();
  var caretPos = sel.anchorOffset;
  var txtData = sel.anchorNode.textContent;

  // Char code 160 is a non breaking space
  const bulletLineMatchArr = AUTOBULLET_TRIGGER_CHARACTER.map((item) => `${item}${String.fromCharCode(160)}`);
  const numberedListLineMatchArr = AUTONUMBEREDLIST_TRIGGER_CHARACTER.map(
    (item) => `${item}${String.fromCharCode(160)}`
  );
  const clearTriggerCharacters = (caretPos) => {
    const edRange = editor.selection.getRng();
    const edNode = edRange.commonAncestorContainer;
    let range = document.createRange(); // create a new range object for the deletion
    range.selectNodeContents(edNode);
    range.setStart(edNode, edRange.endOffset - caretPos); // current caret pos - 3
    range.setEnd(edNode, edRange.endOffset); // current caret pos
    range.deleteContents();
  };
  if (e.key === ' ') {
    if (bulletLineMatchArr.includes(txtData) && caretPos === 2) {
      if (AUTOBULLET_VALID_CONTAINERS.includes(sel.focusNode.parentElement.constructor.name)) {
        // Create an unordered list
        editor.execCommand('InsertUnorderedList', false);
        clearTriggerCharacters(2);
      }
    }
    if (numberedListLineMatchArr.includes(txtData) && caretPos === 3) {
      if (AUTOBULLET_VALID_CONTAINERS.includes(sel.focusNode.parentElement.constructor.name)) {
        // Create an ordered list
        editor.execCommand('InsertOrderedList', false);
        clearTriggerCharacters(3);
      }
    }
  }
};
