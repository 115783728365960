import MicroModal from 'micromodal';
import React, { useCallback } from 'react';
const AdminConfirmDeleteItem = (props) => {
  const { setShowPopUp, deleteKeywordList, topicId } = props;
  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      MicroModal.show('deletetopic-admin-pop-up', {
        awaitCloseAnimation: true,
        disableFocus: true,
      });
    }
  }, []);
  const closeMicroModal = () => {
    MicroModal.close('deletetopic-admin-pop-up');
    setTimeout(() => {
      setShowPopUp(false);
    }, 300);
  };
  return (
    <div
      ref={micromodalElement}
      className='modal micromodal-slide delete-keywordcategory-modal'
      id={`deletetopic-admin-pop-up`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div className='modal__container py-4' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <div className='preregister-close px-2' onClick={closeMicroModal}>
            <img src={`${process.env.REACT_APP_CDNURL}/images/preregister-close.svg`} alt='close-icon' />
          </div>

          <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
            <div className='text-center'>
              <p>Are you sure you want to delete this topic?</p>

              <div className='my-4'>
                <button
                  className='btn btn-general action-button px-4 py-1'
                  onClick={async (e) => {
                    await deleteKeywordList({ e, topicId });
                  }}
                >
                  Delete topic
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminConfirmDeleteItem;
