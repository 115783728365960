import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import axios from 'axios';
import { net_api_url, store } from '../../Store';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const NoTopicsView = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const { substractBorder } = props;

  const { getAccessToken } = useGetAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const [userInformation, setUserInformation] = useState({});
  const { userType } = userInformation;

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadUserType(source);
    return () => {
      source.cancel('User profile canceled by the user.');
    };
  }, []);
  const loadUserType = async (source) => {
    try {
      let token = await getAccessToken();
      let url = `${net_api_url}/api/user/getcurrent`;
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      if (!!response) {
        setUserInformation(response.data);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const [heightContainer, containerRef] = useHeightContainer();
  const { topicOrClientWord, transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <div ref={containerRef} />
      <div
        className={newAgencyPlan ? 'flex-centered justify-content-center' : ''}
        style={{ height: `${heightContainer - (substractBorder ? 1 : 0)}px` }}
      >
        {!isLoading && (
          <div className={`pb-4 p-lg-5 ${newAgencyPlan ? 'new-agency-empty-message text-center' : ''}`}>
            <h3 className={`main-title title-h3-bold mb-2  main-subtle-text adjust-line-height`}>
              {newAgencyPlan
                ? `You haven't created any topics for ${team?.teamName} yet`
                : `Add ${transformTopicOrClientWord({ plural: true })} to make the most out of your PolicyMogul account`}
            </h3>
            {newAgencyPlan ? (
              <p className='main-light-text mb-4' style={{ maxWidth: '500px' }}>
                Create a topic for {team?.teamName} in order to see relevant political updates, stakeholders and
                analysis
              </p>
            ) : (
              <ul className='policy-list no-lists-features py-3 py-lg-5 pl-3 pl-lg-4'>
                {userType !== 'Consultant' && userType !== 'Lobbying or PR agency' && (
                  <>
                    <li className='py-1'>View the latest developments on different issues with a single click</li>
                    <li className='py-1'>Receive email alerts for the political developments that matter to you</li>
                    <li className='py-1'>Keep track of updates for your core and peripheral interests separately</li>
                  </>
                )}
                {(userType === 'Consultant' || userType === 'Lobbying or PR agency') && (
                  <>
                    <li className='py-1'>View the latest developments for each client with a single click</li>
                    <li className='py-1'>Receive separate email alerts for each {topicOrClientWord} you create</li>
                    <li className='py-1'>Monitor issues of interest to prospective clients</li>
                  </>
                )}
              </ul>
            )}
            <Link to={`/settings/${transformTopicOrClientWord({ plural: true })}/new-${topicOrClientWord}`}>
              <button className='general-button action-button px-4 py-2 rounded-button'>
                Add your first {topicOrClientWord} {newAgencyPlan ? ` for ${team?.teamName}` : ''}
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default NoTopicsView;
