import React from 'react';
import useEmailDomainFunctions from '../hooks/useEmailDomainFunctions';
import { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../Login/Loading';
import { useNavigate } from 'react-router-dom-v5-compat';
import Axios from 'axios';

function EmailVerificationSucccess() {
  const { syncEmailDomain } = useEmailDomainFunctions();
  const navigate = useNavigate();

  let initialCall = async (source) => {
    let res = await syncEmailDomain({ isAdminPage: false, source });
    if (res || res === false) {
      navigate('/influence/emails');
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    try {
      initialCall(source);
    } catch (e) {}

    return () => {
      source.cancel('Email sync canceled by the user.');
    };
  }, []);
  return <></>;
}

export default withAuthenticationRequired(EmailVerificationSucccess, {
  onRedirecting: () => <Loading />,
});
