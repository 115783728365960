import dayjs from 'dayjs';
import { useContext, useEffect } from 'react';
import { useState, useRef } from 'react';
import { store } from '../../../../Store';
import normalize from '../../../utilities/normalizeString';
import useOutsideElement from '../../../utilities/useOutsideElement';
import { CalendarPopup } from '../../../Filters/ui/DateFilter';
import { DateOptionsMapping } from '../../createAnalyticsState';
import { useLocation } from 'react-router-dom-v5-compat';

const ChartViews = ['Day', 'Week', 'Month'];
const ChartTypes = ['Line', 'Bar'];
const MapTypes = ['Heat map', 'Bubble chart map'];
const DateOptions = DateOptionsMapping;

const FiltersBar = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsState } = state;
  const location = useLocation();

  const { typeOfChartSections, scrollBarPosition, chartName } = props;
  const sentimentChanges = location.pathname.includes('/sentiment-changes') || chartName === 'Sentiment changes';
  const interestByRegion = location.pathname.includes('/interest-by-region') || chartName === 'Interest by region';

  const hideDateFilter = interestByRegion;
  const hideChartView = interestByRegion;

  const modifyFiltersBar = ({ property, newValue }) => {
    let newState = property === 'CompleteState' ? newValue : { ...analyticsState, [property]: newValue };
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'analyticsState',
      value: newState,
    });
  };

  return (
    <div className='flex-grow-1 d-flex justify-content-end'>
      {!hideDateFilter && (
        <DateFilterButton
          analyticsState={analyticsState}
          modifyFiltersBar={modifyFiltersBar}
          customLeftValue={0}
          scrollBarPosition={scrollBarPosition}
        />
      )}

      {typeOfChartSections && (
        <TypeOfChartOptions
          sentimentChanges={sentimentChanges}
          interestByRegion={interestByRegion}
          modifyFiltersBar={modifyFiltersBar}
          analyticsState={analyticsState}
        />
      )}

      {!hideChartView && <ChartViewOptions analyticsState={analyticsState} modifyFiltersBar={modifyFiltersBar} />}
    </div>
  );
};

const TypeOfChartOptions = (props) => {
  const { sentimentChanges, analyticsState, modifyFiltersBar, interestByRegion } = props;
  const { typeOfChartSentimentChanges, typeOfChart, typeOfChartInterestByRegion } = analyticsState;
  const types = interestByRegion ? MapTypes : ChartTypes;
  return (
    <div className='filter-group ml-3 nowrap-item chart-type-filter'>
      {types.map((item) => {
        const propertyToCompare = sentimentChanges
          ? typeOfChartSentimentChanges
          : interestByRegion
            ? typeOfChartInterestByRegion
            : typeOfChart;
        return (
          <button
            key={`type-${item}`}
            className={`general-button filter-item-button ${propertyToCompare === item ? 'active' : ''}`}
            onClick={() => {
              if (interestByRegion) {
                modifyFiltersBar({
                  property: 'typeOfChartInterestByRegion',
                  newValue: item,
                });
              } else {
                modifyFiltersBar({ property: 'typeOfChart', newValue: item });
                if (sentimentChanges) {
                  modifyFiltersBar({
                    property: 'typeOfChartSentimentChanges',
                    newValue: item,
                  });
                }
              }
            }}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
};

const ChartViewOptions = (props) => {
  const { analyticsState, modifyFiltersBar } = props;
  const { currentView } = analyticsState;
  return (
    <div className='filter-group ml-3 nowrap-item '>
      {ChartViews.map((item) => (
        <button
          key={`type-${item}`}
          className={`general-button filter-item-button ${currentView === item ? 'active' : ''}`}
          onClick={() => {
            modifyFiltersBar({ property: 'currentView', newValue: item });
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

const DateFilterButton = (props) => {
  const { analyticsState, modifyFiltersBar, customTopValue = 42, customLeftValue = 40, scrollBarPosition } = props;
  const { activeDateInterval } = analyticsState;
  const [openOptions, setOpenOptions] = useState(false);
  const [calendarState, setCalendarState] = useState({
    active: false,
    filterProperty: '',
    selectedDay: new Date(),
    name: '',
    range:
      analyticsState.activeDateInterval.filter.length === 2 && analyticsState.activeDateInterval.name === 'Custom'
        ? {
            from: new Date(analyticsState.activeDateInterval.filter[0]),
            to: new Date(analyticsState.activeDateInterval.filter[1]),
          }
        : {
            from: '',
            to: '',
          },
  });

  const filtersElement = useRef(null);
  const location = useLocation();
  const hideFilters = () => {
    setOpenOptions(false);
  };
  useOutsideElement(filtersElement, hideFilters);

  useEffect(() => {
    if (!!scrollBarPosition) {
      setOpenOptions(false);
    }
  }, [scrollBarPosition]);

  let createStyles = () => {
    if (filtersElement.current) {
      let { top, width, left } = filtersElement.current.getBoundingClientRect();
      return {
        position: 'fixed',
        top: top + customTopValue,
        right: 0,
        left: activeDateInterval.name === 'Custom' ? left : left - customLeftValue,
        width,
      };
    }
  };

  const applyCalendarFilter = () => {
    let daysDiff = dayjs(calendarState.range.to).diff(dayjs(calendarState.range.from), 'days');
    let newAnalyticsState = {
      ...analyticsState,
      activeDateInterval: {
        name: 'Custom',
        filter: [
          dayjs(calendarState.range.from).format('YYYY-MM-DD'),
          dayjs(calendarState.range.to).format('YYYY-MM-DD'),
        ],
      },
    };
    if (daysDiff < 14) {
      newAnalyticsState.currentView = 'Day';
    } else if (daysDiff > 14 && daysDiff <= 90) {
      newAnalyticsState.currentView = 'Week';
    } else {
      newAnalyticsState.currentView = 'Month';
    }

    modifyFiltersBar({ property: 'CompleteState', newValue: newAnalyticsState });
    hideFilters();
  };

  const getTitle = () => {
    if (activeDateInterval.name === 'Custom') {
      let equalYear =
        dayjs(activeDateInterval.filter[0]).get('year') === dayjs(activeDateInterval.filter[1]).get('year');
      let equalMonth =
        dayjs(activeDateInterval.filter[0]).get('month') === dayjs(activeDateInterval.filter[1]).get('month');
      let sameYear = dayjs(activeDateInterval.filter[1]).get('year') === dayjs(new Date()).get('year');
      return `${
        activeDateInterval.filter[0]
          ? dayjs(activeDateInterval.filter[0]).format(equalYear ? (equalMonth ? 'D' : 'D MMMM') : 'D MMMM YYYY')
          : '-'
      } to ${
        activeDateInterval.filter[1]
          ? dayjs(activeDateInterval.filter[1]).format(equalYear ? `D MMMM${!sameYear ? ' YYYY' : ''}` : 'D MMMM YYYY')
          : '-'
      }`;
    } else {
      return activeDateInterval.name;
    }
  };

  const titleRef = useRef(null);

  return (
    <>
      <div className='position-relative graph-date-button-container' ref={filtersElement}>
        <div className='filter-group nowrap-item'>
          <button
            className={`general-button filter-item-button`}
            onClick={() => {
              setOpenOptions(!openOptions);
            }}
          >
            <span
              className={`dropdown-item-element dropdown-item-date-element ${openOptions ? 'open' : ''}`}
              ref={titleRef}
              data-tooltip-content={
                titleRef?.current?.scrollWidth > 150 && !location.pathname?.includes('/reports')
                  ? getTitle()
                  : undefined
              }
              data-tooltip-id={'general-tooltip'}
            >
              {getTitle()}
            </span>
          </button>
        </div>
        {openOptions && (
          <div className='login-navbar py-2' style={createStyles()}>
            {DateOptions.map((item, index) => {
              const { name } = item;
              return (
                <button
                  className='settings-button setting-item general-button d-block text-left w-100 px-4 position-relative'
                  key={`date-${normalize(name)}${index}`}
                  onClick={() => {
                    if (name === 'Custom') {
                      if (window.location.pathname === '/sentiment') {
                        modifyFiltersBar();
                      } else {
                        setCalendarState({
                          ...calendarState,
                          active: true,
                          filterProperty: 'range',
                          name: name,
                        });
                      }
                    } else {
                      modifyFiltersBar({
                        property: 'activeDateInterval',
                        newValue: item,
                      });
                      hideFilters();
                      setCalendarState({
                        ...calendarState,
                        range: { from: '', to: '' },
                      });
                    }
                  }}
                >
                  {name}
                  {activeDateInterval?.name === name && <span className='icon-tick topic-selected-mark paragraph-p4' />}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {calendarState.active && (
        <CalendarPopup
          calendarState={{
            ...calendarState,
            range: {
              from: calendarState.range.from,
              to: calendarState.range.to,
            },
          }}
          setCalendarState={setCalendarState}
          ApplyCalendarFilters={applyCalendarFilter}
          analyticsPage
          setShowPopup={(val) => setCalendarState({ ...calendarState, active: val })}
          resetOnApply={() => {
            let newAnalyticsState = {
              ...analyticsState,
              activeDateInterval: DateOptions.find((item) => item.name === 'Last 12 months'),
              currentView: 'Month',
            };
            modifyFiltersBar({ property: 'CompleteState', newValue: newAnalyticsState });
            hideFilters();
          }}
        />
      )}
    </>
  );
};

export { DateOptions, ChartViewOptions, TypeOfChartOptions, DateFilterButton };
export default FiltersBar;
