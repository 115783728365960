import React from 'react';
import normalize from '../../utilities/normalizeString';
const FiltersOutsidePopup = (props) => {
  const { filters, filterTypeModifiers, applyFiltersFunction } = props;
  return (
    <>
      {filters
        .filter((item) => item.excludeFromPopup)
        .map((filter) => {
          const { type, name, value } = filter;
          if (type === 'toggle') {
            return (
              <div className='position-relative filter-main-container' key={`item-${normalize(name)}`}>
                <button
                  className={`sort-button filter-button hand-picked-filters hand-picked-${value ? 'active' : 'inactive'}`}
                  onClick={() => {
                    let newFilters = filterTypeModifiers({ filter, newValue: !value });
                    applyFiltersFunction(newFilters);
                  }}
                >
                  <span className={`hand-picked-toggle`} />
                  <span className='ml-2'>{name}</span>
                </button>
              </div>
            );
          } else {
            return null;
          }
        })}
    </>
  );
};

export default FiltersOutsidePopup;
