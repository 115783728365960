import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { store } from '../../../Store';
import useIsMobile from '../../utilities/useIsMobile';
import useOutsideElement from '../../utilities/useOutsideElement';
import TotalResults from '../../components/TotalResults';
import useDetectActiveFilters from '../../SubNav/useDetectActiveFilters';
import InfluenceCrmSideBar from '../InfluenceCrmSideBar/InfluenceCrmSideBar';
import StakeholderSelectBar from '../../StakeHolders/StakeholderPage/ui/StakeholderSelectBar/StakeholderSelectBar';
import TransparentBackgroundToPreventClick from '../../components/TransparentBackgroundToPreventClick';
import { useLocation } from 'react-router-dom-v5-compat';
import thousandCommas from '../../helpers/thousandCommas';
import { useNoTopicsCondition } from '../../components/EmptyMessage';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';

const InfluenceSectionTitle = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const {
    search,
    contentResults,
    activeResults,
    listDomainsVerified,
    totalEmailsCount,
    teamContentResultsQntty,
    updateCustomContactTitle,
    crmContactsQtty,
    activePlan,
    selectedCrmContacts,
    team,
  } = state;
  const { list } = search;
  const { enableStakeholders } = activePlan;

  const { Stakeholders, CrmEmails, CrmContacts } = contentResults[activeResults];
  const stakeholdersToRender = Stakeholders?.hits ?? [];
  const emailsToRender = CrmEmails?.results ?? [];

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const contactsSelected =
    selectedIds?.length > 0 ? selectedIds.length : selectedAll ? CrmContacts?.totalHits : selectedAllVisible?.length;
  const { detectActiveFilters } = useDetectActiveFilters();
  const activeFilters = detectActiveFilters() || list?.id;
  const { newAgencyPlan } = useTopicOrClientWord();

  const isMobile = useIsMobile();
  const isSmallMobile = useIsMobile(480);
  const [showBox, setShowBox] = useState(false);

  const actionsElement = useRef(null);
  const location = useLocation();

  const hideActions = () => {
    setShowBox(false);
  };

  const helperTextRef = useRef();

  const needsDomainVerification =
    (!listDomainsVerified.verifiedDomains.length ||
      (!listDomainsVerified.allDomains.length && !listDomainsVerified.verifiedEmailAddresses.length) ||
      !listDomainsVerified.verifiedEmailAddresses.length) &&
    !listDomainsVerified.domainsProccessing;

  const firstTimeContactsAdded = useMemo(() => {
    return JSON.parse(localStorage.getItem('firstTimeContactsAdded'));
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      if (firstTimeContactsAdded?.animationShown === false) {
        localStorage.setItem(
          'firstTimeContactsAdded',
          JSON.stringify({ firstTimeContactsAdded: true, animationShown: true })
        );
      }
    };
  }, [location.pathname]);

  useOutsideElement(actionsElement, hideActions);

  const policymakersSection = location.pathname.includes('/policymakers');
  const influenceRoute = location.pathname.split('/')?.[1];
  const innerInfluenceRoute = location.pathname.split('/')?.[2];
  const updateContactsPage = location.pathname.split('/')?.[3] === 'update';
  const emailComponentPage =
    (innerInfluenceRoute === 'emails' && location.pathname.split('/')?.[3]) ||
    location.pathname.includes('/email-performance');

  const influenceSectionMapping = {
    'stakeholder-mapping': 'Stakeholder mapping',
    contacts: updateContactsPage
      ? `${updateCustomContactTitle.firstName} ${updateCustomContactTitle.lastName}`
      : 'Contacts',
    'lobbying-material': newAgencyPlan ? `Content for ${team?.teamName}` : "My team's content",
    emails: ((needsDomainVerification && isMobile) || !needsDomainVerification) && 'Email campaigns',
    inbox: 'Inbox',
    'engagement-statistics': 'Engagement statistics',
    policymakers: 'Policymakers',
    'ai-assistant': 'AI assistant',
  };

  const showItemDropdown = isMobile;

  const influenceTitleHelperTextMapping = {
    'stakeholder-mapping': stakeholdersToRender.length > 0 && activeFilters && (
      <>
        <TotalResults onlyNumber />
        matching stakeholders.
      </>
    ),
    contacts:
      updateContactsPage || !crmContactsQtty
        ? ''
        : contactsSelected > 0
          ? `${thousandCommas(contactsSelected)} contact${contactsSelected > 1 ? 's' : ''} selected`
          : 'Select contacts to send emails, add tags and take other actions',
    'lobbying-material': !!teamContentResultsQntty && `${thousandCommas(teamContentResultsQntty)} results`,
    emails:
      emailsToRender.length > 0 &&
      !!totalEmailsCount &&
      !needsDomainVerification &&
      `${thousandCommas(totalEmailsCount)} email${totalEmailsCount > 0 ? 's' : ''}`,
    inbox: 'Inbox',
    policymakers: <TotalResults />,
  };

  const stakeholderSelectBarCondition = innerInfluenceRoute === 'stakeholder-mapping' && enableStakeholders;

  const animationOnContacts =
    innerInfluenceRoute === 'contacts' &&
    firstTimeContactsAdded?.firstTimeContactsAdded === true &&
    firstTimeContactsAdded?.animationShown === false;

  const { newAgencyNoTopicsCondition } = useNoTopicsCondition();

  return (
    <>
      <div className='row mx-auto main-content-wrapper pb-lg-0'>
        {!policymakersSection && !updateContactsPage && (
          <div className='side-left-element d-none d-lg-block' style={{ width: '280px' }} />
        )}
        <div
          className={`main-content influence-section-title pr-xl-5 px-md-4 px-3 ${
            innerInfluenceRoute || policymakersSection ? 'influence-section-title-stakeholders' : ''
          } ${policymakersSection ? 'pl-xl-5 w-100' : ''} ${newAgencyNoTopicsCondition ? 'influence-section-title-empty-agency' : ''}`}
        >
          {((crmContactsQtty && innerInfluenceRoute === 'contacts') || innerInfluenceRoute !== 'contacts') &&
            !newAgencyNoTopicsCondition && (
              <>
                <TransparentBackgroundToPreventClick popupOpened={showBox} />
                <div className='section-title-left-side' ref={actionsElement}>
                  {stakeholderSelectBarCondition && <StakeholderSelectBar listId={list?.id} />}
                  <div>
                    {!emailComponentPage && (
                      <h3
                        className='my-lg-0 main-title mb-0'
                        onClick={() => {
                          if (showItemDropdown) {
                            setShowBox(!showBox);
                          }
                        }}
                      >
                        <span
                          className={`font-weight-bold ${
                            showItemDropdown ? 'dropdown-item-element pointer dropdown-item-element-main-title' : ''
                          } ${showBox ? 'open' : ''}`}
                        >
                          {influenceSectionMapping[innerInfluenceRoute ?? influenceRoute]}
                        </span>
                        {detectActiveFilters() && location.pathname === '/influence/contacts' && (
                          <span className='paragraph-p1 main-ligh-text ml-1'>
                            ({thousandCommas(CrmContacts?.totalHits)} record
                            {CrmContacts?.totalHits === 1 ? '' : 's'})
                          </span>
                        )}
                      </h3>
                    )}
                    {showBox && (
                      <>
                        <div className='main-title-popup'>
                          <div
                            className={`login-navbar py-2 main-title-items influence-section-picker ${
                              stakeholderSelectBarCondition ? 'influence-section-picker-stakeholders' : ''
                            }`}
                          >
                            <InfluenceCrmSideBar closeMenu={hideActions} mobile />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!isSmallMobile && !emailComponentPage && (
                  <div
                    ref={helperTextRef}
                    className={`section-title-helper-text ${
                      innerInfluenceRoute === 'stakeholder-mapping' ? 'stakeholder-mapping-helper-text' : ''
                    } ${
                      innerInfluenceRoute === 'lobbying-material' ? 'lobbying-title-helper-text' : ''
                    } ${animationOnContacts ? 'title-helper-text-animated' : ''}`}
                  >
                    {influenceTitleHelperTextMapping[innerInfluenceRoute ?? influenceRoute]}
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default InfluenceSectionTitle;
