import { useEffect } from 'react';

const useScrollBody = () => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflowY = 'scroll';
    return () => {
      body.style.overflowY = 'hidden';
    };
  }, []);
};

export default useScrollBody;
