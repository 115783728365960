const validateFields = (props) => {
  const { formFields, setFormFields } = props;
  const finalFields = formFields.slice();
  const updateErrorMessage = (item, errorMessage, i) => {
    let newItem = {
      ...item,
      errorMessage: errorMessage,
    };
    finalFields[i] = newItem;
    setFormFields(finalFields);
  };
  for (let i = 0; i < finalFields.length; i++) {
    let item = finalFields[i];
    //Parliamentarian name field change the value
    let fieldValue = item.type === 'autocomplete' ? item.value.label : item.value;
    //Validate that any item is not empty
    if (item.fieldName !== null && item.required && item.value === '') {
      let errorMessage = 'Please complete this field';
      updateErrorMessage(item, errorMessage, i);
      return false;
    } else if (item.validationRule && !item.validationRule(fieldValue)) {
      //Specific rules for each field
      let errorMessage = item.validationMessage;
      updateErrorMessage(item, errorMessage, i);
      return false;
    }
  }
  return true;
};

export default validateFields;
