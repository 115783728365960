import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { initialCrmEmailState, store } from '../../../Store';
import CrmEmailTableItem from './CrmEmailTableItem/CrmEmailTableItem';
import { useCallback } from 'react';
import { preventHistoryBack } from '../../helpers/preventHistoryBack';
import CrmEmailsTableSkeleton, { CrmEmailItemSkeleton } from './CrmEmailsTableSkeleton';
import EmailsOnBoarding from './EmailsOnBoarding';
import isDummyTableItem from '../../helpers/isDummyTableItem';
import { loadMoreTableRowsDependOnScreen } from '../../helpers/loadMoreTableRowsDependOnScreen';
import addMetaTags from '../../utilities/addMetaTags';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import useGetAccessToken from '../../apiCalls/useGetAccessToken';

function CrmEmails() {
  const columns = [
    {
      accessorKey: 'subject',
      header: 'Title',
      ref: useRef(),
    },
    {
      accessorKey: 'status',
      header: 'State',
      ref: useRef(),
    },
    {
      accessorKey: 'lastEditedBy',
      header: 'Last edited by',
      ref: useRef(),
    },
    {
      accessorKey: 'from',
      header: 'Sender',
      ref: useRef(),
    },
    {
      accessorKey: 'sendRecordCount',
      header: 'Recipients',
      ref: useRef(),
    },
    {
      accessorKey: 'openedCount',
      header: 'Opens',
      ref: useRef(),
    },
    {
      accessorKey: 'lastUpdated',
      header: 'Updated at',
      ref: useRef(),
    },
    {
      accessorKey: 'finishedSendingAt',
      header: 'Sent at',
      ref: useRef(),
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const initialCall = useRef();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { net_api_url, crmEmailsTableOnSort, listDomainsVerified, searchEmailsString, team } = state;
  const { contentResults, activeResults } = state;
  const { CrmEmails } = contentResults[activeResults];
  const [tableHeight, setTableHeight] = useState('auto');
  const [scrollTopContainer, setScrollTopContainer] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const [activeIndex, setActiveIndex] = useState(null);
  const [pageSize, setPageSize] = useState(20);
  const [updateBySort, setUpdateBySort] = useState('');
  const [scrollPositionX, setScrollPositionX] = useState(crmEmailsTableOnSort?.scrollPositionX);

  const needsDomainVerification =
    //no verified domains
    ((((!listDomainsVerified.verifiedDomains.length || !listDomainsVerified.allDomains.length) &&
      !listDomainsVerified.verifiedEmailAddresses.length) ||
      //no verified addresses
      !listDomainsVerified.verifiedEmailAddresses.length) &&
      !listDomainsVerified.domainsProccessing) ||
    location?.state?.setUpDomain;

  const [afterInitialSort, setAfterInitialSort] = useState(false);

  const tableElement = useRef(null);
  const scrollBarRef = useRef(null);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setScrollTopContainer({ scrollTop, scrollHeight, clientHeight });
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      setScrollPositionX(0);
      load_more_results();
    }
  };

  const isDummyItem = useCallback((props) => isDummyTableItem(props), [scrollTopContainer]);

  //LOAD ONSCROLL
  const load_more_results = async () => {
    if (CrmEmails !== undefined) {
      let { thereIsMoreContent, pageNumber } = CrmEmails;
      if (thereIsMoreContent) {
        try {
          await createResults(pageNumber + 1);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        }
      }
    }
  };

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const query = new URLSearchParams(location.search);
  const sort = query.get('sort') ? query.get('sort').split('_') : null;
  const searchQuery = query.get('search') ? decodeURI(query.get('search')) : '';

  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  initialCall.current = async (source) => {
    try {
      await createResults(null, null, source);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setScrollTopContainer({
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
    });
    initialCall.current(source);
    return () => {
      source.cancel('CrmEmails canceled by the user.');
    };
  }, [updateBySort, searchEmailsString]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailStateAfterSave',
      value: initialCrmEmailState(),
    });
  }, []);

  useEffect(() => {
    if (!isLoading && scrollPositionX && scrollBarRef.current) {
      scrollBarRef.current.scrollLeft = scrollPositionX;
    }
  }, [isLoading, scrollBarRef.current]);

  //PREVENT HISTORY BACK ON TRACKPAD SCROLL
  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.addEventListener('mousewheel', (e) => preventHistoryBack(e, scrollBarRef.current));
      loadMoreTableRowsDependOnScreen({
        containerHeight: scrollBarRef.current.clientHeight,
        cellHeight: 57.8,
        headerHeight: 50,
        setPageSize,
        pageSize,
      });
    }
  }, [scrollBarRef.current]);

  useEffect(() => {
    if (pageSize > 20) {
      load_more_results();
    }
  }, [pageSize]);

  async function createResults(pageNumber, processing, sourceProp) {
    if (!pageNumber && !processing) {
      setIsLoading(true);
    }
    const params = {};
    if (sort) {
      params.sortField = sort[0];
      params.sortOrder = sort[1];
    } else if (crmEmailsTableOnSort?.sort?.length) {
      params.sortField = crmEmailsTableOnSort?.sort.split('_')[0];
      params.sortOrder = crmEmailsTableOnSort?.sort.split('_')[1];
    } else {
      params.sortField = 'lastUpdated';
      params.sortOrder = 'descending';
    }
    params.query = searchQuery;
    params.pageNumber = pageNumber ? pageNumber : 1;

    const urlParams = new URLSearchParams(params);

    let token = isAuthenticated ? await getAccessToken() : '';
    let url = `${net_api_url}/api/crm-email/search?${urlParams.toString()}&pageSize=${pageSize}`;
    let requestOptions = {
      cancelToken: sourceProp ? sourceProp.token : source.token,
    };
    if (isAuthenticated) {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    let callResults = await Axios.get(url, requestOptions);
    let result = callResults.data;
    let emailResults = pageNumber ? CrmEmails.results : [];
    if (result.results !== null) {
      emailResults = emailResults.concat(result.results);
    }
    let emailsQntty = callResults.data.totalCount;
    let currentId = result.hits !== null && result.results.length > 0 ? result.results[0].crmEmailId : 0;
    let ResultsToState = {
      ...result,
      results: emailResults,
      pageNumber: pageNumber ? pageNumber : 1,
      currentId: currentId,
      thereIsMoreContent: result.results !== null ? result.results.length >= pageSize : false,
    };
    let CrmEmailsResults = {
      ...contentResults,
      [activeResults]: {
        CrmEmails: ResultsToState,
      },
    };
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'contentResults',
      value: CrmEmailsResults,
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'totalEmailsCount',
      value: emailsQntty,
    });
    setAfterInitialSort(true);
  }

  //SET FILTERS TO URL
  useEffect(() => {
    if (crmEmailsTableOnSort?.sort?.length) {
      query.set('sort', crmEmailsTableOnSort?.sort);
    }
    let urlToHistory = `${location.pathname}?${query.toString()}`;
    let title = `Email campaigns - Influence - PolicyMogul`;
    addMetaTags({ title, hash: null, location, dispatch });
    navigate(urlToHistory, { replace: true });
  }, [crmEmailsTableOnSort]);

  //HANDLING QUERY PARAMS FOR SORTING
  const handleChangeSortParam = (accessorKey) => {
    setScrollPositionX(scrollBarRef.current.scrollLeft);
    if (sort) {
      if (sort[0] === accessorKey) {
        if (sort[1] !== 'descending') {
          query.set('sort', `${accessorKey}_descending`);
        } else {
          query.set('sort', `${accessorKey}_ascending`);
        }
      } else {
        query.set('sort', `${accessorKey}_ascending`);
      }
    } else {
      query.set('sort', `${accessorKey}_ascending`);
    }
    navigate(
      {
        pathname: location.pathname,
        search: query.toString(),
      },
      { replace: true }
    );
    setUpdateBySort(query.get('sort'));
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'crmEmailsTableOnSort',
      value: {
        sort: query.get('sort'),
      },
    });
  };

  const emailsToRender = CrmEmails?.results ?? [];

  useEffect(() => {
    if (tableElement.current) {
      setTableHeight(tableElement.current.clientHeight);
    }
  }, [tableElement.current, afterInitialSort]);

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex) {
          let width = e.clientX - columns[activeIndex].ref.current.getBoundingClientRect().left;
          width = width > 126 ? width : 126;
          return `${width}px`;
        }
        return `${col.ref.current.offsetWidth}px`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const mouseDown = (e, index) => {
    e.stopPropagation();
    setActiveIndex(index);
  };

  const renderTableHeading = () => {
    const columnOnSort = (col) => {
      if (sort) {
        return sort[0] === col.accessorKey ? sort[1] : false;
      }
    };
    const renderSortArrows = (sort) => {
      return (
        <div className='crm-table-arrows-container'>
          <span style={{ opacity: sort === 'ascending' ? 1 : 0.4 }} />
          <span style={{ opacity: sort === 'descending' ? 1 : 0.4 }} />
        </div>
      );
    };
    return (
      <thead>
        <tr>
          {columns.map((col, index) => {
            return (
              <th
                key={index}
                style={{
                  backgroundColor:
                    columnOnSort(col) === 'descending' || columnOnSort(col) === 'ascending' ? '#fafafa' : '#fff',
                }}
                ref={col.ref}
              >
                <div
                  className='crm-table-header'
                  onClick={() => (!col.disabled ? handleChangeSortParam(col.accessorKey) : undefined)}
                >
                  {col.header}
                  {!col.disabled ? renderSortArrows(columnOnSort(col)) : ''}
                </div>
                {index !== 1 && (
                  <div
                    style={{ height: tableHeight }}
                    className='resize-handle-influence-column-container'
                    onMouseDown={(e) => mouseDown(e, index)}
                  >
                    <div
                      className={`resize-handle-influence-column ${
                        activeIndex === index ? 'active-column-influence' : ''
                      }`}
                    />
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  return (
    <>
      <div className={`main-content pl-md-4 pl-3 h-100`}>
        <div className='d-flex flex-column h-100'>
          <div
            className={`row mx-0 h-100 ${
              (!isLoading && afterInitialSort && !CrmEmails?.results?.length > 0) || needsDomainVerification
                ? 'pr-md-4 pr-3'
                : ''
            }`}
          >
            <div className='flex-grow-1' style={isLoading ? { overflow: 'hidden' } : null}>
              {(isLoading || !afterInitialSort || listDomainsVerified.domainsProccessing) && (
                <div
                  style={{
                    overflow: 'hidden',
                  }}
                >
                  <CrmEmailsTableSkeleton />
                </div>
              )}
              {!isLoading &&
                afterInitialSort &&
                (!CrmEmails?.results?.length > 0 || needsDomainVerification ? (
                  <>
                    {!query.get('search') && !needsDomainVerification ? (
                      <div className='contact-list-scrollbar-table-no-data my-lg-2'>
                        <div className='crm-table-no-contacts'>
                          <p className='crm-table-no-contacts-title'>You haven't sent any emails yet</p>
                          <p className='crm-table-no-contacts-description'>
                            Once you or your teammates add contacts, you’ll be able to add them to email campaigns and
                            take other actions
                          </p>
                        </div>
                      </div>
                    ) : needsDomainVerification ? (
                      <EmailsOnBoarding />
                    ) : (
                      <div className='contact-list-scrollbar-table-no-data my-lg-2'>
                        <div className='crm-table-no-contacts'>
                          <p className='crm-table-no-contacts-description'>No data available</p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!isLoading && (
                      <>
                        <div className='email-list-scrollbar-table-wrapper'>
                          <div className='stakeholder-list-content-scrollbar' />
                          <div className='email-list-scrollbar-table' onScroll={handleScroll} ref={scrollBarRef}>
                            {emailsToRender.length > 0 && (
                              <>
                                <table
                                  className='crm-table crm-table-emails'
                                  ref={tableElement}
                                  style={{
                                    userSelect: activeIndex || activeIndex === 0 ? 'none' : 'auto',
                                  }}
                                >
                                  {renderTableHeading()}
                                  <tbody>
                                    {emailsToRender.map((item, index, array) => (
                                      <CrmEmailTableItem
                                        item={item}
                                        team={team}
                                        key={`email-item-${index}`}
                                        dummyItem={isDummyItem({
                                          index,
                                          itemHeight: 57.8,
                                          cachedInvisibleItems: 1,
                                          scrollTopContainer,
                                          pageSize,
                                        })}
                                      />
                                    ))}
                                    {CrmEmails?.thereIsMoreContent && <CrmEmailItemSkeleton />}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrmEmails;
