import React, { useContext } from 'react';
import Organization from './ui/Organization';
import { AdminStore } from './AdminState';

const OrganizationPage = () => {
  const adminState = useContext(AdminStore);
  const { state, dispatch } = adminState;
  const { organisation } = state;
  const setTeam = (team) => {
    let newOrganisation = {
      ...organisation,
      teams: [team],
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'organisation', value: newOrganisation });
  };

  return (
    <>
      <Organization team={organisation?.teams?.[0]} setTeam={setTeam} />
    </>
  );
};

export default OrganizationPage;
