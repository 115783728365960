import React, { useState, useRef, useEffect, useMemo } from 'react';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import useHiddenItemsFunctions from '../../Dashboard/hooks/useHiddenItemsFunctions';
import createNotification from '../Utilities/CreateNotification';

const SelectClientItems = (props) => {
  const { userItem, disabled } = props;
  const [itemsList, setItemsList] = useState([]);
  const [changingState, setChangingState] = useState(null);
  const { getHiddenClientList } = useHiddenItemsFunctions();

  useEffect(() => {
    const asyncFn = async () => {
      let items = await getHiddenClientList({ userId: userItem.id });
      if (items?.length) {
        setItemsList(items);
      }
    };
    asyncFn();
  }, []);

  useEffect(() => {
    if (userItem?.newRole === 'Admin') {
      let newitemsList = itemsList.map((item) => {
        if (item.myState === 'Disabled') {
          return { ...item, myState: 'Enabled' };
        } else {
          return item;
        }
      });
      setItemsList(newitemsList);
    }
  }, [userItem?.newRole]);

  const checkedItem = (item) => {
    return item?.myState === 'Enabled' || item?.myState === 'Hidden';
  };
  const disabledConditional = changingState || disabled;

  const allItemsChecked = useMemo(
    () => itemsList?.every((item) => item?.myState === 'Enabled' || item?.myState === 'Hidden'),
    [itemsList, userItem?.myState]
  );

  const getSelectTitle = () => {
    if (allItemsChecked) return 'All clients';
    return `${itemsList.filter(checkedItem).length} out of ${
      itemsList.length
    } client${itemsList.length > 1 ? 's' : ''}`;
  };

  const hiddenItemsElement = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const hideDropdown = () => {
    setShowDropdown(false);
  };
  useOutsideElement(hiddenItemsElement, hideDropdown);

  return (
    <div
      className='position-relative role-select-container'
      style={{
        maxWidth: '250px',
        backgroundColor: disabled ? 'rgba(196, 196, 196, 0.2)' : undefined,
      }}
      ref={hiddenItemsElement}
    >
      <p
        className={`mb-0 hour-select px-3  ${
          showDropdown ? 'hour-select-opendropdown' : ''
        } ${disabled ? 'disabled-role-select' : ''} ${changingState ? 'disabled-hour-select' : ''}`}
        onClick={() => {
          if (!disabledConditional) {
            setShowDropdown(!showDropdown);
          }
        }}
      >
        <span className={disabled ? 'px-3' : undefined}>{getSelectTitle()}</span>
      </p>

      {showDropdown && (
        <>
          <div className='frequency-dropdown text-left team-role-dropdown scroll-container'>
            {itemsList.map((item) => (
              <ItemCheckbox
                item={item}
                key={`item-checkbox-${item.teamId}`}
                changingState={changingState}
                userItem={userItem}
                checkedItem={checkedItem}
                itemsList={itemsList}
                setChangingState={setChangingState}
                setItemsList={setItemsList}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const ItemCheckbox = ({ item, changingState, userItem, checkedItem, itemsList, setChangingState, setItemsList }) => {
  const { updateHiddenClientState } = useHiddenItemsFunctions();
  const enabledItems = itemsList?.filter((item) => item?.myState !== 'Disabled');
  const switchVisability = async ({ item, returnRes, stateArg, showSuccsessMessage }) => {
    let updated;
    if (!changingState) {
      let newState = () => {
        if (stateArg) {
          return stateArg;
        } else {
          if (checkedItem(item)) {
            return 'Disabled';
          } else {
            return 'Enabled';
          }
        }
      };
      if (newState() === 'Disabled' && enabledItems?.length === 1) {
        createNotification('danger', 'Members must have at least 1 client selected');
      } else {
        if (!returnRes) setChangingState(item.teamId);
        updated = await updateHiddenClientState({
          state: newState(),
          userId: parseInt(userItem.id),
          teamId: item.teamId,
          showSuccsessMessage,
        });

        if (updated) {
          if (returnRes) {
            return updated;
          } else {
            let updatedItemsList = itemsList.map((listItem) => {
              if (listItem.teamId === item.teamId) {
                return { ...listItem, myState: newState() };
              }
              return listItem;
            });
            setItemsList(updatedItemsList);
          }
        }
        if (!returnRes) setChangingState(null);
      }
    }
  };

  return (
    <label htmlFor={`hideTopic-${item?.teamId ?? 'all'}-id`} className='mb-0 w-100 font-weight-normal'>
      <div className={`mb-0 frequency-item px-3 py-2 position-relative`}>
        {changingState === item?.teamId ? (
          <i
            className='fas fa-spinner fa-spin'
            style={{
              position: 'absolute',
              color: '#212529',
              left: '9px',
              top: '12px',
            }}
          ></i>
        ) : (
          <div
            className={`stakeholder-checkbox-input topics-hide-checkbox-dropdown`}
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type={'checkbox'}
              onChange={(e) => {
                switchVisability({
                  item,
                  showSuccsessMessage: true,
                });
              }}
              name={`hideTopic-${item?.teamId ?? 'all'}`}
              id={`hideTopic-${item?.teamId ?? 'all'}-id`}
              checked={checkedItem(item)}
            />
            <label htmlFor={`hideTopic-${item?.teamId ?? 'all'}-id`} />
          </div>
        )}
        {item?.teamName}
      </div>
    </label>
  );
};

export default SelectClientItems;
