import React from 'react';
import DateReplacements from './DatesReplacements.js';
import dayjs from 'dayjs';
import CalendarItem from './CalendarItem.js';
import { OrderCategories } from './EventsHierarchy.js';
import EventsCategoriesInsideDates from './EventsGroupedByDate.js';

const renderItem = (events, content, mainCategory, widgetEmbed, keywordListId, customKeywords) => {
  return (
    <>
      {content.length > 0 && (
        <>
          {content.map((item, index, array) => (
            <div
              className='calendar-category-container position-relative'
              key={`${mainCategory}-${index}`}
              style={{ zIndex: array.length - index }}
            >
              <h4 className={`calendar-category calendar-category-${mainCategory}`}>{item}</h4>
              {events[item].map((event, index, arr) => (
                <CalendarItem
                  key={`event${index}`}
                  item={event}
                  index={index}
                  array={arr}
                  widgetEmbed={widgetEmbed}
                  keywordListId={keywordListId}
                  customKeywords={customKeywords}
                />
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
};

const renderElements = (eventsToShow, widgetEmbed, keywordListId, customKeywords) => {
  const items = [];
  const dateToShow = (date) => {
    let dateReplacement = DateReplacements(date);
    if (dateReplacement === null) {
      return `${dayjs(date).format('dddd[,] DD MMM YYYY')}`;
    } else {
      return dateReplacement;
    }
  };
  for (var i in eventsToShow) {
    const categoriesNames = Object.keys(eventsToShow[i]);
    const categoriesToShow = OrderCategories(categoriesNames);
    const [commonCategoriesToShow, lordsCategoriesToShow, announcementsCategoriesToShow] = categoriesToShow;
    items.push(
      <div key={`event${i}`} className='search-event-container '>
        <div className='p-4 search-calendar-item mb-4'>
          <div className='content-item'>
            <h3 className='search-calendar-date'>{dateToShow(i)}</h3>
            <div className='position-relative' style={{ zIndex: 3 }}>
              {renderItem(
                eventsToShow[i],
                commonCategoriesToShow,
                'commons',
                widgetEmbed,
                keywordListId,
                customKeywords
              )}{' '}
            </div>
            <div className='position-relative' style={{ zIndex: 2 }}>
              {renderItem(eventsToShow[i], lordsCategoriesToShow, 'lords', widgetEmbed, keywordListId, customKeywords)}
            </div>
            <div className='position-relative' style={{ zIndex: 1 }}>
              {renderItem(
                eventsToShow[i],
                announcementsCategoriesToShow,
                'announcements',
                widgetEmbed,
                keywordListId,
                customKeywords
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return items;
};

const createCalendarRenderElements = ({ elements, feedItemFlags }) => {
  const eventsToShow = EventsCategoriesInsideDates(elements, feedItemFlags);
  return renderElements(eventsToShow);
};

export { createCalendarRenderElements };
export default renderElements;
