import { net_api_url } from '../../Store';
import axios from 'axios';
const loadKeyWords = async (getAccessToken, source, includehidden) => {
  try {
    let token = await getAccessToken();
    let keyWordLists = await axios.get(
      `${net_api_url}/api/keyword-list/get-team-keyword-lists${includehidden ? '?includeHidden=true' : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      }
    );
    //CHECK ERRORS FROM THE KEYWORDS SCREEN
    let responseKeyWordLists = keyWordLists.data;
    responseKeyWordLists?.myKeywordLists?.forEach((item) => {
      item.isMyList = true;
    });

    let totalLists = responseKeyWordLists?.myKeywordLists?.concat(responseKeyWordLists.teamMemberKeywordLists);
    return totalLists;
  } catch (error) {
    console.error(error);
  }
};

const loadEmailAlerts = async (getAccessToken, source) => {
  try {
    let token = await getAccessToken();
    let emailLists = await axios.get(`${net_api_url}/api/alert-settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
    //CHECK ERRORS FROM THE KEYWORDS SCREEN
    return emailLists?.data;
  } catch (error) {
    console.error(error);
  }
};

export { loadKeyWords, loadEmailAlerts };
