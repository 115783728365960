import useGetSectionFilters from '../Filters/useGetSectionFilters';
import { sectionName } from '../helpers/controlActiveFilters';

const useDetectActiveFilters = () => {
  const { getSectionFilters } = useGetSectionFilters(sectionName());
  const sectionFilters = getSectionFilters();

  const { filters, searchWithin } = sectionFilters ?? {};
  const detectActiveFilters = () => {
    return (
      (filters && filters.filter((item) => item.field !== 'eventStartDate' && !item.hidden).length > 0) ||
      !!searchWithin
    );
  };
  return { detectActiveFilters };
};

export default useDetectActiveFilters;
