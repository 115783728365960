import React, { useCallback, useContext, useState } from 'react';
import MicroModal from 'micromodal';
import SearchFilter from './SearchFilters';
import CommonFilter from './CommonFilter';
import ToggleFilter from './ToggleFilter';
import SortFilter from './SortFilter';
import SortMode from './SortMode';
import useIsMobile from '../../utilities/useIsMobile';
import { DateFilter } from './DateFilter';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import TransformFromPascalCase from '../../../Settings/Utilities/TransformFromPascalCase';
import normalize from '../../utilities/normalizeString';
import ParliamentaryRecordMultipleHierarchy from './ParliamentaryRecordMultipleHierarchy';
import { useLocation } from 'react-router-dom-v5-compat';
import { useEffect } from 'react';
import { switchValuesBetweenTagsFilters } from '../switchValuesBetweenTagsFilters';
import TwoFiltersPopupContainer from './twoFiltersPopupContainer';
import CalendarPickerFilter from './CalendarPickerFilter';
import { filterFiltersOptionDependOnLocation } from '../filterFiltersOptionDependOnLocation';
import { store } from '../../../Store';
import { RelevantOptions } from '../../key_updates/ShowRelevantExtract';

const FiltersPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contactsTags } = state;

  const {
    clearFunction,
    list,
    closePortal,
    filterTypeModifiers,
    filtersState,
    applyFiltersFunction,
    getDefaultSectionFilters,
    compiledSectionFilters,
    section,
  } = props;
  const [componentFilters, setComponentFilters] = useState(JSON.parse(JSON.stringify(filtersState)));

  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      MicroModal.show('filters-popup', {
        awaitCloseAnimation: true,
      });
    }
  }, []);
  const closeMicroModal = () => {
    MicroModal.close('filters-popup');
    setTimeout(() => {
      closePortal();
    }, 300);
  };

  const componentSelectOption = () => {
    return ({ filter, newValue }) => {
      let basefilters = componentFilters;
      let newFilters = filterTypeModifiers({ basefilters, filter, newValue });
      newFilters = switchValuesBetweenTagsFilters({
        newFilters,
        filter,
        componentFilters,
        newValue,
      });
      setComponentFilters(newFilters);
    };
  };

  const unreadFilter = () => {
    let unreadFilter = componentFilters.find((item) => item.name === 'See unread only');
    return unreadFilter && unreadFilter.value;
  };

  const sectionFilters = getDefaultSectionFilters(componentFilters);

  const clearFiltersConditional = () => {
    const { unread, searchWithin, filters } = compiledSectionFilters;
    if (JSON.stringify(filtersState) !== JSON.stringify(componentFilters)) {
      return true;
    } else {
      return filters.length > 0 || unread === true || !!searchWithin;
    }
  };

  const clearFiltersOnPopUp = () => {
    if (JSON.stringify(filtersState) !== JSON.stringify(componentFilters)) {
      setComponentFilters(JSON.parse(JSON.stringify(filtersState)));
    } else {
      clearFunction();
      if (!influenceItem) {
        closeMicroModal();
      }
    }
  };

  const isMobile = useIsMobile();
  const isMobileFilters = useIsMobile(576);
  const location = useLocation();
  const influenceItem = location.pathname.includes('/have-your-voice-heard') && !isMobile;
  const influenceMapping = location.pathname.includes('/stakeholder-mapping');
  const influenceContacts = location.pathname.includes('influence/contacts');

  useEffect(() => {
    //update componentState when switching between pages
    setComponentFilters(JSON.parse(JSON.stringify(filtersState)));
  }, [location.pathname]);

  const tagsFilters = sectionFilters.filter(
    (item) =>
      (item.name === 'Tag is' && contactsTags.length > 0) || (item.type === 'TagIsNot' && contactsTags.length > 0)
  );

  const relevantExtractCondition = location.pathname?.includes('/key-updates');

  return (
    <div
      className='modal micromodal-slide website-feeds-modal filters-popup'
      id={`filters-popup`}
      aria-hidden='true'
      tabIndex='-1'
      ref={micromodalElement}
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div
          className='modal__container pt-4 d-flex flex-column'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
        >
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              closeMicroModal();
            }}
          >
            Close
          </button>

          {!relevantExtractCondition && <h3 className='topic-title section-title pl-lg-5 pl-4 pb-3 mb-0'>Filters</h3>}
          <div className='px-4 px-lg-5 pt-4 pb-3 filters-popup-container flex-grow-1'>
            {relevantExtractCondition && (
              <div className='section-title pb-3 mb-4'>
                <h3 className='topic-title'>Snippet displayed</h3>
                <RelevantOptions />
              </div>
            )}
            {contactsTags?.length === 0 && influenceContacts && (
              <div className={`flex-grow-1 filters-section`}>
                <div className='filters-section-container'>
                  <h3 className='no-contacts-title'>Tags</h3>
                  <p className='mb-0 light-50-text' style={{ paddingLeft: '2px' }}>
                    You have not tagged any stakeholders yet
                  </p>
                </div>
              </div>
            )}
            {tagsFilters.length > 0 && (
              <TwoFiltersPopupContainer
                sectionFilters={tagsFilters}
                componentSelectOption={componentSelectOption}
                unreadFilter={unreadFilter}
                section={section}
              />
            )}
            {sectionFilters
              .filter((item) => item.name !== 'Tag is' && item.type !== 'TagIsNot' && !item.hidden)
              .map((item, index) => {
                let { type, hidden, hiddenDesktop, excludeFromPopup } = item;
                if (influenceMapping && type === 'sortMode') {
                  excludeFromPopup = true;
                }
                const showFilter = isMobile ? !hidden : !hidden && !hiddenDesktop;
                const renderFilter = isMobileFilters ? showFilter : showFilter && !excludeFromPopup;

                const filteredOptionsItem = filterFiltersOptionDependOnLocation({ item, filters: sectionFilters });

                const filterProps = {
                  filter: filteredOptionsItem,
                  selectOption: componentSelectOption(),
                  unreadFilter: unreadFilter(),
                  filters: sectionFilters,
                };
                return (
                  <div
                    key={`filter-${index}`}
                    className={`filters-section filter-section-${type}-${list !== null ? 'topic' : 'search'}-mode ${
                      list !== null && list.id === null ? 'master-topic' : ''
                    } ${normalize(TransformFromPascalCase(section))}-filters-popup`}
                  >
                    {renderFilter && (
                      <div
                        className={`filters-section-container`}
                        style={type === 'addedOn' ? { paddingBottom: 0 } : undefined}
                      >
                        {type === 'toggle' && <ToggleFilter {...filterProps} />}
                        {type === 'sort' && <SortFilter {...filterProps} />}
                        {type === 'date' && <DateFilter {...filterProps} />}
                        {type === 'addedOn' && <CalendarPickerFilter {...filterProps} />}
                        {type === 'sortMode' && <SortMode {...filterProps} list={list} />}
                        {type === 'search' && <SearchFilter {...filterProps} />}
                        {type === 'parliamentaryRecordMultipleHierarchy' && (
                          <ParliamentaryRecordMultipleHierarchy {...filterProps} />
                        )}
                        {(!type || type === 'membership') && <CommonFilter {...filterProps} />}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className='d-flex justify-content-between align-content-center border-top px-4 px-lg-5 py-4'>
            <button
              className={`simple-link-button general-button ${
                influenceContacts || influenceMapping ? 'action-delete-text' : ''
              }`}
              onClick={() => {
                clearFiltersOnPopUp();
              }}
              style={{
                visibility: clearFiltersConditional() ? 'visible' : 'hidden',
              }}
            >
              Clear filters
            </button>

            <button
              className='general-button action-button px-4'
              onClick={() => {
                applyFiltersFunction(componentFilters);
                if (!influenceItem) {
                  closeMicroModal();
                }
              }}
            >
              Apply filters
            </button>
          </div>
        </div>
      </div>
      <ReactTooltip
        effect={'solid'}
        id={'filters-tooltip'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
      />
    </div>
  );
};

export default FiltersPopup;
