import Preloader from '../AnalyticsDashboard/Preloader';
import { useContext, useEffect, useRef, useState } from 'react';
import AnalyticsTitle from '../AnalyticsTitle';
import HoverText from '../../HoverText';
import useIsMobile from '../../../utilities/useIsMobile';
import InterestByRegionChartData from './InterestByRegionChartData';
import InterestByRegionChart from './InterestByRegionChart';
import InterestByRegionRightPanel from './InterestByRegionRightPanel';
import { removeMarkerTooltip } from './interestByRegionExternalTooltip';
import normalize from '../../../utilities/normalizeString';
import useModifyAnalyticsState from '../../useModifyAnalyticsState';
import CountryPicker from '../../MentionsCountryPicker';
import CustomScrollbar from '../../../../Common/CustomScrollbar';
import { store } from '../../../../Store';
import FiltersBar from '../MentionsByTopic/FiltersBar';

const autoScrollFunction = ({ activeSection, chartDataContainer }) => {
  if (activeSection !== null) {
    let elements = document.querySelectorAll('.chart-data-info thead th');
    let element = elements[activeSection];
    if (element) {
      let dataContainer = chartDataContainer.current;
      let { left: tableLeft, right: tableRight } = dataContainer.getBoundingClientRect();
      let { left, right, width } = element.getBoundingClientRect();
      if (tableLeft >= right - width || tableRight <= left + width) {
        let value = element.offsetLeft - 20;
        dataContainer.scrollLeft = value;
      }
    }
  }
};

export const regionsMapping = {
  'North East': 'North East',
  'North West': 'North West',
  'Yorkshire and The Humber': 'Yorkshire and the Humber',
  'East Midlands': 'East Midlands',
  'West Midlands': 'West Midlands',
  Eastern: 'East',
  London: 'London',
  'South East': 'South East',
  'South West': 'South West',
  'Northern Ireland': 'Northern Ireland',
  Scotland: 'Scotland',
  Wales: 'Wales',
};

export const compileInitialInterestByRegionData = (sections, data) => {
  let newData = JSON.parse(JSON.stringify(data));
  let labels = newData.labels;
  let indexes = [];
  labels.forEach((item, index) => {
    if (sections.includes(item)) {
      indexes.push(index);
    }
  });
  let value = indexes.map((index) => newData.value[index]);
  let newLabels = indexes.map((index) => newData.labels[index]);
  let finalValue = { ...newData, labels: newLabels, value };
  return finalValue;
};

const InterestByRegion = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const {
    isLoading,
    data,
    analyticsState,
    isLoadedFirstTime,
    initialData,
    setData,
    allowFrontbenchers,
    setAllowFrontbenchers,
    embedWidget,
  } = props;

  const [activeSection, setActiveSection] = useState(null);
  const activeSections = useRef();
  activeSections.current = ['Parliamentary contribution', 'Question and answer', 'EDM', 'Tweet', 'Retweet'];
  const [disableSelectSection, setDisableSelectSection] = useState(false);
  const [rightPanelState, setRightPanelState] = useState({ open: false });
  const [map, setMap] = useState(null);
  const [geoData, setGeoData] = useState([]);

  const { modifyState } = useModifyAnalyticsState();
  const { selectedCountriesInterestByRegion } = analyticsState;

  const isMobile = useIsMobile(768);
  const chartContainerRef = useRef(null);
  const chartDataContainer = useRef(null);
  const scrollBarContainer = useRef(null);
  const chartDataSection = useRef(null);
  const emptyMessage = !isLoading ? data?.value?.filter((item) => item > 0).length === 0 : false;

  const scrollFromChart = (region) => {
    const targetRowArr = Array.from(chartDataSection.current.querySelectorAll(`#${normalize(region)}-row`));
    if (!!targetRowArr.length) {
      scrollBarContainer.current.scrollTop(targetRowArr[0].offsetTop + 150);
    }
  };

  const compileInterestByRegionData = (sections) => {
    let finalValue = compileInitialInterestByRegionData(sections, initialData);
    setData(finalValue);
  };

  const autoScrollTable = useRef();
  autoScrollTable.current = () => {
    autoScrollFunction({ activeSection, chartDataContainer });
  };

  //reset typeOfChartInterestByRegion on dashboard
  useEffect(() => {
    return () => {
      modifyState({
        property: 'typeOfChartInterestByRegion',
        newValue: 'Heat map',
      });
    };
  }, []);

  useEffect(() => {
    compileInterestByRegionData(activeSections.current);
  }, [initialData]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'chartState',
      value: { isLoading, chartRef: map, chartSection: chartContainerRef, data, emptyMessage },
    });
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'chartState',
        value: {},
      });
    };
  }, [isLoading, data, map]);

  const highlightGraphOnHover = ({ item, onClick = false }) => {
    if (!disableSelectSection && !isMobile && !!geoData.length && map) {
      let chart = map;
      const markerName = Object.keys(regionsMapping).find((name) => regionsMapping[name] === item);
      const markerFeature = geoData.find((feature) => feature.properties.eer18nm === markerName);
      const markerLatLong = {
        lat: markerFeature.properties.lat,
        long: markerFeature.properties.long,
      };
      const chartMarkerIndex = Object.keys(chart._targets).find((item) => {
        if (chart._targets[item]._latlng) {
          return (
            chart._targets[item]._latlng.lat === markerLatLong.lat &&
            chart._targets[item]._latlng.lng === markerLatLong.long
          );
        } else {
          return false;
        }
      });

      Object.values(chart._targets).forEach((item) => {
        item.fireEvent('mouseout');
      });
      const chartMarker = chart._targets[chartMarkerIndex];
      const chartRegion = Object.values(chart._targets).find(
        (region) => region?.feature?.properties?.eer18nm === markerName
      );
      if (chartRegion) {
        setTimeout(() => {
          chartRegion.setStyle({
            weight: 1,
            fillOpacity: 1,
          });
        }, 10);
        chartRegion.fireEvent('mouseover');
      }
      if (chartMarker) {
        setTimeout(() => {
          chartRegion.setStyle({
            weight: 1,
            fillOpacity: 0.35,
          });
        }, 10);
        chartMarker.fireEvent('mouseover');
      }
      setActiveSection(item);
    }
    if (onClick) {
      setTimeout(() => {
        setDisableSelectSection(true);
      }, 0);
      setTimeout(() => {
        setDisableSelectSection(false);
      }, 1000);
    }
  };

  const clearHoverOnMarker = () => {
    let chart = map;
    if (chart) {
      Object.values(chart?._targets)?.forEach((item) => {
        item.fireEvent('mouseout');
      });
    }
  };

  const changeOrderTableLabel = (label, e) => {
    const element = e.target;
    const order = e.target.dataset.order;
    let rawData = JSON.parse(JSON.stringify(data.rawData));
    let newRawData = {};
    const sumValues = (obj) => {
      return Object.values(obj).reduce((n, m) => n + m, 0);
    };
    const rawDataArray = Object.values(
      Object.entries(rawData).sort(([, a], [, b]) =>
        order === 'asc'
          ? (label === 'total' ? sumValues(a) : a[label] ?? 0) - (label === 'total' ? sumValues(b) : b[label] ?? 0)
          : (label === 'total' ? sumValues(b) : b[label] ?? 0) - (label === 'total' ? sumValues(a) : a[label] ?? 0)
      )
    );

    rawDataArray.forEach((item) => {
      newRawData[item[0]] = item[1];
    });
    setData({ ...data, rawData: newRawData });
    element.dataset.order = order === 'asc' ? 'desc' : 'asc';
    setActiveSection(null);
  };

  let setSelectedCountries = (val) => {
    modifyState({ property: 'selectedCountriesInterestByRegion', newValue: val });
  };

  return (
    <div className='interest-by-region-inner-container d-flex w-100 h-100'>
      <div className={`h-100 flex-grow-1 rounded-top ${embedWidget ? '' : 'bg-white border'}`}>
        <CustomScrollbar
          className={`main-content-scrollbar top-content-scrollbar smooth-scrolling ${
            !isLoading ? 'map-chart-background' : ''
          }`}
          maximalThumbYSize={100}
          ref={scrollBarContainer}
          onScroll={() => {
            removeMarkerTooltip();
          }}
        >
          <div className='pb-4'>
            <div className='px-lg-5 px-3 position-relative region-inner-container'>
              {isLoading && !isLoadedFirstTime.current && <Preloader />}
              {isLoadedFirstTime.current && (
                <>
                  {isLoading && <div className='loading-background' />}
                  <div className='d-flex align-items-md-center justify-content-between interest-by-region-chart-title-container flex-column flex-md-row'>
                    <div className='d-md-flex align-items-center interest-by-region-chart-title'>
                      <AnalyticsTitle title={'Interest by region'} />
                      <div className='ml-md-3 mt-3 mt-md-0'>
                        <CountryPicker
                          selectedCountries={selectedCountriesInterestByRegion}
                          setSelectedCountries={setSelectedCountries}
                        />
                      </div>
                    </div>
                    <div className='d-none d-lg-flex align-items-center' style={{ zIndex: 999 }}>
                      <FiltersBar typeOfChartSections={true} chartName={'Interest by region'} />
                      {!embedWidget && <HoverText />}
                    </div>
                  </div>
                  <div ref={chartContainerRef}>
                    {!emptyMessage ? (
                      <InterestByRegionChart
                        height={420}
                        chartData={data.rawData}
                        setActiveSection={setActiveSection}
                        activeSection={activeSection}
                        chartDataKeys={Object.keys(data.rawData)}
                        map={map}
                        setMap={setMap}
                        data={geoData}
                        setData={setGeoData}
                        regionsMapping={regionsMapping}
                        scrollFromChart={scrollFromChart}
                        analyticsState={analyticsState}
                        rightPanelState={rightPanelState}
                        allowFrontbenchers={allowFrontbenchers}
                        setAllowFrontbenchers={() => {
                          setAllowFrontbenchers(!allowFrontbenchers);
                        }}
                        setRightPanelState={setRightPanelState}
                        highlightGraphOnHover={highlightGraphOnHover}
                        selectedCountries={selectedCountriesInterestByRegion}
                      />
                    ) : (
                      <p className='title-h4 px-4 px-lg-0 text-center main-light-text' style={{ marginTop: '128px' }}>
                        No data available
                      </p>
                    )}
                  </div>
                  {!emptyMessage && !embedWidget && (
                    <div ref={chartDataSection} className='interest-chart-data-container'>
                      <InterestByRegionChartData
                        activeSection={activeSection}
                        setActiveSection={setActiveSection}
                        data={data}
                        analyticsState={analyticsState}
                        activeSections={activeSections.current}
                        disableSelectSection={disableSelectSection}
                        chartDataKeys={Object.keys(data.rawData)}
                        chartDataContainer={chartDataContainer}
                        highlightGraphOnHover={highlightGraphOnHover}
                        setRightPanelState={setRightPanelState}
                        clearHoverOnMarker={clearHoverOnMarker}
                        changeOrderTableLabel={changeOrderTableLabel}
                        rightPanelState={rightPanelState}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </CustomScrollbar>
      </div>
      {rightPanelState.open && (
        <InterestByRegionRightPanel
          contentState={rightPanelState}
          setRightPanelState={setRightPanelState}
          analyticsState={analyticsState}
          allowFrontbenchers={allowFrontbenchers}
        />
      )}
    </div>
  );
};

export default InterestByRegion;
