import React, { useEffect, useRef } from 'react';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';

const Headline = (props) => {
  const { sectionValues, type } = props;
  const { fields } = sectionValues;
  const { value, setValue } = fields[0];

  const inputHeadline = useRef(null);
  const { newAgencyPlan } = useTopicOrClientWord();

  useEffect(() => {
    inputHeadline.current.focus();
  }, []);

  return (
    <div className='influence-creation_section'>
      <h3 className='title-h5-m-bold'>
        Give your {type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ')} a headline
      </h3>
      <p className='paragraph-p2 main-light-text'>
        Your headline should summarise your {newAgencyPlan ? `client's ` : ''}
        {type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ')} in one sentence
      </p>

      <input
        data-hj-allow
        ref={inputHeadline}
        className={'main-input form-control'}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </div>
  );
};

export default Headline;
