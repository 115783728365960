import React from 'react';
import Popup from '../../../Common/PopUp';
import { ReferenceSectionItems } from './ResearchPopUpItems';

const ContentPopup = (props) => {
  return <ReferenceSectionItems {...props} />;
};

const ResearchPopUp = (props) => {
  return (
    <Popup {...props}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default ResearchPopUp;
