import React from 'react';
import { useContext } from 'react';
import { store } from '../../../../Store';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';

function NoContactsEmptyMessage() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <>
      <p className='crm-table-no-contacts-title pt-2'>
        Your contact list{newAgencyPlan ? ` for ${team?.teamName}` : ''} is empty
      </p>
      <p className='crm-table-no-contacts-description'>
        Once you or your teammates add contacts, you’ll be able to add them to email campaigns and take other actions
      </p>
      <div className='no-contacts-actions-container w-100'>
        <div className='w-100 h-100'>
          <img
            className='w-100 rounded-top pt-4'
            src={`${process.env.REACT_APP_CDNURL}/images/no-contacts-img.jpg`}
            alt='No contacts'
          />
        </div>
      </div>
    </>
  );
}

export default NoContactsEmptyMessage;
