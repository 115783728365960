import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom-v5-compat';

const SkeletonHolderListSkeleton = (props) => {
  const location = useLocation();
  const stakeholderMapping = location.pathname.includes('mapping');
  const { includeTitle } = props;
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      {includeTitle && (
        <div className='pt-3 pt-lg-5'>
          <div className='mb-2 section-title pb-2 d-flex justify-content-between align-items-center'>
            <div className='col-10 px-0'>
              <Skeleton width={'80%'} height={19} />
            </div>
            <Skeleton width={200} height={19} />
          </div>
        </div>
      )}
      <div className={`skeleton-adjustment`} style={{ marginTop: '6px' }}>
        <ul className='policy-list px-0 items-list'>
          {Array(9)
            .fill()
            .map((item, index) => (
              <StakeholderSkeletonItem key={index} stakeholderMapping={stakeholderMapping} index={index} />
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

const StakeholderSkeletonItem = ({ stakeholderMapping, index }) => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <li className={`${index !== 0 ? 'my-2' : 'my-lg-2'} ${stakeholderMapping ? 'py-2' : ''}`}>
        <div className='row mx-0 p-3 align-items-center justify-content-between' style={{ position: 'relative' }}>
          {stakeholderMapping && (
            <Skeleton height={17} width={17} style={{ position: 'absolute', top: '15px', left: '15px' }} />
          )}
          <Skeleton circle={true} height={65} width={65} />
          <div className='content-title pl-md-4 stakeholder-title'>
            <div className='section-title pb-2 row justify-content-between mx-0'>
              <div className='col-13 px-0'>
                <Skeleton height={19} width={'40%'} />
              </div>
              {stakeholderMapping && (
                <div className='col-13 px-0 pt-2'>
                  <Skeleton width={'65%'} height={19} />
                </div>
              )}
            </div>
            <div className={`my-1`}>
              <Skeleton width={'60%'} height />
            </div>
            {!stakeholderMapping && (
              <div className='col-13 px-0 pt-2'>
                <Skeleton width={'60%'} height={19} />
              </div>
            )}
          </div>
        </div>
      </li>
    </SkeletonTheme>
  );
};
const StakeholderSkeletonSelectBar = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div
        className='row mx-0 align-items-center justify-content-between'
        style={{
          position: 'relative',
          width: '100%',
          marginTop: '23px',
          marginBottom: '-2px',
        }}
      >
        <div className='row mx-0 align-items-center' style={{ flexGrow: '1' }}>
          <Skeleton height={15} width={160} />
        </div>
        <div style={{ width: '250px' }}>
          <Skeleton height={15} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export { StakeholderSkeletonItem, StakeholderSkeletonSelectBar };
export default SkeletonHolderListSkeleton;
