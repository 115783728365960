import React from 'react';
import useTopicOrClientWord from '../../../Dashboard/hooks/useTopicOrClientWord';
import useIsMobile from '../../../Dashboard/utilities/useIsMobile';
const CreateANewEmailAlertButton = (props) => {
  const { allowAlerts, setIsOpenEditEmailAlert, additionalFunction, buttonText } = props;
  const { topicOrClientWord } = useTopicOrClientWord();

  const ButtonText = () => {
    const isMobile = useIsMobile();
    return <span>{isMobile ? '+ Add an alert' : buttonText ?? `+ Add a ${topicOrClientWord} alert`}</span>;
  };
  return (
    <>
      {allowAlerts && (
        <button
          className={`general-button simple-link-button pl-3 py-0`}
          onClick={() => {
            setIsOpenEditEmailAlert(true);
            if (additionalFunction) {
              additionalFunction();
            }
          }}
        >
          <ButtonText />
        </button>
      )}

      {!allowAlerts && (
        <button
          className='general-button mb-0 action-light-button d-block'
          data-tooltip-content={'Email alerts are available on Pro and Mogul'}
          data-tooltip-id='settings-tooltip'
        >
          <ButtonText />
          <img
            src={`${process.env.REACT_APP_CDNURL}/images/padlock.svg`}
            alt='padlock'
            className='padlock-email-alert'
          />
        </button>
      )}
    </>
  );
};

export default CreateANewEmailAlertButton;
