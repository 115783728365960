import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonAddContent = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='text-center flex-grow pb-5'>
        <div className='py-4 pb-5' style={{ width: '600px' }}>
          <Skeleton height={38} width={`70%`} />
          <div className='mt-2'>
            <Skeleton height={19} width={`60%`} />
          </div>
          <div className='mx-auto mt-5 create-material-options-container'>
            <div className='border rounded'>
              <div className={`px-5 rounded-lg bg-white d-flex align-items-center `} style={{ height: '90px' }}>
                <div className='d-flex justify-content-lg-center create-select-options'>
                  {Array(4)
                    .fill()
                    .map((item, index) => (
                      <Skeleton height={30} width={`130px`} key={index} />
                    ))}
                </div>
              </div>
            </div>

            <div className={`d-flex pt-4 w-100 justify-content-end`}>
              <Skeleton height={32} width={'140px'} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonAddContent;
