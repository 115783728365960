import React, { useEffect, useState, useRef } from 'react';
import Popup from '../../Common/PopUp';
import createMarkup from '../../Dashboard/helpers/createMarkup';
import idsWithoutImages from '../../Dashboard/key_updates/idsWithoutImages';
import normalize from '../../Dashboard/utilities/normalizeString';
import useWindowHeight from '../../Dashboard/utilities/useWindowHeight';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import { ContentPopupCreateFolder } from '../ui/CreateFolderPopup';
const ContentPopup = (props) => {
  //we can access this property from the parent component to use if it's needed. e.g: a cancel button
  //const {closeMicroModal} = props;
  const {
    item,
    closeMicroModal,
    userSavedItemsFolders,
    saveItemState,
    setSaveItemState,
    createEditAFolder,
    addItemToMultipleFolders,
    StakeholderItem,
    itemOnFolders,
    newFolderCreated,
    setNewFolderCreated,
    updateFolders,
  } = props;
  const [selectedFolders, setSelectedFolders] = useState(itemOnFolders);

  const backFunctionality = () => {
    setSaveItemState('saveItem');
  };

  const addSaveItemId = (id) => {
    let newSelectedFolders = [...selectedFolders];
    if (newSelectedFolders.includes(id)) {
      let position = newSelectedFolders.indexOf(id);
      newSelectedFolders.splice(position, 1);
    } else {
      newSelectedFolders.push(id);
    }
    setSelectedFolders(newSelectedFolders);
  };

  const { windowHeight } = useWindowHeight();

  const objectType = () => {
    if (item.type === 'UserContent') {
      return item.contentType;
    } else if (item.type) {
      return item.type;
    } else {
      return item.objectType;
    }
  };

  const updateFoldersSelected = useRef();
  updateFoldersSelected.current = () => {
    if (newFolderCreated !== null) {
      setSelectedFolders([...selectedFolders, newFolderCreated]);
      setNewFolderCreated(null);
    }
  };

  useEffect(() => {
    updateFoldersSelected.current();
  }, [newFolderCreated]);

  return (
    <>
      {saveItemState === 'saveItem' && (
        <>
          <div className='pt-4 section-title px-lg-5 px-4 pb-3 popup-individual-item-container'>
            <h3 className={`topic-title mb-0 font-weight-normal`}>
              {StakeholderItem && (
                <span
                  className={`saved-item-stakeholder stakeholder-profile-image stakeholder-profile-related member-picture member-${item.party !== null ? item.party.toLowerCase() : ''}-picture`}
                  style={{
                    backgroundImage:
                      item.imageId !== 0 && item.imageId !== null && !idsWithoutImages.includes(item.imageId)
                        ? `url('https://www.parliamenttoday.com/image.php?imageid=${item.imageId}&ml=1')`
                        : `url('${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png')`,
                  }}
                />
              )}
              <span dangerouslySetInnerHTML={createMarkup(item.title)} />
            </h3>
            {!StakeholderItem && (
              <span
                className={`mt-3 beta-feature paragraph-p4 main-light-text bg-white individual-item individual-item-${objectType().toLowerCase()} popup-individual-item`}
              >
                {TransformFromPascalCase(objectType())}
              </span>
            )}
          </div>

          <div className='px-4 px-lg-5 pt-3 pt-lg-4'>
            <label className='mb-1'>{`Select the folders this ${StakeholderItem ? 'stakeholder' : 'item'} should appear in`}</label>
          </div>
          <div className='px-4 px-lg-5 pb-3 pl-3 scroll-container' style={{ maxHeight: `${windowHeight - 326}px` }}>
            {userSavedItemsFolders.map((item) => {
              const { id, name } = item;
              return (
                <div className='checkbox-item' key={`item-${id}-${normalize(name)}`}>
                  <input
                    id={`${normalize(name)}-${id}`}
                    type='checkbox'
                    checked={selectedFolders.includes(id)}
                    onChange={() => {
                      addSaveItemId(id);
                    }}
                  />
                  <label className='create-email-alert-label saved-to-label' htmlFor={`${normalize(name)}-${id}`}>
                    {name}
                  </label>
                </div>
              );
            })}

            <button
              className='general-button simple-link-button add-folder-button-active'
              onClick={() => {
                setSaveItemState('createPopup');
              }}
            >
              Create a new folder
            </button>
          </div>
          <div className='d-flex justify-content-end py-3 border-top bg-grey px-4 px-lg-5 mt-3'>
            <button
              className='general-button simple-link-button mr-3 px-3'
              onClick={() => {
                closeMicroModal();
              }}
            >
              Cancel
            </button>
            <button
              className='general-button action-button px-3'
              onClick={async (e) => {
                await addItemToMultipleFolders({ e, selectedFolders, item });
                closeMicroModal();
                if (updateFolders) {
                  updateFolders();
                }
              }}
              disabled={selectedFolders.length === 0 && itemOnFolders.length === 0}
            >
              {'Save'}
            </button>
          </div>
        </>
      )}
      {saveItemState === 'createPopup' && (
        <ContentPopupCreateFolder
          closeMicroModal={closeMicroModal}
          createEditAFolder={createEditAFolder}
          backFunctionality={backFunctionality}
          editParameter={null}
        />
      )}
    </>
  );
};

const SavePopup = (props) => {
  return (
    <Popup {...props}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default SavePopup;
