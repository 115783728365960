import React, { useEffect, useState, useContext } from 'react';
import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import { store } from '../Store.js';
import getCurrentTeam from '../Settings/Team/getCurrentTeam';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import removeLocalStorage from '../Dashboard/Navigation/RemoveLocalStorage';
import getActivePlan from '../Settings/Utilities/getActivePlan';
import { consultantAgenciesMembers } from '../Dashboard/sidebar/parliamentarianUsers.js';
import useGetAccessToken from '../Dashboard/apiCalls/useGetAccessToken.js';

const EndOfTrial = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [isLoading, setIsLoading] = useState(true);
  const [heightContainer, containerRef] = useHeightContainer();
  const [team, setTeam] = useState({});
  const [activePlan, setActivePlan] = useState('');
  const { myself } = team;
  const location = useLocation();
  const { getAccessToken } = useGetAccessToken();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInformation(source);
    return () => {
      source.cancel('End of trial cancelled by the user');
    };
  }, []);
  const redirectToHomeCondition = (plan) => {
    return (
      plan &&
      ((location.pathname.includes('end-of-trial') && plan.subscriptionStatus !== 'TrialEnded') ||
        (location.pathname.includes('end-of-subscription') && plan.subscriptionStatus !== 'SubscriptionEnded'))
    );
  };

  const loadInformation = async (source) => {
    try {
      let team = await getCurrentTeam(getAccessToken, source);
      let activePlan = await getActivePlan(getAccessToken, source);
      if (!!activePlan) {
        let title = `End of ${trialWord} - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        if (redirectToHomeCondition(activePlan)) {
          return window.location.assign('/');
        }
        setActivePlan(activePlan);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
        setTeam(team);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const EnterpriseCodeCondition = () => {
    return activePlan.code.includes('Enterprise');
  };
  const trialWord = location.pathname.includes('/end-of-subscription') ? 'subscription' : 'trial';
  const planWord = consultantAgenciesMembers(activePlan?.code) ? 'agency' : 'Enterprise';

  return (
    <>
      <TrialHeader />
      <div
        className='p-lg-5 p-3 end-of-trial-container d-flex flex-column justify-content-center'
        style={{ height: `${heightContainer}px` }}
        ref={containerRef}
      >
        {!isLoading && (
          <div className='container'>
            <div className='row mx-0 main-content-wrapper'>
              <div className='col-lg-10 px-0 trial-end-text'>
                <p className='owner-trial-text'>
                  {EnterpriseCodeCondition()
                    ? 'Please contact us to continue with PolicyMogul'
                    : 'Please choose a subscription option to continue'}
                </p>
                {myself.role === 'Owner' && (
                  <>
                    <p className='font-weight-light team-trial-text'>
                      {EnterpriseCodeCondition()
                        ? `Your ${trialWord} of the ${planWord} plan has come to an end. Please contact your account manager or our ${planWord} support team if you'd like to regain access.`
                        : `Your ${trialWord} of the ${activePlan.name} plan has come to an end. Click the button below to choose the plan that suits you best.`}
                    </p>
                    {EnterpriseCodeCondition() ? (
                      <button className='px-4 py-2 general-button choose-plan-button mt-4 contact-button-launch-messenger'>
                        Contact support
                      </button>
                    ) : (
                      <Link to='/pricing'>
                        <button className='px-4 py-2 general-button choose-plan-button mt-4'>
                          Choose your plan now
                        </button>
                      </Link>
                    )}
                  </>
                )}
                {myself.role !== 'Owner' && (
                  <>
                    {EnterpriseCodeCondition() ? (
                      <p className='font-weight-light team-trial-text'>
                        Your {trialWord} of the Enterprise plan has come to an end. Please contact your account manager
                        or our Enterprise support team if you'd like to regain access.
                      </p>
                    ) : (
                      <p className='font-weight-light team-trial-text'>
                        Your {trialWord} of the {activePlan.name} plan has come to an end. Ask your{' '}
                        <strong>account owner</strong> choose the plan that suits you best.
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className='col-lg-6 show-lg'>
                <div className='trial-image-container ml-auto mr-0'>
                  <img
                    className='d-block mx-auto'
                    src={`${process.env.REACT_APP_CDNURL}/images/calendar.gif`}
                    alt='calendar gif'
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const TrialHeader = () => {
  const { logout } = useAuth0();
  const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });
  return (
    <div className='py-3 px-4 main-navigation-logo settings-header position-relative'>
      <div className='mx-0 justify-content-between align-items-center row pr-lg-5 pr-2'>
        <img src={`${process.env.REACT_APP_CDNURL}/images/logo.svg`} alt='Logo' />
        <button
          className='general-button logout-button px-4 py-2'
          onClick={() => {
            removeLocalStorage();
            logoutWithRedirect();
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export { TrialHeader, EndOfTrial };
