import React from 'react';

const RelevanceBars = (props) => {
  const { className } = props;
  return (
    <span className={`relevance-bars ${className ? className : ''}`}>
      <span className='relevance-bars-1' />
      <span className='relevance-bars-2' />
      <span className='relevance-bars-3' />
    </span>
  );
};

export default RelevanceBars;
