import React, { useState } from 'react';

const AttachmentLink = (props) => {
  const [fetching, setFetching] = useState(false);
  const { url, filename, includeDeleteButton, deleteFunction } = props;
  const name = url.split('/');
  const nameToShow = filename ?? decodeURIComponent(name[name.length - 1]);

  return (
    <li
      className='attachment-box'
      onClick={(e) => {
        setFetching(true);
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            setFetching(false);
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.style = 'display: none';

            if (nameToShow && nameToShow.length) a.download = nameToShow;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch(() => {});
      }}
    >
      <button
        disabled={fetching}
        className={`general-button `}
        data-tooltip-id={'general-tooltip'}
        data-tooltip-content={'Download'}
      >
        {fetching ? (
          <i className='fas fa-spinner fa-spin ml-3'></i>
        ) : (
          <span className='icon-arrow-download ml-3 main-subtle-text'></span>
        )}
      </button>
      <span className='attachment-file-name flex-grow-1'>{nameToShow}</span>
      {includeDeleteButton && (
        <button
          className='general-button topic-alert-delete mr-3'
          data-tooltip-id={'general-tooltip'}
          data-tooltip-content={'Delete'}
          onClick={(e) => {
            deleteFunction(e);
          }}
        ></button>
      )}
    </li>
  );
};

export default AttachmentLink;
