import React, { useEffect, useContext, useState } from 'react';
import CreateProfileFirstStep from './CreateProfileFirstStep';
import { store } from '../../Store.js';
import CreateProfileAwaits from './CreateProfileAwaits';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import axios from 'axios';
import getActivePlan from '../../Settings/Utilities/getActivePlan';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import SecondStepParlamentarian from './SecondStepParliamentarian';
import SignUpCreateATopic from '../SignUpCreateATopic';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import getCurrentTeam from '../../Settings/Team/getCurrentTeam.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';
let changeStep = false;

const CreateProfile = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { net_api_url, activePlan } = state;

  const { userOnboarded } = activePlan ?? {};
  const params = useParams();
  const { step } = params;

  const location = useLocation();
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadProfile(source);
    return () => {
      source.cancel('Create your profile canceled by the user.');
    };
  }, []);
  useEffect(() => {
    if (parseInt(step) !== 3 && userOnboarded && user.email_verified) {
      return navigate('/monitor-inbox/key-updates', { replace: true });
    } else if (parseInt(step) !== 3 && userOnboarded && !user.email_verified) {
      return navigate('/needs-verification', { replace: true });
    }

    if (changeStep) {
      logAnalitys();
    }
  }, [step, activePlan]);

  const loadProfile = async (source) => {
    try {
      var token = await getAccessToken();
      let activePlanState = Object.keys(activePlan);
      if (activePlanState.length === 0) {
        let activePlan = await getActivePlan(getAccessToken, source);
        activePlanState = activePlan;
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
      }

      let url = `${net_api_url}/api/user/getcurrent`;
      let response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      let result = response.data;
      dispatch({ type: 'MODIFY_SECTION', parameter: 'userProfile', value: result });
      logAnalitys();
      changeStep = true;
      const parliamentarianUserTypes = [
        'Parliamentarian',
        'Parliamentary or political staff',
        'parliamentarian or staff',
      ];

      if (!activePlanState.userOnboarded) {
        if (!!result.userType) {
          let currentTeam = await getCurrentTeam(getAccessToken, source);
          if (!!currentTeam) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
          }
          if (parliamentarianUserTypes.indexOf(result.userType) >= 0) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'parliamentarianOnboarding', value: true });
            if (result.userType === 'Parliamentarian' && step !== '2-parliamentarian') {
              navigate('/create-your-profile/2');
            } else if (result.userType === 'Parliamentary or political staff') {
              navigate(`/create-your-profile/2${!result.organisation ? '-parliamentarian' : ''}`);
            }
          } else {
            navigate('/create-your-profile/2');
          }
        } else {
          if (parseInt(step) !== 1) {
            return window.location.assign('/create-your-profile/1');
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const logAnalitys = () => {
    let title = `Create your profile - PolicyMogul`;
    document.title = title;
    let locationToStorage = {
      title: title,
      storageLocation: { ...location },
    };
    dispatch({ type: 'MODIFY_SECTION', parameter: 'locationToStorage', value: locationToStorage });
  };

  return (
    <>
      {isLoading && <Loading />}

      {!isLoading && (
        <Routes>
          <Route path='2' element={<SignUpCreateATopic />} />
          <Route path=':step/*' element={<FirstAndSecondStep />} />
        </Routes>
      )}
    </>
  );
};

const FirstAndSecondStep = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { parliamentarianOnboarding } = state;
  const params = useParams();
  const { step } = params;

  return (
    <div className='create-profile-container px-0'>
      <div className='scroll-container h-100 create-profile-general-content'>
        <h2 className='company-name'>Policy Mogul</h2>
        <div className='d-flex justify-content-center align-items-center w-100 pt-3 py-lg-5'>
          <div className='mt-5 mt-lg-0'>
            <div
              className={`my-lg-3 create-profile-steps text-center ${step < 3 ? 'mb-3' : ''}`}
              style={{ visibility: step < 3 ? 'visible' : 'hidden' }}
            >
              {!parliamentarianOnboarding && (
                <>
                  <p className='mb-0'>Step {step} of 2</p>
                  <div className={`${step < 3 ? 'mt-2' : ''} create-profile-step create-profile-step-${step}`} />
                </>
              )}
            </div>
            <div className=' p-5 create-profile-content'>
              {step === '1' && <CreateProfileFirstStep />}
              {step === '2-parliamentarian' && <SecondStepParlamentarian />}
              {step === '3' && <CreateProfileAwaits />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(CreateProfile, {
  onRedirecting: () => <Loading />,
});
