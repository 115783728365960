import { store as NotificationStore } from 'react-notifications-component';
import 'animate.css';
const createNotification = (type, message, side) => {
  NotificationStore.addNotification({
    title: '',
    message: message,
    type: type,
    insert: 'bottom',
    container: side ?? 'bottom-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
  });
};

export default createNotification;
