import { useContext } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { store } from '../../Store';
import { formatDate } from '../helpers/formatDate';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';

const useStepsStructure = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, userProfile } = state;
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const params = useParams();

  const baseStepsStructure = [
    {
      name: 'Headline',
      fields: [
        {
          name: 'title',
          value: '',
          required: true,
        },
      ],
      complete: false,
      active: !agencyUser,
    },
    {
      name: 'Description',
      fields: [
        {
          name: 'body',
          value: '',
          title: 'Description',
          subtext: `Explain the key points of your ${newAgencyPlan ? `client's ` : ''}${params.page === 'policy-asks' ? 'policy ask' : params.page?.replace('-', ' ')}. The full publication can be added below as an attachment.`,
          required: true,
        },
        {
          name: 'attachments',
          value: {},
          title: 'Attach relevant documents',
          subtext: '',
          required: false,
        },
      ],
      complete: false,
      active: false,
    },
    {
      name: 'Details',
      fields: [
        {
          name: 'visibility',
          value: 'Anyone',
          required: true,
        },
        {
          name: 'expirationDate',
          value: {
            name: '6 months',
            value: formatDate(6, 'month'),
          },
          required: false,
        },
        {
          name: 'region',
          value: 'National',
          required: false,
        },
        {
          name: 'websiteLink',
          value: null,
          required: false,
        },
        {
          name: 'keywords',
          value: [],
          required: true,
        },
      ],
      complete: false,
      active: false,
    },
    {
      name: 'Associated content',
      fields: [
        {
          name: 'relatedContent',
          value: [],
          required: false,
        },
      ],
      complete: false,
      active: false,
    },
    {
      name: 'Preview',
      isAPreviewItem: true,
      complete: false,
      active: false,
    },
  ];
  const createStepsStructure = ({
    agency = agencyUser,
    topics = keywordsLists,
    organisationWebsite = userProfile?.organisationWebsite,
    version = null,
    summary,
    descriptionValue,
  }) => {
    const firstTopic = topics?.filter((item) => item.id !== null)?.[0];
    const properties = agency
      ? [
          {
            name: 'Client',
            fields: [
              {
                name: 'clientKeywordListId',
                value: firstTopic?.id ?? '',
                required: true,
              },
              {
                name: 'websiteLink',
                value: organisationWebsite ?? '',
                required: true,
              },
            ],
            complete: !!firstTopic,
            active: true,
          },
          ...baseStepsStructure,
        ]
      : baseStepsStructure;
    //AE:MODIFY VERSIONS OF CONTENT
    let description = properties.findIndex((item) => item.name === 'Description');
    if (summary) {
      properties[description].fields = [
        {
          name: 'summary',
          value: summary,
          title: 'Summary',
          subtext: 'Summarise your lobbying material',
          required: false,
        },
        ...properties[description].fields,
      ];
    }
    if (descriptionValue) {
      let descriptionFieldPosition = properties[description].fields?.findIndex((item) => item?.name === 'body');
      properties[description].fields[descriptionFieldPosition].value = descriptionValue;
    }
    if (version === 1) {
      properties[description].fields = [
        {
          name: 'aims',
          value: '',
          type: 'policy-asks',
          title: 'What are the aims of your policy ask?',
          subtext: 'Policy asks should be achievable. World peace would be nice, but...',
          required: true,
        },
        {
          name: 'whoWillBeImpacted',
          value: '',
          type: 'policy-asks',
          title: 'Who would benefit from this proposed change of policy?',
          subtext:
            'Is there a particular group who would benefit (e.g. farmers, children, young parents, carers, etc)?',
          required: false,
        },
        {
          name: 'whatKindOfPoliticalSupport',
          value: '',
          type: 'policy-asks',
          title: 'What kind of political support do you want for this policy ask?',
          required: false,
        },
        {
          name: 'summary',
          value: '',
          type: 'briefing-material',
          title: 'Summary',
          subtext: `Explain the key points of your ${newAgencyPlan ? `client's ` : ''}briefing material. The full publication can be added below as an attachment.`,
          required: true,
        },
        {
          name: 'summary',
          value: '',
          type: 'research',
          title: 'Summary',
          subtext: `Explain the key points of your ${newAgencyPlan ? `client's ` : ''}research. The full publication can be added below as an attachment.`,
          required: true,
        },
        {
          name: 'summary',
          value: '',
          type: 'news-release',
          title: 'Summary',
          subtext: `Explain the key points of your ${newAgencyPlan ? `client's ` : ''}news release. The full publication can be added below as an attachment.`,
          required: true,
        },
        {
          name: 'attachments',
          value: {},
          title: 'Attach relevant documents',
          subtext: '',
          required: false,
        },
      ];
    }

    //AE: REMOVE WEBSITELINK FROM VISIBILITY
    let visibility = properties.findIndex((item) => item.name === 'Details');
    let websitePosition = properties[visibility]?.fields?.findIndex((item) => item.name === 'websiteLink');

    if (websitePosition >= 0) {
      if (agency) {
        properties[visibility]?.fields?.splice(websitePosition, 1);
      } else {
        properties[visibility].fields[websitePosition].value = organisationWebsite ?? '';
      }
    }
    return properties;
  };

  const StepsStructure = createStepsStructure({});
  return { StepsStructure, createStepsStructure };
};

export { useStepsStructure };
