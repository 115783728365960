import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { useSetListsIds } from '../MainSearchBar/setListIds';

const useSearchBarUiFunctions = (props) => {
  const { inputSearch, keywordListsElement, scrollContainerElement, showDropdownContent } = props;
  const { isAuthenticated } = useAuth0();

  const [startMoveLists, setStartMoveLists] = useState(false);
  const [listPosition, setListPosition] = useState(0);

  useEffect(() => {
    if (showDropdownContent) {
      document.addEventListener('keydown', scrollList);
    }
    return () => {
      document.removeEventListener('keydown', scrollList);
    };
  }, [startMoveLists, listPosition, showDropdownContent]);

  const scrollList = (e) => {
    if (isAuthenticated) {
      let listItems = keywordListsElement.current.children;
      switch (e.keyCode) {
        case 13: // enter
          if (startMoveLists) {
            listItems[listPosition].querySelectorAll('.dropdown-keywordlist')[0].click();
          }
          break;
        case 38: // Up arrow
          e.preventDefault();
          let newListPosition = listPosition > 0 ? listPosition - 1 : listItems.length - 1;
          let element = listItems[newListPosition];
          if (startMoveLists) {
            if (!checkInView(scrollContainerElement.current, element)) {
              element.scrollIntoView(false);
            }
            setListPosition(newListPosition);
          }
          break;
        case 40: // Down arrow
          e.preventDefault();
          if (inputSearch && inputSearch.current !== null) {
            inputSearch.current.blur();
          }
          if (!startMoveLists) {
            setStartMoveLists(true);
          } else {
            let newListPosition = listPosition < listItems.length - 1 ? listPosition + 1 : 0;
            let element = listItems[newListPosition];
            if (!checkInView(scrollContainerElement.current, element)) {
              element.scrollIntoView(false);
            }
            setListPosition(newListPosition);
          }
          break;
        default:
          return;
      }
    }
  };

  const checkInView = (container, element) => {
    //Get container properties
    let cTop = container.scrollTop;
    let cBottom = cTop + container.clientHeight;
    //Get element properties
    let eTop = element.offsetTop;
    let eBottom = eTop + element.clientHeight;
    //Check if in view
    let isTotal = eTop >= cTop && eBottom <= cBottom;

    //Return outcome
    return isTotal;
  };

  return { startMoveLists, listPosition, setStartMoveLists, setListPosition };
};

const useChangeKeywordsListsOrder = (props) => {
  const { getStoredListIds } = useSetListsIds();
  const { keywordsLists } = props;

  const removeItem = (property, createdLists) => {
    let list;
    for (let i = 0; i < createdLists.length; i++) {
      let currentlist = createdLists[i];
      if (currentlist[property]) {
        list = currentlist;
        createdLists.splice(i, 1);
        break;
      }
    }
    return list;
  };

  const changeKeywordsListsOrder = () => {
    let enabledKeywordsLists = keywordsLists.filter((item) => item.myState === 'Enabled' || item.id === null);
    let createdLists = [...enabledKeywordsLists];
    let listIdsStored = getStoredListIds({});
    //REMOVE THE MASTER TOPIC AND DEFAULT VIEW
    if (listIdsStored !== null) {
      let lastViewLists = listIdsStored;
      //REMOVE THE MASTER TOPIC AND DEFAULT VIEW FROM THE OBJECT TO COMPARE
      let defaultView = removeItem('defaultView', createdLists);
      let masterTopic = removeItem('masterTopic', createdLists);
      let finalLists = [];
      if (defaultView !== undefined) {
        finalLists.push(defaultView);
      }
      if (masterTopic !== undefined) {
        finalLists.push(masterTopic);
      }
      lastViewLists.forEach((item) => {
        createdLists.forEach((listItem, index) => {
          const { id, defaultView, masterTopic } = listItem;
          if (item === id && !defaultView && !masterTopic) {
            finalLists.push(listItem);
            createdLists.splice(index, 1);
          }
        });
      });
      return finalLists.concat(createdLists).filter((item) => item.myState !== 'Hidden' && item.myState !== 'Disabled');
    } else {
      return enabledKeywordsLists;
    }
  };

  const changeClientsOrder = ({ currentTeam, clients }) => {
    if (clients) {
      let currentItem = clients?.find((item) => item?.teamId === currentTeam?.teamId);
      let clientsToCompare = [currentItem, ...clients?.filter((item) => item?.teamId !== currentTeam?.teamId)];
      let createdClients = [...clientsToCompare];
      let clientsIdsStored = getStoredListIds({ clientPicker: true });
      //REMOVE THE MASTER TOPIC AND DEFAULT VIEW
      if (clientsIdsStored !== null) {
        let lastClientsViewed = clientsIdsStored;
        let finalClients = [];
        lastClientsViewed.forEach((item) => {
          createdClients.forEach((listItem, index) => {
            const { teamId } = listItem;
            if (item === teamId) {
              finalClients.push(listItem);
              createdClients.splice(index, 1);
            }
          });
        });

        return finalClients.concat(createdClients);
      } else {
        return clientsToCompare;
      }
    }
  };

  return { changeKeywordsListsOrder, changeClientsOrder };
};

export { useChangeKeywordsListsOrder };
export default useSearchBarUiFunctions;
