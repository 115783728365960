import React, { useContext, useRef, useEffect, useState } from 'react';
import { store } from '../../Store';
import ResearchBarItems from './ui/ResearchBarItems';
import ResearchPopUp from './ui/ResearchPopUp';
import useResetReference from './useResetReference';
import { PortalWithState } from 'react-portal';
import useIsMobile from '../utilities/useIsMobile';

const SearchResultsBar = (props) => {
  return (
    <PortalWithState>
      {({ openPortal, closePortal, portal }) => (
        <ResearchContent openPortal={openPortal} closePortal={closePortal} portal={portal} />
      )}
    </PortalWithState>
  );
};

const ResearchContent = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { referenceState, openResearchPopup, activePlan } = state;
  const { enableAdvancedSearch } = activePlan;
  const { resetReference } = useResetReference();

  const [keywordsSection, setKeywordsSection] = useState('keywords');
  const closeKeywordsSection = () => {
    setKeywordsSection('keywords');
  };

  const { openPortal, closePortal, portal } = props;
  const isMobile = useIsMobile();

  const showPopupFromEmptyMessage = useRef();
  showPopupFromEmptyMessage.current = () => {
    if (openResearchPopup) {
      openPortal();
      dispatch({ type: 'MODIFY_SECTION', parameter: 'openResearchPopup', value: false });
    }
  };

  useEffect(() => {
    showPopupFromEmptyMessage.current();
  }, [openResearchPopup]);

  const searchBarText = () => {
    let text = '';
    const { keywords, andKeywords, keywordExclusions, activeReference } = referenceState;
    if (activeReference) {
      if (keywords.length > 0) {
        text += `${isMobile ? '' : 'Showing results for: '}<span>${keywords.map((item) => `'${item}'`).join(' or ')}</span>`;
      }
      if (andKeywords.length > 0) {
        text += ` AND ALSO <span>${andKeywords.map((item) => `'${item}'`).join(' or ')}</span>`;
      }
      if (keywordExclusions.length > 0) {
        text += ` BUT NOT <span>${keywordExclusions.map((item) => `'${item}'`).join(' or ')}</span>`;
      }
    }
    return text;
  };

  return (
    <>
      <ResearchBarItems
        openPortal={openPortal}
        referenceState={referenceState}
        clearSearch={resetReference}
        setKeywordsSection={setKeywordsSection}
        searchBarText={searchBarText()}
        enableAdvancedSearch={enableAdvancedSearch}
      />
      {portal(
        <ResearchPopUp
          identifier={'research-popup'}
          setShowPopup={closePortal}
          referenceState={referenceState}
          keywordsSection={keywordsSection}
          additionalCloseAction={closeKeywordsSection}
        />
      )}
    </>
  );
};

export default SearchResultsBar;
