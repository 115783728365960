import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom-v5-compat';
import { useParams } from 'react-router-dom-v5-compat';
import Share from '../components/Share';
import { contentItemSearchQuery } from '../helpers/locationSearchDescriptor';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import useRememberPosition from '../hooks/useRememberPosition';
import TagsLists from '../TagsList';
import getSearchWithinHash from '../utilities/getSearchWithinHash';
import getUrlParam from '../utilities/getUrlParam';
import { store } from '../../Store';
import ThumbsDown from '../../Common/ThumbsDown';
import FeedbackOnItem from '../FeedbackItem/FeedbackOnItem';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const LibraryMaterialItem = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search, lastSeenMarkers, itemsViewed } = state;
  const { list, activeSearch } = search;

  const { item, scrollBarRef, index, feedItemFlags, itemOnclick } = props;
  const { contentUrl, title, highlights, body, dateTime, articleTypes } = item;
  const location = useLocation();

  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);

  const feedItem = feedItemFlags?.[index];
  const alreadyMarkedFeedback = feedItem?.ratingScored === 'Negative';

  const locationDescriptor = (path) => {
    let locationSearch = contentItemSearchQuery(location, list);
    let hashToRender = getSearchWithinHash() ? `~search-within:${getSearchWithinHash()}` : '';

    if (inboxSection && getUrlParam('topic-id') === 'all') {
      locationSearch = `?topic-id=${feedItem?.matchingKeywordLists?.[0]?.id}`;
    }
    return {
      pathname: path,
      search: `${locationSearch}`,
      hash: hashToRender,
    };
  };

  const createMarkup = (body) => {
    return { __html: body };
  };

  const { rememberPosition } = useRememberPosition();
  const feedItemsConditional = list !== null && feedItemFlags?.length > 0;
  const isBackpopulatedCondition = feedItem?.isBackpopulated === null || feedItem?.isBackpopulated === true;
  const unseenConditional =
    feedItemsConditional &&
    !!lastSeenMarkers['commonsLibraryArticleFeedItemId'] &&
    lastSeenMarkers['commonsLibraryArticleFeedItemId'] < feedItem?.feedItemId &&
    !itemsViewed.includes(item?.objectID) &&
    !isBackpopulatedCondition;

  return (
    <Link
      to={locationDescriptor(contentUrl)}
      onClick={(e) => {
        if (scrollBarRef) {
          rememberPosition('LibraryMaterial', scrollBarRef, index);
        }
        if (itemOnclick) {
          itemOnclick(e);
        }
      }}
    >
      <li className={`my-2 content-item`}>
        {unseenConditional && (
          <>
            <span className='unseen-dot' />
          </>
        )}
        <div className='px-4 py-3'>
          <div className='flex-centered mb-2'>
            <h4 className={`mb-0 flex-grow-1 content-item-title`}>{title}</h4>
            <div className='flex-centered'>
              <ThumbsDown item={item} index={index} />
              <Share item={item} listItem />
            </div>
          </div>
          <div className={`content-title w-100`}>
            <p className='heading-date mb-0'>
              {articleTypes?.join(', ')}
              <span className='dot' />
              <span className='date'>{dayjs(dateTime).format('DD MMMM YYYY')}</span>
            </p>
            <p
              className='title-body my-2'
              dangerouslySetInnerHTML={createMarkup(highlights ? highlights.highlightedBodySnippets[0] : body)}
            />
          </div>

          {list !== null && activeSearch && (
            <TagsLists {...props} feedItemFlags={feedItemFlags} index={index} section={'LibraryMaterial'} />
          )}
        </div>
        {alreadyMarkedFeedback && <FeedbackOnItem item={item} index={index} />}
      </li>
    </Link>
  );
};
export default LibraryMaterialItem;
