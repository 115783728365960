import { useContext } from 'react';
import { store } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import getUrlParam from '../utilities/getUrlParam';
import useCallMentionsByTopic, { createKey } from './useCallMentionsByTopic';
import dayjs from 'dayjs';
import { RegionMetaData } from './ui/MentionsByTopic/PartyMetadata';
import { chartDataOptions } from './createAnalyticsState';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import useSelectedCountriesFilters from './useSelectedCountriesFilters';
import useAnalyticsIntervalLimit from './useAnalyticsIntervalLimit';
import { useAuth0 } from '@auth0/auth0-react';
import ChangeSearchQuery from '../utilities/changeSearchQuery';

const useCallInterestByRegion = (props) => {
  const { allowFrontbenchers = false, teamId, reportDateInterval } = props;
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { isAuthenticated } = useAuth0();

  const { generalApiCall } = useGeneralApiCall();
  const { createFilters } = useCallMentionsByTopic(props);
  const { conditionOfPublicApi, validateDataForLoggedOutUsers } = useAnalyticsIntervalLimit();

  const topicParameter = getUrlParam('topic-id');

  const { getCountrySpecificFilters, UKSelected, WalesSelected } = useSelectedCountriesFilters({
    analyticsState: props?.analyticsState,
    interestByRegionSection: true,
  });

  const createDateIntervalFilters = () => {
    if (reportDateInterval) {
      return [...createFilters(reportDateInterval, 'day')];
    } else {
      return [
        {
          field: 'dateTime',
          value: dayjs().subtract(365, 'day').add(-1, 'day').startOf('day').format('YYYY-MM-DD'),
          operator: 'date_gte',
        },
        {
          field: 'dateTime',
          value: dayjs().add('1', 'day').format('YYYY-MM-DD'),
          operator: 'date_lt',
        },
      ];
    }
  };

  let types = [
    {
      type: 'ParliamentaryRecord',
      contentTypes: 'ParliamentaryContribution',
      name: 'Parliamentary contribution',
    },
    {
      type: 'ParliamentaryRecord',
      contentTypes: 'QuestionAndAnswer',
      name: 'Question and answer',
    },
    {
      type: 'ParliamentaryRecord',
      contentTypes: 'Edm',
      name: 'EDM',
    },
    {
      type: 'ParliamentaryRecord',
      contentTypes: 'SeneddCommitteeTranscriptContribution',
      name: 'Committee contributions',
    },
  ];

  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    types = [
      ...types,
      {
        type: 'Tweet',
        additionalFilters: [
          {
            field: 'isRetweet',
            value: 'true',
            operator: 'str_eq',
          },
        ],
        name: 'Tweet',
      },
      {
        type: 'Tweet',
        additionalFilters: [
          {
            field: 'isRetweet',
            value: 'false',
            operator: 'str_eq',
          },
        ],
        name: 'Retweet',
      },
    ];
  }

  const dataTableIndividualApiCalls = async ({ topic, item, source, freeSearch }) => {
    const { type, contentTypes, additionalFilters } = item;
    let pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}aggregation${
      !!topic & !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''
    }`;
    let method = 'post';
    let filters = [
      ...createDateIntervalFilters(),
      ...getCountrySpecificFilters({
        value: contentTypes,
        field: 'contentType',
      }),
    ];

    if (!allowFrontbenchers) {
      filters = [
        ...filters,
        {
          field: 'contactPositionCategories',
          value: 'Backbencher',
          operator: 'str_eq',
        },
        {
          field: 'contactPositionCategories',
          value: 'MSP',
          operator: 'str_eq',
        },
        { field: 'contactPositionCategories', value: 'MS', operator: 'str_eq' },
      ];
    } else {
      filters = [
        ...filters,
        {
          field: 'contactPositionCategories',
          value: 'MSP',
          operator: 'str_eq',
        },
        {
          field: 'contactPositionCategories',
          value: 'MS',
          operator: 'str_eq',
        },
        { field: 'contactPositionCategories', value: 'Backbencher', operator: 'str_eq' },
        { field: 'contactPositionCategories', value: 'Shadow Government', operator: 'str_eq' },
        { field: 'contactPositionCategories', value: 'Government', operator: 'str_eq' },
        { field: 'contactPositionCategories', value: 'Shadow Cabinet', operator: 'str_eq' },
        { field: 'contactPositionCategories', value: 'Cabinet', operator: 'str_eq' },
      ];
    }

    if (additionalFilters) {
      filters = [...filters, ...additionalFilters];
    }

    let requestProperties = {
      type: type,
      filters,
      bucketField: 'contactRegion',
      order: 'asc',
    };

    if (topic?.prospective) {
      const { keywords, andKeywords, keywordExclusions } = topic;
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(keywords),
        andQueries: ChangeKeywordsExclusions(andKeywords),
        mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
      };
    } else if (freeSearch) {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(freeSearch),
      };
    } else {
      requestProperties = {
        ...requestProperties,
        keywordListId: topic?.id,
      };
    }

    if (teamId) {
      requestProperties = {
        ...requestProperties,
        teamId,
      };
    }
    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: !teamId || (freeSearch && isAuthenticated),
      notShowErrorMessage: true,
      requestSource: source,
      returnError: true,
    });

    if (!validateDataForLoggedOutUsers(results)) {
      return { values: [] };
    }

    if (results) {
      return results;
    }
  };

  const callInterestByRegion = async ({
    topic = topicParameter
      ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
      : keywordsListsToUse[0],
    source,
    freeSearch,
  }) => {
    let optionData = chartDataOptions.find((item) => item.name === 'Region');
    let rawData = {};
    let finalResults = {};
    let typesToUse = types.filter((item) => {
      if (item.contentTypes === 'Edm') {
        return UKSelected;
      }
      if (item.contentTypes === 'SeneddCommitteeTranscriptContribution') {
        return WalesSelected;
      }
      return true;
    });

    for (let i = 0; i < typesToUse.length; i++) {
      let item = typesToUse[i];
      let results = await dataTableIndividualApiCalls({ topic, item, source, freeSearch });
      if (results) {
        finalResults[item.name] = results.values;
      }
    }
    let value = Object.keys(finalResults).map((item) => {
      let results = 0;
      let content = finalResults[item];
      for (let i in content) {
        results += content[i];
      }
      return results;
    });
    Object.keys(finalResults).forEach((item) => {
      let content = finalResults[item];
      let initialKeys = Object.keys(content);
      let orderingKeys = initialKeys;
      orderingKeys = [];
      RegionMetaData.orderingRegions.forEach((item) => {
        orderingKeys.push(item === 'Yorkshire and the Humber' ? 'yorkshire & the humber' : item.toLowerCase());
      });

      orderingKeys.forEach((key) => {
        let name = createKey({ chartDataOption: 'Region', item: key });
        let condition = optionData.showRowsFor ? optionData.showRowsFor?.includes(name) : true;
        if (!rawData[name] && condition) {
          rawData[name] = {};
        }
        if (condition) {
          rawData[name][item] = content[key];
        }
      });
    });
    if (value?.length > 0) {
      return {
        labels: typesToUse.map((item) => item.name),
        value,
        rawData,
      };
    }
  };

  return { callInterestByRegion };
};

export default useCallInterestByRegion;
