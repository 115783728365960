import React from 'react';
import { useState } from 'react';
import SalutationModal from './SalutationModal';

const SalutationInfoButton = () => {
  const [showSalutationModal, setShowSalutationModal] = useState(false);
  const hideSalutationModal = () => {
    setShowSalutationModal(false);
  };
  return (
    <>
      {showSalutationModal && <SalutationModal hideSalutationModal={hideSalutationModal} />}
      <h3
        className='update-custom-contact-salutation'
        onClick={() => {
          setShowSalutationModal(true);
        }}
      >
        Salutation
        <span className='icon-info pointer ml-2 paragraph-p3' style={{ opacity: 0.4 }}></span>
      </h3>
    </>
  );
};

export default SalutationInfoButton;
