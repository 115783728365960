const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const formatGeneralDate = (date, includeHour, notIncludeDay) => {
  if (includeHour) {
    return dayjs(date).format('DD MMM[,] YYYY HH:mm');
  } else if (notIncludeDay) {
    return dayjs(date).format('MMM YYYY');
  } else {
    return dayjs(date).format('DD MMM[,] YYYY');
  }
};

const formatSimpleGeneralDate = (date) => {
  return dayjs(date).format('D MMM[,] YYYY');
};

const formatSimpleDay = (date, includeYear) => {
  return dayjs(date).format('DD MMM');
};

const formatCalendarDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

const formatEmbargoedDate = (date) => {
  return dayjs(date).format('DD MMM[,] YYYY [at] HH:mm');
};

const trendsFormat = ({ date, month }) => {
  return month ? dayjs(date).format('MMM YYYY') : dayjs(date).format('DD MMM[,] YY');
};

const formatMonthFirstChar = (date) => {
  return dayjs(date).format('MMM')[0];
};

const formatShortMonth = (date) => {
  return dayjs(date).format('MMM');
};

const startOfMonth = (date) => {
  return dayjs(date).startOf('month');
};

export {
  formatGeneralDate,
  formatCalendarDate,
  formatEmbargoedDate,
  trendsFormat,
  formatSimpleDay,
  formatSimpleGeneralDate,
  formatMonthFirstChar,
  formatShortMonth,
  startOfMonth,
};
