import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import useEmailDomainFunctions from '../../hooks/useEmailDomainFunctions';
import { store } from '../../../Store';

const useGetAllDomains = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { listDomainsVerified } = state;

  const [domains, setDomains] = useState(listDomainsVerified.verifiedDomains);
  const [allDomains, setAllDomains] = useState(listDomainsVerified.allDomains);
  const [emailAddresses, setEmailAddresses] = useState(listDomainsVerified.verifiedEmailAddresses);
  const [allEmailAddresses, setAllEmailAddresses] = useState(listDomainsVerified.allEmailAddresses);
  const [domainsProccessing, setDomainsProccessing] = useState(listDomainsVerified.domainsProccessing);
  const { isAuthenticated } = useAuth0();

  const { getEmailAddressesCall, getEmailDomains, syncEmailDomain } = useEmailDomainFunctions();

  const location = useLocation();

  const asyncFn = async (source) => {
    if (isAuthenticated && location.pathname !== '/influence') {
      setDomainsProccessing(true);
      if (location.pathname.includes('/influence/emails') || location.pathname === '/influence/contacts') {
        await syncEmailDomain({ isAdminPage: false, source });
      }
      let domainsResult = await getEmailDomains({ isAdminPage: false, source });
      if (domainsResult?.length) {
        let domainList = domainsResult.filter((e) => e.verificationStatus === 'Success');
        if (domainList.length) {
          setDomains(domainList);
        }
        setAllDomains(domainsResult);
      }
      const emailAddressesResult = await getEmailAddressesCall(source);
      if (emailAddressesResult?.length) {
        let emailsList = emailAddressesResult.filter((e) => e.isVerified);
        if (emailsList.length) {
          setEmailAddresses(emailsList);
        }
        setAllEmailAddresses(emailAddressesResult);
      }
      if (!domainsResult && !emailAddressesResult) {
        //OP: in case if first call was cancelled we want we want to wait to not trigger redirection from /email/id to /emails immediately
        setTimeout(() => setDomainsProccessing(false), 1000);
      } else {
        setDomainsProccessing(false);
      }
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    try {
      asyncFn(source);
    } catch (e) {}

    return () => {
      source.cancel('Domains cancelled by user');
    };
  }, [location.pathname]);

  return {
    verifiedDomains: domains,
    allDomains: allDomains,
    domainsProccessing: domainsProccessing,
    verifiedEmailAddresses: emailAddresses,
    allEmailAddresses,
  };
};
export default useGetAllDomains;
