import React from 'react';
import CommonFilter from './CommonFilter';
import TransformFromPascalCase from '../../../Settings/Utilities/TransformFromPascalCase';
import normalize from '../../utilities/normalizeString';

function TwoFiltersPopupContainer({ sectionFilters, componentSelectOption, unreadFilter, section }) {
  return (
    <div className='d-flex justify-content-space-between two-filters-popup-container flex-wrap'>
      {sectionFilters.map((item, index) => {
        let { type, options } = item;
        const filterProps = {
          filter: { ...item, options: options },
          selectOption: componentSelectOption(),
          unreadFilter: unreadFilter(),
        };
        return (
          <div
            key={`filter-${index}`}
            className={`flex-grow-1 filters-section filter-section-${type}-search-mode ${normalize(
              TransformFromPascalCase(section)
            )}-filters-popup`}
          >
            <CommonFilter {...filterProps} notRow={true} />
          </div>
        );
      })}
    </div>
  );
}

export default TwoFiltersPopupContainer;
