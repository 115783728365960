import { Fragment, useContext, useEffect, useRef } from 'react';
import { formatGeneralDate, formatSimpleDay } from '../../../helpers/formatGeneralDate';
import { useLocation } from 'react-router-dom-v5-compat';
import thousandCommas from '../../../helpers/thousandCommas';
import { store } from '../../../../Store';
import useIsMobile from '../../../utilities/useIsMobile';
import getUrlParam from '../../../utilities/getUrlParam';
import useModifyAnalyticsState from '../../useModifyAnalyticsState';
import { useAuth0 } from '@auth0/auth0-react';

const switchContributionTypeToLowerCase = (key) => {
  switch (key) {
    case 'Tweets':
      return 'Tweet';
    case 'Retweets':
      return 'Retweet';
    case 'EDMs':
      return 'EDM';
    default:
      return key.toLowerCase().substring(0, key.length - 1);
  }
};

const createDate = (currentView, date) => {
  if (currentView === 'Month') {
    return `in ${formatGeneralDate(date, false, true)}`;
  } else if (currentView === 'Week') {
    return `on the week of ${formatGeneralDate(date, false)}`;
  } else {
    return `on ${formatSimpleDay(date)}`;
  }
};

const useChartDataOptionSelected = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsState } = state;
  const { chartDataOptionSelected, chartDataOptionSelectedTweets, chartDataOptionSelectedSentimentChanges } =
    analyticsState;

  const location = useLocation();
  const TweetsSection = location.pathname.includes('/analytics/tweets');
  const SentimentChangesSection = location.pathname.includes('/analytics/sentiment-changes');
  const createOptionName = () => {
    if (SentimentChangesSection) {
      return chartDataOptionSelectedSentimentChanges;
    } else if (TweetsSection) {
      return chartDataOptionSelectedTweets;
    } else {
      return chartDataOptionSelected;
    }
  };
  const chartOptionName = createOptionName();

  const isMobile = useIsMobile();
  const createDataOption = () => {
    if (isMobile) {
      switch (chartOptionName) {
        case 'Contribution type':
          return 'Type';
        case 'Parliamentary position':
          return 'Position';
        default:
          return chartOptionName;
      }
    } else {
      return chartOptionName;
    }
  };
  let chartDataOption = createDataOption();
  return { chartOptionName, chartDataOption };
};

const useAllowRightPanel = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, analyticsState } = state;
  const { enableAnalytics } = activePlan;
  const { isAuthenticated } = useAuth0();

  const { modifyState } = useModifyAnalyticsState();

  const showPanelPopup = () => {
    modifyState({ property: 'restrictPanel', newValue: true });
  };

  const showPanelWithTitle = (title, useEmoji) => {
    let newState = {
      ...analyticsState,
      restrictPanel: true,
      popupTitle:
        title ??
        `Drill into the data ${!!getUrlParam('search') ? `on ${decodeURIComponent(getUrlParam('search'))}` : ''}`,
      useEmoji: !!title ? useEmoji : true,
    };
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'analyticsState',
      value: newState,
    });
  };

  const delayShowPanelTitle = (title) => {
    if (!enableAnalytics || !isAuthenticated) {
      setTimeout(() => {
        showPanelWithTitle();
      }, 300);
    }
  };

  return { enablePanel: enableAnalytics, showPanelPopup, showPanelWithTitle, delayShowPanelTitle };
};

const ChartDataTable = (props) => {
  const {
    analyticsState,
    chartDataKeys,
    chartDataContainer,
    activeSection,
    highlightGraphOnHover,
    labelDates,
    compiledData,
    data,
    changeOrderTableLabel,
    rawData,
    rightPanelState,
    setRightPanelState,
    createSectionValues,
  } = props;
  const { currentView, restrictPanel } = analyticsState;
  const { chartDataOption } = useChartDataOptionSelected();
  const location = useLocation();
  const mentionsChart = location.pathname.includes('/analytics/mentions');
  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');

  const { delayShowPanelTitle } = useAllowRightPanel();

  useEffect(() => {
    if (!restrictPanel) {
      setRightPanelState({ open: false });
    }
  }, [chartDataOption, topicParameter, searchParameter, restrictPanel]);

  const moveTable = ({ el }) => {
    if (el) {
      setTimeout(() => {
        chartDataContainer.current.scrollLeft = el.offsetLeft - 20;
      }, 10);
    }
  };

  return (
    <>
      <div className='flex-centered justify-content-between pt-5 pb-4 mb-1 sticky-item'>
        <h3 className='title-h5-m-bold mb-0'>Chart data</h3>
      </div>
      <div className='chart-data-container d-flex align-items-start'>
        {chartDataOption !== 'Overall' && (
          <div className='overflow-auto chart-data-labels-container'>
            <table className='table chart-data-labels'>
              <thead>
                <tr>
                  <th className='font-weight-bold'>{chartDataOption}</th>
                </tr>
              </thead>
              <tbody>
                {chartDataKeys.map((item, i) => {
                  return (
                    <tr key={`data-top-${i}`}>
                      <td>
                        <span className='nowrap-item'>{item}</span>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td className='font-weight-bold'>Total</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div
          className={`overflow-auto chart-data-info-container flex-grow-1 ${chartDataOption === 'Overall' ? 'border-left' : ''}`}
          ref={chartDataContainer}
        >
          <table className='table chart-data-info chart-sort-table'>
            <thead>
              <tr>
                {labelDates.map((label, index) => (
                  <th
                    onMouseOver={() => {
                      highlightGraphOnHover(index);
                    }}
                    className={`${chartDataOption === 'Overall' ? '' : 'sort-table-item'}  nowrap-item font-weight-bold ${
                      index === activeSection ? 'selected-label-total' : ''
                    }`}
                    key={`label-${label}`}
                    onClick={(e) => {
                      changeOrderTableLabel(label, e);
                    }}
                    data-order={'asc'}
                  >
                    {formatGeneralDate(label, false, currentView === 'Month')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {chartDataKeys.map((dataKey, i) => {
                let datapoints = data.value[dataKey];
                if (dataKey !== 'Total contributions') {
                  return (
                    <tr key={`data-${i}`}>
                      {labelDates.map((item, index) => {
                        let dateItem = datapoints?.find((datapoint) => datapoint.date === item);
                        return (
                          <CellItem
                            key={`date-${item}`}
                            dateItem={dateItem}
                            highlightGraphOnHover={highlightGraphOnHover}
                            activeSection={activeSection}
                            index={index}
                            i={i}
                            dataKey={dataKey}
                            date={item}
                            analyticsState={analyticsState}
                            rawData={rawData}
                            setRightPanelState={setRightPanelState}
                            moveTable={moveTable}
                            rightPanelState={rightPanelState}
                            createSectionValues={createSectionValues}
                          />
                        );
                      })}
                    </tr>
                  );
                } else {
                  return <Fragment key={`data-${i}`} />;
                }
              })}

              <tr>
                {compiledData.map((item, index) => (
                  <td
                    key={`total-${item}-${index}`}
                    onMouseOver={() => {
                      highlightGraphOnHover(index);
                    }}
                    className={`label-total ${
                      index === activeSection ? 'selected-label-total text-underline' : 'text-light-blue'
                    }`}
                    onClick={(e) => {
                      const sectionValues = createSectionValues({
                        date: labelDates[index],
                        dataKey: 'totalCell',
                        noResults: item === 0,
                      });
                      setRightPanelState({
                        open: true,
                        date: labelDates[index],
                        rawData,
                        text: `${thousandCommas(item)} ${
                          sectionValues?.length === 1
                            ? `${switchContributionTypeToLowerCase(sectionValues[0]?.name)}${thousandCommas(item) === 1 ? '' : 's'}`
                            : mentionsChart
                              ? `mention${item === 1 ? '' : 's'}`
                              : `Tweet${item === 1 ? '' : 's'} or Retweet${item === 1 ? '' : 's'}`
                        } by parliamentarians ${createDate(currentView, labelDates[index])}`,
                        dataKey: 'totalCell',
                        chartDataKeys,
                        noResults: item === 0,
                      });
                      moveTable({ el: e.target });
                      delayShowPanelTitle();
                    }}
                  >
                    {thousandCommas(item)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CellItem = (props) => {
  const {
    dateItem,
    highlightGraphOnHover,
    activeSection,
    index,
    i,
    dataKey,
    date,
    analyticsState,
    rawData,
    rightPanelState,
    moveTable,
    setRightPanelState,
    createSectionValues,
  } = props;
  const { currentView } = analyticsState;
  const location = useLocation();
  const { chartOptionName } = useChartDataOptionSelected();
  const mentionsChart = location.pathname.includes('/analytics/mentions');

  const value = dateItem?.value ?? '—';
  const { delayShowPanelTitle } = useAllowRightPanel();

  const createDataTip = () => {
    const sectionValues = createSectionValues({
      date: dateItem?.date,
      dataKey,
      noResults: value === 0,
    });
    const nextText = () => {
      if (chartOptionName === 'Contribution type') {
        return `${switchContributionTypeToLowerCase(dataKey)}${value === 1 ? '' : 's'}`;
      } else {
        return `${
          sectionValues?.length === 1
            ? `${switchContributionTypeToLowerCase(sectionValues[0]?.name)}${value === 1 ? '' : 's'}`
            : mentionsChart
              ? `mention${value === 1 ? '' : 's'}`
              : `Tweet${value === 1 ? '' : 's'} or Retweet${value === 1 ? '' : 's'}`
        } by parliamentarians (${dataKey})`;
      }
    };

    return `${nextText()} ${createDate(currentView, date)}`;
  };

  const cellItem = useRef();
  const activeCell = rightPanelState?.date === dateItem?.date && rightPanelState?.dataKey === dataKey;

  return (
    <td
      ref={cellItem}
      className={`${index === activeSection ? 'selected-label-info' : ''}`}
      style={activeCell ? { backgroundColor: '#0094CC' } : null}
      onMouseOver={() => {
        highlightGraphOnHover(index, i);
      }}
      onClick={(e) => {
        setRightPanelState({
          open: true,
          date: dateItem?.date,
          rawData,
          text: `${thousandCommas(value)} ${createDataTip()}`,
          dataKey,
          noResults: value === 0,
        });
        moveTable({ el: cellItem.current });
        delayShowPanelTitle();
      }}
    >
      <span className={`cell-element text-light-blue ${activeCell ? 'text-white font-weight-bold' : ''}`}>
        {thousandCommas(value)}
      </span>
    </td>
  );
};

export { switchContributionTypeToLowerCase, createDate, useChartDataOptionSelected, useAllowRightPanel };
export default ChartDataTable;
