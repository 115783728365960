import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import addMetaTags from '../Dashboard/utilities/addMetaTags';
import { store } from '../Store';
import MyContent from './ui/MyContent';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import axios from 'axios';

const WritingMyContent = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan } = state;

  const { individualPage } = props;
  const [isLoading, setIsLoading] = useState(true);
  const getContent = useRef();
  const { generalApiCall } = useGeneralApiCall();
  const [results, setResults] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  getContent.current = async (source) => {
    try {
      const pathname = '/api/writing-assistant-generated-content/list';
      const method = 'get';
      let results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });

      if (!!results) {
        let finalResults = results.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setResults(finalResults);
        setIsLoading(false);
        let title = `AI assistant - PolicyMogul`;
        addMetaTags({ title, hash: location.hash, location, dispatch });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    navigate({ replace: true, pathname: `${location.pathname}`, search: location.search });
    getContent.current(source);
    return () => {
      source.cancel('List cancelled');
    };
  }, []);

  return <MyContent individualPage={individualPage} isLoading={isLoading} results={results} activePlan={activePlan} />;
};
export default WritingMyContent;
