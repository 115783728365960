import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonLists = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='px-3 px-lg-5 pt-lg-5 pb-lg-0'>
        <div className='row mx-0 justify-content-between align-items-center mb-4'>
          <div className='col-4 px-0'>
            <Skeleton width={`80%`} height={22} />
          </div>
          <div className='col-4 text-right'>
            <Skeleton width={`75%`} height={32} />
          </div>
        </div>
      </div>
      <ul className='px-0 policy-list'>
        {Array(5)
          .fill()
          .map((item, index) => (
            <div className='topic-list-item py-4' key={`list${index}`}>
              <div className='d-flex justify-content-between align-items-center mb-3 flex-wrap topic-individual-padding'>
                <div className='col-lg-8 px-0'>
                  <Skeleton height={24} width={`50%`} />
                </div>
                <div className='d-flex align-items-center email-alerts-badges topic-badges-margin-adjustment'>
                  <Skeleton height={24} width={`120px`} style={{ borderRadius: '20px' }} />
                  <div className='ml-3'>
                    <Skeleton height={24} width={`130px`} style={{ borderRadius: '20px' }} />
                  </div>
                </div>
              </div>
              <div className='keywords-description-topic overflow-hidden topic-individual-padding'>
                <div className='email-alert-information-box'>
                  <div className='keywords-adjustment individual-items-vertical-padding'>
                    <Skeleton height={19} width={`30%`} />
                    <Skeleton height={19} width={`100%`} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </ul>
    </SkeletonTheme>
  );
};

export default SkeletonLists;
