const ChangeKeywordsCategory = (keywords) => {
  let trimmedKeywords = [];
  keywords?.forEach((item) => {
    if (!item.includes('"')) {
      trimmedKeywords.push(item.trim());
    } else {
      trimmedKeywords.push(item);
    }
  });
  let keywordsToRender = [];
  trimmedKeywords.forEach((item) => {
    if (item.indexOf(' ') >= 0 && !item.includes('"')) {
      keywordsToRender.push(`"${item}"`);
    } else {
      keywordsToRender.push(item);
    }
  });
  return keywordsToRender.join(', ');
};

export default ChangeKeywordsCategory;
