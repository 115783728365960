import getUrlParam from '../components/Dashboard/utilities/getUrlParam';

const validateParameter = (parameter) => {
  let value = '';
  let urlParameter = getUrlParam(parameter);
  if (urlParameter) {
    value = decodeURIComponent(urlParameter);
  }
  return value;
};

export default validateParameter;
