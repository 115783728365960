import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useRef, useState } from 'react';
import { loadKeyWords } from '../utilities/loadKeyWords';
import getCurrentTeam from '../../Settings/Team/getCurrentTeam';
import TransformTopicsList from '../MainSearchBar/TransformTopicsList';
import { store } from '../../Store';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import useDetectTopicOnTheFirstLoad from './useDetectTopicOnTheFirstLoad';
import { regexSectionName } from '../helpers/controlActiveFilters';
import useEditReferenceState from '../SubNav/useEditReferenceState';
import getUrlParam from '../utilities/getUrlParam';
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat';
import useClientsFunctions from '../hooks/useClientsFunctions';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import { useChangeKeywordsListsOrder } from '../hooks/useSearchBarUiFunctions';
import { useResetClientNavigation } from '../Navigation/ClientNavigation';
import useGetAccessToken from '../apiCalls/useGetAccessToken';
let useFromUrl = true;

const useDashboardInitialController = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan, referenceState, search, keywordsLists, targetClient, clientToSwitchOnReports } = state;
  const { activeReference } = referenceState;
  const { enableStakeholders } = activePlan;
  const [isLoading, setIsLoading] = useState(true);

  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { inboxSection, searchResultsSection } = useMainSectionParameter(params);

  const { detectListOnFirstLoad } = useDetectTopicOnTheFirstLoad({});
  const { rawParliamentaryRecordWidgetSection, researchFunction } = useEditReferenceState();
  const { getTeams } = useClientsFunctions();
  const { newAgencyPlan } = useTopicOrClientWord();
  const { changeClientsOrder } = useChangeKeywordsListsOrder({});
  const { resetClientNavigation } = useResetClientNavigation();

  const { getAccessToken } = useGetAccessToken();

  const searchResults = async (list, notUpdateHistory) => {
    let urlParams = new URLSearchParams(location.search);
    if (location.search.split('&').length > 1 && useFromUrl) {
      //AE: Utm codes
      urlParams.delete('search');
    }

    //AE: Encode the search
    let hashToRender = !!urlParams.id && !useFromUrl ? '' : window.location.hash;

    if (list) {
      urlParams.set('topic-id', `${list.id === null ? 'all' : list.id}`);
    }

    if (activeReference) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'referenceState',
        value: {
          ...referenceState,
          activeReference: false,
        },
      });
    }
    dispatch({ type: 'MODIFY_SECTION', parameter: 'totalNumbers', value: {} });
    if (list) {
      let stateForSearch = { ...search, query: '', searchState: true, activeSearch: true, list: list ?? null };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'activeResults', value: 'searchResults' });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'search', value: stateForSearch });
      if (activeReference) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'referenceState',
          value: {
            activeReference: false,
            keywords: [],
            andKeywords: [],
            keywordExclusions: [],
          },
        });
      }
      if (!useFromUrl && list !== 'noListsCreated' && !notUpdateHistory) {
        navigate({ pathname: location.pathname, search: urlParams.toString(), hash: hashToRender }, { replace: true });
      }
    }
    useFromUrl = false;
  };

  const loadKeywordsLists = useRef();
  loadKeywordsLists.current = async (source) => {
    if (isAuthenticated) {
      try {
        //LOAD KEYWORDLISTS
        let keywordsListsResults = await loadKeyWords(getAccessToken, source);
        keywordsListsResults = keywordsListsResults?.filter((item) => item.myState === 'Enabled');
        //LOAD TEAM INFORMATION
        let currentTeam = await getCurrentTeam(getAccessToken, source);
        if (newAgencyPlan) {
          //AE:Needs includeKeywordsList to use on the embed widgets
          const clients = await getTeams({ includeKeywordsList: true, source });
          if (!!clients) {
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'clients',
              value: changeClientsOrder({ currentTeam, clients }),
            });
          }
        }
        if (!!keywordsListsResults) {
          if (!!currentTeam) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'team', value: currentTeam });
          }
          let keywordLists =
            keywordsListsResults.length > 1
              ? TransformTopicsList(keywordsListsResults, activePlan)
              : keywordsListsResults;
          dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordLists });
          dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: true });
          if (targetClient !== null) {
            dispatch({ type: 'MODIFY_SECTION', parameter: 'dispatchSearchForClients', value: true });
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      let urlParams = new URLSearchParams(location.search);
      if (!location.pathname.includes('stakeholder-mapping')) {
        urlParams.delete('topic-id');
      }
      urlParams.delete('view');
      urlParams.delete('contact-id');
      navigate(
        {
          search: urlParams.toString(),
          hash: location.hash,
        },
        { replace: true }
      );
      hitAllResults();
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: true });
      setIsLoading(false);
    }
    useFromUrl = false;
  };

  const hitAllResults = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'search',
      value: {
        ...search,
        searchState: true,
      },
    });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'activeResults', value: 'allResults' });
  };

  const searchList = () => {
    let list = detectListOnFirstLoad(keywordsLists);
    if (list !== null) {
      let notUpdateHistory = !useFromUrl;
      searchResults(list, notUpdateHistory);
    }
  };

  const dispatchSearch = useRef();
  dispatchSearch.current = () => {
    setIsLoading(true);
    const topicsSections =
      inboxSection ||
      (location.pathname.includes('/stakeholder-mapping') && enableStakeholders) ||
      location.pathname === '/policymakers';
    const officialReportsSections =
      location.pathname.match(/\/parliamentary-record\/scottish-official-report\/\w+/i)?.input ||
      location.pathname.match(/\/parliamentary-record\/senedd-official-report\/\w+/i)?.input ||
      location.pathname.match(/\/parliamentary-record\/welsh-official-report\/\w+/i)?.input;

    const questionsAndAnswersSections =
      location.pathname.match(/\/parliamentary-record\/scottish-written-q-and-a\/\w+/i)?.input ||
      location.pathname.match(/\/parliamentary-record\/written-q-and-a\/\w+/i)?.input ||
      location.pathname.match(/\/parliamentary-record\/welsh-written-q-and-a\/\w+/i)?.input ||
      location.pathname.match(/\/parliamentary-record\/northern-irish-written-q-and-a\/\w+/i)?.input;

    const hansardSections =
      rawParliamentaryRecordWidgetSection || officialReportsSections || questionsAndAnswersSections;
    const parliamentaryRecordSections =
      (hansardSections && !getUrlParam('topic-id')) ||
      (hansardSections && getUrlParam('topic-id') === 'all') ||
      (hansardSections && getUrlParam('topic-id') === 'none') ||
      (hansardSections && !keywordsLists.find((item) => item.id === parseInt(getUrlParam('topic-id'))));

    if (topicsSections) {
      searchList();
      resetClientNavigation();
    } else if (searchResultsSection) {
      researchFunction();
    } else if (regexSectionName(location.pathname) !== null) {
      if (getUrlParam('search') || getUrlParam('or')) {
        researchFunction();
      } else if (parliamentaryRecordSections) {
        let urlParams = new URLSearchParams(location.search);
        urlParams.set('topic-id', 'none');
        navigate(
          {
            search: urlParams.toString(),
            hash: location.hash,
          },
          { replace: true }
        );
        hitAllResults();
      } else {
        searchList();
      }
    }
    setIsLoading(false);
    if (targetClient !== null) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'changingClientFinished', value: true });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readySetCurrentTeam', value: false });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'dispatchSearchForClients', value: false });
      if (clientToSwitchOnReports) {
        navigate('/reports');
        dispatch({ type: 'MODIFY_SECTION', parameter: 'clientToSwitchOnReports', value: null });
      }
    }
  };

  return { isLoading, loadKeywordsLists, dispatchSearch, searchResults };
};

export default useDashboardInitialController;
