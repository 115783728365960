import { useLocation } from 'react-router-dom-v5-compat';
import normalize from '../utilities/normalizeString';
import GraphItem from './AnalyticsDashboardGraphItem';
import { Link } from 'react-router-dom-v5-compat';
import CustomScrollbar from '../../Common/CustomScrollbar';
import { useCreateAnalyticsSections } from './ui/AnalyticsSidebar';
import { useAuth0 } from '@auth0/auth0-react';
import { VocalWidgetContainer } from './ui/AnalyticsDashboard/MostVocalWidget';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAllowRightPanel } from './ui/MentionsByTopic/ChartDataTable';
import getUrlParam from '../utilities/getUrlParam';
import { useCreateHoverText } from './HoverText';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import { store } from '../../Store';
import AnalyticsStakeholderPopup from './ui/AnalyticsDashboard/AnalyticsStakeholderPopup';

const AnalyticsDashboardContainer = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { analyticsRateLimitReached, analyticsHubSearchQuery } = state;

  const location = useLocation();
  const { analyticsSections } = useCreateAnalyticsSections();

  const { isAuthenticated } = useAuth0();
  const [scrollBarPosition, setScrollBarPosition] = useState(0);
  const { scrollBarRef } = props;

  const [stakeholderResults, setStakeholderResults] = useState({});
  const [openStakeholderPopup, setOpenStakeholderPopup] = useState(false);

  const { hoverText } = useCreateHoverText({ datahub: true, chartWord: 'chart' });
  const searchParameter = getUrlParam('search');

  const { generalApiCall } = useGeneralApiCall();

  const searchForStakeholder = useRef();
  searchForStakeholder.current = () => {
    setTimeout(async () => {
      const pathname = `/api/search/query`;
      const method = 'post';
      const requestProperties = {
        filters: [],
        includeHighlights: true,
        pageNumber: 1,
        pageSize: process.env.REACT_APP_PAGE_SIZE,
        query: decodeURIComponent(searchParameter),
        smartMatch: true,
        type: 'stakeholder',
      };
      const results = await generalApiCall({ pathname, method, requestProperties, notShowErrorMessage: true });
      if (results?.hits?.length > 0) {
        setStakeholderResults(results);
      }
    }, 3000);
  };

  useEffect(() => {
    if (!!analyticsHubSearchQuery) {
      searchForStakeholder.current();
      dispatch({ type: 'MODIFY_SECTION', parameter: 'analyticsHubSearchQuery', value: null });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(stakeholderResults)?.length > 0 && !analyticsRateLimitReached) {
      setOpenStakeholderPopup(true);
    }
  }, [stakeholderResults]);

  return (
    <>
      <CustomScrollbar
        ref={scrollBarRef}
        className={'main-content-scrollbar top-content-scrollbar'}
        maximalThumbYSize={100}
        onScroll={(scrollValues) => {
          setScrollBarPosition(scrollValues?.scrollTop);
        }}
      >
        <div className='pt-1'>
          <div className='row mx-0'>
            {analyticsSections.map((section, index) => {
              const { dashboardName, name, dashboardTitle } = section;
              return (
                <div
                  className={`col-lg-8 mb-4 dashboard-analytics dashboard-analytics-${normalize(
                    name
                  )} px-0 p${index % 2 === 0 ? 'r' : 'l'}-lg-3`}
                  key={`item-${normalize(name)}`}
                >
                  {name === 'Most vocal policymakers' ? (
                    <VocalWidgetContainer scrollBarPosition={scrollBarPosition} />
                  ) : (
                    <>
                      {!isAuthenticated && name === 'Top MPs and peers' ? (
                        <LoggedOutStakeholders name={name} />
                      ) : (
                        <Link
                          to={`/${isAuthenticated ? 'analytics' : 'data-hub'}/${normalize(name)}${location.search}`}
                          className='h-100 d-block box-outstanding p-4 box-rounded trends-ink-box position-relative'
                        >
                          <div className='flex-centered mb-4 position-relative z-max justify-content-between'>
                            <h3 className='title-h5-m-bold mb-0'>{dashboardTitle ?? dashboardName ?? name}</h3>
                            <span
                              className='d-none d-lg-inline-block icon-info main-subtle-text'
                              data-tooltip-content={`${hoverText[normalize(name)]}`}
                              data-tooltip-id={'analytics-tooltip'}
                              data-tooltip-class-name={
                                'general-tooltip pricing-feature-tooltip report-popup-tooltip header-tooltip'
                              }
                            />
                          </div>
                          <GraphItem chartType={name} />
                        </Link>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </CustomScrollbar>

      <AnalyticsStakeholderPopup
        isOpen={openStakeholderPopup}
        setIsOpen={(value) => {
          setOpenStakeholderPopup(value);
        }}
        stakeholderResults={stakeholderResults}
      />
    </>
  );
};

const EmptyStateRateLimit = () => {
  return (
    <div className={'flex-centered justify-content-center'} style={{ minHeight: '280px' }}>
      <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
        Try again soon or sign in for unlimited searches
      </p>
    </div>
  );
};

const LoggedOutStakeholders = (props) => {
  const { name } = props;
  const { hoverText } = useCreateHoverText({ datahub: true });
  const { showPanelWithTitle } = useAllowRightPanel();
  return (
    <div
      className='h-100 d-block box-outstanding p-4 box-rounded trends-ink-box position-relative pointer'
      onClick={() => {
        showPanelWithTitle(
          `See who's shaping the debate on ${!!getUrlParam('search') ? decodeURIComponent(getUrlParam('search')) : 'any issue'}`,
          true
        );
      }}
    >
      <div className='mb-4 flex-centered justify-content-between'>
        <h3 className='title-h5-m-bold mb-0 position-relative z-max'>
          {name} <span className={`icon-padlock ml-2 d-none d-lg-inline-block`} style={{ marginTop: '1px' }} />
        </h3>

        <span
          className='icon-info main-subtle-text'
          data-tooltip-content={hoverText['top-mps-and-peers']}
          data-tooltip-id={'general-tooltip'}
          data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
        />
      </div>
      <img
        className='w-100 d-block pt-1'
        src={`${process.env.REACT_APP_CDNURL}/images/20240418-top-mps-and-peers-screenshot1.png`}
        alt={'Top mps and peers screenshot'}
      />
    </div>
  );
};

export { EmptyStateRateLimit };
export default AnalyticsDashboardContainer;
