import React, { useEffect, useState } from 'react';
import { useHeightContainer } from '../../../Dashboard/utilities/useHeightContainer';
import CreateEmailAlert from '../../Topics/CreateEmailAlert';
import UpcomingEventsList from './UpcomingEventsList';
import ListOfEmailAlerts from './ListOfEmailAlerts';
import { Link } from 'react-router-dom-v5-compat';
import useIsMobile from '../../../Dashboard/utilities/useIsMobile';
import { useLocation } from 'react-router-dom-v5-compat';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import useTopicOrClientWord from '../../../Dashboard/hooks/useTopicOrClientWord';
import EmailAlertsForClients from './EmailAlertsForClients';
import createNotification from '../../Utilities/CreateNotification';
import getUrlParam from '../../../Dashboard/utilities/getUrlParam';

const EmailAlertsComponent = (props) => {
  const { clientsAlerts, allowAlerts, userEmail, clients, enableHandpickedContent } = props;
  const location = useLocation();
  /* AE: For now only for slack alerts, needs to be reviewed in a different case */
  const createAlertPage = location.pathname === '/settings/alerts/create';
  const { newAgencyPlan } = useTopicOrClientWord();
  const [isOpenEditEmailAlert, setIsOpenEditEmailAlert] = useState(createAlertPage && !newAgencyPlan);
  const [emailAlertId, setEmailAlertId] = useState('');
  const [teamId, setTeamId] = useState(null);
  const [teamTopics, setTeamTopics] = useState(null);

  const [expandedStates, setExpandedStates] = useState({
    alerts: true,
    upcomingEvents: true,
    majorAnnouncements: true,
  });
  const isOpenExpandedState = Object.keys(expandedStates)?.filter((item) => expandedStates[item])?.length > 0;
  const modifyExpandedStates = ({ property, value }) => {
    setExpandedStates({ ...expandedStates, [property]: value });
  };

  const [heightContainer, containerRef] = useHeightContainer();
  const isMobile = useIsMobile();

  const EmailAlertsSentText = () => {
    return (
      <p className='main-light-text d-flex align-items-center paragraph-p2 email-alerts-user-info mt-3 mt-lg-1'>
        <span>Email alerts will be sent to {userEmail}</span>{' '}
        <Link
          to='/settings/account'
          className='ml-2 general-button general-edit-button indented-button circle-button action-state-hover'
          data-tooltip-content={'Change your email address'}
          data-tooltip-id={'settings-tooltip'}
        >
          Editar
        </Link>
      </p>
    );
  };

  useEffect(() => {
    if (newAgencyPlan && createAlertPage && getUrlParam('alertType') && getUrlParam('alertType') === 'Slack') {
      createNotification('success', 'You have successfully set up an integration with Slack');
    }
  }, []);

  return (
    <div className='pt-4 pt-lg-5 settings-content-component email-alerts-page'>
      <div className='settings-box'>
        <div className='section-title padding-topic-component py-4'>
          <div className='row mx-0 justify-content-between align-items-center'>
            <div>
              <h3 className='main-title mb-0'>Alerts</h3>
              {!isMobile && <EmailAlertsSentText />}
            </div>
            {newAgencyPlan && (
              <button
                className={`general-button border collapse-expand-button action-state-hover mr-3 main-subtle-text ${
                  isOpenExpandedState ? '' : 'collapse-button'
                }`}
                onClick={() => {
                  let newExpandedStates = { ...expandedStates };
                  Object.keys(newExpandedStates)?.forEach((item) => {
                    newExpandedStates[item] = !isOpenExpandedState;
                  });
                  setExpandedStates(newExpandedStates);
                }}
              >
                {isOpenExpandedState ? 'Collapse' : 'Expand'} all
              </button>
            )}
          </div>
          {isMobile && <EmailAlertsSentText />}
        </div>

        <div ref={containerRef} />
        <CustomScrollbar
          className={'topics-scrollbar'}
          style={{ height: `${heightContainer}px` }}
          maximalThumbYSize={100}
        >
          <div className='pb-5'>
            {newAgencyPlan ? (
              <EmailAlertsForClients
                clientsAlerts={clientsAlerts}
                allowAlerts={allowAlerts}
                clients={clients}
                setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                setTeamId={setTeamId}
                setEmailAlertId={setEmailAlertId}
                setTeamTopics={setTeamTopics}
                stateExpanded={{ expandedStates, modifyExpandedStates }}
                enableHandpickedContent={enableHandpickedContent}
              />
            ) : (
              <ListOfEmailAlerts
                {...props}
                setEmailAlertId={setEmailAlertId}
                setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
                showTitle
                enableHandpickedContent={enableHandpickedContent}
              />
            )}
            <UpcomingEventsList stateExpanded={{ expandedStates, modifyExpandedStates }} {...props} />
          </div>
        </CustomScrollbar>
        {isOpenEditEmailAlert && (
          <CreateEmailAlert
            {...props}
            teamId={newAgencyPlan ? teamId : null}
            client={clients?.find((item) => item?.teamId === teamId)}
            emailAlertId={emailAlertId}
            setEmailAlertId={setEmailAlertId}
            setIsOpenEditEmailAlert={setIsOpenEditEmailAlert}
            customKeywordsLists={newAgencyPlan ? teamTopics : null}
          />
        )}
      </div>
    </div>
  );
};

export default EmailAlertsComponent;
