import React, { useState, useRef, useContext, useEffect } from 'react';
import { store } from '../../Store';
import { SideBarItems } from '../sidebar';
import useIsMobile from '../utilities/useIsMobile';
import useOutsideElement from '../utilities/useOutsideElement';
import TotalResults from './TotalResults';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import useDetectActiveFilters from '../SubNav/useDetectActiveFilters';
import TransparentBackgroundToPreventClick from './TransparentBackgroundToPreventClick';
import { useLocation, useParams } from 'react-router-dom-v5-compat';

const SectionTitle = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { search } = state;
  const { list } = search;

  const { sectionName } = props;
  const isMobile = useIsMobile();
  const [showBox, setShowBox] = useState(false);
  const [boxHeight, setBoxHeight] = useState();

  const actionsElement = useRef(null);
  const location = useLocation();
  const params = useParams();
  const hideActions = () => {
    setShowBox(false);
  };
  useOutsideElement(actionsElement, hideActions);

  const showItemDropdown = isMobile && !location.pathname.includes('/stakeholder-mapping');
  const { inboxSection } = useMainSectionParameter(params);
  const { detectActiveFilters } = useDetectActiveFilters();
  const mobileMenu = useRef();

  useEffect(() => {
    let height = window.innerHeight - mobileMenu?.current?.getBoundingClientRect()?.top - 20;
    setBoxHeight(height);
  }, [showBox]);

  return (
    <>
      {list !== 'noListsCreated' && (
        <>
          <TransparentBackgroundToPreventClick popupOpened={showBox} />
          <div
            className='content-lists-title position-relative flex-grow-1 d-flex flex-wrap justify-content-between pr-4 align-items-center'
            ref={actionsElement}
          >
            <div>
              <h3 className='my-lg-0 main-title mb-0'>
                <span
                  className={`font-weight-bold ${showItemDropdown ? 'dropdown-item-element pointer dropdown-item-element-main-title' : ''} ${showBox ? 'open' : ''}`}
                  onClick={() => {
                    if (showItemDropdown) {
                      setShowBox(!showBox);
                    }
                  }}
                >
                  {sectionName}
                </span>
              </h3>
              {showBox && (
                <>
                  <div className='main-title-popup'>
                    <div
                      className='login-navbar py-2 main-title-items overflow-auto scroll-container'
                      ref={mobileMenu}
                      style={{ maxHeight: `${boxHeight}px` }}
                    >
                      <SideBarItems closeMenu={hideActions} />
                    </div>
                  </div>
                </>
              )}
            </div>
            {inboxSection && detectActiveFilters() && <TotalResults />}
          </div>
        </>
      )}
    </>
  );
};

export default SectionTitle;
