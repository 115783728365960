import { Link, useLocation } from 'react-router-dom-v5-compat';
import Popup from '../../../../Common/PopUp';

const ContentPopup = (props) => {
  const { proMessage } = props;
  const location = useLocation();
  return (
    <>
      <div className='p-5 border-bottom'>
        <p className='mb-0 mt-4'>
          To perform this search, please upgrade to a {proMessage ? '' : 'paid'} Mogul or Enterprise subscription.
        </p>
      </div>
      <Link to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`} className='py-3 d-block text-center'>
        <button className='px-3 py-2 action-button general-button'>Compare plans</button>
      </Link>
    </>
  );
};

const NoEnableUsersPopup = (props) => {
  return (
    <Popup {...props}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default NoEnableUsersPopup;
