import EventsGrouped from './EventsGroups';

const EventsGroupedByDate = (events, feedItemFlags) => {
  const dates = [];
  events.forEach((item, index) => {
    if (!dates.includes(item.eventStartDate)) {
      dates.push(item.eventStartDate);
    }
  });
  events.forEach((item, index) => {
    if (feedItemFlags) {
      return (item.feedItemFlags = feedItemFlags[index]);
    }
  });
  const Events = {};
  dates.forEach((item) => {
    Events[item] = [];
  });
  events.forEach((item) => {
    Events[item.eventStartDate].push(item);
  });
  return Events;
};

const EventsCategoriesInsideDates = (events, feedItemFlags) => {
  const eventsGrouped = EventsGroupedByDate(events, feedItemFlags);
  for (var i in eventsGrouped) {
    eventsGrouped[i] = EventsGrouped(eventsGrouped[i]);
  }
  return eventsGrouped;
};

export default EventsCategoriesInsideDates;
