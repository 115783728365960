import { userTypes } from '../Login/CreateProfile/CreateProfileFirstStep';
import changeOrganisationLabel from '../Login/CreateProfile/changeOrganisationLabel';

const TeamUserFields = [
  {
    name: 'email',
    fieldName: 'Email address',
    type: 'text',
    value: '',
    required: true,
    validationRule: (val) => val.includes('@'),
    validationMessage: 'Please enter a valid email',
    page: ['team', 'user'],
  },
  {
    name: 'firstName',
    fieldName: 'First name',
    type: 'text',
    value: '',
    required: true,
    page: ['team', 'user'],
  },
  {
    name: 'lastName',
    fieldName: 'Last name',
    type: 'text',
    value: '',
    required: true,
    page: ['team', 'user'],
  },
  {
    name: 'userType',
    fieldName: 'User type',
    type: 'select',
    options: userTypes,
    value: '',
    placeholder: 'Choose your user type',
    required: true,
    page: ['team'],
  },
  {
    name: 'organisation',
    fieldName: 'Organisation name',
    type: 'text',
    value: '',
    required: true,
    page: ['team'],
  },
  {
    name: 'teamRole',
    fieldName: 'Role',
    type: 'select',
    value: 'Admin',
    options: [{ name: 'Admin' }, { name: 'Team member', value: 'Member' }],
    required: true,
    createUser: true,
    page: ['user'],
  },
  {
    name: 'phoneNumber',
    fieldName: 'Phone number',
    type: 'text',
    value: '',
    required: false,
    page: ['team', 'user'],
  },
];

const CompileUserFields = (fields) => {
  const newFields = [...fields];
  let userTypeField = newFields.find((item) => item.name === 'userType');
  let organisationField = newFields.find((item) => item.name === 'organisation');
  organisationField.fieldName = changeOrganisationLabel(userTypeField.value);
  return newFields;
};

export { TeamUserFields, CompileUserFields };
