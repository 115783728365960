import { useContext } from 'react';
import { store } from '../../Store';
import { useAuth0 } from '@auth0/auth0-react';

const useAnalyticsIntervalLimit = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;

  const { activePlan, analyticsState } = state;
  const { enableAnalytics } = activePlan;

  const { isAuthenticated } = useAuth0();

  const validateDataForLoggedOutUsers = (data) => {
    if (data?.status === 429 && !analyticsState?.restrictPanel) {
      let newState = { ...analyticsState, restrictPanel: true, sideBarText: 'Unlimited searches' };
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'analyticsState',
        value: newState,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'analyticsRateLimitReached',
        value: true,
      });
      return false;
    }
    return true;
  };

  const conditionOfPublicApi = (isAuthenticated && !enableAnalytics) || !isAuthenticated;
  return { validateDataForLoggedOutUsers, conditionOfPublicApi };
};

export default useAnalyticsIntervalLimit;
