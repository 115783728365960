import React, { useContext } from 'react';
import { store } from '../../Store.js';
import createUtmParameters from '../helpers/createUtmParameters.js';
const GimmickPopUp = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { gimmickWord } = state;
  const word = gimmickWord;
  let wordToCompare = word.toLowerCase().trim();
  let mainText = () => {
    switch (wordToCompare) {
      case 'test':
        return 'If you really want to test us, start your free trial';
      case 'hola':
      case 'hello':
      case 'hi':
        let wordToUppercase = word.charAt(0).toUpperCase() + word.slice(1);
        return `${wordToUppercase} yourself. What’s your name? Sign up for free and let us know`;
      default:
        return `Power up your public affairs work with PolicyMogul`;
    }
  };
  return (
    <div className='modal micromodal-slide' id='popup-gimmick' aria-hidden='true'>
      <div className='modal__overlay gimmick-popup' tabIndex='-1' data-micromodal-close>
        <div className='modal__container' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <div className='preregister-close px-2' data-micromodal-close>
            <img
              src={`${process.env.REACT_APP_CDNURL}/images/preregister-close.svg`}
              alt='close-icon'
              data-micromodal-close
            />
          </div>

          <div className='p-3 px-lg-5 pt-5 pb-lg-5'>
            <div className='micromodal-title px-0 px-lg-3'>
              <h2 className='text-center mt-4 mt-lg-2'>{mainText()}</h2>
            </div>
            <main className='modal__content mt-2 mb-3'>
              <ul className='policy-list px-0 pb-0 mb-0 mx-auto gimmick-list my-5 gimmick-list-home'>
                <li>Monitor the policy landscape with unlimited keywords</li>
                <li>Keep up to date with email alerts</li>
                <li>Identify relevant political stakeholders</li>
              </ul>
              <button
                className='general-button main-button px-5 py-2 d-block mx-auto'
                onClick={() => {
                  window.location.assign(
                    `/register?reg_source=${wordToCompare === 'test' ? 'test-' : ''}search-popup${createUtmParameters('&')}`
                  );
                }}
              >
                Start your free trial
              </button>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GimmickPopUp;
