import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import getContactsCount from '../../../Settings/Utilities/getContactCount';
import useGetAccessToken from '../../apiCalls/useGetAccessToken';

const useGetContactsQntty = () => {
  const [contactsQntty, setContactsQntty] = useState(null);
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const location = useLocation();

  const getContactsCountFunc = async (source) => {
    if (isAuthenticated) {
      let results = await getContactsCount(getAccessToken, source);
      if (results) {
        setContactsQntty(results);
      }
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    try {
      getContactsCountFunc(source);
    } catch (e) {}
    return () => {
      source.cancel('Contacts count canceled by the user.');
    };
  }, [location.pathname]);
  return contactsQntty;
};
export default useGetContactsQntty;
