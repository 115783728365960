import React from 'react';
import { useContext } from 'react';
import { store } from '../../../Store';
import IndividualContentStatistics from '../../UserContent/ui/IndividualContentStatistics';
import { useHeightContainer } from '../../utilities/useHeightContainer';
import useGetEngagementStatisticsItem from './useGetEngagementStatisticsItem';
import NoResultsTeam from '../../../Settings/TeamContent/NoResultsTeam';
import CustomScrollbar from '../../../Common/CustomScrollbar';

function EngagementStatistics() {
  const [heightContainer, containerRef] = useHeightContainer();
  const globalState = useContext(store);
  const { state } = globalState;
  const { engagementStatisticsResult, engagementStatisticsOnLoad } = state;

  const { item } = useGetEngagementStatisticsItem();

  return (
    <>
      {engagementStatisticsResult.length > 0 || engagementStatisticsOnLoad ? (
        <div className='engagement-statistics-influence-section main-content pr-xl-5 px-md-4 px-3 h-100'>
          <div className='engagement-statistics-container bg-white'>
            <div ref={containerRef} />
            <CustomScrollbar
              className={'engagement-statistics-scrollbar'}
              maximalThumbYSize={100}
              style={{ height: `${heightContainer}px` }}
            >
              <IndividualContentStatistics item={item} engagementStatisticsOnLoad={engagementStatisticsOnLoad} />
            </CustomScrollbar>
          </div>
        </div>
      ) : (
        <div className='flex-grow-1'>
          <div className='pl-md-4 pl-3'>
            <div ref={containerRef} />
            <div
              className={'simple-scrollbar'}
              style={{
                height: `${heightContainer}px`,
                marginLeft: '-8px',
              }}
              /* maximalThumbYSize={100} */
            >
              <div className={`pt-2 pr-lg-4 px-2`}>
                <NoResultsTeam
                  text={'Once you publish lobbying material, engagement statistics from policymakers will appear here'}
                  totalResults={[]}
                  results={[]}
                  activeFilters={[]}
                  filtersOptions={[]}
                  applyFilters={[]}
                  checkedFilters={[]}
                  influenceSection={true}
                  engagementStatistics={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EngagementStatistics;
