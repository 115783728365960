import React, { useContext, useState } from 'react';
import { store } from '../../Store';
import { sectionName } from '../helpers/controlActiveFilters';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import getUrlParam from '../utilities/getUrlParam';
import thousandCommas from '../helpers/thousandCommas';
import normalize from '../utilities/normalizeString';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import { useAuth0 } from '@auth0/auth0-react';
import CreateAccountPopup from './CreateAccountPopup';
import useGetSectionFilters from '../Filters/useGetSectionFilters';
import { Link, useLocation, useParams } from 'react-router-dom-v5-compat';
const TotalResults = ({ onlyNumber }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { unseenNumbersCurrentList, contentResults, activeResults, activePlan, totalNumbers } = state;
  const { enableReferenceSection } = activePlan;
  const section = sectionName();
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const location = useLocation();
  const params = useParams();
  const { isAuthenticated } = useAuth0();

  const { inboxSection, searchResultsSection } = useMainSectionParameter(params);

  const getUnseenName = () => {
    switch (section) {
      case 'KeyUpdates':
      case 'Consultations':
      case 'Events':
        return section.substring(0, section.length - 1);
      case 'UpcomingEvents':
        return 'Event';
      case 'Tweets':
        return inboxSection ? 'Tweet' : 'Tweets';
      case 'LibraryMaterial':
        return 'CommonsLibraryArticle';
      case 'LobbyingMaterial':
        return 'UserContent';
      default:
        return section;
    }
  };

  const unseenNumber = unseenNumbersCurrentList[getUnseenName()];

  const { getSectionFilters } = useGetSectionFilters(sectionName());
  const sectionFilters = getSectionFilters();

  const { filters } = sectionFilters ?? {};
  const excludeRetweets = filters?.find((item) => item.field === 'isRetweet' && item.value === 'false');
  const StakeholderSection = contentResults[activeResults].Stakeholders;

  return (
    <>
      {!!unseenNumber && unseenNumber > 0 && inboxSection && (
        <div className={`position-relative total-results-container flex-centered`}>
          <p className='mb-0 main-light-text'>
            {unseenNumber >= 10000 ? '9,999+' : thousandCommas(unseenNumber)} unread
            {!!excludeRetweets ? ', including Retweets' : ''}
          </p>
        </div>
      )}
      {searchResultsSection && totalNumbers[sectionName()] ? (
        <p
          className={`mb-0 main-light-text ${totalNumbers[sectionName()] >= 10000 ? 'nowrap-item total-result-more-1000' : ''}`}
        >
          {totalNumbers[sectionName()] > 3 ? (
            <>
              {!enableReferenceSection && <>Showing the top 3 of </>}
              <Link
                to={`/reference/${normalize(
                  TransformFromPascalCase(section)
                )}?search=${encodeURIComponent(getUrlParam('search'))}`}
                className='text-decoration-none default-cursor'
                onClick={(e) => {
                  e.preventDefault();
                  if (!isAuthenticated) {
                    setShowRegisterPopup(true);
                  }
                }}
              >
                <span
                  className={`${
                    !enableReferenceSection ? 'blue-text pointer text-underline-hover' : 'main-light-text'
                  }`}
                >
                  {totalNumbers[sectionName()] >= 10000 ? '9,999+' : thousandCommas(totalNumbers[sectionName()])} result
                  {totalNumbers[sectionName()] === 1 ? '' : 's'}
                </span>
              </Link>
            </>
          ) : (
            `${totalNumbers[sectionName()]} result${totalNumbers[sectionName()] === 1 ? '' : 's'}`
          )}
        </p>
      ) : (
        ''
      )}
      {(location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers')) &&
      StakeholderSection &&
      StakeholderSection?.totalHits ? (
        <>
          {!onlyNumber ? (
            <p className='mb-0 main-light-text'>
              {StakeholderSection?.totalHits >= 10000 ? '9,999+' : thousandCommas(StakeholderSection?.totalHits ?? '')}{' '}
              {!onlyNumber ? ` result${StakeholderSection?.totalHits === 1 ? '' : 's'}` : ''}
            </p>
          ) : (
            <span>
              {StakeholderSection?.totalHits >= 10000 ? '9,999+' : thousandCommas(StakeholderSection?.totalHits ?? '')}
            </span>
          )}
        </>
      ) : (
        ''
      )}

      {showRegisterPopup && !isAuthenticated && (
        <CreateAccountPopup
          text={'You’ll need to create an account to see all results'}
          setShowPopup={setShowRegisterPopup}
        />
      )}
    </>
  );
};

export default TotalResults;
