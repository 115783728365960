const transformGroup = (type) => {
  switch (type) {
    case 'Select Committees':
      return 'Select Committee';
    case 'Lords Select Committees':
      return 'Lords Select Committee';
    case 'All Party Country Groups':
      return 'All-Party Parliamentary Group';
    case 'All Party Groups':
      return 'APPG';
    default:
      return '';
  }
};

export default transformGroup;
