import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import SkeletonItemTwitter from './SkeletonItemTwitter';

const SkeletonTwitter = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <ul className='px-0 policy-list context'>
        {Array(9)
          .fill()
          .map((item, index) => (
            <SkeletonItemTwitter key={`item${index}`} />
          ))}
      </ul>
    </SkeletonTheme>
  );
};
export default SkeletonTwitter;
