export default function insertTrackableLinkPopup(dialog, dialogBackdrop, editor, descriptionEditor) {
  if (dialogBackdrop?.length) {
    dialogBackdrop[0].style.opacity = 1;
    const onclick = () => {
      const linkInputValue =
        dialog[0].children[1].children[0].children[0].children[0].children[0].children[1].children[0].children[0].value;
      const textInputValue = dialog[0].children[1].children[0].children[0].children[0].children[1].children[1].value;

      if (!textInputValue.length && !linkInputValue.length) {
        editor.windowManager.close();
      }
    };
    dialogBackdrop[0].onclick = onclick;
  }
  if (dialog?.length) {
    dialog[0].children[1].children[0].children[0].children[0].children[1].children[0].innerHTML = 'Text';
    dialog[0].children[1].children[0].children[0].children[0].children[0].children[0].innerHTML = 'Link';
    dialog[0].children[0].children[0].innerHTML = `${descriptionEditor ? 'Insert link' : 'Insert trackable link'}  ${
      descriptionEditor
        ? ''
        : '<p style="color:rgba(0, 18, 43, 0.6); font-weight: 400; font-size: 16px; margin-bottom: -4px;">Recipient clicks will be included in performance metrics</p>'
    }`;
    dialog[0].style.opacity = 1;
  }
}
