import React, { useState, useContext, useEffect, useRef } from 'react';
import { store } from '../../Store.js';
import { useLocation, Link, NavLink, useParams } from 'react-router-dom-v5-compat';
import LoginButtons from './login-buttons';
import { useAuth0 } from '@auth0/auth0-react';
import useIsMobile from '../utilities/useIsMobile';
import CreateKeywordListButton from './CreateKeywordListButton';
import TrialBanner from './TrialBanner.js';
import ProfileButton from './ProfileButton.js';
import NavPages, { useResearchItemsUrl } from './NavPages.js';
import MainSearchBar from '../MainSearchBar/index.js';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import SavedItemsIcon, { useCloseButtonFunctionality } from '../../SavedItems/ui/SavedItemsIcon.js';
import getUrlParam from '../utilities/getUrlParam.js';
import useResetReference from '../SubNav/useResetReference.js';
import { SentimentButtons } from '../Sentiment/SentimentGraphPage.js';
import ProspectiveButton from '../../Reports/ProspectiveButton.js';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';
import parliamentarianUsers from '../sidebar/parliamentarianUsers.js';
import NavigationSidebar from './NavigationSideBar.js';
import ClientNavigation, { useResetClientNavigation } from './ClientNavigation.js';

const Navigation = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, isHiddenMenu, readyKeywordLists, activePlan } = state;
  const { code, enableReports } = activePlan;

  const location = useLocation();
  const isMobile = useIsMobile();
  const params = useParams();
  const { inboxSection, searchResultsSection } = useMainSectionParameter(params);

  const { isAuthenticated } = useAuth0();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { policymakerUrl } = useResearchItemsUrl();
  const { closeButtonFunctionality } = useCloseButtonFunctionality();
  const { resetClientNavigation } = useResetClientNavigation();

  //CREATE FIXED POSITIONS
  useEffect(() => {
    const createFixed = () => {
      const html = document.getElementsByTagName('html')[0];
      let position;
      let classHeader;
      let height;
      let classFavicon;
      if (isFixedPathName()) {
        position = 'fixed';
        height = `${headerContainer.current?.getBoundingClientRect()?.height}px`;
        if (html.scrollTop > 50) {
          classHeader = 'header-scrolled';
        } else {
          classHeader = '';
        }
        if (html.scrollTop > 700) {
          classFavicon = 'logo-header-favicon';
        } else {
          classFavicon = '';
        }
      } else {
        position = 'relative';
        height = null;
      }

      setPositonNav({
        position,
        classHeader,
        height,
        classFavicon,
      });
    };
    document.addEventListener('scroll', createFixed);
    // AE: WE CAN KEEP THIS HERE BECAUSE IS A COMMON COMPONENT OF THE DASHBOARD, TO DO A BETTER LOGIC IT SHOULD BE MOVED TO A BETTER PLACE
    return () => {
      document.removeEventListener('scroll', createFixed);
      let stateForResults = { ...contentResults, searchResults: {} };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: stateForResults });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'unseenNumbersCurrentList', value: {} });
    };
  }, []);

  const fixedPathNames = ['/', '/pricing', '/for-parliamentarians'];
  const [positionNav, setPositonNav] = useState({ position: 'relative', classHeader: '', classFavicon: '' });
  const isFixedPathName = () => {
    let isFixed = false;
    fixedPathNames.forEach((item) => {
      if (item === location.pathname) {
        isFixed = true;
      }
    });
    return isFixed;
  };

  const hamburguerMenu = useRef(null);
  const headerContainer = useRef(null);
  const toggleMenu = () => {
    dispatch({ type: 'SHOW_HIDE_MENU', value: !isHiddenMenu });
  };

  const calculateTransform = () => {
    let topReference = !!headerContainer.current ? headerContainer.current.getBoundingClientRect().top : 0;
    return `-${topReference}px`;
  };

  const sentimentPage = location.pathname.toLowerCase() === '/sentiment';
  const slackIntegrationPage = location.pathname.toLowerCase() === '/integration-with-slack';
  const noSpecificPages = !sentimentPage && !slackIntegrationPage;

  const logoUrl = `${slackIntegrationPage ? '/' : isAuthenticated ? `/monitor-inbox/key-updates${inboxSection ? location.search : ''}${location.hash}` : '/'}`;
  const { resetReference } = useResetReference();
  const validPathname = () => {
    const excludePathnames = ['/saved-items', '/sentiment', '/integration-with-slack'];
    for (let i = 0; i < excludePathnames.length; i++) {
      let path = excludePathnames[i];
      if (location.pathname.includes(path)) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <TrialBanner />
      <header
        className={`header-logout ${!isMobile ? positionNav.classHeader : ''} ${isAuthenticated ? 'header-user-logged' : 'header-user-logout'}`}
        style={{ position: positionNav.position }}
        ref={headerContainer}
      >
        <>
          {/*LOGO ON MOBILE */}
          <div
            className={`hide-lg px-3 pb-lg-3 header-mobile row mx-0 align-items-center ${isAuthenticated || sentimentPage || slackIntegrationPage ? 'justify-content-between ' : ''} mobile-loggedout-header`}
          >
            <div
              className={`close-menu container-close-menu hide-lg ${isHiddenMenu ? 'd-none' : 'd-block'}`}
              onClick={() => {
                dispatch({ type: 'SHOW_HIDE_MENU', value: true });
                hamburguerMenu.current.classList.remove('is-active');
              }}
            />
            {noSpecificPages && (
              <>
                <button
                  className={`d-lg-none hamburger hamburger--collapse general-button`}
                  type='button'
                  ref={hamburguerMenu}
                  onClick={() => {
                    toggleMenu();
                    hamburguerMenu.current.classList.toggle('is-active');
                  }}
                  style={{
                    transform: `translateY(${isHiddenMenu ? 0 : calculateTransform()})`,
                  }}
                >
                  <span className='hamburger-box'>
                    <span className='hamburger-inner'></span>
                  </span>
                </button>
              </>
            )}

            <div className={` ${noSpecificPages ? 'flex-grow-1 text-center' : ''}`}>
              <Link to={logoUrl} className={noSpecificPages ? '' : 'd-lg-inline-block'}>
                <img
                  src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                  alt='sidebar-icon'
                  className={`${noSpecificPages ? 'logo' : 'policy-logo'}`}
                />
              </Link>
            </div>

            {isMobile && (
              <>
                {sentimentPage || slackIntegrationPage ? (
                  <SentimentButtons slackIntegrationPage={slackIntegrationPage} />
                ) : isAuthenticated ? (
                  <ProfileButton />
                ) : (
                  ''
                )}
              </>
            )}
          </div>

          {isMobile && (
            <div className='header-mobile-sidenav'>
              <NavigationSidebar />
            </div>
          )}

          {/*NAV PAGES */}
          <div className='mx-auto main-content-wrapper row mx-auto align-items-center justify-content-between nav-wrapper-container resize-elements-container'>
            <div className='pl-md-5 pl-3 show-lg side-left-element'>
              {positionNav.classHeader === 'header-scrolled' ? (
                <div className='logo-header-scrolled-container'>
                  <Link to={logoUrl} className={`d-lg-inline-block logo-header-scrolled ${positionNav.classFavicon}`}>
                    <img
                      className='logo-part logo-part-1'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-1.svg`}
                      alt='logo part 1'
                    />
                    <img
                      className='logo-part logo-part-2'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-2.svg`}
                      alt='logo part 2'
                    />
                    <img
                      className='logo-part logo-part-3'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-3.svg`}
                      alt='logo part 3'
                    />
                    <img
                      className='logo-part logo-part-4'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-4.svg`}
                      alt='logo part 4'
                    />
                  </Link>
                </div>
              ) : (
                <Link
                  onClick={() => {
                    resetClientNavigation();
                  }}
                  to={logoUrl}
                  className='d-lg-inline-block'
                >
                  <img
                    src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                    alt='Logo'
                    className='policy-logo'
                  />
                </Link>
              )}
            </div>

            <div className='main-content flex-grow-1 header-container d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row pr-xl-5 px-md-4 px-3'>
              {!isMobile && <>{noSpecificPages && <>{newAgencyPlan ? <ClientNavigation /> : <NavPages />}</>}</>}
              {isMobile && validPathname() && (
                <div
                  className={`w-100 ${inboxSection || location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers') ? 'pt-3 d-block' : 'd-none'}`}
                >
                  <MainSearchBar />
                </div>
              )}

              <div className={`flex-centered flex-grow-1 justify-content-end `}>
                {/* SEARCHBAR MODIFIED JUST FOR THE HEADER */}
                {noSpecificPages && (
                  <>
                    {(readyKeywordLists || (!isAuthenticated && location.pathname !== '/')) && (
                      <Link
                        className={`simple-searchbar mr-lg-3 flex-centered ${searchResultsSection ? 'simple-searchbar-active' : ''}`}
                        to={'/search-results/key-updates'}
                        data-tooltip-content={'Search'}
                        data-tooltip-id={'general-tooltip'}
                        onClick={() => {
                          if (getUrlParam('search') || getUrlParam('or')) {
                            resetReference();
                          }
                          closeButtonFunctionality();
                        }}
                      >
                        <span className='icon-search-medium' />
                      </Link>
                    )}
                    {readyKeywordLists && isAuthenticated && (
                      <>
                        {parliamentarianUsers(code) && (
                          <NavLink
                            to={policymakerUrl}
                            className={({ isActive }) => `calendar-top-nav-icon mr-3 ${isActive ? 'active' : ''}`}
                            data-tooltip-content={location.pathname.startsWith('/policymakers') ? '' : 'Policymakers'}
                            data-tooltip-id={'general-tooltip'}
                          >
                            <span className='icon-team' />
                          </NavLink>
                        )}
                        <NavLink
                          to='/calendar'
                          className={({ isActive }) => `calendar-top-nav-icon mr-3 ${isActive ? 'active' : ''}`}
                          data-tooltip-content={location.pathname.startsWith('/calendar') ? '' : 'Calendar'}
                          data-tooltip-id={'general-tooltip'}
                          onClick={() => {
                            closeButtonFunctionality();
                          }}
                        >
                          <span className='icon-calendar' />
                        </NavLink>
                        {!isMobile && (
                          <>
                            <SavedItemsIcon />
                            {agencyUser && isAuthenticated && enableReports && <ProspectiveButton />}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <CreateKeywordListButton />
                <LoginButtonsComponent sentimentPage={sentimentPage} slackIntegrationPage={slackIntegrationPage} />
              </div>
            </div>
          </div>
        </>
      </header>
      <div style={{ height: positionNav.height }} />
    </>
  );
};

const LoginButtonsComponent = (props) => {
  const isMobile = useIsMobile();
  const { isAuthenticated } = useAuth0();
  const { sentimentPage, slackIntegrationPage } = props;

  return (
    <>
      {!isMobile && (
        <>
          {sentimentPage || slackIntegrationPage ? (
            <SentimentButtons slackIntegrationPage={slackIntegrationPage} />
          ) : (
            <>{isAuthenticated ? <ProfileButton /> : <LoginButtons />}</>
          )}
        </>
      )}
    </>
  );
};
export default Navigation;
