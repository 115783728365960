import { useParams } from 'react-router-dom-v5-compat';
import ScrollbarList from '../../Common/ScrollbarList';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import SkeletonInfluenceList, { SkeletonInfluenceSingleItem } from '../Influence/skeletons/SkeletonInfluenceList';
import LegislationItem from '../Legislation/LegislationItem';
import SkeletonLegislation, { SkeletonLegislationItem } from '../Legislation/SkeletonLegislation';
import LibraryMaterialItem from '../LibraryMaterial/LibraryMaterialItem';
import ParliamentaryItem from '../Parliamentary_record/ParlamentaryItem';
import SkeletonParliamentary, { SkeletonParliamentaryItem } from '../Parliamentary_record/SkeletonParliamentary';
import WaitingAnimationScreen from '../StakeHolders/WaitingAnimationScreen';
import { createCalendarRenderElements } from '../Upcoming_events/CalendarSearchRenderFunctions';
import SkeletonCalendarSearch from '../Upcoming_events/SkeletonCalendarSearch';
import InfluenceItem from '../UserContent/ui/InfluenceListItem';
import EmptyMessage from '../components/EmptyMessage';
import ResearchPortalButton from '../components/ResearchPortalButton';
import SkeletonConsultation, { SkeletonConsultationItem } from '../consultations/SkeletonConsultation';
import ConsultationItem from '../consultations/consultationItem';
import { sectionName as rawSectionName, createSectionName } from '../helpers/controlActiveFilters';
import ItemNew from '../key_updates/ItemNew';
import SkeletonKeyUpdates, { SkeletonItem } from '../key_updates/SkeletonKeyUpdates';
import SkeletonItemTwitter from '../social_media/SkeletonItemTwitter';
import SkeletonTwitter from '../social_media/SkeletonTwitter';
import TweetItem from '../social_media/TweetItem';
import normalize from '../utilities/normalizeString';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';

const GeneralList = (props) => {
  const { isLoading, createResults, currentlyProcessing, elements, section, scrollBarRef } = props;
  const sectionName = createSectionName();
  const params = useParams();
  const { inboxSection } = useMainSectionParameter(params);
  return (
    <div className={`col-16 px-0`}>
      {isLoading && (
        <>
          {(() => {
            switch (sectionName) {
              case 'KeyUpdates':
                return <SkeletonKeyUpdates />;
              case 'Consultations':
                return <SkeletonConsultation />;
              case 'Legislation':
                return <SkeletonLegislation />;
              case 'ParliamentaryRecord':
                return <SkeletonParliamentary />;
              case 'UpcomingEvents':
                return <SkeletonCalendarSearch />;
              case 'Influence':
                return <SkeletonInfluenceList influencePage={false} />;
              case 'SocialMedia':
                return <SkeletonTwitter />;
              default:
                return <SkeletonKeyUpdates />;
            }
          })()}
        </>
      )}
      {!isLoading && (
        <>
          {currentlyProcessing.state === false && inboxSection ? (
            <WaitingAnimationScreen section={section} />
          ) : (
            <>
              {section?.loaded && (
                <>
                  {elements.length === 0 ? (
                    <EmptyMessage
                      resourceName={
                        rawSectionName() === 'ParliamentaryRecord'
                          ? 'updates'
                          : TransformFromPascalCase(rawSectionName())?.toLowerCase()
                      }
                      filtersFunction={createResults}
                    />
                  ) : (
                    <>
                      <ScrollbarList ref={scrollBarRef} createResults={createResults}>
                        {sectionName === 'UpcomingEvents' ? (
                          <div className='py-2'>
                            {createCalendarRenderElements({ elements, feedItemFlags: section?.feedItemFlags })}
                          </div>
                        ) : (
                          <ul className='policy-list px-0 items-list pb-2 all-open-consultations'>
                            {elements.map((item, index, array) => {
                              const commonProperties = {
                                item,
                                array,
                                index,
                                scrollBarRef,
                                listView: true,
                                openInNewTab: sectionName === 'ElectionKeyUpdates',
                              };
                              switch (sectionName) {
                                case 'KeyUpdates':
                                case 'ElectionKeyUpdates':
                                  return (
                                    <ItemNew
                                      key={`item-${normalize(sectionName)}-${index}`}
                                      switchRelevantExtractOrSummary={true}
                                      {...commonProperties}
                                    />
                                  );
                                case 'Consultations':
                                  return (
                                    <ConsultationItem
                                      {...commonProperties}
                                      key={`item-${normalize(sectionName)}-${index}`}
                                      includeTags={true}
                                      title={item?.departmentName}
                                      isAll={!section.totalHits}
                                    />
                                  );
                                case 'Legislation':
                                  return (
                                    <LegislationItem
                                      key={`item-${normalize(sectionName)}-${index}`}
                                      {...commonProperties}
                                      legislationType={item?.contentType}
                                    />
                                  );
                                case 'ParliamentaryRecord':
                                  return (
                                    <ParliamentaryItem
                                      key={`item-${normalize(sectionName)}-${index}`}
                                      {...commonProperties}
                                      numberOfContributions={section?.collapsedHits[index]?.totalHits}
                                    />
                                  );
                                case 'LibraryMaterial':
                                  return (
                                    <LibraryMaterialItem
                                      key={`item-${normalize(sectionName)}-${index}`}
                                      {...commonProperties}
                                      feedItemFlags={section?.feedItemFlags}
                                    />
                                  );
                                case 'Influence':
                                  return (
                                    <InfluenceItem
                                      key={`item-${normalize(sectionName)}-${index}`}
                                      {...commonProperties}
                                      feedItemFlags={section?.feedItemFlags}
                                    />
                                  );
                                case 'SocialMedia':
                                  return (
                                    <TweetItem key={`item-${normalize(sectionName)}-${index}`} {...commonProperties} />
                                  );
                                default:
                                  return '';
                              }
                            })}
                            {section?.thereIsMoreContent && (
                              <>
                                {(() => {
                                  switch (sectionName) {
                                    case 'KeyUpdates':
                                      return <SkeletonItem />;
                                    case 'Consultations':
                                      return <SkeletonConsultationItem />;
                                    case 'Legislation':
                                      return <SkeletonLegislationItem />;
                                    case 'ParliamentaryRecord':
                                      return <SkeletonParliamentaryItem />;
                                    case 'Influence':
                                      return <SkeletonInfluenceSingleItem />;
                                    case 'SocialMedia':
                                      return <SkeletonItemTwitter />;
                                    default:
                                      return <SkeletonItem />;
                                  }
                                })()}
                              </>
                            )}
                          </ul>
                        )}
                        <ResearchPortalButton />
                      </ScrollbarList>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GeneralList;
