export const createTagFilterOption = (array, tagIsNot) => {
  return array.map((tag) => {
    if (tag.selected === 'exclude' && tagIsNot) {
      return { name: tag.value, selected: true, value: tag.value };
    } else if (tag.selected === true && !tagIsNot) {
      return { name: tag.value, selected: true, value: tag.value };
    } else {
      return { name: tag.value, value: tag.value, hidden: true };
    }
  });
};

const InfluenceContactsFilters = [
  {
    name: 'Tag is',
    pages: ['InfluenceContacts'],
    searchState: 'always',
    filterName: 'tags',
    additionalItemsText: 'tags',
    notUseFromLocalStorage: true,
    customOrder: 0,
    prefix: 'tag',
    options: [],
    updateFilter: true,
  },
  {
    name: 'Tag is not',
    pages: ['InfluenceContacts'],
    type: 'TagIsNot',
    searchState: 'always',
    filterName: 'tags',
    prefix: 'tag-is-not',
    notUseFromLocalStorage: true,
    additionalItemsText: 'tags',
    customOrder: 1,
    options: [],
    updateFilter: true,
    operator: 'str_not_eq',
  },
  {
    name: 'Added by',
    pages: ['InfluenceContacts'],
    type: 'membership',
    subtype: 'ContactAddedByUser',
    searchState: 'always',
    filterName: 'addedByUserId',
    customOrder: 5,
    options: [],
    prefix: 'added-by',
    optionSelected: '',
  },
  {
    name: 'Date added',
    searchState: 'always',
    pages: ['InfluenceContacts'],
    type: 'addedOn',
    optionSelected: '',
    filterName: 'addedOn',
    prefix: 'added-on',
    options: [
      {
        name: 'Before',
        filterProperty: 'DATE_LT',
        showCalendar: true,
      },
      {
        name: 'After',
        filterProperty: 'DATE_GTE',
        showCalendar: true,
      },
      {
        name: 'Custom',
        hash: 'between',
        filterProperty: 'range',
        showCalendar: true,
      },
    ],
  },
  {
    name: 'Champion status',
    pages: ['InfluenceContacts'],
    searchState: 'always',
    filterName: 'championStatus',
    optionSelected: '',
    options: [
      {
        name: 'Promoter',
      },
      {
        name: 'Detractor',
      },
      {
        name: 'Neutral',
      },
      {
        name: 'No status',
        filterValue: null,
        operator: 'not_exists',
      },
    ],
    prefix: '',
    updateFilter: true,
  },
];

export default InfluenceContactsFilters;
