import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import ContentItemsPanel from './ui/ContentItemsPanel';
import useRightPanelIndividualCall from './useRightPanelIndividualCall';
import getUrlParam from '../utilities/getUrlParam';
import { useChartDataOptionSelected } from './ui/MentionsByTopic/ChartDataTable';

const MentionsRightPanel = (props) => {
  const { contentState, analyticsState, setRightPanelState, createSectionValues } = props;
  const { text, dataKey, noResults } = contentState;

  const { chartOptionName } = useChartDataOptionSelected();
  const totalCell = dataKey === 'totalCell';
  const contributionGraph = chartOptionName === 'Contribution type' || chartOptionName === 'Overall';

  const sectionValues = createSectionValues(contentState);

  const [activeSection, setActiveSection] = useState(
    contributionGraph && !totalCell ? dataKey : sectionValues[0]?.name
  );
  const [isLoading, setIsLoading] = useState(!noResults);
  const [results, setResults] = useState([]);

  const callContentItems = useRef();
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const isLoadedComponent = useRef(false);
  const searchParameter = getUrlParam('search');

  const { individualApiCall } = useRightPanelIndividualCall({ analyticsState, contentState });

  callContentItems.current = async ({ type = activeSection, pageNumber, source }) => {
    try {
      if (noResults) {
        setResults({ hits: [] });
        setIsLoading(false);
      } else {
        if (!pageNumber) {
          setIsLoading(true);
        }
        let snapshot = !!contentState?.sentiment;
        let callresults = await individualApiCall({
          type,
          pageNumber,
          snapshot,
          freeSearch: !!searchParameter ? decodeURIComponent(searchParameter) : null,
          source,
        });
        if (callresults) {
          let hits = pageNumber ? results.hits : [];
          let highlights = pageNumber ? results.highlights : [];

          hits = hits.concat(callresults.hits);
          highlights = highlights.concat(callresults.highlights);

          setResults({ ...callresults, hits, highlights, thereIsMoreContent: callresults.hits?.length === pageSize });
          setIsLoading(false);
        }
      }
      isLoadedComponent.current = true;
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callContentItems.current({ source });
    return () => {
      source.cancel('Content items panel cancelled by the user');
    };
  }, [activeSection]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (isLoadedComponent.current) {
      let valueToCompare = contributionGraph && !totalCell ? dataKey : sectionValues[0]?.name;
      if (valueToCompare !== activeSection) {
        setActiveSection(valueToCompare);
      } else {
        callContentItems.current({ source });
      }
    }
    return () => {
      source.cancel('Content items panel cancelled by the user');
    };
  }, [contentState]);

  const load_more_results = async () => {
    let { thereIsMoreContent, pageNumber } = results;
    if (thereIsMoreContent) {
      await callContentItems.current({ pageNumber: pageNumber + 1 });
    }
  };
  const handleScroll = (scrollValues) => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      load_more_results();
    }
  };

  return (
    <ContentItemsPanel
      handleScroll={handleScroll}
      setRightPanelState={setRightPanelState}
      text={text}
      sectionsValues={sectionValues}
      isLoading={isLoading}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      results={results}
    />
  );
};

export default MentionsRightPanel;
