import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useRef, useState } from 'react';
import createUtmParameters from '../../../helpers/createUtmParameters';
import useOutsideHoverElement from '../../../hooks/useOutsideHoverElement';
import useSearchBarUiFunctions, { useChangeKeywordsListsOrder } from '../../../hooks/useSearchBarUiFunctions';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import { useSetListsIds } from '../../../MainSearchBar/setListIds';
import AddTopicButton from '../../../Navigation/AddTopicButton';
import getUrlParam from '../../../utilities/getUrlParam';
import useIsMobile from '../../../utilities/useIsMobile';
import useOutsideElement from '../../../utilities/useOutsideElement';
import { searchIncludesTitleConditional, relevantActivityFalseCondition } from '../../searchIncludesTitle';
import { store } from '../../../../Store';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

const StakeholderTopicPicker = (props) => {
  const {
    localTopic,
    setLocalTopic,
    searchPageQuery,
    keywordsLists,
    seeAllActivity,
    StakeholderInformation,
    setShowPopup,
    stakeholderSearchCondition,
    enableStakeholders,
  } = props;
  const { setListsIds } = useSetListsIds();
  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({ keywordsLists });
  const keywordsListsToUse = changeKeywordsListsOrder();

  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { search } = state;

  const { officalName } = StakeholderInformation;
  const list = localTopic;
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [searchValue, setSearchValue] = useState(
    searchIncludesTitleConditional(officalName) || relevantActivityFalseCondition() ? '' : searchPageQuery
  );
  const topicContainer = useRef(null);
  const inputElement = useRef(null);

  const masterTopic = (list !== null && list.id === null) || getUrlParam('topic-id') === 'all';
  const cursorConditional = !masterTopic && !showDropdownContent ? 'pointer' : 'auto';
  const inputCondition =
    showDropdownContent ||
    masterTopic ||
    searchIncludesTitleConditional(officalName) ||
    relevantActivityFalseCondition() ||
    list == null ||
    !enableStakeholders;

  const iconClass = () => {
    return !inputCondition && searchPageQuery === '' ? 'main-search-icon-list' : '';
  };

  const hideSearchBar = () => {
    setShowDropdownContent(false);
  };
  useOutsideElement(topicContainer, hideSearchBar);

  const openSearchBar = () => {
    setShowDropdownContent(true);
    setTimeout(() => {
      if (inputElement.current) {
        inputElement.current.focus();
      }
    }, 100);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isMobileTablet = useIsMobile(1310);

  const applySearchQueryOrTopic = ({ freeSearch, topic }) => {
    if (stakeholderSearchCondition) {
      navigate(
        `${location.pathname}?${topic ? `topic-id=${topic.id}` : ''}${freeSearch ? `search=${encodeURIComponent(freeSearch)}` : ''}`,
        { replace: true }
      );
      if (topic) {
        setListsIds(topic.id);
      }
      setShowDropdownContent(false);
      if (freeSearch) setLocalTopic(null);
      setTimeout(() => {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'search',
          value: { ...search, query: freeSearch ?? '' },
        });
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'stakeholderMobileSection',
          value: 'activity',
        });
      }, 10);
    } else {
      setShowPopup(true);
    }
  };
  const { topicOrClientWord } = useTopicOrClientWord();

  return (
    <div
      className={`stakeholder-dropdown-list dropdown-keywords-lists ${showDropdownContent ? 'dropdown-keywords-lists-open' : ''} ${(searchValue === '' && masterTopic) || searchIncludesTitleConditional(officalName) || relevantActivityFalseCondition() || list === null ? 'dropdown-keywords-influence' : ''}`}
      ref={topicContainer}
    >
      <div
        className='dropdown-keywords-activeitem d-flex align-items-center '
        style={{
          cursor: cursorConditional,
        }}
        onClick={() => {
          if (showDropdownContent) {
            setShowDropdownContent(false);
          } else {
            openSearchBar();
          }
        }}
      >
        {/* TYPE ICON */}
        <span className={`main-search-icon ${iconClass()}`} />
        {/*INPUT SEARCHBAR */}
        {inputCondition && (
          <input
            className='search-main-input'
            ref={inputElement}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder={isMobileTablet ? 'Search activity' : `Search by keyword or ${topicOrClientWord}`}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                applySearchQueryOrTopic({ freeSearch: e.target.value });
              }
            }}
          />
        )}
        {!inputCondition && (
          <p
            className={`search-main-text-value ${list === 'noListsCreated' ? 'search-main-text-value-disabled' : ''}`}
            style={{
              cursor: cursorConditional,
            }}
          >
            <span className='d-block hidden-lines hidden-one-line'>
              {searchPageQuery && !searchIncludesTitleConditional(officalName) && !relevantActivityFalseCondition()
                ? searchPageQuery
                : list !== null
                  ? list.name
                  : ''}
            </span>
          </p>
        )}

        {/* CLOSE BUTTON  */}
        <div
          className='close-icon-container'
          style={{
            paddingRight: '63px',
            visibility:
              ((!masterTopic && list !== null && enableStakeholders) || searchPageQuery !== '') &&
              !searchIncludesTitleConditional(officalName) &&
              !relevantActivityFalseCondition()
                ? 'visible'
                : 'hidden',
          }}
        >
          <button
            className='general-button close-icon'
            data-tooltip-content={isMobile ? '' : 'See all activity'}
            data-tooltip-id='general-tooltip'
            data-tooltip-place='top'
            onClick={(e) => {
              e.stopPropagation();
              seeAllActivity();
              setSearchValue('');
            }}
          />
        </div>

        {/* ARROW BUTTON */}

        <span
          className={`search-dropdown ${showDropdownContent ? 'search-dropdown-open' : ''}`}
          data-tooltip-content={`${list !== null && !showDropdownContent ? `Select a ${topicOrClientWord}` : ''}`}
          data-tooltip-id='general-tooltip'
          data-tooltip-place='right'
        ></span>
      </div>
      <div className={`open-dropdown d-${showDropdownContent ? 'block' : 'none'}`}>
        <div className='open-dropdown-container'>
          <StakeholderTopicPickerList
            keywordsLists={keywordsListsToUse}
            applyTopic={applySearchQueryOrTopic}
            inputElement={inputElement}
            showDropdownContent={showDropdownContent}
            localTopic={localTopic}
          />
        </div>
      </div>
    </div>
  );
};

const StakeholderTopicPickerList = (props) => {
  const { keywordsLists, applyTopic, inputElement, showDropdownContent, localTopic } = props;
  const { isMobile } = useIsMobile();
  const { isAuthenticated } = useAuth0();

  const keywordListsElement = useRef(null);
  const scrollContainerElement = useRef(null);
  const topicsListElement = useRef(null);

  const { startMoveLists, listPosition, setStartMoveLists, setListPosition, scrollList } = useSearchBarUiFunctions({
    inputSearch: inputElement,
    keywordsLists,
    keywordListsElement,
    scrollContainerElement,
    topicsListElement,
    showDropdownContent,
  });
  const { newAgencyPlan } = useTopicOrClientWord();

  const unselectTopic = () => {
    setStartMoveLists(false);
  };
  useOutsideHoverElement(topicsListElement, unselectTopic);
  const agencyPlanCondition = newAgencyPlan && keywordsLists?.length > 0;

  return (
    <>
      {isAuthenticated && (
        <div
          ref={topicsListElement}
          onKeyDown={(e) => {
            scrollList(e);
          }}
        >
          <div className='scroll-container hover-container search-bar-lists' ref={scrollContainerElement}>
            <ul className='policy-list px-0 mb-0 hover-content' ref={keywordListsElement}>
              {keywordsLists.map((item, index) => (
                <TopicItem
                  key={`keywordlist${item.id === null ? 'master-topic' : item.id}`}
                  item={item}
                  index={index}
                  applyTopic={applyTopic}
                  listPosition={listPosition}
                  setListPosition={setListPosition}
                  startMoveLists={startMoveLists}
                  setStartMoveLists={setStartMoveLists}
                  localTopic={localTopic}
                />
              ))}
            </ul>
          </div>
          {!agencyPlanCondition && <AddTopicButton keywordsLists={keywordsLists} />}
        </div>
      )}
      {!isAuthenticated && (
        <>
          <button
            className='general-button add-keyword-list-button'
            onClick={() => {
              window.location.assign(`/register?reg_source=searchbar-add-topic${createUtmParameters('&')}`);
            }}
          >
            <span className='icon-square-plus' /> Create a topic
            {!isMobile && (
              <span
                className='pricing-feature-more create-topic-more-feature'
                data-tooltip-id='general-tooltip'
                data-tooltip-html={
                  'Topics allow you to save searches<br/> for multiple keywords, and to enable<br/> tailored email alerts'
                }
                data-tooltip-place={'right'}
              >
                More information
              </span>
            )}
          </button>
        </>
      )}
    </>
  );
};

const TopicItem = (props) => {
  const { item, applyTopic, startMoveLists, setListPosition, index, listPosition, setStartMoveLists, localTopic } =
    props;
  const isMobile = useIsMobile();
  return (
    <div
      className={`${startMoveLists && listPosition === index ? 'dropdown-keywordlist-highlighted' : ''}`}
      onClick={() => {
        applyTopic({ topic: item });
      }}
      onMouseOver={() => {
        if (!isMobile) {
          if (!startMoveLists) {
            setStartMoveLists(true);
          }
          setListPosition(index);
        }
      }}
    >
      <li className={`dropdown-keywordlist d-flex ${item.description ? 'dropdown-keywordlist-description' : ''}`}>
        <span className={`icon-topics-thin`} />
        <div className='flex-grow-1'>
          <p className='hidden-lines hidden-one-line dropdown-name'>{item.name}</p>
          {item.description && <p className='dropdown-description description-field'>{item.description}</p>}

          {localTopic?.id === item.id && <span className='icon-tick topic-selected-mark' />}
        </div>
      </li>
    </div>
  );
};

export default StakeholderTopicPicker;
