import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonPricing = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='pricing-page'>
        <div className='py-3 px-3 px-lg-5 main-navigation-logo settings-header position-relative'>
          <div className='mx-0 justify-content-between align-items-center row pr-lg-5 pr-2'>
            <div className='my-1 col-lg-3'>
              <Skeleton height={19} width={`100%`} />
            </div>

            <Skeleton height={24} width={24} circle={true} />
          </div>
        </div>

        <div className='pricing-page-heading py-lg-5 pt-5 pb-3'>
          <div className='container text-center'>
            <Skeleton height={30} width={`50%`} />

            <div className='d-flex toggle-container align-items-center justify-content-center my-5'>
              <Skeleton height={19} width={90} />
              <button
                className={`general-button mx-4  toggle-component pricing-toggle toggle-inactive toggle-skeleton`}
              />
              <Skeleton height={19} width={90} />
            </div>

            <div className={`row justify-content-center pt-5 pb-lg-4`}>
              {Array(4)
                .fill()
                .map((item, index) => (
                  <div className='col-lg price-box mb-5 mb-lg-0' key={index}>
                    <div className='side-a'>
                      <div className='pricing-card'>
                        <div className='pricing-card--title pt-4'>
                          <div className='mb-4'>
                            <Skeleton height={24} width={'40%'} />
                          </div>
                          <div className='price-container pb-4'>
                            <Skeleton height={40} width={'70%'} />
                          </div>
                        </div>
                        <div className='pricing-description px-4'>
                          <Skeleton height={19} width={'80%'} />
                        </div>
                        <div className='p-3'>
                          <Skeleton height={24} width={'80%'} />
                        </div>
                        <div className='my-2'>
                          <Skeleton height={19} width={'60%'} />
                        </div>
                        <div className='pricing-features-list pt-3'>
                          <ul className='policy-list px-4 pb-5 text-left pricing-features-container'>
                            <div className='my-2'>
                              <Skeleton height={19} width={'80%'} />
                            </div>
                            <div className='my-2'>
                              <Skeleton height={19} width={'50%'} />
                            </div>
                            <div className='my-2'>
                              <Skeleton height={19} width={'60%'} />
                            </div>
                            <div className='my-2'>
                              <Skeleton height={19} width={'70%'} />
                            </div>
                            <div className='my-2'>
                              <Skeleton height={19} width={'30%'} />
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className='my-2'>
              <Skeleton height={19} width={'70%'} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonPricing;
