export const formatHour = (hour) => {
  let splitHour = hour.split(':');
  let mainHour = splitHour[0];
  let halfHour = splitHour[1];
  if (mainHour === '00') {
    return `12${halfHour !== '00' ? `.${halfHour}` : ''}am`;
  } else if (mainHour - 12 >= 0) {
    let hourToSet = mainHour - 12;
    return `${hourToSet === 0 ? '12' : hourToSet}${halfHour !== '00' ? `.${halfHour}` : ''}pm`;
  } else {
    return `${mainHour < 10 ? mainHour.replace('0', '') : mainHour}${halfHour !== '00' ? `.${halfHour}` : ''}am`;
  }
};
