import React from 'react';
import Skeleton from 'react-loading-skeleton';
import useIsMobile from '../../../utilities/useIsMobile';
import { useHeightContainer } from '../../../utilities/useHeightContainer';

const FromToFields = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  const isMobile = useIsMobile(768);
  return (
    <div className={`email-component-section ${!isMobile ? 'email-component-side-section' : ''}`}>
      <div className='email-component-from'>
        <Skeleton height={24} width={50} />
        <Skeleton height={36} width={'100%'} />
      </div>
      <div
        className={`email-component-from email-component-to-section ${
          !isMobile ? 'flex-grow-1 d-flex flex-column' : ''
        }`}
      >
        <div
          style={{
            marginLeft: isMobile ? '16px' : undefined,
          }}
        >
          <div ref={containerRef}></div>
          <Skeleton height={24} width={50} />
          {!isMobile && (
            <div className='flex-grow-1' style={{ height: heightContainer - 25, overflowY: 'auto' }}>
              <Skeleton height={24} width={'100%'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmailBodySkeleton = () => {
  const isMobile = useIsMobile(768);
  const isSmallMobile = useIsMobile(480);
  return (
    <div className='email-component-container flex-grow-1' style={{ height: '100%' }}>
      {isMobile && (
        <div className='email-component-section'>
          <div className='email-component-from'>
            <Skeleton height={24} width={50} />
            <div style={{ width: '5px' }} />
            <Skeleton height={24} width={90} />
          </div>

          <div className='email-component-from email-component-to-section'>
            <div>
              <Skeleton height={24} width={25} />
            </div>
            <div
              style={{
                display: 'flex',
                gap: '7px',
                height: '33px',
              }}
            >
              <div style={{ width: '5px' }} />
              <Skeleton height={24} width={24} circle />
              <Skeleton height={24} width={50} />
              <Skeleton height={24} width={70} />
            </div>
          </div>
        </div>
      )}
      <div
        className='email-component-section email-subject-section'
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <Skeleton height={18} width={80} />
      </div>
      <div
        style={{
          height: '100%',
          paddingLeft: isSmallMobile ? '12px' : '22px',
          paddingTop: '18px',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Skeleton height={18} width={80} />
        <Skeleton height={18} width={140} />
      </div>
    </div>
  );
};

const CrmEmailComponentSkeleton = () => {
  const isMobile = useIsMobile(768);
  return (
    <div className='h-100 email-component-body'>
      <EmailBodySkeleton />
      {!isMobile && <FromToFields />}
    </div>
  );
};

export { EmailBodySkeleton };
export default CrmEmailComponentSkeleton;
