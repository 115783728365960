import useGeneralApiCall from '../apiCalls/useGeneralApiCall';

const useAdminNotesFunctions = () => {
  const { generalApiCall } = useGeneralApiCall();

  const addAdminNote = async ({ adminNotes, organisationId }) => {
    let method = 'post';
    let pathname = `/api/admin/set-organisation-admin-notes`;
    let requestProperties = { organisationId, adminNotes };
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    if (result) {
      return result;
    }
  };

  return {
    addAdminNote,
  };
};

export default useAdminNotesFunctions;
