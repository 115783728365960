import useIsMobile from '../../../../utilities/useIsMobile';

const InfoTooltip = ({ text, senderDropdown, style }) => {
  const isSmallMobile = useIsMobile(480);

  return (
    <div
      className={`email-performance-info-tooltip ${senderDropdown ? 'sender-dropdown-info-tooltip' : ''}`}
      data-tooltip-html={text}
      data-tooltip-id={`tooltip-mobile`}
      data-tooltip-class-name={`header-tooltip pricing-tooltip-description  ${isSmallMobile ? 'tooltip-width' : ''} ${
        senderDropdown ? 'sender-tooltip' : 'pricing-tooltip-description'
      }`}
      style={style}
    >
      <span className='icon-info-circle-filled title-h3' style={{ color: 'rgba(0, 18, 43, 0.5)' }}></span>
    </div>
  );
};

export default InfoTooltip;
