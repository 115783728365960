const changeStakeholderLinks = (links, navigate) => {
  links.forEach((item) => {
    let href = item.href;
    if (href.includes('/stakeholders')) {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        let newUrl = new URL(href);
        navigate(`${newUrl.pathname}${window.location.search}`);
      });
    }
  });
};

export default changeStakeholderLinks;
