import { net_api_url } from '../../Store';
import axios from 'axios';
const getCurrentUser = async (getAccessToken, source) => {
  try {
    let token = await getAccessToken();
    let currentUser = await axios.get(`${net_api_url}/api/user/getcurrent`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
    let currentUserInformation = currentUser.data;
    return currentUserInformation;
  } catch (error) {
    console.error(error);
  }
};

export default getCurrentUser;
