import { Chart as ChartJS, LinearScale, LogarithmicScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import Preloader from '../AnalyticsDashboard/Preloader';
import useExternalTooltipHandler from './externalTooltipHandler';
import useIsMobile from '../../../utilities/useIsMobile';
import AnalyticsTitle from '../AnalyticsTitle';
import HoverText from '../../HoverText';
import WaitingAnimationScreen from '../../../StakeHolders/WaitingAnimationScreen';
import StakeholdersTable from './StakeholdersTable';
import RelevantStakeholdersRightPanel from '../../RelevantStakeholdersRightPanel';
import getUrlParam from '../../../utilities/getUrlParam';
import CustomScrollbar from '../../../../Common/CustomScrollbar';
import { store } from '../../../../Store';
import { useHeightContainer } from '../../../utilities/useHeightContainer';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, LogarithmicScale);

const RelevantStakeholders = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const { isLoading, stakeholdersData, isLoadedFirstTime, currentlyProcessing, setStakeholdersData } = props;
  const { graphData } = stakeholdersData;
  const [activeSection, setActiveSection] = useState(null);
  const [disableSelectSection, setDisableSelectSection] = useState(false);
  const scrollbarStakeholders = useRef(null);
  const generalContainer = useRef(null);
  const [rightPanelState, setRightPanelState] = useState({ open: false });
  const topicParameter = getUrlParam('topic-id');

  const emptyMessage = graphData.length === 0;

  const chartSection = useRef(null);

  const stakeholdersClickFunction = (index) => {
    let el = document.querySelectorAll('.stakeholders-table-row')[index];
    if (el) {
      scrollbarStakeholders.current.scrollTop(
        el.getBoundingClientRect().top - generalContainer.current.getBoundingClientRect().top - 173
      );
    }
    setActiveSection(index);
    setDisableSelectSection(true);
    setTimeout(() => {
      setDisableSelectSection(false);
    }, 2000);
  };

  const highlightFunction = (index) => {
    if (!disableSelectSection) {
      setActiveSection(index);
    }
  };

  useEffect(() => {
    setRightPanelState({ open: false });
  }, [topicParameter]);

  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'chartState',
      value: { isLoading, chartRef: {}, chartSection, data: graphData, emptyMessage },
    });
    return () => {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'chartState',
        value: {},
      });
    };
  }, [isLoading, graphData]);
  const searchParameter = getUrlParam('search');
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <div className='d-flex w-100 h-100'>
      <div ref={generalContainer} className='h-100 bg-white  flex-grow-1 border rounded-top'>
        <CustomScrollbar
          ref={scrollbarStakeholders}
          className={'simple-scrollbar smooth-scrolling'}
          maximalThumbYSize={100}
        >
          {isLoading && !isLoadedFirstTime.current && <Preloader />}
          {isLoadedFirstTime.current ? (
            <div className='px-lg-5 px-3 py-5 position-relative'>
              {isLoading && <div className='loading-background' />}

              <div className='mb-5 flex-centered justify-content-between'>
                <AnalyticsTitle />

                <div className='d-flex align-items-lg-center'>
                  <HoverText />
                </div>
              </div>
              {currentlyProcessing ? (
                <div className='top-stakeholders-waiting'>
                  <WaitingAnimationScreen waitingMessage={'the most relevant stakeholders'} />
                </div>
              ) : (
                <>
                  <StakeholdersGraph
                    stakeholdersData={stakeholdersData}
                    stakeholdersClickFunction={stakeholdersClickFunction}
                    chartSection={chartSection}
                  />
                  {!emptyMessage && (
                    <StakeholdersTable
                      stakeholdersData={stakeholdersData}
                      activeSection={activeSection}
                      highlightFunction={highlightFunction}
                      setRightPanelState={setRightPanelState}
                      rightPanelState={rightPanelState}
                      setStakeholdersData={setStakeholdersData}
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <>
              {searchParameter && (
                <div
                  className='flex-centered justify-content-center'
                  ref={containerRef}
                  style={{ height: `${heightContainer - 1}px` }}
                >
                  <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
                    {searchParameter ? (
                      <span className='d-block' style={{ maxWidth: '580px', marginTop: '-50px' }}>
                        Calculating most influential policymakers requires a topic. <br />
                        Please choose a topic rather than a specific keyword to display this report
                      </span>
                    ) : (
                      <> No data available</>
                    )}
                  </p>
                </div>
              )}
            </>
          )}
        </CustomScrollbar>
      </div>
      {rightPanelState.open && (
        <RelevantStakeholdersRightPanel
          stakeholdersData={stakeholdersData}
          contentState={rightPanelState}
          setRightPanelState={setRightPanelState}
        />
      )}
    </div>
  );
};

const StakeholdersGraph = (props) => {
  const {
    stakeholdersData,
    dashboard,
    stakeholdersClickFunction,
    chartSection,
    reportWidget,
    paddingXLabel = 15,
  } = props;
  const { graphData, images, chartLabels } = stakeholdersData;
  const location = useLocation();
  const { externalTooltipHandler } = useExternalTooltipHandler();
  const emptyMessage = graphData.length === 0;
  const isMobile = useIsMobile(526);
  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
        scales: { x, y },
      } = chart;
      const midX = x.getPixelForValue(0);
      const midY = y.getPixelForValue(0);

      ctx.save();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(right / 2 + left / 2, top, right / 2 - 24, bottom / 2);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, bottom / 2 + top, right / 2 - left / 2, bottom / 2 - top);
      ctx.restore();

      ctx.beginPath();
      ctx.strokeStyle = '#D4D4D4';
      ctx.lineWidth = 0.5;
      //horizontal center line
      ctx.moveTo(left, bottom / 2 + top);
      ctx.lineTo(right, bottom / 2 + top);

      //vertical center line
      ctx.moveTo(right / 2 + left / 2, top);
      ctx.lineTo(right / 2 + left / 2, bottom);

      //border right line
      ctx.moveTo(right, top);
      ctx.lineTo(right, bottom);

      //top line
      ctx.moveTo(left, top);
      ctx.lineTo(right, top);

      ctx.stroke();
    },
  };

  let options = {
    scales: {
      x: {
        type: 'logarithmic',
        ticks: {
          display: false,
          beginAtZero: true,
          autoSkip: true,
          maxRotation: 0,
        },
        title: {
          display: true,
          text: chartLabels?.x,
          align: 'end',
          font: {
            size: 16,
          },
          color: 'rgb(0, 148, 204)',
          padding: {
            top: paddingXLabel,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'linear',
        ticks: {
          display: false,
          beginAtZero: true,
        },
        title: {
          display: true,
          text: chartLabels?.y,
          align: 'end',
          font: {
            size: 16,
          },
          color: 'rgb(0, 148, 204)',
          padding: {
            bottom: 20,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
      },
      quadrants: {
        topLeft: '#FFFFFF',
        topRight: '#F9F9F9',
        bottomRight: '#FFFFFF',
        bottomLeft: '#F9F9F9',
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    onClick: (evt) => {
      let points = chartGraph.current.getElementsAtEventForMode(evt, 'point', { intersect: true }, true);
      if (points.length) {
        const firstPoint = points[points.length - 1];
        if (stakeholdersClickFunction) {
          stakeholdersClickFunction(firstPoint.index);
        } else {
          setTimeout(() => {
            window.open(`${graphData[firstPoint.index].contentUrl}${location.search}`);
          }, 10);
        }
      }
    },
  };

  if (!isMobile && !reportWidget) {
    options = {
      ...options,
      plugins: {
        ...options.plugins,
        tooltip: {
          ...options.plugins.tooltip,
          external: externalTooltipHandler,
        },
      },
    };
  }

  if (reportWidget) {
    options = {
      ...options,
      animation: false,
    };
  }

  const data = {
    datasets: [
      {
        label: 'Top MPs and peers',
        data: graphData,
        backgroundColor: 'rgba(242, 168, 5, 1)',
        pointStyle: images,
        pointRadius: 16,
        pointHoverRadius: 16,
      },
    ],
  };

  const chartGraph = useRef();
  const searchParameter = getUrlParam('search');

  return (
    <>
      <div
        style={{ height: props.totalHeight ? '100%' : dashboard || emptyMessage ? '280px' : '400px' }}
        className={`${emptyMessage ? 'flex-centered justify-content-center' : ''}`}
        ref={chartSection}
      >
        {emptyMessage ? (
          <p className='title-h4 px-4 px-lg-0 text-center main-light-text my-0'>
            {
              <>
                {searchParameter ? (
                  <span className='d-block' style={{ marginTop: '-80px' }}>
                    Calculating most influential policymakers requires a topic. Please choose a topic rather than a
                    specific keyword to display this report
                  </span>
                ) : (
                  <> No data available</>
                )}
              </>
            }
          </p>
        ) : (
          <Scatter ref={chartGraph} data={data} options={options} plugins={[quadrants]} />
        )}
      </div>
    </>
  );
};

export { StakeholdersGraph };
export default RelevantStakeholders;
