import getUrlParam, { getUrlParamRaw } from '../utilities/getUrlParam';

const locationSearchDescriptor = (location) => {
  let url = new URL(window.location.href);
  let locationSearch = '';
  if (getUrlParam('search')) {
    locationSearch = `?search=${getUrlParamRaw('search')}${getUrlParamRaw('and') ? `&and=${getUrlParamRaw('and')}` : ''}${getUrlParamRaw('not') ? `&not=${getUrlParamRaw('not')}` : ''}`;
  } else if (getUrlParam('or')) {
    locationSearch = `?or=${getUrlParamRaw('or')}${getUrlParamRaw('and') ? `&and=${getUrlParamRaw('and')}` : ''}${getUrlParamRaw('not') ? `&not=${getUrlParamRaw('not')}` : ''}`;
  } else if (getUrlParam('topic-id')) {
    locationSearch = `?topic-id=${url.searchParams.get('topic-id')}${url.searchParams.get('view') ? `&view=${url.searchParams.get('view')}` : ''}`;
  }

  return locationSearch;
};

const locationTopicDescriptor = (location) => {
  let url = new URL(window.location.href);
  let locationSearch = '';
  if (location.search.includes('topic-id')) {
    locationSearch = `?topic-id=${url.searchParams.get('topic-id')}`;
  }

  return locationSearch;
};

const locationReferenceDescriptor = (location) => {
  let locationSearch = '';
  if (getUrlParam('search')) {
    locationSearch = `?search=${getUrlParamRaw('search')}`;
  } else if (!!location && getUrlParam('or')) {
    locationSearch = `?or=${getUrlParamRaw('or')}${getUrlParamRaw('and') ? `&and=${getUrlParamRaw('and')}` : ''}${getUrlParamRaw('not') ? `&not=${getUrlParamRaw('not')}` : ''}`;
  }
  return locationSearch;
};

const contentItemSearchQuery = (location, list) => {
  let contentPage = location.pathname.match(/[0-9]+/);
  if ((list !== null && !contentPage) || getUrlParam('topic-id')) {
    return `${locationTopicDescriptor(location)}`;
  } else {
    return `${locationReferenceDescriptor(location)}`;
  }
};

const getQueryParameterByName = (name, url = window.location.href) => {
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export { locationTopicDescriptor, locationReferenceDescriptor, contentItemSearchQuery, getQueryParameterByName };

export default locationSearchDescriptor;
