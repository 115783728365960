import { useContext, useState } from 'react';
import { store } from '../../../Store';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import { useParams } from 'react-router-dom-v5-compat';

const useAddTagsToStakeholder = (props) => {
  const { customContact } = props ?? {};
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activeResults, contentResults, customContactInfo } = state;
  const { Stakeholders } = contentResults[activeResults];
  const Stakeholder = Stakeholders?.contact;

  const { generalApiCall } = useGeneralApiCall();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const { id } = params;

  const addTagsToStakeholder = async (closeMicroModal, newTags) => {
    setIsLoading(true);
    const pathname = '/api/crm-contact/update';
    const method = 'post';
    let requestProperties;
    if (customContact) {
      const tagsToUpdate = newTags ?? [...customContactInfo?.tags, ...tags.map((tag) => tag.value)];
      requestProperties = {
        id,
        tags: tagsToUpdate.length === 0 ? null : tagsToUpdate,
      };
    } else {
      const tagsToUpdate = newTags ?? [...Stakeholder?.contactInformation?.[0]?.tags, ...tags.map((tag) => tag.value)];
      requestProperties = {
        id: Stakeholder?.contactInformation?.[0]?.id,
        tags: tagsToUpdate.length === 0 ? null : tagsToUpdate,
      };
    }
    const results = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    if (results !== undefined) {
      if (customContact) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'customContactInfo', value: results });
      } else {
        let resultsForState = {
          ...contentResults,
          [activeResults]: {
            Stakeholders: {
              ...Stakeholders,
              contact: {
                ...Stakeholders?.contact,
                contactInformation: [results],
              },
            },
          },
        };

        dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
      }

      if (closeMicroModal) {
        closeMicroModal();
      }
    }
  };
  return { tags, setTags, isLoading, addTagsToStakeholder };
};

export default useAddTagsToStakeholder;
