import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonRelatedContent = () => {
  return (
    <div className='related-content pr-0'>
      <div className='py-3 pl-3 pr-3 mb-3 box-rounded box-outstanding-reports bg-white'>
        <div style={{ marginBottom: '11px', height: '18px' }}>
          <SkeletonTheme baseColor='#fff'>
            <Skeleton height={15} width={`100%`} />
          </SkeletonTheme>
        </div>
        <Skeleton width={133} height={30.6} />
      </div>
      <div className='py-3 pl-3 pr-3 mb-3 box-rounded box-outstanding-reports bg-white'>
        <div style={{ marginBottom: '20px', height: '18px' }}>
          <SkeletonTheme baseColor='#fff'>
            <Skeleton height={16} width={`100%`} />
          </SkeletonTheme>
        </div>
        <div style={{ marginBottom: '10px', height: '56px' }} className='graph-skeleton overflow-hidden'>
          <div>
            <Skeleton height={3} />
            <div className='graph-skeleton-line-before'>
              <Skeleton height={7} width={7} circle />
            </div>
          </div>
          <div>
            <Skeleton height={3} />
            <div className='graph-skeleton-line-before'>
              <Skeleton height={7} width={7} circle />
            </div>
          </div>
          <div>
            <Skeleton height={3} />
            <div className='graph-skeleton-line-before'>
              <Skeleton height={7} width={7} circle />
            </div>
          </div>
          <div>
            <Skeleton height={3} />
            <div className='graph-skeleton-line-before'>
              <Skeleton height={7} width={7} circle />
            </div>
          </div>
          <div>
            <Skeleton height={3} />
            <div className='graph-skeleton-line-before'>
              <Skeleton height={7} width={7} circle />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '-5px' }}>
          <Skeleton height={14} />
        </div>
      </div>
      <div className='px-3 pt-3 box-rounded box-outstanding bg-white'>
        {Array(3)
          .fill()
          .map((item, index) => (
            <div key={index} className='mb-3'>
              <div className='mb-3' style={{ height: '18px' }}>
                <SkeletonTheme baseColor='#fff'>
                  <Skeleton height={19} width={`100%`} />
                </SkeletonTheme>
              </div>
              {Array(2)
                .fill()
                .map((item, index) => (
                  <div className='related-item' key={index}>
                    <div className='d-flex w-100 mx-0 align-items-center' style={{ height: '21px' }}>
                      <div className='col-2 pl-0'>
                        <Skeleton height={22} width={22} circle={true} />
                      </div>
                      <div className='d-flex w-100' style={{ height: '21px' }}>
                        <div className='mr-1'>
                          <Skeleton width={30} />
                        </div>
                        <div className='mr-1'>
                          <Skeleton width={40} />
                        </div>
                        <div className='mr-1'>
                          <Skeleton width={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SkeletonRelatedContent;
