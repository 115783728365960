import React from 'react';
import { normalizedDateUK } from '../../../../utilities/normalizedDateUK';
import dayjs from 'dayjs';
let advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

function AdditionalRecipientInfo({ isLoading, info, status }) {
  const getActionName = (action) => {
    switch (action) {
      case 'Click':
        return 'Clicked';
      case 'Open':
        return 'Opened';
      case 'Delivery':
        return 'Delivered';
      case 'Send':
        return 'Sent';
      default:
        return action;
    }
  };
  const AdditionalInfoRow = ({ data }) => {
    return (
      <div className='additional-recipient-info-row'>
        <div className='additional-recipient-info-row-first-cell paragraph-p2'>
          {getActionName(data.type)}
          {data.clickLink && <span className='email-performance-link'>{data.clickLink}</span>}
        </div>
        <div className='additional-recipient-info-row-second-cell paragraph-p3'>
          <span>{normalizedDateUK(data.eventDateTime).format('MMMM Do, YYYY')}</span>
          <span>&nbsp;at {normalizedDateUK(data.eventDateTime).format('HH:mm:ss')}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className='additional-recipient-info-loading-container'>
          <i className='fas fa-spinner fa-spin'></i>
        </div>
      ) : (
        <>
          {info?.length > 0 ? (
            <>
              {info.map((data, index) => (
                <AdditionalInfoRow key={`${index}-${data.type}`} data={data} />
              ))}
            </>
          ) : (
            <div className='additional-recipient-info-loading-container paragraph-p2'>
              <span style={{ color: 'rgba(0, 18, 43, 0.45)' }}>
                {status === 'Cancelled' || status === undefined ? 'Email not sent' : 'No events'}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default AdditionalRecipientInfo;
