const TweetsFilters = [
  {
    name: 'Exclude Retweets',
    searchState: 'inbox',
    pages: ['Tweets'],
    filterName: 'isRetweet',
    type: 'toggle',
    value: true,
    phrase: 'excluding retweets',
    customOrder: 0,
    activeSearchCustomOrder: 1,
    hiddenDesktop: true,
    exportAsExternalFilter: true,
  },
  {
    name: 'Exclude Retweets',
    searchState: 'referenceSection',
    pages: ['Tweets'],
    filterName: 'isRetweet',
    type: 'toggle',
    value: false,
    customOrder: 0,
    activeSearchCustomOrder: 1,
    updateFilter: true,
  },
  {
    name: 'Commentators',
    searchState: 'always',
    filterName: 'policyMogulUserType',
    pages: ['Tweets'],
    hiddenDesktop: true,
    exportAsExternalFilter: true,
    options: [
      {
        name: 'MPs - England',
        filterValue: 'MP - England',
        hash: 'mps-england',
      },
      {
        name: 'MPs - Scotland',
        filterValue: 'MP - Scotland',
        hash: 'mps-scotland',
      },
      {
        name: 'MPs - Wales',
        filterValue: 'MP - Wales',
        hash: 'mps-wales',
      },
      {
        name: 'MPs - Northern Ireland',
        filterValue: 'MP - Northern Ireland',
        hash: 'mps-northen-ireland',
      },
      {
        name: 'Lords',
        filterValue: 'Lord',
        hash: 'lord',
      },
      {
        name: 'MS (Welsh Parliament)',
        filterValue: 'AM',
        hash: 'ams',
      },
      {
        name: 'MLAs (N.Ireland Assembly)',
        filterValue: 'MLA',
        hash: 'mlas',
      },
      {
        name: 'MSPs (Scottish Parliament)',
        filterValue: 'MSP',
        hash: 'msps',
      },
      {
        name: 'Members of London Assembly',
        filterValue: 'Member Of London Assembly',
        hash: 'london-assembly',
      },
      {
        name: 'Special advisers',
        filterValue: 'Special Adviser',
        hash: 'special-advisers',
      },
      {
        name: 'Other political commentators',
        filterValue: 'Other political commentator',
        tooltip:
          'Includes think tanks, pressure groups, Metro Mayors, Special<br/> Advisors, police and crime commissioners, and other political<br/> commentators',
        hash: 'others',
      },
    ],
  },
  {
    name: 'Tweets option',
    searchState: 'referenceSection',
    pages: ['Tweets'],
    filterName: 'isRetweet',
    optionSelected: '',
    options: [
      { name: 'Is retweet', filterValue: 'true' },
      { name: 'Is Tweet', filterValue: 'false' },
    ],
    hidden: true,
    updateHash: true,
    notUseFromLocalStorage: true,
  },
];

export default TweetsFilters;
