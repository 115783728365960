import { net_api_url } from '../../Store';
import axios from 'axios';
const getActivePlan = async (getAccessToken, source, logout) => {
  try {
    let token = await getAccessToken();
    let activePlan = await axios.get(`${net_api_url}/api/user/get-active-plan`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source?.token,
    });
    if (!!activePlan) {
      return activePlan.data;
    }
  } catch (error) {
    if (error?.response?.status === 401 && !!logout) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
    console.error(error);
  }
};

export default getActivePlan;
