const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const calculateDifferenceBetweenDays = (date) => {
  let date1 = dayjs.utc().startOf('day');
  let date2 = dayjs.utc(date).startOf('day');
  return date2.diff(date1, 'day');
};

export default calculateDifferenceBetweenDays;
