import { useContext } from 'react';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import { store } from '../../../Store';
import createNotification from '../../../Settings/Utilities/CreateNotification';
import useValidateContactsOversubscribed from '../../utilities/useValidateContactsOversubscribed';

const useContactsFunctionForStakeholders = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activeResults, contentResults } = state;
  const { Stakeholders } = contentResults[activeResults];

  const { setShowContactsOversubscribedConfirmModal, setRequestedToAddContacts } = useValidateContactsOversubscribed();

  const { generalApiCall } = useGeneralApiCall();

  const verifyIfContactIsAdded = async ({ contactId }) => {
    const pathname = '/api/crm-contact/query';
    const method = 'post';
    const requestProperties = {
      filters: [{ field: 'contactId', value: `${contactId}`, operator: 'str_eq' }],
      pageSize: '10',
      query: '',
    };
    const results = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: true });
    return results?.hits;
  };

  const addOrDeleteContact = async ({ contactId, e, action = 'add', callback = () => {} }) => {
    let el;
    if (e) {
      el = e.target;
      el.disabled = true;
    }
    const pathname = `/api/crm-contact/${action === 'delete' ? 'delete' : 'add-stakeholders'}`;
    const method = 'post';
    let requestProperties;
    if (action === 'add') {
      requestProperties = {
        contactIds: [contactId],
        tags: null,
      };
    } else {
      requestProperties = {
        ids: [contactId],
      };
    }
    const results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      returnError: true,
    });
    if (results !== undefined && !results.status) {
      let resultsForState = {
        ...contentResults,
        [activeResults]: {
          Stakeholders: {
            ...Stakeholders,
            contact: {
              ...Stakeholders?.contact,
              contactInformation: action === 'delete' ? [] : results,
            },
          },
        },
      };

      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: resultsForState });
    } else {
      let message = `An error ocurred.`;
      if (action === 'add') {
        if (results?.status === 403) {
          callback();
          setRequestedToAddContacts(1);
          setShowContactsOversubscribedConfirmModal(true);
        } else {
          createNotification('danger', message);
        }
      } else {
        createNotification('danger', message);
      }
    }
    if (el) {
      el.disabled = false;
    }
  };

  return { verifyIfContactIsAdded, addOrDeleteContact };
};

export default useContactsFunctionForStakeholders;
