//https://stackoverflow.com/questions/54892403/usereducer-action-dispatched-twice
import React, { createContext, useReducer } from 'react';
import filtersOptions from './FiltersOptions';

const initialState = {
  organisation: {},
  activeFilters: {
    search: '',
    sort: filtersOptions.find((item) => item.name === 'sort').options.find((item) => item.default === true),
    order: filtersOptions.find((item) => item.name === 'order').options.find((item) => item.default === true),
  },
};
const AdminStore = createContext(initialState);
const { Provider } = AdminStore;

function reducer(state, action) {
  switch (action.type) {
    case 'MODIFY_SECTION':
      //return {...state, [action.parameter]: action.value}
      let newStateSection = Object.assign(state);
      newStateSection[action.parameter] = action.value;
      return { ...state, newStateSection };
    default:
      throw new Error();
  }
}

const AdminStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { AdminStore, AdminStateProvider };
