import React, { useState } from 'react';
import ExplanationItem from './ExplanationItem.js';
import MatchingListItem from './MatchingListItem.js';
import preventLink from './prevenLink.js';

const RenderingTags = React.forwardRef((props, ref) => {
  const { terms, numberOfTags, matchingKeywordLists, calendarTags } = props;
  const [showMoreTags, setShowMoreTags] = useState(false);

  return (
    <div className='d-flex align-items-start rendering-items-container' ref={ref}>
      {terms.length <= numberOfTags && (
        <>
          {terms.map((item, index) => {
            if (matchingKeywordLists) {
              return <MatchingListItem item={item} key={`keywordlist-${index}`} calendarTags={calendarTags} />;
            } else {
              return <ExplanationItem {...props} name={item} key={`explanation-${index}`} />;
            }
          })}
        </>
      )}
      {terms.length > numberOfTags && (
        <div className='d-flex align-items-center'>
          {(() => {
            let items = [];
            for (let i = 0; i < numberOfTags; i++) {
              if (matchingKeywordLists) {
                items.push(<MatchingListItem item={terms[i]} key={`keywordlist-${i}`} calendarTags={calendarTags} />);
              } else {
                items.push(<ExplanationItem {...props} name={terms[i]} key={`explanation-${i}`} />);
              }
            }
            return items;
          })()}
          <div className='more-tags-text position-relative'>
            <span
              className='more-text'
              onClick={(e) => {
                preventLink(e);
                setShowMoreTags(true);
              }}
            >
              {terms.length - numberOfTags} more
            </span>
            {showMoreTags && (
              <div className='more-tags-container'>
                <p
                  className='less-tags px-3 py-2 mb-0'
                  onClick={(e) => {
                    preventLink(e);
                    setShowMoreTags(false);
                  }}
                >
                  less
                  <img src={`${process.env.REACT_APP_CDNURL}/images/arrow-down.svg`} alt='arrow up' />
                </p>
                <div className='px-3 pb-2'>
                  {(() => {
                    let items = [];
                    for (let i = numberOfTags; i < terms.length; i++) {
                      if (matchingKeywordLists) {
                        items.push(
                          <MatchingListItem item={terms[i]} key={`keywordlist-${i}`} calendarTags={calendarTags} />
                        );
                      } else {
                        items.push(<ExplanationItem {...props} name={terms[i]} key={`explanation-${i}`} />);
                      }
                    }
                    return items;
                  })()}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default RenderingTags;
