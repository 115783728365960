import getUrlParam from '../utilities/getUrlParam';

const getIdPositionOnSection = (section, id) => {
  return () => {
    if (!!section && !!section.hits) {
      let idPosition = section.hits.findIndex((item) => item.id === parseInt(id));
      return idPosition >= 0 ? idPosition : null;
    }
    return null;
  };
};

const createNavigationLinksOnSection = (section) => {
  return (idPosition) => {
    const { hits, feedItemFlags } = section ?? {};
    let navigation = {
      next: null,
      prev: null,
      nextIndex: null,
      prevIndex: null,
    };

    const searchTopicParameter = (position) => {
      let matchingListId = feedItemFlags?.[position]?.matchingKeywordLists?.[0]?.id;
      if (getUrlParam('topic-id') && matchingListId) {
        return `?topic-id=${matchingListId}`;
      } else {
        return window.location.search;
      }
    };

    if (idPosition !== null) {
      let nextPosition = idPosition + 1;
      let prevPosition = idPosition - 1;
      if (nextPosition <= hits?.length - 1) {
        let item = hits[nextPosition];
        const { contentUrl, contributionId } = item;
        navigation.next = `${contentUrl}${searchTopicParameter(nextPosition)}${!!contributionId ? `#contribution-${contributionId}` : ''}`;
        navigation.nextIndex = nextPosition;
      }
      if (prevPosition >= 0) {
        let item = hits?.[prevPosition];
        const { contentUrl, contributionId } = item;
        navigation.prev = `${contentUrl}${searchTopicParameter(prevPosition)}${!!contributionId ? `#contribution-${contributionId}` : ''}`;
        navigation.prevIndex = prevPosition;
      }
    }
    return navigation;
  };
};

export { getIdPositionOnSection, createNavigationLinksOnSection };
