import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom-v5-compat';

function ReferralCode() {
  const params = useParams();
  const { referralCode } = params;

  useEffect(() => {
    window.location.assign(
      `${process.env.REACT_APP_REFERRAL_URL}?utm_source=policymogul-referral&utm_medium=referrer&utm_campaign=${referralCode}`
    );
  }, []);

  return <div>Redirection...</div>;
}

export default ReferralCode;
