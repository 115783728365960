import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const SkeletonInfluenceList = (props) => {
  const { influencePage } = props;
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div>
        {influencePage && (
          <div className={`box-top-rounded mb-5`}>
            <div className='influence-initial-message'>
              <div className='mx-auto main-content-wrapper'>
                <div className={`message-content`}>
                  <p className='main-light-text message-text my-2'>
                    <Skeleton height={20} width={`80%`} />
                  </p>
                  <div className='mt-3'>
                    <Skeleton height={34} width={220} style={{ borderRadius: '20px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={`${influencePage ? 'px-3 px-lg-5 px-3 px-lg-5 mx-auto main-content-wrapper' : ''}`}>
          <ul className='policy-list px-0 items-list mt-2'>
            {Array(9)
              .fill()
              .map((item, index) => (
                <SkeletonInfluenceSingleItem key={index} />
              ))}
          </ul>
        </div>
      </div>
    </SkeletonTheme>
  );
};

const SkeletonInfluenceSingleItem = () => {
  return (
    <li className='box-rounded main-link-item'>
      <div className='main-link-item-padding'>
        <div className='d-flex align-items-start'>
          <Skeleton height={60} width={80} />
          <div className='influence-item_title'>
            <div className='mb-2'>
              <Skeleton height={19} width={`100%`} />
            </div>
            <Skeleton height={19} width={`100%`} />
          </div>
        </div>
      </div>
    </li>
  );
};

export { SkeletonInfluenceSingleItem };
export default SkeletonInfluenceList;
