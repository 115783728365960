import { useRef, useEffect, useState } from 'react';

const useContentWidgetFunctions = (props) => {
  const { isLoading, previewMode, item, widgetData, modifyItemLayout } = props;
  const mainContentElement = useRef();
  const itemsList = useRef();
  const [previeMaxHeight, setPrevieMaxHeight] = useState(0);
  const [itemMinHeight, setItemMinHeight] = useState(0);
  const [numberOfItemsShown, setNumberOfItemsShown] = useState(null);

  useEffect(() => {
    if (mainContentElement.current) {
      let parent = mainContentElement.current.parentElement;
      let header = parent?.previousElementSibling;
      if (header) {
        header.style.zIndex = '1002';
        header.style.position = 'relative';
      }
      if (parent) {
        parent.style.setProperty('padding-left', '0px', 'important');
        parent.style.setProperty('padding-right', '0px', 'important');
      }
    }
  }, []);

  const containerHeight = mainContentElement.current?.getBoundingClientRect()?.height;
  const calculateSpecificItemHeight = () => {
    const children = itemsList.current?.children;
    if (children) {
      let height = 0;
      let numberOfItems = 0;
      let parentHeight = mainContentElement.current?.offsetHeight;
      for (let i = 0; i < children.length; i++) {
        const item = children[i];
        const contentItem = item.children[0];
        const contentHeight = contentItem?.scrollHeight;
        let newHeight = height + contentHeight;
        if (newHeight <= parentHeight) {
          height = newHeight;
          numberOfItems = i + 1;
        }
      }
      let finalHeight = parentHeight - height;
      let finalNumber = Math.round(finalHeight / numberOfItems);
      if (numberOfItems >= children?.length) {
        modifyItemLayout({ item, state: item.data?.state, rowSpan: numberOfItems + 1 });
      }
      setNumberOfItemsShown(numberOfItems);
      if (isFinite(finalNumber)) {
        setItemMinHeight(finalNumber);
      }
    }
  };

  useEffect(() => {
    if (!isLoading && mainContentElement.current?.getBoundingClientRect()?.height) {
      //AE: Waiting when the local commentary is added and the height is updated, needs to be tracked
      setTimeout(() => calculateSpecificItemHeight(), 10);
    }
  }, [
    isLoading,
    mainContentElement.current,
    containerHeight,
    item?.rowSpan,
    item?.data?.state?.commentary,
    widgetData,
  ]);

  useEffect(() => {
    let parent = mainContentElement.current?.parentElement?.parentElement?.parentElement;
    let header = mainContentElement.current?.parentElement?.previousElementSibling;
    let commentaryElement = mainContentElement.current?.previousElementSibling;
    let height =
      parent?.getBoundingClientRect()?.height -
      (header?.getBoundingClientRect()?.height + 24) -
      (commentaryElement ? commentaryElement.getBoundingClientRect()?.height + 24 : 0);
    setPrevieMaxHeight(height);
  }, [previewMode]);

  return { itemMinHeight, previeMaxHeight, mainContentElement, itemsList, numberOfItemsShown };
};

export default useContentWidgetFunctions;
