import InfluenceHeader from './InfluenceHeader';

const CreatingSummaryLoading = () => {
  return (
    <div className='dashboard-root-container'>
      <InfluenceHeader />
      <div className='flex-grow-1 h-100 d-flex flex-column justify-content-center'>
        <LoadingSummaryText />
      </div>
    </div>
  );
};

const LoadingSummaryText = () => {
  return (
    <div>
      <div className='text-center pb-5 mx-auto waiting-screen'>
        <p className='mb-2'>
          <strong>This will take a moment but will ultimately be worth it</strong>
        </p>
        <p>
          <em>This could take up to about 30 seconds</em>
        </p>
      </div>
      <div className='waiting-bar mx-auto'>
        <div className='waiting-bar-indicator' />
      </div>
    </div>
  );
};

export { LoadingSummaryText };
export default CreatingSummaryLoading;
