import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { net_api_url, store } from '../../Store';
import createNotification from '../Utilities/CreateNotification';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const OrganisationLogo = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const { myself } = team;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { organisationLogo, setOrganisationLogo, setIsSaved, setShowRemoveLogoPopup } = props;
  //const [userImage, setUserImage] = useState(profile.organisationLogo);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const { getAccessToken } = useGetAccessToken();

  useEffect(() => {
    return () => {
      source.cancel('Upload file canceled by the user.');
    };
  }, []);

  const uploadLogo = async (e) => {
    try {
      setLoadingUpload(true);
      const reader = new FileReader();
      const files = e.target.files;
      const file = files[0];
      reader.addEventListener(
        'load',
        () => {
          setOrganisationLogo(reader.result);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
        const pathname = `${net_api_url}/api/user-upload/upload`;
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i]);
        }
        const requestProperties = formData;
        const token = await getAccessToken();
        const requestHeaders = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        };
        let result = await axios.post(pathname, requestProperties, requestHeaders);
        if (!!result) {
          setOrganisationLogo(result.data[0]);
          setIsSaved(false);
        }
      }
      setLoadingUpload(false);
    } catch (error) {
      createNotification('danger', 'An error ocurred.');
    }
  };

  return (
    <>
      <h3 className='account-list-title organisation-title'>Organisation logo</h3>
      <div className='mt-2 account-logo-container'>
        <div className='account-logo mb-3'>
          {!!organisationLogo && <img className='img-fluid w-100' src={organisationLogo} alt='organisation logo' />}
          {organisationLogo === null && (
            <img
              src={`${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`}
              alt='logotype'
              className='img-fluid w-100'
            />
          )}
        </div>
        {myself.role !== 'Member' && (
          <>
            <label
              className={`pointer w-100 mb-0 d-block ${organisationLogo === null ? 'action-button' : 'outline-button'} py-2 text-center ${loadingUpload ? 'disabled-button' : ''}`}
              htmlFor='inputFile'
            >
              Upload a logo
            </label>
            <input
              disabled={loadingUpload}
              id='inputFile'
              accept='image/png, image/gif, image/jpeg'
              type='file'
              hidden
              onChange={(e) => {
                uploadLogo(e);
              }}
            />
            {organisationLogo !== null && (
              <div>
                <button
                  className={`general-button button-danger-text w-100 d-block py-2 rounded-button ${organisationLogo === null ? 'disabled-button' : ''}`}
                  disabled={loadingUpload}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowRemoveLogoPopup(true);
                  }}
                >
                  Remove logo
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default OrganisationLogo;
