const getSearchWithinHash = (hashForItem) => {
  let hash = window.location.hash;
  if (hash.includes('search-within')) {
    let hashParts = hash.split('~');
    let searchQuery;
    hashParts.forEach((item) => {
      if (item.includes('search-within')) {
        let query = item.split(':');
        searchQuery = decodeURIComponent(query[1]);
      }
    });
    return hashForItem ? `#~search-within:${encodeURIComponent(searchQuery)}` : searchQuery;
  } else {
    return false;
  }
};

export default getSearchWithinHash;
