const labelsMapping = {
  Backbenchers: 0,
  Government: 1,
  'Shadow government': 2,
  MSs: 3,
  MSPs: 4,
};

export const sortPositionLabels = (items) => {
  return items.sort(function (a, b) {
    if (a.text) {
      return labelsMapping[a.text] - labelsMapping[b.text];
    }
    return labelsMapping[a] - labelsMapping[b];
  });
};
