import { useRef, useState } from 'react';
import useIsMobile from '../../utilities/useIsMobile';
import useOutsideElement from '../../utilities/useOutsideElement';
import useUpdateTitleAnalytics from '../useUpdateTitleAnalytics';
import AnalyticsSidebar from './AnalyticsSidebar';
import { useLocation } from 'react-router-dom-v5-compat';

const AnalyticsTitle = (props) => {
  const isMobile = useIsMobile();
  const [showBox, setShowBox] = useState(false);
  const containerElement = useRef(null);
  const location = useLocation();

  const hideElements = () => {
    setShowBox(false);
  };

  const { title } = props;
  const { sectionTitle } = useUpdateTitleAnalytics();

  useOutsideElement(containerElement, hideElements);
  const mobileCondition = isMobile && !location.pathname.startsWith('/embed-analytics');

  return (
    <div className='position-relative' ref={containerElement}>
      <h3
        className={`trends-title title-h4-bold mb-0 mr-1 ${mobileCondition ? 'dropdown-item-element pointer' : ''} ${showBox ? 'open' : ''} nowrap-item`}
        onClick={() => {
          if (mobileCondition) {
            setShowBox(!showBox);
          }
        }}
      >
        {title ?? sectionTitle()}
      </h3>
      {showBox && (
        <div className='main-title-popup'>
          <div className='login-navbar py-2 main-title-items'>
            <AnalyticsSidebar />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyticsTitle;
