import axios from 'axios';
import createNotification from '../../Settings/Utilities/CreateNotification.js';
import { net_api_url } from '../../Store';
const customApiCall = async (props) => {
  const { method, pathname, requestHeaders, requestProperties, successMessage = null } = props;
  try {
    let request = await axios[method](
      `${net_api_url}${pathname}`,
      method === 'post' ? requestProperties : requestHeaders,
      method === 'post' ? requestHeaders : ''
    );
    if (successMessage !== null) {
      createNotification('success', successMessage);
    }
    return request.data === null || request.data === '' ? request : request.data;
  } catch (error) {
    let message = `An error ocurred.`;
    if (!!error.response && !!error.response.data) {
      message = error.response.data;
    }
    createNotification('danger', message);
    return false;
  }
};

export default customApiCall;
