import { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';
import TopicPicker from '../../../../Common/TopicPicker';
import { store } from '../../../../Store';
import { useChangeKeywordsListsOrder } from '../../../hooks/useSearchBarUiFunctions';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import { useSetListsIds } from '../../../MainSearchBar/setListIds';
import getUrlParam from '../../../utilities/getUrlParam';
import EmbedAnalyticsButton from '../../../../EmbedAnalytics/EmbedAnalyticsButton';
import GraphActions from '../GraphActions';
import { useAuth0 } from '@auth0/auth0-react';
import useOutsideElement from '../../../utilities/useOutsideElement';
import { useAllowRightPanel } from '../MentionsByTopic/ChartDataTable';
import { copyLinkFunction } from '../../../Sentiment/SentimentSharePopup';
import useIsMobile from '../../../utilities/useIsMobile';
import { useNoTopicsCondition } from '../../../components/EmptyMessage';

const AnalyticsSubNav = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, activePlan } = state;

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists.filter((item) => item.id !== null),
  });
  const keywordsListsToUse = changeKeywordsListsOrder();
  const { setListsIds, getStoredListIds } = useSetListsIds();

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const dashboardSection = params['*'] === 'dashboard';

  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const storedLists = getStoredListIds({});

  const [searchValue, setSearchValue] = useState('');
  const createFirstTopic = () => {
    //AE: This is to create the local topic but currently there is no case where the topic parameter is not being sent because it's don't on the analytics.index file
    let id;
    let defaultList = keywordsLists.find((item) => item.id === activePlan?.defaultKeywordListId)
      ? activePlan?.defaultKeywordListId
      : null;
    if (topicParameter) {
      id = parseInt(topicParameter);
    } else if (defaultList !== null) {
      id = defaultList;
    } else if (!!storedLists) {
      id = storedLists[0]?.id;
    } else {
      id = keywordsListsToUse[0]?.id;
    }
    return keywordsListsToUse.find((item) => item.id === id);
  };
  const [localTopic, setLocalTopic] = useState(createFirstTopic());
  const searchFunction = ({ topic, freeSearch }) => {
    const params = new URLSearchParams(location.search);
    if (!!freeSearch) {
      setSearchValue(freeSearch);
      params.delete('topic-id');
      params.set('search', encodeURIComponent(freeSearch));
    } else if (!!topic) {
      setLocalTopic(topic);
      params.delete('search');
      params.set('topic-id', topic.id);
      setListsIds(topic.id);
    }
    navigate({ pathname: location.pathname, search: params.toString(), hash: location.hash });
  };

  useEffect(() => {
    if (topicParameter && topicParameter !== localTopic?.id) {
      let newTopic = keywordsLists.find((item) => item?.id === parseInt(topicParameter));
      setLocalTopic(newTopic);
    }
  }, [topicParameter]);

  useEffect(() => {
    if (!!searchParameter) {
      let parameterValue = decodeURIComponent(searchParameter);
      if (!!parameterValue && parameterValue !== searchValue) {
        setSearchValue(parameterValue);
      }
    }
  }, [searchParameter]);

  const { topicOrClientWord } = useTopicOrClientWord();
  const { newAgencyNoTopicsCondition } = useNoTopicsCondition();

  return (
    <div className={`bg-white monitor-items-bar inbox-items-bar trends-subnav-bar overflow-auto`}>
      <div className='mx-auto main-content-wrapper flex-centered justify-content-between resize-elements-container '>
        <div className='side-left-element pl-md-5 pl-3 d-none d-lg-block'>
          <p className={`mb-0 title-h4 nowrap-item`}>{isAuthenticated ? 'Analytics' : 'Data hub'}</p>
        </div>
        <div
          className={`main-content flex-centered position-relative filters-general-container justify-content-between pr-xl-5 px-md-4 px-3 `}
        >
          <div className='flex-centered w-100 justify-content-between'>
            <div className={'col-lg-6 col-xl-8 px-0 analytics-topic-picker'}>
              <TopicPicker
                keywordsLists={keywordsListsToUse}
                showTopicCases={!!getUrlParam('topic-id') && getUrlParam('topic-id') !== 'none'}
                searchFunction={searchFunction}
                defaultTopic={localTopic}
                fixedPosition
                placeholder={`Select a ${topicOrClientWord} to see analytics`}
                mainSearchValue={searchValue ?? ''}
                allowFreeSearch={true}
                closeButtonFunction={
                  searchParameter
                    ? (inputElement) => {
                        setSearchValue('');
                        if (inputElement) {
                          inputElement?.current?.focus();
                        }
                      }
                    : null
                }
                closeButtonMessage={'Clear search'}
                closeButtonPosition={'bottom'}
                topicButtonDataTip={`Advanced search`}
              />
            </div>
            {isAuthenticated ? (
              <>
                {!newAgencyNoTopicsCondition && <>{dashboardSection ? <EmbedAnalyticsButton /> : <GraphActions />}</>}
              </>
            ) : (
              <ShareLoggedOutButton dashboardSection={dashboardSection} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ShareLoggedOutButton = (props) => {
  const [showActions, setShowActions] = useState(false);
  const { dashboardSection } = props;
  const actionsElement = useRef();
  const hideActions = () => {
    setShowActions(false);
  };
  useOutsideElement(actionsElement, hideActions);
  const { width, top, left, height } = actionsElement?.current?.getBoundingClientRect() ?? {};
  const itemWord = dashboardSection ? 'dashboard' : 'chart';
  const { showPanelWithTitle } = useAllowRightPanel();
  const location = useLocation();
  const isMobile = useIsMobile();

  return (
    <div className='position-relative' ref={actionsElement}>
      <button
        className={`general-button rounded-button third-button text-white px-lg-4 py-2 analytics-logged-out-share-button ml-2 ml-lg-0`}
        onClick={() => {
          setShowActions(!showActions);
        }}
      >
        {isMobile ? (
          <span className='icon-share paragraph-p3' />
        ) : (
          <span className={`dropdown-item-element dropdown-item-element-10 ${showActions ? 'open' : ''}`}>
            Share {itemWord}
          </span>
        )}
      </button>
      {showActions && (
        <div
          className='position-fixed rounded border bg-main-white box-outstanding adjustment-of-position py-2'
          style={{
            width: '320px',
            top: `${top + height + 5}px`,
            left: `${left + width - 320}px`,
          }}
        >
          <div
            className='d-flex px-4 py-3 pointer action-state-hover'
            onClick={(e) => {
              const url = `${window.location.origin}${location.pathname}${location?.search}&utm_source=analytics-share-button${location.hash}`;
              copyLinkFunction({ e, url, closeFunction: () => setShowActions(false) });
            }}
          >
            <div className='pt-1 analytics-share-icon-container'>
              <span className='icon-share-link title-h5-m' />
            </div>
            <div className='pl-3'>
              <p className='font-weight-bold mb-1'>Copy link to {itemWord}</p>
              <p className='main-light-text mb-0'>Share a link to this specific {itemWord} with anyone</p>
            </div>
          </div>
          {!dashboardSection && <GraphActions />}
          <div
            className='d-flex px-4 py-3 pointer action-state-hover'
            onClick={() => {
              showPanelWithTitle('Embed a real-time chart on any site or blog');
            }}
          >
            <div className=' analytics-share-icon-container'>
              <span className='icon-code paragraph-p4' />
            </div>
            <div className='pl-3'>
              <p className='font-weight-bold mb-1'>
                Embed {itemWord} <span className='d-inline-block icon-padlock ml-2' />
              </p>
              <p className='main-light-text mb-0'>Embed this specific {itemWord} on any website</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyticsSubNav;
