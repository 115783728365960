import React, { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import createNotification from '../Settings/Utilities/CreateNotification';
import { TeamUserFields } from './TeamUserFields';
import userFiltersOptions from './UserFiltersOptions';
import createUpdateField from './createUpdateField';
import UserList from './ui/UserList';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import useCreateANewTeam from './CreateANewTeam';
import { AdminStore } from './AdminState';
import { useParams } from 'react-router-dom-v5-compat';

const TeamUserList = (props) => {
  const adminState = useContext(AdminStore);
  const { state } = adminState;
  const { organisation } = state;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const initialFields = TeamUserFields.filter((item) => item.page.includes('user'));
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [createTeamFields, setCreateTeamFields] = useState(initialFields);
  const [activeFilters, setActiveFilters] = useState({
    search: '',
    sort: userFiltersOptions.find((item) => item.name === 'sort').options.find((item) => item.default === true),
    order: userFiltersOptions.find((item) => item.name === 'order').options.find((item) => item.default === true),
  });
  const [userToDeleteId, setUserToDeleteId] = useState(null);
  const [localKeywordList, setLocalKeywordList] = useState(
    organisation
      ? organisation?.teams?.flatMap((item) =>
          item?.keywordLists?.map((list) => ({
            ...list,
            myState: 'Enabled',
          }))
        )
      : []
  );
  const [localClients, setLocalClients] = useState(
    organisation
      ? organisation?.teams?.map((item) => {
          const { id, name, keywordLists } = item;
          return {
            ...item,
            myState: 'Enabled',
            teamName: name,
            teamId: id,
            myKeywordLists: keywordLists,
            teamMemberKeywordLists: [],
          };
        })
      : []
  );

  const { generalApiCall } = useGeneralApiCall();
  const params = useParams();
  const { id } = params;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const initialLoading = useRef();
  initialLoading.current = async (source) => {
    try {
      await loadTeamInformation(source);
    } catch {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialLoading.current(source);
    return () => {
      source.cancel('Team user cancelled by the user');
    };
  }, []);

  const loadTeamMembers = async (props) => {
    const { pageNumber, filters, source: sourceProp } = props;
    const filtersToApply = filters ?? activeFilters;
    const { search, order, sort } = filtersToApply;
    let method = 'post';
    let pathname = '/api/admin/list-users';

    let requestProperties = {
      organisationId: parseInt(id),
      pageNumber: pageNumber ?? 1,
      pageSize: 100000,
      sort: sort.value,
      order: order.value,
      searchFilter: search,
    };
    let results = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestSource: sourceProp ?? source,
      requestProperties,
      notShowErrorMessage: true,
    });
    if (filters) {
      setIsLoadingFilters(false);
    }
    return results;
  };

  let loadTeamInformation = async (source) => {
    try {
      if (id !== undefined) {
        let teamMembers = await loadTeamMembers({ source });
        if (!!teamMembers) {
          setTeamMembers(teamMembers);
          setIsLoading(false);
        }
      }
    } catch (error) {}
  };

  const sendASetEmailPasswordEmail = async ({ e, userAuth0Id }) => {
    let element = e.target;
    element.disabled = true;
    let method = 'post';
    let pathname = `/api/admin/send-set-password-email`;
    let requestProperties = {
      userAuth0Id,
    };
    let returnCompleteRequest = true;
    let sendEmail = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource: source,
      requestProperties,
      returnCompleteRequest,
    });
    if (sendEmail && sendEmail.status === 200) {
      createNotification('success', `Email sent successfully`);
    }
    element.disabled = false;
  };

  const applyFilters = (props) => {
    setIsLoadingFilters(true);
    const { property, value } = props;
    const filters = { ...activeFilters, [property]: value };
    setActiveFilters(filters);
    loadTeamMembers({ filters });
  };

  const { createFunction, copyCredentials } = useCreateANewTeam({
    fields: createTeamFields,
    setFields: setCreateTeamFields,
    source,
    results: teamMembers,
    setResults: setTeamMembers,
    setShowSuccessMessage,
    userPage: true,
    temporaryPassword,
    setTemporaryPassword,
    localKeywordList,
    localClients,
  });

  const deleteUser = async () => {
    try {
      if (!!userToDeleteId) {
        const pathname = `/api/admin/delete-team-member/${userToDeleteId}`;
        const method = 'delete';
        const results = await generalApiCall({
          pathname,
          method,
          needsAuthentication: true,
          returnCompleteRequest: true,
        });
        if (results?.status === 200) {
          let newTeam = [...teamMembers];
          let userPosition = newTeam.findIndex((item) => item.id === userToDeleteId);
          newTeam.splice(userPosition, 1);
          setTeamMembers(newTeam);
          createNotification('success', `User deleted successfully`);
        }
      }
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  return (
    <>
      {isLoading && <p className='pt-5'>Loading...</p>}
      {!isLoading && (
        <UserList
          teamId={id}
          isLoadingFilters={isLoadingFilters}
          teamMembers={teamMembers}
          setTeamMembers={setTeamMembers}
          sendASetEmailPasswordEmail={sendASetEmailPasswordEmail}
          updateField={createUpdateField({
            createTeamFields,
            setCreateTeamFields,
          })}
          applyFilters={applyFilters}
          activeFilters={activeFilters}
          createTeamFields={createTeamFields}
          setCreateTeamFields={setCreateTeamFields}
          teamFunctions={{ createFunction, copyCredentials }}
          showSuccessMessage={showSuccessMessage}
          setShowSuccessMessage={setShowSuccessMessage}
          initialFields={initialFields}
          temporaryPassword={temporaryPassword}
          setUserToDeleteId={setUserToDeleteId}
          deleteUser={deleteUser}
          userPage
          localKeywordList={localKeywordList}
          setLocalKeywordList={setLocalKeywordList}
          organisation={organisation}
          localClientsState={{ localClients, setLocalClients }}
        />
      )}
    </>
  );
};

export default TeamUserList;
