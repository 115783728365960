import React, { useContext } from 'react';
import Popup from '../../Common/PopUp';
import { store } from '../../Store';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

const ContentPopup = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan, requestedToAddContacts, team, activeSubscriptions } = state;
  const { totalCrmContacts } = activePlan;
  const { planName, subscriptionStatus, isLegacyPlan } = activeSubscriptions;
  const { myself, owner } = team;
  const location = useLocation();
  const navigate = useNavigate();

  const subscribed = subscriptionStatus === 'Subscribed' || subscriptionStatus === 'SubscribedOverLimit';

  const freePlan = subscriptionStatus === 'OnFreePlan' || subscriptionStatus === 'OnFreePlanOverLimit';
  const enterprisePlan = subscribed && planName === 'Enterprise';
  const legacyPlan = subscribed && isLegacyPlan;

  const disablePrepopulateAdditionalContacts = freePlan || enterprisePlan || legacyPlan;

  const closeMicroModal = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showContactsOversubscribedConfirmModal',
      value: false,
    });
  };
  return (
    <div className='contacts-oversubscribed-confirm-modal'>
      <p className='popup-header px-3 px-lg-5'>
        {myself.role === 'Owner'
          ? 'Update your subscription to add more contacts'
          : `Your subscription includes up to ${totalCrmContacts} contacts`}
      </p>
      <div className='separator' />
      <div className='popup-body p-3 px-lg-5 pb-lg-2'>
        {myself.role === 'Owner' ? (
          <>
            <p>
              You are about to go over the {totalCrmContacts} contacts included in your current subscription. You can
              purchase more contacts on the Billing page
            </p>
          </>
        ) : (
          <p>
            Your account owner <strong>({owner.emailAddress})</strong> can increase the number of contacts available on
            your subscription.
          </p>
        )}

        <div className='mb-4 flex-centered justify-content-end'>
          <button className='general-button delete-button px-4 mr-3 py-1' onClick={closeMicroModal}>
            Cancel
          </button>
          {myself.role === 'Owner' && (
            <button
              style={{
                backgroundColor: '#0094cc',
                fontWeight: 400,
                marginTop: '-4px',
                border: 'none',
              }}
              className='btn btn-general action-button px-4 py-2'
              onClick={() => {
                closeMicroModal();
                navigate(
                  `/settings/billing?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}${
                    !disablePrepopulateAdditionalContacts
                      ? `&additionalCrmContacts=${Math.ceil(requestedToAddContacts / 50) * 50}`
                      : ''
                  }`,
                  { replace: true }
                );
              }}
            >
              Go to billing page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const ContactsOversubscribedConfirmModal = (props) => {
  return (
    <Popup {...props} maxWidth={530}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default ContactsOversubscribedConfirmModal;
