import React, { useEffect, useRef, useState } from 'react';

const ToggleFilter = (props) => {
  const { filter, selectOption } = props;
  const { value, name } = filter;
  const [localValue, setLocalValue] = useState(value);

  const updateValue = useRef();
  updateValue.current = () => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  };

  useEffect(() => {
    updateValue.current();
  }, [value]);

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <p className='toggle-title'>{name}</p>
      <button
        className={`general-button filter-toggle filter-toogle-${localValue ? 'active' : 'inactive'}`}
        onClick={() => {
          let newValue = !value;
          setLocalValue(newValue);
          selectOption({ filter, newValue });
        }}
      >
        <span className='toggle-item'></span>
      </button>
    </div>
  );
};

export default ToggleFilter;
