import { Portal } from 'react-portal';
import { useContext, useState } from 'react';
import EmbedAnalyticsMain from './EmbedAnalyticsMain';
import Popup from '../Common/PopUp';
import { store } from '../Store';
import { useAuth0 } from '@auth0/auth0-react';
import { useAllowRightPanel } from '../Dashboard/Analytics/ui/MentionsByTopic/ChartDataTable';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import useIsMobile from '../Dashboard/utilities/useIsMobile';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';

const EmbedAnalyticsButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableEmbedWidgets } = activePlan;
  const topicParameter = getUrlParam('topic-id');

  const { isAuthenticated } = useAuth0();
  const enableCondition = (isAuthenticated && enableEmbedWidgets && !!topicParameter) || !isAuthenticated;

  const { topicsProp, settingsPage } = props;
  const [showPopup, setShowPopup] = useState(false);

  const popupProperties = {
    identifier: 'analytics-feeds-popup',
    awaitOpenAnimation: false,
  };
  const { showPanelWithTitle } = useAllowRightPanel();
  const { newAgencyPlan } = useTopicOrClientWord();

  const isMobile = useIsMobile();

  return (
    <>
      {settingsPage ? (
        <button
          className='action-button general-button px-4 py-2 text-left text-lg-center'
          style={{ width: `${isMobile ? '212' : `${newAgencyPlan ? '318' : '326'}`}px` }}
          onClick={() => {
            if (enableEmbedWidgets) {
              setShowPopup(true);
            }
          }}
        >
          Embed real-time data{' '}
          <span className='d-none d-lg-inline-block'>on {newAgencyPlan ? 'any' : 'your'} website</span>
        </button>
      ) : (
        <button
          className='general-button action-state-hover border code-button ml-2 ml-lg-0  main-subtle-text'
          data-tooltip-content={!topicParameter ? 'Apply a topic to embed this dashboard' : ''}
          data-tooltip-id={'general-tooltip'}
          data-tooltip-place={'bottom'}
          onClick={(e) => {
            if (enableCondition) {
              if (isAuthenticated) {
                setShowPopup(true);
              } else {
                showPanelWithTitle('Embed a real-time chart on any site or blog');
              }
            }
          }}
        >
          <span className='icon-code-thin paragraph-p4' />{' '}
          <span className='d-none d-lg-inline' style={{ marginLeft: '6px' }}>
            {' '}
            Embed <span className='d-none d-xl-inline-block'>live dashboard</span>{' '}
            {(!enableCondition || !isAuthenticated) && <span className='icon-padlock ml-1 paragraph-p3' />}
          </span>
        </button>
      )}

      {showPopup && (
        <EmbedAnalyticsPopup
          setShowPopup={setShowPopup}
          additionalClass='website-feeds-popup-container'
          maxWidth={1200}
          topicsProp={topicsProp}
          {...popupProperties}
        />
      )}
    </>
  );
};

const EmbedAnalyticsPopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <EmbedAnalyticsMain {...props} />
      </Popup>
    </Portal>
  );
};

export { EmbedAnalyticsPopup };
export default EmbedAnalyticsButton;
