import useHiddenItemsFunctions from '../../Dashboard/hooks/useHiddenItemsFunctions';
import { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../Store';
import getActivePlan from '../Utilities/getActivePlan';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';

const HideClientCheckbox = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team } = state;
  const { myself } = team ?? {};

  const { item, clientsListState, hidingState } = props;
  const { clientsList, setClientList } = clientsListState ?? {};
  const { currentlyHiding, setCurrentlyHiding } = hidingState ?? {};
  const [changingState, setChangingState] = useState(false);

  const { updateHiddenClientState } = useHiddenItemsFunctions();
  const { getAccessToken } = useGetAccessToken();

  const isComponentMounted = useRef();
  const itemState = item?.currentState ?? item?.myState;

  useEffect(() => {
    isComponentMounted.current = true;
    return function () {
      isComponentMounted.current = false;
    };
  }, []);

  const switchVisability = async (item) => {
    if (!changingState && !currentlyHiding) {
      setCurrentlyHiding(true);
      setChangingState(true);
      let newState = itemState === 'Enabled' ? 'Hidden' : 'Enabled';
      let result = await updateHiddenClientState({
        state: newState,
        userId: parseInt(myself?.id),
        teamId: item.teamId,
        showSuccsessMessage: true,
      });
      //AE:Necessary to update master topic
      dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: false });
      const activePlan = await getActivePlan(getAccessToken);
      if (!!activePlan) {
        dispatch({ type: 'MODIFY_SECTION', parameter: 'activePlan', value: activePlan });
      }

      if (isComponentMounted.current) {
        if (!!result) {
          const newClients = clientsList.map((listItem) => {
            if (listItem.teamId === item.teamId) {
              return { ...listItem, currentState: newState };
            }
            return listItem;
          });
          setClientList(newClients);
        }
        setChangingState(false);
        setCurrentlyHiding(false);
      }
    }
  };

  return (
    <div
      className={`position-relative flex-centered mr-2 mr-lg-3 hide-topic-btn ${currentlyHiding ? 'hide-topic-btn-disabled' : ''}`}
    >
      <label
        htmlFor={`hideTopic-${item.teamId}-id`}
        className={`mb-0 flex-centered pointer hide-topic-label-wrapper-topics`}
        onClick={(e) => {
          e.stopPropagation();
          switchVisability(item);
        }}
      >
        {changingState ? (
          <i
            className='fas fa-spinner fa-spin'
            style={{
              color: '#212529',
              width: '14px',
              marginRight: '11px',
              fontSize: '14px',
            }}
          ></i>
        ) : (
          <span
            className={`hide-topic-btn-icon ${itemState === 'Hidden' ? 'icon-eye-hide' : 'icon-visibility'}`}
          ></span>
        )}
        {itemState === 'Hidden' ? 'Unhide' : 'Hide'} client
      </label>
      <span
        onClick={(e) => e.stopPropagation()}
        className='icon-info main-light-text d-inline-block pointer ml-2'
        data-tooltip-content={`Hidden clients will no longer appear in drop-down lists on Monitor, Influence or Analytics, and will not be available for email alerts. They will also be removed from any existing email alerts. This setting only applies to you, and will not impact others on your team.`}
        data-tooltip-id={'settings-tooltip'}
        data-tooltip-class-name={'pricing-feature-tooltip feed-tooltip billing-tooltip'}
        style={{ fontSize: '15px' }}
      />
    </div>
  );
};

export default HideClientCheckbox;
