import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import SavePopup from './SavePopup';
import createNotification from '../../Settings/Utilities/CreateNotification';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import axios from 'axios';
import { Link } from 'react-router-dom-v5-compat';
import { useAuth0 } from '@auth0/auth0-react';
import CreateAccountPopup from '../../Dashboard/components/CreateAccountPopup';

const SaveItemPopup = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { saveItemPopup, saveItemsIds, userSavedItemsFolders, reloadSavedItemInformation } = state;
  const { showPopup, item, listItem } = saveItemPopup;
  const { savedItemsPerFolder } = item ?? {};
  const [isLoading, setIsLoading] = useState(true);

  const { saveItemsPageFunctions } = props;
  const { currentFolder, loadFolderItems, updateFolders } = saveItemsPageFunctions ?? {};

  const [newFolderCreated, setNewFolderCreated] = useState(null);
  const [saveItemState, setSaveItemState] = useState('saveItem');
  const { generalApiCall } = useGeneralApiCall();
  const { isAuthenticated } = useAuth0();
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      if (isAuthenticated) {
        await callListFolders(source);
        await getSavedItemsIds(source);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Saved items cancelled by the user');
    };
  }, []);

  const setShowPopup = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'saveItemPopup',
      value: { item: null, showPopup: false },
    });
    //source.cancel("General api call cancelled by the user");
  };

  const callListFolders = async (source) => {
    let pathname = `/api/saved-items/saved-item-folders${showTweets ? '' : '?excludeTweets=true'}`;
    let method = 'get';
    let results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    if (!!results) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'userSavedItemsFolders',
        value: results,
      });
    }
  };

  const getSavedItemsIds = async (source) => {
    const pathname = `/api/saved-items/saved-item-ids${showTweets ? '' : '?excludeTweets=true'}`;
    const method = 'get';
    const results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    if (!!results) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'saveItemsIds',
        value: results,
      });
    }
  };

  let objectId = (item) => {
    if (
      item.contributionId ||
      (listItem &&
        (item.contentType === 'ScotlandCountrySpecificParliamentaryContribution' ||
          item.contentType === 'WalesCountrySpecificParliamentaryContribution'))
    ) {
      return item.parentObjectID;
    } else if (item.type === 'RankedStakeholder') {
      return item.baseObjectID;
    } else {
      return item.objectID ?? item.objectId; // Using objectId rather than objectID for consultations
    }
  };

  const addSaveItem = async (props) => {
    const { selectedFolderId, item, source } = props;
    const { type } = item;
    let pathname = `/api/saved-items/add-saved-item`;
    let contentType = () => {
      if (
        (item.contentType === 'ScotlandCountrySpecificParliamentaryContribution' ||
          item.contentType === 'WalesCountrySpecificParliamentaryContribution') &&
        listItem
      ) {
        return 'CountrySpecificParliamentaryRecord';
      } else if (item.contentType === 'SeneddCommitteeTranscriptContribution') {
        return 'SeneddCommitteeTranscript';
      } else if (item.contributionId) {
        return 'HansardContent';
      } else if (item.type === 'RankedStakeholder') {
        return 'Stakeholder';
      } else if (type === 'WrittenQuestion') {
        return 'ParliamentaryRecord';
      } else if (item.objectType === 'UserContent') {
        return 'UserContent';
      } else if (type) {
        return type;
      } else {
        return item.objectType;
      }
    };
    let method = 'post';
    let requestProperties = {
      savedItemFolderId: selectedFolderId,
      contentType: contentType(),
      objectId: objectId(item),
    };
    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
    });
    if (results) {
      createNotification(
        'success',
        (() => {
          return (
            <span>
              Saved to{' '}
              <Link to={`/saved-items?folder-id=${selectedFolderId}`}>
                {userSavedItemsFolders.find((item) => item.id === selectedFolderId).name}
              </Link>
            </span>
          );
        })()
      );
      return results;
    }
  };

  const addItemToMultipleFolders = async (props) => {
    const { e, selectedFolders, item } = props;
    let el = e.target;
    el.disabled = true;
    let itemOnFolders = createSelectedFolders();
    let itemSelected = currentItem();
    let foldersToAdd = selectedFolders.filter((folder) => !itemOnFolders.includes(folder));
    let foldersToRemove = itemOnFolders.filter((folder) => !selectedFolders.includes(folder));
    let foldersToRemoveMainObject = saveItemsIds[itemSelected]
      ? savedItemsPerFolder.filter((folder) => foldersToRemove.includes(folder.folderId))
      : [];

    let itemsToAdd = [];
    let itemsToDelete = [];
    let newSavedItemsIds = [...saveItemsIds];

    for (let i = 0; i < foldersToAdd.length; i++) {
      let itemAdded = await addSaveItem({
        selectedFolderId: foldersToAdd[i],
        item,
      });
      if (itemAdded) {
        itemsToAdd.push(itemAdded);
      }
    }

    for (let i = 0; i < foldersToRemoveMainObject.length; i++) {
      let itemRemoved = await deleteItemFromFolder(foldersToRemoveMainObject[i]);
      if (itemRemoved) {
        itemsToDelete.push(itemRemoved);
      }
    }

    itemsToDelete.forEach((id) => {
      let itemPosition = newSavedItemsIds.findIndex((item) => item.id === id);
      newSavedItemsIds.splice(itemPosition, 1);
    });

    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'saveItemsIds',
      value: [...newSavedItemsIds, ...itemsToAdd],
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'reloadSavedItemInformation',
      value: {
        updateNumber: reloadSavedItemInformation?.number ? reloadSavedItemInformation?.number + 1 : 1,
        itemId: objectId(item),
      },
    });
    e.disabled = false;

    //UPDATE THE ITEMS ON THE SAVE ITEMS PAGE
    if (currentFolder && foldersToRemove.includes(currentFolder.id)) {
      loadFolderItems(currentFolder.id);
    }
  };

  const createEditAFolder = async (props) => {
    const { e, name, source } = props;
    let el = e.target;
    el.disabled = true;
    let pathname = `/api/saved-items/create-saved-item-folder`;
    let method = 'post';
    let requestProperties = {
      name,
    };
    let results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      requestProperties,
      requestSource: source,
    });
    if (results) {
      let newFolders = [...userSavedItemsFolders];
      newFolders = [...newFolders, results];
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'userSavedItemsFolders',
        value: newFolders,
      });
      setSaveItemState('saveItem');
      setNewFolderCreated(results.id);
      createNotification('success', `Folder created successfully`);
    } else {
      el.disabled = false;
    }
  };

  const currentItem = () => {
    if (item !== null) {
      let defineObjectId = () => {
        if (item.contributionId) {
          return item.parentObjectID;
        } else if (item.type === 'RankedStakeholder') {
          return item.baseObjectID;
        } else {
          return item.objectID ?? item.objectId;
        }
      };
      let position = saveItemsIds.findIndex((element) => {
        let propertyToCompare = defineObjectId();
        return element.objectId === propertyToCompare;
      });
      if (position >= 0) {
        return position;
      }
    }
  };

  const deleteItemFromFolder = async (folder, source) => {
    const { id, folderName } = folder;
    let pathname = `/api/saved-items/saved-item/${id}`;
    let method = 'delete';
    let results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      returnCompleteRequest: true,
      requestSource: source,
    });
    if (results && results.status === 200) {
      createNotification('success', `Removed from ${folderName} folder`);
      return id;
    }
  };

  const createSelectedFolders = () => {
    let position = currentItem();
    if (position !== undefined && savedItemsPerFolder?.length > 0) {
      return savedItemsPerFolder.map((item) => item.folderId);
    } else {
      return [];
    }
  };

  return (
    <>
      {!isLoading && showPopup && item !== null && (
        <SavePopup
          setShowPopup={setShowPopup}
          identifier={'save-item-popup'}
          userSavedItemsFolders={userSavedItemsFolders}
          item={item}
          createEditAFolder={createEditAFolder}
          saveItemState={saveItemState}
          setSaveItemState={setSaveItemState}
          additionalClass={'saved-items-popup'}
          addItemToMultipleFolders={addItemToMultipleFolders}
          StakeholderItem={item.type === 'Stakeholder' || item.type === 'RankedStakeholder'}
          itemOnFolders={createSelectedFolders()}
          newFolderCreated={newFolderCreated}
          setNewFolderCreated={setNewFolderCreated}
          updateFolders={updateFolders}
          additionalCloseAction={() => {
            setSaveItemState('saveItem');
          }}
        />
      )}
      {!isAuthenticated && showPopup && (
        <CreateAccountPopup text={'You’ll need to create an account to save content'} setShowPopup={setShowPopup} />
      )}
    </>
  );
};

export default SaveItemPopup;
