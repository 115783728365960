import React from 'react';
import Skeleton from 'react-loading-skeleton';
const SkeletonItemTwitter = () => {
  return (
    <div className='tweet-container p-4 context my-2'>
      <div className='d-flex justify-content-between align-items-start'>
        <div className='twitter-profile-info' style={{ width: '40%' }}>
          <div className='profile-info'>
            <Skeleton height={19} width={`60%`} />
            <Skeleton height={19} width={`30%`} />
          </div>
        </div>
        <Skeleton height={21} width={21} circle={true} />
      </div>

      <div className='twitter-content mb-4'>
        <Skeleton height={19} width={`100%`} />
        <Skeleton height={19} width={`100%`} />
        <Skeleton height={19} width={`40%`} />
      </div>

      <div className='d-flex justify-content-between'>
        <div className='twitter-action-buttons'>
          <span className='mr-1'>
            <Skeleton height={21} width={21} circle={true} />
          </span>
          <span className='mr-1'>
            <Skeleton height={21} width={21} circle={true} />
          </span>
          <Skeleton height={21} width={21} circle={true} />
        </div>
        <Skeleton height={19} width={130} />
      </div>
    </div>
  );
};

export default SkeletonItemTwitter;
