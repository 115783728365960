import React, { useEffect, useState, useRef } from 'react';
import normalize from '../../utilities/normalizeString';
import MembershipSelectFilter from './MembershipSelectFilter';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import TagsFilter from './TagsFilter';
let referenceValue = 6;
let splitItemValue = 4;
const CommonFilter = (props) => {
  const { filter, selectOption, notRow } = props;
  const { name, options, additionalItemsText, showAllOptions, type, disabled, filterName, expandedByDefault } = filter;
  const changeFilter = (selected, index, name) => {
    let newValue = [...options];
    if (filterName === 'tags') {
      let i = newValue.findIndex((item) => item.name === name);
      let currentOption = newValue[i];
      currentOption.selected = selected ? !selected : true;
    } else {
      let currentOption = newValue[index];
      if (name) {
        if (!selected) {
          currentOption.selected = true;
        }
        currentOption.optionSelected = name;
      } else {
        currentOption.selected = selected ? !selected : true;
      }
    }

    selectOption({ filter, newValue });
  };
  let optionsToRender = options.length > referenceValue && !showAllOptions ? options.slice(0, splitItemValue) : options;
  let additionalItems = showAllOptions ? [] : options.slice(splitItemValue, options.length);

  const { agencyUser } = useTopicOrClientWord();
  return (
    <>
      <h3 className={`${disabled ? 'main-light-text' : ''}`}>
        {name}
        {disabled && (
          <span
            className='ml-2 icon-padlock d-inline-block'
            data-tooltip-content={`Upgrade to ${agencyUser ? '' : 'Mogul or '}Enterprise for sentiment analysis`}
            data-tooltip-id={'general-tooltip'}
          />
        )}
      </h3>
      {type === 'membership' && <MembershipSelectFilter {...props} notIncludeName useInPopUp />}
      {filterName === 'tags' && <TagsFilter {...props} />}
      <div
        className={`${
          options.length >= splitItemValue && !showAllOptions && !notRow
            ? 'row'
            : options.length > 0
              ? 'd-flex flex-column'
              : ''
        }`}
      >
        {optionsToRender.map((option, index) => (
          <FilterItem
            option={option}
            options={optionsToRender}
            key={`option-filter${index}`}
            index={index}
            changeFilter={changeFilter}
            notRow={showAllOptions || notRow}
            type={type}
            showInColumn={notRow}
            parentDisabled={disabled}
            filterName={filterName}
          />
        ))}
      </div>
      {additionalItems.length > 0 && options.length > referenceValue && (
        <AdditionalItemDropdown
          additionalItems={additionalItems}
          changeFilter={changeFilter}
          additionalItemsText={additionalItemsText}
          type={type}
          showInColumn={notRow}
          parentDisabled={disabled}
          filterName={filterName}
          expanded={expandedByDefault}
        />
      )}
    </>
  );
};

const AdditionalItemDropdown = (props) => {
  const {
    additionalItems,
    changeFilter,
    additionalItemsText,
    type,
    showInColumn,
    parentDisabled,
    filterName,
    expanded = false,
  } = props;
  const [showItems, setShowItems] = useState(expanded);
  const [boxHeight, setBoxHeight] = useState(0);

  let boxReference = useRef();

  useEffect(() => {
    if (boxReference.current !== null) {
      if (expanded) {
        boxReference.current.style.height = 'auto';
        setBoxHeight(boxReference.current.getBoundingClientRect().height);
      } else {
        setTimeout(() => {
          boxReference.current.style.height = 'auto';
          setBoxHeight(boxReference.current.getBoundingClientRect().height);
          boxReference.current.style.height = 0;
        }, 300);
      }
    }
  }, [boxReference?.current]);

  return (
    <>
      <div
        className={`${additionalItems.length !== 3 && !showInColumn ? 'row' : ''} transition-item overflow-hidden`}
        ref={boxReference}
        style={{ height: showItems ? `${boxHeight}px` : '0px' }}
      >
        {additionalItems.map((option, index) => (
          <FilterItem
            option={option}
            options={additionalItems}
            key={`additional-filter${index}`}
            index={index + splitItemValue}
            changeFilter={changeFilter}
            type={type}
            notRow={showInColumn}
            parentDisabled={parentDisabled}
            filterName={filterName}
          />
        ))}
      </div>
      <button
        className={`mt-3 d-inline-block simple-link-button general-button showitem-button mb-3 ${
          showItems ? 'closeitem-button' : ''
        }`}
        onClick={() => setShowItems(!showItems)}
      >
        {showItems ? 'Close' : 'Show'} all {additionalItemsText ? additionalItemsText : ''}
      </button>
    </>
  );
};

const FilterItem = (props) => {
  const { option, options, index, changeFilter, notRow, insideDropDown, type, parentDisabled, filterName } = props;
  const { name, selected, suboptions, optionSelected, tooltip, disabled } = option;
  const TooltipComponent = () => {
    return (
      <>
        {tooltip && (
          <span
            className='pricing-feature-more'
            data-tooltip-content={tooltip}
            data-tooltip-id='general-tooltip'
            data-tooltip-place='left'
          >
            More information
          </span>
        )}
      </>
    );
  };

  return (
    <div
      className={`filter-item-container ${
        options.length >= splitItemValue && !notRow ? 'col-md-8' : 'filter-item-checkbox'
      } ${suboptions ? 'filter-item-checkbox-suboptions' : ''} ${insideDropDown ? 'filter-item-dropdown' : ''}`}
    >
      <div
        className={`checkbox-item filter-item-${normalize(name)} ${
          disabled || parentDisabled ? 'checkbox-item-disabled' : ''
        }`}
      >
        <input
          id={`${normalize(name)}${type === 'TagIsNot' ? type : ''}`}
          onChange={() => {
            if (filterName === 'tags') {
              changeFilter(selected, index, name);
            } else {
              changeFilter(selected, index);
            }
          }}
          type={`checkbox`}
          disabled={disabled || parentDisabled}
          checked={selected ?? false}
        />
        <label htmlFor={`${normalize(name)}${type === 'TagIsNot' ? type : ''}`}>
          {name}
          <TooltipComponent />
        </label>
      </div>
      {suboptions && (
        <ul className='policy-list filters-indented-list mb-0 pb-2 suboptions-list'>
          {suboptions.map((suboption, suboptionIndex) => {
            const { name } = suboption;
            const identifier = `${normalize(option.name)}-${normalize(name)}-${suboptionIndex}`;
            return (
              <li
                className={`radio-button ${suboptionIndex < suboptions.length - 1 ? 'mb-2' : ''}`}
                key={`${identifier}`}
              >
                <input
                  id={identifier}
                  onChange={() => {
                    changeFilter(selected, index, name);
                  }}
                  type='radio'
                  checked={optionSelected === name && selected}
                />
                <label htmlFor={identifier}>{name}</label>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export { FilterItem };
export default CommonFilter;
