import React, { useRef } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import overallExternalTooltipHandler from './OverallExternalTooltip';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const SmallPieChart = (props) => {
  const { data, title, clickFunction, hiddenItems } = props;
  const { value, colours, labels } = data;
  const chartRef = useRef();

  const emptyChart = data.value.length === 0 || data.value.filter((item) => item > 0).length === 0;

  const createData = () => {
    return {
      labels,
      datasets: [
        {
          label: 'Overall sentiment',
          data: value,
          backgroundColor: colours.map((item) => `rgba(${item}, 0.8)`),
          borderColor: colours.map((item) => `rgba(${item}, 1)`),
          borderWidth: 1,
        },
      ],
    };
  };

  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        padding: 10,
        displayColors: false,
        callbacks: {
          label: function (context) {
            let sum = data?.value.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
            let lf = new Intl.ListFormat('en-GB');
            let percentage = Math.round((value[context.dataIndex] * 100) / sum) + '%';
            return `${context.label}: ${percentage} of contributions in the last ${process.env.REACT_APP_SENTIMENT_SNAPSHOT_NUM_DAYS} days ${hiddenItems?.length ? `(with ${lf.format(hiddenItems.map((item) => item.toLowerCase()))} removed)` : ''}`;
          },
          title: (context) => {
            return `${context[0]?.label}`;
          },
        },
        external: overallExternalTooltipHandler,
      },
      datalabels: {
        display: true,
        align: 'end',
        offset: -5,
        clip: true,
        formatter: function (value, context) {
          return value > 0 ? labels[context.dataIndex] : '';
        },
      },
    },
    maintainAspectRatio: false,
    onClick: () => {
      if (clickFunction) {
        clickFunction();
      }
    },
  };

  return (
    <>
      <div
        style={{ height: '220px' }}
        className={`pie-chart-graph-container position-relative ${emptyChart ? 'flex-centered justify-content-center empty-small-pie-chart mx-auto' : ''}`}
      >
        {emptyChart ? (
          <p className='text-center mb-0 main-light-text'>No data</p>
        ) : (
          <Pie ref={chartRef} options={options} data={createData()} />
        )}
      </div>
      <h3 className='title-h6 text-center mt-4'>{title}</h3>
    </>
  );
};

export default SmallPieChart;
