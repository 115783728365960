import { getUrlParamRaw } from '../utilities/getUrlParam';

const changeSearchQuery = () => {
  let searchParam = getUrlParamRaw('search');
  let decodedQuery = decodeURIComponent(searchParam);
  if (decodedQuery.trim().split(' ').length > 1 && !searchParam.includes('"')) {
    return `"${decodedQuery}"`;
  } else {
    return decodedQuery;
  }
};

export default changeSearchQuery;
