import currentDate from '../../utilities/currentDateUTC';

const CalendarFilters = [
  /* {
    name: 'Sort',
    searchState: 'activeSearch',
    type: 'sort',
    pages: ['UpcomingEvents'],
    optionSelected: 'Most recent',
    hidden: true,
    options: [      
      {
        name: 'Most recent',
        filter: [
          {pages: ['UpcomingEvents'], property: {"field":"eventStartDateTimestamp", "order":"ASC" }}
        ]
      }
    ]
  }, */
  {
    name: 'Exclude past events',
    searchState: 'activeSearch',
    pages: ['UpcomingEvents'],
    filterName: 'eventStartDate',
    filterValue: currentDate,
    operator: 'DATE_GTE',
    type: 'toggle',
    value: true,
    phrase: 'excluding past events',
    hidden: true,
    notUseFromLocalStorage: true,
  },
  /* {
    name: 'Current date filter',
    searchState: 'activeSearch',
    pages: ['UpcomingEvents'],
    hidden: true,
    filterName: "eventStartDate",
    options: [
      {
        name: 'Current date',
        filterValue: currentDate,
        operator: 'DATE_GTE',
        selected: true
      }, 
    ]
  }, */
];

export default CalendarFilters;
