import { net_api_url } from '../../Store';
import axios from 'axios';
const makePlanCompliant = async (getAccessToken, user, source) => {
  try {
    let token = await getAccessToken();
    let planCompliant = await axios.post(
      `${net_api_url}/api/subscription/make-plan-compliant`,
      {
        userId: user.sub,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      }
    );
    return planCompliant.data;
  } catch (error) {
    console.error(error);
  }
};

export default makePlanCompliant;
