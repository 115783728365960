import { useContext } from 'react';
import { store } from '../../Store';
import { mentionsGraphNations } from './createAnalyticsState';

function useSelectedCountriesFilters(props) {
  const globalState = useContext(store);
  const { state } = globalState;
  const { analyticsState } = state;
  const { interestByRegionSection, selectedCountriesProps } = props ?? {};
  const { selectedCountries: selectedCountriesProp, selectedCountriesInterestByRegion } =
    props?.analyticsState ?? analyticsState;

  const selectedCountries = selectedCountriesProps
    ? selectedCountriesProps
    : window.location.pathname.startsWith('/analytics/dashboard')
      ? interestByRegionSection
        ? [mentionsGraphNations[0]]
        : mentionsGraphNations
      : window.location.pathname.includes('/interest-by-region') || interestByRegionSection
        ? selectedCountriesInterestByRegion
        : selectedCountriesProp;

  const getSelectedCountriesFilter = () => {
    let filters = [];
    if (selectedCountries?.find((item) => item.value === 'Scotland')) {
      filters = [
        ...filters,
        {
          field: 'contentType',
          value: 'ScotlandCountrySpecificParliamentaryContribution',
          operator: 'str_eq',
        },
        {
          field: 'contentType',
          value: 'ScotlandQuestionAndAnswer',
          operator: 'str_eq',
        },
      ];
    }
    if (selectedCountries?.find((item) => item.value === 'Wales')) {
      filters = [
        ...filters,
        {
          field: 'contentType',
          value: 'WalesCountrySpecificParliamentaryContribution',
          operator: 'str_eq',
        },
        {
          field: 'contentType',
          value: 'WalesQuestionAndAnswer',
          operator: 'str_eq',
        },
        {
          field: 'contentType',
          value: 'SeneddCommitteeTranscriptContribution',
          operator: 'str_eq',
        },
      ];
    }
    if (selectedCountries?.find((item) => item.value === 'UK')) {
      filters = [
        ...filters,
        {
          field: 'contentType',
          value: 'QuestionAndAnswer',
          operator: 'str_eq',
        },
        {
          field: 'contentType',
          value: 'ParliamentaryContribution',
          operator: 'str_eq',
        },
        {
          field: 'contentType',
          value: 'Edm',
          operator: 'str_eq',
        },
      ];
    }

    return filters;
  };

  const countrySpecificFilterMapping = {
    Scotland: {
      ParliamentaryContribution: 'ScotlandCountrySpecificParliamentaryContribution',
      QuestionAndAnswer: 'ScotlandQuestionAndAnswer',
    },
    Wales: {
      ParliamentaryContribution: 'WalesCountrySpecificParliamentaryContribution',
      QuestionAndAnswer: 'WalesQuestionAndAnswer',
    },
  };

  const coutrySpecificFilter = (country, value) => {
    return [
      {
        field: 'contentType',
        value: countrySpecificFilterMapping[country][value],
        operator: 'str_eq',
      },
    ];
  };

  const getCountrySpecificFilters = ({ value, field }) => {
    let filters = [];
    if (value !== 'ParliamentaryContribution' && value !== 'QuestionAndAnswer') {
      if ((value === 'Edm' && UKSelected) || (value === 'SeneddCommitteeTranscriptContribution' && WalesSelected)) {
        filters = [
          ...filters,
          {
            field: field,
            value: value,
            operator: 'str_eq',
          },
        ];
      }
    } else {
      if (UKSelected) {
        filters = [
          ...filters,
          {
            field: 'contentType',
            value: value,
            operator: 'str_eq',
          },
        ];
      }
      if (ScotlandSelected) {
        filters = [...filters, ...coutrySpecificFilter('Scotland', value)];
      }
      if (WalesSelected) {
        filters = [...filters, ...coutrySpecificFilter('Wales', value)];
      }
    }
    return filters;
  };

  const UKSelected = selectedCountries?.find((item) => item.value === 'UK');
  const WalesSelected = selectedCountries?.find((item) => item.value === 'Wales');
  const ScotlandSelected = selectedCountries?.find((item) => item.value === 'Scotland');

  return {
    getSelectedCountriesFilter,
    UKSelected,
    WalesSelected,
    ScotlandSelected,
    coutrySpecificFilter,
    getCountrySpecificFilters,
  };
}

export default useSelectedCountriesFilters;
