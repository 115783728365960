import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom-v5-compat';
import normalize from '../Dashboard/utilities/normalizeString';
import parliamentarianUsers from '../Dashboard/sidebar/parliamentarianUsers';
import { store } from '../Store';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';

const SettingsSidebar = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, activePlan } = state;
  const { enableCrm } = activeSubscriptions;
  const { code } = activePlan;
  const { headerSizes } = props;
  const { top, height } = headerSizes;

  const isParliamentarian = parliamentarianUsers(code);

  return (
    <div
      className={`main-navigation settings-navigation pt-4`}
      style={{
        top: `${top}px`,
        height: `${height}px`,
      }}
    >
      <div className='navigation-container d-flex justify-content-between flex-column'>
        <div className='pr-3 pb-4'>
          <h3 className='mb-3 title-h5-m-bold sidenav-subtitle sidenav-subtitle-influence'>General</h3>
          <ul className='px-0 mb-2'>
            <ListItems />
          </ul>
          {!isParliamentarian && enableCrm && (
            <>
              <h3 className='mb-3 title-h5-m-bold sidenav-subtitle sidenav-subtitle-influence'>Sending emails</h3>
              <ul className='px-0 mb-2'>
                <ListItems
                  list={[
                    { name: 'Email domains', icon: 'at-sign' },
                    { name: 'Sender email addresses', icon: 'send' },
                  ]}
                />
              </ul>
            </>
          )}
        </div>

        <div className='bottom-terms'>
          <div className='row mx-0 pt-2 pb-3 align-items-center bottom-terms-container'>
            <a href='https://about.policymogul.com/terms-and-conditions/' rel='noopener noreferrer' target='_blank'>
              T&Cs
            </a>
            <div className='px-2'></div>
            <a href='https://about.policymogul.com/privacy-policy/' rel='noopener noreferrer' target='_blank'>
              Privacy policy
            </a>
            <div className='px-2'></div>
            <p className='mb-0'>
              Logos by{' '}
              <a href=' https://clearbit.com/' rel='noopener noreferrer' target='_blank'>
                Clearbit
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ListItems = ({ list }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions } = state;
  const { subscriptionStatus } = activeSubscriptions;
  const { planCode } = activeSubscriptions;

  const { transformTopicOrClientWord, newAgencyPlan } = useTopicOrClientWord();
  const trialCondition = subscriptionStatus === 'InTrial' || subscriptionStatus === 'TrialEnded';

  const options = [
    { name: transformTopicOrClientWord({ plural: true, uppercase: true }), icon: 'topics' },
    { name: 'Alerts', icon: 'alert-medium' },
    { name: 'Embeddables', icon: 'code' },
    { name: 'Integration with Slack', icon: 'associated-content', url: 'integration-with-slack' },
    { name: 'Team', icon: 'team' },
    { name: 'Account', icon: 'suitcase' },
    {
      name: trialCondition ? 'Choose your plan' : 'Billing',
      customUrl: trialCondition ? '/pricing' : null,
      icon: 'billing',
    },
  ];
  const parliamentaryOptions = [
    { name: 'Team', icon: 'team' },
    { name: 'Topics', icon: 'topics' },
    { name: 'Embeddables', icon: 'code' },
    { name: 'Alerts', icon: 'email' },
    { name: 'Integration with Slack', icon: 'associated-content', url: 'integration-with-slack' },
    { name: 'Account', icon: 'suitcase' },
  ];

  const createOptions = () => {
    if (newAgencyPlan) {
      let newOptions = [...options];
      let position = newOptions?.findIndex((item) => item.name === 'Topics');
      newOptions.splice(position, 1);
      let billingPosition = newOptions?.findIndex((item) => item.name === 'Choose your plan');
      newOptions.splice(billingPosition, 1);
      return [{ name: 'Clients', icon: 'clients' }, ...newOptions];
    } else {
      return parliamentarianUsers(planCode) ? parliamentaryOptions : options;
    }
  };

  const finalOptions = list ?? createOptions();

  return (
    <>
      {finalOptions.map((item) => (
        <li key={`item-${normalize(item.name)}`}>
          <NavLink
            to={`${item.customUrl ?? `/settings/${item.url ?? normalize(item.name)}`}`}
            className={({ isActive }) => `${isActive ? 'active' : ''} px-2 pl-lg-5 sidebar-item setting-item d-flex`}
          >
            <span className={`icon-container-${item.icon}`}>
              <span className={`political-updates-nav-icon icon-${item.icon}`} />
            </span>
            <span>{item.name}</span>
          </NavLink>
        </li>
      ))}
    </>
  );
};

export default SettingsSidebar;
