import Modal from 'react-modal';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const ModalComponent = (props) => {
  const {
    children,
    isOpen,
    setIsOpen,
    maxWidth = 700,
    height = 'auto',
    onAfterClose = () => {},
    overlayClassName = 'react-modal-overlay',
    shouldCloseOnOverlayClick = true,
  } = props;

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={overlayClassName}
      className={'react-modal-content'}
      onRequestClose={closeModal}
      shouldFocusAfterRender={false}
      style={{
        content: {
          width: '90%',
          maxWidth: `${maxWidth}px`,
          height,
        },
      }}
      onAfterClose={onAfterClose}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(false);
        }}
        className='circle-button general-button hovered-button modal-close-button'
      >
        <span className='icon-close paragraph-p5' />
      </button>
      {children}

      <ReactTooltip
        effect={'solid'}
        id={'modal-tooltip'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
      />
    </Modal>
  );
};

export default ModalComponent;
