import React, { useEffect, useState } from 'react';
import MicroModal from 'micromodal';
import CreateEmailAlertForm, { useDefaultValuesEmailAlert } from '../../../Settings/Topics/CreateEmailAlertForm.js';

const AdminCreateEmailAlert = (props) => {
  const {
    emailAlertId,
    setIsOpenEditEmailAlert,
    setEmailAlertId,
    emailAlerts,
    keywordsLists,
    createUpdateEmailAlert,
    deleteEmailAlert,
    teamSubscriptions,
    userInformation,
    client,
  } = props;
  const { hasSlackConfigured } = userInformation;
  const { enableTwitter } = teamSubscriptions;

  const [isSaving, setIsSaving] = useState(false);

  const { createEmailAlertData } = useDefaultValuesEmailAlert({ enableTwitter, keywordsLists, emailAlerts });

  const [emailAlertData, setEmailAlertData] = useState(createEmailAlertData({ emailAlertId }));

  useEffect(() => {
    MicroModal.show('admin-create-email-alert', {
      awaitCloseAnimation: true,
    });
  }, [emailAlertId]);

  const closeMicroModal = () => {
    MicroModal.close('admin-create-email-alert');
    setTimeout(() => {
      setIsOpenEditEmailAlert(false);
      setEmailAlertId('');
    }, 300);
  };

  const saveEmailAlertFunction = async () => {
    setIsSaving(true);
    let saveFunction = await createUpdateEmailAlert(emailAlertData);
    if (!!saveFunction) {
      closeMicroModal();
    }
    setIsSaving(false);
  };

  const deleteEmailAlertFunction = async () => {
    setIsSaving(true);
    let deleteFunction = await deleteEmailAlert(emailAlertData.id);
    if (deleteFunction) {
      setIsSaving(false);
      closeMicroModal();
    }
  };

  const FormProperties = {
    keywordsLists,
    emailAlertData,
    setEmailAlertData,
    isSaving,
    saveEmailAlertFunction,
    deleteEmailAlertFunction,
    adminSubscriptions: teamSubscriptions,
    hasSlackConfigured,
    enableTwitter,
    adminPage: true,
    userInformation,
    client,
  };

  return (
    <div
      className={`modal micromodal-slide topic-modal create-email-alert-simple-modal create-email-alert-modal`}
      id={`admin-create-email-alert`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div className='modal__container pt-4' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
          <CreateEmailAlertForm {...FormProperties} />
        </div>
      </div>
    </div>
  );
};

export default AdminCreateEmailAlert;
