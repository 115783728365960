import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export const normalizedDateUK = (date) => dayjs(date).tz('Europe/London', true);

export const normalizedDateUtc = (date) => dayjs.utc(date);

export const getUKDayTime = () => {
  const hours = new Date().toLocaleString('en-GB', {
    hour: '2-digit',
    hour12: false,
    timeZone: 'Europe/London',
  });
  if (hours >= 18 || hours < 6) {
    return `dark`;
  } else {
    return `day`;
  }
};

export const normalMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const shortMonthsLowerCase = [
  'jan',
  'feb',
  'march',
  'april',
  'may',
  'june',
  'july',
  'aug',
  'sept',
  'oct',
  'nov',
  'dec',
];
