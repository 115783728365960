const uppercaseFirstLetter = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const lowerCaseFirstLetter = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toLowerCase() + s.slice(1);
};

export { lowerCaseFirstLetter };
export default uppercaseFirstLetter;
