import React from 'react';

function CrmEmailDummyItem({ dummyItem }) {
  return (
    <tr className='crm-table-item'>
      <td style={{ height: dummyItem?.itemHeight }}></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );
}

export default CrmEmailDummyItem;
