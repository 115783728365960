import React, { useState, useRef } from 'react';
import axios from 'axios';
import { net_api_url } from '../../../Store.js';
import Autosuggest from 'react-autosuggest';
import { throttle } from 'throttle-debounce';
const RegionAutocomplete = (props) => {
  const { visibilityOptions } = props;
  const { location } = visibilityOptions;
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputSuggest = useRef(null);

  const getSuggestionValue = (suggestion) => {
    return suggestion.value;
  };

  const renderInputComponent = (inputProps) => (
    <div className='flex-centered py-1'>
      <span className='icon-search mr-2 main-light-text' />
      <input {...inputProps} />
    </div>
  );

  const renderSuggestion = (suggestion) => {
    return (
      <div className='search-item row mx-0 text-item'>
        <div className='icon'></div>
        <div className='grow pl-2'>
          <p className='mb-0'>{suggestion.label}</p>
        </div>
      </div>
    );
  };

  const onChange = (event, { newValue, method }) => {
    if (method !== 'down' && method !== 'up') {
      setValue(newValue);
    }
  };

  const onSuggestionsFetchRequested = (value) => {
    if (value === '') {
      setOptions([]);
      return;
    }
    let url = `${net_api_url}/api/autocomplete/location?term=${encodeURIComponent(value)}`;
    axios
      .get(url)
      .then(function (response) {
        //AE: It had a .toLowerCase() and it was causing the case changing but I'm not sure if it was a specific functionality before.
        if (response.data.length > 0 && response.data[0].value === value.trim()) {
          setOptions(response.data);
        } else {
          setOptions([{ label: 'National', value: 'National' }].concat(response.data));
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  const autocompleteSearchThrottled = ({ value }) => {
    throttle(500, onSuggestionsFetchRequested(value));
  };

  const onSuggestionsClearRequested = () => {
    setOptions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setValue('');
    if (suggestionValue !== '') {
      location.setValue(suggestionValue);
      setShowSuggestions(false);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (value === '') {
        setValue('');
      }
    } else if (e.keyCode === 9) {
      e.preventDefault();
    }
  };

  const searchInputToFocus = useRef(null);
  return (
    <div className={`region-autocomplete m-0 ${showSuggestions && 'region-autocomplete-open'}`}>
      <p
        className='mb-0 region-autocomplete-main-value'
        onClick={() => {
          setShowSuggestions(true);
          if (!showSuggestions) {
            setTimeout(() => {
              searchInputToFocus.current.focus();
            }, 10);
          }
        }}
      >
        {location.value}
      </p>
      {showSuggestions && (
        <Autosuggest
          ref={inputSuggest}
          suggestions={options}
          onSuggestionsFetchRequested={autocompleteSearchThrottled}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={{
            ref: searchInputToFocus,
            placeholder: 'Search for a location',
            value,
            onChange: onChange,
            onKeyDown: onKeyDown,
            onClick: (e) => {
              if (value !== '') {
                e.target.select();
              }
            },
            onBlur: () => {
              //setValue(location.value)
              setShowSuggestions(false);
            },
          }}
          highlightFirstSuggestion={true}
        />
      )}
    </div>
  );
};

export default RegionAutocomplete;
