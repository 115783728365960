import { net_api_url } from '../../Store';
import axios from 'axios';
const getContactsCount = async (getAccessToken, source) => {
  try {
    let token = await getAccessToken();
    let contactsCount = await axios.get(`${net_api_url}/api/crm-contact/count`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    });
    return contactsCount.data;
  } catch (error) {
    console.error(error);
  }
};

export default getContactsCount;
