import { useEffect, useRef } from 'react';

const BigBen = () => {
  const deg = 6;
  const updateTime = useRef();
  const hr = useRef();
  const mn = useRef();
  const sc = useRef();

  const clockInterval = () => {
    let day = new Date();
    let hh = day.getHours() * 30;
    let mm = day.getMinutes() * deg;
    let ss = day.getSeconds() * deg;

    if (hr.current) {
      hr.current.style.transform = `rotateZ(${hh + mm / 12}deg)`;
    }
    if (mn.current) {
      mn.current.style.transform = `rotateZ(${mm}deg)`;
    }
    if (sc.current) {
      sc.current.style.transform = `rotateZ(${ss}deg)`;
    }
  };
  updateTime.current = () => {
    setInterval(clockInterval);
  };

  useEffect(() => {
    updateTime.current();
    return () => {
      clearInterval(clockInterval);
    };
  }, []);

  return (
    <div className={`big-ben-container`}>
      <div className='big-ben-background'>
        <div className='clock-background'>
          <div className='clock'>
            <div className='hour'>
              <div className='hr' ref={hr}></div>
            </div>
            <div className='min'>
              <div className='mn' ref={mn}></div>
            </div>
            <div className='sec'>
              <div className='sc' ref={sc}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigBen;
