const validateLocalStorage = () => {
  let storageSupported = false;
  try {
    storageSupported = window.localStorage && true;
  } catch (e) {}
  return storageSupported;
};

const storageSupported = validateLocalStorage();
export { storageSupported };
