import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { store } from '../../../../Store';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import CrmContactItem from './CrmContactItem/CrmContactItem';
import CrmContactsTableSkeleton, { CrmContactItemSkeleton } from './CrmContactsSkeleton';
import { useCallback } from 'react';
import { preventHistoryBack } from '../../../helpers/preventHistoryBack';
import useGetSectionFilters from '../../../Filters/useGetSectionFilters';
import NoContactsEmptyMessage from './NoContactsEmptyMessage';
import EmptyMessage from '../../../components/EmptyMessage';
import isDummyTableItem from '../../../helpers/isDummyTableItem';
import { loadMoreTableRowsDependOnScreen } from '../../../helpers/loadMoreTableRowsDependOnScreen';
import addMetaTags from '../../../utilities/addMetaTags';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import useGetAccessToken from '../../../apiCalls/useGetAccessToken';

const CrmContactsTable = ({
  selectedCrmContacts,
  setSelectedCrmContacts,
  setPreviousStoredSelectedCrmContacts,
  setTotalHits,
  isLoading,
  setIsLoading,
  initialCall,
  setShowAddTagsModal,
}) => {
  const columns = [
    {
      accessorKey: 'checkbox',
      header: '',
      ref: useRef(),
    },
    {
      accessorKey: 'title',
      header: 'Name',
      ref: useRef(),
    },
    {
      accessorKey: 'championStatus',
      header: 'Champion status',
      ref: useRef(),
    },
    {
      accessorKey: 'role',
      header: 'Role',
      ref: useRef(),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      ref: useRef(),
    },
    {
      accessorKey: 'email',
      header: 'Email',
      ref: useRef(),
    },
    {
      accessorKey: 'tags',
      header: 'Tags',
      ref: useRef(),
    },
    {
      accessorKey: 'createdAtUtc',
      header: 'Date added',
      ref: useRef(),
    },
    {
      accessorKey: 'addedByUserId',
      header: 'Added by',
      ref: useRef(),
    },
    {
      accessorKey: 'salutation',
      header: 'Salutation',
      ref: useRef(),
    },
  ];

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    contentResults,
    activeResults,
    net_api_url,
    addedCustomContacts,
    crmContactsTableOnSort,
    contentFilters,
    removedContactsQntty,
  } = state;
  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { hash, filters } = sectionFilters;

  const { CrmContacts } = contentResults[activeResults];
  const [currentlyProcessing, setCurrentlyProcessing] = useState({
    attempt: 0,
    state: false,
  });
  const [tableHeight, setTableHeight] = useState('auto');
  const [scrollTopContainer, setScrollTopContainer] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const [activeIndex, setActiveIndex] = useState(null);
  const [pageSize, setPageSize] = useState(11);
  const [scrollPositionX, setScrollPositionX] = useState(crmContactsTableOnSort?.scrollPositionX);
  const tableElement = useRef(null);
  const rowRef = useRef();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollCondition = CrmContacts && CrmContacts.scrollPosition;

  const query = new URLSearchParams(location.search);
  const sort = query.get('sort') ? query.get('sort').split('_') : null;
  const searchQuery = query.get('search') ? decodeURIComponent(query.get('search')) : '';

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const { isAuthenticated } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  initialCall.current = (source) => {
    setCurrentlyProcessing({ attempt: 0, state: false });
    const loadResults = async () => {
      try {
        await createResults({ source });
      } catch (error) {
        console.error(error);
      }
    };
    if (!scrollCondition) {
      loadResults();
    } else {
      setIsLoading(false);
      //AE: Necessary to have a setTimeOut to update the scrollPosition and allow continue using the list correctly. (Without this if you clear the search or change the topic it will not work.)
      setTimeout(() => {
        let CrmContactsWithoutScroll = {
          ...CrmContacts,
          scrollPosition: null,
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              CrmContacts: CrmContactsWithoutScroll,
            },
          },
        });
      }, 300);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setScrollTopContainer({
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
    });
    setTimeout(() => {
      initialCall.current(source);
    }, 10);

    return () => {
      source.cancel('CrmContacts canceled by the user.');
    };
  }, [hash, searchQuery, contentFilters, addedCustomContacts.length, removedContactsQntty, crmContactsTableOnSort]);

  useEffect(() => {
    setTotalHits(CrmContacts?.totalHits);
  }, [CrmContacts]);

  useEffect(() => {
    if (!isLoading && scrollPositionX) {
      scrollBarRef.current.scrollLeft = scrollPositionX;
    }
  }, [isLoading, CrmContacts]);

  async function createResults({ pageNumber, processing, source: sourceProp }) {
    if (!pageNumber && !processing) {
      setIsLoading(true);
    }
    let token = isAuthenticated ? await getAccessToken() : '';
    let url = `${net_api_url}/api/crm-contact/query`;
    let requestBody = {
      filters: filters,
      query: searchQuery,
      pageSize: pageSize,
      pageNumber: pageNumber ? pageNumber : 1,
    };

    let requestOptions = {
      cancelToken: sourceProp ? sourceProp.token : source.token,
    };

    if (isAuthenticated) {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    if (sort) {
      requestBody = {
        ...requestBody,
        sort: { field: sort[0], order: sort[1] },
      };
    }

    let callResults = await axios.post(url, requestBody, requestOptions);

    let results = callResults.data;
    let contactsIds = pageNumber ? CrmContacts.contactsIds : [];
    let hitsResults = pageNumber ? CrmContacts.hits : [];

    if (results.hits !== null) {
      contactsIds = contactsIds.concat(results.contactsIds);
      hitsResults = hitsResults.concat(results.hits);
    }
    let currentId = results.hits !== null && results.hits.length > 0 ? results.hits[0].id : 0;
    let ResultsToState = {
      ...results,
      currentId: currentId,
      contactsIds: contactsIds,
      currentPositionId: contactsIds.indexOf(currentId),
      hits: hitsResults,
      thereIsMoreContent: results.hits !== null ? results.hits.length >= pageSize : false,
    };

    let CrmContactsResults = {
      ...contentResults,
      [activeResults]: {
        CrmContacts: ResultsToState,
      },
    };
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'contentResults',
      value: CrmContactsResults,
    });
    setIsLoading(false);
  }

  //SET FILTERS TO URL
  useEffect(() => {
    if (crmContactsTableOnSort?.sort?.length) {
      query.set('sort', crmContactsTableOnSort?.sort);
    }
    let urlToHistory = `${location.pathname}?${query.toString()}${hash !== '' ? `#${hash}` : ''}`;
    let title = `Contacts - Influence - PolicyMogul`;
    addMetaTags({ title, hash, location, dispatch });
    setTimeout(() => {
      navigate(urlToHistory, { replace: true });
    }, 0);
  }, [hash, crmContactsTableOnSort]);

  //LOAD ONSCROLL
  const load_more_results = async () => {
    if (CrmContacts !== undefined) {
      let { thereIsMoreContent, pageNumber } = CrmContacts;
      if (thereIsMoreContent) {
        await createResults({ pageNumber: pageNumber + 1 });
      }
    }
  };

  const scrollBarRef = useRef(null);
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setScrollTopContainer({ scrollTop, scrollHeight, clientHeight });
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      setScrollPositionX(0);
      load_more_results();
    }
  };

  const isDummyItem = useCallback((props) => isDummyTableItem(props), [scrollTopContainer]);

  let hitsToRender = CrmContacts?.hits ?? [];

  //HANDLING QUERY PARAMS FOR SORTING
  const handleChangeSortParam = (accessorKey) => {
    setScrollPositionX(scrollBarRef.current.scrollLeft);
    const sort = query.get('sort') ? query.get('sort').split('_') : null;
    if (sort) {
      if (sort[0] === accessorKey) {
        if (sort[1] !== 'desc') {
          query.set('sort', `${accessorKey}_desc`);
        } else {
          query.set('sort', `${accessorKey}_asc`);
        }
      } else {
        query.set('sort', `${accessorKey}_asc`);
      }
    } else {
      query.set('sort', `${accessorKey}_asc`);
    }
    let urlToHistory = `${location.pathname}?${query.toString()}${hash !== '' ? `#${hash}` : ''}`;
    navigate(urlToHistory, { replace: true });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'crmContactsTableOnSort',
      value: {
        sort: query.get('sort'),
      },
    });
  };

  //BEHAVIOUR OF CHECKBOX IN TABLE HEADER
  const handleCheck = (e) => {
    if (!e.target.checked) {
      setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
      setSelectedCrmContacts({
        selectedAll: false,
        selectedIds: [],
        selectedAllVisible: [],
      });
    } else {
      setPreviousStoredSelectedCrmContacts(selectedCrmContacts);
      setSelectedCrmContacts({
        selectedAll: true,
        selectedIds: [],
        selectedAllVisible: [],
      });
    }
  };

  const contactIds = CrmContacts?.hits.map((item) => item.id);
  const isSelected = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;

  useEffect(() => {
    if (tableElement.current) {
      setTableHeight(tableElement.current.clientHeight);
    }
  }, [tableElement?.current?.clientHeight]);

  //PREVENT HISTORY BACK ON TRACKPAD SCROLL
  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.addEventListener('mousewheel', (e) => preventHistoryBack(e, scrollBarRef.current));
      loadMoreTableRowsDependOnScreen({
        containerHeight: scrollBarRef.current.clientHeight,
        cellHeight: 62,
        headerHeight: 50,
        setPageSize,
        pageSize,
      });
    }
  }, [scrollBarRef.current]);

  useEffect(() => {
    if (pageSize > 11) {
      load_more_results();
    }
  }, [pageSize]);

  const mouseMove = useCallback(
    (e) => {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex) {
          const championStatusActiveIndex = 2; //AE: Prevent resizing champion status
          const referenceValue = activeIndex === championStatusActiveIndex ? 225 : 160;
          let width = e.clientX - columns[activeIndex].ref.current.getBoundingClientRect().left;
          width = width > referenceValue ? width : referenceValue;
          return `${width}px`;
        }
        return `${col.ref.current.offsetWidth}px`;
      });

      tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    },
    [activeIndex, columns]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  const mouseDown = (e, index) => {
    e.stopPropagation();
    setActiveIndex(index);
  };

  const renderTableHeading = () => {
    const columnOnSort = (col) => {
      const params = new URLSearchParams(location.search);
      const sort = params.get('sort') ? params.get('sort').split('_') : null;
      if (sort) {
        return sort[0] === col.accessorKey ? sort[1] : false;
      }
    };
    const renderSortArrows = (sort) => {
      return (
        <div className='crm-table-arrows-container'>
          <span style={{ opacity: sort === 'asc' ? 1 : 0.4 }} />
          <span style={{ opacity: sort === 'desc' ? 1 : 0.4 }} />
        </div>
      );
    };
    return (
      <thead>
        <tr>
          {columns.map((col, index) => {
            if (index === 0) {
              return (
                <th key={index} ref={col.ref}>
                  <div className='crm-table-header'>
                    <div className={`checkbox-crm-table ${selectedAll ? 'checkbox-crm-table-select-all' : ''}`}>
                      <input
                        type={'checkbox'}
                        id={`select-all-crm-list`}
                        onChange={handleCheck}
                        checked={isSelected}
                        value={isSelected}
                      />
                      <label htmlFor={`select-all-crm-list`} />
                    </div>
                  </div>
                </th>
              );
            }
            return (
              <th
                key={index}
                style={{
                  backgroundColor: columnOnSort(col) === 'desc' || columnOnSort(col) === 'asc' ? '#fafafa' : '#fff',
                }}
                ref={col.ref}
              >
                <div className='crm-table-header' onClick={() => handleChangeSortParam(col.accessorKey)}>
                  {col.header}
                  {renderSortArrows(columnOnSort(col))}
                </div>
                <div
                  style={{ height: tableHeight }}
                  className='resize-handle-influence-column-container'
                  onMouseDown={(e) => mouseDown(e, index)}
                >
                  <div
                    className={`resize-handle-influence-column ${
                      activeIndex === index ? 'active-column-influence' : ''
                    }`}
                  />
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  return (
    <div className={`row mx-0 h-100 ${!isLoading && !CrmContacts?.totalHits ? 'pr-md-4 pr-3' : ''}`}>
      <div className='flex-grow-1' style={isLoading ? { overflow: 'hidden' } : null}>
        {isLoading && <CrmContactsTableSkeleton />}
        {!isLoading &&
          (!CrmContacts?.totalHits ? (
            <div
              className='contact-list-scrollbar-table-no-data'
              style={{ marginTop: '-42px', height: 'calc(100% + 42px)' }}
            >
              {!query.get('search') && !filters.length ? (
                <div
                  className='crm-table-no-contacts w-100'
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: '52px',
                    gap: '5px',
                    backgroundColor: 'rgba(0, 148, 204, 0.04)',
                  }}
                >
                  <NoContactsEmptyMessage />
                </div>
              ) : (
                <div className='w-100'>
                  <EmptyMessage contactsTable emptyMessageResourceName={'contacts'} />
                </div>
              )}
            </div>
          ) : (
            <>
              {!currentlyProcessing.state && !isLoading && (
                <>
                  <div className='contact-list-scrollbar-table-wrapper'>
                    <div className='stakeholder-list-content-scrollbar' />
                    <div className='contact-list-scrollbar-table' onScroll={handleScroll} ref={scrollBarRef}>
                      {hitsToRender.length > 0 && (
                        <table
                          className='crm-table crm-table-contacts'
                          ref={tableElement}
                          style={{
                            userSelect: activeIndex || activeIndex === 0 ? 'none' : 'auto',
                          }}
                        >
                          {renderTableHeading()}
                          <tbody>
                            {hitsToRender.map((item, index, array) => {
                              return (
                                <CrmContactItem
                                  ref={index === 1 ? rowRef : undefined}
                                  array={array}
                                  item={item}
                                  key={`item${item.contactId}-${index}`}
                                  index={index}
                                  setSelectedCrmContacts={setSelectedCrmContacts}
                                  setPreviousStoredSelectedCrmContacts={setPreviousStoredSelectedCrmContacts}
                                  selectedCrmContacts={selectedCrmContacts}
                                  contactIds={contactIds}
                                  hitsToRender={hitsToRender}
                                  dummyItem={
                                    CrmContacts.totalHits > 50
                                      ? isDummyItem({
                                          index,
                                          itemHeight: 61.8,
                                          cachedInvisibleItems: 1,
                                          scrollTopContainer,
                                          pageSize,
                                        })
                                      : false
                                  }
                                  setShowAddTagsModal={setShowAddTagsModal}
                                  scrollBarRef={scrollBarRef}
                                  activeIndex={activeIndex}
                                />
                              );
                            })}
                            {CrmContacts?.thereIsMoreContent && <CrmContactItemSkeleton />}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default CrmContactsTable;
