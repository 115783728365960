import React, { useEffect } from 'react';
import Loading from './Loading';
import { useAuth0 } from '@auth0/auth0-react';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import * as QueryString from 'query-string';
const Initialise = () => {
  const getLocationToRedirect = function () {
    var redirectUrl = getUrlParam('redirectUrl');

    if (redirectUrl === undefined) {
      redirectUrl = '%2F';
    }

    let locationToRedirect = decodeURIComponent(redirectUrl);

    var n = locationToRedirect.indexOf('?');

    if (n > 0) {
      var finalQueryString = '';

      var queryStringParams = QueryString.parse(locationToRedirect.substr(n));
      var queryStringKeys = Object.keys(queryStringParams);

      for (var i = queryStringKeys.length - 1; i >= 0; i--) {
        if (queryStringKeys[i].toLowerCase() !== 'code' && queryStringKeys[i].toLowerCase() !== 'state') {
          if (finalQueryString.length === 0) {
            finalQueryString = '?';
          } else {
            finalQueryString += '&';
          }
          finalQueryString += queryStringKeys[i] + '=' + encodeURIComponent(queryStringParams[queryStringKeys[i]]);
        }
      }

      locationToRedirect = locationToRedirect.substr(0, n) + finalQueryString;
    }

    return locationToRedirect;
  };

  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    // We are not logged in and we are not verified hence nothing to initialise
    if (!isAuthenticated || !user.email_verified) {
      return window.location.replace(`${window.location.origin}${getLocationToRedirect()}`);
    } else {
      return window.location.replace(`${window.location.origin}${getLocationToRedirect()}`);
    }
  }, []);

  return (
    <>
      <Loading />
    </>
  );
};

export default Initialise;
