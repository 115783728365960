import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonCalendarSearch = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <ul className='policy-list px-0 items-list'>
        {Array(3)
          .fill()
          .map((item, index) => (
            <div key={`item${index}`}>
              <li className={`my-2 p-3`} key={index}>
                <div className='content-title pr-lg-5 pr-2 ml-2'>
                  <div className='mb-4'>
                    <Skeleton height={24} width={`40%`} />
                  </div>
                  {Array(2)
                    .fill()
                    .map((item, index) => (
                      <div key={`item${index}`}>
                        <div className='mt-4 mb-1'>
                          <Skeleton height={10} width={`30%`} />
                        </div>
                        <div className='my-1'>
                          <Skeleton height={19} width={`100%`} />
                        </div>
                        <div className='my-1'>
                          <Skeleton height={19} width={`70%`} />
                        </div>
                      </div>
                    ))}
                </div>
              </li>
            </div>
          ))}
      </ul>
    </SkeletonTheme>
  );
};
export default SkeletonCalendarSearch;
