import React, { useCallback } from 'react';
import MicroModal from 'micromodal';
const Popup = (props) => {
  const {
    setShowPopup,
    identifier,
    additionalClass,
    children,
    additionalOpenAction,
    additionalCloseAction,
    disableFocus = true,
    maxWidth,
    awaitOpenAnimation = true,
    modalContainerStyles = {},
  } = props;
  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      if (additionalOpenAction) {
        additionalOpenAction();
      }
      MicroModal.show(`popup-${identifier}`, {
        awaitOpenAnimation,
        awaitCloseAnimation: true,
        disableFocus,
      });
    }
  }, []);

  const closeMicroModal = () => {
    //IMPORTANT TO CHECK IF WE NEED TO DO SOEMETHING ADDITIONAL ON THIS FUNCTION
    MicroModal.close(`popup-${identifier}`);
    setTimeout(() => {
      setShowPopup(false);
      if (additionalCloseAction) {
        additionalCloseAction();
      }
    }, 300);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    //https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { closeMicroModal });
    }
    return child;
  });

  return (
    <div
      className={`modal micromodal-slide website-feeds-modal ${additionalClass ? additionalClass : ''}`}
      id={`popup-${identifier}`}
      aria-hidden='true'
      tabIndex='-1'
      ref={micromodalElement}
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='transparent-close-modal'
          onClick={() => {
            closeMicroModal();
          }}
        />
        <div
          className='modal__container'
          style={{ maxWidth: maxWidth ?? undefined, ...modalContainerStyles }}
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
        >
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
          {childrenWithProps}
        </div>
      </div>
    </div>
  );
};

export default Popup;
