import React from 'react';
import normalize from '../../utilities/normalizeString';
const SortFilter = (props) => {
  const { filter, selectOption, notIncludeName, setExpanded } = props;
  const { options, optionSelected, collapseOnSelect } = filter;
  return (
    <>
      {!notIncludeName && <h3>Sort by</h3>}
      <div className=''>
        {options.map((option, index) => {
          const { name, labelClassName } = option;
          return (
            <div key={`option-filter${index}`} className='mb-3'>
              <div className={`radio-button`}>
                <input
                  id={normalize(name)}
                  onChange={() => {
                    let newValue = name;
                    selectOption({ filter, newValue });
                    if (collapseOnSelect && setExpanded) {
                      setExpanded(false);
                    }
                  }}
                  type={`radio`}
                  checked={optionSelected === name}
                />
                <label htmlFor={normalize(name)} className={`${labelClassName ? labelClassName : ''}`}>
                  {name}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default SortFilter;
