import React from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import createUtmParameters from '../helpers/createUtmParameters';
const LoginButtons = () => {
  const location = useLocation();
  return (
    <div className='d-flex justify-content-lg-end login-buttons'>
      <>
        <button
          className='btn btn-default btn-login pr-xl-4 pl-0 pl-lg-2'
          onClick={() => {
            window.location.assign(
              `/login?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`
            );
          }}
        >
          Log in
        </button>
        <button
          className='btn btn-default btn-register px-3 pxl-lg-1 px-xl-3'
          onClick={() => {
            window.location.assign(`/register?reg_source=topnav-start-free-trial-button${createUtmParameters('&')}`);
          }}
        >
          Start free trial
        </button>
      </>
    </div>
  );
};

export default LoginButtons;
