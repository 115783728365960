import { useContext } from 'react';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import { chartDataOptions } from './createAnalyticsState';
import useAnalyticsIntervalLimit from './useAnalyticsIntervalLimit';
import useCallMentionsByTopic from './useCallMentionsByTopic';
import useSelectedCountriesFilters from './useSelectedCountriesFilters';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import ChangeSearchQuery from '../utilities/changeSearchQuery';

const useCallMostVocal = (props) => {
  const { teamId, selectedCountriesProps } = props ?? {};
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, analyticsState } = state;

  const { currentView, activeDateInterval } = analyticsState;

  const topicParameter = getUrlParam('topic-id');
  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const defaultTopic = topicParameter
    ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
    : keywordsListsToUse[0];

  const { getCountrySpecificFilters } = useSelectedCountriesFilters({ selectedCountriesProps });

  const { createFilters } = useCallMentionsByTopic();

  const parliamentaryPositionsFilters = chartDataOptions.find(
    (item) => item.name === 'Parliamentary position'
  )?.filters;

  const { conditionOfPublicApi, validateDataForLoggedOutUsers } = useAnalyticsIntervalLimit();
  const { generalApiCall } = useGeneralApiCall();

  const callMostVocal = async ({
    view = currentView,
    topic = defaultTopic,
    dateInterval = activeDateInterval.filter,
    freeSearch,
  }) => {
    try {
      let publicApi = freeSearch && conditionOfPublicApi;
      let pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${publicApi ? 'public-' : ''}aggregation${!!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''}-v2`;
      const method = 'post';
      let filters = [
        ...parliamentaryPositionsFilters,
        ...createFilters(dateInterval, view),
        ...getCountrySpecificFilters({ value: 'ParliamentaryContribution' }),
      ];

      let requestProperties = {
        type: 'ParliamentaryRecord',
        filters: filters,
        bucketField: 'contactIds',
        order: 'desc',
        bucketMaxCount: 3,
      };

      if (topic?.prospective) {
        const { keywords, andKeywords, keywordExclusions } = topic;
        requestProperties = {
          ...requestProperties,
          query: ChangeKeywordsCategory(keywords),
          andQueries: ChangeKeywordsExclusions(andKeywords),
          mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
        };
      } else if (freeSearch) {
        requestProperties = {
          ...requestProperties,
          query: ChangeSearchQuery(freeSearch),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          keywordListId: topic.id,
        };
      }
      if (teamId) {
        requestProperties = {
          ...requestProperties,
          teamId,
        };
      }
      const results = await generalApiCall({
        pathname,
        method,
        requestProperties,
        needsAuthentication: !publicApi,
        notShowErrorMessage: true,
        returnError: true,
      });

      if (!validateDataForLoggedOutUsers(results)) {
        return [];
      }
      if (!!results) {
        if (results?.values?.length > 0) {
          if (teamId) {
            const { values, stakeholders } = results;
            let baseResults = [];
            values?.forEach((result) => {
              let item = stakeholders?.hits?.find((item) => `${item.id}` === result.key);
              if (!!item) {
                baseResults.push({
                  ...item,
                  numberOfContributions: result?.value,
                });
              }
            });
            return baseResults;
          } else {
            let contacts = await getStakeholders({ results: results?.values, freeSearch });
            if (contacts) {
              return contacts;
            }
          }
        } else {
          return results?.values;
        }
      }
    } catch (error) {}
  };

  const getStakeholders = async ({ results, freeSearch }) => {
    try {
      const publicApi = freeSearch && conditionOfPublicApi;
      const pathname = `/api/stakeholder/${publicApi ? 'public-' : ''}list`;
      const method = 'post';
      const requestProperties = {
        ids: results.map((item) => item.key),
      };

      const contacts = await generalApiCall({ pathname, method, requestProperties, needsAuthentication: !publicApi });
      if (contacts) {
        let baseResults = [];
        results?.forEach((result) => {
          let item = contacts?.hits?.find((item) => `${item.id}` === result.key);
          if (!!item) {
            baseResults.push({
              ...item,
              numberOfContributions: result?.value,
            });
          }
        });
        return baseResults;
      }
    } catch (error) {}
  };

  return { callMostVocal };
};

export default useCallMostVocal;
