import React from 'react';
import { useHeightContainer } from '../utilities/useHeightContainer';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import createUtmParameters from '../helpers/createUtmParameters';
const StakeHolderNoMogulUsers = (props) => {
  const navigate = useNavigate();
  const [heightContainer, containerRef] = useHeightContainer();
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const { title, subtext, image, imageClass } = props;
  return (
    <div className='mx-auto main-content-wrapper'>
      <div className={`pt-3 stake-holder-no-mogul`}>
        <div className='settings-box p-lg-5 py-4 px-3' ref={containerRef} style={{ height: `${heightContainer}px` }}>
          <div className='row mx-0 mt-3'>
            <div className='col-lg-7 pt-3'>
              <h3>{title ? title : 'Influence starts with insight'}</h3>
              <p className='mt-4 mb-5'>
                {subtext
                  ? subtext
                  : 'Identify the ever-changing stakeholders relevant to your interests. Replace hours of research with the click of a button'}
              </p>
              <button
                className='general-button action-button px-4 py-2'
                onClick={() => {
                  if (isAuthenticated) {
                    navigate(`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`);
                  } else {
                    window.location.assign(`/register?reg_source=stakeholders-page${createUtmParameters('&')}`);
                  }
                }}
              >
                {isAuthenticated ? 'Compare plans' : 'Try it for free'}
              </button>
            </div>
            <div className='col-lg-9 image-stakeholder-nomogul pl-4 pt-3 d-none d-lg-block'>
              <img
                src={`${process.env.REACT_APP_CDNURL}${image ?? '/images/20210430-stakeholder-screenshot.jpg'}`}
                className={`img-fluid w-100 image-render-optimized ${imageClass ?? ''}`}
                alt={'Preview of the stakeholders section'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeHolderNoMogulUsers;
