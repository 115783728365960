import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom-v5-compat';
import { store } from '../../Store';
import CreateAccountPopup from '../components/CreateAccountPopup';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import useIsMobile from '../utilities/useIsMobile';

const ResearchPortalButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableReferenceSection } = activePlan;
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const { isAuthenticated } = useAuth0();
  const params = useParams();
  const location = useLocation();

  const { inboxSection } = useMainSectionParameter(params);
  const isMobile = useIsMobile();

  const electionPage = location.pathname.includes('/general-election-2024');

  return (
    <>
      {!inboxSection && !enableReferenceSection && !electionPage && (
        <div className='pb-5'>
          <Link
            to={`${isAuthenticated ? `/pricing?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}` : '/register?reg_source=use-research-portal-button'}`}
            className='general-button simple-link-button'
          >
            For all results {isMobile ? '' : 'and filters'},{' '}
            {isAuthenticated ? 'please upgrade to a paid subscription' : 'create an account'}
          </Link>
        </div>
      )}

      {showRegisterPopup && !isAuthenticated && (
        <CreateAccountPopup
          text={'You’ll need to create an account to use advanced search'}
          setShowPopup={setShowRegisterPopup}
        />
      )}
    </>
  );
};

export default ResearchPortalButton;
