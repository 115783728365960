import { useAuth0 } from '@auth0/auth0-react';
const useGetAccessToken = () => {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();
  const getAccessToken = async (options) => {
    try {
      let token = isAuthenticated ? await getAccessTokenSilently(options) : '';
      return token;
    } catch (error) {
      // Handle an expired token by redirecting the user to login
      if (error.error === 'invalid_grant' || error.error === 'login_required' || error.error === 'consent_required') {
        logout({ logoutParams: { returnTo: window.location.origin } });
        return;
      }
    }
  };

  return { getAccessToken };
};

export default useGetAccessToken;
