import { net_api_url } from '../../Store';
import axios from 'axios';
const getActiveSubscriptions = async (getAccessToken, source, includeScheduledSubscription) => {
  try {
    let token = await getAccessToken();
    let activeSuscriptions = await axios.get(
      `${net_api_url}/api/payment/get-active-subscriptions${includeScheduledSubscription ? '?includeScheduledSubscription=true' : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      }
    );
    //Needs to review if there is more than 1 subscription;
    return activeSuscriptions.data[0];
  } catch (error) {
    console.error(error);
  }
};

export default getActiveSubscriptions;
