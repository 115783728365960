import React, { useContext } from 'react';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import { store } from '../../Store';
import createFrequencyName from './Helpers/createFrequencyName';
import createResourcesName from './Helpers/createResourcesName';
const EmailAlertInformation = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists: mainKeywordsLists, activeSubscriptions } = state;
  const { planCode } = activeSubscriptions;

  const { emailAlert } = props;
  const {
    frequency,
    dayOfWeek,
    timeOfDay,
    secondTimeOfDay,
    contentTypesToReceive,
    digestCombiningAllTopics,
    keywordLists,
  } = emailAlert;
  const { transformTopicOrClientWord } = useTopicOrClientWord();

  const emailAlertsName = (contentTypesToReceive) => {
    let resourcesName = createResourcesName(contentTypesToReceive, null, planCode);
    return `Includes ${resourcesName.replace('Everything', 'all sources')} ${contentTypesToReceive.split(',').length === 1 ? 'only' : ''}`;
  };

  const digestConditional = digestCombiningAllTopics && mainKeywordsLists && mainKeywordsLists.length > 1;

  var sentText = createFrequencyName(frequency, dayOfWeek, timeOfDay, secondTimeOfDay);
  if (sentText === 'Immediate') {
    sentText = 'immediately';
  } else if (sentText.startsWith('weekdays')) {
    sentText = sentText[0].toLowerCase() + sentText.substring(1);
  }

  return (
    <>
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className='pricing-feature-more'
        data-tooltip-html={`• For ${digestConditional ? `all of your ${transformTopicOrClientWord({ plural: true })}` : digestCombiningAllTopics ? `${mainKeywordsLists.map((item) => item.name).join(', ')}` : `${keywordLists.map((item) => item.name).join(', ')}`}<br/>• Sent ${sentText}<br/>• ${emailAlertsName(contentTypesToReceive)}`}
        data-tooltip-id='setting-tooltip-all'
      >
        More information
      </span>
    </>
  );
};

export default EmailAlertInformation;
