import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonParliamentary = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div>
        <ul className='policy-list px-0 items-list'>
          {Array(9)
            .fill()
            .map((item, index) => (
              <SkeletonParliamentaryItem key={index} />
            ))}
        </ul>
      </div>
    </SkeletonTheme>
  );
};

const SkeletonParliamentaryItem = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <li className={`my-2`}>
        <div className='row mx-0 p-3 align-items-center'>
          <div className='mr-3'>
            <Skeleton height={19} width={19} />
          </div>
          <div className='col-12 col-sm-13 pr-lg-5 pr-2 pl-0'>
            <Skeleton height={19} width={`80%`} />
            <Skeleton height={19} width={`30%`} />
          </div>
          <div className='col-4 col-sm-2 d-none d-md-block'>
            <Skeleton height={13} width={`100%`} />
            <div className='mx-auto d-flex flex-column align-items-center'>
              <Skeleton height={13} width={`65px`} />
            </div>
          </div>
        </div>
      </li>
    </SkeletonTheme>
  );
};
export { SkeletonParliamentaryItem };
export default SkeletonParliamentary;
