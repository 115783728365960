import { sectionName } from '../helpers/controlActiveFilters';
import useGetSectionFilters from './useGetSectionFilters';

const useSetFiltersLocalStorageByType = () => {
  const section = sectionName();
  const { sectionStored } = useGetSectionFilters(section);

  const setFiltersLocalStorageByType = ({ type, options }) => {
    let storedFilters = localStorage.getItem('contentFilters');
    let finalObject = {};
    if (storedFilters) {
      let filtersParsed = JSON.parse(storedFilters);
      if (Array.isArray(filtersParsed)) {
        finalObject = {
          ...finalObject,
          initialFilters: filtersParsed,
        };
      } else {
        finalObject = filtersParsed;
      }
    }
    let sectionToStore = sectionStored;
    finalObject[sectionToStore].map((item) => {
      if (item.type === type) {
        return { ...item, options };
      } else {
        return item;
      }
    });
  };

  return { setFiltersLocalStorageByType };
};

export default useSetFiltersLocalStorageByType;
