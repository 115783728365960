import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { store } from '../../Store';

const BackButton = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { clickedOnContentUrl } = state;
  const navigate = useNavigate();

  return (
    <>
      {clickedOnContentUrl && (
        <button
          className='general-button back-content-button blue-text'
          onClick={() => {
            navigate(clickedOnContentUrl);
            dispatch({ type: 'MODIFY_SECTION', parameter: 'clickedOnContentUrl', value: null });
          }}
        >
          <span className='arrow-left-blue pointer'></span>Back
        </button>
      )}
    </>
  );
};

export default BackButton;
