import { useEffect } from 'react';
import L from 'leaflet';

const Legend = ({ map, maxValue, dashboard, typeOfChartInterestByRegion, reportWidget }) => {
  const clearPreviousLegends = (mapInstance) => {
    if (mapInstance?.['_container']) {
      const existingLegends = mapInstance['_container'].querySelectorAll('.legend');
      if (existingLegends.length > 0) {
        Array.from(existingLegends).forEach((legend) => {
          legend.remove();
        });
      }
    }
  };
  const labels = {
    'Heat map': {
      blue: 'Most contributions',
      white: 'Fewest contributions',
    },
    'Bubble chart map': {
      'max-round': `Size represents ${reportWidget ? '' : 'amount of'} interest`,
    },
  };
  useEffect(() => {
    if (map) {
      //clear existing legends
      clearPreviousLegends(map);

      const legend = L.control({
        position: 'bottomleft',
      });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        Object.keys(labels[typeOfChartInterestByRegion]).forEach((label) => {
          const labelContainer = document.createElement('div');
          labelContainer.innerHTML = `<span class="label ${label}" style="width:${dashboard ? '15px' : '18px'};height:${
            dashboard ? '15px' : '18px'
          };"></span><span class="legend" style="transform:translateY(${
            dashboard ? '1px' : '1px'
          });opacity:${dashboard ? '0.7' : '1'}">${labels[typeOfChartInterestByRegion][label]}</span>`;
          div.append(labelContainer);
        });
        div.style.marginLeft = dashboard ? '1.5rem' : '40px';
        div.style.marginBottom = dashboard ? '1.5rem' : '27px';
        div.style.rowGap = dashboard ? '6px' : '7px';
        div.style.cursor = dashboard ? 'pointer' : undefined;
        return div;
      };

      legend.addTo(map);
    }
    return () => {
      //clear existing legends when switch type of chart
      clearPreviousLegends(map);
    };
  }, [map, maxValue]);
  return null;
};

export default Legend;
