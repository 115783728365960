import { Link, useLocation } from 'react-router-dom-v5-compat';
import Popup from '../../../Common/PopUp';

const ContentPopup = (props) => {
  const { proMessage } = props;
  const location = useLocation();
  const contactsSection = location.pathname.includes('influence/contacts');
  const stakeholdersInfluenceSection = location.pathname.includes('influence/stakeholder-mapping');
  const influenceSections = stakeholdersInfluenceSection || contactsSection;
  return (
    <>
      <div className='section-title px-4 py-4'>
        <h3 className='export-message-title  mb-0 filters-register-title'>
          <span>Filters</span>
        </h3>
      </div>

      <div className='px-4 py-3 section-title'>
        {influenceSections ? (
          <>
            <p>Sorting and filtering are not available during the trial period.</p>
            <p className='mb-0'>Please upgrade to a paid subscription to use this feature.</p>
          </>
        ) : (
          <>
            <p>
              Sorting and filtering is {proMessage ? 'a research feature available' : 'available to paying subscribers'}{' '}
              on the Mogul or Enterprise plans.{' '}
            </p>

            <p className='mb-0'>
              To sort and filter results, please upgrade to a {proMessage ? '' : 'paid'} Mogul or Enterprise
              subscription.
            </p>
          </>
        )}
      </div>
      <Link to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`} className='py-3 d-block text-center'>
        <button className='px-3 py-2 action-button general-button'>Compare plans</button>
      </Link>
    </>
  );
};

const FilterRegisterPopup = (props) => {
  return (
    <Popup {...props}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default FilterRegisterPopup;
