/*AE: TODO - REVIEW IF IT'S NECCESSARY ON THE CONTENT PAGES */
const compileFilters = (filters, section, state, notUseStoredFilters) => {
  const { search } = state;
  const { list } = search;
  let filtersToModify = { ...filters };
  let storedFilters = localStorage.getItem('activeFilters');
  let filtersToApply = storedFilters !== null && !notUseStoredFilters ? JSON.parse(storedFilters) : {};
  const verifyItem = (key) => {
    if (key in filtersToModify) {
      filtersToApply[key] = filtersToModify[key];
      delete filtersToModify[key];
    }
  };
  verifyItem('dates');
  verifyItem('search');
  if ('sort' in filtersToModify) {
    //AE: Keep the old property here while is ready the new functionality
    filtersToApply['sort'] = filtersToModify['sort'];
    filtersToApply[`sort-${list !== null ? 'topic' : 'search'}`] = filtersToModify['sort'];
    delete filtersToModify['sort'];
  }
  if (Object.keys(filtersToModify).length > 0) {
    filtersToApply[section] = filtersToModify;
  }
  return filtersToApply;
};

const setFiltersLocalStorage = async (props) => {
  const { filtersToApply, section, state, notUseStoredFilters } = props;
  let filters = { ...filtersToApply };
  let unreadFilterStored = localStorage.getItem('seeUnreadFilter');
  let unreadFilter = !!unreadFilterStored ? unreadFilterStored === 'true' : false;
  if (filters.seeUnreadFilter !== undefined && filters.seeUnreadFilter !== null) {
    unreadFilter = filters.seeUnreadFilter;
    delete filters.seeUnreadFilter;
  }
  localStorage.setItem('seeUnreadFilter', unreadFilter);
  let filtersToStorage = compileFilters(filters, section, state, notUseStoredFilters);
  if (Object.keys(filtersToStorage).length > 0) {
    localStorage.setItem('activeFilters', JSON.stringify(filtersToStorage));
  }
};

const getFiltersLocalStorage = (section) => {
  let storedFilters = localStorage.getItem('activeFilters');
  let unreadFilterStored = localStorage.getItem('seeUnreadFilter');
  if (storedFilters === null && unreadFilterStored === null) {
    return false;
  } else if (storedFilters === null && !!unreadFilterStored) {
    return {
      seeUnreadFilter: unreadFilterStored === 'true',
    };
  } else {
    let unreadFilter = !!unreadFilterStored ? unreadFilterStored === 'true' : false;
    let filters = JSON.parse(storedFilters);
    let sectionFilters = filters[section] ? filters[section] : {};
    let sortFilters = {
      sort: filters.sort,
      'sort-search': filters['sort-search'] ? filters['sort-search'] : { name: 'Most relevant' },
      'sort-topic': filters['sort-topic']
        ? filters['sort-topic']
        : {
            name: 'Most recent',
            sortFilter: { field: 'dateTime', order: 'DESC' },
          },
    };
    switch (section) {
      case 'Legislation':
        return {
          ...sectionFilters,
          ...sortFilters,
          search: filters.search,
          seeUnreadFilter: unreadFilter,
        };
      case 'StakeHolders':
        return {
          ...sectionFilters,
          search: filters.search,
        };
      case 'ParliamentaryRecord':
      case 'KeyUpdates':
      case 'Tweets':
        return {
          ...sectionFilters,
          ...sortFilters,
          search: filters.search,
          dates: filters.dates,
          seeUnreadFilter: unreadFilter,
        };
      case 'Consultation':
      case 'Calendar':
      case 'UpcomingEvents':
        return {
          ...sectionFilters,
          seeUnreadFilter: unreadFilter,
        };
      default:
        return filters[section];
    }
  }
};

const compareFilters = (initialFilters, storedFilters) => {
  let filters = { ...initialFilters };
  for (let filter in initialFilters) {
    if (filter in storedFilters && storedFilters[filter]) {
      filters[filter] = storedFilters[filter];
    }
  }
  return filters;
};

export { setFiltersLocalStorage, getFiltersLocalStorage, compareFilters };
