import React from 'react';
const AdminTopicName = (props) => {
  const { setValue, setShowDeletePopup, topicInformation, createUpdateKeywordList, initialTopicInformation, disabled } =
    props;
  const { name, id } = topicInformation ?? {};
  return (
    <div
      className={`row mx-0 ${
        disabled ? 'container' : 'padding-topic-component'
      } border-bottom topic-component-title align-items-center`}
    >
      <div className='col-lg-11 col-xl-12 col-xxl-13 px-0'>
        <div className='topic-name-container'>
          <input
            type='text'
            placeholder={'Give your topic a name'}
            className={`topic-name pr-2 pl-0`}
            value={name}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && name !== '') {
                e.preventDefault();
                document.getElementsByClassName('keywords-category-words')[0].click();
              }
            }}
            onChange={(e) => {
              let property = 'name';
              let newValue = e.target.value;
              setValue({ property, newValue });
            }}
            tabIndex={0}
            disabled={disabled}
          />
        </div>
      </div>
      {!disabled && (
        <div className='col-lg-5 col-xl-4 col-xxl-3 pr-0 pl-3 text-right mt-3 mt-lg-0 topic-action-buttons'>
          {!!id && (
            <button
              tabIndex={-1}
              className='general-button topic-delete-button mr-3 px-3'
              onClick={() => {
                setShowDeletePopup(true);
              }}
            >
              Delete
            </button>
          )}
          <button
            tabIndex={-1}
            className={`general-button action-button px-3`}
            disabled={JSON.stringify(initialTopicInformation) === JSON.stringify(topicInformation)}
            onClick={async (e) => {
              let topic = topicInformation;
              createUpdateKeywordList({ e, topic });
            }}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminTopicName;
