import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { store } from '../../Store.js';
import getCurrentTeam from '../Team/getCurrentTeam';
import EmailDomainList from './ui/EmailDomainList';
import { useLocation } from 'react-router-dom-v5-compat';
import SkeletonTeam from '../Team/SkeletonTeam.js';
import useEmailDomainFunctions from '../../Dashboard/hooks/useEmailDomainFunctions.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import useClientsFunctions from '../../Dashboard/hooks/useClientsFunctions.js';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken.js';

const EmailDomains = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [isLoading, setIsLoading] = useState(true);

  const [team, setTeam] = useState({});
  const [emailDomains, setEmailDomains] = useState([]);
  const location = useLocation();
  const { getAccessToken } = useGetAccessToken();

  const { newAgencyPlan } = useTopicOrClientWord();
  const { getEmailDomains, syncEmailDomain } = useEmailDomainFunctions();
  const { getTeams } = useClientsFunctions();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    loadInitialInformation(source);
    return () => {
      source.cancel('Email domains canceled by the user.');
    };
  }, []);

  const loadInitialInformation = async (source) => {
    try {
      if (newAgencyPlan) {
        const clients = await getTeams({});
        if (!!clients) {
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'clients',
            value: clients,
          });
        }
      }
      let team = await getCurrentTeam(getAccessToken, source);
      setTeam(team);

      await syncEmailDomain({ teamId: newAgencyPlan ? team?.teamId : null });
      const domains = await getEmailDomains({ getAllTeams: newAgencyPlan, source });
      if (!!domains) {
        setEmailDomains(domains);
        let title = `Email Domains - Settings - PolicyMogul`;
        document.title = title;
        let locationToStorage = {
          title: title,
          storageLocation: { ...location },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'locationToStorage',
          value: locationToStorage,
        });
        setIsLoading(false);
      }
    } catch (error) {}
  };

  return (
    <>
      {isLoading && <SkeletonTeam />}
      {!isLoading && (
        <EmailDomainList team={team} isAdminPage={false} emailDomainsState={{ emailDomains, setEmailDomains }} />
      )}
    </>
  );
};

export default EmailDomains;
