import { useState } from 'react';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';

const useUploadImageBase64 = () => {
  const { generalApiCall } = useGeneralApiCall();
  const [isLoading, setIsLoading] = useState(false);

  const uploadImageBase64 = async ({ fileName, mimeContentType, base64EncodedData }) => {
    setIsLoading(true);
    let method = 'post';
    let pathname = '/api/user-upload/upload-base64';
    let requestProperties = {
      fileName,
      mimeContentType,
      base64EncodedData,
    };

    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      returnCompleteRequest: true,
      notShowErrorMessage: true,
    });
    if (result) {
      setIsLoading(false);
      return result;
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    uploadImageBase64,
  };
};

export default useUploadImageBase64;
