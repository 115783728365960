import useGeneralApiCall from '../apiCalls/useGeneralApiCall';

const useGetTweetsInfo = () => {
  const { generalApiCall } = useGeneralApiCall();

  const getTweetsInfo = async ({ relatedContent, requestSource }) => {
    let relateTweetsContent =
      relatedContent?.length > 0
        ? relatedContent?.filter((item) => item.contentType === 'Tweet' && !item.screenName)
        : [];
    if (relateTweetsContent.length > 0) {
      let newRelatedContent = [...relatedContent];
      let tweetIds = relateTweetsContent.map((item) => item.objectId);
      let additionalTweetsInfo = await generalApiCall({
        bigInt: true,
        pathname: `/api/tweet/?object-ids=${tweetIds.join(',')}`,
        method: 'get',
        notShowErrorMessage: true,
        requestSource,
      });
      relateTweetsContent.forEach((item) => {
        let relatedContentPosition = newRelatedContent.findIndex((element) => element.objectId === item.objectId);
        let additionalTweetPosition = additionalTweetsInfo.findIndex((element) => element.objectID === item.objectId);
        newRelatedContent[relatedContentPosition] = {
          ...additionalTweetsInfo[additionalTweetPosition],
          contentType: 'Tweet',
        };
      });
      return newRelatedContent;
    } else {
      return relatedContent;
    }
  };

  return { getTweetsInfo };
};

export default useGetTweetsInfo;
