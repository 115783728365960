export const applyEmailSignatureToBody = ({ emailAddressItem, emailBody, disableChange }) => {
  if (emailAddressItem?.signature) {
    let htmlBody = document.createElement('div');
    htmlBody.innerHTML = emailBody;
    let htmlSignature = htmlBody.querySelector('#email_signature');
    //OP: #nosignature span - means signature was removed on purpose
    let signatureRemovedSpan = htmlBody.querySelector('#nosignature');
    let disableSignatureChange = disableChange && htmlSignature;
    if (!disableSignatureChange && !signatureRemovedSpan) {
      if (!htmlSignature) {
        htmlSignature = document.createElement('div');
        htmlSignature.id = 'email_signature';
      }
      htmlSignature.innerHTML = emailAddressItem.signature;
      htmlBody.appendChild(htmlSignature);
      return htmlBody.innerHTML;
    } else {
      return emailBody;
    }
  } else {
    let htmlBody = document.createElement('div');
    htmlBody.innerHTML = emailBody;
    let htmlSignature = htmlBody.querySelector('#email_signature');
    if (htmlSignature) {
      htmlSignature.remove();
    }
    return htmlBody.innerHTML;
  }
};

export const toggleSignature = ({ emailAddressItem, emailBody }) => {
  let htmlBody = document.createElement('div');
  htmlBody.innerHTML = emailBody;
  let signatureRemovedSpan = htmlBody.querySelector('#nosignature');
  if (signatureRemovedSpan) {
    if (emailAddressItem?.signature) {
      let htmlSignature = document.createElement('div');
      htmlSignature.id = 'email_signature';
      htmlSignature.innerHTML = emailAddressItem.signature;
      htmlBody.appendChild(htmlSignature);
      let signatureRemovedSpanContainer = signatureRemovedSpan.parentNode;
      if (signatureRemovedSpanContainer) {
        signatureRemovedSpanContainer.parentNode.removeChild(signatureRemovedSpanContainer);
      }
      return htmlBody.innerHTML;
    } else {
      return emailBody;
    }
  } else {
    let htmlSignature = htmlBody.querySelector('#email_signature');
    if (htmlSignature) {
      htmlSignature.parentNode.removeChild(htmlSignature);
    }
    let signatureRemovedSpan = document.createElement('span');
    signatureRemovedSpan.id = 'nosignature';
    signatureRemovedSpan.style.visibility = 'hidden';
    htmlBody.appendChild(signatureRemovedSpan);
    return htmlBody.innerHTML;
  }
};
