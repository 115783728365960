import React, { useContext, useRef, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom-v5-compat';
import normalize from '../utilities/normalizeString';
import { store } from '../../Store.js';
import idsWithoutImages from '../key_updates/idsWithoutImages';
import { contentItemSearchQuery, locationTopicDescriptor } from '../helpers/locationSearchDescriptor';
import Share from '../components/Share';
import useIsMobile from '../utilities/useIsMobile';
import useRememberPosition from '../hooks/useRememberPosition';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import { useAuth0 } from '@auth0/auth0-react';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import getUrlParam from '../utilities/getUrlParam';

const loadImageUrl = (imageId) => {
  return new Promise((resolve, reject) => {
    const urlImage =
      imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
        ? `https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1`
        : `${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png`;
    let base_image = new Image();
    base_image.src = urlImage;
    base_image.onload = () => {
      resolve('success');
    };
    base_image.onerror = reject;
  });
};

const StakeholderItem = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { search, activePlan, selectedStakeholders, team } = state;
  const { enableStakeholders, enableSentiment } = activePlan;
  const { list, activeSearch } = search;

  const advancedSearch = getUrlParam('and') || getUrlParam('not') || getUrlParam('or');
  const searchQuery = getUrlParam('search') || getUrlParam('or');
  const {
    item,
    scrollBarRef,
    array,
    index,
    stakeholderIds,
    isContact,
    setContactIdsCheck,
    contactIdsCheck,
    setShowStakeholdersCrmPopup,
    highlights,
    openInNewTab,
  } = props;
  const { id, title, positionCategory, imageId, summary, categories, positions, aggregatedSentiment, tags } = item;
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { searchResultsSection } = useMainSectionParameter(params);
  const { isAuthenticated } = useAuth0();
  const { newAgencyPlan } = useTopicOrClientWord();

  const { selectedIds, selectedAll, selectedAllVisible } = selectedStakeholders;

  const relevantListRef = useRef(null);
  const relevantActivitiesContainerRef = useRef(null);
  const isSelected = selectedIds.includes(item.id) || selectedAll || selectedAllVisible.includes(item.id);

  const biographyIncludesCondition = () => {
    return (
      (activeSearch || advancedSearch || getUrlParam('search') || getUrlParam('or')) &&
      (list === null || location.pathname?.startsWith('/general-election')) &&
      highlights?.highlightedBodySnippets?.some((item) => item.includes("<em class='policymogul-keyword-match'>")) &&
      !title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !positions.some((position) => position.toLowerCase().includes(searchQuery.toLowerCase())) &&
      !tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      if (selectedAllVisible.length > 0) {
        const newSelectedIds = [...selectedAllVisible, item.id];
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedStakeholders',
          value: {
            selectedAll: false,
            selectedIds: [],
            selectedAllVisible: newSelectedIds,
          },
        });
      } else {
        const newSelectedIds = [...selectedIds, item.id];
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedStakeholders',
          value: {
            selectedAll: false,
            selectedIds: newSelectedIds,
            selectedAllVisible: [],
          },
        });
      }
    } else {
      if (selectedAll) {
        const newSelectedIds = stakeholderIds.filter((id) => id !== item.id);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedStakeholders',
          value: {
            selectedIds: newSelectedIds,
            selectedAll: false,
            selectedAllVisible: [],
          },
        });
      } else if (selectedAllVisible.length > 0) {
        const newSelectedIds = selectedAllVisible.filter((id) => id !== item.id);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedStakeholders',
          value: {
            selectedIds: [],
            selectedAll: false,
            selectedAllVisible: newSelectedIds,
          },
        });
      } else {
        const newSelectedIds = selectedIds.filter((id) => id !== item.id);
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'selectedStakeholders',
          value: {
            selectedAll: false,
            selectedIds: newSelectedIds,
            selectedAllVisible: [],
          },
        });
      }
    }
  };

  const positionCategoryItems = [
    'Cabinet',
    'Government',
    'Shadow Cabinet',
    'Shadow Government',
    'Parliamentary Private Secretary',
    'Special Advisor',
  ];
  const houseOfItem = (categories) => {
    if (categories.includes('MP')) {
      return 'commons';
    } else if (categories.includes('Lord')) {
      return 'lords';
    } else {
      return '';
    }
  };

  const relevantActivityQueryParam = (search) => {
    return `${searchResultsSection ? `${search?.startsWith('?') ? '&' : '?'}relevant-activity=false` : ''}`;
  };

  const locationDescriptor = (path) => {
    const createSearch = () => {
      let search;
      if (!enableStakeholders || !isAuthenticated) {
        search = '';
      } else if (searchResultsSection || !getUrlParam('topic-id')) {
        search = `${contentItemSearchQuery(location, list)}`;
      } else {
        search = `${locationTopicDescriptor(location)}`;
      }
      return `${search}${relevantActivityQueryParam(search)}`;
    };
    return {
      pathname: path,
      search: createSearch(),
      hash: '',
    };
  };

  const CreateMatchedInformation = (field, member, finalSentence) => {
    let value = item[field];
    return (
      <>
        {!!value && value.length > 0 && (
          <>
            {value.map((item, index) => (
              <li key={`item-position${index}`}>
                <span className={`icon-${member ? 'group' : 'star'}`} style={{ fontSize: member ? '8px' : '11px' }} />
                {member ? 'Member of ' : ''}
                {item} {finalSentence}
              </li>
            ))}
          </>
        )}
      </>
    );
  };

  const addNumbers = (value) => {
    return item[`${value}Count12mo`] + item[`${value}Count3mo`] + item[`${value}Count1mo`] + item[`${value}Count1w`];
  };

  const createNumbers = (values, iconClassName, featureName, additionalText) => {
    let finalNumber = 0;
    values.forEach((value) => {
      finalNumber += addNumbers(value);
    });
    return (
      <>
        {finalNumber > 0 && (
          <li>
            <span className={`paragraph-p5 icon-${iconClassName}`} />
            {finalNumber} relevant {featureName}
            {finalNumber > 1 ? 's' : ''} {additionalText} in the last 12 months
          </li>
        )}
      </>
    );
  };
  const listConditional = list !== null && list.id !== null;
  const topicParameterCondition = !!getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all';
  const isMobile = useIsMobile(768);
  const { rememberPosition } = useRememberPosition();

  const stakeholderMapping = location.pathname.includes('/stakeholder-mapping');
  const candidatesWithoutSearch = location.pathname.includes('/general-election-2024') && !getUrlParam('search');
  const { topicOrClientWord } = useTopicOrClientWord();

  const PositionItem = ({ noIcon }) => {
    return (
      <>
        {positionCategory !== null && positionCategoryItems.includes(positionCategory) && (
          <p
            className={`nowrap-item my-2 my-md-0 stakeholder-item-position stakeholder-position-${houseOfItem(
              categories
            )} ${
              !noIcon ? `mr-4 stakeholder-position-${normalize(positionCategory)}-${houseOfItem(categories)}` : 'pt-1'
            }`}
            style={noIcon ? { fontSize: '0.95rem', marginRight: '12px' } : undefined}
          >
            {positionCategory === 'Parliamentary Private Secretary'
              ? 'Parliamentary Private Secretaries'
              : positionCategory}
          </p>
        )}
      </>
    );
  };

  const biographyItem = () => {
    return (
      <ul className='px-0 policy-list stake-list-information'>
        {(activeSearch || stakeholderMapping || candidatesWithoutSearch) && (
          <li className={`my-1 mt-1 stake-information-bio stake-information-bio-list`}>{summary}</li>
        )}
      </ul>
    );
  };

  return (
    <div
      className={`${
        stakeholderMapping ? 'position-relative py-2 stakeholder-list-item-container' : ''
      } ${isContact ? 'isContact' : undefined}`}
      onClick={() => {
        if (openInNewTab) {
          window.open(locationDescriptor(`/stakeholders/${id}/${normalize(title)}`), '_blank');
        } else {
          navigate(locationDescriptor(`/stakeholders/${id}/${normalize(title)}`));
        }
      }}
    >
      {stakeholderMapping && (
        <div
          className={`stakeholder-checkbox-input ${
            isContact ? 'stakeholder-checkbox-already-added' : ''
          }  ${selectedAll ? 'stakeholder-checkbox-selected-all' : ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type={'checkbox'}
            checked={isSelected}
            onChange={handleCheck}
            id={`selectStakeholder-${item.id}`}
            disabled={isContact || selectedAll}
          />
          <label
            htmlFor={`selectStakeholder-${item.id}`}
            data-tooltip-content={
              isContact
                ? `This stakeholder is in your contact list ${newAgencyPlan ? `for ${team?.teamName}` : ''}`
                : undefined
            }
            data-tooltip-id={isContact ? 'general-tooltip' : undefined}
            data-tooltip-class-name={isContact ? 'header-tooltip pricing-tooltip-description' : undefined}
          />
        </div>
      )}
      <Link
        className='content-item-link'
        to={locationDescriptor(`/stakeholders/${id}/${normalize(title)}`)}
        style={{ zIndex: array ? array.length - index : 1 }}
        onClick={(e) => {
          e.stopPropagation();
          rememberPosition('Stakeholders', scrollBarRef, index);
        }}
        target={openInNewTab ? '_blank' : undefined}
        rel={openInNewTab ? 'noopener noreferrer' : undefined}
      >
        <li
          className={`content-item stakeholder-list-item ${
            stakeholderMapping ? 'stakeholder-mapping-list-item' : 'my-2'
          }`}
        >
          <div className='row mx-0 p-3 align-items-start justify-content-between'>
            {!isMobile && (
              <StakeholderImage imageId={imageId} index={index} candidatesWithoutSearch={candidatesWithoutSearch} />
            )}
            <div className={`content-title ${stakeholderMapping ? '' : 'pl-md-4'} stakeholder-title`}>
              <div
                className={`stakeholder-title-name-position ${
                  stakeholderMapping
                    ? listConditional || topicParameterCondition
                      ? 'section-title-mapping'
                      : ''
                    : candidatesWithoutSearch
                      ? ''
                      : 'section-title pb-2'
                }  mb-2`}
              >
                <div className='flex-centered pb-2 pb-md-0'>
                  {isMobile && (
                    <StakeholderImage
                      imageId={imageId}
                      index={index}
                      candidatesWithoutSearch={candidatesWithoutSearch}
                    />
                  )}
                  <div className='d-flex align-items-start flex-grow-1 pl-3 pl-md-0'>
                    <div className='flex-grow-1 '>
                      <div className='d-flex align-items-center align-items-lg-start justify-content-between'>
                        <div>
                          <h4
                            className={`content-item-title mb-0 ${
                              stakeholderMapping && !isMobile ? 'nowrap-item' : 'flex-grow-1'
                            }`}
                            style={{
                              width: stakeholderMapping && !isMobile ? 'min-content' : undefined,
                            }}
                          >
                            {title}
                          </h4>
                          {!isMobile && <>{biographyItem()}</>}
                        </div>
                        {stakeholderMapping && !isMobile && <PositionItem noIcon />}
                        {isMobile && (
                          <Share
                            item={item}
                            listItem
                            alreadyInContacts={isContact}
                            stakeholderMapping={stakeholderMapping}
                            setContactIdsCheck={setContactIdsCheck}
                            contactIdsCheck={contactIdsCheck}
                            setShowStakeholdersCrmPopup={setShowStakeholdersCrmPopup}
                          />
                        )}
                      </div>
                      {isMobile && <>{biographyItem()}</>}
                    </div>
                    {!isMobile && (
                      <div className={`d-flex justify-content-end align-items-center px-0`}>
                        {!stakeholderMapping && !candidatesWithoutSearch && <PositionItem />}
                        <Share
                          item={item}
                          listItem
                          alreadyInContacts={isContact}
                          stakeholderMapping={stakeholderMapping}
                          setContactIdsCheck={setContactIdsCheck}
                          contactIdsCheck={contactIdsCheck}
                          setShowStakeholdersCrmPopup={setShowStakeholdersCrmPopup}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={`d-flex align-items-center position-relative`} ref={relevantActivitiesContainerRef}>
                <ul
                  ref={relevantListRef}
                  className={`px-0 policy-list  ${
                    stakeholderMapping ? 'stake-list-information-mapping' : ''
                  } stake-list-information`}
                >
                  {isMobile && !candidatesWithoutSearch && <PositionItem />}
                  {!activeSearch && !candidatesWithoutSearch && !topicParameterCondition && (
                    <li className={`mb-1 stake-information-bio pl-4`}>{summary}</li>
                  )}
                  {((activeSearch && listConditional) || topicParameterCondition) && (
                    <>
                      {CreateMatchedInformation('keywordMatchedPositions')}
                      {CreateMatchedInformation('keywordMatchedAppgs', true, 'APPG')}
                      {CreateMatchedInformation('keywordMatchedSelectCommittees', true, 'Select Committee')}
                      {CreateMatchedInformation('keywordMatchedLordSelectCommittees', true, 'Lords Select Committee')}
                      {createNumbers(
                        ['parliamentaryContribution'],
                        `parliamentary-record`,
                        'spoken parliamentary contribution'
                      )}
                      {createNumbers(
                        ['writtenQuestionsAsked'],
                        `parliamentary-record`,
                        'written parliamentary contribution'
                      )}
                      {createNumbers(['edm'], `parliamentary-record`, 'Early Day Motion', 'as primary sponsor')}
                      {createNumbers(['edmSupport'], `parliamentary-record`, 'Early Day Motion', 'supported')}
                      {process.env.REACT_APP_ENABLE_TWITTER === 'true' && (
                        <>{createNumbers(['tweet', 'retweet'], `tweet`, 'Tweet')}</>
                      )}
                      {aggregatedSentiment && enableSentiment && (
                        <li className={`mb-0 sentiment-stakeholder-list`}>
                          <span
                            className={`d-inline-block sentiment-dot sentiment-dot-${aggregatedSentiment?.toLowerCase()}`}
                          />
                          Sentiment analysis for this {topicOrClientWord}: {aggregatedSentiment}
                        </li>
                      )}
                    </>
                  )}
                  {activeSearch && list === null && (
                    <>
                      {positions.length > 0 && positionCategory !== 'Special Adviser' && (
                        <>
                          {positions.map((item, index) => (
                            <li key={`item-position${index}`}>
                              <span className={`icon-star`} style={{ fontSize: '11px' }} />
                              {item}
                            </li>
                          ))}
                        </>
                      )}
                    </>
                  )}
                  {biographyIncludesCondition() && (
                    <li>
                      <span className={`icon-profile`} style={{ fontSize: '11px' }} />
                      Biography includes {advancedSearch ? 'search terms' : `"${searchQuery}"`}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </li>
      </Link>
    </div>
  );
};

const StakeholderImage = (props) => {
  const { imageId, index, candidatesWithoutSearch } = props;
  const imageToLoad = index > 4;
  const componentMounted = useRef(true);

  const [loadingImage, setLoadingImage] = useState(imageToLoad);
  const loadImage = useRef();
  loadImage.current = async () => {
    await loadImageUrl(imageId);
    if (componentMounted.current) {
      setLoadingImage(false);
    }
  };

  useEffect(() => {
    componentMounted.current = true;
    if (imageToLoad) {
      loadImage.current();
    }
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const isMobile = useIsMobile();
  const size = isMobile ? 65 : 95;

  return (
    <>
      {loadingImage ? (
        <SkeletonTheme baseColor='#f2f2f2'>
          <Skeleton circle={true} height={size} width={size} />
        </SkeletonTheme>
      ) : (
        <div
          className={`stakeholder-profile-image stakeholder-list-profile-image ${candidatesWithoutSearch ? 'candidate-profile-image' : ''}`}
          style={{
            backgroundImage:
              imageId !== 0 && imageId !== null && !idsWithoutImages.includes(imageId)
                ? `url('https://www.parliamenttoday.com/image.php?imageid=${imageId}&ml=1')`
                : `url('${process.env.REACT_APP_CDNURL}/images/20210722-stakeholders-placeholder.png')`,
          }}
        />
      )}
    </>
  );
};

export { StakeholderImage };
export default StakeholderItem;
