import { useState } from 'react';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';

const useStakeholderEmailsFunctions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [emails, setEmails] = useState([]);
  const { generalApiCall } = useGeneralApiCall();
  const getEmails = async ({ id, requestSource, contentType }) => {
    setIsLoading(true);
    const pathname = `/api/crm-email/list?sortField=lastUpdated&sortOrder=descending&pageNumber=1&pageSize=10&${contentType ?? 'stakeholderContact'}Id=${id}`;
    const method = 'get';
    const results = await generalApiCall({
      pathname,
      method,
      requestSource,
      notShowErrorMessage: true,
      needsAuthentication: true,
    });
    if (results) {
      setEmails(results);
      setIsLoading(false);
    }
  };

  return { getEmails, isLoading, emails };
};

export default useStakeholderEmailsFunctions;
