import Axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import { store } from '../../../Store';
import useEmailDomainFunctions from '../../hooks/useEmailDomainFunctions';

const useGetEmailAddresses = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { getEmailAddressesCall } = useEmailDomainFunctions();

  const asyncFn = async (source) => {
    let emailAddressesResult = await getEmailAddressesCall(source);
    if (!!emailAddressesResult?.length) {
      let verifiedAddresses = emailAddressesResult.filter((item) => item.isVerified);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailAddresses',
        value: verifiedAddresses,
      });
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    try {
      asyncFn(source);
    } catch (e) {}

    return () => {
      source.cancel('Email addresses canceled by the user.');
    };
  }, []);
};
export default useGetEmailAddresses;
