import React, { useEffect, useContext, useRef } from 'react';
import { store } from './Store';
import { useAuth0 } from '@auth0/auth0-react';
import ReactGA from 'react-ga4';
import useGeneralApiCall from './Dashboard/apiCalls/useGeneralApiCall';
import axios from 'axios';
import { useLocation } from 'react-router-dom-v5-compat';
let lastPageLogged = '';
const TrackingCodes = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { isAuthenticated, user } = useAuth0();
  const { locationToStorage } = state;
  const location = useLocation();
  const initialCodes = useRef();

  initialCodes.current = async (source) => {
    try {
      //HUBSPOT
      if (`${process.env.REACT_APP_HUBSPOT_CODE}`.trim() !== '') {
        var _hsq = (window._hsq = window._hsq || []);
        //IT CREATES THE FIRST RECORD BUT WITH A WRONG TITLE AND WHEN RESULTS ARE READY THE CORRECT TITLE IS UPDATED
        if (isAuthenticated) {
          _hsq.push(['setPath', location.pathname]);
          _hsq.push(['identify', { email: user.email }]);
        } else {
          _hsq.push(['setPath', location.pathname]);
        }
      }
      //GOOGLE ANALYTICYS
      if (isAuthenticated) {
        let dimensionValue = 'true';
        ReactGA.set({ userId: user.sub });
        ReactGA.set({ dimension1: dimensionValue });
      } else {
        let dimensionValue = 'false';
        ReactGA.set({ dimension1: dimensionValue });
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCodes.current(source);
    return () => {
      source.cancel('Intercom api cancelled by the user');
    };
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (locationToStorage !== '' && document.title !== 'PolicyMogul') {
      const { pathname, search, hash } = locationToStorage.storageLocation;
      //GOOGLE ANALYTICS
      if (isAuthenticated) {
        var dimensionValue = 'true';
        ReactGA.set({ userId: user.sub });
        ReactGA.set({ dimension1: dimensionValue });
      } else {
        let dimensionValue = 'false';
        ReactGA.set({ dimension1: dimensionValue });
      }

      let value = `${pathname}${search}${hash !== '' ? `${hash.startsWith('#') ? '' : '#'}${hash}` : ''}`;
      ReactGA.set({ page: value });

      if (lastPageLogged !== value) {
        ReactGA.send({ hitType: 'pageview', page: value });
      }
      lastPageLogged = value;

      //HUBSPOT
      if (`${process.env.REACT_APP_HUBSPOT_CODE}`.trim() !== '') {
        var _hsq = (window._hsq = window._hsq || []);
        if (isAuthenticated) {
          _hsq.push(['setPath', value]);
          _hsq.push(['trackPageView']);
          _hsq.push(['identify', { email: user.email }]);
        } else {
          _hsq.push(['setPath', value]);
          _hsq.push(['trackPageView']);
        }
      }

      //INTERCOM
      if (window.Intercom && `${process.env.REACT_APP_INTERCOM_APP_ID}`.trim() !== '') {
        window.Intercom('update');
      }
    }
  }, [locationToStorage]);

  return <></>;
};

const useGetUserHash = () => {
  const { generalApiCall } = useGeneralApiCall();
  const getUserHash = async (source) => {
    try {
      let pathname = '/api/user/intercom-identity-verification';
      let method = 'get';
      let results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        requestSource: source,
        notShowErrorMessage: true,
        returnError: true,
      });
      return results;
    } catch (error) {}
  };
  return { getUserHash };
};

export { useGetUserHash };
export default TrackingCodes;
