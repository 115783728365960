const LibraryMaterialFilters = [
  {
    name: 'Type',
    searchState: 'always',
    pages: ['LibraryMaterial'],
    filterName: 'articleTypes',
    options: [
      {
        name: 'Research Briefing',
      },
      {
        name: 'Constituency Casework',
      },
      {
        name: 'Data Dashboard',
      },
      {
        name: 'Insight',
      },
      {
        name: 'Debate Pack',
      },
    ],
  },
];

export default LibraryMaterialFilters;
