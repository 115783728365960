export const createMarkerTooltip = ({ x, y, properties, region }) => {
  // do not show tooltip above subnav
  if (y < 170) {
    return;
  }
  const tooltipEl = document.createElement('div');
  tooltipEl.classList.add('external-interest-by-region-tooltip');

  tooltipEl.style.top = `${y}px`;
  tooltipEl.style.left = `${x}px`;
  tooltipEl.style.opacity = 0;

  const getProperties = () => {
    const propertiesToShow = Object.values(properties).filter((val) => !!val);
    if (propertiesToShow.length) {
      return Object.keys(properties)
        .map((property) =>
          properties[property]
            ? `<p class="chart-tooltip-description-p" key=${`description-${property}`}><span>${
                property === 'Question and answer' ? 'Questions and answer' : property
              }s :</span> <span>${properties[property]}</span></p>`
            : ''
        )
        .join('');
    } else {
      return `<p class="chart-tooltip-description-no-contributions">No relevant contributions</p>`;
    }
  };

  tooltipEl.innerHTML = `<p class="chart-tooltip-title">${region}</p>${getProperties()}`;

  document.body.appendChild(tooltipEl);

  const tooltipOverlapBottom = document.body.getBoundingClientRect().height - tooltipEl.getBoundingClientRect().bottom;

  const tooltipOverlapRight = document.body.getBoundingClientRect().width - tooltipEl.getBoundingClientRect().right;

  const getOverlapBottom = () => {
    if (tooltipOverlapBottom < 0) {
      return `${-(tooltipEl.getBoundingClientRect().height + 15)}px`;
    } else {
      return '15px';
    }
  };

  const getOverlapSide = () => {
    if (tooltipOverlapRight < 0) {
      return `${-(Math.abs(tooltipOverlapRight) + tooltipEl.getBoundingClientRect().width / 2 + 10)}px`;
    } else if (tooltipEl.getBoundingClientRect().left < 0) {
      return `${Math.abs(tooltipEl.getBoundingClientRect().left) - tooltipEl.getBoundingClientRect().width / 2 + 10}px`;
    } else {
      return '-50%';
    }
  };

  tooltipEl.style.transform = `translate(${getOverlapSide()},${getOverlapBottom()})`;
  tooltipEl.style.opacity = 1;
};

export const removeMarkerTooltip = () => {
  const tooltips = document.querySelectorAll('.external-interest-by-region-tooltip');
  if (tooltips.length > 0) {
    Array.from(tooltips).forEach((tooltip) => tooltip.remove());
  }
};
