const splitItems = (arr, character) => {
  let separatedWords = [];
  arr.forEach((item) => {
    let arr = item.split(character);
    if (arr.length > 1) {
      arr.forEach((word) => {
        if (word.trim() !== '') {
          separatedWords.push(word);
        }
      });
    } else {
      separatedWords.push(item);
    }
  });
  return separatedWords;
};

export default splitItems;
