import React, { useContext } from 'react';

import Navigation from '../Navigation';
import Footer from '../../home/Footer';
import useScrollBody from '../../home/useScrollBody';
import { store } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { useState } from 'react';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import Axios from 'axios';
import getActiveSubscriptions from '../../Settings/Billing/getActiveSubscriptions';
import { useRef } from 'react';
import { useEffect } from 'react';
import useGetAccessToken from '../apiCalls/useGetAccessToken';

const SlackIntegration = () => {
  useScrollBody();

  const location = useLocation();
  const navigate = useNavigate();

  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activeSubscriptions, search } = state;
  const { planName } = activeSubscriptions;
  const [isLoading, setIsLoading] = useState(false);

  const { isAuthenticated } = useAuth0();
  const { agencyUser } = useTopicOrClientWord();

  const planCondition = planName === 'Mogul' || planName === 'Enterprise';

  const { generalApiCall } = useGeneralApiCall();

  const { getAccessToken } = useGetAccessToken();

  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  const activeSubscriptionsApiCall = useRef();
  activeSubscriptionsApiCall.current = async () => {
    const includeScheduledSubscription = true;
    let activeSubscriptions = await getActiveSubscriptions(getAccessToken, source, includeScheduledSubscription);
    if (!!activeSubscriptions) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'activeSubscriptions',
        value: activeSubscriptions,
      });
    }
  };

  useEffect(() => {
    activeSubscriptionsApiCall.current();
    //AE: THIS IS NECCESSARY TO AVOID DUPLICATE CALLS WHEN YOU CLICK ON A SETTINGS PAGE AND GO BACK TO THE MAIN APP
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'search',
      value: {
        ...search,
        searchState: false,
      },
    });

    return () => {
      source.cancel('Slack integration cancel by the user');
    };
  }, []);

  const callIntegration = async () => {
    try {
      setIsLoading(true);
      const pathname = '/api/slack/authorize-url';
      const method = 'get';
      const url = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
      });
      if (url) {
        window.open(url, '_self');
      }
      setIsLoading(false);
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  const loginUrl = `/login?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`;

  return (
    <div>
      <Navigation />
      <div className='create-profile-container px-0 accept-invite'>
        <div className='scroll-container h-100 create-profile-general-content'>
          <div className='d-flex justify-content-center align-items-center w-100 py-lg-2 py-lg-5 pt-5'>
            <div className='d-flex flex-centered flex-column'>
              <div className='d-flex slack-integration'>
                <div className='d-flex flex-column'>
                  <img
                    src={`${process.env.REACT_APP_CDNURL}/images/slack_integration-image.png`}
                    alt='Slack'
                    className='slack-integration-animation'
                  />
                  <p className='slack-integration-subtitle mt-3'>
                    This is what immediate alerts from PolicyMogul look like in Slack.
                  </p>
                </div>

                <div className='slack-integration-header d-flex flex-column'>
                  <p className='slack-integration-pretitle'>Stay up to date with</p>
                  <img src={`${process.env.REACT_APP_CDNURL}/images/logo.svg`} alt='Logo' className='policy-logo' />
                  <p className='slack-integration-subtitle'>
                    Our integration with Slack means you can get immediate alerts
                    <br />
                    whenever your topics are mentioned.
                  </p>
                  <button
                    className='slack-integration-btn'
                    onClick={() => {
                      if (isAuthenticated) {
                        if (planCondition) {
                          if (!isLoading) {
                            callIntegration();
                          }
                        } else {
                          navigate('/pricing');
                        }
                      } else {
                        navigate(loginUrl);
                      }
                    }}
                    data-tooltip-content={
                      planCondition ? '' : `Available on the${agencyUser ? '' : ' Mogul and'}  Enterprise plan`
                    }
                    data-tooltip-id={'settings-tooltip'}
                  >
                    {isLoading ? (
                      <i className='fas fa-spinner fa-spin mr-2' />
                    ) : (
                      <>
                        Add to <strong>Slack</strong>
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className='slack-integration-description p-4 p-lg-5'>
                <div className='p-lg-3 slack-integration-description-section'>
                  <div>
                    <h3 className='mb-4'>What does it do?</h3>
                    <p>
                      By connecting <strong>PolicyMogul</strong> to <strong>Slack</strong>, you can receive your alerts
                      on <strong>Slack </strong>
                      instead of in your email inbox. When an immediate alert comes through, you’ll get a direct message
                      from <strong>@PolicyMogul</strong> with the contents of the update.
                    </p>
                  </div>
                  <div>
                    <h3 className='mb-4'>How do i set up PolicyMogul alerts in Slack?</h3>
                    <p>
                      First, connect <strong>Slack</strong> to <strong>PolicyMogul</strong> using the{' '}
                      <strong>‘Add to Slack’ </strong>
                      button above. Then, you’ll be taken to a page on <strong>Slack’s </strong>
                      website where you can grant <strong>PolicyMogul</strong> permission to access your account. Follow
                      the instructions.
                    </p>
                  </div>
                </div>
                <div className='p-lg-3 slack-integration-description-section align-items-md-center'>
                  <div className='justify-content-start'>
                    <h3 className='mb-4'>How do i choose which alerts to get in Slack?</h3>
                    <p>
                      In <strong>PolicyMogul</strong>, click into{' '}
                      <a href='https://policymogul.com/settings/alerts' target='_blank' rel='noreferrer'>
                        Alerts
                      </a>
                      . Add a topic alert if you haven’t already, and select <strong>‘Immediate’</strong> as the
                      frequency. Finally, choose <strong>‘Slack’</strong> as your <strong>Alert</strong> type. After
                      that, you’re all set!
                    </p>
                  </div>
                  <div className='flex-centered justify-content-center w-100'>
                    <img
                      src={`${process.env.REACT_APP_CDNURL}/images/slack-integration-alerts.png`}
                      alt='Slack alert'
                      className='w-100'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer sentiment />
    </div>
  );
};

export default SlackIntegration;
