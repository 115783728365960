export default function isDummyTableItem({ index, itemHeight, cachedInvisibleItems, scrollTopContainer, pageSize }) {
  if (!scrollTopContainer.scrollTop && index < pageSize + 1) {
    return false;
  }
  if (
    (index + 1) * itemHeight < scrollTopContainer.scrollTop - itemHeight * cachedInvisibleItems ||
    (index + 1) * itemHeight >
      scrollTopContainer.scrollTop + scrollTopContainer.clientHeight + itemHeight * cachedInvisibleItems
  ) {
    return { itemHeight };
  } else {
    return false;
  }
}
