import React from 'react';
import EngagementStatisticsDropdown from '../../EngagementStatisticsDropdown';

function EngagementStatisticsNav() {
  return (
    <div className='influence-subnav-wrapper'>
      <div className='mx-auto main-content-wrapper flex-centered align-items-center resize-elements-container stakeholder-sub-nav justify-content-between'>
        <EngagementStatisticsDropdown />
      </div>
    </div>
  );
}

export default EngagementStatisticsNav;
