const checkEllipsis = (el) => {
  if (el) {
    const styles = getComputedStyle(el);
    const widthEl = parseFloat(styles.width);
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = `${styles.fontSize} ${styles.fontFamily}`;
    const text = ctx.measureText(`${el.innerText}`);
    return text.width > widthEl;
  }
};

export default checkEllipsis;
