import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import createMarkup from '../helpers/createMarkup';
import { Link } from 'react-router-dom-v5-compat';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import Popup from '../../Common/PopUp';
import { useAuth0 } from '@auth0/auth0-react';
import { Portal } from 'react-portal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import InputError from './InputError';
import { copyInnerHtml } from '../helpers/copyFunctionality';
import { store } from '../../Store';
import useIsMobile from '../utilities/useIsMobile';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import createNotification from '../../Settings/Utilities/CreateNotification';
import useUpdateSummaries from '../hooks/useUpdateSummaries';
import TailorSummaryModal from './TailorSummaryModal';
import DropdownComponent from '../../Settings/WebsiteFeeds/DropdownComponent';

import dayjs from 'dayjs';
import useOutsideElement from '../utilities/useOutsideElement';
import getUrlParam from '../utilities/getUrlParam';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import parliamentarianUsers from '../sidebar/parliamentarianUsers';
import WebsiteSettingsTopics from '../../Settings/WebsiteFeeds/WebsiteSettingsTopics';

var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

const ContentSummary = (props) => {
  const { content, hide } = props;
  const { objectId, objectType, keywordsListsIds } = content;

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    activePlan,
    keywordsLists,
    team,
    showTailorSummaryModal,
    initialOpenAiSummary,
    clickedOnContentUrl,
    loadingCreateSummary,
  } = state;
  const { teamName, code } = activePlan;

  const navigate = useNavigate();
  const location = useLocation();

  const keywordsListsToUse = useMemo(() => {
    let initialKeywordsLists = keywordsLists
      .filter((item) => item.myState !== 'Hidden')
      .filter((item) => keywordsListsIds?.includes(item.id));
    return initialKeywordsLists.length > 0
      ? initialKeywordsLists
      : keywordsLists.filter((item) => item.myState !== 'Hidden');
  }, [keywordsLists, keywordsListsIds]);

  const getDefaultTopic = () => {
    if (getUrlParam('topic-id')) {
      if (getUrlParam('topic-id') === 'all') {
        return keywordsListsToUse.filter((item) => item.id !== null)?.[0];
      } else {
        return (
          keywordsListsToUse.find((item) => item.id === parseInt(getUrlParam('topic-id'))) ??
          keywordsListsToUse.filter((item) => item.id !== null)?.[0]
        );
      }
    } else {
      return keywordsListsToUse.filter((item) => item.id !== null)?.[0];
    }
  };

  const [showPopup, setShowPopup] = useState(false);
  const [contentSummary, setContentSummary] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(getDefaultTopic());
  const [editMode, setEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(initialOpenAiSummary);
  const [triggerLoadSummary, setTriggerLoadSummary] = useState(false);

  const setLoadingCreateSummary = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'loadingCreateSummary',
      value: val,
    });
  };

  let disableTailorSummary = process.env.REACT_APP_DISABLE_TAILORED_SUMMARIES === 'true';

  const { agencyUser: isAgencyUser } = useTopicOrClientWord();
  const agencyUser = disableTailorSummary ? false : isAgencyUser;

  const { getSummaryForContent } = useGetSummaryForContent();

  const initialCall = useRef();
  const containerRef = useRef();

  initialCall.current = async () => {
    setLoadingCreateSummary(true);
    setTriggerLoadSummary(false);
    let initialSummary = await getSummaryForContent({
      objectId,
      contentType: objectType,
      keywordListId: agencyUser ? selectedTopic?.id : undefined,
    });
    setContentSummary(initialSummary);
    setTimeout(() => setLoadingCreateSummary(false), 700);
  };

  useEffect(() => {
    if ((isOpen && contentSummary === null && !hide) || triggerLoadSummary) {
      initialCall.current();
    }
  }, [isOpen, triggerLoadSummary]);

  useEffect(() => {
    if (initialOpenAiSummary) {
      setInitialOpen(false);
    }
  }, []);

  const showHideTailorSummaryModal = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showTailorSummaryModal',
      value: val,
    });
  };

  let setInitialOpen = (val) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'initialOpenAiSummary',
      value: val,
    });
  };

  const searchFunction = ({ topic }) => {
    if (topic) {
      setSelectedTopic(topic);
      const params = new URLSearchParams(location.search);
      params.set('topic-id', topic.id);
      navigate(
        {
          pathname: location.pathname,
          search: params.toString(),
        },
        { replace: true }
      );
    }
  };

  const { isAuthenticated } = useAuth0();

  return (
    !hide && (
      <>
        <SummaryElement
          {...props}
          contentSummary={contentSummary}
          loadingCreateSummary={loadingCreateSummary}
          setContentSummary={setContentSummary}
          setLoadingCreateSummary={setLoadingCreateSummary}
          selectedTopic={selectedTopic}
          setSelectedTopic={setSelectedTopic}
          teamName={teamName}
          activePlan={activePlan}
          keywordsLists={keywordsListsToUse?.filter((item) => item.id !== null)}
          keywordsListsAll={keywordsLists
            .filter((item) => item.myState !== 'Hidden')
            ?.filter((item) => item.id !== null)}
          showHideTailorSummaryModal={showHideTailorSummaryModal}
          disableTailorSummary={disableTailorSummary}
          agencyUser={agencyUser}
          team={team}
          searchFunction={searchFunction}
          containerRef={containerRef}
          isAuthenticated={isAuthenticated}
          setShowPopup={setShowPopup}
          code={code}
          editMode={editMode}
          setEditMode={setEditMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setInitialOpen={setInitialOpen}
          clickedOnContentUrl={clickedOnContentUrl}
          initialOpenAiSummary={initialOpenAiSummary}
        />
        {showPopup && (
          <Portal>
            <ContentSummaryPopup
              identifier={'content-summaru-limit-popup'}
              setShowPopup={setShowPopup}
              additionalClass={'rate-limit-popup'}
            />
          </Portal>
        )}
        {showTailorSummaryModal && containerRef.current && (
          <TailorSummaryModal
            setShowPopup={showHideTailorSummaryModal}
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
            keywordsLists={keywordsLists
              .filter((item) => item.myState !== 'Hidden')
              ?.filter((item) => item.id !== null)}
            setTriggerLoadSummary={setTriggerLoadSummary}
            {...props}
          />
        )}
      </>
    )
  );
};

const SummaryElement = (props) => {
  const {
    content,
    setContentSummary,
    contentSummary,
    showHideTailorSummaryModal,
    loadingCreateSummary,
    selectedTopic,
    keywordsLists,
    teamName,
    agencyUser,
    disableTailorSummary,
    team,
    searchFunction,
    containerRef,
    isAuthenticated,
    setShowPopup,
    code,
    editMode,
    setEditMode,
    isOpen,
    setIsOpen,
    setInitialOpen,
    clickedOnContentUrl,
    keywordsListsAll,
    initialOpenAiSummary,
  } = props;
  const { subject, canonicalUrl, title, embargoed, feedback } = content;
  const [onEditProcessing, setOnEditProcessing] = useState(false);
  const [inputError, setInputError] = useState(null);
  const [showActions, setShowActions] = useState(false);
  const { updateSummaries } = useUpdateSummaries();

  const isMobile = useIsMobile(540);

  const paragraphItem = useRef();
  const contentItem = useRef();
  const actionsContainer = useRef();
  const openedSummary = useRef(initialOpenAiSummary ?? null);

  const hideItem = () => {
    setShowActions(false);
  };

  useOutsideElement(actionsContainer, hideItem);

  const isParliamentarian = parliamentarianUsers(code);

  const handleSaveSummary = async () => {
    let text = contentItem.current.innerText;
    if (!text || text?.length === 0) {
      setInputError(isMobile ? 'Please enter info' : 'Please enter some information above');
    } else {
      if (contentItem.current.innerHTML === contentSummary.summary) {
        setEditMode(false);
        return;
      }
      setOnEditProcessing(true);
      const objectId = contentSummary.objectId;
      const contentType = contentSummary.contentType;
      const newContentSummary = await updateSummaries({
        objectId,
        contentType,
        summary: contentItem.current.innerHTML,
        keywordListId: agencyUser ? selectedTopic?.id : undefined,
      });
      setEditMode(false);
      setOnEditProcessing(false);
      setContentSummary(newContentSummary);
    }
  };

  let topicFromKeywordList = (topic) => {
    return keywordsLists.find((item) => item.id === topic.id);
  };

  let forCondition =
    (agencyUser && keywordsLists?.filter((item) => item.id !== null)?.length) ||
    (!agencyUser && teamName?.length && team?.organisationDescription !== null);

  let tailoredCondition =
    (agencyUser && topicFromKeywordList(selectedTopic)?.hasClientOrganisationDescription) ||
    (!agencyUser && teamName?.length && team?.organisationDescription !== null);

  const lastEditedCondition = editMode && contentSummary?.authorFullName && contentSummary?.lastEditedByUser;

  let tailorSummariesCondition = !disableTailorSummary && !contentSummary?.isTailored && !loadingCreateSummary;

  const handleOpen = () => {
    if (!isOpen) {
      setTimeout(() => {
        openedSummary.current = true;
      }, 200);
    }
    setIsOpen(!isOpen);
  };

  const notTailoredBannerCondition =
    agencyUser && !topicFromKeywordList(selectedTopic)?.hasClientOrganisationDescription;

  return (
    <>
      <div
        className={`${lastEditedCondition ? 'mb-5' : `${embargoed || feedback ? '' : 'mb-4'}`} ${
          editMode && isOpen ? 'border-dashed' : ''
        } bg-light-blue-hovered summary-content px-3 px-lg-4 py-3 ${
          !isOpen ? 'summary-content-collapsed' : ''
        } ${loadingCreateSummary ? 'summary-content-loading' : ''}`}
        style={{
          userSelect: isOpen ? undefined : 'none',
          maxHeight: !isOpen ? '67px' : loadingCreateSummary ? '122px' : '1500px',
        }}
        ref={containerRef}
      >
        {!isAuthenticated ? (
          <button
            class='general-button flex-centered mb-0 text-light-blue py-2 
          flex-grow-1'
            onClick={() => setShowPopup(true)}
          >
            <span class='icon-bot d-inline-block title-h3 d-inline-block mr-2' style={{ paddingTop: '2px' }}></span>
            <span>Summarise this key update</span>
            <span
              class='ml-3 paragraph-p5 d-inline-block icon-long-arrow-right d-inline-block'
              style={{ paddingTop: '2px' }}
            ></span>
          </button>
        ) : (
          <>
            <div
              className={`flex-centered font-weight-bold mb-0 pb-3 pointer justify-content-between summary-title-section ${
                agencyUser ? 'summary-header-container' : ''
              }`}
              onClick={handleOpen}
              ref={paragraphItem}
              style={{ gap: '15px' }}
            >
              <div
                className={`flex-grow-1 summary-content-dropdown-component-container ${
                  agencyUser ? 'agency-user-summary' : ''
                } ${
                  !topicFromKeywordList(selectedTopic)?.hasClientOrganisationDescription ? 'not-tailored' : ''
                } flex-centered`}
                style={{
                  maxWidth: isOpen ? undefined : 'calc(100% - 25px)',
                }}
              >
                <b
                  className={`${agencyUser ? '' : 'title-max-width'}`}
                  style={{ lineHeight: agencyUser ? '33.6px' : '' }}
                >
                  {loadingCreateSummary ? (
                    tailoredCondition ? (
                      <span>Tailoring summary</span>
                    ) : (
                      <span>Summarising</span>
                    )
                  ) : tailoredCondition ? (
                    !!contentSummary ? (
                      <span>Tailored summary</span>
                    ) : (
                      <span className='blue-text'>Show tailored summary</span>
                    )
                  ) : !!contentSummary ? (
                    <span>Summary</span>
                  ) : (
                    <span className='blue-text'>Show summary</span>
                  )}{' '}
                  {forCondition && ((isMobile && agencyUser) || !isMobile) ? (
                    <span>for {!agencyUser && teamName?.length ? teamName : ''}</span>
                  ) : (
                    ''
                  )}{' '}
                </b>
                {agencyUser && (
                  <div
                    className='tailor-summaries-dropdown-component'
                    onClick={(e) => {
                      if (keywordsLists.length !== 1) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <DropdownComponent
                      mainItem={() => {
                        return (
                          <div className='not-tailored-title'>
                            <span
                              className={`hidden-lines hidden-one-line nowrap-item ${keywordsLists.length === 1 ? 'pointer' : ''} ${tailoredCondition && !contentSummary && !loadingCreateSummary ? 'blue-text font-weight-normal' : 'font-weight-bolder'}`}
                            >
                              {`${topicFromKeywordList(selectedTopic)?.name}`}
                            </span>{' '}
                          </div>
                        );
                      }}
                      hideArrowDisabled={keywordsLists.length === 1}
                    >
                      <WebsiteSettingsTopics
                        firstItem={
                          <>
                            <span style={{ fontSize: '17px', fontWeight: 700 }}>Select a client</span>
                            <span
                              className='icon-info main-light-text d-inline-block pointer ml-2'
                              data-tooltip-content={`The list below includes clients to whom this update is relevant.`}
                              data-tooltip-id={'summary-tooltip'}
                              data-tooltip-class-name={'content-summary-tooltip'}
                              style={{ transform: 'translateY(1px)' }}
                            />
                          </>
                        }
                        selectedTopic={selectedTopic}
                        setSelectedTopic={(item) => {
                          setInitialOpen(isOpen);
                          searchFunction({ topic: item });
                        }}
                        agencyUser
                        topicsVariant
                        hideButtons
                        hideKeywords
                        keywordsListsToUseProp={keywordsLists}
                        hideAddTopic
                      />
                    </DropdownComponent>
                  </div>
                )}
              </div>
              <div className='flex-centered actions-container' style={{ whiteSpace: 'nowrap' }}>
                {isOpen && (
                  <>
                    {tailorSummariesCondition && openedSummary?.current && (
                      <button
                        className={`general-button gray-outlined-button tailor-summaries-btn px-2 py-0 mr-3`}
                        onClick={(e) => {
                          e.stopPropagation();
                          showHideTailorSummaryModal(true);
                        }}
                        disabled={onEditProcessing}
                        style={{
                          transition: 'opacity 200ms, background 200ms',
                        }}
                      >
                        {isMobile ? 'Tailor' : 'Tailor summaries'}
                      </button>
                    )}

                    {!loadingCreateSummary && !editMode && openedSummary?.current && (
                      <button
                        className='general-button circle-button hover-background-gray-05 p-1 mr-1'
                        onClick={(e) => {
                          e.stopPropagation();
                          let contentToCopy = `<h3><strong>Summary of <a href=${
                            process.env.REACT_APP_APPURL
                          }${canonicalUrl}>${subject ?? title}</a></strong></h3>`;
                          copyInnerHtml(
                            contentToCopy + contentSummary?.summary,
                            `Summary of ${subject ?? title}\n` +
                              contentSummary?.summary?.replaceAll('<li>', '- ').replace(/<[^>]+>/g, ''),
                            'Link and summary copied'
                          );
                        }}
                      >
                        <span
                          className='icon-copy main-light-text pointer d-inline-block'
                          data-tooltip-content={isMobile ? undefined : `Copy link and summary`}
                          data-tooltip-id={'summary-tooltip'}
                          data-tooltip-class-name={'content-summary-tooltip'}
                        ></span>
                      </button>
                    )}
                    {!loadingCreateSummary && contentSummary?.isTailored && (
                      <>
                        {editMode ? (
                          <button
                            className={`general-button action-button px-2 py-0 mr-2`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSaveSummary();
                            }}
                            disabled={onEditProcessing}
                          >
                            {onEditProcessing ? (
                              <i className='fas fa-spinner fa-spin mx-2' />
                            ) : (
                              <span
                                style={{
                                  display: 'inline-block',
                                  verticalAlign: 'text-top',
                                  fontSize: '14px',
                                  lineHeight: '19px',
                                }}
                              >
                                Save changes
                              </span>
                            )}
                          </button>
                        ) : (
                          <>
                            {!disableTailorSummary && (
                              <button
                                className='general-button circle-button hover-background-gray-05 p-1 edit-commentary-btn'
                                style={{ transition: 'none' }}
                                disabled={!tailoredCondition}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditMode(true);
                                  setTimeout(() => {
                                    contentItem?.current.focus();
                                  }, 10);
                                }}
                              >
                                <span
                                  className='icon-commentary main-light-text pointer d-inline-block'
                                  data-tooltip-content={
                                    isMobile
                                      ? undefined
                                      : !tailoredCondition
                                        ? 'Add description to edit AI summary'
                                        : `Add commentary or edit text`
                                  }
                                  data-tooltip-id={'summary-tooltip'}
                                  data-tooltip-class-name={'content-summary-tooltip'}
                                ></span>
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <div className='position-relative' ref={actionsContainer}>
                      <button
                        className={`general-button circle-button hover-background-gray-05 p-1`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowActions(!showActions);
                        }}
                      >
                        <span className='icon-three-dots main-light-text' />
                      </button>
                      {showActions && (
                        <div className='box box-shadow py-2 item-actions-box w-auto'>
                          <div className='share-container position-relative'>
                            <div className='share-container-items flex-column'>
                              {!disableTailorSummary && contentSummary?.isTailored && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setShowActions(false);
                                    showHideTailorSummaryModal(true);
                                  }}
                                  className='general-button'
                                  style={{
                                    opacity: !contentSummary?.isTailored || loadingCreateSummary ? 0.5 : 1,
                                  }}
                                  disabled={!contentSummary?.isTailored || loadingCreateSummary}
                                  data-tooltip-content={
                                    loadingCreateSummary
                                      ? `Unable to update description while summary is in progress`
                                      : undefined
                                  }
                                  data-tooltip-id={'summary-tooltip'}
                                  data-tooltip-class-name={'content-summary-tooltip'}
                                >
                                  <span className='icon-bag' />
                                  {agencyUser
                                    ? `Update client description${keywordsListsAll.length === 1 ? '' : 's'}`
                                    : isParliamentarian
                                      ? 'Update biographical information'
                                      : 'Update organisation description'}
                                </button>
                              )}
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (window.Intercom) {
                                    window.Intercom(
                                      'startSurvey',
                                      parseInt(process.env.REACT_APP_INTERCOM_AI_FEEDBACK_SURVEY_ID)
                                    );
                                  }
                                }}
                                className='general-button'
                              >
                                <span className='icon-communication' />
                                Give feedback on {contentSummary?.isTailored ? 'tailored' : ''} summaries
                              </button>
                            </div>
                            <p className='px-3 pt-2 pb-1 item-actions-box-description'>
                              Summaries are created with AI, and tailored based on the description you provide. Care
                              should be taken to verify information before acting on it.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <span className={`dropdown-item-element ${isOpen ? 'open' : ''}`} />
              </div>
            </div>
            <>
              <div
                className={`w-100 summary-waiting ${loadingCreateSummary ? 'pb-4' : ''} pt-0`}
                style={{
                  opacity: isOpen && loadingCreateSummary ? 1 : 0,
                  transition: 'opacity 300ms',
                  height: !loadingCreateSummary ? 0 : undefined,
                  marginTop: loadingCreateSummary ? '-18px' : undefined,
                }}
              >
                <span className='main-light-text paragraph-p2 user-select-none'>
                  This typically takes 8 -10 seconds, but may take longer
                </span>
                <div className='waiting-bar mx-auto'>
                  <div className='waiting-bar-indicator' />
                </div>
              </div>
            </>
            <div
              style={{
                opacity: isOpen && !loadingCreateSummary ? 1 : 0,
                transition: 'opacity 100ms',
                pointerEvents: isOpen ? undefined : 'none',
              }}
            >
              <>
                <div className='last-edited-by-subtext'>
                  {contentSummary?.authorFullName && contentSummary?.lastEditedByUser && (
                    <i>
                      Last edited by {contentSummary?.authorFullName} on{' '}
                      {dayjs(contentSummary?.lastEditedByUser).tz('Europe/London', true).format('DD MMMM, YYYY')}
                    </i>
                  )}
                  {editMode && <EditBanner />}
                </div>
                {notTailoredBannerCondition && (
                  <NotTailoredBanner
                    clientName={topicFromKeywordList(selectedTopic)?.name}
                    clickedOnContentUrl={clickedOnContentUrl}
                  />
                )}
                <div
                  className={`mb-3 outline-none ${!editMode && !notTailoredBannerCondition ? 'pt-2' : ''}`}
                  ref={contentItem}
                  contentEditable={editMode}
                  dangerouslySetInnerHTML={createMarkup(contentSummary?.summary)}
                  onInput={() => setInputError(null)}
                  spellCheck='false'
                />
              </>
            </div>
          </>
        )}

        <ReactTooltip
          id={'summary-tooltip'}
          effect={'solid'}
          type={'info'}
          className={'content-summary-tooltip'}
          backgroundColor={'#00122B'}
          textColor={'#fff'}
          multiline={true}
          arrowColor={'transparent'}
          place={'right'}
        />
      </div>
      {inputError && <InputError errText={inputError} />}
    </>
  );
};

const useGetSummaryForContent = () => {
  const { generalApiCall } = useGeneralApiCall();
  const { isAuthenticated } = useAuth0();
  const getSummaryForContent = async ({ objectId, contentType, keywordListId }) => {
    if (isAuthenticated) {
      const pathname = keywordListId
        ? `/api/content-summary/get-summary-for-client/${keywordListId}?objectId=${objectId}&contentType=${contentType}`
        : `/api/content-summary/get-summary?objectId=${objectId}&contentType=${contentType}`;
      const method = 'get';
      let response = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        returnCompleteRequest: true,
        returnError: true,
        returnCancelled: true,
      });
      if (response?.status !== 204 && response?.status !== 200) {
        if (response?.status === 413) {
          return {
            summary: 'A summary for this content is unavailable because it is too long',
          };
        } else if (response?.status === 429) {
          return {
            summary: 'A summary for this content is temporarily unavailable, please try again later',
          };
        } else {
          if (response?.message?.includes('cancelled')) {
            return;
          }
          createNotification('danger', 'Error generating summary');
        }
      }
      if (response?.data?.summary === '') {
        return;
      }
      return response?.data;
    }
    return;
  };
  return { getSummaryForContent };
};

const EditBanner = () => {
  return (
    <div className='summary-edit-banner'>
      This allows you to fine-tune AI output or add your own commentary before sharing it. Changes you make will also be
      visible to your teammates.
    </div>
  );
};

const NotTailoredBanner = ({ clientName, clickedOnContentUrl }) => {
  let inSearchCondition =
    getUrlParam('search') || getUrlParam('or') || clickedOnContentUrl?.startsWith('/search-results/');
  return (
    <div className='summary-not-tailored-banner'>
      This update {inSearchCondition ? 'may be relevant' : 'has been identified as'} relevant to {clientName}, but the
      summary is generic. Click ‘Tailor summaries’ to customise it.
    </div>
  );
};

const ContentSummaryPopupContent = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <div className='bg-white rounded-lg text-center relative'>
        <div className='d-lg-flex'>
          <div className='bg-main-blue pl-lg-2 py-5 text-left d-none d-lg-block'>
            <img
              className='statistics-popup-logo'
              alt='statistics-logo'
              src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
            />
            <ul className='policy-list text-white text-left title-h5-m pt-5 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
              <li className='pl-4 mb-3 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Content summaries
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Tailored political monitoring
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Analytics on any policy area
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Advanced stakeholder mapping
              </li>
              <li className='pl-4 mb-3 py-2 nowrap-item '>
                <span className='inline-block title-h3 mr-3 icon-small-check' />
                Policymaker CRM
              </li>
            </ul>
          </div>
          <div className='grow px-4 pb-5 mb-3'>
            <p className='title-h3 text-center mt-3 pt-3 pt-lg-5 px-lg-3 mb-4'>
              {isAuthenticated
                ? 'Content summaries are available on Pro, Mogul and Enterprise'
                : 'Try PolicyMogul’s AI features for free to summarise content, create briefing material and more'}
            </p>
            <img
              className='w-100 mt-4 mb-5'
              alt='content-summary'
              src={`${process.env.REACT_APP_CDNURL}/images/20230330-content-summary-image.svg`}
            />
            <div className='text-center text-lg-right mt-4 mt-lg-0'>
              <Link
                className='action-button rounded-button py-2 px-5 title-h5 d-inline-block'
                to={!isAuthenticated ? '/register?utm_source=content-summary-button' : '/pricing'}
              >
                <span>
                  {isAuthenticated ? 'Compare plans' : 'Try PolicyMogul for free'}
                  <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContentSummaryPopup = (props) => {
  return (
    <Popup {...props}>
      <ContentSummaryPopupContent {...props} />
    </Popup>
  );
};

export { useGetSummaryForContent };
export default ContentSummary;
