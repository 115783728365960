import React, { useEffect, useContext, useRef, useState } from 'react';
import { store } from '../Store.js';
import { useLocation, Link } from 'react-router-dom-v5-compat';
import Navigation from '../Dashboard/Navigation';
import Footer from './Footer.js';
import useIsMobile from '../Dashboard/utilities/useIsMobile.js';
import addMetaTags from '../Dashboard/utilities/addMetaTags.js';
import getUtmParameters from '../Dashboard/helpers/getUtmParameters.js';
import createUtmParameters from '../Dashboard/helpers/createUtmParameters.js';
import SimpleSearchBar from '../Dashboard/Navigation/SimpleSearchBar.js';
import useScrollBody from './useScrollBody.js';
const WebFont = require('webfontloader');

const Home = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const location = useLocation();
  const [fontLoaded, setFontLoaded] = useState(false);

  useScrollBody();
  useEffect(() => {
    let title = `PolicyMogul - Political monitoring platform for UK public affairs and policymakers`;
    let hash = location.hash;
    let contentUrl = '/';
    let description =
      'PolicyMogul is an all-in-one public affairs and political monitoring platform. We make it easy to monitor, influence and analyse parliament, government and policymakers';
    addMetaTags({ title, hash, location, dispatch, contentUrl, description });
    getUtmParameters(location);
    requestAnimationFrame(draw);
    WebFont.load({
      google: {
        families: ['Lato'],
      },
      active: function () {
        setFontLoaded(true);
      },
    });
  }, []);

  const [dataSpin, setDataSpin] = useState(0);
  const fps = 30;
  const interval = 60000 / fps;
  const counter = useRef(0);
  let then;
  let delta;

  const draw = (now) => {
    if (counter.current < 5) {
      if (!then) {
        then = now;
      }
      requestAnimationFrame(draw);
      delta = now - then;
      if (delta > interval) {
        // update time stuffs
        // Just `then = now` is not enough.
        // Lets say we set fps at 10 which means
        // each frame must take 100ms
        // Now frame executes in 16ms (60fps) so
        // the loop iterates 7 times (16*7 = 112ms) until
        // delta > interval === true
        // Eventually this lowers down the FPS as
        // 112*10 = 1120ms (NOT 1000ms).
        // So we have to get rid of that extra 12ms
        // by subtracting delta (112) % interval (100).
        // Hope that makes sense.

        then = now - (delta % interval);
        setDataSpin(counter.current + 1);
        counter.current += 1;
      }
    }
  };

  const isMobile = useIsMobile(720);
  const homeMainContainer = useRef();

  return (
    <div className='home-container'>
      <Navigation />
      <main className='home'>
        <section className='home-main-information' ref={homeMainContainer} data-spin-to={dataSpin}>
          <div className='home-animation-square'>
            <div className='home-animation-square-element' />
          </div>
          <div className='home-main-container' style={{ opacity: fontLoaded ? 1 : 0 }}>
            <div className='home-elements mx-auto'>
              <div className='col-sm-15 col-lg-10 px-3 px-lg-4 mx-auto text-center pb-5 pt-2 pt-lg-5 main-information-container'>
                <h1>
                  Champions {!isMobile ? 'of' : ''}
                  <span className='accent accent-3 home-spinner' data-spin-to={dataSpin}>
                    <span className='spinner-container d-block'>
                      <span className='spinner-item'>
                        {isMobile ? 'of' : ''} public {!isMobile ? 'affairs' : ''}
                      </span>
                      <span className='spinner-item'>
                        {isMobile ? 'of' : ''} cutting through {!isMobile ? 'the noise' : ''}
                      </span>
                      <span className='spinner-item'>
                        {isMobile ? 'of' : ''} knowing who {!isMobile ? 'to target' : ''}
                      </span>
                      <span className='spinner-item'>
                        {isMobile ? 'of' : ''} actually having {!isMobile ? 'an impact' : ''}
                      </span>
                      <span className='spinner-item'>
                        {isMobile ? 'of' : ''} avoiding {!isMobile ? 'surprises' : ''}
                      </span>
                      <span className='spinner-item'>
                        {isMobile ? 'of' : ''} public {!isMobile ? 'affairs' : ''}
                      </span>
                    </span>
                  </span>
                  {isMobile && (
                    <span className='accent accent-3 home-spinner' data-spin-to={dataSpin}>
                      <span className='spinner-container d-block'>
                        <span className='spinner-item'>affairs</span>
                        <span className='spinner-item'>the noise</span>
                        <span className='spinner-item'>to target</span>
                        <span className='spinner-item'>an impact</span>
                        <span className='spinner-item'>surprises</span>
                        <span className='spinner-item'>affairs</span>
                      </span>
                    </span>
                  )}
                </h1>
                <p className='px-2'>
                  PolicyMogul makes you better at public affairs. We ensure you don't miss the political developments
                  that matter to you, and help you connect with the right policymakers.
                </p>
                <div className='py-2 home-searchbar-container'>
                  <SimpleSearchBar homePage />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='pb-5 py-lg-5 mb-lg-5 real-time-political'>
          <div className='container'>
            <div className='row mx-0 align-items-center'>
              <div className='col-lg-8 pr-lg-5 mb-5 mb-lg-0'>
                <img
                  src={`${process.env.REACT_APP_CDNURL}/images/ui-policy-mogul.png`}
                  alt=' PolicyMogul screenshot'
                  className='img-fluid'
                />
              </div>
              <div className='col-lg-8 pl-lg-5 text-left text-md-center text-lg-left'>
                <h2>Real-time political monitoring</h2>
                <p>
                  99% of government and political developments are irrelevant to your organisation. PolicyMogul ensures
                  you don't miss the 1% you need to know. Our real-time coverage of parliament, government and the wider
                  policy-forming arena includes:
                </p>
                <ul className='px-0 policy-list real-time-coverage text-left col-sm-10 col-lg-16 mx-auto'>
                  <li>Political announcements</li>
                  <li>Consultations</li>
                  <li>Legislation</li>
                  <li>Parliamentary proceedings</li>
                  <li>Upcoming events</li>
                  {process.env.REACT_APP_ENABLE_TWITTER === 'true' && <li>Twitter</li>}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className='home-blockquote-container mt-lg-5 pt-5 pb-5 pb-lg-0'>
          <div className='container'>
            <div className='row mx-0 align-items-center'>
              <div className='col-lg-10'>
                <div className='blockquote-container'>
                  <blockquote>
                    PolicyMogul has changed the way we work and campaign. It has moved us from being reactive as a trade
                    association, to being proactive as a thought leader.
                  </blockquote>
                  <p>Bhavina Bharkhada, Make UK Head of Policy & Campaigns</p>
                </div>
              </div>
              <div className='col-lg-4 px-0 pl-lg-5 text-center d-none d-lg-block'>
                <img
                  src={`${process.env.REACT_APP_CDNURL}/images/bb-profile-pic.png`}
                  alt='photograph of Bhavina Bharkhada'
                  className='img-fluid'
                />
              </div>
            </div>
          </div>
        </section>
        <section className='home-opening-message pt-lg-5 pb-lg-5'>
          <div className='container py-5'>
            <div className='row mx-0 align-items-center'>
              <div className='col-lg-8 px-0 mb-5 mb-lg-0 d-none d-lg-block'>
                <img
                  src={`${process.env.REACT_APP_CDNURL}/images/20210928-increase-visibility.gif`}
                  alt='PolicyMogul Manage Campaigns'
                  className='img-fluid mx-auto d-block opening-image'
                />
              </div>
              <div className='col-lg-8 pr-lg-0 pl-lg-4'>
                <h2>Have your voice heard</h2>
                <p
                  style={{
                    maxWidth: '450px',
                  }}
                >
                  PolicyMogul enables organisations of all sizes to make policy asks, news releases and other briefing
                  material available to relevant parliamentarians. MPs, peers and their staff will have access to your
                  content to help inform their work.
                </p>
                <Link to={'/influence/add-content'}>
                  <button className='general-button px-3 py-2'>Add content</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className='home-second-blockquote py-5'>
          <div className='container'>
            <div className='col-lg-12 mx-auto px-lg-0'>
              <div className='blockquote-container'>
                <blockquote>
                  With PolicyMogul we can keep on top of political developments that are relevant to our business. It
                  saves us significant time and money, and gives us confidence that nothing is missed.
                </blockquote>
                <p>Jonathan Ainley, Head of Public Affairs, KiWi Power</p>
              </div>
            </div>
          </div>
        </section>
        <section className='home-trybanner text-center py-5 px-3'>
          <h2 className='mb-4'>Ready to get started?</h2>
          <button
            className='text-uppercase general-button px-4 py-2'
            onClick={() => {
              window.location.assign(
                `/register?reg_source=homepage-bottom-try-it-its-free-button${createUtmParameters('&')}`
              );
            }}
          >
            Try it for free
          </button>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
