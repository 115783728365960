import { useEffect } from 'react';
//https://css-tricks.com/dangers-stopping-event-propagation/
//https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
//
const useOutsideElement = (ref, callback, triggers = []) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event.target);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ...triggers]);
};

export default useOutsideElement;
