import { useStepsStructure } from './StepsStructure.js';
import dayjs from 'dayjs';
import { lowerCaseFirstLetter } from '../utilities/uppercaseFirstLetter.js';
import transformToPascalCase from '../../Settings/Utilities/TransformToPascalCase.js';
import { useParams } from 'react-router-dom-v5-compat';

const useTransformEditInfluenceResults = () => {
  const { createStepsStructure } = useStepsStructure();
  const params = useParams();

  let contentField =
    params.page === 'policy-asks'
      ? 'policyAskContent'
      : `${lowerCaseFirstLetter(transformToPascalCase(params.page.replace('-', ' ')))}Content`;

  const TransformEditInfluenceResults = (results, agency, organisationWebsite) => {
    let finalResults = JSON.parse(
      JSON.stringify(
        createStepsStructure({
          agency,
          organisationWebsite,
          version: results?.contentVersion,
          summary: results?.[contentField]?.summary,
        })
      )
    );
    finalResults.forEach((item, index) => {
      let { fields } = item;
      fields?.forEach((field) => {
        for (let result in results) {
          if (
            result === 'briefingMaterialContent' ||
            result === 'policyAskContent' ||
            result === 'newsReleaseContent' ||
            result === 'researchContent'
          ) {
            for (let resultField in results[result]) {
              if (resultField === field.name) {
                field.value = adjustApiValues(field.name, results[result][resultField]);
              }
            }
          } else {
            if (result === field.name) {
              field.value = adjustApiValues(field.name, results[result]);
            }
          }
        }
      });
      if (results.publishedAt !== null || index === 0) {
        item.complete = true;
      }
    });
    return finalResults;
  };

  const adjustApiValues = (fieldName, fieldValue) => {
    switch (fieldName) {
      case 'attachments':
        let newAttachments = [];
        fieldValue.forEach((item) => {
          let filename = item.split('/');
          newAttachments.push({
            url: item,
            name: decodeURIComponent(filename[filename.length - 1]),
          });
        });
        return {
          savedFiles: newAttachments,
        };
      case 'expirationDate':
        if (!!fieldValue) {
          return {
            name: dayjs(fieldValue).format('DD MMM[,] YYYY'),
            value: fieldValue,
          };
        } else {
          return fieldValue;
        }
      case 'region':
        if (!!fieldValue) {
          return fieldValue;
        } else {
          return 'National';
        }
      default:
        return fieldValue;
    }
  };

  return { TransformEditInfluenceResults };
};

export default useTransformEditInfluenceResults;
