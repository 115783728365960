import { CompileUserFields } from './TeamUserFields';
const createUpdateField = (props) => {
  const { createTeamFields, setCreateTeamFields } = props;

  return (property, newValue) => {
    let newFields = createTeamFields.slice();
    let position = createTeamFields.findIndex((item) => item.name === property);
    let item = {
      ...newFields[position],
      value: newValue,
      errorMessage: null,
    };
    newFields[position] = item;

    if (property === 'userType') {
      newFields = CompileUserFields(newFields);
    }
    setCreateTeamFields(newFields);
  };
};

export default createUpdateField;
