import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';

const SkeletonTeam = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='pt-4 pt-lg-5'>
        <div className='settings-box pb-lg-5 pb-3' ref={containerRef} style={{ height: `${heightContainer}px` }}>
          <div className='px-lg-5 px-3'>
            <div className='row mx-0 align-items-center section-title py-4'>
              <div className='col-lg-6 d-none d-lg-block'>
                <Skeleton width={'80%'} height={19} />
              </div>
              <div className='col-lg-5 d-none d-lg-block'>
                <Skeleton width={'50%'} height={19} />
              </div>

              <div className='col-lg-5 text-right px-0'>
                <Skeleton width={'70%'} height={19} />
              </div>
            </div>
            <div className='team-content'>
              {Array(5)
                .fill()
                .map((item, index) => (
                  <div className='row mx-0 team-item py-4' key={`item${index}`}>
                    <div className='col-lg-6 mb-4 mb-lg-0'>
                      <Skeleton width={'80%'} height={19} />
                      <Skeleton width={'50%'} height={19} />
                    </div>
                    <div className='col-14 col-lg-5 align-self-lg-center pr-lg-3 pr-0'>
                      <Skeleton width={'80%'} height={32} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonTeam;
