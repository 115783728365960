import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';

const useGetTeamTopics = () => {
  const { generalApiCall } = useGeneralApiCall();
  const getTeamTopics = async ({ organisationId, source }) => {
    let method = 'get';
    let pathname = `/api/admin/get-organisation?organisationId=${organisationId}`;
    let requestProperties = {};
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });

    return result;
  };
  return { getTeamTopics };
};

export { useGetTeamTopics };
