import { useContext, useEffect, useState } from 'react';
import ModalComponent from '../Common/Modal';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import { store } from '../Store';
import { useNavigate } from 'react-router-dom-v5-compat';
import KeywordsAndAdvancedSearchComponent from '../Settings/Topics/KeywordsAndAdvancedSearchComponent';
import TopicPicker from '../Common/TopicPicker';
import { useChangeKeywordsListsOrder } from '../Dashboard/hooks/useSearchBarUiFunctions';
import { useLocation } from 'react-router-dom-v5-compat';
import { useSetListsIds } from '../Dashboard/MainSearchBar/setListIds';

const ReportsCreatePopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists.filter((item) => item.id !== null),
  });
  const keywordsListsToUse = changeKeywordsListsOrder();

  const { agencyUser } = useTopicOrClientWord();
  const { isOpen, setIsOpen } = props;
  const [contentStep, setContentStep] = useState(agencyUser ? 'FirstStep' : 'SecondStep');
  const [selectedTopic, setSelectedTopic] = useState(keywordsListsToUse[0]);
  const [optionSelected, setOptionSelected] = useState('existingClient');
  const [originProspectiveOption, setOriginProspectiveOption] = useState('FirstStep');

  const closePopup = (value) => {
    setIsOpen(value);
    //AE: Avoid a flashing effect resetting the popup state
    setTimeout(() => {
      setContentStep(agencyUser ? 'FirstStep' : 'SecondStep');
      setOptionSelected('existingClient');
    }, 200);
  };

  useEffect(() => {
    setSelectedTopic(keywordsListsToUse[0]);
  }, [keywordsLists]);

  return (
    <ModalComponent
      setIsOpen={closePopup}
      isOpen={isOpen}
      maxWidth={contentStep === 'SecondStep' ? 600 : 830}
      shouldCloseOnOverlayClick={contentStep !== 'ProspectiveContent'}
      overlayClassName={`react-modal-overlay ${contentStep === 'SecondStep' ? 'report-create-overlay' : ''}`}
    >
      <div className='reports-create-popup-content'>
        {contentStep === 'FirstStep' && (
          <FirstStepContent
            optionSelected={optionSelected}
            setOptionSelected={setOptionSelected}
            setContentStep={setContentStep}
            setOriginProspectiveOption={setOriginProspectiveOption}
          />
        )}
        {contentStep === 'SecondStep' && (
          <SecondStepContent
            optionSelected={optionSelected}
            keywordsListsToUse={keywordsListsToUse}
            setOptionSelected={setOptionSelected}
            setContentStep={setContentStep}
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
            setOriginProspectiveOption={setOriginProspectiveOption}
          />
        )}
        {contentStep === 'ProspectiveContent' && (
          <PropectiveContent setContentStep={setContentStep} originProspectiveOption={originProspectiveOption} />
        )}
      </div>
    </ModalComponent>
  );
};

const FirstStepContent = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableAdvancedSearch } = activePlan;
  const { setContentStep, optionSelected, setOptionSelected, setOriginProspectiveOption } = props;
  const { topicOrClientWord } = useTopicOrClientWord();
  return (
    <>
      <h3 className='title-h4-bold py-4 px-3 px-lg-5 section-title mb-0'>
        <span className='icon-document mr-2' /> Who is this report for?
      </h3>

      <div className='px-3 px-lg-5 py-4'>
        <div className='d-lg-flex'>
          <div className={`col-lg-8 influence-box-container influence-box-container-auto`}>
            <div
              className={`h-100 pointer influence-visibility-box box-rounded text-center box-select ${optionSelected === 'existingClient' ? 'box-selected' : ''}`}
              onClick={() => setOptionSelected('existingClient')}
            >
              <h4 className='title-h4-bold mb-3'>An existing {topicOrClientWord}</h4>
              <p className='paragraph-p2 main-light-text mb-0'>
                Create a report based on the keywords you’ve set up for an existing {topicOrClientWord} on PolicyMogul
              </p>
            </div>
          </div>

          <div
            className={`col-lg-8 influence-box-container influence-box-container-auto ${!enableAdvancedSearch ? 'influence-box-container-disabled' : ''}`}
            onClick={() => setOptionSelected('prospectiveClient')}
          >
            <div
              className={`h-100 pointer influence-visibility-box box-rounded text-center box-select ${optionSelected === 'prospectiveClient' ? 'box-selected' : ''}`}
              onClick={() => {
                if (enableAdvancedSearch) {
                  setOptionSelected('prospectiveClient');
                }
              }}
            >
              {!enableAdvancedSearch && <span className='icon-padlock d-inline-block ml-2' />}
              <h4 className='title-h4-bold mb-3'>A prospective {topicOrClientWord}</h4>
              <p className='paragraph-p2 main-light-text mb-0'>
                Create a report for a prospective client, or for an issue not yet covered by your PolicyMogul keywords
              </p>
            </div>
          </div>
        </div>

        <div className='text-right'>
          <button
            className='action-button rounded-button px-5 py-2'
            onClick={() => {
              setContentStep(optionSelected === 'prospectiveClient' ? 'ProspectiveContent' : 'SecondStep');
              setOriginProspectiveOption('FirstStep');
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

const SecondStepContent = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan } = state;
  const { enableAdvancedSearch } = activePlan;

  const {
    selectedTopic = {},
    setSelectedTopic,
    setContentStep,
    optionSelected,
    setOptionSelected,
    setOriginProspectiveOption,
    keywordsListsToUse,
  } = props;
  const { topicOrClientWord, agencyUser } = useTopicOrClientWord();
  const { setListsIds } = useSetListsIds();

  const changeOption = (option) => {
    setOptionSelected(option);
  };
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <h3 className='title-h4-bold px-3 px-lg-5 py-4 section-title'>
        {agencyUser ? 'Who is this report for?' : 'Choose a topic to base your report on'}
      </h3>

      <div className='px-3 px-lg-5 pb-4'>
        {agencyUser ? (
          <ul className='policy-list px-0 mb-0 pt-3'>
            <li className={`radio-button mb-4`}>
              <input
                id={'existingClient'}
                type='radio'
                checked={optionSelected === 'existingClient'}
                onChange={() => {
                  changeOption('existingClient');
                }}
              />
              <label htmlFor={'existingClient'}>Create a report for an existing {topicOrClientWord}</label>

              <div className='mt-3 px-4'>
                <TopicPicker
                  keywordsLists={keywordsListsToUse}
                  showTopicCases={true}
                  searchFunction={({ topic }) => {
                    setSelectedTopic(topic);
                    setOptionSelected('existingClient');
                    setListsIds(topic?.id);
                  }}
                  defaultTopic={selectedTopic}
                  fixedPosition
                  placeholder={`Select a ${topicOrClientWord}`}
                  showTopicSelected={true}
                  dataTooltip={'modal-tooltip'}
                />
              </div>
            </li>
            <li className={`radio-button pt-1 mb-4`}>
              <input
                id={'prospectiveClient'}
                type='radio'
                checked={optionSelected === 'prospectiveClient'}
                onChange={() => {
                  changeOption('prospectiveClient');
                }}
                disabled={!enableAdvancedSearch}
              />
              <label htmlFor={'prospectiveClient'}>
                Create a report for a prospective client or new issue{' '}
                {!enableAdvancedSearch && <span className='icon-padlock d-inline-block ml-2' />}
              </label>
            </li>
          </ul>
        ) : (
          <div className='pt-4 pb-3'>
            <TopicPicker
              keywordsLists={keywordsListsToUse}
              showTopicCases={true}
              searchFunction={({ topic }) => {
                setSelectedTopic(topic);
                setOptionSelected('existingClient');
                setListsIds(topic?.id);
              }}
              defaultTopic={selectedTopic}
              fixedPosition
              placeholder={`Select a ${topicOrClientWord}`}
              showTopicSelected={true}
              dataTooltip={'modal-tooltip'}
            />
          </div>
        )}

        <div className={`pt-4 pb-2 d-flex justify-content-${agencyUser ? 'between' : 'end'}`}>
          {agencyUser && (
            <button
              className='text-light-blue general-button rounded-button px-0 py-2'
              onClick={() => {
                setContentStep('FirstStep');
              }}
            >
              <span className='icon-long-arrow-left mr-2 paragraph-p5' />
              Back
            </button>
          )}
          <button
            className='action-button rounded-button px-4 py-2'
            onClick={() => {
              if (agencyUser && optionSelected === 'prospectiveClient') {
                setContentStep('ProspectiveContent');
                setOriginProspectiveOption('SecondStep');
              } else {
                dispatch({ type: 'MODIFY_SECTION', parameter: 'topicForReport', value: selectedTopic });
                let queryParameters = new URLSearchParams(location.search);
                queryParameters.set('open', true);
                queryParameters.set('topic-id', selectedTopic?.id);
                navigate(
                  { pathname: '/reports/create', search: queryParameters.toString() },
                  { state: { topicId: selectedTopic?.id } }
                );
              }
            }}
            disabled={Object.keys(selectedTopic)?.length === 0 && optionSelected === 'existingClient'}
          >
            Create report
          </button>
        </div>
      </div>
    </>
  );
};

const PropectiveContent = (props) => {
  const { originProspectiveOption, setContentStep } = props;
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [customTopic, setCustomTopic] = useState({
    isMyList: true,
    id: '',
    keywords: [],
    andKeywords: [],
    keywordExclusions: [],
  });
  const [organisationData, setOrganisationData] = useState({
    name: '',
    websiteUrl: '',
  });
  const { topicOrClientWord } = useTopicOrClientWord();
  const [openStates, setOpenStates] = useState({
    additional: false,
    exclusions: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className='dahboard-root-container'>
      <h3 className='title-h4-bold px-3 px-lg-5 py-4 section-title mb-0'>
        <span className='icon-document mr-2' /> Enter some details about the recipient. This will allow us to tailor
        your report.
      </h3>

      <div className='flex-grow-1 position-relative'>
        <div className='py-4 px-3 px-lg-5 reports-create-prospective-keywords-content prospective-keywords-container'>
          <div className='row'>
            <div className={`col-lg-8`}>
              <div className={`form-group`}>
                <label className='font-weight-bold' htmlFor='organisationName'>
                  Organisation name
                </label>
                <input
                  id='organisationName'
                  value={organisationData?.name}
                  onChange={(e) => setOrganisationData({ ...organisationData, name: e.target.value })}
                  autocomplete={'off'}
                  className='main-input form-control'
                  type='text'
                  placeholder='Organisation name'
                  autoFocus={true}
                />
              </div>
            </div>

            <div className={`col-lg-8 `}>
              <div className={`form-group`}>
                <label className='font-weight-bold' htmlFor='organisationWebsite'>
                  Their website URL{' '}
                  <span
                    className='icon-info ml-2'
                    data-tooltip-html="For most websites, this will enable us<br/> to automatically include the prospect's<br/> logo on the report. You'll be able to remove<br/> or change it later."
                    data-tooltip-id='modal-tooltip'
                  />
                </label>
                <input
                  id='organisationWebsite'
                  value={organisationData?.websiteUrl}
                  onChange={(e) => setOrganisationData({ ...organisationData, websiteUrl: e.target.value })}
                  className='main-input form-control'
                  type='text'
                  placeholder='Website URL'
                />
              </div>
            </div>
          </div>

          <div className='my-4'>
            <p className='title-h5-bold mb-1'>Keywords and phrases</p>
            <p className='mb-3'>Enter the keywords and phrases relevant to this {topicOrClientWord} or prospect</p>

            <KeywordsAndAdvancedSearchComponent
              customTopic={customTopic}
              customHandleChange={({ topic }) => setCustomTopic(topic)}
              openStates={openStates}
              setOpenStates={setOpenStates}
              tooltipTarget={'modal-tooltip'}
            />
          </div>
        </div>
      </div>

      <div className='py-4 text-right px-3 px-lg-5 border-top bg-salmon rounded-bottom d-flex justify-content-between'>
        <button
          className='text-light-blue general-button rounded-button px-0 py-2'
          onClick={() => {
            setContentStep(originProspectiveOption);
          }}
        >
          <span className='icon-long-arrow-left mr-2 paragraph-p5' />
          Back
        </button>

        <button
          className='general-button action-button rounded-button px-4 py-2'
          disabled={
            customTopic?.keywords?.length === 0 || organisationData?.name === '' || organisationData?.websiteUrl === ''
          }
          onClick={() => {
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'topicForReport',
              value: { ...organisationData, ...customTopic, prospective: true },
            });
            dispatch({ type: 'MODIFY_SECTION', parameter: 'createReportPopup', value: true });
            let queryParameters = new URLSearchParams(location.search);
            queryParameters.set('open', true);
            navigate({ pathname: '/reports/create', search: queryParameters.toString() }, { state: { topicId: null } });
          }}
        >
          Create report
        </button>
      </div>
      {/* <div className="py-3 px-3 px-lg-5">
        <p className="mb-0 main-light-text">You have 10 reports remaining this month. <a href={process.env.REACT_APP_APPURL} className="simple-link-button">Learn more</a></p>  
      </div> */}
    </div>
  );
};

export { SecondStepContent };
export default ReportsCreatePopup;
