import axios from 'axios';
import ContentItemsPanel from './ui/ContentItemsPanel';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import { graphDataSections } from './useCallMentionsByTopic';
import dayjs from 'dayjs';

const RelevantStakeholdersRightPanel = (props) => {
  const { contentState, setRightPanelState, stakeholdersData } = props;
  const { pointInTime } = stakeholdersData;
  const { text, rawData, dataKey, stakeholderId, stakeholderTweetsId } = contentState;
  const totalCell = dataKey === 'Total';

  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const list = keywordsLists.find((item) => item.id === parseInt(getUrlParam('topic-id')));

  let sections = ['Spoken parliamentary contributions', 'Written questions', 'EDMs', 'Committee contributions'];
  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    sections = [...sections, 'Tweets', 'Retweets'];
  }

  const createSectionValues = () => {
    const values = [];
    if (totalCell) {
      sections.forEach((section) => {
        values.push({ name: section, value: rawData[section] });
      });
    }
    return values.filter((item) => item.value > 0);
  };

  const sectionsValues = createSectionValues();
  const [activeSection, setActiveSection] = useState(totalCell ? sectionsValues[0]?.name : dataKey);
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);

  const callContentItems = useRef();
  const pageSize = parseInt(process.env.REACT_APP_PAGE_SIZE);
  const isLoadedComponent = useRef(false);
  const { generalApiCall } = useGeneralApiCall();

  callContentItems.current = async ({ type = activeSection, pageNumber, source }) => {
    try {
      if (!pageNumber) {
        setIsLoading(true);
      }

      const pathname = '/api/search/query-by-keyword-list';
      const method = 'post';
      const typeToCall = type.toLowerCase().includes('tweet') ? 'tweet' : 'ParliamentaryRecord';

      let requestProperties = {
        pageSize,
        pageNumber: pageNumber ?? 1,
        type: typeToCall,
        includeHighlights: true,
        keywordListId: list?.id,
        sort: { field: 'dateTime', order: 'DESC' },
      };

      let filters = [
        {
          field: 'dateTime',
          value: `${dayjs(pointInTime).subtract('365', 'day').startOf('day').format('YYYY-MM-DD')}`,
          operator: 'DATE_GTE',
        },
        { field: 'dateTime', value: `${dayjs(pointInTime).format('YYYY-MM-DD')}`, operator: 'DATE_LT' },
      ];

      let sectionItem = graphDataSections.find((item) => item.name === type);
      filters.push({ field: sectionItem?.field, value: sectionItem?.value, operator: 'str_eq' });

      if (typeToCall === 'ParliamentaryRecord') {
        filters.push({ field: 'contactIds', value: `${stakeholderId}`, operator: 'str_eq' });
      }

      if (typeToCall === 'tweet') {
        filters.push({ field: 'ptUserId', value: `${stakeholderTweetsId}`, operator: 'str_eq' });
      }

      requestProperties = {
        ...requestProperties,
        filters,
      };

      let callresults = await generalApiCall({
        pathname,
        method,
        requestProperties,
        requestSource: source,
        needsAuthentication: true,
        notShowErrorMessage: true,
      });
      if (callresults) {
        let hits = pageNumber ? results.hits : [];
        let highlights = pageNumber ? results.highlights : [];

        hits = hits.concat(callresults.hits);
        highlights = highlights.concat(callresults.highlights);

        setResults({ ...callresults, hits, highlights, thereIsMoreContent: callresults.hits?.length === pageSize });
        setIsLoading(false);
        isLoadedComponent.current = true;
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    callContentItems.current({ source });
    return () => {
      source.cancel('Content items panel cancelled by the user');
    };
  }, [activeSection]);

  useEffect(() => {
    if (isLoadedComponent.current) {
      let valueToCompare = totalCell ? sectionsValues[0]?.name : dataKey;
      if (valueToCompare !== activeSection) {
        setActiveSection(valueToCompare);
      } else {
        callContentItems.current({});
      }
    }
  }, [contentState]);

  const load_more_results = async () => {
    let { thereIsMoreContent, pageNumber } = results;
    if (thereIsMoreContent) {
      await callContentItems.current({ pageNumber: pageNumber + 1 });
    }
  };
  const handleScroll = (scrollValues) => {
    const { scrollTop, clientHeight, scrollHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      load_more_results();
    }
  };

  return (
    <ContentItemsPanel
      handleScroll={handleScroll}
      setRightPanelState={setRightPanelState}
      text={text}
      sectionsValues={sectionsValues}
      isLoading={isLoading}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      results={results}
      stakeholdersPage={true}
    />
  );
};

export default RelevantStakeholdersRightPanel;
