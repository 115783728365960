import React from 'react';
import { formatGeneralDate } from '../../Dashboard/helpers/formatGeneralDate';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import CustomScrollbar from '../../Common/CustomScrollbar';
import { Link } from 'react-router-dom-v5-compat';

const TeamList = (props) => {
  const { teamList, loadMoreResults } = props;
  const [heightContainer, containerRef] = useHeightContainer();
  const handleScroll = (scrollValues) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      loadMoreResults();
    }
  };
  return (
    <div className='box-top-rounded bg-white admin-general-content'>
      <div ref={containerRef} />
      <CustomScrollbar
        className={'main-content-scrollbar content-page-scrollbar'}
        style={{ height: `${heightContainer - 1}px` }}
        maximalThumbYSize={100}
        onScroll={(scrollValues) => {
          handleScroll(scrollValues);
        }}
      >
        {teamList.results.length > 0 && (
          <div>
            <div className='row mx-0 d-none d-lg-flex py-3 px-4 topic-list-titles main-sticky bg-white '>
              <div className='col-lg-4'>
                <h3>Team name</h3>
              </div>
              <div className='col-lg-5'>
                <h3>Owner</h3>
              </div>
              <div className='col-lg-5'>
                <h3>Plan information</h3>
              </div>
              <div className='col-lg-2'>
                <h3>Subscribed at</h3>
              </div>
            </div>
            {teamList.results.map((item) => {
              const {
                id,
                name,
                ownerFullName,
                ownerEmailAddress,
                plan,
                subscriptionStatus,
                createdAt,
                planCode,
                organisationLogo,
                trialEndDate,
              } = item;
              return (
                <Link to={`/admin/organisation/${id}/users`} className='team-link'>
                  <div
                    key={`team${id}`}
                    className='team-list-item section-title row mx-0 action-state-hover p-4 align-items-center'
                  >
                    <div className='col-lg-4 team-title-link'>
                      <img
                        className='rounded-logo mr-2 object-fit-cover'
                        src={
                          !!organisationLogo
                            ? organisationLogo
                            : `${process.env.REACT_APP_CDNURL}/images/20230909-company-placeholder.png`
                        }
                        alt='organisation logo'
                      />
                      {name}
                    </div>
                    <div className='col-lg-5'>
                      <p>{ownerFullName}</p>
                      <p className='main-light-text'>{ownerEmailAddress}</p>
                    </div>
                    <div className='col-lg-5'>
                      <p className='mb-1'>
                        {plan} ({planCode})
                      </p>

                      <p
                        className={`d-inline-block subscription-status status-${!!subscriptionStatus ? subscriptionStatus.toLowerCase() : ''}`}
                      >
                        {!!subscriptionStatus ? TransformFromPascalCase(subscriptionStatus) : ''}{' '}
                        {!!trialEndDate ? `(Ends ${formatGeneralDate(trialEndDate, true)})` : ''}{' '}
                      </p>
                    </div>
                    <div className='col-lg-2'>
                      <p>{formatGeneralDate(createdAt)}</p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
        {teamList.results.length === 0 && <p className='p-5 text-center'>No results</p>}
      </CustomScrollbar>
    </div>
  );
};

export default TeamList;
