import { useContext } from 'react';
import { store } from '../../../Store';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import useGetSectionFilters from '../../Filters/useGetSectionFilters';
import { useLocation } from 'react-router-dom-v5-compat';

function useContactsApiCalls() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { selectedCrmContacts } = state;
  const { selectedAll } = selectedCrmContacts;
  const { generalApiCall } = useGeneralApiCall();
  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const sectionFilters = getSectionFilters();
  const { filters } = sectionFilters;

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchQuery = query.get('search') ? decodeURIComponent(query.get('search')) : '';

  const getContactTagsByQuery = async (source) => {
    let method = 'post';
    let pathname = `/api/crm-contact/list-tags-by-query`;
    let requestProperties = {
      query: searchQuery,
      filters,
    };

    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });

    return result;
  };

  const removeTagsFromContacts = async ({ tags, contactIds }) => {
    const requestProperties = selectedAll
      ? {
          query: searchQuery,
          filters,
          tags,
        }
      : {
          ids: contactIds,
          tags,
        };
    let pathname = selectedAll ? '/api/crm-contact/remove-tags-by-query' : '/api/crm-contact/remove-tag';
    let method = 'post';
    let results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    return results;
  };

  const addTagsToContacts = async ({ tags, contactIds, preselectedContact }) => {
    const requestProperties =
      selectedAll && !preselectedContact
        ? {
            query: searchQuery,
            filters,
            tags,
          }
        : {
            ids: contactIds,
            tags,
          };
    let pathname = selectedAll && !preselectedContact ? '/api/crm-contact/tag-by-query' : '/api/crm-contact/tag';
    let method = 'post';
    let results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    return results;
  };

  return {
    getContactTagsByQuery,
    removeTagsFromContacts,
    addTagsToContacts,
  };
}

export default useContactsApiCalls;
