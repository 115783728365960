import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const SkeletonParliamentarians = () => {
  return (
    <div className='main-navigation  pt-lg-4 pt-5 pl-md-5 pl-3'>
      <SkeletonTheme baseColor='#f2f2f2'>
        <div className='my-3'>
          <Skeleton height={17} width={`40%`} />
        </div>
        <ul className='policy-list px-0'>
          {Array(6)
            .fill()
            .map((item, index) => (
              <li className={`my-3`} key={index}>
                <Skeleton height={22} width={`80%`} />
              </li>
            ))}
        </ul>
        {/* <div className="mt-5 mb-3">
          <Skeleton height={17} width={`40%`} />
        </div>
        <ul className="policy-list px-0" >
          {Array(2)
            .fill()
            .map((item, index) => (
              <li className={`my-2`} key={index}>
                <Skeleton height={20} width={`60%`} />
              </li>
            ))
          }
        </ul> */}
      </SkeletonTheme>
    </div>
  );
};

export default SkeletonParliamentarians;
