import React, { useRef } from 'react';
import Share from '../../Dashboard/components/Share';
import TweetItem from '../../Dashboard/social_media/TweetItem';
import TransformFromPascalCase from '../../Settings/Utilities/TransformFromPascalCase';
import { Link } from 'react-router-dom-v5-compat';
import changeLabelOfContent from '../../Dashboard/Parliamentary_record/ChangeLabelOfContent';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const SavedItemElements = (props) => {
  const { hits, rememberScrollPosition } = props;
  const transformResources = useRef();
  transformResources.current = (item) => {
    let finalItem = { ...item, renderTitle: item.title };
    switch (item.type) {
      case 'KeyUpdate':
        finalItem = {
          ...finalItem,
          renderSubtitle: dayjs(item.dateTime).format('DD MMM[,] YYYY'),
          additionalItemSubtitle: dayjs(item.dateTime).format('HH:mm'),
        };
        break;
      case 'Consultation':
        finalItem = {
          ...finalItem,
          renderSubtitle: `Source: ${item.departmentName}`,
        };
        break;
      case 'Legislation':
        finalItem = {
          ...finalItem,
          renderSubtitle: `${item.primaryTypeOfBill}${item.contentType === 'Secondary' ? ' legislation' : ''}`,
          additionalItemSubtitle: !!item.primarySession ? `Session ${item.primarySession}` : false,
        };
        break;
      case 'ParliamentaryRecord':
        const changeTypeOfContent = () => {
          if (!!item.hansardContentType) {
            return item.hansardContentType;
          } else if (item.contentType === 'QuestionAndAnswer') {
            return 'Written Q&As';
          } else if (item.contentType === 'Edm') {
            return 'Early Day Motion';
          } else {
            return changeLabelOfContent(item.contentType);
          }
        };

        finalItem = {
          ...finalItem,
          renderSubtitle: changeTypeOfContent(),
          additionalItemSubtitle: `${
            item.contentType.includes('QuestionAndAnswer') || item.contentType === 'Edm'
              ? item.questionHasAnswer === 'True'
                ? `Answered on `
                : `Tabled on `
              : ''
          } ${dayjs(item.dateTime).format('DD MMMM YYYY')}`,
        };
        break;
      case 'Stakeholder':
        finalItem = {
          ...finalItem,
          renderSubtitle: item.summary,
        };
        break;
      case 'UserContent':
        finalItem = {
          ...finalItem,
          renderSubtitle: `Lead organisation: ${item.organisation}`,
        };
        break;
      case 'Event':
        finalItem = {
          ...finalItem,
          contentUrl: `/calendar/${dayjs(item.dateTime).format('YYYY-MM-DD')}`,
        };
        break;
      default:
        finalItem = {
          ...finalItem,
        };
    }
    return finalItem;
  };
  return (
    <div className={`save-items-elements-list`}>
      {hits.map((item, index, array) => {
        const { type } = item;
        const explanations = [];
        const props = { index, item, array, explanations };
        if (type === 'Tweet') {
          return <TweetItem {...props} key={`${type}-item-${index}`} />;
        } else {
          return (
            <SavedItemElement
              {...props}
              key={`${type}-item-${index}`}
              item={transformResources.current(item)}
              rememberScrollPosition={rememberScrollPosition}
            />
          );
        }
      })}
    </div>
  );
};

const SavedItemElement = (props) => {
  const { item, index, array, rememberScrollPosition } = props;
  const { renderTitle, renderSubtitle, additionalItemSubtitle, contentUrl } = item;

  const objectType = () => {
    if (item.type === 'UserContent') {
      return item.contentType;
    } else if (item.type) {
      return item.type;
    } else {
      return item.objectType;
    }
  };
  const getTitle = (text) => {
    if (text === 'Country specific parliamentary record') {
      return 'Devolved parliament official report';
    } else {
      return text;
    }
  };

  return (
    <Link
      to={contentUrl}
      className='content-item-link'
      style={{ zIndex: array.length - index }}
      onClick={() => {
        rememberScrollPosition();
      }}
    >
      <div className='saved-item-element d-flex justify-content-between align-items-start'>
        <div>
          <p
            className={`beta-feature paragraph-p4 text-light-blue blue-icon individual-item individual-item-${objectType().toLowerCase()} popup-individual-item mb-2 mt-0`}
          >
            {getTitle(TransformFromPascalCase(objectType()))}
          </p>
          <h3 className={`content-item-title ${renderSubtitle ? '' : 'mb-0'}`}>{renderTitle}</h3>
          <p className='paragraph-p2 main-light-text mb-0'>
            {renderSubtitle}
            {additionalItemSubtitle && (
              <>
                <span className='dot'></span>
                {additionalItemSubtitle}
              </>
            )}
          </p>
        </div>
        <div className='flex-centered'>
          <Share item={item} listItem influenceItem={item?.type === 'UserContent'} />
        </div>
      </div>
    </Link>
  );
};

export default SavedItemElements;
