export const colourStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: 'transparent',
    outline: 'none',
    boxShadow: 'none',
    ':hover': {
      borderColor: 'transparent',
    },
    backgroundColor: 'transparent',
    cursor: 'text',
    paddingLeft: '26px',
  }),
  option: (styles) => {
    return {
      ...styles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      color: '#00122b',
      fontSize: '16px',
      ':active': {
        ...styles[':active'],
        backgroundColor: 'transparent',
      },
    };
  },
  placeholder: (styles) => ({
    ...styles,
    color: 'rgba(0, 18, 43, 0.6)',
    position: 'absolute',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    right: 0,
    top: '50%',
    backgroundColor: '#fff',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent',
    },
  }),
};
