function sortArrayByPattern(array, pattern) {
  return array.sort(function (a, b) {
    if (pattern.indexOf(a) === pattern.indexOf(b)) {
      return 0;
    } else {
      return pattern.indexOf(a) > pattern.indexOf(b) ? 1 : -1;
    }
  });
}

export default sortArrayByPattern;
