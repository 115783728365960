const AgencyLoggedOut = () => {
  const features = [
    'The UK’s most comprehensive political monitoring service',
    'Early access to news, shared prior to publication when permitted',
    'Fully-integrated CRM to manage and track stakeholder engagement',
    'Map stakeholders by influence, interest and sentiment for any client issue',
    'Data analysis platform to drill into and share political data on any issue',
    'Create customised PDF reports for clients with live political data',
    'Campaigning tools including lobbying platform and embedded news feeds',
    'AI assistant to create lobbying material, summarise updates and more',
  ];
  return (
    <div className='box-shadow p-4 p-md-5 mt-5 rounded mx-auto' style={{ maxWidth: '820px' }}>
      <div className='d-md-flex align-items-lg-center justify-content-between text-left'>
        <h3 className='mb-0 title-h3-bold pt-3 pt-lg-0'>PolicyMogul for agencies and consultants</h3>
        <button
          className={`general-button outline-active-button px-4 py-2 mt-3 mt-md-0`}
          onClick={() => {
            window.open('https://calendar.app.google/XfyRwDQ1m1BjLKB47', '_blank');
          }}
        >
          {'Book a demo'}
        </button>
      </div>
      <ul className='policy-list text-left title-h5-m mt-4 px-0 mb-0'>
        {features.map((item, index) => {
          return (
            <li
              className={`${index === features?.length - 1 ? '' : 'mb-3'} py-1 text-main-blue d-flex`}
              key={`list-item-${index}`}
            >
              <span className='inline-block title-h2 mr-3 icon-small-check agency-small-check' />
              <span>{item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AgencyLoggedOut;
