import { useContext } from 'react';
import { store } from '../../Store';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import getUrlParam from '../utilities/getUrlParam';
import useCallMentionsByTopic, { createKey, graphDataSections, TweetsFilters } from './useCallMentionsByTopic';
import { RegionMetaData } from './ui/MentionsByTopic/PartyMetadata';
import { chartDataOptions } from './createAnalyticsState';
import ChangeKeywordsCategory from '../utilities/changeKeywordsCategory';
import ChangeKeywordsExclusions from '../utilities/changeKeywordExclusions';
import { sortPositionLabels } from './sortPositionLabels';
import useSelectedCountriesFilters from './useSelectedCountriesFilters';
import useAnalyticsIntervalLimit from './useAnalyticsIntervalLimit';
import { useAuth0 } from '@auth0/auth0-react';
import { useChartDataOptionSelected } from './ui/MentionsByTopic/ChartDataTable';
import ChangeSearchQuery from '../utilities/changeSearchQuery';

const compilePositiveAndNegative = (array) => {
  let dateValues = {};
  array?.forEach((item) => {
    let { bucket, datapoints } = item ?? {};
    datapoints?.forEach((data) => {
      let { date, value } = data;
      if (dateValues[date]) {
        dateValues[date] = [...dateValues[date], { [bucket]: value }];
      } else {
        dateValues[date] = [{ [bucket]: value }];
      }
    });
  });
  let compiledResults = {};
  for (let i in dateValues) {
    let values = dateValues[i];
    let number = 0;
    values?.forEach((value) => {
      if (value.positive) {
        number += value.positive;
      }
      if (value.negative) {
        number -= value.negative;
      }
    });
    compiledResults[i] = number;
  }
  return compiledResults;
};

const compileGeneralResults = (finalResults, rawData) => {
  let finalValues = {};
  Object.keys(rawData).forEach((item) => {
    let content = rawData[item];
    if (content) {
      Object.keys(content).forEach((value) => {
        if (!finalValues[value]) {
          finalValues[value] = {};
        }
        finalValues[value][item] = content[value];
      });
    }
  });
  let totalSeries = finalResults.flat();
  let compiledResults = compilePositiveAndNegative(totalSeries);
  let labels = Object.keys(compiledResults).map((item) => item);
  const createValues = () => {
    let values = [];
    for (let i in compiledResults) {
      let item = compiledResults[i];
      values.push(item);
    }
    return values;
  };

  return {
    labels: labels,
    values: createValues(),
    rawData: finalValues,
  };
};

const totalValues = (ob) => {
  let values = [];
  if (ob) {
    Object.keys(ob).forEach((item) => {
      let group = ob[item];
      group.forEach((datepoint, index) => {
        if (!values[index]) {
          values[index] = 0;
        }
        values[index] += datepoint.value;
      });
    });
  }
  return values;
};

const createEmptyMessageCondition = (rawData) => {
  let positiveValues = totalValues(rawData['positive']);
  let negativeValues = totalValues(rawData['negative']);
  return positiveValues?.length === 0 && negativeValues?.length === 0;
};

const useCallSentimentOverTime = (props) => {
  const { isAuthenticated } = useAuth0();
  const { teamId, selectedCountriesProps } = props ?? {};
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, analyticsState } = state;

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { currentView, activeDateInterval } = analyticsState;
  const { chartOptionName } = useChartDataOptionSelected();

  const { generalApiCall } = useGeneralApiCall();
  const { transformInterval, createFilters } = useCallMentionsByTopic(props);
  const { validateDataForLoggedOutUsers, conditionOfPublicApi } = useAnalyticsIntervalLimit();

  const topicParameter = getUrlParam('topic-id');
  const parliamentaryPositionsFilters = chartDataOptions.find(
    (item) => item.name === 'Parliamentary position'
  )?.filters;

  const { getCountrySpecificFilters, UKSelected, WalesSelected } = useSelectedCountriesFilters({
    selectedCountriesProps,
  });

  let filterResults = (results) => {
    return {
      ...results,
      series: results?.series?.map((item) => {
        return {
          ...item,
          datapoints: item.datapoints.filter((datapoint) => {
            if (activeDateInterval.name === 'Custom') {
              return (
                new Date(datapoint.date) >= new Date(activeDateInterval.filter[0]) &&
                new Date(datapoint.date) <= new Date(activeDateInterval.filter[1])
              );
            } else {
              return true;
            }
          }),
        };
      }),
    };
  };

  const individualApiCall = async ({ topic, view, dateInterval, section, getOnlyDates, source, freeSearch }) => {
    let { value, field, type, additionalFilters } = section ?? {};
    let pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}date-histogram${!!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''}`;
    let method = 'post';
    let filters = [...createFilters(dateInterval, view)];
    if (!!value) {
      filters = [...parliamentaryPositionsFilters, ...filters, ...getCountrySpecificFilters({ value, field })];
    }

    if (additionalFilters) {
      filters = [...filters, ...additionalFilters];
    }

    let requestProperties = {
      type: type ?? 'ParliamentaryRecord',
      interval: transformInterval(view),
      filters,
      order: 'asc',
    };

    if (!getOnlyDates) {
      requestProperties = { ...requestProperties, bucketField: 'sentiment' };
    }

    if (topic?.prospective) {
      const { keywords, andKeywords, keywordExclusions } = topic;
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(keywords),
        andQueries: ChangeKeywordsExclusions(andKeywords),
        mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
      };
    } else if (freeSearch) {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(freeSearch),
      };
    } else {
      requestProperties = {
        ...requestProperties,
        keywordListId: topic?.id,
      };
    }

    if (teamId) {
      requestProperties = {
        ...requestProperties,
        teamId,
      };
    }

    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: !teamId || (freeSearch && isAuthenticated),
      notShowErrorMessage: true,
      requestSource: source,
      returnError: true,
    });
    if (!validateDataForLoggedOutUsers(results)) {
      return [];
    }
    if (!!results) {
      return filterResults(results);
    }
  };

  const sentiments = ['positive', 'negative', 'neutral', 'mixed'];
  let types = [
    {
      type: 'ParliamentaryRecord',
      contentTypes: ['ParliamentaryContribution', 'QuestionAndAnswer', 'Edm', 'SeneddCommitteeTranscriptContribution'],
    },
  ];

  if (process.env.REACT_APP_ENABLE_TWITTER === 'true') {
    types.push({ type: 'Tweet', additionalFilters: TweetsFilters });
  }

  const dataTableIndividualApiCalls = async ({
    topic,
    item,
    optionData,
    sentiment,
    dateInterval,
    view,
    getOnlyDates,
    source,
    freeSearch,
  }) => {
    const { type, contentTypes, additionalFilters } = item ?? {};
    let pathname = `/api/${teamId ? 'widget' : 'graph-data'}/${freeSearch && conditionOfPublicApi ? 'public-' : ''}date-histogram${!!topic && !topic?.prospective && !freeSearch ? '-by-keyword-list' : ''}`;
    let method = 'post';
    let filters = [...parliamentaryPositionsFilters, ...createFilters(dateInterval, view)];

    if (!getOnlyDates) {
      filters.push({
        field: 'sentiment',
        value: sentiment,
        operator: 'str_eq',
      });
    }

    if (contentTypes) {
      contentTypes.forEach((item) => {
        filters = [...filters, ...getCountrySpecificFilters({ value: item, field: 'contentType' })];
      });
    }

    if (optionData?.filters) {
      filters = [...filters, ...optionData.filters];
    }

    if (additionalFilters) {
      filters = [...filters, ...additionalFilters];
    }

    let requestProperties = {
      type: type ?? 'ParliamentaryRecord',
      interval: transformInterval(view),
      filters,
      order: 'asc',
    };

    if (!getOnlyDates) {
      requestProperties = {
        ...requestProperties,
        bucketField: optionData.bucketField,
      };
    }

    if (topic?.prospective) {
      const { keywords, andKeywords, keywordExclusions } = topic;
      requestProperties = {
        ...requestProperties,
        query: ChangeKeywordsCategory(keywords),
        andQueries: ChangeKeywordsExclusions(andKeywords),
        mustNotQueries: ChangeKeywordsExclusions(keywordExclusions),
      };
    } else if (freeSearch) {
      requestProperties = {
        ...requestProperties,
        query: ChangeSearchQuery(freeSearch),
      };
    } else {
      requestProperties = {
        ...requestProperties,
        keywordListId: topic?.id,
      };
    }

    if (teamId) {
      requestProperties = {
        ...requestProperties,
        teamId,
      };
    }

    let results = await generalApiCall({
      pathname,
      method,
      requestProperties,
      needsAuthentication: !teamId || (freeSearch && isAuthenticated),
      notShowErrorMessage: true,
      requestSource: source,
      returnError: true,
    });
    if (!validateDataForLoggedOutUsers(results)) {
      return [];
    }
    if (!!results) {
      return filterResults(results);
    }
  };

  const callOverallDataChart = async ({ chartDataOption, topic, dateInterval, view, source, freeSearch }) => {
    let optionData = chartDataOptions.find((item) => item.name === chartDataOption);
    let finalResults = {};
    let initialResults = {};

    let datesValuesCall = await dataTableIndividualApiCalls({
      topic,
      optionData,
      dateInterval,
      view,
      getOnlyDates: true,
      source,
      freeSearch,
    });
    let datesValues = datesValuesCall?.series?.[0]?.datapoints ?? [];
    for (let i = 0; i < types.length; i++) {
      let item = types[i];
      for (let a = 0; a < sentiments.length; a++) {
        let sentiment = sentiments[a];
        let results = await dataTableIndividualApiCalls({
          topic,
          optionData,
          item,
          sentiment,
          dateInterval,
          view,
          freeSearch,
          source,
        });
        if (results) {
          if (!finalResults[sentiment]) {
            finalResults[sentiment] = [];
          }
          if (!initialResults[sentiment]) {
            initialResults[sentiment] = {};
          }
          let rawResultsSimplified = {};
          if (results.series) {
            JSON.parse(JSON.stringify(results.series)).forEach(
              (result) =>
                (rawResultsSimplified[createKey({ chartDataOption, item: result?.bucket })] = result?.datapoints)
            );
            initialResults[sentiment][item?.type] = rawResultsSimplified;
            finalResults[sentiment].push(results.series);
          }
        }
      }
    }
    let compiledKeys = {};
    let rawData = {};

    Object.keys(finalResults).forEach((item) => {
      let content = finalResults[item].flat();
      let reduceItems = content.reduce((acc, item) => {
        let key = createKey({ chartDataOption, item: item.bucket });
        let condition = optionData.showRowsFor ? optionData.showRowsFor?.includes(key) : true;

        if (!acc[key] && condition) {
          acc[key] = [];
        }

        if (condition) {
          acc[key].push(item.datapoints);
        }

        return acc;
      }, {});
      compiledKeys[item] = reduceItems;
    });

    Object.keys(compiledKeys).forEach((item) => {
      let content = compiledKeys[item];
      let compiledItems = {};

      let initialKeys = Object.keys(content);
      let orderingKeys = initialKeys;
      if (chartDataOption === 'Region') {
        orderingKeys = [];
        RegionMetaData.orderingRegions.forEach((item) => {
          if (initialKeys.includes(item)) {
            orderingKeys.push(item);
          }
        });
      }

      orderingKeys.forEach((group) => {
        let itemsToCompiles = content[group].flat();
        let compiledData = itemsToCompiles.reduce((acc, item) => {
          let dateIndex = acc.findIndex((point) => point.date === item.date);
          if (dateIndex >= 0) {
            let { value } = acc[dateIndex];
            let newValue = item.value + value;
            acc[dateIndex].value = newValue;
          } else {
            acc.push(item);
          }
          return acc;
        }, []);
        compiledItems[group] = compiledData;
      });
      const sortedCompiledItems = sortPositionLabels(Object.keys(compiledItems)).reduce((accumulator, key) => {
        accumulator[key] = compiledItems[key];
        return accumulator;
      }, {});
      rawData[item] = sortedCompiledItems;
    });

    let positiveValues = totalValues(rawData['positive']);
    let negativeValues = totalValues(rawData['negative']);
    let values = datesValues.map((date, index) => {
      let item = positiveValues[index] ?? 0;
      let negativeValue = negativeValues[index] ?? 0;
      return item - negativeValue;
    });
    if (!!datesValuesCall) {
      let labels = datesValues.map((item) => item.date);
      return { rawData, values, labels, initialResults };
    }
  };

  const callSentimentOverTime = async ({
    view = currentView,
    topic = topicParameter
      ? keywordsListsToUse.find((item) => item.id === parseInt(topicParameter))
      : keywordsListsToUse[0],
    dateInterval = activeDateInterval.filter,
    chartDataOption = chartOptionName,
    source,
    freeSearch,
  }) => {
    let rawData = {};
    let values;
    let labels;
    let initialResults = {};
    if (chartDataOption === 'Contribution type' || chartDataOption === 'Overall') {
      let finalResults = [];
      let graphDataSectionsFiltered = graphDataSections.filter((item) => {
        if (item.value === 'Edm') {
          return UKSelected;
        }
        if (item.value === 'SeneddCommitteeTranscriptContribution') {
          return WalesSelected;
        }
        return true;
      });
      for (let i = 0; i < graphDataSectionsFiltered.length; i++) {
        let section = graphDataSectionsFiltered[i];
        let results = await individualApiCall({
          topic,
          view,
          dateInterval,
          section,
          source,
          freeSearch,
        });
        if (results) {
          finalResults.push(results.series);
          rawData[section.name] = results.series?.reduce((acc, item) => {
            acc[item.bucket] = item.datapoints;
            return acc;
          }, {});
        }
      }
      if (Object.keys(rawData)?.length > 0) {
        const compiledItems = compileGeneralResults(finalResults, rawData);
        labels = compiledItems.labels;
        values = compiledItems.values;
        rawData = compiledItems.rawData;
      }
    } else {
      let results = await callOverallDataChart({
        topic,
        chartDataOption,
        dateInterval,
        view,
        source,
        freeSearch,
      });
      if (!!results) {
        labels = results?.labels;
        rawData = results?.rawData;
        values = results?.values;
        initialResults = results?.initialResults;
      }
    }
    if (!!values) {
      return {
        labels: labels,
        values,
        rawData: rawData,
        initialResults,
      };
    }
  };

  return { callSentimentOverTime };
};

export { compileGeneralResults, createEmptyMessageCondition };
export default useCallSentimentOverTime;
