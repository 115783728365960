import React from 'react';
const TopicDiscardPopUp = (props) => {
  const { discardFunction } = props;
  return (
    <div
      className='modal micromodal-slide delete-keywordcategory-modal'
      id={`topic-discard-popup`}
      aria-hidden='true'
      tabIndex='-1'
    >
      <div className='modal__overlay' tabIndex='-1' data-micromodal-close>
        <div className='modal__container py-4' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <div className='preregister-close px-2' data-micromodal-close>
            <img
              src={`${process.env.REACT_APP_CDNURL}/images/preregister-close.svg`}
              alt='close-icon'
              data-micromodal-close
            />
          </div>

          <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
            <div className='text-center'>
              <p>Do you want to discard the changes you've made?</p>

              <div className='my-4'>
                <button className='general-button delete-button px-4 mr-3 py-1' data-micromodal-close>
                  Cancel
                </button>
                <button
                  className='btn btn-general action-button px-4 py-1'
                  onClick={async () => {
                    discardFunction();
                  }}
                >
                  {'Discard changes'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicDiscardPopUp;
