const changeItemInsideArray = (array, from, to) => {
  if (to === from) return array;

  let target = array[from];
  let increment = to < from ? -1 : 1;

  for (let k = from; k !== to; k += increment) {
    array[k] = array[k + increment];
  }
  array[to] = target;
  return array;
};

export default changeItemInsideArray;
