import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SkeletonRelatedContent from '../components/SkeletonRelatedContent';

const SkeletonKeyUpdatesContent = () => {
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div className='h-100 row mx-0 piece-content-container skeleton-update-container'>
        <div className={`general-content-column padding-to-nav  h-100 main-content-section content-container`}>
          <div className='p-4 pb-5'>
            <div className='section-title pb-2 mb-2'>
              <div className='d-flex align-items-lg-center justify-content-between'>
                <div className='pr-lg-5 pr-3 content-new-title'>
                  <h3 className='main-title news-title pr-lg-4'>
                    <Skeleton height={24} width={`100%`} />
                    <div className='row justify-content-between mx-0 mt-2'>
                      <Skeleton height={19} width={200} />
                      <div className='mt-2 mt-lg-0'>
                        <Skeleton height={19} width={50} />
                        <span className='mx-1'>
                          <Skeleton width={20} height={20} circle={true} />
                        </span>
                        <Skeleton width={20} height={20} circle={true} />
                      </div>
                    </div>
                  </h3>
                </div>
                <Skeleton height={85} width={`114px`} />
              </div>
            </div>
            <div className='pt-2 pb-3 context mb-2'>
              {Array(9)
                .fill()
                .map((item, index) => (
                  <div key={index} className='mb-3'>
                    <Skeleton count={4} height={19} width={`100%`} />
                    <Skeleton height={19} width={`45%`} />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className='related-content-column show-lg px-0 h-100'>
          <SkeletonRelatedContent />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonKeyUpdatesContent;
