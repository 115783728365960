import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { store } from '../../../../Store';
import useIsMobile from '../../../utilities/useIsMobile';
import CrmContactsListBar from '../../CrmContacts/CrmContactsTable/CrmContactsListBar/CrmContactsListBar';

function CrmContactsSubNav() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { crmContactsTotalHits } = state;
  const [isLoading, setIsLoading] = useState(false);
  const isSmallMobile = useIsMobile(820);

  const setSelectedCrmContacts = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedCrmContacts',
      value,
    });
  };
  const resetSelectedContacts = () => {
    setSelectedCrmContacts({
      selectedAll: false,
      selectedIds: [],
      selectedAllVisible: [],
    });
  };
  const setShowAddTagsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddTagsModalCrmContacts',
      value,
    });
  };
  const setShowAddNoteModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showAddNoteModalCrmContacts',
      value,
    });
  };
  const setShowRemoveContactsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactsModal',
      value,
    });
  };

  const setShowRemoveContactTagsModal = (value) => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'showRemoveContactTagsModal',
      value,
    });
  };

  return (
    <>
      <CrmContactsListBar
        setSelectedCrmContacts={setSelectedCrmContacts}
        totalHits={crmContactsTotalHits}
        setIsLoading={setIsLoading}
        setShowAddTagsModal={setShowAddTagsModal}
        isLoading={isLoading}
        resetSelectedContacts={resetSelectedContacts}
        setShowAddNoteModal={setShowAddNoteModal}
        setShowRemoveContactsModal={setShowRemoveContactsModal}
        setShowRemoveContactTagsModal={setShowRemoveContactTagsModal}
      />
      {!isSmallMobile && (
        <button onClick={resetSelectedContacts} className='stakeholder-subnav-deselect-btn ml-2 text-red'>
          Deselect all
        </button>
      )}
    </>
  );
}

export default CrmContactsSubNav;
