export const loadMoreTableRowsDependOnScreen = ({
  containerHeight,
  cellHeight,
  headerHeight,
  setPageSize,
  pageSize,
}) => {
  if (containerHeight > cellHeight * pageSize + headerHeight) {
    if (Math.round((containerHeight - (cellHeight + headerHeight)) / cellHeight) + 1 > pageSize) {
      setPageSize(Math.round((containerHeight - (cellHeight + headerHeight)) / cellHeight) + 1);
    }
  }
};
