import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom-v5-compat';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../Store.js';
import createUtmParameters from '../helpers/createUtmParameters.js';

const SplitBanner = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, search } = state;
  const { activeSearch, list } = search;
  const { enableStakeholders } = activePlan;
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    if (Object.keys(activePlan).length > 0) {
      setIsLoading(false);
    }
  }, [activePlan]);
  const { isAuthenticated } = useAuth0();
  const StakeholderBannerConditional =
    isAuthenticated &&
    !isLoading &&
    !enableStakeholders & (params.id !== undefined) &&
    location.pathname.includes('stakeholders') &&
    (!activeSearch || (activeSearch && list !== null));

  const bannerText = () => {
    if (
      location.pathname.toLowerCase().startsWith('/briefing-material/') ||
      location.pathname.toLowerCase().startsWith('/policy-asks/') ||
      location.pathname.toLowerCase().startsWith('/research/') ||
      location.pathname.toLowerCase().startsWith('/news-release/')
    ) {
      return 'Do you want to make policy asks and briefing material available to parliamentarians?';
    } else {
      return 'Do you want tailored political updates and email alerts based on your interests?';
    }
  };

  const regSourceItem = () => {
    if (
      location.pathname.toLowerCase().startsWith('/briefing-material/') ||
      location.pathname.toLowerCase().startsWith('/policy-asks/') ||
      location.pathname.toLowerCase().startsWith('/research/') ||
      location.pathname.toLowerCase().startsWith('/news-release/')
    ) {
      return 'influence';
    } else {
      return 'monitor';
    }
  };

  const electionPage = location.pathname.includes('/general-election-2024');

  return (
    <>
      {!isAuthenticated && !activeSearch && !electionPage && (
        <Link
          to={`/register?reg_source=blue-${regSourceItem()}-banner${createUtmParameters('&')}`}
          className={`d-block py-3 header-banner header-banner-blue`}
        >
          <p className='mb-0 main-content-wrapper px-3 px-lg-5 mx-auto'>
            {bannerText()} <span>Sign up now</span>
          </p>
        </Link>
      )}
      {!!StakeholderBannerConditional && (
        <Link
          to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`}
          className={`d-block py-3 header-banner header-banner-blue`}
        >
          <p className='mb-0 main-content-wrapper px-3 px-lg-5 mx-auto'>
            Identify the most relevant MPs, peers and special advisers for each of your interests.{' '}
            <span>Compare plans</span>
          </p>
        </Link>
      )}
    </>
  );
};

export default SplitBanner;
