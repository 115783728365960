const InputError = ({ errText }) => {
  return (
    <div className='red-error-message'>
      <span className='icon-info' />
      <span className='message px-2'>{errText}</span>
    </div>
  );
};

export default InputError;
