import React, { useEffect, useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import getActivePlan from './Settings/Utilities/getActivePlan';
import { store } from './Store.js';
import axios from 'axios';
import getUtmParameters from './Dashboard/helpers/getUtmParameters';
import getContactsCount from './Settings/Utilities/getContactCount';
import { deleteURLParams } from './Login/LoginSuccess';
import { useLocation } from 'react-router-dom-v5-compat';
import useGetAccessToken from './Dashboard/apiCalls/useGetAccessToken.js';

const InitialValidations = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { trialBannerHeight } = state;
  const location = useLocation();
  const { isAuthenticated, user, logout } = useAuth0();
  const { getAccessToken } = useGetAccessToken();

  const call = useRef();
  call.current = (source) => {
    if (isAuthenticated) {
      deleteURLParams();
    } else {
      getUtmParameters(location);
    }
    validationCall(source);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    call.current(source);
    return () => {
      source.cancel('Keywords canceled by the user.');
    };
  }, []);

  const logoutWithRedirect = () => logout({ logoutParams: { returnTo: window.location.origin } });

  const validationCall = async (source) => {
    try {
      if (isAuthenticated) {
        let activePlan =
          Object.keys(state.activePlan).length === 0 ? await getActivePlan(getAccessToken, source) : state.activePlan;
        if (!!activePlan) {
          let crmContactsQtty = await getContactsCount(getAccessToken, source);

          const { subscriptionStatus, userOnboarded, blocked, totalCrmContacts } = activePlan ?? {};

          if (blocked) {
            return logoutWithRedirect();
          }
          const excludePathsConditional =
            location.pathname !== '/create-your-profile/3' && !location.pathname.includes('/settings');
          //CHECK IF THE USER IS OVER LIMITS
          if (excludePathsConditional) {
            if (subscriptionStatus === 'OnFreePlanOverLimit' || subscriptionStatus === 'SubscribedOverLimit') {
              return window.location.assign('/settings/check-plan');
            }
            if (subscriptionStatus === 'TrialEnded') {
              return window.location.assign('/end-of-trial');
            }
            if (subscriptionStatus === 'SubscriptionEnded') {
              return window.location.assign('/end-of-subscription');
            }
          }
          //CHECK IF THE USER HAS CREATED THE FIRST LIST
          if (!userOnboarded && excludePathsConditional) {
            let urlToRedirect = `/create-your-profile/1${location.search}`;
            return window.location.assign(urlToRedirect);
          } else {
            if (!user.email_verified && excludePathsConditional) {
              return window.location.assign(`/needs-verification${location.search}`);
            }
          }

          if (trialBannerHeight !== 0 && subscriptionStatus !== 'InTrial' && subscriptionStatus !== 'TrialEnded') {
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'trialBannerHeight',
              value: 0,
            });
          }
          if (activePlan) {
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'activePlan',
              value: activePlan,
            });
          }
          if (crmContactsQtty) {
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'crmContactsQtty',
              value: crmContactsQtty,
            });
          }
          dispatch({
            type: 'MODIFY_SECTION',
            parameter: 'contactsOversubscribed',
            value: totalCrmContacts - crmContactsQtty,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return <></>;
};
export default InitialValidations;
