import { useLocation, useParams, useNavigate } from 'react-router-dom-v5-compat';
import ReportBoardComponent from './ui/ReportBoardComponent';
import { useContext, useEffect, useRef, useState } from 'react';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import { store } from '../Store';
import { useCreateReportsWidgets } from './ui/useCreateReportsWidgets';
import dayjs from 'dayjs';
import addMetaTags from '../Dashboard/utilities/addMetaTags';
import axios from 'axios';
import getUrlParam from '../Dashboard/utilities/getUrlParam';

const ReportComponent = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, updateToCreateReport } = state;

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const topicId = location?.state?.topicId;
  const topicParamenter = getUrlParam('topic-id');

  const { id } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [macrosList, setMacrosList] = useState([]);
  const [agencyClientLogoUrl, setAgencyClientLogoUrl] = useState(null);

  const { generalApiCall } = useGeneralApiCall();
  const { agencyUser } = useTopicOrClientWord();
  const { topic, modifyGraphDates } = useCreateReportsWidgets({ reportData });

  const getReportItem = async ({ source }) => {
    try {
      const pathname = `/api/report-definition/${id}`;
      const method = 'get';
      return await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
        returnCompleteRequest: true,
      });
    } catch (error) {}
  };

  const getTeamMacros = async ({ source }) => {
    try {
      const pathname = `/api/report-macro/team-report-macros`;
      const method = 'get';
      let results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
      });
      if (results) {
        return results.sort((a, b) => {
          return new Date(b.lastEditedAt) - new Date(a.lastEditedAt);
        });
      }
    } catch (error) {}
  };

  const getCompanyLogoUrl = async ({ name }) => {
    try {
      const pathname = `/api/client/client-domain-url?companyName=${name}`;
      const method = 'get';
      return await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        returnCompleteRequest: true,
        notShowErrorMessage: true,
      });
    } catch (error) {}
  };

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      setIsLoading(true);
      let title = 'New report';
      if (topicParamenter && !keywordsLists.find((item) => item?.id === parseInt(topicParamenter))) {
        const params = new URLSearchParams(location.search);
        params?.delete('topic-id');
        navigate({ pathname: '/reports', search: params.toString() }, { replace: true });
      }
      if (!!id) {
        let reportCall = await getReportItem({ source });
        if (!!reportCall) {
          if (reportCall?.status === 200) {
            let report = reportCall?.data;
            let json = JSON.parse(report?.json);
            let dateToReference = json?.updateTo ?? report?.lastEditedAt;
            let layout = Array.isArray(json) ? json : json?.layout;
            let design = Array.isArray(json) ? null : json?.design ?? null;
            let defaultProperty = Array.isArray(json) ? null : json?.defaultProperty ?? null;

            layout = layout.map((item) => {
              let newItem = { ...item, saved: true };
              newItem = modifyGraphDates({ item: newItem, dateToReference });
              return newItem;
            });
            json = {
              updateTo: dateToReference,
              layout,
              design,
              defaultProperty,
            };
            let finalReport = {
              ...report,
              updateTo: dateToReference,
              json: JSON.stringify(json),
            };
            title = report?.title;
            setReportData(finalReport);
          } else if (reportCall?.status === 404) {
            navigate({ pathname: '/reports' }, { replace: true });
          }
        }
      } else {
        if (updateToCreateReport) {
          const { billTitle, subject, title } = updateToCreateReport ?? {};
          const updateTitle = billTitle ?? subject ?? title;
          let json = {
            updateTo: dayjs().format('YYYY-MM-DD'),
            layout: [
              {
                id: `report-from-update-${updateToCreateReport?.id}`,
                definition: { minRowSpan: 4, minColumnSpan: 2, defaultRowSpan: 4, defaultColumnSpan: 4 },
                columnSpan: 4,
                columnOffset: { 4: 0 },
                data: {
                  name: updateTitle,
                  icon: 'briefing-material',
                  component: 'SpecificUpdate',
                  state: { specificUpdate: { initialItem: updateToCreateReport }, commentary: null, topic: topic },
                },
              },
            ],
          };
          let report = {
            json: JSON.stringify(json),
            title: updateTitle,
          };
          setReportData(report);
          setIsLoading(false);
        }
      }
      let macros = await getTeamMacros({ source });
      if (!!macros) {
        setMacrosList(
          macros.map((item) => {
            return {
              id: `macro-${item?.id}`,
              definition: { minColumnSpan: 2, minRowSpan: 2, defaultRowSpan: 2, defaultColumnSpan: 2 },
              data: {
                ...item,
                component: 'Commentary',
                state: { widgetName: item?.name, value: item?.content, type: 'macro' },
              },
            };
          })
        );
        if (agencyUser && topicId) {
          const client = keywordsLists.find((item) => item.id === parseInt(topicId));
          const logoUrl = await getCompanyLogoUrl({ name: client?.name });
          if (logoUrl?.status === 200) {
            setAgencyClientLogoUrl(logoUrl.data);
          }
        }
        addMetaTags({ title: `${title} - Reports - PolicyMogul`, hash: location.hash, location, dispatch });
        setIsLoading(false);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialCall.current(source);
    return () => {
      source.cancel('Report cancelled by the user');
    };
  }, []);

  return (
    <>
      <ReportBoardComponent
        isLoading={isLoading}
        reportData={reportData}
        setReportData={setReportData}
        macrosState={{ macrosList, setMacrosList }}
        agencyClientLogoUrl={agencyClientLogoUrl}
      />
    </>
  );
};

export default ReportComponent;
