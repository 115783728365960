import axios from 'axios';
import { useEffect, useRef } from 'react';
import createNotification from '../../Settings/Utilities/CreateNotification.js';
import { net_api_url } from '../../Store';
import { useAuth0 } from '@auth0/auth0-react';
import useGetAccessToken from './useGetAccessToken.js';
const JSONBigInt = require('json-bigint');

const useGeneralApiCall = () => {
  const { isAuthenticated } = useAuth0();

  const cancelSource = useRef();
  const { getAccessToken } = useGetAccessToken();

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    cancelSource.current = source;
    return () => {
      source.cancel('General api call cancelled by the user');
    };
  }, []);

  const generalApiCall = async (props) => {
    const {
      method,
      pathname,
      requestProperties,
      notShowErrorMessage,
      returnCompleteRequest,
      needsAuthentication,
      requestSource,
      requestHeaders: propsRequestHeaders,
      bigInt,
      returnError,
      returnCancelled,
    } = props;
    try {
      let token = isAuthenticated ? await getAccessToken() : '';
      let localRequestHeaders = {
        cancelToken: requestSource?.token ?? cancelSource?.current?.token,
      };
      if (needsAuthentication) {
        localRequestHeaders = {
          ...localRequestHeaders,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
      if (bigInt) {
        localRequestHeaders = {
          ...localRequestHeaders,
          transformResponse: [(data) => data],
        };
      }
      let requestHeaders = propsRequestHeaders ?? localRequestHeaders;
      let request = await axios[method](
        `${net_api_url}${pathname}`,
        method === 'post' ? requestProperties : requestHeaders,
        method === 'post' ? requestHeaders : ''
      );
      return returnCompleteRequest ? request : bigInt ? JSONBigInt.parse(request.data) : request.data;
    } catch (error) {
      console.error(error);

      if (error?.response && returnError) {
        return error.response;
      } else if (!error?.response && returnCancelled) {
        return error;
      }
      let message = `An error ocurred.`;
      if (typeof error?.response?.data === 'string' && !!error?.response?.data) {
        message = error?.response?.data;
      }
      if (!!error?.response?.data?.message) {
        message = error.response.data.message;
      }
      if (!notShowErrorMessage) {
        createNotification('danger', message);
      }
    }
  };
  return { generalApiCall };
};

export default useGeneralApiCall;
