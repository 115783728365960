import changeHour from './changeHour';

const createFrequencyName = (frequency, dayOfWeek, timeOfDay, secondTimeOfDay) => {
  return frequency === 'Immediate'
    ? 'Immediate'
    : frequency === 'Weekly'
      ? `${dayOfWeek}s at ${changeHour(timeOfDay)}`
      : frequency === 'TwiceWeekdaily'
        ? `twice daily on weekdays at ${changeHour(timeOfDay)} and ${changeHour(secondTimeOfDay)}`
        : `weekdays at ${changeHour(timeOfDay)}`;
};

export default createFrequencyName;
