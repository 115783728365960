import React, { useEffect, useRef, useState } from 'react';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import InputError from '../../Dashboard/components/InputError';
import createNotification from '../../Settings/Utilities/CreateNotification';
import useAdminNotesFunctions from '../../Dashboard/hooks/useAdminNotesFunctions';
import CustomScrollbar from '../../Common/CustomScrollbar';
import { useParams } from 'react-router-dom-v5-compat';

const AdminNote = (props) => {
  const { team, setTeam, getCurrentTopic, clientPage } = props;
  const topicInformation = useRef();
  topicInformation.current = getCurrentTopic ? getCurrentTopic() : {};
  const [heightContainer, containerRef] = useHeightContainer();
  const [initialAdminNote, setInitialAdminNote] = useState(null);
  const [adminNote, setAdminNote] = useState(null);
  const [inputError, setInputError] = useState(null);

  const params = useParams();
  const { id } = params;

  const [loadingAddNote, setLoadingAddNote] = useState(false);

  const textAreaRef = useRef();
  const { addAdminNote } = useAdminNotesFunctions();

  const getInitialDescription = useRef();
  getInitialDescription.current = async () => {
    if (textAreaRef?.current) {
      setAdminNote(team?.adminNotes ?? '');
      textAreaRef?.current.focus();
    }
    setInitialAdminNote(team?.adminNotes);
  };

  useEffect(() => {
    getInitialDescription.current();
    setInputError(null);
  }, [team]);

  const handleClick = async () => {
    let text = adminNote;
    setLoadingAddNote(true);
    let response = await addAdminNote({
      adminNotes: text,
      organisationId: id,
    });
    setLoadingAddNote(false);
    if (response) {
      createNotification('success', `Note ${!!initialAdminNote ? 'updated' : 'saved'}`);
      setTeam({ ...team, adminNotes: text });
    }
  };

  const adminNoteIsChanged = adminNote !== initialAdminNote && !(adminNote === '' && initialAdminNote === null);

  return (
    <div className={`${clientPage ? 'pt-4 pt-lg-5' : ''}`}>
      <h3 className='my-lg-0 main-title mb-0 mr-3 py-5 '>Notes</h3>
      <div className='widget-padding'>
        <div
          className='box-top-rounded bg-white admin-general-content pt-5 p-4'
          ref={containerRef}
          style={{ height: `${heightContainer}px` }}
        >
          <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
            <div className='container widget-padding'>
              <div className='position-relative pt-2 p-0'>
                <textarea
                  ref={textAreaRef}
                  value={adminNote}
                  rows={8}
                  className='input-empty w-100 border border-grey rounded-sm p-3'
                  style={{ resize: 'none' }}
                  onChange={(e) => {
                    setAdminNote(e.target.value);
                    setInputError(null);
                  }}
                ></textarea>
              </div>
              <div
                className={`tailor-summary-action-container flex-centered justify-content-${
                  inputError ? 'between' : 'end'
                }  mt-2 p-0`}
              >
                {inputError && <InputError errText={inputError} />}

                <div className='flex-centered'>
                  <button
                    className={`action-button general-button px-3 filter-action-button update-button`}
                    onClick={() => {
                      handleClick('show');
                    }}
                    disabled={loadingAddNote || !adminNoteIsChanged}
                  >
                    {loadingAddNote && <i className='fas fa-spinner fa-spin mx-2' />}
                    <span>{!!initialAdminNote ? 'Update' : 'Save'} note </span>
                  </button>
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </div>
      </div>
    </div>
  );
};

export default AdminNote;
