import React, { useState, useEffect, useRef } from 'react';

const TextForCell = React.forwardRef(({ text, type }, ref) => {
  const [containerWidth, setContainerWidth] = useState(null);
  const [addElipsis, setAddElipsis] = useState(false);

  const containerRef = useRef();

  useEffect(() => {
    if (ref?.current && containerWidth) {
      const padding = type === 'title' ? 86 : 44;
      setAddElipsis(ref.current.offsetWidth < containerWidth + padding);
    }
  });

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.scrollWidth);
    }
  }, [containerRef, ref?.current?.offsetWidth]);
  if (text) {
    const textForTooltip = typeof text === 'object' ? text.props?.children?.[0]?.props?.children : text;
    return (
      <div
        ref={containerRef}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        className={`${type === 'title' ? 'crm-table-item-title' : ''}`}
        data-tooltip-content={addElipsis ? textForTooltip : undefined}
        data-tooltip-id={addElipsis ? 'general-tooltip' : undefined}
        data-tooltip-class-name={
          addElipsis ? 'header-tooltip pricing-tooltip-description table-item-cell-tooltip' : undefined
        }
      >
        <span>{text}</span>
      </div>
    );
  } else {
    return null;
  }
});

export default TextForCell;
