import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import { useContext, useRef } from 'react';
import { useEffect } from 'react';
import { store } from '../../Store';
import normalize from '../utilities/normalizeString';
import useGeneralApiCall from '../apiCalls/useGeneralApiCall';
import { useLocation } from 'react-router-dom-v5-compat';

const useGetContactsTags = (props) => {
  const { notCallInitially = false } = props ?? {};
  const { isAuthenticated } = useAuth0();

  const location = useLocation();

  const globalState = useContext(store);
  const { dispatch } = globalState;

  let hash = window.location.hash;
  let hashParts = hash !== '' ? hash.split('#')[1].split('~') : [];

  const modifyContactsTags = (results) => {
    if (location.pathname.includes('/influence/contacts')) {
      //GET FILTERS FROM HASH
      let resultToStore = results;
      hashParts.forEach((hash) => {
        resultToStore.forEach((item) => {
          if (hash === 'tag-is-not-' + normalize(item.value)) {
            item.selected = 'exclude';
          } else if (hash === normalize(item.value)) {
            item.selected = true;
          }
        });
      });
      return resultToStore;
    } else {
      return results;
    }
  };

  const { generalApiCall } = useGeneralApiCall();
  const callTags = useRef();

  callTags.current = async (source) => {
    const pathname = `/api/autocomplete/crm-contact-tag`;
    const method = 'get';
    const results = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
      requestSource: source,
      notShowErrorMessage: true,
    });
    if (results) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'contactsTags',
        value: modifyContactsTags(results),
      });
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    try {
      if (!notCallInitially) {
        const asyncFn = async () => {
          if (isAuthenticated) {
            callTags.current(source);
          }
        };
        asyncFn();
      }
    } catch (e) {}

    return () => {
      source.cancel('Contact tag canceled by the user.');
    };
  }, [isAuthenticated]);

  return { upadateContactTags: callTags.current };
};

export default useGetContactsTags;
