import React, { useContext } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';
import { store } from '../../Store';
import { useLocation } from 'react-router-dom';

const NoResultsTeam = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team, activePlan } = state;

  const { totalResults, results, activeFilters, influenceSection, text, engagementStatistics } = props;

  const [heightContainer, containerRef] = useHeightContainer();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const isMobile = useIsMobile(992);
  const location = useLocation();

  return (
    <>
      <div
        className={`${
          influenceSection ? 'influence-box settings-box-all-screen p-4' : ''
        } settings-box d-lg-flex justify-content-lg-center align-items-lg-center`}
        ref={containerRef}
        style={{
          height: `${heightContainer}px`,
        }}
      >
        <div className={`text-center message-content`}>
          {totalResults.length === 0 && results.length === 0 && (
            <div style={{ marginTop: isMobile ? '150px' : '-50px' }} className='text-center'>
              <p
                className='light-50-text title-h5-m mb-5'
                style={{
                  maxWidth: influenceSection
                    ? engagementStatistics
                      ? '460px'
                      : `${newAgencyPlan ? '720' : '600'}px`
                    : undefined,
                  margin: influenceSection ? '0 auto' : undefined,
                }}
              >
                {text ??
                  (newAgencyPlan
                    ? `Lobbying material - including policy asks, briefing material, and news releases - is shared with relevant policymakers. Content is published as if from the client, not ${activePlan?.organisationName}. You will be asked to confirm that  ${team?.teamName} has given authority to publish content on their behalf`
                    : agencyUser
                      ? 'Lobbying material you draft or publish on behalf of clients will appear here'
                      : influenceSection
                        ? 'Policy asks, briefing material and news releases are shared with organisations and parliamentarians based on their interests'
                        : 'Your draft and published lobbying material will appear here')}
              </p>
              <Link
                to='/influence/add-content?step=1'
                className='action-button general-button rounded-button px-5 py-2'
              >
                Add lobbying material{' '}
                {newAgencyPlan ? <span className='d-none d-lg-inline-block'> for {team?.teamName}</span> : ''}
              </Link>
            </div>
          )}
          {results.length === 0 && totalResults.length > 0 && (
            <>
              <p className='message-text title-h5-m main-light-text mx-auto'>{`Your team has not created any ${
                activeFilters[0].name === 'Policy asks' ? 'policy asks' : 'briefing material'
              } yet`}</p>
              <Link
                to={`/influence/${
                  activeFilters[0].name === 'Policy asks' ? 'policy-asks' : 'briefing-material'
                }/create/1?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}${location.hash}`)}`}
                className='action-button roundend-action-button text-uppercase d-inline-block'
              >
                Add {activeFilters[0].name === 'Policy asks' ? 'a policy ask' : 'briefing material'}
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NoResultsTeam;
