import { createSectionName } from '../Dashboard/helpers/controlActiveFilters';
import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import React, { useContext, useState } from 'react';
import { store } from '../Store';
import CustomScrollbar from './CustomScrollbar';

const ScrollbarList = React.forwardRef((props, ref) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contentResults, activeResults } = state;
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);

  const { children, createResults, additionalClass = '' } = props;
  const [heightContainer, containerRef] = useHeightContainer();

  const handleScroll = (scrollValues) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollValues;
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      load_more_results();
    }
  };

  const load_more_results = async () => {
    const sectionName = createSectionName();
    const section = contentResults[activeResults][sectionName];

    if (section && createResults) {
      //AE: As we don't have now the unred filter, I'll not include on the create results function. If it's necessary see the hooks for each contentType
      let { thereIsMoreContent, pageNumber } = section;
      if (thereIsMoreContent && !isLoadingScroll) {
        setIsLoadingScroll(true);
        let newPage = pageNumber + 1;
        let parameter = newPage;
        await createResults(parameter);
        setIsLoadingScroll(false);
      }
    }
  };

  return (
    <div ref={containerRef}>
      <CustomScrollbar
        className={`main-content-scrollbar ${additionalClass}`}
        style={{ height: `${heightContainer}px` }}
        maximalThumbYSize={100}
        ref={ref}
        onScroll={(scrollValues) => {
          handleScroll(scrollValues);
        }}
      >
        {children}
      </CustomScrollbar>
    </div>
  );
});

export default ScrollbarList;
