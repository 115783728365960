import React from 'react';
import { useState } from 'react';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';

function EmailAddressesDropDown({
  disabledCondition = false,
  senderAddresses,
  handleChange = () => {},
  value,
  emailBody,
  sideBar,
}) {
  const [listDomainsIsOpen, setListDomainsIsOpen] = useState(false);
  const isSmallMobile = useIsMobile(480);
  return (
    <div className={`email-component-custom-select ${sideBar ? 'custom-select-side-bar' : ''}`}>
      {listDomainsIsOpen && (
        <span
          className='email-component-close-select'
          onClick={() => {
            setListDomainsIsOpen(false);
          }}
        />
      )}
      <div
        style={{ justifyContent: sideBar ? 'space-between' : undefined }}
        className={`email-component-custom-select-container ${disabledCondition ? 'opacity-6' : ''}`}
        onClick={() => {
          if (!disabledCondition) {
            setListDomainsIsOpen(!listDomainsIsOpen);
          }
        }}
      >
        <div
          id='organisation-type'
          className='email-component-custom-select-title'
          style={{
            cursor: disabledCondition ? 'default' : 'pointer',
            opacity: value || listDomainsIsOpen ? 1 : 0.6,
          }}
        >
          <span className='d-block custom-select-title-text'>
            {value
              ? emailBody
                ? isSmallMobile || sideBar
                  ? `${value.userName}@${value.crmDomainName}`
                  : value.emailAddress
                : `${value.userName}@${value.crmDomainName}`
              : 'Select email address'}
          </span>
        </div>
        {!disabledCondition && (
          <div className='domains-expand-btn-container' tabIndex={-1} data-value={disabledCondition ? 'disabled' : ''}>
            <span
              className='domains-expand-btn'
              style={listDomainsIsOpen ? { transform: 'rotate(180deg)', marginTop: '-3px' } : undefined}
              tabIndex={-1}
            />
          </div>
        )}
      </div>
      {listDomainsIsOpen && (
        <div className='open-dropdown'>
          {senderAddresses.map((item) => (
            <div
              className='open-dropdown-option filter-item-dropdown'
              key={item.id}
              value={item.id}
              onClick={() => {
                setListDomainsIsOpen(false);
                handleChange(item);
              }}
            >
              {item.emailAddress}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EmailAddressesDropDown;
