import React, { useEffect, useState } from 'react';

const ProgressBar = ({ done }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    const stylesFunc = setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
      };

      setStyle(newStyle);
    }, 200);
    return () => {
      clearTimeout(stylesFunc);
    };
  }, []);

  return (
    <div className='progress-bar'>
      <div className='progress-bar-done' style={style} />
    </div>
  );
};

export default ProgressBar;
