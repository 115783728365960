import useGeneralApiCall from '../apiCalls/useGeneralApiCall';

const useReferralLinkApiCall = () => {
  const { generalApiCall } = useGeneralApiCall();

  const getReferralLink = async () => {
    let method = 'get';
    let pathname = `/api/registration/referral-code`;
    const result = await generalApiCall({
      method,
      pathname,
      needsAuthentication: true,
    });
    return result;
  };

  const editReferralLinkEmail = async (emailAddress) => {
    let method = 'post';
    let pathname = '/api/registration/update-referral-payment-email-address';
    let requestProperties = {
      paymentEmailAddress: emailAddress,
    };
    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
    });
    return result;
  };

  return {
    getReferralLink,
    editReferralLinkEmail,
  };
};

export default useReferralLinkApiCall;
