import React, { useCallback, useState } from 'react';
import MicroModal from 'micromodal';
import dayjs from 'dayjs';
import DayPicker from 'react-day-picker';
const CalendarExpirtyDate = (props) => {
  const { setShowCalendarPicker, visibilityOptions } = props;
  const { date } = visibilityOptions;
  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      MicroModal.show('calendar-expiry-date', {
        awaitCloseAnimation: true,
      });
    }
  }, []);
  const closeMicroModal = () => {
    MicroModal.close('calendar-expiry-date');
    setTimeout(() => {
      setShowCalendarPicker(false);
    }, 300);
  };

  const [selectedDay, setSelectedDay] = useState(new Date());

  const handleDayClick = (day, modifiers = {}) => {
    setSelectedDay(day);
    date.setValue({
      name: dayjs(day).format('DD MMM[,] YYYY'),
      value: dayjs(day).utc().startOf('day').format().replace('Z', ''),
      selected: true,
    });
    closeMicroModal();
  };

  return (
    <div
      className='modal micromodal-slide modal-calendar'
      id={`calendar-expiry-date`}
      aria-hidden='true'
      tabIndex='-1'
      ref={micromodalElement}
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div className='modal__container' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>
          <div className={`calendar-picker tweets-calendar`}>
            <DayPicker
              month={new Date()}
              className='Selectable'
              selectedDays={selectedDay}
              modifiers={{}}
              onDayClick={handleDayClick}
              firstDayOfWeek={1}
              showOutsideDays
              weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarExpirtyDate;
