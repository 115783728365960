import { useEffect, useState } from 'react';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';
import { useContext } from 'react';
import { store } from '../../../Store.js';
import { useParams } from 'react-router-dom-v5-compat';
import Axios from 'axios';

function useGetEngagementStatisticsItem() {
  const params = useParams();
  let { itemId, type } = params;
  const [item, setItem] = useState(null);
  const { generalApiCall } = useGeneralApiCall();

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const getEngagementStatisticsItem = async (source) => {
    const pathname = `/api/user-generated-content/${type === 'policy-asks' ? 'policy-ask' : type}/${itemId}`;
    const method = 'get';
    const results = await generalApiCall({
      pathname,
      method,
      needsAuthentication: true,
      notShowErrorMessage: true,
      requestSource: source,
    });
    if (results) {
      setItem(results);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'engagementStatisticItem',
        value: results,
      });
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (itemId && type) {
      getEngagementStatisticsItem(source);
    }
    return () => {
      source.cancel('Engagement statistics api call canceled by the user');
    };
  }, [itemId, type]);
  return { item };
}

export default useGetEngagementStatisticsItem;
