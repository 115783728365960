import React, { useCallback } from 'react';
import MicroModal from 'micromodal';
const RemoveLogoPopup = (props) => {
  const { setShowRemoveLogoPopup, setOrganisationLogo, setIsSaved, organisationLogo } = props;
  const micromodalElement = useCallback((node) => {
    if (node !== null) {
      MicroModal.show('remove-logo-popup', {
        awaitCloseAnimation: true,
      });
    }
  }, []);
  const closeMicroModal = () => {
    MicroModal.close('remove-logo-popup');
    setTimeout(() => {
      setShowRemoveLogoPopup(false);
    }, 300);
  };

  return (
    <div
      className='modal micromodal-slide website-feeds-modal'
      id={`remove-logo-popup`}
      aria-hidden='true'
      tabIndex='-1'
      ref={micromodalElement}
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div
          className='modal__container pt-4'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-alerts-title'
          style={{
            maxWidth: '530px',
          }}
        >
          <button
            className='close-button-filters general-button webfeed-popup-close'
            onClick={(e) => {
              e.stopPropagation();
              closeMicroModal();
            }}
          >
            Close
          </button>

          <h3 className='topic-title mb-4 section-title pl-lg-5 pl-4 pb-3'>Remove your logo</h3>
          <div className='topic-email-alerts-content px-4 px-lg-5 py-3 '>
            <img
              className='img-fluid d-block mx-auto mb-4'
              src={organisationLogo}
              alt='organisation logo'
              style={{ maxWidth: '180px' }}
            />

            <p className='text-center mx-auto main-light-text'>
              Are you sure you want to remove your logo? We’ll replace it with a default image on all content created by
              you or your team
            </p>
            <div className='my-4 text-center'>
              <button
                className='general-button outline-button px-4 mr-3 py-1'
                onClick={() => {
                  closeMicroModal();
                }}
              >
                Cancel
              </button>
              <button
                className='btn btn-general topic-delete-button py-1 remove-logo-button'
                onClick={() => {
                  setOrganisationLogo(null);
                  setIsSaved(null);
                  closeMicroModal();
                }}
              >
                Yes, remove logo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveLogoPopup;
