import React from 'react';
import Popup from '../../../Common/PopUp';
import useEmailDomainFunctions from '../../../Dashboard/hooks/useEmailDomainFunctions';

const ContentPopup = ({ closeMicroModal, emailDomainId, isAdminPage, teamId, loadInitialInformation }) => {
  const { deleteEmailDomainRequest } = useEmailDomainFunctions();

  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p className='pr-2'>
          Are you sure? This cannot be undone and will remove all email records and stats from PolicyMogul
        </p>

        <div className='my-4'>
          <button
            className='general-button main-button px-4 py-2'
            style={{ backgroundColor: '#9b171f' }}
            onClick={async (e) => {
              let deleteAction = await deleteEmailDomainRequest({
                emailDomainId,
                isAdminPage,
                teamId,
                loadInitialInformation,
              });
              if (deleteAction) {
                closeMicroModal();
              }
            }}
          >
            Delete Email domain
          </button>
        </div>
      </div>
    </div>
  );
};

const DeleteEmailDomainModal = (props) => {
  return (
    <Popup {...props} maxWidth={500}>
      <ContentPopup {...props} />
    </Popup>
  );
};

export default DeleteEmailDomainModal;
