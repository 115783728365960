import { useEffect, useRef, useState } from 'react';
import TopicPicker from '../../../Common/TopicPicker';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import getUrlParam from '../../utilities/getUrlParam';
import normalize from '../../utilities/normalizeString';
import { ConfirmClientModal } from './Client';
import { useNavigate } from 'react-router-dom-v5-compat';

const CreateMaterial = (props) => {
  const { setType, type, keywordsLists, organisationWebsite, client, setClient, team } = props;

  const inputBox = useRef(null);
  const step = getUrlParam('step');
  const returnUrl = (firstInSearch) =>
    getUrlParam('returnUrl')
      ? `${firstInSearch ? '?' : '&'}returnUrl=${encodeURIComponent(getUrlParam('returnUrl'))}`
      : '';
  const [createState, setCreateState] = useState({ headline: '', websiteLink: organisationWebsite });
  const [showClientValidation, setShowClientValidation] = useState(false);
  const [route, setRoute] = useState('Create with AI');
  const { headline, websiteLink } = createState;
  const updateFromLocalStorage = useRef();
  const [disabledInput, setDisabledInput] = useState(false);
  const navigate = useNavigate();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();

  updateFromLocalStorage.current = () => {
    navigate({ search: `?step=${'1'}${agencyUser ? '-agency' : ''}${returnUrl()}` });
  };

  useEffect(() => {
    updateFromLocalStorage.current();
  }, []);

  const modifyState = ({ property, newValue }) => {
    setCreateState({ ...createState, [property]: newValue });
  };

  useEffect(() => {
    modifyState({ property: 'websiteLink', newValue: organisationWebsite });
  }, [organisationWebsite]);

  const changeStep = () => {
    let state = {};
    if (agencyUser) {
      state = { client: client?.id, websiteLink };
    }
    if (step === '1' || route === 'Upload existing work') {
      navigate({ search: `?step=${parseInt(step) + 1}${returnUrl()}` });
    } else {
      navigate({ pathname: `/influence/ai/${normalize(type)}`, search: returnUrl(true), state });
    }
  };

  const finishCreateProcess = () => {
    let state = { headline: headline };
    if (agencyUser) {
      state = { ...state, client: client?.id, websiteLink };
    }
    navigate(
      {
        pathname: `/influence/${type === 'Policy ask' ? 'policy-asks' : normalize(type)}/create/${agencyUser ? '3' : '2'}}`,
        search: returnUrl(true),
      },
      { state }
    );
  };

  const stepOne = step === '1' || step === '2' || !step;
  const searchTimeout = useRef(null);
  const items = {
    step1: ['Create with AI', 'Upload existing work'],
    step2: ['Briefing material', 'Policy ask', 'Research', 'News release'],
  };

  return (
    <>
      <div
        className={`mx-auto mt-5 create-material-options-container ${step?.includes('agency') ? 'create-material-agency ' : ''}`}
      >
        {step === '1-agency' ? (
          <div className='agency-dropdown'>
            <TopicPicker
              showTopicCases={keywordsLists?.length > 0}
              fixedPosition
              keywordsLists={keywordsLists}
              defaultTopic={client}
              searchFunction={({ topic }) => setClient(topic)}
              customClassOpen={'create-material-agency-dropdown'}
            />
          </div>
        ) : (
          <div className='border rounded'>
            <div
              className={`px-3 ${step !== '2-agency' ? 'px-lg-5' : ''} rounded-lg bg-white d-flex flex-column align-items-center flex-wrap justify-content-center`}
              style={{ height: step?.includes('agency') ? 'auto' : '90px' }}
            >
              {stepOne && (
                <div className='py-4 overflow-auto w-100'>
                  <div className='d-flex justify-content-lg-center create-select-options'>
                    {items[`step${step}`]?.map((item, index) => {
                      const validateItem = () => {
                        if (step === '1') {
                          return route === item;
                        } else {
                          return type === item;
                        }
                      };
                      return (
                        <button
                          onClick={() => {
                            if (step === '1') {
                              setRoute(item);
                            } else {
                              setType(item);
                            }
                          }}
                          key={`item-${item}-${index}`}
                          className={`general-button nowrap-item rounded px-3 py-1 px-lg-4 py-lg-2 transition third-button ${validateItem() ? `text-white` : 'create-no-selected-button'} ${step === '1' ? 'flex-grow-1' : ''}`}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              {step === '3' && (
                <div className='d-flex justify-content-center align-items-center flex-column h-100 w-100'>
                  <input
                    autoFocus={true}
                    value={headline}
                    ref={inputBox}
                    onChange={(e) => {
                      modifyState({ property: 'headline', newValue: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.target.value !== '') {
                        finishCreateProcess();
                      }
                    }}
                    onKeyPress={(e) => {
                      setDisabledInput(true);
                      if (searchTimeout.current) clearTimeout(searchTimeout.current);
                      searchTimeout.current = setTimeout(() => {
                        setDisabledInput(false);
                      }, 500);
                    }}
                    className='main-input form-control w-100 create-material-input'
                    type='text'
                    placeholder='Type your headline...'
                  />
                </div>
              )}
              {step === '2-agency' && (
                <div className='d-flex justify-content-center align-items-center flex-column h-100 w-100'>
                  <input
                    autoFocus={true}
                    value={websiteLink ?? ''}
                    onChange={(e) => {
                      modifyState({ property: 'websiteLink', newValue: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && e.target.value !== '') {
                        navigate({ search: `?step=1${returnUrl()}` });
                      }
                    }}
                    className='main-input form-control w-100 create-material-input'
                    type='text'
                    placeholder={`Type your client's website...`}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        <div className={`d-flex ${stepOne ? 'justify-content-end' : 'justify-content-between'} pt-4`}>
          <button
            className={`general-button third-button px-4 py-2 text-white pointer rounded block w-100`}
            disabled={
              (step === '3' && headline === '') ||
              (step === '2-agency' && (websiteLink === '' || !websiteLink)) ||
              disabledInput
            }
            onClick={() => {
              if (step === '1-agency' || (step === '1' && newAgencyPlan)) {
                setShowClientValidation(true);
              } else if (step === '2-agency') {
                navigate({ search: `?step=1${returnUrl()}` });
              } else if (stepOne) {
                changeStep();
              } else {
                finishCreateProcess();
              }
            }}
          >
            Next <span className='icon-long-arrow-right inline-block paragraph-p6 ml-2' />
          </button>
        </div>
      </div>
      <ConfirmClientModal
        clientId={client?.id}
        keywordsListsToUse={keywordsLists}
        isOpen={showClientValidation}
        setIsOpen={setShowClientValidation}
        nextFunction={() => {
          newAgencyPlan ? changeStep() : navigate({ search: `?step=2-agency` });
        }}
        clientName={newAgencyPlan ? team?.teamName : null}
      />
    </>
  );
};

export default CreateMaterial;
