import React, { useState, useRef, useEffect, useContext } from 'react';
import { net_api_url, store } from '../Store';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import createNotification from '../Settings/Utilities/CreateNotification';
import useGetAccessToken from '../Dashboard/apiCalls/useGetAccessToken';
const ChangeEmail = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userProfile } = state;
  const { setUserEmail } = props;
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  useEffect(() => {
    return () => {
      source.cancel('Update profile canceled by the user.');
    };
  }, []);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const newEmailInput = useRef(null);
  const [newUserEmail, setNewUserEmail] = useState({
    change: false,
    value: '',
  });

  const changeEmailAddress = async (e) => {
    try {
      if (newUserEmail.value.includes('@')) {
        setIsLoadingButton(true);
        let token = await getAccessToken();
        let results = await axios.post(
          `${net_api_url}/api/user/update`,
          {
            userType: userProfile.userType,
            email: newUserEmail.value,
            userId: user.sub,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            cancelToken: source.token,
          }
        );
        if (results?.data?.success) {
          setUserEmail(newUserEmail.value);
          setNewUserEmail({
            change: false,
            value: '',
          });
          setIsLoadingButton(false);
          createNotification('success', 'Email changed successfully');
        }
      }
    } catch (error) {
      createNotification('danger', error.response.data.message);
      setIsLoadingButton(false);
    }
  };
  return (
    <>
      <div className='mt-2'>
        <button
          className='general-button resend-button mb-2 mt-0'
          onClick={() => {
            setNewUserEmail({ ...newUserEmail, change: true });
            setTimeout(() => {
              newEmailInput.current.focus();
            }, 50);
          }}
        >
          Change email address
        </button>
      </div>
      {newUserEmail.change && (
        <div className='pb-5'>
          <div className='form-group my-4'>
            <label htmlFor='new-email-address'>New email address</label>
            <input
              data-hj-allow
              className='form-control'
              id='new-email-address'
              type='text'
              value={newUserEmail.value}
              ref={newEmailInput}
              onChange={(e) => {
                setNewUserEmail({ ...newUserEmail, value: e.target.value });
              }}
            />
            {/* {error.type === 'newEmail' &&
              <p className="mb-0 profile-error-message py-2 px-3 mt-2">{typeof error.message === 'string' ? error.message : error.message()}</p> 
            } */}
          </div>
          <div className='text-right'>
            <button
              className='general-button action-button px-3 py-2'
              disabled={isLoadingButton}
              onClick={(e) => {
                changeEmailAddress(e);
              }}
            >
              {isLoadingButton ? <i className='fas fa-spinner fa-spin'></i> : `Change email address`}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChangeEmail;
