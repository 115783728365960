import React from 'react';
import { Link } from 'react-router-dom-v5-compat';
import normalize from '../../utilities/normalizeString';
import { useLocation } from 'react-router-dom-v5-compat';

const CreationSteps = (props) => {
  const {
    compiledSteps,
    type,
    isReadyToPreview,
    disabledNavigation,
    editPageId,
    clientValidationState,
    setClientValidationState,
    setShowClientValidation,
  } = props;
  const pathnameParams = `${type}/${!!editPageId ? 'edit' : 'create'}/${!!editPageId ? `${editPageId}/` : ''}`;
  const icons = {
    Client: 'suitcase',
    Headline: 'headline',
    Description: 'description',
    Details: 'visibility',
    'Associated content': 'associated-content',
    Preview: 'simple-check',
  };
  const location = useLocation();

  return (
    <>
      <ul className='policy-list vertical-progress px-0 main-sticky pt-3'>
        {compiledSteps.map((item, index) => {
          const { active, complete, disabled, notShow, name } = item;
          return (
            <React.Fragment key={`item-${normalize(name)}`}>
              {!notShow && (
                <li
                  className={`item-${normalize(name)} vertical-progress-item 
                      vertical-progress-item_${complete ? 'complete' : 'incomplete'} 
                      vertical-progress-item_${active ? 'active' : 'inactive'} 
                      vertical-progress-item_${disabled ? 'disabled' : ''}
                      ${editPageId ? ' vertical-progress-edit-item' : ''}            
                    `}
                >
                  <Link
                    to={{
                      pathname: `/influence/${pathnameParams}${index + 1}`,
                      search: location.search,
                      state: location?.state,
                    }}
                    onClick={(e) => {
                      if (disabled || disabledNavigation) {
                        e.preventDefault();
                      } else {
                        if (!clientValidationState?.isConfirmedClient) {
                          e.preventDefault();
                          setShowClientValidation(true);
                          setClientValidationState({
                            ...clientValidationState,
                            url: e.target.href,
                          });
                        }
                      }
                    }}
                  >
                    <span
                      className={`icon-${icons[name]} paragraph-p3 ${active || complete ? 'text-light-blue' : ''}`}
                    />
                    {name}
                  </Link>
                </li>
              )}
            </React.Fragment>
          );
        })}
        <li
          className={`item-preview vertical-progress-item vertical-progress-item_${isReadyToPreview ? 'complete' : 'disabled'}`}
        >
          <Link
            to={`/influence/${pathnameParams}preview`}
            onClick={(e) => {
              if (!isReadyToPreview) {
                e.preventDefault();
              }
            }}
          >
            <span className={`icon-${icons['Preview']} paragraph-p4 ${isReadyToPreview ? 'text-light-blue' : ''}`} />
            Preview
          </Link>
        </li>
      </ul>
    </>
  );
};

export default CreationSteps;
