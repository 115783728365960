const userFiltersOptions = [
  {
    name: 'sort',
    options: [
      {
        name: 'Last name',
        value: 'LastName',
        default: true,
      },
      {
        name: 'Full name',
        value: 'FullName',
      },
      {
        name: 'Registration date',
        value: 'RegistrationDate',
      },
    ],
  },
  {
    name: 'order',
    options: [
      {
        name: 'Ascending',
        value: 'Asc',
        default: true,
      },
      {
        name: 'Descending',
        value: 'Desc',
      },
    ],
  },
];

export default userFiltersOptions;
