const scrollUp = (element, distance) => {
  let s = element.scrollTop;
  let scrollDistance = 10;

  let scrollAnimate = setInterval(() => {
    if (s < distance) {
      s += scrollDistance;
      element.scrollTop = s;
    } else {
      clearInterval(scrollAnimate);
    }
  }, 13);
};

export default scrollUp;
