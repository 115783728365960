import React from 'react';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import SkeletonLists from './SkeletonLists';

const SkeletonTopic = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <div className={`pt-4 pt-lg-5`}>
      <div className='settings-box'>
        <div className='keyword-category-container'>
          <div className='keyword-category-box' ref={containerRef} style={{ height: `${heightContainer}px` }}>
            <SkeletonLists />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonTopic;
