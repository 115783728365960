import masterTopicObject from './masterTopicObject';

const TransformTopicsList = (keywordLists, activePlan, defaultKeywordId) => {
  let newKeywordLists = [...keywordLists];
  let masterTopic = masterTopicObject(activePlan, keywordLists);
  let defaultList = keywordLists.find((item) => item.id === activePlan?.defaultKeywordListId)
    ? activePlan?.defaultKeywordListId
    : null;
  let defaultKeywordListId = defaultKeywordId !== undefined ? defaultKeywordId : defaultList;
  if (defaultKeywordListId === null) {
    if (!newKeywordLists.find((item) => item.id === null)) {
      newKeywordLists.unshift({ ...masterTopic, defaultView: true });
    }
  } else {
    let keywordListPosition = newKeywordLists.findIndex((item) => item.id === defaultKeywordListId);
    let firstLists = newKeywordLists.slice(keywordListPosition, keywordListPosition + 1);
    firstLists[0] = { ...firstLists[0], defaultView: true };
    if (!firstLists.find((item) => item.id === null)) {
      firstLists.push(masterTopic);
    }
    newKeywordLists.splice(keywordListPosition, 1);
    newKeywordLists = [...firstLists, ...newKeywordLists];
  }
  return newKeywordLists;
};

export default TransformTopicsList;
