import { useRef, useState } from 'react';
import { Link } from 'react-router-dom-v5-compat';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';
import { Portal } from 'react-portal';

const ClientPickerForDuplicate = (props) => {
  const { clientsLists, localClientState, closeModal, maxListsPerTeam, maxNumberOfTeams } = props;
  const { localClients, setLocalClients } = localClientState;
  const selectClientFunction = ({ teamId }) => {
    let newClients = [...localClients];
    if (localClients?.includes(teamId)) {
      let position = newClients.indexOf(teamId);
      newClients.splice(position, 1);
    } else {
      newClients.push(teamId);
    }
    setLocalClients(newClients);
  };

  const allowAddClients = maxNumberOfTeams === null || clientsLists?.length < maxNumberOfTeams;

  const [showDropdownContent, setShowDropdownContent] = useState(false);

  const isMobile = useIsMobile();

  const topicContainer = useRef(null);

  const positionStyles = () => {
    let { top, left, width, height } = topicContainer.current ? topicContainer.current.getBoundingClientRect() : {};
    return {
      position: 'fixed',
      top: top ? top + height : 0,
      left,
      width,
    };
  };

  const createName = () => {
    if (localClients?.length === 0) {
      return `Select a client`;
    } else if (localClients?.length === 1) {
      let client = clientsLists.find((item) => item?.teamId === localClients[0]);
      return client?.teamName;
    } else {
      return `${localClients?.length} clients selected`;
    }
  };

  return (
    <>
      {showDropdownContent && (
        <div className='general-close-background z-extra-max' onClick={() => setShowDropdownContent(false)} />
      )}
      <div
        className={`mx-0 client-picker client-picker-complete dropdown-keywords-lists ${showDropdownContent ? 'dropdown-keywords-lists-open' : ''} dropdown-keywords-lists-fixed-position`}
        ref={topicContainer}
      >
        <div
          className={`dropdown-keywords-activeitem flex-centered pointer`}
          onClick={(e) => {
            setShowDropdownContent(!showDropdownContent);
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <p
            className={`search-main-text-value pointer flex-centered ${localClients?.length === 0 ? 'main-light-text' : ''}`}
          >
            <span className='d-block hidden-lines hidden-one-line'>{createName()}</span>
          </p>

          <span className={`search-dropdown ${showDropdownContent ? 'search-dropdown-open' : ''}`} />
        </div>
      </div>
      <Portal>
        <div
          className={`open-dropdown d-${showDropdownContent ? 'block' : 'none'} dropdown-fixed-position`}
          style={positionStyles()}
        >
          <div className='open-dropdown-container'>
            <div>
              <div className='scroll-container hover-container search-bar-lists'>
                <ul className='policy-list px-0 mb-0 py-2 hover-content'>
                  {clientsLists?.map((client, index) => {
                    const { teamId, teamName, myKeywordLists, teamMemberKeywordLists } = client ?? {};
                    const lists =
                      !!myKeywordLists && !!teamMemberKeywordLists
                        ? [...myKeywordLists, ...teamMemberKeywordLists]
                        : [];
                    const addNewTopicCondition = maxListsPerTeam === null || lists?.length < maxListsPerTeam;
                    const disabledCondition = !addNewTopicCondition;

                    return (
                      <div className='px-4 py-2' key={`item-${teamId}`}>
                        <div
                          data-tooltip-content={
                            disabledCondition
                              ? `You have reached the limit of topics you can create for this client`
                              : ''
                          }
                          data-tooltip-id={'client-picker-tooltip'}
                          className={`duplicate-client-checkbox-item checkbox-item d-flex ${disabledCondition ? 'checkbox-item-disabled' : ''}`}
                        >
                          <input
                            type='checkbox'
                            id={`item-${teamId}`}
                            value={localClients?.includes(teamId)}
                            checked={localClients?.includes(teamId)}
                            onChange={(e) => {
                              if (!disabledCondition) {
                                selectClientFunction({ teamId, e });
                              }
                            }}
                          />
                          <label className='flex-grow-1' htmlFor={`item-${teamId}`}>
                            <p className='hidden-lines hidden-one-line dropdown-name mb-0'>
                              {teamName}
                              {disabledCondition && <span className='icon-padlock ml-2' />}
                            </p>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
            <Link
              to={'/settings/clients?create=true'}
              className={`text-light-blue manage-clients-button w-100 d-block py-3 px-4 border-top ${!allowAddClients ? 'contact-button-launch-messenger' : ''}`}
              data-tooltip-content={
                !allowAddClients ? `You have reached the limit of clients you can add. Contact us to add more` : ''
              }
              data-tooltip-id={'client-picker-tooltip'}
              onClick={(e) => {
                if (!allowAddClients) {
                  e.preventDefault();
                }
                closeModal();
              }}
            >
              <span className='icon-square-plus ' />
              Add client {!allowAddClients && <span className='icon-padlock ml-1' />}
            </Link>
          </div>
        </div>
        <ReactTooltip
          id={'client-picker-tooltip'}
          effect={'solid'}
          type={'info'}
          className={'pricing-feature-tooltip feed-tooltip'}
          arrowColor={'transparent'}
          backgroundColor={'#00122B'}
          multiline={true}
          globalEventOff={isMobile ? 'click' : undefined}
        />
      </Portal>
    </>
  );
};

export default ClientPickerForDuplicate;
