import React, { useState } from 'react';
import RecipientsSearchBar from './EmailRecipients/RecipientSearchBar';
import { useLocation } from 'react-router-dom-v5-compat';

const emailTabs = ['Email', 'Performance', 'Recipients'];

function EmailComponentTabs({ activeTab, setActiveTab, sentQntty, searchValue, setSearchValue, emailId }) {
  const location = useLocation();
  const [tabFromLocation, setTabFromLocation] = useState(location?.state?.prepopulatedActiveTab);

  return (
    <div className='email-tabs-container-recipients'>
      <div className={`${emailId ? 'email-tabs-container-performance' : 'email-tabs-container'}`}>
        {activeTab !== 'Email' && !emailId && (
          <p className='sent-tabs-qntty'>{`Sent to ${sentQntty} recipient${sentQntty > 1 ? 's' : ''}`}</p>
        )}
        {emailTabs.map((tab, index) => {
          return (
            <div
              key={`${tab}-${index}`}
              className={`email-tab-btn ${
                tabFromLocation === tab || (activeTab === tab && !tabFromLocation) ? 'email-tab-btn-active' : ''
              }`}
              onClick={() => {
                setTabFromLocation(null);
                setActiveTab(tab);
              }}
            >
              {tab}
            </div>
          );
        })}
      </div>
      {activeTab === 'Recipients' && <RecipientsSearchBar searchValue={searchValue} setSearchValue={setSearchValue} />}
    </div>
  );
}

export default EmailComponentTabs;
