import { useEffect, useState, useRef } from 'react';
import useLoadCompanyImage from '../../UserContent/useLoadCompanyImage';
import { Portal } from 'react-portal';
import Popup from '../../../Common/PopUp';

const ContentSupportPopup = (props) => {
  const { data, closeMicroModal, openFromSupport, updateSupport, supporter } = props;
  const { id } = supporter ?? {};

  const [reason, setReason] = useState(supporter?.reason ?? '');
  const inputItem = useRef(null);
  useEffect(() => {
    if (inputItem?.current) {
      setTimeout(() => {
        inputItem.current.focus();
      }, 300);
    }
  }, []);

  const openFromSupportCondition = openFromSupport || !supporter?.reason;

  return (
    <>
      <div className='py-4 section-title px-lg-5 px-4 border-bottom d-flex text-light-blue'>
        <span className='icon-circle-check title-h3 mr-3 d-inline-block align-middle' />
        <h3 className='topic-title font-weight-normal mb-0 pr-4 pr-lg-5'>
          Your support has been added to {data?.rawTitle}
        </h3>
      </div>
      <div className='px-lg-5 px-4 py-3 pt-lg-4 pb-lg-5'>
        <p className='title-h5-m-bold mb-0 pt-1'>Why did you support this?</p>
        <p className='main-light-text title-h5-m mb-0 pb-1'>
          Your support reason will be displayed alongside your organisation name
        </p>

        <div className='border border-purple-100/70 rounded px-4 py-3 d-flex align-items-center mt-4 mb-5'>
          <OrganisationLogo ownerOrganisationLogo={supporter?.organisationLogo} />
          <div className='flex-grow-1'>
            <input
              value={reason}
              type='text'
              ref={inputItem}
              className='w-100 input-empty'
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
          </div>
        </div>

        <button
          className='d-block action-button general-button py-2 mb-3 w-100'
          disabled={reason === ''}
          onClick={async (e) => {
            let support = await updateSupport({ e, properties: { id, reason } });
            if (support) {
              closeMicroModal();
            }
          }}
        >
          {openFromSupportCondition ? 'Add' : 'Update'} support reason
        </button>
        <button
          className={`d-block simple-link-button w-100 general-button ${openFromSupportCondition ? '' : 'text-red'}`}
          onClick={async (e) => {
            if (openFromSupportCondition) {
              closeMicroModal();
            } else {
              let support = await updateSupport({ e, properties: { id, reason: '' } });
              if (support) {
                closeMicroModal();
              }
            }
          }}
        >
          {openFromSupportCondition ? 'Skip for now' : 'Delete support reason'}
        </button>
      </div>
    </>
  );
};

const SupportPopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentSupportPopup {...props} />
      </Popup>
    </Portal>
  );
};

const ContentConfirmDeletePopup = (props) => {
  const { closeMicroModal, supportItem, page } = props;

  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
      <div className='text-center'>
        <p>
          Are you sure you want to remove your support from this{' '}
          {page === 'policy-asks' ? 'policy ask' : page?.replace('-', ' ')}?
        </p>

        <div className='my-4'>
          <button
            className='general-button outline-button general-button-spacing mr-2 mr-lg-3 mt-lg-0 text-red-70'
            onClick={async (e) => {
              await supportItem(e);
              closeMicroModal();
            }}
          >
            {`Remove support`}
          </button>
        </div>
      </div>
    </div>
  );
};

const ConfirmDeletePopup = (props) => {
  return (
    <Portal>
      <Popup {...props}>
        <ContentConfirmDeletePopup {...props} />
      </Popup>
    </Portal>
  );
};

const OrganisationLogo = (props) => {
  const { ownerOrganisationLogo } = props;
  const imageState = useLoadCompanyImage(ownerOrganisationLogo);
  return (
    <>
      {!imageState.loading && (
        <img
          style={{ height: '25px', width: '25px', borderRadius: '50%' }}
          src={imageState.src}
          alt='logotype'
          className='image-circle mr-3'
        />
      )}
    </>
  );
};

export { SupportPopup, ConfirmDeletePopup };
