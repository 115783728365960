import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import TotalResults from '../../../components/TotalResults';
import Filters from '../../../Filters';
import useGetSectionFilters from '../../../Filters/useGetSectionFilters';
import MainSearchBar from '../../../MainSearchBar';
import ClearFiltersButton from '../../../SubNav/ui/ClearFiltersButton';
import useDetectActiveFilters from '../../../SubNav/useDetectActiveFilters';
import useIsMobile from '../../../utilities/useIsMobile';
import { useLocation } from 'react-router-dom-v5-compat';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import InfluenceNotIncludedFeaturesPopup from '../../CrmContacts/InfluenceNotIncludedFeaturesPopup';
import useGeneralApiCall from '../../../apiCalls/useGeneralApiCall';
import { store } from '../../../../Store';
import thousandCommas from '../../../helpers/thousandCommas';
import useNewlyAddedContacts from '../../../hooks/useNewlyAddedContacts';
import getUrlParam from '../../../utilities/getUrlParam';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';

function StakeholdersSubNav({
  isMobile,
  isSmallMobile,
  readyKeywordLists,
  filtersProperties,
  setIsStakeholdersSelected,
}) {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { selectedStakeholders, search, availableStakeholders, contactIdsCheck, activePlan, team } = state;
  const { enableCrm } = activePlan;
  const { list } = search;

  const [isLoading, setIsLoading] = useState(false);
  const stakeholderHeaderRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { getSectionFilters } = useGetSectionFilters('Stakeholders');
  const sectionFilters = getSectionFilters();
  const { searchWithin, filters, nationsFilter } = sectionFilters;

  const { detectActiveFilters } = useDetectActiveFilters();
  const activeFilters = detectActiveFilters() || list?.id || getUrlParam('topic-id');
  const [showInfluenceCrmPopup, setShowInfluenceCrmPopup] = useState(false);

  const { setNewlyAddedContacts } = useNewlyAddedContacts();

  const { validateContactsOversubscribed, setShowContactsOversubscribedConfirmModal } =
    useValidateContactsOversubscribed();

  const { generalApiCall } = useGeneralApiCall();
  const { newAgencyPlan } = useTopicOrClientWord();

  const { selectedIds, selectedAll, selectedAllVisible } = selectedStakeholders;

  const showSubNavFilters = useIsMobile(1280);

  const filteredStakeholders = (arr) => {
    return arr.filter((id) => !contactIdsCheck[id]);
  };
  const isSelected = filteredStakeholders(selectedIds).length > 0 || selectedAll;

  useEffect(() => {
    setIsStakeholdersSelected(isSelected);
  }, [isSelected]);

  const renderAddContactsLink = (requestedToAdd) => {
    return isLoading ? (
      <i
        className='fas fa-spinner fa-spin'
        style={{
          marginLeft: '15px',
          marginTop: '9px',
          width: '15px',
          height: '15px',
          fontSize: '15px',
          color: '#fff',
        }}
      ></i>
    ) : (
      <p
        className='white-link-underline'
        onClick={() => {
          if (enableCrm) {
            validateContactsOversubscribed(callAddToContactLists, 'add_contact', requestedToAdd);
          } else {
            setShowInfluenceCrmPopup(true);
          }
        }}
      >
        Add to my contact{newAgencyPlan ? ` list for ${team?.teamName}` : 's'}.
      </p>
    );
  };
  const callAddToContactLists = async () => {
    let requestBody = selectedAll
      ? {
          keywordListId: list ? list.id : null,
          filters: filters,
          searchWithinQuery: searchWithin?.searchWithinQuery,
          tags: null,
        }
      : {
          contactIds: selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds,
          tags: null,
        };

    if (selectedAll && nationsFilter) {
      let selectedOption = nationsFilter.options.find((item) => nationsFilter.optionSelected === item.name);
      requestBody = {
        ...requestBody,
        country: selectedOption.filterValue,
      };
    }
    const url = selectedAll ? '/api/crm-contact/add-stakeholders-by-query' : '/api/crm-contact/add-stakeholders';
    try {
      setIsLoading(true);
      const result = await generalApiCall({
        method: 'post',
        pathname: url,
        requestProperties: requestBody,
        needsAuthentication: true,
        returnError: true,
      });

      if (result && !result.status) {
        setIsLoading(false);
        const addedContacts = selectedAllVisible.length > 0 ? selectedAllVisible : selectedIds.length;
        const addQntty = selectedAll ? availableStakeholders : addedContacts;
        createNotification(
          'success',
          `${addQntty} stakeholder${addQntty > 1 ? 's  were' : ' was'} added to your contacts`
        );
        setNewlyAddedContacts(result);
        setIsStakeholdersSelected(false);
        navigate('/influence/contacts');
      } else {
        if (result?.status === 403) {
          setShowContactsOversubscribedConfirmModal(true);
        } else {
          let message = `An error ocurred.`;
          createNotification('danger', message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      let errorMessage = 'There was an error completing your operation. Please reload the page.';
      createNotification('danger', `${errorMessage}`);
    } finally {
      setIsLoading(false);
    }
  };
  const deselectHandler = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'selectedStakeholders',
      value: {
        selectedAll: false,
        selectedIds: [],
        selectedAllVisible: [],
      },
    });
  };

  const SelectedStakeholderSubNav = () => {
    return (
      <div className='stakeholder-subnav-selected'>
        <div className='stakeholder-subnav-selected-text-wrapper'>
          {filteredStakeholders(selectedIds).length > 0 && (
            <>
              <p className='stakeholder-subnav-selected-text'>
                <span>{thousandCommas(filteredStakeholders(selectedIds).length)} out of </span>
                <TotalResults onlyNumber />
                <span>{isSmallMobile ? 'selected:' : `${activeFilters ? 'matching' : ''} stakeholders selected:`}</span>
              </p>
              {renderAddContactsLink(filteredStakeholders(selectedIds).length)}
            </>
          )}
          {selectedAll &&
            (availableStakeholders > 0 ? (
              <>
                <p className='stakeholder-subnav-selected-text'>
                  <span>{thousandCommas(availableStakeholders)} out of </span>
                  <TotalResults onlyNumber />
                  <span>
                    {isSmallMobile ? 'selected:' : `${activeFilters ? 'matching' : ''} stakeholders selected:`}
                  </span>
                </p>
                {renderAddContactsLink(availableStakeholders)}
              </>
            ) : (
              <p className='stakeholder-subnav-selected-text'>
                All stakeholders matching your current filters are already in your contact list
              </p>
            ))}
        </div>
        {!isSmallMobile && (
          <button onClick={deselectHandler} className='stakeholder-subnav-deselect-btn text-red'>
            Deselect all
          </button>
        )}
      </div>
    );
  };
  return (
    <>
      <div className='influence-subnav-wrapper' ref={stakeholderHeaderRef}>
        <div
          className={`mx-auto main-content-wrapper flex-centered justify-content-between align-items-center resize-elements-container stakeholder-sub-nav ${
            isSelected ? `d-none` : ''
          }`}
        >
          {isMobile && (
            <p className={`mb-0 title-h4 nowrap-item`}>
              {location.pathname.includes('/policymakers') ? 'Policymakers' : 'Stakeholders'}
            </p>
          )}
          <div className={`stakeholder-search-bar-container`}>
            {readyKeywordLists && !isMobile && <MainSearchBar />}
            {showSubNavFilters && (
              <>
                <Filters section={'Stakeholders'} filtersProperties={filtersProperties} useOutside={false} />
                {!isSmallMobile && activeFilters && (
                  <div style={{ marginLeft: '25px' }}>
                    <ClearFiltersButton stakeholdersSubNav />
                  </div>
                )}
              </>
            )}
          </div>
          {!isSmallMobile && activeFilters && showSubNavFilters && (
            <div className='stakeholder-action-btn-container'>
              <div className='flex-centered'>
                <div className='stakeholders-mapping-total-results'>
                  <div className='ml-auto mr-0 onlysort-filters-button'>
                    <Filters section={'Stakeholders'} dropDownFilters onlySort />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {!showSubNavFilters && activeFilters && !isSelected && (
          <div className='d-flex' style={{ marginLeft: '25px', columnGap: '16px' }}>
            <ClearFiltersButton stakeholdersSubNav />
          </div>
        )}
        {/* SUBNAV IF SELECTED*/}
        {isSelected && <SelectedStakeholderSubNav />}
      </div>
      {showInfluenceCrmPopup && (
        <InfluenceNotIncludedFeaturesPopup setShowPopup={setShowInfluenceCrmPopup} type={'contacts'} />
      )}
    </>
  );
}

export default StakeholdersSubNav;
