import React from 'react';
import { store } from '../../../../../Store.js';
import { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import useGeneralApiCall from '../../../../apiCalls/useGeneralApiCall.js';
import useGetSectionFilters from '../../../../Filters/useGetSectionFilters.js';
import StakeholderSelectBarSkeleton from './StakeholderSelectBarSkeleton.js';
import useGetAccessToken from '../../../../apiCalls/useGetAccessToken.js';

const StakeholderSelectBar = ({ totalHits, listId }) => {
  const [isLoadingAvailable, setIsLoadingAvailable] = useState(false);
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { selectedStakeholders, contentResults, activeResults } = state;

  const { getAccessToken } = useGetAccessToken();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { generalApiCall } = useGeneralApiCall();
  const { selectedAll } = selectedStakeholders;

  const { getSectionFilters } = useGetSectionFilters('Stakeholders');
  const sectionFilters = getSectionFilters();
  const { searchWithin, filters } = sectionFilters;

  const { Stakeholders } = contentResults[activeResults];
  const stakeholdersToRender = Stakeholders?.hits ?? [];

  const availableToSelectStakeholders = async () => {
    try {
      setIsLoadingAvailable(true);
      let method = 'post';
      let pathname = '/api/crm-contact/count-not-added-already';
      let token = await getAccessToken();
      let requestHeaders = {
        cancelToken: source.token,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const requestProperties = {
        filters,
        searchWithinQuery: searchWithin?.searchWithinQuery,
        keywordListId: listId,
      };
      let selectedStakeholdersQntty = await generalApiCall({
        pathname,
        method,
        requestHeaders,
        requestProperties,
        notShowErrorMessage: true,
      });
      if (selectedStakeholdersQntty || selectedStakeholdersQntty === 0) {
        return selectedStakeholdersQntty;
      } else {
        return totalHits;
      }
    } finally {
      setIsLoadingAvailable(false);
    }
  };

  const handleCheck = async (checked) => {
    if (checked) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'selectedStakeholders',
        value: {
          selectedAll: false,
          selectedIds: [],
          selectedAllVisible: [],
        },
      });
    } else {
      const selectedStakeholdersQntty = await availableToSelectStakeholders();
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'availableStakeholders',
        value: selectedStakeholdersQntty,
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'selectedStakeholders',
        value: {
          selectedAll: true,
          selectedIds: [],
          selectedAllVisible: [],
        },
      });
    }
  };

  const renderSelectAll = () => {
    return (
      <div className='stakeholder-selectbar'>
        <div className='stakeholder-selectbar-left-side'>
          <div className='stakeholder-master-checkbox-input-wrapper'>
            {isLoadingAvailable ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  width: '15px',
                  height: '15px',
                  fontSize: '15px',
                  color: '#0094cc',
                }}
              ></i>
            ) : (
              <div className={`stakeholder-master-checkbox-input`} onClick={(e) => e.stopPropagation()}>
                <input
                  type={'checkbox'}
                  checked={selectedAll}
                  onChange={async () => await handleCheck(selectedAll)}
                  id={`selectAllStakeholders`}
                />
                <label htmlFor={`selectAllStakeholders`} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!Stakeholders ? (
        <div className='stakeholder-selectbar'>
          <div className='stakeholder-selectbar-left-side'>
            <StakeholderSelectBarSkeleton />
          </div>
        </div>
      ) : stakeholdersToRender.length > 0 ? (
        renderSelectAll()
      ) : (
        ''
      )}
    </>
  );
};

export default StakeholderSelectBar;
