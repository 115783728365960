import React from 'react';

function CustomHighlighter({ searchWord = '', textToHighlight = '', highlightClassName = 'highlight-text' }) {
  let highlightedPartIndex = textToHighlight?.toLowerCase().indexOf(searchWord?.toLowerCase());
  if (highlightedPartIndex >= 0 && searchWord.length > 0 && textToHighlight.length > 0) {
    return (
      <span>
        <span>{textToHighlight.substring(0, highlightedPartIndex)}</span>
        <mark className={highlightClassName}>
          {textToHighlight.substring(highlightedPartIndex, highlightedPartIndex + searchWord.length)}
        </mark>
        <span>{textToHighlight.substring(highlightedPartIndex + searchWord.length)}</span>
      </span>
    );
  }
  return <span>{textToHighlight}</span>;
}

export default CustomHighlighter;
