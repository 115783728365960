const TransformFromPascalCase = (text) => {
  if (text) {
    let result = text
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .trim();
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
};

export default TransformFromPascalCase;
