const InfluenceFilters = [
  {
    name: 'Type',
    searchState: 'always',
    filterName: 'contentType',
    pages: ['Influence'],
    options: [
      {
        name: 'Briefing material',
        filterValue: 'BriefingMaterial',
      },
      {
        name: 'Policy asks',
        filterValue: 'PolicyAsk',
      },
      {
        name: 'Research',
        filterValue: 'Research',
      },
      {
        name: 'News release',
        filterValue: 'NewsRelease',
      },
    ],
  },

  {
    name: 'Sort by',
    searchState: 'always',
    type: 'sort',
    mainListSort: true,
    pages: ['Influence'],
    optionSelected: 'random',
    hidden: true,
    options: [
      {
        name: 'random',
        filter: [{ pages: ['Influence'], property: { field: null, order: 'random' } }],
      },
    ],
  },
];

export default InfluenceFilters;
