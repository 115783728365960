import MicroModal from 'micromodal';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { store } from '../../Store';
import { Portal } from 'react-portal';
const StakeHolderSharePopUp = () => {
  const location = useLocation();
  const globalState = useContext(store);
  const { state } = globalState;
  const { shareStakeholderPopupProps } = state;
  const { title, header, urlToShare: urlToShareProp, influenceItem } = shareStakeholderPopupProps ?? {};

  let urlToShare = window.location.origin;
  let analyticsTracking = `${location.search === '' ? '?' : '&'}utm_source=share-button`;
  const url =
    urlToShareProp ??
    `${urlToShare}${location.pathname}${location.search}${location.hash}${
      !location.search.includes('utm_source=share-button') ? analyticsTracking : ''
    }`;
  return (
    <Portal>
      <div
        className='modal micromodal-slide delete-keywordcategory-modal adjustment-of-position'
        id={`share-stakeholder-popup`}
        aria-hidden='true'
        tabIndex='-1'
      >
        <div className='modal__overlay' tabIndex='-1' data-micromodal-close>
          <div className='modal__container pt-4' role='dialog' aria-modal='true' aria-labelledby='modal-alerts-title'>
            <div className='preregister-close px-2' data-micromodal-close>
              <img
                src={`${process.env.REACT_APP_CDNURL}/images/preregister-close.svg`}
                alt='close-icon'
                data-micromodal-close
              />
            </div>

            <div className='p-3 px-lg-5 py-lg-3'>
              <h3 className='main-title'>{header}</h3>
              <div className='d-flex justify-content-center px-4 pb-4 pt-2'>
                <div className='row stakeholder-share-buttons share-popoup'>
                  <div>
                    <button
                      className='general-button position-relative p-3 copy-link-button'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let tempInput = document.createElement('input');
                        tempInput.style = 'position: absolute; left: -1000px; top: -1000px;';
                        tempInput.value = url;
                        document.body.appendChild(tempInput);
                        tempInput.select();
                        document.execCommand('copy');
                        document.body.removeChild(tempInput);
                        MicroModal.close('share-stakeholder-popup');
                        createNotification('success', `Link copied`);
                      }}
                    >
                      <span className='icon-share-link title-h2' style={{ color: '#f2a805' }}></span>
                      <span>Copy link</span>
                    </button>
                    <TwitterShareButton
                      url={url}
                      title={title}
                      onClick={() => {
                        MicroModal.close('share-stakeholder-popup');
                      }}
                    >
                      <span className='icon-twitter-x-circle' style={{ fontSize: '30px' }}></span>
                      <span>Share on X</span>
                    </TwitterShareButton>
                  </div>

                  {influenceItem && (
                    <div>
                      <LinkedinShareButton
                        title={title}
                        source={'Policy influence'}
                        className={`linkedin-button pointer text-left general-button`}
                        url={url}
                        resetButtonStyle={false}
                      >
                        <span className={`icon-share-image icon-linkedin`} /> <span>Share on LinkedIn</span>
                      </LinkedinShareButton>

                      <FacebookShareButton
                        quote={title}
                        url={url}
                        resetButtonStyle={false}
                        className={`facebook-button pointer text-left general-button`}
                      >
                        <span
                          className={`icon-share-image icon-facebook title-h3`}
                          style={{ marginTop: '-4px', verticalAlign: 'middle' }}
                        />{' '}
                        <span>Share on Facebook</span>
                      </FacebookShareButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default StakeHolderSharePopUp;
